import React, { Dispatch, SetStateAction, useEffect, useCallback } from "react";
import { BaseReview, Guideline, GuidelineType, Indication } from "@coherehealth/core-platform-api";
import {
  Body1,
  Body2,
  Card,
  Checkbox,
  colorsLight,
  Sanitized,
  useFeature,
  findList,
  getIndicationText,
  ToggleResults,
  generateDynamicElementBlocks,
  populateDepthFirstNodeList,
  DynamicElemBlock,
  DynamicIndication,
} from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, Theme } from "@material-ui/core/styles";
import isEqual from "lodash/isEqual";
import CheckIcon from "@material-ui/icons/Done";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { useReviewsDispatch, ReviewsAction } from "components/ClinicalReview/reviewUtils/useReviews";
const useStyles = makeStyles({
  lists: {
    "& ul": {
      padding: 0,
    },
    "& ol": {
      padding: 0,
    },
  },
});

interface StyledIndicationCardProps {
  isDraft: boolean;
}

interface IProps {
  guideline: Guideline;
  review: BaseReview;
  setCriteriaMet: Dispatch<SetStateAction<boolean>>;
}

interface RecursiveProps {
  guideline: Guideline;
  guidelineElemRefs: Element[];
  review: BaseReview;
  indication: DynamicIndication;
  useDynamicIndicationRuleIcons: boolean;
}

interface StaticProps {
  guideline: Guideline;
  block: DynamicElemBlock;
  indication?: Indication;
  review: BaseReview;
  lowestDepth: boolean;
  iconCheck?: string;
  useDynamicIndicationRuleIcons: boolean;
}

export default function IndicationsSection({ guideline, review, setCriteriaMet }: IProps) {
  const useDynamicIndicationRuleIcons = useFeature("dynamicIndicationRuleIcons");
  const guidelineElemRefs: Element[] = populateDepthFirstNodeList(guideline.guidelineHtml ?? "");
  let blocks: DynamicElemBlock[] | undefined = generateDynamicElementBlocks(
    guidelineElemRefs,
    Array.from(Array(guidelineElemRefs.length).keys()),
    guideline.indications ?? []
  );

  const { blocksWithLogicalOperators, indicationCount, satisfiedIndicationCount, topMostLogicalOperator } =
    convertIndicationToCondition(blocks);

  useEffect(() => {
    if (topMostLogicalOperator === "ALL" && satisfiedIndicationCount === indicationCount) {
      setCriteriaMet(true);
    } else if (topMostLogicalOperator === "ANY" && satisfiedIndicationCount >= 1) {
      setCriteriaMet(true);
    } else {
      setCriteriaMet(false);
    }
  }, [satisfiedIndicationCount, indicationCount, topMostLogicalOperator, setCriteriaMet]);
  return (
    <>
      {blocksWithLogicalOperators &&
        blocksWithLogicalOperators.map((block: DynamicElemBlock) => (
          <div key={block.htmlString}>
            {block.indication ? (
              <IndicationCard isDraft={review.reviewStatus === "DRAFT"}>
                <RecursiveIndicationComponent
                  guidelineElemRefs={guidelineElemRefs}
                  indication={block.indication}
                  guideline={guideline}
                  review={review}
                  useDynamicIndicationRuleIcons={useDynamicIndicationRuleIcons}
                />
              </IndicationCard>
            ) : (
              <StaticComponent
                block={block}
                review={review}
                guideline={guideline}
                lowestDepth={false}
                useDynamicIndicationRuleIcons={useDynamicIndicationRuleIcons}
              />
            )}
          </div>
        ))}
    </>
  );
}

function RecursiveIndicationComponent({
  guidelineElemRefs,
  indication,
  review,
  guideline,
  useDynamicIndicationRuleIcons,
}: RecursiveProps) {
  const blocks = generateDynamicElementBlocks(
    guidelineElemRefs,
    indication.guidelineHtmlIndices ?? [],
    indication.indications ?? []
  );
  return (
    <>
      {blocks.map((block) => (
        <div key={block.htmlString}>
          {block.indication ? (
            <IndicationCard isDraft={review.reviewStatus === "DRAFT"}>
              <RecursiveIndicationComponent
                guidelineElemRefs={guidelineElemRefs}
                guideline={guideline}
                indication={block.indication}
                review={review}
                useDynamicIndicationRuleIcons={useDynamicIndicationRuleIcons}
              />
            </IndicationCard>
          ) : (
            <StaticComponent
              block={block}
              guideline={guideline}
              review={review}
              lowestDepth={!blocks.find((block) => block.indication)}
              indication={indication}
              iconCheck={indication.iconCheck}
              useDynamicIndicationRuleIcons={useDynamicIndicationRuleIcons}
            />
          )}
        </div>
      ))}
    </>
  );
}

function convertIndicationToCondition(blocks: DynamicElemBlock[]): {
  indicationCount: number;
  satisfiedIndicationCount: number;
  blocksWithLogicalOperators: DynamicElemBlock[];
  topMostLogicalOperator: string;
} {
  return {
    blocksWithLogicalOperators: blocks,
    satisfiedIndicationCount: 0,
    indicationCount: 0,
    topMostLogicalOperator: "",
  };
}

function StaticComponent({
  block,
  lowestDepth,
  indication,
  review,
  guideline,
  iconCheck,
  useDynamicIndicationRuleIcons,
}: StaticProps) {
  const classes = useStyles();
  const htmlString = getIndicationText(block.htmlString);
  const listExists = findList(block.htmlString);
  let policyType: GuidelineType | undefined;
  review.guidelines?.forEach((guidelinePolicyMap) => {
    const filterMatchMap = guidelinePolicyMap.guidelineMatchMap?.find((gMM) => gMM.guidelineId === guideline.id);
    if (filterMatchMap) {
      policyType = filterMatchMap.policyType;
    }
  });
  let isChecked = !!review.guidelines?.find((guidelinePolicyMap) =>
    guidelinePolicyMap.checkedIndications?.find((ind) => isEqual(ind.uid, indication?.uid))
  );

  //This is to check whether there is any html text, this has be to corrected at upper level but checking this way for now
  const stripedHtml = block.htmlString.replace(/<[^>]+>/g, "");
  const indicationHtml = indication?.indicationHtml?.replace(/<[^>]+>/g, "");
  const index = indicationHtml?.indexOf(stripedHtml);
  const reviewsDispatch = useReviewsDispatch({ suppressErrorSnackbar: true });
  const toggleFunc = useCallback(
    (metIndications: Indication[], unmetIndications: Indication[], met: boolean): ToggleResults => {
      if (indication) {
        const isChecked = !!metIndications.find((metIndication) => metIndication.uid === indication.uid);
        if (isChecked) {
          return {
            checkedIndications: metIndications.filter((metIndication) => metIndication.uid !== indication.uid),
            unmetIndications,
          };
        } else {
          return {
            checkedIndications: [...metIndications, indication],
            unmetIndications: unmetIndications.filter((unmetIndication) => unmetIndication.uid !== indication.uid),
          };
        }
      } else {
        return { checkedIndications: [], unmetIndications: [] };
      }
    },
    [indication]
  );
  return (
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <div>
        {indication &&
          stripedHtml &&
          index === 0 &&
          (review.reviewStatus === "DRAFT" ? (
            iconCheck === "QuestionMark" ? (
              <QuestionMark />
            ) : iconCheck === "GreenCheck" ? (
              <SuccessIcon />
            ) : iconCheck === "CrossMark" ? (
              <CrossIcon />
            ) : lowestDepth ? (
              checkBoxDisplay(
                isChecked,
                indication,
                policyType,
                reviewsDispatch,
                review.id,
                toggleFunc,
                guideline.id || "",
                lowestDepth
              )
            ) : (
              ""
            ) //todo: verify if this right
          ) : isChecked ? (
            <GreenCheckIcon />
          ) : (
            <div style={{ marginRight: 40 }} />
          ))}
      </div>
      <div>
        {review.reviewStatus === "DRAFT" || !isChecked ? (
          <Body1 component="div" style={{ margin: !listExists ? "16px 0px" : 0 }}>
            <Sanitized __html={htmlString || "- -"} className={classes.lists} />
          </Body1>
        ) : (
          <Body2 component="div" style={{ margin: !listExists ? "16px 0px" : 0 }}>
            <Sanitized __html={htmlString || "- -"} className={classes.lists} />
          </Body2>
        )}
      </div>
    </div>
  );
}

function checkBoxDisplay(
  isChecked: boolean,
  indication: Indication | undefined,
  policyType: GuidelineType | undefined,
  reviewsDispatch: React.Dispatch<ReviewsAction> | null,
  reviewId: string,
  toggleFunc: (metIndications: Indication[], unmetIndications: Indication[], met: boolean) => ToggleResults,
  guidelineId: string,
  lowestDepth?: boolean
) {
  return (
    <Checkbox
      style={{ marginTop: "3px", marginRight: "-10px" }}
      checked={isChecked}
      disabled={!lowestDepth}
      onChange={() => {
        indication &&
          reviewsDispatch?.({
            type: "CHECK_INDICATION",
            reviewId: reviewId,
            payload: {
              checkedIndication: indication,
              toggleFunc,
              indicationPolicyType: policyType,
              met: true,
              guidelineId,
            },
          });
      }}
    />
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const IndicationCard = styled(({ isDraft, children, ...other }) => <Card {...other}>{children}</Card>)<
  Theme,
  StyledIndicationCardProps
>(({ isDraft, theme }) => ({
  backgroundColor: colorsLight.gray.light,
  boxShadow: "none",
  padding: theme.spacing(0, 2),
  margin: theme.spacing(1, 0),
  border: isDraft ? "1px solid #d7d5d5" : "none",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const GreenCheckIcon = styled(CheckIcon)(({ theme }) => ({
  margin: theme.spacing(1.5, 2, 0, 0),
  color: theme.palette.success.main,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const QuestionMark = styled(HelpOutlineIcon)({
  marginTop: "12px",
  color: colorsLight.font.secondary,
  marginRight: "16px",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SuccessIcon = styled(CheckCircleIcon)(({ theme }) => ({
  marginTop: "12px",
  color: theme.palette.success.main,
  marginRight: "16px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CrossIcon = styled(CancelIcon)(({ theme }) => ({
  marginTop: "12px",
  color: theme.palette.error.main,
  marginRight: "16px",
}));
