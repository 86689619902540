import React, { useState, useEffect, useContext } from "react";
import { Container, Drawer, makeStyles } from "@material-ui/core";
import { DocumentInfo } from "../../DocumentViewer";
import FileMetaDataDisplay from "../../FileMetaDataDisplay";
import { Body1, colorsDark, colorsLight, H1, H2, H6, PrimaryButton, queueMgmtBaseUrl } from "@coherehealth/common";
import PhoneInput from "common/PhoneInput";
import { PhoneNumber, SendFaxResponse, useSendFax, FaxNoticeInfo } from "@coherehealth/core-platform-api";
import { useCompleteServiceCase } from "@coherehealth/qm-api";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import SendFaxIcon from "components/images/SendFaxIcon.svg";
import FaxSentConfirmationIcon from "components/images/FaxSentConfirmationIcon.svg";
import { Dialog } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useTrackUserInteraction } from "../../../../util/userActivityTracker";
import { FaxNoticeReasonsContext } from "./SendFaxModal";
import { FaxIdAndIsCohereTemplateContext } from "./index";
import { FaxAttachmentContext } from "../FaxAttachmentContext";
import config from "api/config";
import { error as logError } from "logger";
interface Props {
  fileData: Partial<DocumentInfo>;
  onClose?: () => void;
  width: number;
  blob?: Blob | null;
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    "& .MuiDrawer-paperAnchorDockedRight": {
      borderBottomRightRadius: 4,
    },
  },
  container: {
    width: ({ width }: { width: number }) => width,
    height: "100%",
    paddingTop: theme.spacing(5),
    backgroundColor: colorsLight.background.light,
    display: "flex",
    flexDirection: "column",
  },
  iconContainer: {
    width: "100%",
    backgroundColor: "#F8FBFC",
  },
  text: {
    paddingBottom: theme.spacing(1),
  },
  secondaryText: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: "80%",
  },
  close: {
    float: "right",
    width: 48,
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: "auto",
    marginBottom: theme.spacing(3),
  },
  box: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    backgroundColor: colorsLight.background.light,
  },
  image: {
    width: 275,
    height: 275,
    paddingTop: 120,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  modal: {
    "& .MuiDialog-paperFullScreen": {
      backgroundColor: colorsLight.background.light,
    },
  },
  modalContainer: {
    width: 730,
    height: 230,
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  modalImage: {
    width: 230,
    height: 230,
    display: "block",
  },
  modalTextContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "auto",
    marginBottom: "auto",
    paddingLeft: theme.spacing(5),
  },
}));

export default function SendFaxSidebar({ fileData, onClose, width, blob }: Props) {
  const [faxNumber, setFaxNumber] = useState<PhoneNumber>();
  const [sendFaxConfirmationModalOpen, setSendFaxConfirmationModalOpen] = useState(false);
  const [attemptedSend, setAttemptedSend] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const phoneNumberInvalid = faxNumber?.number?.length !== 10;
  const { caseId } = useContext(FaxAttachmentContext);
  const { mutate: send, error, loading } = useSendFax({});
  const { mutate: updateServiceCase, loading: serviceCaseLoading } = useCompleteServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: () => {
      window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
    },
  });
  useEffect(() => {
    if (error) {
      enqueueSnackbar("Failed to send fax, please try again");
    }
  }, [error, enqueueSnackbar]);

  const classes = useStyles({ width });
  const faxNoticeInfo = useContext(FaxNoticeReasonsContext);
  const faxInfo = useContext(FaxIdAndIsCohereTemplateContext);
  const trackUserActivityInteraction = useTrackUserInteraction();
  const sendUserTracking = () => {
    trackUserActivityInteraction({
      event: "SEND_FAX_NOTICE",
      stage: faxInfo?.isCohereTemplate ? "COHERE_TEMPLATE_FAX_FLOW" : "NON_COHERE_TEMPLATE_FAX_FLOW",
      activityContext: {
        faxId: faxInfo?.faxId,
        faxNoticeInfo: faxNoticeInfo,
      },
    });
  };
  const onSendFax = async () => {
    const number = faxNumber?.number || "";
    const extension = faxNumber?.extension || "";
    const formattedFaxNumber = "1" + number + extension;
    const formData = new FormData();
    if (blob) {
      formData.set("file", blob);
      formData.set("faxNumber", formattedFaxNumber);
      formData.set("recipientName", "Cohere User");
    }

    try {
      const resp = await send(formData as unknown as void);
      const response = resp as SendFaxResponse[];
      if (response[0]?.isSuccess) {
        sendUserTracking();
        if (caseId) {
          updateServiceCase({
            outcome: "FAXBACK",
            dateCompleted: new Date().toISOString(),
            description: "Fax notice sent",
            noticeInfo: formatNoticeInfo(faxNoticeInfo),
            faxbackNumber: formattedFaxNumber,
            faxbackTime: new Date().toISOString(),
          });
        } else {
          setSendFaxConfirmationModalOpen(true);
        }
      } else {
        enqueueSnackbar(`Unable to send fax. Fax number may be invalid`, { variant: "error" });
      }
    } catch (e) {
      logError(e);
      enqueueSnackbar(`Unable to send fax`, { variant: "error" });
    }
  };

  return (
    <Drawer variant="permanent" anchor="right" className={classes.drawer}>
      <Container className={classes.iconContainer}>
        <>
          {onClose ? (
            <IconButton className={classes.close} edge="end" onClick={onClose} aria-label="close" data-public>
              <CloseIcon />
            </IconButton>
          ) : (
            <Box className={classes.box} />
          )}
        </>
      </Container>
      <Container className={classes.container}>
        <FileMetaDataDisplay {...fileData} />
        <H2 className={classes.text} style={{ paddingTop: 16 }} data-public>
          Fax notice preview
        </H2>
        <Body1 className={classes.secondaryText} data-public>
          Double check the information and fax number before sending
        </Body1>
        <PhoneInput
          fullWidth
          value={faxNumber || {}}
          onChange={setFaxNumber}
          label="Send fax to (10 digits)"
          error={phoneNumberInvalid && (attemptedSend || Boolean(faxNumber?.number))}
          helperText={phoneNumberInvalid && "Phone number value must be 10 digits"}
        />
        <img src={SendFaxIcon} className={classes.image} alt="SendFaxIcon" />
        <PrimaryButton
          className={classes.button}
          fullWidth
          disabled={phoneNumberInvalid}
          onClick={() => {
            setAttemptedSend(true);
            if (!phoneNumberInvalid) {
              onSendFax();
            }
          }}
          loading={loading || serviceCaseLoading}
          data-public
        >
          Send fax notice
        </PrimaryButton>
      </Container>
      <Dialog fullScreen open={sendFaxConfirmationModalOpen} className={classes.modal}>
        <Container className={classes.modalContainer}>
          <img src={FaxSentConfirmationIcon} className={classes.modalImage} alt="FaxSentConfirmationIcon" />
          <div className={classes.modalTextContainer}>
            <H1 style={{ paddingBottom: 16 }} data-public>
              Fax sent!
            </H1>
            <H6 style={{ color: colorsDark.gray.divider }} data-public>
              Great job! A fax notice has been sent to the provider. Return to Salesforce to continue.
            </H6>
          </div>
        </Container>
      </Dialog>
    </Drawer>
  );
}

function formatNoticeInfo(faxNoticeInfo: FaxNoticeInfo | undefined) {
  if (faxNoticeInfo) {
    let subreasons = [];
    if (faxNoticeInfo.patientInfoMissing) {
      subreasons.push("Patient information missing");
    }
    if (faxNoticeInfo.practiceAuthContactMissing) {
      subreasons.push("Practice auth contact missing");
    }
    if (faxNoticeInfo.diagnosisInfoMissing) {
      subreasons.push("Diagnosis information missing");
    }
    if (faxNoticeInfo.servicesRequestedInfoMissing) {
      subreasons.push("Service(s) requested information missing");
    }
    if (faxNoticeInfo.orderingPhysicianInfoMissing) {
      subreasons.push("Ordering physician information missing");
    }
    if (faxNoticeInfo.facilityInfoMissing) {
      subreasons.push("Facility information missing");
    }
    if (faxNoticeInfo.ineligiblePatient) {
      subreasons.push("Patient is not eligible");
    }
    if (faxNoticeInfo.ineligibleProvider) {
      subreasons.push("Provider is not eligible");
    }
    if (faxNoticeInfo.mustBePCPSubmitted) {
      subreasons.push("Prior Authorization must be submitted by PCP");
    }
    if (faxNoticeInfo.additionalNotes) {
      subreasons.push("Other");
    }
    return {
      reason: faxNoticeInfo.reasonForNotice,
      subReason: subreasons,
      additionalNotes: faxNoticeInfo.additionalNotes,
    };
  }
  return {};
}
