import React, { Dispatch, SetStateAction, useState } from "react";
import { ProcedureCodeWithId, useFeature, withId } from "@coherehealth/common";
import { AuthStatus, ProcedureCode } from "@coherehealth/core-platform-api";
import { ServiceRequestFormContent, ContinuationFormContent } from "common/SharedServiceRequestFormComponents";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { NonCohereCodes } from "components/AuthBuilder/common";
import ProcedureCodeSelector from "./ProcedureCodeSelector";
import ProcedureCodeTable from "./ProcedureCodeTable";

interface FillFormsProps {
  formContent: ServiceRequestFormContent | ContinuationFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent | ContinuationFormContent>>;
  patientId?: string;
  palProcedureCodes: ProcedureCode[];
  setPalProcedureCodes: (pxs: ProcedureCode[]) => void;
  setNonPalPDFOpen: Dispatch<SetStateAction<boolean>>;
  showApprovedSrEditWarning?: boolean | undefined;
  nonPalProcedureCodes: ProcedureCode[];
  setNonPalProcedureCodes: Dispatch<SetStateAction<ProcedureCode[]>>;
  nonCohereProcedureCodes: NonCohereCodes[];
  setNonCohereProcedureCodes: Dispatch<SetStateAction<NonCohereCodes[]>>;
  isContinuation?: boolean;
  onPatientSummary?: boolean;
  requestType?: string;
  authStatus: AuthStatus;
  authorizationProcedureCodes?: ProcedureCode[];
  setSemanticProcedureCodes?: Dispatch<SetStateAction<ProcedureCode[]>>;
  healthPlanName: string | null;
}

export default function ProcedureCodeSelectTable({
  formContent,
  setFormContent,
  patientId,
  palProcedureCodes,
  setPalProcedureCodes,
  setNonPalPDFOpen,
  showApprovedSrEditWarning,
  nonPalProcedureCodes,
  setNonPalProcedureCodes,
  nonCohereProcedureCodes,
  setNonCohereProcedureCodes,
  isContinuation,
  onPatientSummary,
  authStatus,
  requestType,
  authorizationProcedureCodes,
  setSemanticProcedureCodes,
  healthPlanName,
}: FillFormsProps) {
  const [procedureCodeSelectionActive, setProcedureCodeSelectionActive] = useState(false);
  const canAddMoreUnits = onPatientSummary ? !formContent?.isInpatient && isContinuation : true;
  const multipleUnitsHomeHealthFF = useFeature("multipleUnitsHomeHealth");
  const procedureCodes = formContent?.procedureCodes?.map(withId) || [];
  const setProcedureCodes = (newCodes: ProcedureCodeWithId[]) =>
    setFormContent((prev) => ({ ...prev, procedureCodes: newCodes }));

  return (
    <>
      <Grid container spacing={0}>
        <ProcedureCodeTable
          formContent={formContent}
          authorizationProcedureCodes={authorizationProcedureCodes}
          setSemanticProcedureCodes={setSemanticProcedureCodes}
          setNonPalPDFOpen={setNonPalPDFOpen}
          nonPalProcedureCodes={nonPalProcedureCodes}
          nonCohereProcedureCodes={nonCohereProcedureCodes}
          procedureCodes={procedureCodes}
          setProcedureCodes={setProcedureCodes}
          setProcedureCodeSelectionActive={setProcedureCodeSelectionActive}
          isUnitsOnPx={false}
          onPatientSummary={onPatientSummary}
          isContinuation={isContinuation}
          authStatus={authStatus}
          requestType={requestType}
          healthPlanName={healthPlanName || ""}
        />
        {canAddMoreUnits && (
          <>
            <Spacer />
            <ProcedureCodeSelector
              error={procedureCodes.length < 1}
              formContent={formContent}
              palProcedureCodes={palProcedureCodes}
              setPalProcedureCodes={setPalProcedureCodes}
              setNonPalPDFOpen={setNonPalPDFOpen}
              nonPalProcedureCodes={nonPalProcedureCodes}
              setNonPalProcedureCodes={setNonPalProcedureCodes}
              nonCohereProcedureCodes={nonCohereProcedureCodes}
              setNonCohereProcedureCodes={setNonCohereProcedureCodes}
              procedureCodeSelectionActive={procedureCodeSelectionActive}
              setProcedureCodeSelectionActive={setProcedureCodeSelectionActive}
              procedureCodes={procedureCodes}
              setProcedureCodes={(codes) =>
                setProcedureCodes([
                  ...codes.map((px) => {
                    const groupBy: ProcedureCode["groupBy"] = formContent?.clinicalService?.id
                      ? "ClinicalService"
                      : undefined;
                    return {
                      ...px,
                      id: px.id ?? "",
                      units: formContent?.units ? parseInt(formContent.units) : undefined,
                      groupId: formContent?.clinicalService?.id ?? "",
                      groupBy,
                      //TODO: temporary solution to be able to support mutiple units for "Home Health" clinical service
                      unitType:
                        multipleUnitsHomeHealthFF &&
                        formContent?.clinicalService?.name === "Home Health" &&
                        px.code === "G0156"
                          ? "Number of visits"
                          : px.unitType,
                    };
                  }),
                ])
              }
              showApprovedSrEditWarning={showApprovedSrEditWarning}
              patientId={patientId}
              healthPlanName={healthPlanName}
            />
          </>
        )}
      </Grid>
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Spacer = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 0),
  width: "100%",
}));
