import React, { Dispatch, ForwardedRef, SetStateAction, useCallback, useEffect, useRef, useState } from "react";

import { ServiceRequestResponse, useGetServiceRequestRuleActions } from "@coherehealth/core-platform-api";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { InlineButton, Caption, useFeature, formatDateStrAsEastern, HEALTH_HELP_NAME } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core/styles";
import { appHeaderHeight, headerHeight } from "util/StyleConstants";

import ServiceRequestIntegrationSummary from "components/IntegrationManagement/ServiceRequestIntegrationSummary";
import { useAuthorized } from "authorization";

import loadable from "@loadable/component";
import { AuthorizationSummaryCardProps } from ".";
import ServiceRequestFormSection, {
  SRFormViewState,
} from "components/PatientSummary/ServiceRequestSummaryCard/ServiceRequestFormSection";
import {
  canBeWithdrawn,
  isTerminalStatus,
  useIsDelegatedToVendor,
  useServiceRequestWithdrawn,
} from "util/serviceRequest";
import ServiceRequestHistory from "components/ServiceRequest/ReadonlyDetail/ServiceRequestHistory";
import AutoApproveAlertModal from "components/PatientSummary/ServiceRequestSummaryCard/AutoApproveAlertModal";
import NudgeOpportunitySection from "components/PatientSummary/ServiceRequestSummaryCard/NudgeOpportunitySection";
import AttachmentSection from "components/PatientSummary/ServiceRequestSummaryCard/AttachmentSection";
import AuthorizationAuditHistory from "components/ServiceRequest/ReadonlyDetail/AuthorizationAuditHistory";
import RequestedByInfo from "../RequestedByInfo";
import WithdrawButton from "../../../ServiceRequest/WithdrawButton";
import WithdrawModal from "../../ServiceRequestSummaryCard/WithdrawModal";
import ClinicalAssessmentSection from "components/PatientSummary/ServiceRequestSummaryCard/ClinicalAssessmentSection";
import { Subtitle2 } from "@coherehealth/design-system";
import { useGetServiceRequestEditConfigurationByPayerAndAuthStatus } from "hooks/useGetFeatureConfigurations";

const ReviewSection = loadable(() => import("components/ServiceRequest/ReviewSection"));

interface Props extends Omit<AuthorizationSummaryCardProps, "initialIsExpanded" | "onShowSummaryPrintout"> {
  srFormViewState: SRFormViewState;
  setSRFormViewState: (newState: SRFormViewState) => void;
  submitEditsOnDraftSrsToIntake?: boolean;
  hideServiceRequestEditWithdrawButtons?: boolean;
  targetReviewId?: string | null;
  setTargetReview?: Dispatch<SetStateAction<HTMLElement | null>>;
  initialIsExpanded?: boolean;
  clinicalAssessmentSectionRef?: ForwardedRef<HTMLElement>;
  populateDefaults?: boolean;
  // The latest SR in this authorization
  latestSr: ServiceRequestResponse;
  refreshAuthorization?: () => Promise<void>;
  adhocLetter?: boolean;
  singleAttachmentViewerRedesign?: boolean;
}

/**
 * Expanded sections for a AuthorizationRequestSummary
 *
 * Has 4 parts:
 * - The AuthorizationForm, which has the 278 fields
 * - The Attachments
 * - The Clinical Assessment
 * - The Outreach Opportunities and their corresponding Outreach Attempts (READ ONLY)
 *
 * Each part has its own READ_ONLY and EDIT states
 */
export default function AuthorizationSummaryDetails({
  authorization,
  latestSr,
  srFormViewState,
  setSRFormViewState,
  onEdit,
  submitEditsOnDraftSrsToIntake,
  hideServiceRequestEditWithdrawButtons = false,
  targetReviewId,
  setTargetReview,
  populateDefaults,
  refreshAuthorization,
  adhocLetter,
  singleAttachmentViewerRedesign,
}: Props) {
  const canShowHistory = useAuthorized("VIEW_SERVICE_REQUEST_AUDIT_LOG");
  const canViewServiceRequestIntegration = useAuthorized("VIEW_PATIENT_SUMMARY_INTEGRATIONS_UI");
  const showReview = useAuthorized("VIEW_REVIEW");
  const limitVoidsAfterApprovalFF = useFeature("LimitVoidsAfterApproval");

  const canViewOutreachOpportunities = useAuthorized("VIEW_OUTREACH_OPPORTUNITIES");

  const [showHistory, setShowHistory] = useState(false);

  const [showClinicalAssessment, setShowClinicalAssessment] = useState<boolean>(false);
  const authorizationHasOneSR = authorization.serviceRequestsOnAuth?.length === 1;
  const isSingleService = !Boolean(
    authorization.serviceRequestsOnAuth && authorization?.serviceRequestsOnAuth[0].carePathJourney?.id
  );
  const isDelegatedToHealthHelp = useIsDelegatedToVendor(
    authorization?.serviceRequestsOnAuth ? authorization.serviceRequestsOnAuth[0] : null,
    [HEALTH_HELP_NAME]
  );
  // // There are some old service requests in the system with a PAL category; these have no assessment
  const hasNoAssessment =
    (isDelegatedToHealthHelp &&
      authorization?.serviceRequestsOnAuth &&
      !authorization.serviceRequestsOnAuth[0]?.vendorIdentifier) ||
    (isSingleService && authorization?.serviceRequestsOnAuth && authorization.serviceRequestsOnAuth[0].palCategory);

  const { data: ruleActions, refetch: simulateRuleRun } = useGetServiceRequestRuleActions({
    id: latestSr.id,
    queryParams: {
      persistRuleRunDescription: false,
    },
    lazy: true,
  });

  const { srEditConfig } = useGetServiceRequestEditConfigurationByPayerAndAuthStatus({
    healthPlanName: latestSr?.healthPlanName,
    authStatus: latestSr.authStatus,
  });

  const [showApproveAlertModal, setShowApproveAlertModal] = useState(false);
  const reviewSectionRef = useRef<HTMLDivElement | null>(null);
  const { spacing } = useTheme();
  const scrollToReview = useCallback(() => {
    // delay this a bit to let the modal close
    setTimeout(() => {
      if (reviewSectionRef.current) {
        const reviewSectionOffsetToViewport = reviewSectionRef.current.getBoundingClientRect().top;
        window.scrollTo({
          top: reviewSectionOffsetToViewport + window.scrollY - appHeaderHeight() - headerHeight - spacing(2),
          behavior: "smooth",
        });
      }
    }, 200);
  }, [reviewSectionRef, spacing]);

  // used as a key for refetching nudge opportunities after updating a service request
  const [nudgeOpKey, setNudgeOpKey] = useState(0);
  const incrementNudgeOpKey = () => setNudgeOpKey(nudgeOpKey + 1);
  // used as a key for refetching reviews after updating a service request
  const [reviewKey, setReviewKey] = useState<number>(0);
  const incrementReviewKey = () => {
    setReviewKey(reviewKey + 1);
  };

  const internalOnEdit = () => {
    onEdit?.(); // refreshes authorizations list
    incrementNudgeOpKey();
  };

  useEffect(() => {
    if (ruleActions?.length && ruleActions[0].actionType === "APPROVE") {
      setShowApproveAlertModal(true);
    }
  }, [ruleActions]);

  const hasRelationships = latestSr.serviceRequestRelations?.length;

  const srNoLongerNeededButton = srEditConfig?.srNoLongerNeededButton && limitVoidsAfterApprovalFF;

  const {
    withdrawnReason,
    withdrawModalOpen,
    setWithdrawModalOpen,
    withdrawRequestor,
    setWithdrawRequestor,
    setWithdrawnReason,
    onWithdraw,
    submitting,
  } = useServiceRequestWithdrawn(latestSr, undefined, internalOnEdit);

  const auditServiceHistory = useFeature("auditServiceHistory");
  const isAuthViewOnlyUser = useAuthorized("AUTH_VIEW_ONLY");

  return (
    <Grid container spacing={3}>
      {!hasRelationships && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
      <Grid item xs={12}>
        <ServiceRequestFormSection
          authorization={authorization}
          serviceRequest={latestSr}
          viewState={srFormViewState}
          setViewState={setSRFormViewState}
          simulateRuleRun={showReview && !isTerminalStatus(latestSr) ? simulateRuleRun : undefined}
          submitEditsOnDraftSrsToIntake={submitEditsOnDraftSrsToIntake}
          hideEditWithdrawButtons={hideServiceRequestEditWithdrawButtons}
          populateDefaults={populateDefaults}
          refreshAuthorization={refreshAuthorization}
          refreshAuthorizationList={() => onEdit?.()}
          incrementNudgeOpKey={incrementNudgeOpKey}
          incrementReviewKey={incrementReviewKey}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <AttachmentSection
          authorization={authorization}
          serviceRequest={latestSr}
          onEdit={internalOnEdit}
          singleAttachmentViewerRedesign={singleAttachmentViewerRedesign}
        />
      </Grid>
      {canViewServiceRequestIntegration && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ServiceRequestIntegrationSummary serviceRequest={latestSr} onEdit={internalOnEdit} />
          </Grid>
        </>
      )}
      {canShowHistory && (
        <Grid item xs={12}>
          <InlineButton
            title="Toggle audit history"
            onClick={() => {
              if (canShowHistory) {
                setShowHistory(!showHistory);
              }
            }}
          >
            <Caption color="primary" style={{ fontWeight: 600, fontSize: "13px" }}>
              {latestSr.authStatus === "DRAFT"
                ? "Draft"
                : "Submitted " + formatDateStrAsEastern(latestSr.intakeTimestamp)}
            </Caption>
          </InlineButton>
        </Grid>
      )}
      {showHistory && canShowHistory && (
        <Grid item xs={12}>
          {auditServiceHistory ? (
            <AuthorizationAuditHistory authorization={authorization} />
          ) : (
            <ServiceRequestHistory serviceRequestId={latestSr.id} />
          )}
        </Grid>
      )}
      {!hasNoAssessment && authorization.serviceRequestsOnAuth && authorizationHasOneSR && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <InlineButton
              onClick={() => {
                setShowClinicalAssessment(!showClinicalAssessment);
              }}
              disabled={latestSr?.isRestricted}
            >
              <Subtitle2>{showClinicalAssessment ? "Hide clinical assessment" : "Show clinical assessment"}</Subtitle2>
            </InlineButton>
          </Grid>
          {showClinicalAssessment && (
            <Grid container item xs={12}>
              <ClinicalAssessmentSection
                serviceRequest={authorization.serviceRequestsOnAuth[0]}
                showReadOnlyVendorSummary={isDelegatedToHealthHelp}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {authorization && authorization.serviceRequestsOnAuth && authorization.serviceRequestsOnAuth.length === 1 && (
            <>
              <Grid item xs={canBeWithdrawn(latestSr) ? (!srNoLongerNeededButton ? 10 : 8) : 12}>
                <RequestedByInfo
                  serviceRequest={authorization.serviceRequestsOnAuth[0]}
                  serviceRequestRefetch={async () => {
                    onEdit?.();
                  }}
                />
              </Grid>
              {canBeWithdrawn(latestSr) && !isAuthViewOnlyUser && !latestSr.isSrNoLongerNeeded ? (
                <Grid item style={{ paddingLeft: !srNoLongerNeededButton ? 25 : 46 }}>
                  <WithdrawButton
                    serviceRequest={latestSr}
                    buttonText={!srNoLongerNeededButton ? "Withdraw" : "Mark as no longer needed"}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      setWithdrawModalOpen(true);
                      event.stopPropagation();
                    }}
                    styles={{ padding: 0 }}
                    srNoLongerNeededButton={srNoLongerNeededButton}
                  />
                </Grid>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
      {showReview && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <StyledReviewSectionContainer>
              <ReviewSection
                key={reviewKey}
                ref={reviewSectionRef}
                onFinishEditing={internalOnEdit}
                serviceRequest={latestSr}
                authorization={authorization}
                willAutoApprove={Boolean(ruleActions?.length && ruleActions[0].actionType === "APPROVE")}
                targetReviewId={targetReviewId}
                setTargetReview={setTargetReview}
                adhocLetter={adhocLetter}
              />
            </StyledReviewSectionContainer>
          </Grid>
          <AutoApproveAlertModal
            open={showApproveAlertModal}
            handleClose={() => setShowApproveAlertModal(false)}
            scrollToReview={scrollToReview}
          />
        </>
      )}
      {canViewOutreachOpportunities && <NudgeOpportunitySection serviceRequest={latestSr} key={nudgeOpKey} />}
      {canBeWithdrawn(latestSr) && (
        <WithdrawModal
          withdrawnReason={withdrawnReason}
          setWithdrawnReason={setWithdrawnReason}
          withdrawRequestor={withdrawRequestor}
          setWithdrawRequestor={setWithdrawRequestor}
          serviceRequestId={latestSr.id}
          serviceRequest={latestSr}
          withdrawnReasonOptionsList={latestSr.reviewOutcomeWithdrawOptions}
          open={withdrawModalOpen}
          onClose={() => {
            setWithdrawModalOpen(false);
            setWithdrawnReason("");
          }}
          onWithdraw={onWithdraw}
          submitting={submitting}
          srNoLongerNeededButton={srNoLongerNeededButton}
        />
      )}
    </Grid>
  );
}

const REVIEW_MARGIN_OFFSET_TO_LEAVE_SECTION_FLUSH_TO_CONTAINER = -25;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledReviewSectionContainer = styled("div")({
  marginLeft: REVIEW_MARGIN_OFFSET_TO_LEAVE_SECTION_FLUSH_TO_CONTAINER,
  marginRight: REVIEW_MARGIN_OFFSET_TO_LEAVE_SECTION_FLUSH_TO_CONTAINER,
});
