import { useFeature } from "@coherehealth/common";
import {
  ReviewTypeTarget,
  RecommendChangeRuleAction,
  ReviewType,
  useGetServiceRequestRuleActions,
} from "@coherehealth/core-platform-api";
import { useEffect, useMemo, useState } from "react";
import { willApprove } from "util/rule";

interface useSRRuleResultsForClinicalReviewProps {
  serviceRequestId: string;
  isReviewEditMode: boolean;
  reviewType?: ReviewType["reviewType"];
}

export interface useSRRuleResultsForClinicalReviewReturn {
  hasReviewRedirectRule: boolean;
  reviewRedirectRule: RecommendChangeRuleAction | undefined;
  reviewNudges: RecommendChangeRuleAction[];
  showAutoApprovalModal: boolean;
  setAutoApprovalModalDismissed: React.Dispatch<React.SetStateAction<boolean>>;
  showAutoApprovalInfoBanner: boolean;
  fetchingSrRules: boolean;
}

export const useServiceRequestRuleResultsForClinicalReview = ({
  serviceRequestId,
  reviewType,
  isReviewEditMode,
}: useSRRuleResultsForClinicalReviewProps): useSRRuleResultsForClinicalReviewReturn => {
  const [autoApprovalModalDismissed, setAutoApprovalModalDismissed] = useState(false);
  const {
    data: ruleActions,
    refetch: fetchSrRules,
    loading: fetchingSrRules,
  } = useGetServiceRequestRuleActions({
    id: serviceRequestId || "",
    lazy: true,
  });

  useEffect(() => {
    if (serviceRequestId && isReviewEditMode) {
      fetchSrRules();
    }
  }, [serviceRequestId, fetchSrRules, isReviewEditMode]);

  const reviewNudges: RecommendChangeRuleAction[] = useMemo(() => {
    const reviewRedirectRules = ruleActions?.filter((item): item is RecommendChangeRuleAction => {
      return (
        item.actionType === "RecommendChange" &&
        item.displayTarget === "CLINICAL_REVIEW" &&
        isReviewTypeForRuleTarget(reviewType) &&
        !!item.reviewTypeTargets?.includes(reviewType)
      );
    });
    return reviewRedirectRules || [];
  }, [ruleActions, reviewType]);

  const reviewRedirectRule = reviewNudges?.[0];

  const hasReviewRedirectRule = !!reviewRedirectRule;

  const willAutoApprove = ruleActions ? willApprove(ruleActions) : false;

  const shouldShowAutoApprovalModal =
    useFeature("autoApprovalModalInCrr") &&
    // If something has gotten to MD review, someone has already determined that it likely doesn't meet approval criteria
    reviewType === "NurseReview";

  const showAutoApprovalModal = shouldShowAutoApprovalModal && willAutoApprove && !autoApprovalModalDismissed;
  const showAutoApprovalInfoBanner = shouldShowAutoApprovalModal && willAutoApprove;

  return {
    hasReviewRedirectRule,
    reviewRedirectRule,
    reviewNudges,
    showAutoApprovalModal,
    setAutoApprovalModalDismissed,
    showAutoApprovalInfoBanner,
    fetchingSrRules,
  };
};

const isReviewTypeForRuleTarget = (
  reviewType: ReviewType["reviewType"] | undefined
): reviewType is ReviewTypeTarget => {
  return !!reviewType && ["NurseReview", "MdReview", "PeerToPeerReview"].includes(reviewType);
};
