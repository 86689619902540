import React, { Dispatch, SetStateAction } from "react";

import { ClinicalAssessment, RuleActions, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { H6, colorsLight, useGetAuthorizationByIdWithFallback } from "@coherehealth/common";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import { FieldValue } from "./FormElements";
import ServiceRequestFormReadOnly from "../../PatientSummary/ServiceRequestSummaryCard/ServiceRequestFormReadOnly";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import { evalValidAuthorization } from "@coherehealth/common";

interface Props {
  serviceRequest: ServiceRequestResponse;
  includeStatusDisplay?: boolean;
  includeEditMessage?: boolean;
  onWithdraw?: () => Promise<any>;
  submitting?: boolean;
  openServiceRequestForEditing?: () => void;
  onEdit?: () => void;
  disableEdits?: boolean;
  ruleActions: RuleActions;
  setServiceRequest?: Dispatch<SetStateAction<ServiceRequestResponse>>;
  refetchServiceRequest: () => void;
  onAssessmentUpdate?: (clinicalAssessment: ClinicalAssessment) => void;
  caqSkipped?: boolean;
  isContinuationSR?: boolean;
  onReviewPage?: boolean;
  showServiceChangeBanner?: boolean;
}

export default function ReadonlyDetail({
  serviceRequest,
  includeEditMessage = false,
  onWithdraw,
  submitting,
  onEdit = () => {},
  ruleActions,
  refetchServiceRequest,
  onAssessmentUpdate,
  caqSkipped,
  isContinuationSR,
  onReviewPage,
  showServiceChangeBanner,
}: Props) {
  const { spacing } = useTheme();
  const { healthPlanName, encounterType } = serviceRequest;

  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName,
    encounterType,
    skipRequestTimingCheck: true,
  });
  const { data: authorization } = useGetAuthorizationByIdWithFallback({
    id: serviceRequest?.authorization?.id || "",
  });

  // There are some old service requests in the system with a PAL category; these have no assessment

  return (
    <Grid container direction="column" spacing={3} style={{ padding: 0 }}>
      <Grid item container>
        {Boolean(
          includeEditMessage && ["APPROVED", "PARTIALLY_APPROVED", "PENDING"].includes(serviceRequest.authStatus || "")
        ) && (
          <Grid item xs={12} style={{ paddingLeft: spacing(2) }}>
            <H6 style={{ marginBottom: spacing(0.5) }} data-public>
              Request Information
            </H6>
            <FieldValue style={{ color: `${colorsLight.error.main}` }}>{`Cannot be edited when request is ${
              StatusLabel[serviceRequest.authStatus || ""]
            }`}</FieldValue>
          </Grid>
        )}
        <ServiceRequestFormReadOnly
          serviceRequest={serviceRequest}
          ruleActions={ruleActions || []}
          isContinuationSR={isContinuationSR}
          isFacilityBasedAuth={facilityBasedFeatureEnabled}
          authorization={evalValidAuthorization(serviceRequest, authorization)}
          onReviewPage={onReviewPage}
          showServiceChangeBanner={showServiceChangeBanner}
        />
      </Grid>
    </Grid>
  );
}

const StatusLabel: Record<string, string> = {
  APPROVED: "Approved",
  PARTIALLY_APPROVED: "Partially Approved",
  PENDING: "Pending",
};
