// Cohere Health Contact info
import { OSCAR_HEALTH_PLAN_NAME } from "@coherehealth/common";

const CONTACT_PHONE = "1-833-283-0033";
export const CONTACT_PHONE_US_FORMAT = "(833) 283-0033";
const CONTACT_EMAIL = "support@coherehealth.com";

// Oscar Contact info
const CONTACT_PHONE_OSCAR = "(855) 672-2755";

interface contactInfo {
  email?: string;
  phone: string;
  contactName: string;
}

// Fetches the contact info for the given health plan.
// Right now, only Oscar and the generic Cohere Health contact info exists.
export const getHealthPlanContactInfo = (healthPlanName: string | undefined): contactInfo => {
  if (healthPlanName && healthPlanName === OSCAR_HEALTH_PLAN_NAME) {
    return { email: "", phone: CONTACT_PHONE_OSCAR, contactName: "Oscar" };
  } else {
    return { email: CONTACT_EMAIL, phone: CONTACT_PHONE, contactName: "us" };
  }
};
