import { parseDateFromISOString } from "@coherehealth/common";
import { Patient, ReferralRequestResponse, useGetPatient } from "@coherehealth/core-platform-api";
import { fieldIsValid } from "common/FormConfigUtils";
import { ReferralFormConfiguration } from "components/ReferralManagement/FormContentSpecification/ReferralRequestFormContentSpecifications";
import { ReferralRequestFormContent } from "components/ReferralManagement/RequestBuilder/ReferralRequestForm";
import { useSnackbar } from "notistack";
import { useEffect } from "react";

export type ReferralRequestSummaryField =
  | "DATES_VALID"
  | "PRIMARY_DIAGNOSIS"
  | "SECONDARY_DIAGNOSIS"
  | "REFERRING_PROVIDER"
  | "SPECIALTY"
  | "SPECIALIST_PRACTICE"
  | "SPECIALIST";

export const getDetailFields = (isAdministrative: boolean): ReferralRequestSummaryField[] => {
  if (isAdministrative) {
    return ["DATES_VALID", "REFERRING_PROVIDER", "SPECIALTY", "SPECIALIST_PRACTICE", "SPECIALIST"];
  } else {
    return [
      "DATES_VALID",
      "PRIMARY_DIAGNOSIS",
      "SECONDARY_DIAGNOSIS",
      "REFERRING_PROVIDER",
      "SPECIALTY",
      "SPECIALIST_PRACTICE",
      "SPECIALIST",
    ];
  }
};

export function rrFormContentFromResponse(referralRequest: ReferralRequestResponse): ReferralRequestFormContent {
  return {
    id: referralRequest?.id,
    startDate: referralRequest?.startDate ? parseDateFromISOString(referralRequest.startDate) : undefined,
    cohereId: referralRequest?.cohereId,
    primarySemanticDiagnosisCode: referralRequest?.primarySemanticDiagnosisCode || null,
    secondarySemanticDiagnosisCodes: referralRequest?.secondarySemanticDiagnosisCodes || [],
    selectedReferringProvider: referralRequest?.selectedReferringProvider || null,
    referringProviderSelectedAddress:
      referralRequest?.referringProviderSelectedLocation ||
      referralRequest?.selectedReferringProvider?.selectedLocation,
    referringProviderSelectedTin:
      referralRequest?.referringProviderSelectedTin ||
      referralRequest?.selectedReferringProvider?.selectedLocation?.tin,
    selectedFacility: referralRequest?.selectedFacility || null,
    facilitySelectedAddress:
      referralRequest?.facilitySelectedLocation || referralRequest?.selectedFacility?.selectedLocation,
    facilitySelectedTin:
      referralRequest?.facilitySelectedTin || referralRequest?.selectedFacility?.selectedLocation?.tin,
    selectedPerformingSpecialist: referralRequest?.selectedPerformingSpecialist || null,
    performingSpecialistSelectedAddress:
      referralRequest?.performingSpecialistSelectedLocation ||
      referralRequest?.selectedPerformingSpecialist?.selectedLocation,
    performingSpecialistSelectedTin:
      referralRequest?.performingSpecialistSelectedTin ||
      referralRequest?.selectedPerformingSpecialist?.selectedLocation?.tin,
    specialty: referralRequest?.specialty,
    referralStatus: referralRequest?.referralStatus,
  };
}

export const defaultFormContent: ReferralRequestFormContent = {
  id: undefined,
  startDate: undefined,
  cohereId: undefined,
  primarySemanticDiagnosisCode: null,
  secondarySemanticDiagnosisCodes: [],
  selectedReferringProvider: null,
  referringProviderSelectedAddress: null,
  referringProviderSelectedTin: null,
  selectedFacility: null,
  facilitySelectedAddress: null,
  facilitySelectedTin: null,
  specialty: undefined,
  selectedPerformingSpecialist: null,
  performingSpecialistSelectedAddress: null,
  performingSpecialistSelectedTin: null,
  referralStatus: "DRAFT",
};

export const validatePerformingSpecialistAddress = (
  rrFormConfiguration: ReferralFormConfiguration,
  rrFormContent: ReferralRequestFormContent,
  hasPerformingSpecialistAddress: boolean
) => {
  if (validatePerformingSpecialistSharedConditions(rrFormConfiguration, rrFormContent)) {
    return true;
  }
  if (rrFormConfiguration.performingSpecialistAddress.fieldSpec === "OMIT") {
    return true;
  }
  if (rrFormContent.selectedPerformingSpecialist) {
    if (rrFormContent.performingSpecialistSelectedAddress?.address) {
      return fieldIsValid(rrFormConfiguration.performingSpecialistAddress, hasPerformingSpecialistAddress);
    }
    return false;
  }
  return fieldIsValid(rrFormConfiguration.performingSpecialistAddress, hasPerformingSpecialistAddress);
};

export const validatePerformingSpecialistTin = (
  rrFormConfiguration: ReferralFormConfiguration,
  rrFormContent: ReferralRequestFormContent,
  performingSpecialistTinLength: number | undefined,
  hasPerformingSpecialistTin: boolean
) => {
  if (validatePerformingSpecialistSharedConditions(rrFormConfiguration, rrFormContent)) {
    return true;
  }
  if (!performingSpecialistTinLength) {
    return true;
  }
  if (!!rrFormConfiguration.selectedPerformingSpecialist && performingSpecialistTinLength > 0) {
    return !!rrFormConfiguration.performingSpecialistTIN;
  }
  return fieldIsValid(rrFormConfiguration.performingSpecialistTIN, hasPerformingSpecialistTin);
};

export const validatePerformingSpecialistNPI = (
  rrFormConfiguration: ReferralFormConfiguration,
  rrFormContent: ReferralRequestFormContent,
  hasPerformingSpecialistNpi: boolean
) => {
  if (validatePerformingSpecialistSharedConditions(rrFormConfiguration, rrFormContent)) {
    return true;
  }
  return fieldIsValid(rrFormConfiguration?.performingSpecialistNPI, hasPerformingSpecialistNpi);
};

const validatePerformingSpecialistSharedConditions = (
  rrFormConfiguration: ReferralFormConfiguration,
  rrFormContent: ReferralRequestFormContent
) => {
  if (rrFormConfiguration.selectedPerformingSpecialist.fieldSpec === "OMIT") {
    return true;
  }
  if (
    rrFormConfiguration.selectedPerformingSpecialist.fieldSpec === "OPTIONAL" &&
    !!rrFormContent.selectedPerformingSpecialist
  ) {
    return true;
  }
  return false;
};

/*
  Created a custom hook which returns referralEligibility field for a patient today
  Return of this hook will be same as that of useGetPatient()
*/
export function useGetPatientWithReferralEligibility(patientId: string): Patient | null {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: patient,
    loading: loadingPatient,
    error,
  } = useGetPatient({
    id: patientId || "",
    queryParams: { includeReferralEligibility: true },
  });

  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Failed to fetch the patient with id: ${patientId}`, {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [enqueueSnackbar, error, loadingPatient, patientId]);

  return patientId === "" ? null : patient;
}
