import { Container, Grid, useTheme, makeStyles } from "@material-ui/core";
import { Card, Caption, H2, useMuiContainerStyles } from "@coherehealth/common";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import ServiceRequestForm from "./ServiceRequestForm";
import RequestorCard from "components/Requestor/RequestorCard";
import { AuthBuilderRequestorProps } from "components/AuthBuilder";
import { Patient, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import {
  ServiceRequestFormStateSetters,
  useFacilityOutOfNetworkCheck,
  usePerformingProviderOutOfNetworkCheck,
  useOrderingProviderOutOfNetworkCheck,
} from "components/AuthBuilder/common";
import listReplace from "util/listReplace";
import { useServiceRequestConfigSpec } from "components/ServiceRequest/ConfigurableServiceRequestForm";
import { getPatientHealthPlanName } from "util/patientUtils";

interface Props extends AuthBuilderRequestorProps, ServiceRequestFormStateSetters {
  serviceRequestFormContents: ServiceRequestFormContent[];
  serviceRequestsCanBeSubmitted: boolean[];
  attemptedSubmit: boolean;
  patient?: Patient;
  userFaxExtension?: string;
  workflowId?: string;
  setFacilityOonCheckLoading: Dispatch<SetStateAction<boolean>>;
  setProviderOonCheckLoading: Dispatch<SetStateAction<boolean>>;
  setOrderingProviderOonCheckLoading: Dispatch<SetStateAction<boolean>>;
  delegatedVendorName?: string;
  serviceRequest?: ServiceRequestResponse;
  authorizationId?: string;
}

export function FillFormsContainer({
  attemptedSubmit,
  serviceRequestFormContents,
  setServiceRequestFormContents,
  serviceRequestsCanBeSubmitted,
  setServiceRequestFormsCanBeSubmitted,
  setServiceRequestFormsHaveNewEdits,
  patient,
  requestorFormAuthorized,
  userFaxExtension,
  workflowId,
  setFacilityOonCheckLoading,
  setProviderOonCheckLoading,
  setOrderingProviderOonCheckLoading,
  delegatedVendorName,
  serviceRequest,
  authorizationId,
  ...requestorProps
}: Props) {
  const containerClasses = useMuiContainerStyles();

  const { spacing } = useTheme();
  return (
    <Container classes={containerClasses} maxWidth="lg">
      {requestorFormAuthorized && (
        <div style={{ paddingTop: spacing(5) }}>
          <RequestorCard {...requestorProps} />
        </div>
      )}
      {serviceRequestFormContents?.map((content, index) => (
        <ServiceRequestFormWrapper
          content={content}
          setServiceRequestFormContents={setServiceRequestFormContents}
          attemptedSubmit={attemptedSubmit}
          setServiceRequestFormsCanBeSubmitted={setServiceRequestFormsCanBeSubmitted}
          setServiceRequestFormsHaveNewEdits={setServiceRequestFormsHaveNewEdits}
          patient={patient}
          index={index}
          key={index}
          userFaxExtension={userFaxExtension}
          workflowId={workflowId}
          setFacilityOonCheckLoading={setFacilityOonCheckLoading}
          setProviderOonCheckLoading={setFacilityOonCheckLoading}
          setOrderingProviderOonCheckLoading={setOrderingProviderOonCheckLoading}
        />
      ))}
    </Container>
  );
}

interface SRProps
  extends Omit<
    Props,
    | "buckets"
    | "requestorFormAuthorized"
    | "setRequestor"
    | "serviceRequestsCanBeSubmitted"
    | "serviceRequestFormContents"
  > {
  index: number;
  content: ServiceRequestFormContent;
  healthPlan?: string | null;
  userFaxExtension?: string;
  workflowId?: string;
  setFacilityOonCheckLoading: Dispatch<SetStateAction<boolean>>;
  setProviderOonCheckLoading: Dispatch<SetStateAction<boolean>>;
  setOrderingProviderOonCheckLoading: Dispatch<SetStateAction<boolean>>;
  isContinuation?: boolean;
}

export const useSRFormStyles = makeStyles((theme) => ({
  caption: {
    color: theme.palette.text.secondary,
  },
  card: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
  },
}));

function ServiceRequestFormWrapper({
  attemptedSubmit,
  content,
  setServiceRequestFormContents,
  setServiceRequestFormsCanBeSubmitted,
  setServiceRequestFormsHaveNewEdits,
  patient,
  index,
  userFaxExtension,
  workflowId,
  delegatedVendorName,
  setFacilityOonCheckLoading,
  setProviderOonCheckLoading,
  setOrderingProviderOonCheckLoading,
}: SRProps) {
  const setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>> = useCallback(
    (setStateAction: SetStateAction<ServiceRequestFormContent>) => {
      if (typeof setStateAction === "function") {
        return setServiceRequestFormContents((prev) => {
          const newSr = setStateAction(prev[index]);
          return listReplace(prev, index, newSr);
        });
      } else {
        return setServiceRequestFormContents((prev) => listReplace(prev, index, setStateAction));
      }
    },
    [setServiceRequestFormContents, index]
  );
  const setCanBeSubmitted = useCallback(
    (canBeSubmitted: boolean) =>
      setServiceRequestFormsCanBeSubmitted((prev) => listReplace(prev, index, canBeSubmitted)),
    [setServiceRequestFormsCanBeSubmitted, index]
  );
  const setHasNewEdits = useCallback(
    (hasNewEdits) => setServiceRequestFormsHaveNewEdits((prev) => listReplace(prev, index, hasNewEdits)),
    [setServiceRequestFormsHaveNewEdits, index]
  );

  const onUserEdit = useCallback(() => setHasNewEdits(true), [setHasNewEdits]);

  const classes = useSRFormStyles();

  const isOffCarePath = content.carePath?.id !== content?.carePathJourney?.carePath?.id;
  const clinicalService = content.clinicalService;

  const healthPlanName = getPatientHealthPlanName(patient, content.startDate) || "";
  const { formFieldConfigurations, outOfNetworkCheckConfiguration } = useServiceRequestConfigSpec({
    ...content,
    patientId: patient?.id || "",
    healthPlanName,
  });

  const { providerOonCheckData, providerOonCheckLoading, performedProviderOONCheck } =
    usePerformingProviderOutOfNetworkCheck({
      formFieldConfigurations,
      outOfNetworkCheckConfiguration,
      content,
      patient,
      workflowId,
      delegatedVendorName,
      setFormContent,
    });

  const { facilityOonCheckData, facilityOonCheckLoading, performedFacilityOONCheck } = useFacilityOutOfNetworkCheck({
    formFieldConfigurations,
    outOfNetworkCheckConfiguration,
    content,
    patient,
    workflowId,
    delegatedVendorName,
    setFormContent,
  });

  const { orderingProviderOonCheckData, orderingProviderOonCheckLoading, performedOrderingProviderOONCheck } =
    useOrderingProviderOutOfNetworkCheck({
      formFieldConfigurations,
      outOfNetworkCheckConfiguration,
      content,
      patient,
      workflowId,
      delegatedVendorName,
      setFormContent,
    });

  useEffect(() => {
    setFacilityOonCheckLoading(facilityOonCheckLoading);
    setProviderOonCheckLoading(providerOonCheckLoading);
    setOrderingProviderOonCheckLoading(orderingProviderOonCheckLoading);
  }, [
    facilityOonCheckLoading,
    providerOonCheckLoading,
    orderingProviderOonCheckLoading,
    setFacilityOonCheckLoading,
    setProviderOonCheckLoading,
    setOrderingProviderOonCheckLoading,
  ]);

  return (
    <Card className={classes.card} setPosition={false}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <H2>{clinicalService?.name || "Other Procedures"}</H2>
        </Grid>
        {!isOffCarePath && clinicalService?.description && (
          <Grid item xs={12}>
            <Caption className={classes.caption}>{clinicalService.description}</Caption>
          </Grid>
        )}
        <Grid item xs={12}>
          <ServiceRequestForm
            key={index}
            authStatus="DRAFT"
            formContent={content}
            setFormContent={setFormContent}
            formConfiguration={formFieldConfigurations}
            attemptedSubmit={attemptedSubmit}
            setCanBeSubmitted={setCanBeSubmitted}
            patient={patient}
            hideDiagnoses
            userFaxExtension={userFaxExtension}
            onUserEdit={onUserEdit}
            workflowId={workflowId}
            oonCheckLoading={providerOonCheckLoading || facilityOonCheckLoading}
            providerOonCheckData={providerOonCheckData}
            facilityOonCheckData={facilityOonCheckData}
            orderingProviderOonCheckData={orderingProviderOonCheckData}
            oonCheckFieldsFilled={
              performedProviderOONCheck || performedFacilityOONCheck || performedOrderingProviderOONCheck
            }
          />
        </Grid>
      </Grid>
    </Card>
  );
}
