import { makeStyles, Theme } from "@material-ui/core/styles";
import { isIE } from "../../util/envUtils";
import { colorsDark, colorsLight } from "../../themes";
import deepEqual from "fast-deep-equal";

interface AutocompleteStyleProps {
  markSelectedOptions?: boolean;
  isDefaultAll?: boolean;
  isAllSelected?: boolean;
}

export const useStylesAutocomplete = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  const colors = isDarkTheme ? colorsDark : colorsLight;
  const borderRadius = "5px";
  return {
    paper: {
      marginTop: 0 /* so it doesn't overlap with textField border */,
      border: `1px solid ${colors.gray.divider}`,
      borderRadius: borderRadius,
      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)",
      backgroundColor: isDarkTheme ? "#171D26" : colors.white.highEmphasis,
      color: isDarkTheme ? colors.white.highEmphasis : colors.font.main,
    },
    listbox: {
      padding: 0,
      transition: theme.transitions.create("height"),
    },
    option: {
      overflow: "hidden",
      borderTop: `1px solid ${colors.gray.divider}`,
      padding: ({ markSelectedOptions }: AutocompleteStyleProps) => theme.spacing(2, 2, 2, markSelectedOptions ? 5 : 2),
      "&:first-child": {
        borderTop: "none",
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
      },
      "&:last-child": {
        marginBottom: theme.spacing(-1),
        borderBottom: "none",
        borderBottomLeftRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
      },
      '&[aria-selected="true"]': {
        paddingLeft: ({ markSelectedOptions }: AutocompleteStyleProps) =>
          theme.spacing(markSelectedOptions ? 1 : 2) /* selected icon is present */,
      },
      '&[aria-selected="true"]&:not([data-focus="true"])': {
        backgroundColor: "inherit",
      },
      '&[data-focus="true"]': {
        backgroundColor: isDarkTheme ? colors.background.light : colors.gray.light,
      },
    },
    inputRoot: {
      "& .MuiInputBase-input::placeholder": {
        color: isDarkTheme ? colorsDark.font.secondary : colorsLight.font.light,
        opacity: 1,
      },
      '&[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
        padding: ({ isAllSelected }: AutocompleteStyleProps) => (isAllSelected ? 0 : undefined),
        minWidth: ({ isDefaultAll }: AutocompleteStyleProps) => (isDefaultAll ? 0 : undefined),
      },
    },
    hasClearIcon: {
      '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
        paddingRight: theme.spacing(1.5),
      },
    },
    withoutLabel: {
      '& [class*="MuiFilledInput-root"]': {
        paddingTop: 10,
        paddingBottom: 9,
      },
      "& .MuiInputAdornment-positionEnd": {
        top: 0,
      },
    },
    validationErrorColors: {
      "& .MuiFormLabel-root": {
        color: colorsDark.error.dark,
      },
      "& .MuiInputBase-root.Mui-error": {
        borderColor: colorsDark.error.dark,
      },
      "& .MuiFormHelperText-root": {
        color: colorsDark.error.dark,
      },
    },
    withLabelAndSelections: {
      '& [class*="MuiFilledInput-root"]': {
        paddingTop: 25,
        paddingBottom: 9,
      },
    },
  };
});

export const useStylesLookupEndAdornment = makeStyles((theme: Theme) => ({
  loadingSpinner: {
    position: "absolute",
    margin: "0 6px", // centers the spinner icon
  },
  iconButton: {
    color: theme.palette.type === "dark" ? colorsDark.gray.light : colorsLight.font.secondary,
    width: theme.spacing(4),
    height: theme.spacing(4),
    position: "absolute",
  },
  autoFilledIcon: {
    position: "absolute",
    top: theme.spacing(-2),
  },
  normalIcon: {
    color: theme.palette.type === "dark" ? "#6F7580" : colorsLight.font.light,
    width: theme.spacing(4),
    height: 20,
    position: "absolute",
  },
  largeIcon: {
    color: theme.palette.type === "dark" ? colorsDark.gray.light : colorsLight.font.light,
    width: theme.spacing(3),
    height: theme.spacing(3),
    position: "absolute",
  },
  endAdornment: {
    position: "relative",
    width: theme.spacing(4),
    top: isIE ? theme.spacing(-2.25) : theme.spacing(-1),
  },
}));

export const useStylesIcon = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return {
    root: {
      color: isDarkTheme ? theme.palette.primary.main : colorsLight.black.inactive,
      "&$positionStart&:not($hiddenLabel)": {
        marginTop: 0,
      },
    },
    positionStart: {},
    hiddenLabel: {},
  };
});

/*
 * The default values of getOptionSelected and getOptionLabel for
 * the autocomplete are bug-inducing in the general case.
 * That's particularly annoying because having these bad fallbacks implemented
 * means consumers can't rely on typescript to warn them about it!
 * These are fallbacks that at least do not cause runtime errors in the
 * consuming application
 */
export const defaultGetOptionSelected = <T extends unknown>(option: T, value: T) => deepEqual(option, value);
export const defaultGetOptionLabel = (option: unknown) =>
  typeof option === "string" ? option : JSON.stringify(option);

/**
 * The typical use case for the Mui Autocomplete is a scenario in which there is a static
 * list of options, and the component picks which options are a match by looking at the entered
 * value and string comparing it to the string label of the options.
 * This component's use case is a fundamentally dynamic set of options,
 * and so the default behavior should be that any option provided by the option hook
 * should be considered a match.
 */
export const defaultFilterOptions = <T extends unknown>(x: T) => x;

/**
 * If there is a label, then a 19px padding top is added to the input wrapper element
 * We need to correct for that to keep the endAdornment centered
 */
interface InputAdornmentStylesProps {
  hasLabel: boolean;
}
export const useStylesInputAdornment = makeStyles({
  root: {
    marginTop: ({ hasLabel }: InputAdornmentStylesProps) => (hasLabel ? -19 : 0),
    height: "100%",
  },
});

if (window.console && console.warn) {
  const originalWarn = console.warn;
  console.warn = function (...args: any[]) {
    const message = args[0];
    if (message && message.indexOf) {
      if (
        message.indexOf("Material-UI: The value provided to Autocomplete is invalid.\nNone of the options match") === 0
      ) {
        // The autocomplete has an annoying warning we can't suppress https://github.com/mui-org/material-ui/issues/18514
        // Cut it down here so it doesn't spam the console.
        return;
      }
    }
    originalWarn.apply(console, args as any);
  };
}
