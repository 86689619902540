import React, {
  forwardRef,
  MutableRefObject,
  RefCallback,
  useCallback,
  useEffect,
  useState,
  useRef,
  Dispatch,
} from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import classnames from "classnames";
import debounce from "lodash/debounce";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

import { Caption } from "../Typography";
import { useStylesAutocomplete } from "./commonAutocomplete";
import { SelectOptionsHook } from "./SelectOptionsHook";

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement, any>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const InnerElementType = React.forwardRef<HTMLUListElement, any>(({ style, ...rest }, ref) => (
  <ul ref={ref} style={{ ...style, marginBottom: "0", marginTop: "0" }} {...rest} />
));

const useStylesLazyLoadingOptions = makeStyles((theme) => ({
  loadingRowSpinner: {
    right: theme.spacing(2),
    position: "absolute",
  },
}));

const MAX_LIST_HEIGHT = 300;
export const LOAD_MORE_BATCH_SIZE = 10;
export const DEFAULT_OPTION_HEIGHT = 56;

export function useLoadMoreItems<T>(query: string, refetchOptions: ReturnType<SelectOptionsHook<T>>["refetch"]) {
  return useCallback(
    (startIndex: number) => {
      return new Promise<void>((resolve) => {
        refetchOptions
          ? refetchOptions({ queryParams: { offset: startIndex, max: LOAD_MORE_BATCH_SIZE, query } }).then(() =>
              resolve()
            )
          : resolve();
      });
    },
    [query, refetchOptions]
  );
}

// https://www.davedrinks.coffee/how-do-i-use-two-react-refs/
function mergeRefs<T>(...refs: Array<MutableRefObject<T> | RefCallback<T> | null>) {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) {
    return null;
  }
  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
}

const recalculateListHeight = debounce(
  (currentRowHeights: Record<number, number>, currentListHeight: number, setListHeight: Dispatch<number>) => {
    const newListHeight = Object.entries(currentRowHeights).reduce(
      (totalHeight, [_, rowHeight]) => totalHeight + rowHeight,
      0
    );

    // Sometimes we get in a feedback loop where small changes in the list height lead to individual item client
    // heights changing, which changes the list height, etc. having a small delta here seems to fix that
    if (Math.abs(currentListHeight - newListHeight) > 2) {
      setListHeight(newListHeight);
    }
  },
  100
);

export interface Props {
  numOptions: number;
  loadMoreItems: (startIndex: number, stopIndex: number) => Promise<void> | void;
  isNextPageLoading: boolean;
  hasNextPage: boolean;
  markSelectedOptions?: boolean;
  query?: string;
}

// Generic Types and Higher Order Functions (i.e. forwardRef) do not play nice
// To get around this we can wrap this component in a closure and declare the type on th econ
const LazyLoadingOptions = forwardRef<HTMLDivElement, Props>(
  (
    { numOptions, loadMoreItems, isNextPageLoading, hasNextPage, children, markSelectedOptions, query, ...otherProps },
    ref
  ) => {
    const itemData = React.Children.toArray(children);

    // References used to calculate the height of the list
    const virtualListRef = useRef<VariableSizeList>();
    const innerListRef = useRef<HTMLUListElement>(null);
    const outerListRef = useRef<HTMLElement>(null);
    const rowHeights = useRef<Record<number, number>>({});

    useEffect(() => {
      setTimeout(() => {
        outerListRef.current?.scrollTo(0, 0);
      }, 0);
    }, [query]);

    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const itemCount = hasNextPage || (numOptions === 0 && isNextPageLoading) ? numOptions + 1 : numOptions;

    // storing the list height in a state so we can force a re-render (just changing the ref won't necessarily do that)
    const [realListHeight, setRealListHeight] = useState(0);

    const listHeight = Math.min(realListHeight, MAX_LIST_HEIGHT);

    // getters and setters for individual item heights
    const getItemHeight = (index: number) => rowHeights.current[index] || DEFAULT_OPTION_HEIGHT;
    const setItemHeight = (index: number, size: number) => {
      virtualListRef.current?.resetAfterIndex(0); // This forces the list to repaint items when their height changes
      rowHeights.current = { ...rowHeights.current, [index]: size };
      recalculateListHeight(rowHeights.current, realListHeight, setRealListHeight);
    };

    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = useCallback((index: number) => !hasNextPage || index < numOptions, [hasNextPage, numOptions]);

    const { option: optionClass } = useStylesAutocomplete({ markSelectedOptions });
    const lazyLoadingClasses = useStylesLazyLoadingOptions();

    const { spacing } = useTheme();

    function Row({ data, index, style }: ListChildComponentProps) {
      // These refs allow us to programmatically recalculate heights of list items
      // Each list item has an inner div that is height: "auto", so it expands to fit the content.
      // Once the height is calculated for the inner div (when mounted in the DOM), if it exceeds the height of the
      // fixed-height container (minus vertical padding), then we'll set the height of the outer container to match it
      // Adapted from https://medium.com/@tiagohorta1995/dynamic-list-virtualization-using-react-window-ab6fbf10bfb2
      const outerListItemRef = useRef<HTMLLIElement>(null);
      const innerListItemRef = useRef<HTMLDivElement>(null);
      useEffect(() => {
        if (outerListItemRef.current && innerListItemRef.current) {
          // Sets the height of the containing <li> to match the height of the inner div (which expands to fit content)
          setItemHeight(index, innerListItemRef.current.clientHeight + spacing(4));
        }
        return () => {
          recalculateListHeight.cancel();
        };
        // eslint-disable-next-line
      }, [outerListItemRef, innerListItemRef]);

      // This is the "Loading" row
      if (!isItemLoaded(index)) {
        return (
          <li ref={outerListItemRef} className={classnames(optionClass, "MuiAutocomplete-option")} style={style}>
            <div style={{ height: "auto" }} ref={innerListItemRef}>
              <Caption>Loading additional results</Caption>
              <CircularProgress size={16} color="inherit" className={lazyLoadingClasses.loadingRowSpinner} />
            </div>
          </li>
        );
      }

      if (!data[index]) {
        return null;
      }

      return React.cloneElement(
        data[index],
        {
          style: {
            ...style,
          },
          ref: outerListItemRef,
        },
        // Wrap the children of the list item in their own div that expands to fit the height. This is used to
        // recalculate the absolute height of the list item
        <div ref={innerListItemRef} key="innerDiv" style={{ height: "auto", display: "flex" }}>
          {data[index].props.children}
        </div>
      );
    }

    // Only load 1 page of items at a time. Passes an empty callback if pages are currently loading
    const loadMoreItemsInternal = isNextPageLoading ? () => Promise.resolve() : loadMoreItems;

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={otherProps}>
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItemsInternal}
            threshold={1}
          >
            {({ onItemsRendered, ref: infiniteLoaderListRef }) => (
              <VariableSizeList
                itemData={itemData}
                itemCount={itemCount}
                onItemsRendered={onItemsRendered}
                outerElementType={OuterElementType}
                innerElementType={InnerElementType}
                innerRef={innerListRef}
                outerRef={outerListRef}
                ref={mergeRefs(virtualListRef, infiniteLoaderListRef)}
                height={listHeight}
                width="100%"
                itemSize={getItemHeight}
              >
                {Row}
              </VariableSizeList>
            )}
          </InfiniteLoader>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

export default LazyLoadingOptions;
