import { FormConfigurationFieldSpec, Patient } from "@coherehealth/core-platform-api";
import { getCoverageBasedOnDate } from "util/patientUtils";

export type ReferralRequestFieldNames =
  | "startDate"
  | "primarySemanticDiagnosisCode"
  | "secondarySemanticDiagnosisCodes"
  | "selectedReferringProvider"
  | "referringProviderNPI"
  | "referringProviderTIN"
  | "referringProviderAddress"
  | "specialty"
  | "selectedFacility"
  | "selectedPerformingSpecialist"
  | "outOfNetworkCheck"
  | "outOfNetworkFacility"
  | "outOfNetworkPerformingSpecialist"
  | "facilityOutOfNetworkStatusDisplay"
  | "facilityAddress"
  | "facilityTIN"
  | "facilityNPI"
  | "performingSpecialistOutOfNetworkStatusDisplay"
  | "performingSpecialistAddress"
  | "performingSpecialistTIN"
  | "performingSpecialistNPI"
  | "selectedPerformingSpecialistOutOfNetworkExceptionReason"
  | "userSelectedOONExceptionAttestation";

export type ReferralFormConfiguration = {
  [key in ReferralRequestFieldNames]: { fieldSpec: FormConfigurationFieldSpec };
};

export function useGetReferralRequestFormConfiguration(
  patient: Patient | null,
  isAdministrative: boolean
): ReferralFormConfiguration | null {
  // Determine active coverage based on today's date.
  const asOfToday = new Date();
  const coveragePlan = patient && getCoverageBasedOnDate(asOfToday, patient);
  const DEFAULT_RR_CONFIG_SPEC: ReferralFormConfiguration = {
    startDate: { fieldSpec: "REQUIRED" },
    primarySemanticDiagnosisCode: { fieldSpec: "REQUIRED" },
    secondarySemanticDiagnosisCodes: { fieldSpec: "OPTIONAL" },
    selectedReferringProvider: { fieldSpec: "REQUIRED" },
    referringProviderNPI: { fieldSpec: "REQUIRED" },
    referringProviderTIN: { fieldSpec: "REQUIRED" },
    referringProviderAddress: { fieldSpec: "REQUIRED" },
    specialty: { fieldSpec: "REQUIRED" },
    selectedFacility: { fieldSpec: "REQUIRED" },
    facilityAddress: { fieldSpec: "REQUIRED" },
    facilityTIN: { fieldSpec: "REQUIRED" },
    facilityNPI: { fieldSpec: "REQUIRED" },
    selectedPerformingSpecialist: { fieldSpec: "OPTIONAL" },
    performingSpecialistAddress: { fieldSpec: "OPTIONAL" },
    performingSpecialistTIN: { fieldSpec: "OPTIONAL" },
    performingSpecialistNPI: { fieldSpec: "OPTIONAL" },
    outOfNetworkCheck: { fieldSpec: "REQUIRED" },
    outOfNetworkFacility: { fieldSpec: "REQUIRED" },
    outOfNetworkPerformingSpecialist: { fieldSpec: "OPTIONAL" },
    facilityOutOfNetworkStatusDisplay: { fieldSpec: "REQUIRED" },
    performingSpecialistOutOfNetworkStatusDisplay: { fieldSpec: "OPTIONAL" },
    selectedPerformingSpecialistOutOfNetworkExceptionReason: { fieldSpec: "OPTIONAL" },
    userSelectedOONExceptionAttestation: { fieldSpec: "OPTIONAL" },
  };

  const isOakStreetHMO =
    coveragePlan?.riskBearingEntity === "Oak Street Health" && coveragePlan.coverageProductType === "HMO";

  const isBCHMO = coveragePlan?.healthPlanName === "BCBS South Carolina" && coveragePlan.coverageProductType === "HMO";

  if (isAdministrative) {
    return {
      ...DEFAULT_RR_CONFIG_SPEC,
      primarySemanticDiagnosisCode: { fieldSpec: "OMIT" },
      secondarySemanticDiagnosisCodes: { fieldSpec: "OMIT" },
    };
  } else if (isBCHMO || isOakStreetHMO) {
    return {
      ...DEFAULT_RR_CONFIG_SPEC,
      facilityTIN: { fieldSpec: "OMIT" },
      selectedPerformingSpecialist: { fieldSpec: "REQUIRED" },
      performingSpecialistAddress: { fieldSpec: "REQUIRED" },
      performingSpecialistTIN: { fieldSpec: "OMIT" },
      performingSpecialistNPI: { fieldSpec: "REQUIRED" },
    };
  } else {
    return null;
  }
}
