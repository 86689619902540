import { useEffect, useCallback, useState } from "react";

import { UseGetProps, UseGetReturn } from "restful-react";
import { SelectOptionsHook } from "./SelectOptionsHook";

type SelectOptionsHookParams<T> = Parameters<SelectOptionsHook<T>>[0];
type OptionsType<T> = ReturnType<SelectOptionsHook<T>>["options"];
export type OptionsDecorator<T> = (loadedOptions: OptionsType<T>, hasMoreOptions: boolean) => OptionsType<T>;

export interface LazyLoadingGetProps {
  query: string;
  offset?: number;
  max?: number;
}

interface Props<TData extends { id?: string } | null | undefined, TError, TGetHookProps>
  extends SelectOptionsHookParams<TData> {
  /** The restful-react get hook to use */
  useGetHook: (
    arg0: Omit<UseGetProps<TData[], unknown, TGetHookProps, void>, "path">
  ) => UseGetReturn<TData[], TError, TGetHookProps>;
  /** Additional query params to use in the GET request */
  additionalQueryParams?: object;
  optionsDecorator?: OptionsDecorator<TData>;
  reloadData?: boolean;
  onReloadData?: () => void;
  toGetHookProps?: (expectedProps: LazyLoadingGetProps) => TGetHookProps;
}

export function defaultToGetHookProps<TGetProps extends LazyLoadingGetProps>(
  queryParams: LazyLoadingGetProps
): TGetProps {
  return queryParams as TGetProps;
}

/**
 * Boilerplate code for a SelectOptionsHook that lazy-loads additional data while scrolling
 * use it like this
 * const useReferenceDataOptions = (selectOptionsParams) => useLazyLoadingGetQueryOptionsHook({ useGetHook: useGetReferenceData, ...selectOptionsParams });
 *
 * @param useGetHook The GET hook to use
 * @param additionalQueryParams Any additional query params (besides query, offset, or max)
 * @param optionsDecorator A function that does stuff with the loaded options array
 * @param query Should be spread from SelectOptionsHook params
 * @param offset Should be spread from SelectOptionsHook params
 * @param max Should be spread from SelectOptionsHook params
 * @param onError Should be spread from SelectOptionsHook params
 * @param reloadData When true, clears existing data on next load
 * @param onReloadData Callback which is called after data is cleared
 * @param toGetHookProps A function that maps TGetHookProps to LazyLoadingGetProps (optional)
 */
export function useLazyLoadingQueryOptionsHook<
  TData extends { id?: string } | null | undefined,
  TError,
  TGetHookProps = LazyLoadingGetProps
>({
  useGetHook,
  additionalQueryParams = {},
  optionsDecorator,
  query,
  offset,
  max,
  onError,
  reloadData,
  onReloadData,
  toGetHookProps,
}: Props<TData, TError, TGetHookProps>): ReturnType<SelectOptionsHook<TData>> {
  const massageProps = toGetHookProps || defaultToGetHookProps;
  const queryParams = massageProps({ query: query || "", offset, max, ...additionalQueryParams }) as TGetHookProps;

  // Make the REST GET call for option data given a query
  const {
    data,
    loading,
    error,
    refetch: refetchInternal,
  } = useGetHook({
    debounce: 300,
    queryParams,
  });

  // Handle errors
  useEffect(() => {
    if (error) {
      onError(error);
    }
  }, [error, onError]);

  // Handle fetching more data, we want to persist any additionalQueryParams passed in here
  const refetch: ReturnType<SelectOptionsHook<TData>>["refetch"] = useCallback(
    (refetchParams) => {
      if (!refetchParams) {
        return Promise.resolve(null);
      }
      const { queryParams: passthroughQueryParams } = refetchParams; // This will have the offset/max/query params needed to load more data
      return refetchInternal({ queryParams: { ...passthroughQueryParams, ...additionalQueryParams } });
    },
    [additionalQueryParams, refetchInternal]
  );

  // As we load more options, we still need to keep track of the already loaded options from previous queries
  // We manage that state here w/ a few side effects
  const [loadedOptions, setLoadedOptions] = useState<TData[]>([]);
  useEffect(() => {
    // load new data into loadedOptions state
    const isInitialLoad = loadedOptions.length === 0;
    const newOptionsLoaded = data?.[data.length - 1]?.id !== loadedOptions[loadedOptions.length - 1]?.id;
    if (data && (isInitialLoad || newOptionsLoaded)) {
      const allOptions = [...loadedOptions, ...data];
      if (reloadData && onReloadData) {
        setLoadedOptions(data);
        onReloadData();
      } else {
        setLoadedOptions(allOptions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    // clear loaded options when query changes
    setLoadedOptions([]);
  }, [query]);

  return {
    options: optionsDecorator ? optionsDecorator(loadedOptions, data?.length === max) : loadedOptions,
    optionsLoading: loading,
    refetch,
    hasMoreOptions: data?.length === max,
  };
}
