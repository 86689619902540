// TODO: implement
import { TextField } from "@coherehealth/common";
import { makeStyles, useTheme } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";

import { ReferralRequestSearchRequestBody } from "@coherehealth/core-platform-api";

interface Props {
  queryParams: ReferralRequestSearchRequestBody;
  setQueryParams: Dispatch<SetStateAction<ReferralRequestSearchRequestBody>>;
}

const useStyles = makeStyles((theme) => ({
  searchAdornment: {
    marginLeft: -9,
  },
  clearAdornment: {
    marginRight: -9,
  },
}));

export default function ReferralRequestSearchBar({ queryParams, setQueryParams }: Props) {
  const [searchText, setSearchText] = useState(queryParams.query || "");

  const searchReferralRequests = (searchText: string) => {
    setQueryParams({ ...queryParams, query: `${searchText}` });
  };

  const onClearSearch = () => {
    setSearchText("");
    searchReferralRequests("");
  };

  const classes = useStyles();
  const theme = useTheme();

  return (
    <TextField
      placeholder={"Search (Patient name, Member ID, Referral ID)"}
      style={{ marginBottom: theme.spacing(5) }}
      value={searchText}
      onChange={(e) => {
        if (searchText !== e.target.value) {
          setSearchText(e.target.value);
          searchReferralRequests(e.target.value);
        }
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          searchReferralRequests(searchText);
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment className={classes.searchAdornment} position={"start"}>
            <IconButton
              aria-label="search"
              onClick={() => {
                searchReferralRequests(searchText);
              }}
            >
              <Search />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment className={classes.clearAdornment} position="end">
            {searchText && (
              <IconButton aria-label="clear" onClick={onClearSearch}>
                <Clear />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    ></TextField>
  );
}
