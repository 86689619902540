import {
  Body2,
  InlineButton,
  POLICY_UNITED_STATES_OPTIONS,
  ReviewTimeDateAuthorCaption,
  parseDateFromISOStringWithoutFallback,
  useFeature,
} from "@coherehealth/common";
import { Coverage, MdReview, PeerToPeerReview, ServiceRequestResponse } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Grid from "@material-ui/core/Grid";
import { generatePath } from "react-router-dom";
import routes from "routes";
import { InformativeModal } from "@coherehealth/common";
import ClipboardWarningIcon from "components/images/ClipboardWarningIcon";
import { useState } from "react";
import { getCoverageBasedOnDate, getSortedListOfCoverages } from "../../../../util/patientUtils";

interface Props {
  review: MdReview | PeerToPeerReview;
  serviceRequest: ServiceRequestResponse;
}

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(2),
  },
  captionContainer: {
    display: "inline-block",
    flexDirection: "row",
    marginTop: theme.spacing(1),
  },
}));

//This set will change annually. Goal is to have a better workflow in place by next year; otherwise move this to the backend
export const licenseDependentStates = ["AZ", "GA", "MS", "NC", "ND", "OK", "OR"];

export default function DenialLetterSummaryCard({ review, serviceRequest }: Props) {
  const classes = useStyles();
  const showDenialsInTheSameWindow = useFeature("showDenialsInTheSameWindow");
  const showLicensePopup = useFeature("showLicensePopup");
  const [licensedModalOpen, setLicensedModalOpen] = useState<boolean>(false);
  const [licensedState, setLicensedState] = useState<string>();
  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={8}>
        <Body2 className={classes.header}>Denial Letter</Body2>
        <div className={classes.captionContainer}>
          <ReviewTimeDateAuthorCaption
            reviewDateCreated={review?.dateCreated || ""}
            reviewLastUpdated={review?.lastUpdated || ""}
            reviewCreatedByName={review?.createdByName || ""}
            firstLineText="Last edited on "
            useUpdatedDate
          />
        </div>
      </Grid>
      <ViewDenialsButtonContainer item xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
        <InlineButton
          onClick={async () => {
            const isLicenseNeeded = licenseDependentStates.find(
              (state) =>
                state ===
                getCoverageBasedOnDate(
                  parseDateFromISOStringWithoutFallback(serviceRequest?.startDate),
                  serviceRequest?.patient
                )?.stateOfIssue
            );
            const reviewStatus =
              serviceRequest.authStatus === "RECOMMENDED_DENIAL" ||
              serviceRequest.authStatus === "RECOMMENDED_PARTIAL_APPROVAL";
            if (showLicensePopup && serviceRequest?.lobType === "Commercial" && isLicenseNeeded && reviewStatus) {
              const coverages = getSortedListOfCoverages(serviceRequest.patient);
              let activePrimaryCoverage: Coverage | null | undefined = null;
              if (coverages?.[0]?.planActive) {
                //If the first coverage in the sorted list is active, then that is the primary coverage
                // we'll then set it aside from the other (non-primary) coverages and display them separately
                activePrimaryCoverage = coverages.shift();
                if (activePrimaryCoverage) {
                  POLICY_UNITED_STATES_OPTIONS?.forEach((opt) => {
                    if (opt.id === activePrimaryCoverage?.stateOfIssue) {
                      setLicensedState(opt.label);
                    }
                  });
                }
                setLicensedModalOpen(true);
              }
            } else {
              showDenials(showDenialsInTheSameWindow, serviceRequest, review);
            }
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <VisibilityIcon />
            <ViewDenialButtonText>View denial letter</ViewDenialButtonText>
          </div>
        </InlineButton>
        <InformativeModal
          headerText={"Is " + review?.createdByName + " licensed in " + licensedState + "?"}
          subHeaderText={
            "The last review author must be licenced in the applicable state before sending a final determination letter."
          }
          icon={<ClipboardWarningIcon />}
          primaryButtonText="Confirm license"
          primaryButtonAction={() => {
            showDenials(showDenialsInTheSameWindow, serviceRequest, review);
          }}
          tertiaryButtonText={"Not licensed, go back"}
          tertiaryButtonAction={() => setLicensedModalOpen(false)}
          showDivider
          open={licensedModalOpen}
          onClose={() => setLicensedModalOpen(false)}
        />
      </ViewDenialsButtonContainer>
    </Grid>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ViewDenialButtonText = styled("span")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ViewDenialsButtonContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const showDenials = (
  showDenialsInTheSameWindow: boolean,
  serviceRequest: ServiceRequestResponse,
  review: MdReview | PeerToPeerReview
) => {
  if (showDenialsInTheSameWindow) {
    window.location.assign(
      generatePath(routes.DENIAL_REVIEW, {
        serviceRequestId: serviceRequest?.id,
      })
    );
  } else {
    window.open(
      generatePath(routes.DENIAL_REVIEW, {
        serviceRequestId: serviceRequest?.id,
      }),
      "_blank",
      "noopener"
    );
  }
};
