import React from "react";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { Body1, Sanitized } from "@coherehealth/common";
import { HmoExceptionReview } from "@coherehealth/core-platform-api";
import { Label } from "components/ServiceRequest/ReadonlyDetail/FormElements";

interface Props {
  hmoExceptionReview: HmoExceptionReview;
  hideDecisionReasoning?: boolean;
}

export default function HmoExceptionReviewReadOnly({ hmoExceptionReview, hideDecisionReasoning }: Props) {
  return (
    <Grid container spacing={2}>
      {!hideDecisionReasoning && (
        <Grid item xs={12}>
          <Label>Decision reasoning</Label>
          <Body1 style={{ marginTop: -16, marginBottom: -16 }}>
            <Sanitized __html={hmoExceptionReview?.decisionReasoning || "- -"} />
          </Body1>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Label>Review outcome</Label>
        <Body1>
          {hmoExceptionReview.reviewStatus === "DISCARDED"
            ? "Discarded"
            : ReviewOutcomes[hmoExceptionReview.reviewOutcome || ""]}
        </Body1>
      </Grid>
    </Grid>
  );
}

const ReviewOutcomes: Record<string, string> = {
  APPROVED: "Approved",
  RECOMMEND_DENIAL: "Recommend Denial",
};
