import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MUIChip, { ChipProps } from "@material-ui/core/Chip";
import { Clear, SvgIconComponent } from "@material-ui/icons";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { colorsLight } from "../../themes";

type ChipSize = "small" | "large";
export type ChipType = "default" | "success" | "warning" | "error" | "disabled" | "neutral";

interface StyleProps {
  size?: ChipSize;
  type: ChipType;
  translucent: boolean;
  noHover?: boolean;
  Icon?: SvgIconComponent;
}

const useStyles = makeStyles((theme) => ({
  root: ({ size, type, translucent }: StyleProps) => ({
    backgroundColor: translucent ? alpha(theme.palette.primary.main, 0) : getBackgroundColor(type, theme),
    color: translucent ? "" : getColor(type, theme),
    height: translucent ? "auto" : theme.spacing(size === "small" ? 3 : 4),
    ...theme.typography.body1,
    paddingRight: translucent ? 0 : theme.spacing(2),
    paddingLeft: translucent ? 0 : theme.spacing(2),
    "& .MuiChip-label": {
      color: `${translucent ? "" : getColor(type, theme)} !important`,
    },
  }),
  clickable: ({ type, noHover }: StyleProps) => ({
    "&:hover, &:focus": {
      backgroundColor: noHover ? getBackgroundColor(type, theme) : alpha(getColor(type, theme), 0.21),
    },
    "&:active": {
      boxShadow: "none",
    },
  }),
  label: {
    padding: 0,
    height: 19,
  },
  deletable: ({ type }: StyleProps) => ({
    "&:focus": {
      backgroundColor: alpha(getColor(type, theme), 0.21),
    },
  }),
  avatar: {},
  avatarColorPrimary: {},
  disabled: {},
}));

const deleteStyles = makeStyles((theme) => ({
  root: {
    color: ({ type }: StyleProps) => getColor(type, theme),
    width: "15px",
    height: "15px",
    marginLeft: theme.spacing(1),
    marginRight: 0,
    order: 1,
    "&:hover": {
      color: ({ type }: StyleProps) => alpha(getColor(type, theme), 0.8),
    },
  },
}));

const getBackgroundColor = (type: ChipType, theme: Theme) => {
  switch (type) {
    case "default":
      return alpha(theme.palette.primary.main, 0.1);
    case "success":
      return theme.palette.type === "dark" ? alpha(theme.palette.success.main, 0.1) : theme.palette.success.light;
    case "warning":
      return theme.palette.type === "dark" ? alpha(theme.palette.warning.main, 0.1) : theme.palette.warning.light;
    case "error":
      return theme.palette.type === "dark" ? alpha(theme.palette.error.main, 0.1) : theme.palette.error.light;
    case "disabled":
      return colorsLight.gray.inactive;
    case "neutral":
      return colorsLight.background.panel;
  }
};

const getColor = (type: ChipType, theme: Theme) => {
  switch (type) {
    case "default":
      return theme.palette.primary.dark;
    case "success":
      return theme.palette.type === "dark" ? theme.palette.success.main : theme.palette.success.dark;
    case "warning":
      return theme.palette.type === "dark" ? theme.palette.warning.main : theme.palette.warning.dark;
    case "error":
      return theme.palette.type === "dark" ? theme.palette.error.main : theme.palette.error.dark;
    case "disabled":
      return colorsLight.font.secondary;
    case "neutral":
      return colorsLight.font.secondary;
  }
};

interface LogRocketProps {
  dataPublic?: boolean; //this determines if the chip contents will be visible within a LogRocket recording
}

const noop = () => {};

const Chip = React.forwardRef<HTMLDivElement, Omit<ChipProps, "css"> & Partial<StyleProps> & Partial<LogRocketProps>>(
  (props, ref) => {
    const { classes, onClick, onDelete, disabled, size, type, translucent, noHover, Icon, dataPublic, ...otherProps } =
      props;

    // Translucent optional prop makes chips smaller and same color as background

    const sizeWithFallback = size || "large";
    const typeWithFallback = type || "default";
    const translucentWithFallback = translucent || false;
    const chipClasses = useStyles({
      size: sizeWithFallback,
      type: typeWithFallback,
      translucent: translucentWithFallback,
      noHover: noHover,
    });
    const deleteClasses = deleteStyles({ type: type || "default", translucent: translucentWithFallback });

    // MUI doesn't prevent keyboard triggers for click/delete when disabled, so override those with noops here
    let onClickFn = disabled && onClick ? noop : onClick;
    let onDeleteFn = disabled && onDelete ? noop : onDelete;
    const logRocketProps = dataPublic ? { "data-public": true } : {};
    return (
      <MUIChip
        ref={ref}
        classes={{ ...chipClasses, ...classes }}
        disabled={disabled}
        onClick={onClickFn}
        onDelete={onDeleteFn}
        {...logRocketProps}
        {...otherProps}
        deleteIcon={<Clear classes={deleteClasses} />}
        icon={Icon && <Icon classes={deleteClasses} />}
      />
    );
  }
);
Chip.displayName = "Chip";

export default Chip;
