import { OnRotateCallback } from ".";
import React, { useState, Dispatch, SetStateAction } from "react";
import DocumentNavigator from "./DocumentNavigator";
import { ReactNode } from "react";
import Draggable from "react-draggable";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, Grid, makeStyles } from "@material-ui/core";
import { AttachmentViewerSidePanel, colorsLight, AttachmentInfo } from "@coherehealth/common";
import { Attachment } from "@coherehealth/core-platform-api";

interface Props {
  url: string;
  name?: string;
  onRotate?: OnRotateCallback;
  rotation?: number[];
  crrStyling?: boolean;
  sidePanel?: ReactNode;
  attachments?: Attachment[];
  openSidePanel?: boolean;
  setOpenSidePanel?: Dispatch<SetStateAction<boolean>>;
  handleAttachmentClick?: (index: number, landingPage?: number) => void;
  attachmentIndexOpen?: number;
  withDocSegmentedSidePanel?: boolean;
  attachmentsInfo?: AttachmentInfo[];
}

const ROTATION_DEGREES = [0, 270, 180, 90];

export default function ImageViewer({
  url,
  name,
  onRotate,
  rotation,
  crrStyling,
  sidePanel,
  openSidePanel,
  setOpenSidePanel,
  attachments,
  attachmentIndexOpen,
  handleAttachmentClick,
  withDocSegmentedSidePanel,
  attachmentsInfo,
}: Props) {
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState((rotation && rotation[0]) || 0);
  const crrClasses = useCrrStyles();

  const doSetRotate = (r: number) => {
    setRotate(r);
    onRotate && onRotate([r]);
  };

  return crrStyling ? (
    <Grid container direction="column" className={crrClasses.imageViewerRoot}>
      <div style={{ height: 38 }}>
        <DocumentNavigator
          currentPage={1}
          totalPages={1}
          scale={scale}
          setScale={setScale}
          rotate={rotate}
          setRotate={doSetRotate}
          rotateDegrees={ROTATION_DEGREES}
          crrStyling={crrStyling}
          openSidePanel={openSidePanel}
          setOpenSidePanel={setOpenSidePanel}
        />
      </div>
      <Grid container>
        <Grid item xs={4} container className={crrClasses.sidePanelContainer}>
          {attachments && handleAttachmentClick && (
            <AttachmentViewerSidePanel
              attachments={attachments}
              handleAttachmentClick={handleAttachmentClick}
              openSidePanel={openSidePanel}
              attachmentIndexOpen={attachmentIndexOpen}
              fromImageViewer
              withDocSegmentedSidePanel={withDocSegmentedSidePanel}
              attachmentsInfo={attachmentsInfo}
            />
          )}
        </Grid>

        <Grid item xs={8}>
          <DocumentWrapper>
            <Draggable>
              <div>
                <img
                  style={{
                    transform: `rotate(${rotate}deg) scale(${scale}, ${scale})`,
                    maxHeight: "100%",
                    maxWidth: "100%",
                  }}
                  src={url}
                  alt={name || "unknown"}
                />
              </div>
            </Draggable>
          </DocumentWrapper>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <>
      <DocumentWrapper>
        <Draggable>
          <div>
            <img
              style={{
                transform: `rotate(${rotate}deg) scale(${scale}, ${scale})`,
                maxHeight: "100%",
                maxWidth: "100%",
              }}
              src={url}
              alt={name || "unknown"}
            />
          </div>
        </Draggable>
      </DocumentWrapper>
      <DocumentNavigator
        currentPage={1}
        totalPages={1}
        scale={scale}
        setScale={setScale}
        rotate={rotate}
        setRotate={doSetRotate}
        rotateDegrees={ROTATION_DEGREES}
        crrStyling={crrStyling}
      />
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DocumentWrapper = styled("div")(({ theme }) => ({
  overflow: "auto",
  position: "relative",
  height: "100%",
  width: "100%",
  background: colorsLight.background.light,
  "& .react-draggable": {
    cursor: "grab",
    margin: "auto",
    marginTop: "5%",
    marginBottom: "15%",
    "&.react-draggable-dragging": {
      cursor: "grabbing",
    },
  },
}));

const useCrrStyles = makeStyles((theme) => ({
  imageViewerRoot: {
    borderRight: `1px solid ${colorsLight.gray.divider}`,
  },
  sidePanelContainer: {
    height: "calc(100vh - 180px)",
  },
}));
