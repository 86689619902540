import { AppealPayload, ServiceRequestResponse, useCreateAppeal } from "@coherehealth/core-platform-api";
import { AppealNoteFormAction, AppealNoteFormState } from "./AppealNote";
import { useContext, useState } from "react";
import { Checkbox, colorsLight, InformativeModal, SingleSelectDropdown, useConfiguration } from "@coherehealth/common";
import usePatientStayDateOnCRR from "components/ServiceRequest/PatientStay/usePatientStayDateOnCRR";
import { useProcedureCodesState } from "components/ServiceRequest/ReviewSection/util/useProcedureCodesState";
import { Box, Grid } from "@material-ui/core";
import PartialApprovalFlexibleCodes from "components/ServiceRequestStatusDisplay/PartialApproval/PartialApprovalFlexibleCodes";
import PatientStays, { deAggregateStayDates } from "components/ServiceRequest/PatientStay/PatientStays";
import elementIsNonNull from "util/elementIsNonNull";
import { ClinicalReviewContext } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { ReviewOutcomeOption } from "components/ServiceRequest/ReviewSection/util/QueueManagementReviewUtil";
import { useSnackbar } from "notistack";
import { error as logError } from "logger";
import { navigateToPS } from "components/AuthBuilder/common";
import { useNavigate } from "react-router";

interface AppealNoteSubmissionModalProps {
  openSubmissionModal: boolean;
  setOpenSubmissionModal: React.Dispatch<React.SetStateAction<boolean>>;
  serviceRequest: ServiceRequestResponse;
  appealNoteFormState: AppealNoteFormState;
  updateAppealNoteFormState: React.Dispatch<AppealNoteFormAction>;
}

const validateAppealNoteSubmissionModal = (
  state: AppealNoteFormState,
  isStayDatesValid: boolean,
  isPxCodesValid: boolean
) => {
  const validStayDates = ["APPROVED", "PARTIALLY_APPROVED"].includes(state.postAppealOutcome) ? isStayDatesValid : true;
  const validPxCodes = ["APPROVED", "PARTIALLY_APPROVED"].includes(state.postAppealOutcome) ? isPxCodesValid : true;
  return Boolean(state.postAppealOutcome) && state.attestationOfRequiredFileUpload && validStayDates && validPxCodes;
};

export const AppealNoteSubmissionModal = ({
  openSubmissionModal,
  setOpenSubmissionModal,
  serviceRequest,
  appealNoteFormState,
  updateAppealNoteFormState,
}: AppealNoteSubmissionModalProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { startDate, healthPlanName, encounterType, patientStatus, clinicalServices, units } = serviceRequest;
  const { enabled: facilityBasedConfigEnabled, levelOfCareIsMandatoryOnReviews } =
    useConfiguration("facilityBasedRequestConfiguration", healthPlanName) ?? {};
  const isConfigEnabledAndInpatient = facilityBasedConfigEnabled && encounterType === "INPATIENT";
  const { allowedReviewOutcomes } = useContext(ClinicalReviewContext);
  const { patientStayDateRanges, setPatientStayDateRanges, patientStaysErrorStates, isPatientStayDatesError } =
    usePatientStayDateOnCRR({
      version: "DecisionStay",
      serviceStartDate: startDate,
      patientStayDates: serviceRequest.patientStayDates,
      checkEmptyRequestedLoc: levelOfCareIsMandatoryOnReviews,
    });
  const showPatientStays =
    isConfigEnabledAndInpatient &&
    appealNoteFormState.postAppealOutcome === "PARTIALLY_APPROVED" &&
    patientStayDateRanges &&
    setPatientStayDateRanges;
  const showFlexibleCodes = appealNoteFormState.postAppealOutcome === "PARTIALLY_APPROVED";
  const [attemptedSubmit, setAttemptedSubmit] = useState<boolean>(false);

  const {
    approvedProcedureCodes,
    pxCodesByClinicalService,
    approvedUnitsByClinicalService,
    updateApprovedUnitsByCS,
    updateSinglePxCodeUnit,
    approvedUnits,
    approvedPxCodesAreInvalid,
  } = useProcedureCodesState({ serviceRequest, existingReviews: null, currentReviewId: "" });

  const isApprovedPxCodesInvalid = approvedPxCodesAreInvalid();
  const isValidAppealNote = validateAppealNoteSubmissionModal(
    appealNoteFormState,
    !isPatientStayDatesError,
    !isApprovedPxCodesInvalid
  );

  const upholdOrOverturn = (currentAuthStatus?: string, optionAuthStatus?: string) => {
    return currentAuthStatus === optionAuthStatus ? "Uphold initial" : "Overturn to";
  };

  const getReviewOutcomeOptions = (serviceRequest: ServiceRequestResponse): ReviewOutcomeOption[] => {
    if (!serviceRequest.authStatus) {
      return [];
    }
    if (allowedReviewOutcomes && allowedReviewOutcomes.length > 0) {
      return allowedReviewOutcomes;
    }
    return [
      { id: "APPROVED", label: `${upholdOrOverturn(serviceRequest.authStatus, "APPROVED")} approval` },
      {
        id: "PARTIALLY_APPROVED",
        label: `${upholdOrOverturn(serviceRequest.authStatus, "PARTIALLY_APPROVED")} partial approval`,
      },
      { id: "DENIED", label: `${upholdOrOverturn(serviceRequest.authStatus, "DENIED")} denied` },
      { id: "NOT_COMPLETED", label: "Not completed" },
      { id: "WITHDRAWN", label: "Withdrawn" },
    ];
  };

  const { mutate: createAppeal, loading: createAppealLoading } = useCreateAppeal({
    serviceRequestId: serviceRequest.id,
  });

  const handleModalSubmission = async () => {
    setAttemptedSubmit(true);

    if (!isValidAppealNote) {
      return;
    }
    const { attachments, postAppealOutcome, postAppealNote } = appealNoteFormState;
    const serviceRequestAttachments = attachments.map((attachment) => ({
      id: attachment.id,
    }));
    let appealPayload: AppealPayload = {
      attachments: serviceRequestAttachments,
      isManualAppeal: true,
      outcome: postAppealOutcome,
      healthPlanName: serviceRequest.healthPlanName,
      serviceRequest: {
        id: serviceRequest.id,
      },
      postAppealNote,
    };
    if (appealNoteFormState.postAppealOutcome === "PARTIALLY_APPROVED") {
      appealPayload = {
        ...appealPayload,
        approvedSemanticProcedureCodes: approvedProcedureCodes,
        approvedUnits: approvedUnits,
        patientStayDates: deAggregateStayDates(patientStayDateRanges),
      };
    }
    try {
      await createAppeal(appealPayload);
      enqueueSnackbar("Successfully submitted appeals", { variant: "success" });
      navigateToPS(serviceRequest?.patient?.id ?? "", navigate, serviceRequest.id);
    } catch (error) {
      const errorAny: any = error;
      const message = errorAny?.data?.message;
      const embeddedError = errorAny?.data?._embedded?.errors?.map((error: any) => error?.message).join("\n");
      const errorMessage = (
        <Box>
          <Box>Error while saving appeal information</Box>
          {embeddedError ? <Box>{embeddedError}</Box> : message && <Box>{message}</Box>}
        </Box>
      );
      logError("Error while saving requestor information");
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    }
  };

  const primaryButtonDisabled = !isValidAppealNote || createAppealLoading;

  return (
    <InformativeModal
      open={openSubmissionModal}
      onClose={() => {
        setOpenSubmissionModal(false);
      }}
      maxWidth="lg"
      hideX={createAppealLoading}
      headerText="Select post-appeal outcome"
      additionalInfoElement={
        <Grid container direction="column" alignItems="center" style={{ marginBlock: "32px" }}>
          <SingleSelectDropdown
            label="Post-appeal outcome"
            options={getReviewOutcomeOptions(serviceRequest) as any}
            value={appealNoteFormState.postAppealOutcome}
            onChange={(postAppealOutcome) => {
              updateAppealNoteFormState({ type: "updatePostAppealOutcome", value: postAppealOutcome });
            }}
            data-testid={"appeal-note-outcome-options"}
            error={attemptedSubmit && !Boolean(appealNoteFormState.postAppealOutcome)}
            helperText={attemptedSubmit && !Boolean(appealNoteFormState.postAppealOutcome) && "Required"}
          />
          {showFlexibleCodes && (
            <Grid style={{ marginTop: "16px" }}>
              <PartialApprovalFlexibleCodes
                data-testid={"modal-flexible-codes"}
                pxCodesByClinicalService={pxCodesByClinicalService}
                approvedUnitsByClinicalService={approvedUnitsByClinicalService}
                updateApprovedUnitsByCS={updateApprovedUnitsByCS}
                clinicalServices={clinicalServices?.filter(elementIsNonNull) ?? []}
                serviceRequestUnits={units ?? 0}
                approvedServiceRequestUnits={approvedUnits}
                healthPlanName={healthPlanName || ""}
                updateSinglePxCodeUnit={updateSinglePxCodeUnit}
              />
            </Grid>
          )}
          {showPatientStays && (
            <Grid style={{ marginTop: "32px", width: "100%" }}>
              <PatientStays
                data-testid={"review-modal-patient-stays"}
                version="DecisionStay"
                minimumFromDate={undefined}
                admissionDate={null}
                healthPlanName={healthPlanName || ""}
                patientStayDateRanges={patientStayDateRanges}
                setPatientStayDateRanges={setPatientStayDateRanges}
                patientStaysErrorStates={patientStaysErrorStates}
                isDischarged={patientStatus === "DISCHARGED"}
                attemptedSubmit={attemptedSubmit}
              />
            </Grid>
          )}
          <Checkbox
            style={{ marginTop: "32px", color: colorsLight.font.secondary }}
            checked={appealNoteFormState.attestationOfRequiredFileUpload}
            label="I attest that I have uploaded the required documentation for this post-appeal note"
            onChange={() => {
              updateAppealNoteFormState({ type: "updateAttestationOfRequiredFileUpload" });
            }}
          />
        </Grid>
      }
      showDivider={false}
      primaryButtonTestid="appeal-note-submission-save-button"
      primaryButtonText="Finish note"
      primaryButtonAction={handleModalSubmission}
      primaryButtonDisabled={primaryButtonDisabled}
      primaryButtonLoading={createAppealLoading}
    />
  );
};
