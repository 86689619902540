import React from "react";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import {
  CohereFaxPDFHeader,
  CohereFaxFooter,
  CohereFaxResourcesSection,
  CohereFaxEmptyForm,
  BULLET,
} from "util/PDFUtils";
import { FaxNoticeOutOfScopeInformation } from "../../common";

interface Props {
  outOfScopeInformation: FaxNoticeOutOfScopeInformation;
  patientName: string;
  dateRequested: string;
  isGhpUser?: boolean;
}

export default function OutOfScopeFaxPDF({ outOfScopeInformation, patientName, dateRequested, isGhpUser }: Props) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <CohereFaxPDFHeader faxBackType="OUT_OF_SCOPE" dateRequested={dateRequested} isGhpUser={isGhpUser} />
        <View style={styles.bodySection}>
          <Text>
            <Text style={styles.patientNameRegularText}>Patient name: </Text>
            <Text style={styles.patientNameBoldText}>{patientName}</Text>
          </Text>
        </View>
        <View style={styles.bodySection}>
          <Text style={[styles.bodySemiBoldText, { paddingBottom: 16 }]}>Hello,</Text>
          <Text style={[styles.bodySemiBoldText, { paddingBottom: 8 }]}>
            Thank you for submitting a service request to Cohere Health. Unfortunately, we are not able to complete your
            request. The services requested are out of scope for Cohere.
          </Text>
          <View style={styles.bulletSection}>
            <Text style={{ paddingBottom: 4 }}>
              <Text style={styles.bullet}>{BULLET + "    "}</Text>
              <Text style={styles.bulletText}>Please log into the portal at www.next.coherehealth.com</Text>
            </Text>
            <Text>
              <Text style={styles.bullet}>{BULLET + "    "}</Text>
              <Text style={styles.bulletText}>
                If missing clinical attachment, please upload your clinical documents via the upload attachments link
              </Text>
            </Text>
          </View>
          <View style={styles.reasonSection}>
            {outOfScopeInformation?.ineligiblePatient && (
              <View>
                <Text style={[styles.bodySemiBoldText, { paddingBottom: 2 }]}>Patient is not eligible</Text>
                <Text style={styles.bodySmallText}>
                  Please contact the member's health plan if you have additional questions or concerns.
                </Text>
              </View>
            )}
            {outOfScopeInformation?.ineligibleProvider && (
              <View style={{ paddingTop: 8 }}>
                <Text style={[styles.bodySemiBoldText, { paddingBottom: 2 }]}>Provider is not eligible</Text>
                <Text style={styles.bodySmallText}>
                  Please contact the member's health plan if you have additional questions or concerns.
                </Text>
              </View>
            )}
            {outOfScopeInformation?.mustBePCPSubmitted && (
              <View style={{ paddingTop: 8 }}>
                <Text style={[styles.bodySemiBoldText, { paddingBottom: 2 }]}>
                  Prior Authorization must be submitted by PCP
                </Text>
                <Text style={styles.bodySmallText}>Please refer the Patient to their PCP to obtain Authorization.</Text>
              </View>
            )}
            {outOfScopeInformation?.additionalNotes && (
              <View style={{ paddingTop: 8 }}>
                <Text style={[styles.bodySemiBoldText, { paddingBottom: 2 }]}>Additional Notes</Text>
                <Text style={styles.bodySmallText}>{outOfScopeInformation.additionalNotes}</Text>
              </View>
            )}
          </View>
        </View>
        <CohereFaxResourcesSection faxBackType="OUT_OF_SCOPE" isGhpUser={isGhpUser} />
        <CohereFaxFooter />
      </Page>
      <CohereFaxEmptyForm isGhpUser={isGhpUser} />
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
    padding: 56,
  },
  bodySection: {
    width: "100%",
    borderBottom: 1,
    paddingTop: 24,
    paddingBottom: 24,
    position: "relative",
  },
  patientNameRegularText: {
    fontSize: 12,
    fontFamily: "GilroyRegular",
  },
  patientNameBoldText: {
    fontSize: 12,
    fontFamily: "GilroyBold",
  },
  bodySemiBoldText: {
    fontSize: 10,
    fontFamily: "GilroySemiBold",
  },
  bulletSection: {
    marginLeft: 12,
    paddingBottom: 16,
  },
  bullet: {
    fontSize: 10,
    fontFamily: "GilroyBold",
  },
  bulletText: {
    fontSize: 10,
    fontFamily: "GilroyRegular",
  },
  bodySmallText: {
    fontSize: 7,
    fontFamily: "GilroyRegular",
  },
  bodySmallBoldText: {
    fontSize: 7,
    fontFamily: "GilroyBold",
  },
  reasonSection: {
    width: "100%",
  },
});
