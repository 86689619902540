import { useEffect, useReducer, createContext, useContext, useState } from "react";
import omit from "lodash/omit";
import {
  UseClinicalReviewManagerProps,
  UseClinicalReviewExternalManagerProps,
  UseClinicalReviewExternalManagerResult,
  ClinicalReviewExternalData,
  ClinicalReviewComponentData,
  ClinicalReviewSerializableData,
  ClinicalReviewApi,
  ClinicalReviewAsyncAction,
} from "./types";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { initialStoreState, initialExternalState, initialComponentApi } from "./state";
import { stateReducer } from "./reducer";
import { useGetUser } from "../reviewUtils/utils";
import useOtherAuthorizations from "../ClinicalReviewInfoPanel/OtherServiceRequests/useOtherAuthorization";
import useClaimHistory from "components/ClaimHistory/useClaimHistory";
import { useClinicalReviewFeatureFlags } from "./hooks/useClinicalReviewFeatureFlags";
import { useClinicalReviewPageConfigurations } from "./hooks/useClinicalReviewPageConfigurations";
import { useClinicalReviewPageUserAuth } from "./hooks/useClinicalReviewPageUserAuths";
import { ServiceCase, useGetServiceCase, useGetServiceRequestCases } from "@coherehealth/qm-api";
import config from "api/config";

// Performs APIs calls for ClinicalReviewPage. Returns this external data
// with async dispatch functions for updates/mutations.
export function useClinicalReviewExternalManager({
  state,
  dispatch,
  hooks,
}: UseClinicalReviewExternalManagerProps): UseClinicalReviewExternalManagerResult {
  const {
    useGetServiceRequest,
    useGetServiceRequests,
    useAuthorizationFetch,
    useGetPatient,
    useGetAllCoverages,
    useIsCoverageActive,
    useGetAllReviews,
    useGetLatestReviewLeadingToDenial,
    useGetServiceRequestOutreachOpportunities,
    useServiceRequestRuleResultsForClinicalReview,
    useAttachments,
  } = hooks;

  const { serviceRequestId, caseId, currentReview, isReviewEditMode, caseType } = state;

  const [fetchedServiceRequest, setFetchedServiceRequest] = useState<ServiceRequestResponse | null>(null);
  const [currentCase, setCurrentCase] = useState<ServiceCase>();

  // Service request and authorization
  const {
    data: serviceRequest,
    error: serviceRequestFetchError,
    loading: serviceRequestLoading,
    refetch: fetchServiceRequest,
  } = useGetServiceRequest({
    id: serviceRequestId || "",
    lazy: true,
  });
  const {
    data: otherServiceRequests,
    error: otherServiceRequestsFetchError,
    loading: otherServiceRequestsLoading,
    refetch: fetchOtherServiceRequests,
  } = useGetServiceRequests({
    queryParams: { patient: `eq:${serviceRequest?.patient?.id || ""}` },
    lazy: true,
    resolve: (patientServiceRequests: ServiceRequestResponse[]) =>
      patientServiceRequests.filter((patientServiceRequest) => patientServiceRequest.id !== serviceRequest?.id),
  });
  const {
    data: outreachOpportunities,
    error: outreachOpportunitiesFetchError,
    loading: outreachOpportunitiesLoading,
    refetch: fetchOutreachOpportunities,
  } = useGetServiceRequestOutreachOpportunities({
    id: serviceRequestId || "",
    lazy: true,
  });
  const {
    authorization,
    authorizationFetchLoading: authorizationLoading,
    fetchAuthorization,
  } = useAuthorizationFetch(serviceRequest);

  // Patient and coverage
  const {
    data: patient,
    error: patientFetchError,
    loading: patientLoading,
    refetch: fetchPatient,
  } = useGetPatient({
    id: serviceRequest?.patient?.id || "fallback-no-patient",
    lazy: true,
  });
  const {
    data: allCoverages,
    error: allCoveragesFetchError,
    loading: allCoveragesLoading,
    refetch: fetchAllCoverages,
  } = useGetAllCoverages({
    id: serviceRequestId || "",
    lazy: true,
  });
  const {
    data: isCoverageActive,
    error: isCoverageActiveFetchError,
    loading: isCoverageActiveLoading,
    refetch: fetchIsCoverageActive,
  } = useIsCoverageActive({
    queryParams: {
      serviceRequestId: serviceRequestId || "",
    },
    lazy: true,
  });

  // Review
  const {
    data: existingReviews,
    error: existingReviewsFetchError,
    loading: existingReviewsLoading,
    refetch: fetchExistingReviews,
  } = useGetAllReviews({
    serviceRequestId: serviceRequestId || "",
    queryParams: { max: 200 },
    lazy: true,
  });
  const {
    data: latestReviewLeadingToDenial,
    error: latestReviewLeadingToDenialFetchError,
    loading: latestReviewLeadingToDenialLoading,
    refetch: fetchLatestReviewLeadingToDenial,
  } = useGetLatestReviewLeadingToDenial({
    id: serviceRequestId || "",
    lazy: true,
  });

  // Service case
  const {
    data: serviceCase,
    error: serviceCaseFetchError,
    loading: serviceCaseLoading,
    refetch: fetchServiceCase,
  } = useGetServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    lazy: true,
  });
  const {
    data: serviceRequestCases,
    error: serviceRequestCasesFetchError,
    loading: serviceRequestCasesLoading,
    refetch: fetchServiceRequestCases,
  } = useGetServiceRequestCases({
    id: serviceRequest?.id || "none",
    base: `${config.QM_SERVICE_API_URL}`,
    lazy: true,
  });
  // TODO: Add useUpdateServiceCase mutating function

  // Other data fetching hooks
  const ruleResultsForClinicalReview = useServiceRequestRuleResultsForClinicalReview({
    serviceRequestId: serviceRequestId ?? "",
    isReviewEditMode,
    reviewType: currentReview?.reviewType,
  });
  const attachmentsProps = useAttachments({
    serviceRequest,
    existingReviews: existingReviews || [],
  });
  const otherAuthsProps = useOtherAuthorizations({ serviceRequest });
  const claimHistoryProps = useClaimHistory({ patientId: serviceRequest?.patient?.id });

  // Feature flags
  const { claimHistoryFeature, genericNotes, updatedClinicalReviewNudges, caseEventHistoryGA } =
    useClinicalReviewFeatureFlags();

  // Feature configuration
  const { facilityBasedRequestConfiguration, continuationConfiguration } =
    useClinicalReviewPageConfigurations(serviceRequest);

  // User authorization/permissions
  const user = useGetUser(); // how to prevent when FF is off?
  const {
    canUseClinicalReviewRedesign,
    canUseOutreachPage,
    viewReview,
    canUseMissingInfoPage,
    canEditMdReview,
    canEditPeerToPeerReview,
    canEditFinalizedMDReview,
    canSubmitDenial,
    canUseNewDenialsWorkflowBeta,
    addNoteAuthorized,
    viewNoteAuthorized,
    isAllowedCaseHistory,
  } = useClinicalReviewPageUserAuth();

  // Effects
  useEffect(() => {
    if (serviceRequestId) {
      fetchServiceRequest();
    }
  }, [fetchServiceRequest, serviceRequestId]);
  useEffect(() => {
    if (serviceRequest) {
      setFetchedServiceRequest(serviceRequest);
    }
  }, [serviceRequest]);
  useEffect(() => {
    if (fetchedServiceRequest) {
      fetchOutreachOpportunities();
      fetchAllCoverages();
      fetchIsCoverageActive();
      fetchExistingReviews();
      fetchLatestReviewLeadingToDenial();
    }
  }, [
    fetchedServiceRequest,
    fetchOutreachOpportunities,
    fetchAllCoverages,
    fetchIsCoverageActive,
    fetchExistingReviews,
    fetchLatestReviewLeadingToDenial,
  ]);
  useEffect(() => {
    if (fetchedServiceRequest?.patient?.id) {
      fetchPatient();
      fetchAuthorization();
      fetchOtherServiceRequests();
    }
  }, [fetchedServiceRequest?.patient?.id, fetchAuthorization, fetchOtherServiceRequests, fetchPatient]);
  useEffect(() => {
    const fetchCaseInformation = async () => {
      const activeCase = await fetchServiceCase();
      if (activeCase) {
        setCurrentCase(activeCase);
      }
    };
    if (serviceRequest && caseType && config.HAS_QM_ENVIRONMENT) {
      if (caseId) {
        fetchCaseInformation();
      }
    }
  }, [caseId, caseType, serviceRequest, serviceRequestId, fetchServiceCase]);
  useEffect(() => {
    if (serviceRequest && config.HAS_QM_ENVIRONMENT && (isAllowedCaseHistory || caseEventHistoryGA)) {
      fetchServiceRequestCases();
    }
  }, [serviceRequest, isAllowedCaseHistory, caseEventHistoryGA, fetchServiceRequestCases]);

  const externalState: ClinicalReviewExternalData = {
    ...initialExternalState,
    ...otherAuthsProps,
    ...claimHistoryProps,
    serviceRequest,
    otherServiceRequests,
    outreachOpportunities,
    authorization,
    patient,
    allCoverages,
    isCoverageActive,
    existingReviews,
    latestReviewLeadingToDenial,
    serviceCase,
    serviceRequestCases,
    loading: {
      serviceRequestLoading,
      otherServiceRequestsLoading,
      outreachOpportunitiesLoading,
      authorizationLoading,
      patientLoading,
      allCoveragesLoading,
      isCoverageActiveLoading,
      existingReviewsLoading,
      latestReviewLeadingToDenialLoading,
      serviceCaseLoading,
      serviceRequestCasesLoading,
    },
    errors: {
      serviceRequestFetchError,
      otherServiceRequestsFetchError,
      outreachOpportunitiesFetchError,
      patientFetchError,
      allCoveragesFetchError,
      isCoverageActiveFetchError,
      existingReviewsFetchError,
      latestReviewLeadingToDenialFetchError,
      serviceCaseFetchError,
      serviceRequestCasesFetchError,
    },
    featureFlagData: { caseEventHistoryGA, claimHistoryFeature, genericNotes, updatedClinicalReviewNudges },
    featureConfigurationData: { facilityBasedRequestConfiguration, continuationConfiguration },
    clinicalReviewPageUserAuths: {
      canUseClinicalReviewRedesign,
      canUseOutreachPage,
      viewReview,
      canUseMissingInfoPage,
      canEditMdReview,
      canEditPeerToPeerReview,
      canEditFinalizedMDReview,
      canSubmitDenial,
      canUseNewDenialsWorkflowBeta,
      addNoteAuthorized,
      viewNoteAuthorized,
      isAllowedCaseHistory,
    },
    user,
    rightColumnTab: undefined,
    attachmentsProps,
    ruleResultsForClinicalReview,
    currentReview,
    currentCase,
  };

  // TODO
  const dispatchAsync = (action: ClinicalReviewAsyncAction) => {};

  return {
    externalState,
    dispatchAsync,
  };
}

// Should only be used at the top-level of the component tree using
// this store. This is intended to be a singleton.
export function useClinicalReviewManager({ hooks }: UseClinicalReviewManagerProps): ClinicalReviewApi {
  const [state, dispatch] = useReducer(stateReducer, initialStoreState);
  const { externalState, dispatchAsync } = useClinicalReviewExternalManager({ state, dispatch, hooks });

  const combinedState = {
    ...state,
    ...externalState,
  };

  return {
    state: combinedState,
    dispatch,
    dispatchAsync,
  };
}

export function serializableState(
  state: Partial<ClinicalReviewComponentData>
): Partial<ClinicalReviewSerializableData> {
  return omit(state, ["claimHistoryProps", "otherAuthsProps"]);
}

export function printState(state: Partial<ClinicalReviewComponentData>) {
  console.log(JSON.stringify(serializableState(state), null, 4));
}

/* Component-facing API */

export const ClinicalReviewContext = createContext<ClinicalReviewApi>(initialComponentApi);

export function useClinicalReviewNext(): ClinicalReviewApi {
  const contextData = useContext(ClinicalReviewContext);
  if (!contextData) {
    throw new Error("No ClinicalReviewContext provider found");
  }
  return contextData;
}
