import { AttachmentProps } from "../reviewUtils/useAttachment";
import {
  ClinicalReviewStoreData,
  ClinicalReviewExternalData,
  ClinicalReviewComponentData,
  ClinicalReviewApi,
  ClinicalReviewSyncAction,
  ClinicalReviewAsyncAction,
} from "./types";

/* Initial state */

export const initialStoreState: ClinicalReviewStoreData = {
  isEnabled: false,
  serviceRequestId: null,
  serviceRequest: null,
  patientId: null,
  currentReview: undefined,
  isReviewEditMode: false,
  caseId: null,
  caseType: undefined,
  leftColumnTab: "REQUEST_INFORMATION",
};

export const initialExternalState: ClinicalReviewExternalData = {
  otherAuthsProps: null,
  claimHistoryProps: undefined,
  serviceRequest: null,
  otherServiceRequests: null,
  outreachOpportunities: null,
  authorization: undefined,
  patient: null,
  allCoverages: null,
  isCoverageActive: null,
  existingReviews: null,
  latestReviewLeadingToDenial: null,
  serviceCase: null,
  serviceRequestCases: null,
  loading: {
    serviceRequestLoading: false,
    otherServiceRequestsLoading: false,
    outreachOpportunitiesLoading: false,
    authorizationLoading: false,
    patientLoading: false,
    allCoveragesLoading: false,
    isCoverageActiveLoading: false,
    existingReviewsLoading: false,
    latestReviewLeadingToDenialLoading: false,
    serviceCaseLoading: false,
    serviceRequestCasesLoading: false,
  },
  errors: {
    serviceRequestFetchError: null,
    otherServiceRequestsFetchError: null,
    outreachOpportunitiesFetchError: null,
    // authorizationFetchError does not exist.
    patientFetchError: null,
    allCoveragesFetchError: null,
    isCoverageActiveFetchError: null,
    existingReviewsFetchError: null,
    latestReviewLeadingToDenialFetchError: null,
    serviceCaseFetchError: null,
    serviceRequestCasesFetchError: null,
  },
  featureFlagData: {
    caseEventHistoryGA: undefined,
    claimHistoryFeature: undefined,
    genericNotes: undefined,
    updatedClinicalReviewNudges: undefined,
  },
  featureConfigurationData: {
    facilityBasedRequestConfiguration: undefined,
    continuationConfiguration: null,
  },
  clinicalReviewPageUserAuths: {
    canUseClinicalReviewRedesign: false,
    canUseOutreachPage: false,
    viewReview: false,
    canUseMissingInfoPage: false,
    canEditMdReview: false,
    canEditPeerToPeerReview: false,
    canEditFinalizedMDReview: false,
    canSubmitDenial: false,
    canUseNewDenialsWorkflowBeta: false,
    addNoteAuthorized: false,
    viewNoteAuthorized: false,
    isAllowedCaseHistory: false,
  },
  user: undefined,
  rightColumnTab: undefined,
  attachmentsProps: {} as AttachmentProps,
  ruleResultsForClinicalReview: null,
  currentReview: undefined,
  currentCase: undefined,
};

export const initialCombinedState: ClinicalReviewComponentData = {
  ...initialStoreState,
  ...initialExternalState,
};

// Default value of context
export const initialComponentApi: ClinicalReviewApi = {
  state: initialCombinedState,
  dispatch: (_: ClinicalReviewSyncAction) => undefined,
  dispatchAsync: (_: ClinicalReviewAsyncAction) => undefined,
};
