const routes = {
  AUTH_BUILDER: "/auth_builder/:patientId",
  AUTHENTICATED_ORGANIZATION_ONBOARDING: "/authenticated_organization_onboarding",
  CAREWEBQI: "/carewebqi/:serviceRequestId",
  CLAIM_SERVICE_REQUEST: "/claim_service_request/:claimId",
  CLINICAL_ASSESSMENT_TEST: "/clinical_assessment_test/:serviceRequestId",
  CLINICAL_REVIEW: "/:serviceRequestId/review",
  CONFIGURATION_MANAGEMENT: "/admin/configuration_management",
  CREATE_P2P_REIVEW_PAGE: "/:serviceRequestId/review?createNewPeerToPeerReview",
  CYPRESS_LANDING: "/cypress/cypress_landing",
  DASHBOARD: "/dashboard",
  DENIAL_REVIEW: "/denial_review/:serviceRequestId",
  FAX: "/fax/:faxId",
  FAX_AUTH_BUILDER: "/fax_auth_builder/:faxId/patient/:patientId",
  FAX_SERVICE_REQUEST: "/fax_service_request/:faxId",
  FEATURE_FLAGS: "/admin/feature_flags",
  HOME: "/home", // Only used for internal testing, remove once not necessary
  INTEGRATION_MANAGEMENT: "/integration_management",
  INTEGRATION_OBSERVABILITY_MANAGEMENT: "/integration_observability",
  LOG_OUTREACH_PAGE: "/:serviceRequestId/outreach",
  MAINTENANCE: "/maintenance.html",
  MY_ORGANIZATION: "/my_organization",
  NEW_NOTE_PAGE: "/:serviceRequestId/new-note",
  APPEAL_NOTE_PAGE: "/:serviceRequestId/post-appeal-note",
  NEW_OUTREACH: "/:serviceRequestId/new_outreach",
  ORGANIZATION_MANAGEMENT_LIST: "/organization_management",
  ORGANIZATION_MANAGEMENT: "/organization_management/:orgId",
  ORGANIZATION_ONBOARDING: "/organization_onboarding",
  OPS_USER_MANAGEMENT: "/ops_user_management",
  PATIENT_MANAGEMENT: "/patient_management",
  PATIENT_MANAGEMENT_EDIT_PATIENT: "/patient_management/:patientId",
  PATIENT_SUMMARY: "/patient_summary/:patientId",
  SERVICE_REQUEST: "/service_request/:serviceRequestId",
  EDIT_SERVICE_REQUEST_FAX: "/service_request/:serviceRequestId/service_case/:caseId",
  EDIT_SR_SIDE_BY_SIDE: "/fax_edit_service_request/:faxId/service_request/:serviceRequestId/service_case/:caseId",
  SMART_ON_FHIR_SERVICE_REQUEST: "/smart_on_fhir_service_request/:serviceRequestId",
  SMART_ON_FHIR_AUTH_BUILDER: "/smart_on_fhir_auth_builder/:patientId",
  SMART_ON_FHIR_LAUNCH: "/smart_on_fhir_launch",
  SMART_ON_FHIR_LANDING: "/smart_on_fhir_landing",
  SMART_ON_FHIR_NOT_FOUND: "/smart_on_fhir_not_found",
  TEST_ERROR: "/test/error",
  UNAUTHORIZED_CHECK_STATUS: "/check_status",
  VIEW_ONLY_REVIEW: "/view_only_review",
  WITHDRAW_AND_REPLACE: "/withdraw_and_replace/:serviceRequestId",
  ADHOC_LETTER: "/letter/:serviceRequestId",
  ADD_NEW_PATIENT: "/add_new_patient",
  FAX_AUDIT: "/fax_audit/:serviceRequestId",
  REFERRAL_DASHBOARD: "/referrals/dashboard",
  REFERRAL_BUILDER: "/referrals/build/:patientId",
  REFERRAL_PATIENT_SUMMARY: "/referrals/patient_summary/:patientId",
  REFERRAL_REQUEST: "/referrals/:referralRequestId",
};

export default routes;
