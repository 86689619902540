import { FunctionComponent, useEffect, useState } from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import { Provider, Facility, PhoneNumber, PracticeCreatePayload, Location } from "@coherehealth/core-platform-api";
import ProviderFacilityEdit from "./ProviderFacilityEditManual";
import ProviderFacilityReadonly from "./ProviderFacilityReadonly";
import ProviderFacilityEditTinAndAddress from "./ProviderFacilityEditTinAndAddress";
import { ModalStatus } from "util/providerUtils";

interface Props extends Pick<DialogProps, "open" | "onClose"> {
  modalType: "performingProvider" | "orderingProvider" | "facility";
  modalStatus: ModalStatus;
  setSelection?: (params: PracticeCreatePayload, practice: Provider | Facility) => Promise<void>;
  setSelectedTin?: (practice: Provider | Facility | null, tin: string | null) => void;
  onSave?(payload: PracticeCreatePayload, setEntryId: (practice: Provider | Facility | null) => void): Promise<any>;
  savedPractice?: Provider | Facility | null;
  serviceCategory?: string;
  isDraft?: boolean;
  sameProviders?: boolean;
  setBothProviders?: (provider: Provider | null, tin: string | null) => void;
  patientHealthPlan?: string;
  selectedTin?: string;
  selectedLocation?: Location | null;
  hideTinField?: boolean;
}

export type ProviderFacilityModalContent = {
  name?: string;
  lastName?: string;
  firstName?: string;
  npi?: string;
  tin?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  contactNumber?: PhoneNumber;
  faxNumber?: string;
  npiNotApplicable?: boolean;
  tinNotApplicable?: boolean;
};

const ProviderFacilityModal: FunctionComponent<Props> = ({
  onSave,
  modalType,
  modalStatus,
  setSelection,
  setSelectedTin,
  savedPractice,
  serviceCategory,
  isDraft,
  sameProviders,
  setBothProviders,
  open,
  patientHealthPlan,
  onClose,
  selectedTin,
  selectedLocation,
  hideTinField = false,
}) => {
  const isProvider = modalType === "performingProvider" || modalType === "orderingProvider";
  const isOrderingProvider = modalType === "orderingProvider";
  const [isEditable, setIsEditable] = useState(modalStatus !== ModalStatus.Summary);
  const [practice, setPractice] = useState<Provider | Facility | null>(savedPractice || null);
  useEffect(() => {
    if (savedPractice) {
      setPractice(savedPractice);
    }
  }, [savedPractice]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { borderRadius: "10px" },
      }}
    >
      {isEditable ? (
        modalStatus === ModalStatus.Add || modalStatus === ModalStatus.Edit ? (
          <ProviderFacilityEdit
            onClose={onClose}
            practice={modalStatus === ModalStatus.Add ? null : practice}
            setPractice={setPractice}
            isProvider={isProvider}
            setSelection={setSelection}
            onSave={onSave}
            setIsEditable={setIsEditable}
            modalStatus={modalStatus}
            patientHealthPlan={patientHealthPlan}
            selectedTin={selectedTin}
            selectedLocation={selectedLocation}
            hideTinField={hideTinField}
          />
        ) : modalStatus === ModalStatus.EditAddress ||
          modalStatus === ModalStatus.AddAddress ||
          modalStatus === ModalStatus.EditTin ||
          modalStatus === ModalStatus.AddTin ? (
          <ProviderFacilityEditTinAndAddress
            onClose={onClose}
            practice={practice}
            setPractice={setPractice}
            isProvider={isProvider}
            isOrderingProvider={isOrderingProvider}
            setSelection={setSelection}
            setSelectedTin={setSelectedTin}
            onSave={onSave}
            setIsEditable={setIsEditable}
            modalStatus={modalStatus}
            sameProviders={sameProviders}
            setBothProviders={setBothProviders}
            selectedTin={selectedTin}
            selectedLocation={selectedLocation}
            hideTinField={hideTinField}
          />
        ) : (
          <div></div>
        )
      ) : (
        <ProviderFacilityReadonly
          onClose={onClose}
          serviceCategory={serviceCategory || ""}
          isProvider={isProvider}
          practice={practice}
          setIsEditable={setIsEditable}
          isDraft={isDraft || false}
          hideTinField={hideTinField}
        />
      )}
    </Dialog>
  );
};

export default ProviderFacilityModal;
