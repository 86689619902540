import { Dispatch, SetStateAction, useMemo } from "react";

import { SingleSelect, useConfiguration, useFeature } from "@coherehealth/common";
import { ExtendedRnReviewOutcome, ReviewOutcomeOption } from "../util/QueueManagementReviewUtil";
import { AuthStatus, NurseReview, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { getTatInfo } from "components/ClinicalReview/reviewUtils/utils";

interface Props {
  serviceRequest: ServiceRequestResponse;
  previousServiceRequest: ServiceRequestResponse | null;
  firstDeniedDay: string | undefined;
  caseId: string;
  allowedReviewOutcomes: ReviewOutcomeOption[];
  setDisplayCaseOutcome: (displayOutcome: ExtendedRnReviewOutcome) => void;
  setReview: (nurseReviewUpdate: Partial<NurseReview>) => void;
  setRecommendedOutcome?: Dispatch<
    SetStateAction<"RECOMMENDED_PARTIAL_APPROVAL" | "RECOMMENDED_DENIAL" | "RECOMMENDED_APPROVAL" | undefined>
  >;
  isCppCase: boolean;
  isUnplannedInpatient: boolean;
  displayCaseOutcome?: ExtendedRnReviewOutcome;
  facilityBasedFeatureEnabled?: boolean;
  error?: boolean;
}

export default function NurseReviewSubmissionOutcomes({
  serviceRequest,
  previousServiceRequest,
  firstDeniedDay,
  caseId,
  allowedReviewOutcomes,
  setDisplayCaseOutcome,
  setReview,
  setRecommendedOutcome,
  displayCaseOutcome,
  isCppCase,
  isUnplannedInpatient,
  facilityBasedFeatureEnabled,
  error,
}: Props) {
  const denyWithoutPendToMdForPartialApproval = useFeature("denyWithoutPendToMdForPartialApproval");
  const pendingAdminVoidEnabled = useFeature("pendingAdminVoidEnabled");
  const outOfScopeEnabled = useFeature("diagnosisOutOfScope");
  const isAdminDenialsEnabled = useFeature("allowNurseReviewAdminDenials");
  const allowPartialNurseReviewAdminDenials = useFeature("allowPartialNurseReviewAdminDenials");

  const oonReviewConfig = useConfiguration("outOfNetworkReviewConfiguration", serviceRequest.healthPlanName);

  const oonReviewFeatureEnabled = oonReviewConfig?.enabled;
  const isQmCase = !!caseId.length;

  const isGHPServiceRequest = serviceRequest.healthPlanName === "Geisinger";

  const hasRecDeniedOONDecision =
    serviceRequest.oonExceptionDecision === "RECOMMENDED_DENIAL" && oonReviewFeatureEnabled;
  const outcomeOptions = useMemo(() => {
    const { tatTimeRemainingPercentage = 0 } = getTatInfo(serviceRequest);
    const lessThan50percentTat = tatTimeRemainingPercentage < 50;
    const previousSRDenialAllowedStatuses: AuthStatus[] = denyWithoutPendToMdForPartialApproval
      ? ["DENIED", "PARTIALLY_APPROVED"]
      : ["DENIED"];

    const allowDenial =
      previousServiceRequest?.authStatus &&
      previousSRDenialAllowedStatuses.includes(previousServiceRequest.authStatus) &&
      facilityBasedFeatureEnabled;
    return allowedReviewOutcomes?.length
      ? allowedReviewOutcomes
      : reviewOutcomeOptions(
          serviceRequest.healthPlanName,
          isQmCase,
          pendingAdminVoidEnabled,
          isCppCase,
          isUnplannedInpatient,
          outOfScopeEnabled,
          lessThan50percentTat,
          isAdminDenialsEnabled,
          allowPartialNurseReviewAdminDenials,
          allowDenial,
          hasRecDeniedOONDecision
        );
  }, [
    allowedReviewOutcomes,
    serviceRequest,
    denyWithoutPendToMdForPartialApproval,
    previousServiceRequest?.authStatus,
    facilityBasedFeatureEnabled,
    isQmCase,
    pendingAdminVoidEnabled,
    isCppCase,
    isUnplannedInpatient,
    outOfScopeEnabled,
    isAdminDenialsEnabled,
    allowPartialNurseReviewAdminDenials,
    hasRecDeniedOONDecision,
  ]);

  /**
   * This is to customize the helper text if in future we want more helper text conditionally.
   */
  const outcomeHelperText = () => {
    switch (displayCaseOutcome) {
      case "DENIED":
        if (facilityBasedFeatureEnabled) {
          return `An MD has denied days from ${firstDeniedDay} onward. These days can be denied without pending to an MD reviewer. A letter will not be sent.`;
        }
    }
    return undefined;
  };

  return (
    <SingleSelect<ReviewOutcomeOption>
      data-testid="review-outcomes-single-select"
      label="Outcome"
      useOptions={() => ({
        optionsLoading: false,
        options: outcomeOptions,
      })}
      selectedValue={outcomeOptions.find(({ id }) => displayCaseOutcome === id) || null}
      getOptionLabel={(opt) => opt.label}
      setSelectedValue={(opt) => {
        setDisplayCaseOutcome(opt?.id);
        setRecommendedOutcome?.(undefined);
        setReview({
          reviewOutcome: opt?.id !== "CANNOT_WORK" ? opt?.id : undefined,
          serviceStatusCode: isGHPServiceRequest && opt?.id === "ADMINISTRATIVE_DENIAL" ? "DN00" : "",
        });
      }}
      helperText={outcomeHelperText()}
      error={error}
    />
  );
}

const reviewOutcomeOptions = (
  healthPlanName: string | undefined,
  isQmCase: boolean,
  pendingAdminVoidEnabled: boolean,
  isCppCase: boolean,
  isUnplannedInpatient: boolean,
  outOfScopeEnabled: boolean,
  lessThan50percentTat: boolean,
  isAdminDenialsEnabled: boolean,
  allowPartialNurseReviewAdminDenials: boolean,
  allowDenial?: boolean,
  hasRecDeniedOONDecision?: boolean
): ReviewOutcomeOption[] => {
  const options = reviewOutComeOptionsForHP(
    healthPlanName,
    isAdminDenialsEnabled,
    pendingAdminVoidEnabled,
    allowDenial
  );
  let updatedOptions = [...options];
  if (!outOfScopeEnabled || lessThan50percentTat) {
    updatedOptions = updatedOptions.filter((option) => option.id !== "OUT_OF_SCOPE");
  }
  if (isQmCase) {
    if (!isCppCase) {
      updatedOptions = updatedOptions.filter((option) => option.id !== "PENDING_RN_REVIEW");
    }
    updatedOptions = updatedOptions.concat([{ id: "CANNOT_WORK", label: "Cannot work - do not have skills" }]);
  }
  if (hasRecDeniedOONDecision) {
    updatedOptions = updatedOptions.filter((option) => option.id !== "APPROVED");
  }
  if (allowPartialNurseReviewAdminDenials && isUnplannedInpatient) {
    updatedOptions = updatedOptions.concat([
      { id: "ADMINISTRATIVE_PARTIAL_APPROVAL", label: "Administrative partial approval" },
    ]);
  }
  return updatedOptions;
};

const reviewOutComeOptionsForHP = (
  healthPlanName: string | undefined,
  isAdminDenialsEnabled: boolean,
  pendingAdminVoidEnabled: boolean,
  allowDenial?: boolean
): ReviewOutcomeOption[] => {
  if (healthPlanName === "Geisinger") {
    return [
      { id: "APPROVED", label: "Approved" },
      ...(allowDenial ? [{ id: "DENIED", label: "Deny without pending to MD" }] : []),
      { id: "PENDING_MISSING_CLINICAL_INFO", label: "Pending missing info" },
      { id: "PENDING_MD_REVIEW", label: "Pending MD review" },
      { id: "WITHDRAWN", label: "Withdrawn" },
      ...(isAdminDenialsEnabled ? [{ id: "ADMINISTRATIVE_DENIAL", label: "Administrative denial" }] : []),
    ];
  } else if (healthPlanName === "BCBS South Carolina") {
    return [
      { id: "RECOMMENDED_APPROVAL", label: "Recommended approval" },
      { id: "RECOMMENDED_PARTIAL_APPROVAL", label: "Recommended partial approval" },
      { id: "RECOMMENDED_DENIAL", label: "Recommended denial" },
      { id: "MISROUTE", label: "Misroute" },
      ...(isAdminDenialsEnabled ? [{ id: "ADMINISTRATIVE_DENIAL", label: "Administrative denial" }] : []),
      ...(pendingAdminVoidEnabled ? [{ id: "PENDING_ADMIN_VOID", label: "Pending admin void" }] : []),
    ];
  } else if (healthPlanName === "Humana") {
    return [
      ...ReviewOutcomeOptions,
      { id: "OUT_OF_SCOPE", label: "Out of scope - Send to Humana" },
      ...(isAdminDenialsEnabled ? [{ id: "ADMINISTRATIVE_DENIAL", label: "Administrative denial" }] : []),
      ...(pendingAdminVoidEnabled ? [{ id: "PENDING_ADMIN_VOID", label: "Pending admin void" }] : []),
    ];
  } else {
    return [
      ...ReviewOutcomeOptions,
      ...(isAdminDenialsEnabled ? [{ id: "ADMINISTRATIVE_DENIAL", label: "Administrative denial" }] : []),
      ...(pendingAdminVoidEnabled ? [{ id: "PENDING_ADMIN_VOID", label: "Pending admin void" }] : []),
    ];
  }
};

const ReviewOutcomeOptions: ReviewOutcomeOption[] = [
  { id: "APPROVED", label: "Approved" },
  { id: "PENDING_MISSING_CLINICAL_INFO", label: "Pending missing info" },
  { id: "PENDING_NUDGE_OUTREACH", label: "Pending nudge outreach" },
  { id: "PENDING_RN_REVIEW", label: "Pending clinical review" },
  { id: "PENDING_MD_REVIEW", label: "Pending MD review" },
  { id: "WITHDRAWN", label: "Withdrawn" },
];
