import { CohereCard } from "@coherehealth/design-system";
import {
  AuthorizationResponse,
  ServiceRequestResponse,
  useUpdateServiceRequest,
  useGetPlaceOfServices,
} from "@coherehealth/core-platform-api";
import Grid from "@mui/material/Grid";
import {
  Body2,
  InlineButton,
  useFeature,
  SecondaryButton,
  PrimaryButton,
  parseDateFromISOString,
  Caption,
} from "@coherehealth/common";
import PencilIcon from "@material-ui/icons/Create";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core";
import { Dispatch, SetStateAction, useEffect, useState, useCallback } from "react";
import DiagnosisCodesSection from "components/AuthBuilder/GatherRequirements/DiagnosisCodesSection";
import { PriorAuthRequirements, convertStringToAuthBuilderWorkflowStep } from "components/AuthBuilder/common";
import usePrevious from "react-use/lib/usePrevious";
import ProvidersCardWrapper from "components/AuthBuilder/FillFormsGeneralAuthSubmission/ProvidersCard";
import { ServiceRequestFormContent } from "components/ServiceRequest";
import { useServiceRequestConfigSpec } from "components/ServiceRequest/ConfigurableServiceRequestForm";
import { getPatientHealthPlanName } from "util/patientUtils";
import { useServiceRequestFormRedirectDispatch } from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import { ServiceRequestFieldName } from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import { useAuthorized } from "authorization";
import ReadOnlyRequestDetailPage from "./ReadOnlyRequestDetailPage";
import { Row } from "components/AuthBuilder/FillForms/ServiceRequestForm";
import {
  validatePerformingProviderAddress,
  validatePerformingProviderNPI,
} from "components/AuthBuilder/validationUtil";
import { isExceptionCommentRequired } from "util/providerUtils";
import {
  isAnyCareParticipantOONExceptionRequired,
  validateOONExceptionCommentFieldForCareParticipants,
  validateOONExceptionReasonFieldForCareParticipants,
} from "util/serviceRequest";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";
import { fieldIsValid } from "./FormConfigUtils";

const useStyles = makeStyles((theme) => ({
  secondaryContainer: {
    padding: "16px",
  },
  caption: {
    color: theme.palette.error.dark,
  },
}));

type Props = React.PropsWithChildren<{
  authorization?: AuthorizationResponse;
  serviceRequest: ServiceRequestResponse;
  priorAuthRequirements?: PriorAuthRequirements;
  setPriorAuthRequirements?: Dispatch<SetStateAction<PriorAuthRequirements>>;
  onUpdateDiagnosisCodes?: () => void;
  facilityBasedWorkflow?: boolean;
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  performingProviderExceptionRequest?: boolean;
  facilityExceptionRequest?: boolean;
  orderingProviderExceptionRequest?: boolean;
  setFacilityExceptionRequest?: React.Dispatch<SetStateAction<boolean>>;
  careParticipantExceptionRequest?: boolean;
  setCareParticipantExceptionRequest?: React.Dispatch<SetStateAction<boolean>>;
  setPerformingProviderExceptionRequest?: React.Dispatch<SetStateAction<boolean>>;
  setOrderingProviderExceptionRequest?: React.Dispatch<SetStateAction<boolean>>;
  setDisableContinueButtonOnContinuation?: Dispatch<SetStateAction<boolean>>;
}> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function AuthorizationDetail({
  authorization,
  serviceRequest,
  priorAuthRequirements,
  setPriorAuthRequirements,
  onUpdateDiagnosisCodes,
  facilityBasedWorkflow,
  formContent,
  setFormContent,
  performingProviderExceptionRequest,
  facilityExceptionRequest,
  orderingProviderExceptionRequest,
  setFacilityExceptionRequest,
  careParticipantExceptionRequest,
  setCareParticipantExceptionRequest,
  setPerformingProviderExceptionRequest,
  setOrderingProviderExceptionRequest,
  setDisableContinueButtonOnContinuation,
}: Props) {
  const classes = useStyles();
  const outPatientSpecificFeatureFlag = useFeature("outPatientSpecificFF");
  const [editing, setEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [attemptedSubmit, setAttemptedSubmit] = useState<boolean>(false);

  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  // Checks if ordering provider and performing provider is same
  const [sameProviders, setSameProviders] = useState(false);
  const previousLoading = usePrevious(loading);
  const isOutPatientEncounterType =
    serviceRequest?.encounterType === "OUTPATIENT" && outPatientSpecificFeatureFlag ? true : false;

  // Allow edits on inpatient requests
  const showEdit =
    (facilityBasedWorkflow || isOutPatientEncounterType) &&
    priorAuthRequirements &&
    setPriorAuthRequirements &&
    !editing;

  // Checking if the Diagnosis and Provider card is on continuation forms
  const isDiagnosisAndProviderCardOnContinuation =
    // Checking if the workflow step is "FILL_FORMS_CONTINUATION" or "CLINICAL_ASSESSMENT_CONTINUATION"
    serviceRequest?.workflowStep === "FILL_FORMS_CONTINUATION" ||
    serviceRequest?.workflowStep === "CLINICAL_ASSESSMENT_CONTINUATION"
      ? // If true, set isDiagnosisAndProviderCardOnContinuation to true; otherwise, set it to false
        true
      : false;

  useEffect(() => {
    if (formContent?.orderingProvider?.id === formContent?.performingProvider?.id) {
      setSameProviders(true);
    } else {
      setSameProviders(false);
    }
  }, [formContent?.orderingProvider?.id, formContent?.performingProvider?.id, setSameProviders]);

  const onCancelClick = () => {
    setEditing(false);
    setAttemptedSubmit?.(false);
    setDisableContinueButtonOnContinuation?.(false);
    setPerformingProviderExceptionRequest?.(
      !!serviceRequest?.performingProviderLocation?.outOfNetworkExceptionReason || false
    );
    setFacilityExceptionRequest?.(!!serviceRequest?.facilityLocation?.outOfNetworkExceptionReason || false);
    setOrderingProviderExceptionRequest?.(
      !!serviceRequest?.orderingProviderLocation?.outOfNetworkExceptionReason || false
    );
    if (serviceRequest && formContent) {
      formContent.placeOfService = serviceRequest?.placeOfService;
      formContent.secondaryDiagnosisCodes = serviceRequest?.secondaryDiagnoses;
      formContent.selectedOrderingProvider = serviceRequest?.selectedOrderingProvider;
      formContent.orderingProvider = serviceRequest?.orderingProvider;
      formContent.selectedPerformingProvider = serviceRequest?.selectedPerformingProvider;
      formContent.performingProvider = serviceRequest?.performingProvider;
      formContent.orderingProviderSelectedTin = serviceRequest?.orderingProviderSelectedTin;
      formContent.orderingProviderSelectedAddress = serviceRequest?.orderingProviderLocation;
      formContent.performingProviderSelectedTin = serviceRequest?.performingProviderSelectedTin;
      formContent.performingProviderSelectedAddress = serviceRequest?.performingProviderLocation;
      formContent.selectedFacility = serviceRequest?.selectedFacility;
      formContent.facility = serviceRequest?.facility;
      formContent.facilitySelectedTin = serviceRequest?.facilitySelectedTin;
      formContent.facilitySelectedAddress = serviceRequest?.facilityLocation;
    }
  };

  const onSaveClick = async () => {
    if (priorAuthRequirements || formContent) {
      setAttemptedSubmit?.(true);
      if (serviceRequest && isValid) {
        setLoading(true);
        await patchServiceRequest(
          {
            primarySemanticDiagnosisCode: priorAuthRequirements?.primaryDiagnosis,
            secondarySemanticDiagnosisCodes: priorAuthRequirements?.secondaryDiagnoses,
            placeOfService: formContent?.placeOfService?.id || undefined,
            selectedOrderingProvider: formContent?.selectedOrderingProvider || undefined,
            selectedPerformingProvider: formContent?.selectedPerformingProvider || undefined,
            selectedFacility: formContent?.selectedFacility || undefined,
          },
          { pathParams: { id: serviceRequest.id } }
        );
        onUpdateDiagnosisCodes?.();
        setLoading(false);
        setDisableContinueButtonOnContinuation?.(false);
      }
    }
  };

  const { mutate: patchServiceRequest } = useUpdateServiceRequest({ id: "" });

  // Wait for loading to go from true => false (set loading in the /save callback)
  useEffect(() => {
    if (previousLoading && !loading) {
      setEditing(false);
    }
  }, [loading, previousLoading]);

  // Getting the health plan name using the getPatientHealthPlanName function
  // and providing the patient information and the parsed start date from the service request
  const healthPlan =
    getPatientHealthPlanName(serviceRequest?.patient, parseDateFromISOString(serviceRequest?.startDate)) ||
    // If the above expression is falsy, use the healthPlanName from the serviceRequest object
    serviceRequest?.healthPlanName ||
    // If both expressions are falsy, default to an empty string
    "";

  // Destructuring the result of the useServiceRequestConfigSpec hook
  const { formFieldConfigurations, outOfNetworkCheckConfiguration } = useServiceRequestConfigSpec({
    // Spread operator to include properties from formContent
    ...formContent,

    // Setting the patientId based on the serviceRequest object
    patientId: serviceRequest?.patient?.id || "",

    // Setting the healthPlanName based on the healthPlan variable
    healthPlanName: healthPlan || "",
  });

  const authCategoryClinicalService = formContent?.clinicalServices?.find((clinicalService) =>
    Boolean(clinicalService.authCategory)
  );
  const clinicalServiceId = authCategoryClinicalService
    ? authCategoryClinicalService.id
    : formContent?.clinicalServices && formContent?.clinicalServices.length > 0
    ? formContent.clinicalServices[0].id
    : undefined;

  // Destructuring the result of the useGetPlaceOfServices hook
  const { data: placeOfServiceData, loading: placeOfServiceLoading } = useGetPlaceOfServices({
    // Providing parameters for the hook
    queryParams: {
      // Including healthPlanName in queryParams, using undefined if healthPlan is falsy
      healthPlanName: healthPlan || undefined,

      // Determining encounterType based on formContent
      encounterType: formContent.isInpatient ? "INPATIENT" : "OUTPATIENT",

      clinicalServiceId: clinicalServiceId,
    },
  });

  // Destructuring the result of the useServiceRequestFormRedirectDispatch hook
  const {
    dispatchRedirectRuleCheck, // Function to dispatch redirect rule check
  } = useServiceRequestFormRedirectDispatch({
    // Parameters for the hook
    workflowId: serviceRequest?.workflowId, // Workflow ID obtained from the service request object
    formContent, // Content related to the service request form
    clinicalService: formContent.clinicalServices?.[0], // The first clinical service from the form content
    healthPlanName: healthPlan, // Health plan name obtained earlier
    patient: serviceRequest?.patient, // Patient information obtained from the service request object
    authStage: convertStringToAuthBuilderWorkflowStep("FILL_FORMS_CONTINUATION"), // Authentication stage conversion
  });

  // Checking if a facility Out-Of-Network exception comment is required
  const requiresFacilityOONExceptionComment =
    // Checking if the selected facility's selected location is Out-Of-Network
    formContent.selectedFacility?.selectedLocation?.isOutOfNetwork &&
    // Checking if the Out-Of-Network exception reason is "Other (comment is required)"
    formContent.selectedFacility?.selectedLocation?.outOfNetworkExceptionReason === "Other (comment is required)";

  // Checking if a Performing Provider Out-Of-Network exception comment is required
  const requiresPerformingProviderOONExceptionComment =
    // Checking if the selected performing provider's selected location is Out-Of-Network
    formContent.selectedPerformingProvider?.selectedLocation?.isOutOfNetwork &&
    // Checking if the Out-Of-Network exception reason is "Other (comment is required)"
    formContent.selectedPerformingProvider?.selectedLocation?.outOfNetworkExceptionReason ===
      "Other (comment is required)";

  // Checking if a ordering provider Out-Of-Network exception comment is required
  const requiresOrderingProviderOONExceptionComment = isExceptionCommentRequired(formContent);

  // Wait for loading to go from true => false (set loading in the /save callback)
  useEffect(() => {
    if (previousLoading && !loading) {
      setEditing(false);
    }
  }, [loading, previousLoading]);

  const hasOrderingProvider = Boolean(formContent.orderingProvider);
  const hasPerformingProvider = Boolean(formContent.performingProvider);
  const skipManualNpiTin = useFeature("skipManualNpiTin");
  const canSkipManualNpiTin = useAuthorized("CAN_SKIP_MANUAL_NPI_TIN");
  const isGeisingerPatient = serviceRequest?.healthPlanName === "Geisinger";
  const canSkipValidationForNpiTin = skipManualNpiTin && isGeisingerPatient && canSkipManualNpiTin;
  // This weird condition may be due to something broken within react. Refer https://github.com/facebook/react/issues/21328 or https://github.com/facebook/react/issues/19476
  // Upgrading typescript eslint did not do the trick
  const hasFacilityNpi =
    formContent?.facility?.manuallyCreated && canSkipValidationForNpiTin && !formContent?.facility?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent.facility?.npi);

  const hasPerformingProviderNpi =
    formContent?.performingProvider?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.performingProvider?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent.performingProvider?.npi);
  const hasFacilityTin =
    formContent?.facility?.manuallyCreated && canSkipValidationForNpiTin && !formContent?.facilitySelectedTin
      ? canSkipValidationForNpiTin
      : formContent.facility?.tinList?.length === 0
      ? true
      : Boolean(formContent.facilitySelectedTin);
  const hasFacilityAddress = Boolean(
    formContent.facilitySelectedAddress?.address || formContent.selectedFacility?.selectedLocation?.address
  );
  const hasOrderingProviderTin =
    formContent?.orderingProvider?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.orderingProviderSelectedTin
      ? canSkipValidationForNpiTin
      : formContent.orderingProvider?.tinList?.length === 0
      ? true
      : Boolean(formContent.orderingProviderSelectedTin);
  const hasOrderingProviderAddress = Boolean(
    formContent.orderingProviderSelectedAddress?.address ||
      formContent.selectedOrderingProvider?.selectedLocation?.address
  );
  const hasOrderingProviderNpi =
    formContent?.orderingProvider?.manuallyCreated && canSkipValidationForNpiTin && !formContent?.orderingProvider?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent.orderingProvider?.npi);
  const hasFacility = Boolean(formContent.facility);
  const shouldValidatePerformingProviderTin = formFieldConfigurations?.performingProviderTIN.fieldSpec !== "OMIT";
  const performingProviderTinLength = formContent.performingProvider?.tinList?.length;
  const hasPerformingProviderTin =
    formContent?.performingProvider?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.performingProviderSelectedTin
      ? canSkipValidationForNpiTin
      : performingProviderTinLength === 0
      ? true
      : Boolean(formContent.performingProviderSelectedTin);
  const hasPerformingProviderAddress = Boolean(
    formContent.performingProviderSelectedAddress?.address ||
      formContent.selectedPerformingProvider?.selectedLocation?.address
  );
  const hasProviderOONExceptionReason = Boolean(formContent?.performingProviderSelectedAddress?.isOutOfNetwork);
  const hasOrderingProviderOONExceptionReason = Boolean(formContent?.orderingProviderSelectedAddress?.isOutOfNetwork);
  const hasFacilityOONExceptionComment = formContent.selectedFacility?.selectedLocation?.outOfNetworkExceptionComment;
  const hasOrderingProviderOONExceptionComment =
    formContent.selectedOrderingProvider?.selectedLocation?.outOfNetworkExceptionComment;
  const hasPerformingProviderOONExceptionComment =
    formContent.selectedPerformingProvider?.selectedLocation?.outOfNetworkExceptionComment;
  const hasPerformingProviderExceptionRequestOnContinuation = formContent?.performingProviderSelectedAddress
    ?.isOutOfNetwork
    ? performingProviderExceptionRequest
    : false;

  const hasFacilityExceptionRequestOnContinuation = formContent?.facilitySelectedAddress?.isOutOfNetwork
    ? facilityExceptionRequest
    : false;

  const hasOrderingProviderExceptionRequestOnContinuation = formContent?.orderingProviderSelectedAddress?.isOutOfNetwork
    ? orderingProviderExceptionRequest
    : false;

  const VALIDATION_MAP: Record<ServiceRequestFieldName, boolean> = {
    expeditedTatUpdateTimestamp: true,
    placeOfService: true,
    orderingProvider: fieldIsValid(formFieldConfigurations?.orderingProvider, hasOrderingProvider),
    orderingProviderTIN:
      (formContent.orderingProvider?.source === "CMS" && !formContent.orderingProvider.manuallyCreated) ||
      fieldIsValid(formFieldConfigurations?.orderingProviderTIN, hasOrderingProviderTin),
    orderingProviderNPI: fieldIsValid(formFieldConfigurations?.orderingProviderNPI, hasOrderingProviderNpi),
    orderingProviderAddress: fieldIsValid(formFieldConfigurations?.orderingProviderAddress, hasOrderingProviderAddress),
    performingProvider: fieldIsValid(formFieldConfigurations?.performingProvider, hasPerformingProvider),
    performingProviderNPI: validatePerformingProviderNPI(
      formFieldConfigurations,
      formContent,
      hasPerformingProviderNpi
    ),
    performingProviderTIN:
      (formContent.performingProvider?.source === "CMS" && !formContent.performingProvider.manuallyCreated) ||
      (shouldValidatePerformingProviderTin && !!performingProviderTinLength
        ? !!formContent.performingProvider && performingProviderTinLength > 0
          ? !!formContent.performingProviderSelectedTin
          : fieldIsValid(formFieldConfigurations?.performingProviderTIN, hasPerformingProviderTin)
        : true),
    performingProviderAddress: validatePerformingProviderAddress(
      formFieldConfigurations,
      formContent,
      healthPlan,
      hasPerformingProviderAddress
    ),
    facility: fieldIsValid(formFieldConfigurations?.facility, hasFacility),
    facilityNPI: fieldIsValid(formFieldConfigurations?.facilityNPI, hasFacilityNpi),
    facilityTIN:
      (formContent.facility?.source === "CMS" && !formContent.facility.manuallyCreated) ||
      fieldIsValid(formFieldConfigurations?.facilityTIN, hasFacilityTin),
    facilityAddress: fieldIsValid(formFieldConfigurations?.facilityAddress, hasFacilityAddress),
    facilityOutOfNetworkExceptionReason:
      (facilityExceptionRequest && !formContent?.facilityOONExceptionRequired) ||
      (formContent?.facilityOONExceptionRequired && hasFacilityExceptionRequestOnContinuation)
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, !!formContent?.facilitySelectedAddress?.outOfNetworkExceptionReason)
        : fieldIsValid(formFieldConfigurations?.facilityOutOfNetworkExceptionReason),
    performingProviderOutOfNetworkExceptionReason:
      (hasPerformingProviderExceptionRequestOnContinuation && !formContent?.performingProviderOONExceptionRequired) ||
      (formContent?.performingProviderOONExceptionRequired && hasProviderOONExceptionReason)
        ? fieldIsValid(
            { fieldSpec: "REQUIRED" },
            !!formContent?.performingProviderSelectedAddress?.outOfNetworkExceptionReason
          )
        : fieldIsValid(formFieldConfigurations?.performingProviderOutOfNetworkExceptionReason),

    orderingProviderOutOfNetworkExceptionReason:
      (hasOrderingProviderExceptionRequestOnContinuation && !formContent?.orderingProviderOONExceptionRequired) ||
      (formContent?.orderingProviderOONExceptionRequired && hasOrderingProviderOONExceptionReason)
        ? fieldIsValid(
            { fieldSpec: "REQUIRED" },
            !!formContent?.orderingProviderSelectedAddress?.outOfNetworkExceptionReason
          )
        : fieldIsValid(formFieldConfigurations?.orderingProviderOutOfNetworkExceptionReason),

    facilityOutOfNetworkExceptionComment:
      (requiresFacilityOONExceptionComment && !!hasFacilityOONExceptionComment) || !requiresFacilityOONExceptionComment,
    performingProviderOutOfNetworkExceptionComment:
      (requiresPerformingProviderOONExceptionComment && !!hasPerformingProviderOONExceptionComment) ||
      !requiresPerformingProviderOONExceptionComment,
    orderingProviderOutOfNetworkExceptionComment:
      (requiresOrderingProviderOONExceptionComment && !!hasOrderingProviderOONExceptionComment) ||
      !requiresOrderingProviderOONExceptionComment,
    careParticipantOutOfNetworkExceptionReason: formContent.additionalCareParticipants
      ? (careParticipantExceptionRequest &&
          !isAnyCareParticipantOONExceptionRequired(formContent.additionalCareParticipants)) ||
        validateOONExceptionReasonFieldForCareParticipants(
          formContent.additionalCareParticipants,
          true,
          careParticipantExceptionRequest
        )
      : true,
    careParticipantOutOfNetworkExceptionComment: formContent.additionalCareParticipants
      ? validateOONExceptionCommentFieldForCareParticipants(formContent.additionalCareParticipants)
      : true,
    singleClinicalService: true,
    encounterType: true,
    primaryDiagnosis: true,
    secondaryDiagnoses: true,
    procedureCodes: true,
    recurring: true,
    startEndDate: true,
    units: true,
    urgency: true,
    behavioralHealthReviewType: true,
    behavioralHealthAdmissionType: true,
    admissionDischargeDate: true,
    faxInputField: true,
    procedureCodeWithUnits: true,
    patientStayDateRanges: true,
    authCategory: true,
    authSubCategory: true,
    prescribedDrug: true,
    outOfNetworkCheck: true,
    facilityOutOfNetworkStatusDisplay: true,
    performingProviderOutOfNetworkStatusDisplay: true,
    orderingProviderOutOfNetworkStatusDisplay: true,
    careParticipantOutOfNetworkStatusDisplay: true,
    outOfNetworkOrderingProvider: true,
    userDeclaredOONException: true,
    admissionDate: true,
    admissionTime: true,
    dischargeDate: true,
    dischargeTime: true,
    dischargedTo: true,
    expectedAdmissionDate: true,
    expectedDischargeDate: true,
    additionalCareParticipants: true,
    nonPalCheckbox: true,
    blockUserIfExceedsRecommendedEndDate: true,
    userSelectedOONException: true,
  };

  // Function to validate fields based on form configurations
  const validateFields = () => {
    // Obtain form configurations
    const formConfig = formFieldConfigurations;

    // Check if form configurations exist
    if (formConfig) {
      // Extract keys from form configurations
      const formConfigKeys = Object.keys(formConfig) as ServiceRequestFieldName[];

      // Iterate through form configuration keys
      for (const key of formConfigKeys) {
        // If there is no validation map entry for the current key, return false
        if (!VALIDATION_MAP[key]) {
          return false;
        }
      }

      // If all keys have corresponding entries in the validation map, return true
      return true;
    }

    // Should never happen, but if it does, block the user from submitting by returning false
    return false;
  };

  // Validate fields and store the result in the isValid variable
  const isValid = validateFields();

  // Define a callback function for handling form validation
  const handleFormValidation = useCallback(
    // The callback function takes a boolean parameter (isFormValid)
    (isFormValid: boolean) => {
      // Set whether service request forms can be submitted based on the form validation result
      setAttemptedSubmit(isFormValid);
    },
    // Dependency array for the useCallback hook
    [setAttemptedSubmit]
  );

  // Effect hook to handle form validation when isValid or handleFormValidation changes
  useEffect(() => {
    // Call the handleFormValidation callback with the current validity status (isValid)
    handleFormValidation(isValid);
    // Dependencies array ensures that the effect runs when isValid or handleFormValidation changes
  }, [isValid, handleFormValidation]);

  return (
    // TODO: remove the padding once we switch from common package themes to design-system themes!!!
    <>
      {editing ? (
        <CohereCard
          sx={{
            boxShadow: "0px 2px 2px rgba(207, 226, 231, 0.8)",
            padding: isFaxAuthBuilderFlow ? "24px 0px" : "24px",
          }}
          noBorder={isFaxAuthBuilderFlow}
          noBoxShadow={isFaxAuthBuilderFlow}
        >
          {(facilityBasedWorkflow || isOutPatientEncounterType) && editing && (
            <Grid container alignItems={"flex-end"} justifyContent={"flex-end"}>
              <Grid item>
                <CancelButton
                  warning
                  onClick={() => {
                    onCancelClick();
                  }}
                >
                  Cancel
                </CancelButton>
              </Grid>
            </Grid>
          )}
          {(facilityBasedWorkflow || isOutPatientEncounterType) &&
            editing &&
            priorAuthRequirements &&
            setPriorAuthRequirements && (
              <div style={{ marginTop: "24px" }}>
                <DiagnosisCodesSection
                  priorAuthRequirements={priorAuthRequirements}
                  setPriorAuthRequirements={setPriorAuthRequirements}
                  attemptedSubmit={false}
                  resetPalResults={() => {}}
                  hideHeader={true}
                  isOutPatientEncounterType={isOutPatientEncounterType}
                />
              </div>
            )}
          <div style={{ paddingTop: "32px" }}>
            {(facilityBasedWorkflow || isOutPatientEncounterType) &&
              editing &&
              priorAuthRequirements &&
              setPriorAuthRequirements && (
                <ProvidersCardWrapper
                  authStatus={serviceRequest.authStatus || "DRAFT"}
                  formContent={formContent}
                  isInpatient={formContent?.isInpatient}
                  sameProviders={sameProviders}
                  setSameProviders={setSameProviders}
                  placeOfService={formContent?.placeOfService || null}
                  setFormContent={setFormContent}
                  attemptedSubmit={attemptedSubmit}
                  facilityIsOptional={formFieldConfigurations?.facility.fieldSpec === "OPTIONAL"}
                  performingIsOptional={formFieldConfigurations?.performingProvider.fieldSpec === "OPTIONAL"}
                  placeOfServiceLoading={placeOfServiceLoading}
                  availablePlacesOfService={placeOfServiceData || undefined}
                  setPlaceOfService={(pos) => setFormContent((prev) => ({ ...prev, placeOfService: pos }))}
                  dispatchRedirectRuleCheck={dispatchRedirectRuleCheck}
                  VALIDATION_MAP={VALIDATION_MAP}
                  formFieldConfigurations={formFieldConfigurations}
                  outOfNetworkCheckConfiguration={outOfNetworkCheckConfiguration}
                  patient={serviceRequest?.patient}
                  delegatedVendorName={serviceRequest?.delegatedVendor}
                  workflowId={serviceRequest?.workflowId}
                  isDiagnosisAndProviderCardOnContinuation={isDiagnosisAndProviderCardOnContinuation}
                  setPriorAuthRequirements={setPriorAuthRequirements}
                  isFacilityOutOfNetworkCommentRequired={requiresFacilityOONExceptionComment}
                  isPerformingProviderOutOfNetworkCommentRequired={requiresPerformingProviderOONExceptionComment}
                  performingProviderExceptionRequest={performingProviderExceptionRequest}
                  setPerformingProviderExceptionRequest={setPerformingProviderExceptionRequest}
                  facilityExceptionRequest={facilityExceptionRequest}
                  setFacilityExceptionRequest={setFacilityExceptionRequest}
                  orderingProviderExceptionRequest={orderingProviderExceptionRequest}
                  setOrderingProviderExceptionRequest={setOrderingProviderExceptionRequest}
                  careParticipantExceptionRequest={careParticipantExceptionRequest}
                  setCareParticipantExceptionRequest={setCareParticipantExceptionRequest}
                />
              )}
          </div>

          {(facilityBasedWorkflow || isOutPatientEncounterType) && editing && (
            <Row container justifyContent="flex-start" alignItems="center" style={{ paddingTop: "40px" }}>
              <Grid item style={{ paddingRight: "24px" }}>
                <SubmitButton
                  onClick={() => {
                    onSaveClick();
                  }}
                  loading={loading}
                  disabled={loading || (attemptedSubmit && !isValid)}
                >
                  Save
                </SubmitButton>
              </Grid>

              {attemptedSubmit && !isValid && (
                <Grid item className={classes.secondaryContainer}>
                  <Caption className={classes.caption}>{"There are some errors with the details above. "}</Caption>
                </Grid>
              )}
            </Row>
          )}
        </CohereCard>
      ) : (
        <>
          <CohereCard
            sx={{
              boxShadow: "0px 2px 2px rgba(207, 226, 231, 0.8)",
              padding: isFaxAuthBuilderFlow ? "24px 0px" : "24px",
            }}
            noBorder={isFaxAuthBuilderFlow}
            noBoxShadow={isFaxAuthBuilderFlow}
          >
            {(facilityBasedWorkflow || isOutPatientEncounterType) && showEdit && (
              <EditButtonContainer>
                <EditButton
                  disabled={false}
                  startIcon={<PencilIcon />}
                  onClick={() => {
                    setEditing(true);
                    setAttemptedSubmit?.(false);
                    setDisableContinueButtonOnContinuation?.(true);
                  }}
                >
                  <Body2>Edit</Body2>
                </EditButton>
              </EditButtonContainer>
            )}
            <ReadOnlyRequestDetailPage
              facilityBasedWorkflow={facilityBasedWorkflow}
              showEdit={showEdit}
              serviceRequest={serviceRequest}
              authorization={authorization}
              editing={editing}
              isOutPatientEncounterType={isOutPatientEncounterType}
            />
          </CohereCard>
        </>
      )}
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const EditButtonContainer = styled("div")(({ theme }) => ({
  position: "relative",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const EditButton = styled(InlineButton)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CancelButton = styled(SecondaryButton)(({ theme }) => ({
  width: theme.spacing(19),
  marginBottom: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SubmitButton = styled(PrimaryButton)(({ theme }) => ({
  width: theme.spacing(19),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
