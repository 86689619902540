import { formatDateStr } from "@coherehealth/common";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import _ from "lodash";
import { HistoryData, HistoryNote, HistoryOutreach, HistoryReview } from "./NotesCard/notesCardTypes";

export const buildSrGroupingTitle = (serviceRequest?: ServiceRequestResponse, hasContinuations?: boolean) => {
  if (hasContinuations && serviceRequest?.encounterType === "OUTPATIENT") {
    if (serviceRequest?.requestType === "INITIAL") {
      return `Reviewing Initial Request \u2022 ${serviceRequest.cohereId}`;
    } else if (serviceRequest?.requestType === "CONTINUATION") {
      return `Reviewing Continuation \u2022 ${serviceRequest.cohereId}`;
    }
  } else if (serviceRequest?.encounterType === "INPATIENT" && serviceRequest?.requestType === "INITIAL") {
    return serviceRequest.requestTiming === "PRE_SERVICE"
      ? `Reviewing Procedure Codes and Initial Admission`
      : `Reviewing Initial Admission`;
  } else if (serviceRequest?.encounterType === "INPATIENT" && serviceRequest?.requestType === "CONTINUATION") {
    return `Reviewing Continued Stay \u2022 ${formatDateStr(serviceRequest?.startDate)} - ${formatDateStr(
      serviceRequest.endDate
    )}`;
  }
  return "";
};

export const sortedReviewTimelineData = (historyData: (HistoryData | undefined)[]): HistoryData[] => {
  if (historyData === undefined) {
    return [];
  }
  const groupedByServiceRequest = _.groupBy(historyData, (item) => item?.serviceRequestId);
  const cleanedGroupedByServiceRequest = _.omitBy(
    groupedByServiceRequest,
    (value, key) => key === "null" || key === "undefined"
  );
  Object.values(cleanedGroupedByServiceRequest).forEach((group) => {
    group.sort((a, b) => {
      const dateA = new Date(a?.lastUpdated ?? "").getTime();
      const dateB = new Date(b?.lastUpdated ?? "").getTime();
      return dateB - dateA;
    });
  });
  const flattenedSortedData: (HistoryReview | HistoryNote | HistoryOutreach)[] = [];
  Object.values(cleanedGroupedByServiceRequest).forEach((group) => {
    group.forEach((item, index) => {
      const isNewServiceRequest = index === 0;
      flattenedSortedData.push({
        ...item,
        isNewServiceRequest,
      } as HistoryReview | HistoryNote | HistoryOutreach);
    });
  });

  flattenedSortedData.sort((a, b) => {
    const dateA = new Date(a?.srDateCreated ?? "").getTime();
    const dateB = new Date(b?.srDateCreated ?? "").getTime();
    return dateB - dateA;
  });
  return flattenedSortedData;
};

export const convertOutcomeLabelText = (outreachOutcome?: string) => {
  if (outreachOutcome === undefined) {
    return "";
  }
  let labelText;
  switch (outreachOutcome) {
    case "SUCCESS":
      labelText = "Success";
      break;
    case "FAILED":
      labelText = "Failed";
      break;
    case "OFFICE_STAFF_MEMBER_NOTIFIED":
      labelText = "Office Staff Member Notified";
      break;
    case "LEFT_SCRIPTED_MESSAGE":
      labelText = "Left Scripted Message";
      break;
    case "P2P_RIGHTS_GIVEN":
      labelText = "P2P Rights Given";
      break;
    case "UNABLE_TO_REACH":
      labelText = "Unable To Reach";
      break;
    case "MEMBER_NOTIFIED":
      labelText = "Member Notified";
      break;
    case "AUTHORIZED_REP_NOTIFIED":
      labelText = "Authorized Representative Notified";
      break;
    default:
      labelText = "Unable To Reach - No Answer";
  }
  return labelText;
};

export const convertOutreachTypeText = (outreachType?: string) => {
  if (outreachType === undefined) {
    return "";
  }
  let outreachTypeText;
  switch (outreachType) {
    case "CREATE_A_NEW_SUBMISSION":
      outreachTypeText = "Create a New Submission";
      break;
    case "EXISTING_AUTH_STATUS_INQUIRY":
      outreachTypeText = "Existing Auth - Status Inquiry";
      break;
    case "EXISTING_AUTH_UPDATE":
      outreachTypeText = "Existing Auth Update";
      break;
    case "P2P_SCHEDULING":
      outreachTypeText = "Peer to Peer Scheduling";
      break;
    case "SCHEDULING_OUTREACH":
      outreachTypeText = "Scheduling Outreach";
      break;
    case "OTHER":
      outreachTypeText = "Other";
      break;
    case "MISSING_INFO_CLINICAL":
      outreachTypeText = "Missing Information - Clinical";
      break;
    case "MISSING_INFO_NON_CLINICAL":
      outreachTypeText = "Missing Information - Non-Clinical";
      break;
    case "NOTIFICATION":
      outreachTypeText = "Notification";
      break;
    default:
      outreachTypeText = "Nudges";
  }
  return outreachTypeText;
};
