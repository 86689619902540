import React, { useCallback, useEffect, useState, useMemo, useContext } from "react";

import {
  DenialsInfo,
  MdReview,
  useDiscardMdReview,
  useSubmitMdReview,
  useUpdateMdReview,
  useUserActivityBatchUpdate,
  ReviewType,
  ServiceRequestResponse,
  ServiceRequestUpdatePayload,
  AuthStatus,
  MdReviewSubmitPayload,
} from "@coherehealth/core-platform-api";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  colorsLight,
  InformativeModal,
  useFeature,
  createBatchActivities,
  getEmptyUserClickTracking,
  queueMgmtBaseUrl,
  GEISINGER_NAME,
  getNoTimezoneDateTimeString,
  formatDateToISODate,
} from "@coherehealth/common";
import Collapse from "@material-ui/core/Collapse";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import GuidelineReview from "../GuidelineReview/GuidelineReview";
import MDReviewReadonly from "./MDReviewReadonly";
import MDReviewEdit from "./MDReviewEdit";
import ReviewHeader from "../ReviewHeader/ReviewHeader";
import { useTrackUserInteraction } from "util/userActivityTracker";
import { guidelineSnapshotFromReview } from "../index";
import { UNLISTED_GUIDELINE_ID } from "./GuidelinesUsedSection";
import { generatePath, useLocation } from "react-router-dom";
import routes from "routes";
import { useMdReview, MdReviewUpdate } from "components/ClinicalReview/reviewUtils/useReviews";
import { useCompleteServiceCase, useUpdateCasesAfterReview } from "@coherehealth/qm-api";
import config from "api/config";
import { OutreachAttemptProps } from "../useOutreachAttempt";
import {
  ReviewCommonProps,
  ReviewReadOnlyCommonProps,
  useContinueServiceRequestTillNextReviewDate,
  useGetUser,
} from "components/ClinicalReview/reviewUtils/utils";
import { last } from "lodash";
import DetailedHardRedirectClinicalReviewModal, {
  ClinicalReviewErrorDetails,
} from "../Modals/DetailedHardRedirectClinicalReviewModal";
import { defaultClinicalReviewErrorState, extractErrorDetails } from "../util/ReviewSectionUtil";
import { useProcedureCodesState } from "../util/useProcedureCodesState";
import usePersistAttachmentHighlights from "components/ClinicalReview/Review/hooks/usePersistAttachmentHighlights";
import {
  isUnplannedInpatientRequest,
  patientAdmittedOrDischarged,
  patientNotYetAdmittedForInpatient,
} from "util/serviceRequest";
import { redirectToPatientSummaryFromReview, routeToPatientSummaryFromReview } from "util/routeUtils/routeUtils";
import { MdModal } from "./MDReviewModal/MdModal";
import { error as logError, stringifyError } from "logger";
import { ClinicalReviewContext } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { Box } from "@material-ui/core";

/**
 * Please put common props to reviews inside ReviewCommon Props
 */
export interface MDReviewComponentProps extends ReviewCommonProps, Partial<OutreachAttemptProps> {
  serviceRequest: ServiceRequestResponse;
  mdReview: MdReview;
  existingReviews?: ReviewType[] | null;
  willAutoApprove?: boolean;
  newDenialsWorkflow?: boolean;
  allReviews?: ReviewType[];
  isQmUserAudited?: boolean;
  hasOutreachAttempt?: boolean;
  setHasOutreachAttempt?: React.Dispatch<React.SetStateAction<boolean>>;
  nextReviewDate?: string;
}

interface MDReviewEditableProps extends Omit<MDReviewComponentProps, "mdReview" | "setMdReview"> {
  mdReviewId: string;
}
interface MdReviewEditorProps extends Omit<MDReviewComponentProps, "setMdReview"> {
  setMdReview: MdReviewUpdate;
}

/**
 * Please put common read only props to reviews inside ReviewReadOnlyCommonProps
 */
interface MdReviewReadonlyProps extends ReviewReadOnlyCommonProps {
  serviceRequest: ServiceRequestResponse;
  mdReview: MdReview;
  clinicalReviewView?: boolean;
}

const MDReviewComponent = ({ mdReview, ...otherProps }: MDReviewComponentProps) => {
  if (mdReview?.reviewStatus === "DRAFT") {
    return <MDReviewEditable {...otherProps} mdReviewId={mdReview.id} />;
  } else if (mdReview?.reviewStatus === "COMPLETE" || mdReview?.reviewStatus === "DISCARDED") {
    return <MdReviewReadonly mdReview={mdReview} {...otherProps} />;
  } else {
    return null;
  }
};

const MDReviewEditable = ({ mdReviewId, ...otherProps }: MDReviewEditableProps) => {
  const [mdReview, setMdReview] = useMdReview({ mdReviewId });
  if (!mdReview || !setMdReview) {
    return null;
  }
  return <MDReviewEditor {...otherProps} mdReview={mdReview} setMdReview={setMdReview} />;
};

function MDReviewEditor({
  serviceRequest,
  mdReview,
  existingReviews,
  setMdReview,
  expanded,
  toggleExpanded,
  onFinishEditing,
  willAutoApprove,
  newDenialsWorkflow,
  allReviews,
  clinicalReviewView,
  userClickInfoTracking,
  setUserClickInfoTracking,
  searchInfos,
  setSearchInfos,
  reviewRef,
  expandableGuidelines,
  copiedAttachmentText,
  pastedAttachmentTexts,
  setPastedAttachmentTexts,
  submitHighlights,
  submittingHighlights,
  getHighlightPayload,
  attachmentGuidelineTextHighlightsSnapshot,
  isQmUserAudited = false,
  patientStayDateRanges,
  setPatientStayDateRanges,
  patientStaysErrorStates,
  isStayDatesValid,
  isDischargeDetailsValid,
  isDischarged,
  setIsDischarged,
  dischargeDetails,
  setDischargeDetails,
  buildAuthorizationDischargeDetails,
  reviewDetailsCollapseStyle,
  facilityBasedFeatureEnabled,
  outreachAttempt,
  setOutreachAttempt,
  saveOutreachAttempt,
  outreachAttemptLoading,
  outreachFormConfig,
  outreachFormErrors,
  setOutreachFormErrors,
  validateOutreachForm,
  hasOutreachAttempt,
  setHasOutreachAttempt,
  authorization,
  nextReviewDate,
  checkEmptyRequestedLoc,
  continuationConfiguration,
  patientStayManager,
}: MdReviewEditorProps) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const caseId = search.get("caseId");
  const { mutate: completeServiceCase, loading: completingServiceCase } = useCompleteServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: () => {
      window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
    },
  });
  const { mutate: updateCasesAfterReview } = useUpdateCasesAfterReview({
    base: `${config.QM_SERVICE_API_URL}`,
  });
  const user = useGetUser();
  const userId = user?.sub;
  const prePopulateDenialsTemplate = useFeature("prePopulateDenialsTemplate");
  const trackUserActivityInteraction = useTrackUserInteraction();

  const [sameGuidelines, setSameGuidelines] = useState<boolean | undefined>();
  const [isInheritingBlobReview, setIsInheritingBlobReview] = useState<boolean>(false);
  const [openHardRedirectOnSubmitModal, setOpenHardRedirectOnSubmitModal] = useState(false);
  const [duplicateReviewModalOpen, setDuplicateReviewModalOpen] = useState<boolean>(false);
  const [errorDetails, setErrorDetails] = useState<ClinicalReviewErrorDetails>(
    defaultClinicalReviewErrorState(serviceRequest.id, mdReview.id)
  );
  const isGhpRequest = serviceRequest.healthPlanName === GEISINGER_NAME;
  const isUnplannedInpatient = isUnplannedInpatientRequest(serviceRequest);
  const hideGuidelinesUsed = isGhpRequest;
  const [reviewSubmissionLoading, setReviewSubmissionLoading] = useState<boolean>(false);
  const { submitDraftHighlights, submittingDraftHighlights } = usePersistAttachmentHighlights();

  const isSrReadmission = serviceRequest.isReadmission;
  const srAnchorAuthNumber = serviceRequest.anchorAuthNumber;
  const [isReadmission, setIsReadmission] = useState<boolean>(isSrReadmission === undefined ? false : isSrReadmission);
  const [anchorAuthNumber, setAnchorAuthNumber] = useState<string>(
    srAnchorAuthNumber !== undefined ? srAnchorAuthNumber : ""
  );
  const [hasErrorWithAnchorAuth, setHasErrorWithAnchorAuth] = useState<boolean>(false);
  const isReadmissionFeatureEnabled = useFeature("readmissionIdentification");
  const multiCoverageReviews = useFeature("multiCoverageReviews");

  const { isMultiCoverageReviewRequired } = useContext(ClinicalReviewContext);

  const multiCoverageContent = multiCoverageReviews && isMultiCoverageReviewRequired;

  // For partial approval
  const {
    approvedProcedureCodes,
    pxCodesByClinicalService,
    approvedUnitsByClinicalService,
    updateApprovedUnitsByCS,
    updateSinglePxCodeUnit,
    approvedUnits,
    approvedPxCodesAreInvalid,
  } = useProcedureCodesState({ serviceRequest, existingReviews, currentReviewId: mdReview.id });

  const onFinishEditingInternal = useCallback(() => {
    toggleExpanded?.();
    onFinishEditing?.();
  }, [toggleExpanded, onFinishEditing]);

  // Hooks & callbacks to save a draft
  const {
    mutate: update,
    error: updateError,
    loading: updateLoading,
  } = useUpdateMdReview({
    reviewId: mdReview?.id,
    serviceRequestId: serviceRequest.id,
  });
  useEffect(() => {
    if (updateError) {
      enqueueSnackbar(`Error updating md review: ${updateError.message}`, { variant: "error" });
    }
  }, [updateError, enqueueSnackbar]);

  const { mutate: postBatchAcivities } = useUserActivityBatchUpdate({});

  const partialApprovedSemanticProcedureCodes = multiCoverageContent
    ? mdReview.approvedSemanticProcedureCodes
    : approvedProcedureCodes;

  const partialApprovedUnits = multiCoverageContent ? mdReview.approvedUnits : approvedUnits;

  const formatMdReview = useCallback(
    (denialsInfo?: DenialsInfo | null) => {
      const usedGuidelineIds = mdReview?.usedGuidelineIds || [""];
      const usedGuidelineIdSet = new Set(usedGuidelineIds);
      const wasUnlistedGuidelineUsed = usedGuidelineIdSet.has(UNLISTED_GUIDELINE_ID);
      usedGuidelineIdSet.delete("");
      usedGuidelineIdSet.delete(UNLISTED_GUIDELINE_ID);
      return {
        ...mdReview,
        approvedUnits:
          mdReview?.reviewOutcome === "RECOMMENDED_PARTIAL_APPROVAL" && Boolean(serviceRequest.units)
            ? partialApprovedUnits
            : undefined,
        approvedSemanticProcedureCodes:
          mdReview?.reviewOutcome === "RECOMMENDED_PARTIAL_APPROVAL"
            ? partialApprovedSemanticProcedureCodes
            : undefined,
        usedGuidelineIds: Array.from(usedGuidelineIdSet),
        wasUnlistedGuidelineUsed,
        ...((!denialsInfo || denialsInfo?.denialsTemplateId === mdReview?.denialsTemplate?.denialsTemplateId) &&
        !isInheritingBlobReview
          ? { denialsTemplate: mdReview?.denialsTemplate }
          : { denialsTemplate: denialsInfo }),
      };
    },
    [
      mdReview,
      serviceRequest.units,
      partialApprovedUnits,
      partialApprovedSemanticProcedureCodes,
      isInheritingBlobReview,
    ]
  );

  const saveMdReviewDraft = useCallback(
    async (denialsTemplate?: DenialsInfo | null) => {
      try {
        const res = await update({
          ...formatMdReview(denialsTemplate),
        });
        setMdReview(res);
        if (submitDraftHighlights) {
          await submitDraftHighlights();
        }
        userClickInfoTracking &&
          (await postBatchAcivities(
            createBatchActivities(
              "MD_REVIEW",
              "REVIEW_SUBMISSION",
              "INTERACTION",
              userClickInfoTracking,
              searchInfos,
              pastedAttachmentTexts,
              userId,
              res.id,
              serviceRequest.cohereId,
              serviceRequest.id
            )
          ));
        setUserClickInfoTracking?.(getEmptyUserClickTracking());
        setSearchInfos?.([]);
        onFinishEditingInternal();
        window.location.assign(
          generatePath(routes.DENIAL_REVIEW, {
            serviceRequestId: serviceRequest?.id,
          })
        );
      } catch {
        setIsSubmissionModalOpen(false);
        setDuplicateReviewModalOpen(true);
      }
    },
    [
      update,
      formatMdReview,
      setMdReview,
      userClickInfoTracking,
      postBatchAcivities,
      searchInfos,
      userId,
      serviceRequest.cohereId,
      serviceRequest?.id,
      setUserClickInfoTracking,
      setSearchInfos,
      submitDraftHighlights,
      onFinishEditingInternal,
      pastedAttachmentTexts,
    ]
  );

  const saveMdReviewDraftWithRedirect = useCallback(
    async (denialsTemplate?: DenialsInfo | null) => {
      try {
        const res = await update({
          ...formatMdReview(denialsTemplate),
        });
        setMdReview(res);
        if (submitDraftHighlights) {
          await submitDraftHighlights();
        }
        userClickInfoTracking &&
          (await postBatchAcivities(
            createBatchActivities(
              "MD_REVIEW",
              "REVIEW_SUBMISSION",
              "INTERACTION",
              userClickInfoTracking,
              searchInfos,
              pastedAttachmentTexts,
              userId,
              res.id,
              serviceRequest.cohereId,
              serviceRequest.id
            )
          ));
        setUserClickInfoTracking?.(getEmptyUserClickTracking());
        setSearchInfos?.([]);
        onFinishEditingInternal();
        redirectToPatientSummaryFromReview({
          serviceRequest,
          caseId,
          reviewId: mdReview.id,
          navigate,
        });
      } catch {
        setIsSubmissionModalOpen(false);
        setDuplicateReviewModalOpen(true);
      }
    },
    [
      update,
      formatMdReview,
      setMdReview,
      submitDraftHighlights,
      userClickInfoTracking,
      postBatchAcivities,
      searchInfos,
      pastedAttachmentTexts,
      userId,
      serviceRequest,
      setUserClickInfoTracking,
      setSearchInfos,
      onFinishEditingInternal,
      mdReview.id,
      caseId,
      navigate,
    ]
  );

  // Hooks & callbacks to submit a review
  const [isSubmissionModalOpen, setIsSubmissionModalOpen] = useState(false);
  const {
    mutate: submit,
    error: submitError,
    loading: submitLoading,
  } = useSubmitMdReview({
    reviewId: mdReview.id,
    queryParams: {
      isUserAudited: isQmUserAudited,
    },
  });
  useEffect(() => {
    if (submitError) {
      setIsSubmissionModalOpen(false);
      if (submitError.status === 409) {
        setDuplicateReviewModalOpen(true);
      } else {
        setErrorDetails(extractErrorDetails(submitError, serviceRequest.id, mdReview.id));
        setOpenHardRedirectOnSubmitModal(true);
      }
    }
  }, [submitError, serviceRequest.id, mdReview.id, setOpenHardRedirectOnSubmitModal, setIsSubmissionModalOpen]);

  const { continueServiceRequestTillNextReviewDate, loadingContinuation } = useContinueServiceRequestTillNextReviewDate(
    serviceRequest?.authorization?.id || "",
    facilityBasedFeatureEnabled
  );

  const isPatientNotYetAdmittedForInpatient = patientNotYetAdmittedForInpatient(serviceRequest);

  const submitMdReview = useCallback(
    async (event: React.FormEvent<HTMLFormElement>, serviceRequestUpdates?: Partial<ServiceRequestUpdatePayload>) => {
      event.preventDefault();
      if (mdReview.reviewOutcome) {
        setReviewSubmissionLoading(true);
        if (submitHighlights) {
          await submitHighlights();
        }
        let payload: MdReviewSubmitPayload = {
          ...formatMdReview(),
          reviewOutcome: mdReview.reviewOutcome,
          denialStatus: "NOT_STARTED",
          guidelines: mdReview.guidelines || [],
          //keeping the acknowledgement true right now to autoCreateContinuation from consumer in future based on
          //payer we might need acknowledgement to create continaution as a result of final decision of service request
          reviewerCreateContinuationAcknowledgement: true,
        };

        if (isReadmissionFeatureEnabled) {
          payload = {
            ...payload,
            isReadmission: isReadmission,
            anchorAuthNumber: anchorAuthNumber,
          };
        }

        if (facilityBasedFeatureEnabled) {
          const dischargeDetails = buildAuthorizationDischargeDetails?.() || {};
          payload = {
            ...payload,
            nextReviewDate,
          };

          //preservice doens't have discharge component enabled so update to discharge details
          if (isUnplannedInpatient && !isPatientNotYetAdmittedForInpatient) {
            payload = {
              ...payload,
              actualDischargeDateTime: dischargeDetails?.actualDischargeDateTime,
              dischargedTo: dischargeDetails?.dischargedTo?.enumName,
              patientStatus: isDischarged ? "DISCHARGED" : serviceRequest.patientStatus,
              patientStayDates: serviceRequestUpdates?.patientStayDates,
            };
          }

          if (patientStayManager?.reviewStayDateEditsFlag) {
            const isPatientAdmittedOrDischarged = patientAdmittedOrDischarged(
              patientStayManager?.formContent?.patientStatus
            );
            payload = {
              ...payload,
              actualDischargeDateTime: patientStayManager.getCombinedDischargedDateTime(),
              admissionDateTime: getNoTimezoneDateTimeString(
                patientStayManager?.formContent?.admissionDate,
                patientStayManager?.formContent?.admissionTime
              ),
              admissionSource: patientStayManager?.formContent?.admissionSource?.enumName,
              dischargedTo: patientStayManager?.formContent?.dischargedTo?.enumName,
              patientStatus: patientStayManager?.formContent?.patientStatus,
              expectedAdmissionDate: formatDateToISODate(patientStayManager?.formContent?.expectedAdmissionDate),
              patientStayDates: isPatientAdmittedOrDischarged ? serviceRequestUpdates?.patientStayDates : undefined,
            };
          }
        }

        const res = await submit(payload);
        userClickInfoTracking &&
          (await postBatchAcivities(
            createBatchActivities(
              "MD_REVIEW",
              "REVIEW_SUBMISSION",
              "INTERACTION",
              userClickInfoTracking,
              searchInfos,
              pastedAttachmentTexts,
              userId,
              res.id,
              serviceRequest.cohereId,
              serviceRequest.id
            )
          ));
        setUserClickInfoTracking?.(getEmptyUserClickTracking());
        setSearchInfos?.([]);
        onFinishEditingInternal();
        const postedOutreachAttempt = mdReview.nudgeAttempted && (await saveOutreachAttempt?.());
        const snap = res.guidelines ? guidelineSnapshotFromReview(res) : undefined;
        await trackUserActivityInteraction({
          event: "MD_REVIEW",
          stage: "REVIEW_SUBMISSION",
          activityContext: { reviewId: res.id, serviceRequestId: serviceRequest.id },
          afterGuidelineSnapshot: snap,
          attachmentGuidelineTextHighlightsSnapshot: {
            ...attachmentGuidelineTextHighlightsSnapshot,
            reviewer: res.createdByName,
            dateCompleted: res.dateCompleted,
            reviewOutcome: res.reviewOutcome,
            textHighlights: getHighlightPayload?.() || undefined,
          },
        });

        const lastPatientStayDateRange = last(patientStayDateRanges);
        // will not create continuation on FE when autoCreateFinalDecisionContinuations is ON
        if (!continuationConfiguration?.autoCreateFinalDecisionContinuations) {
          await continueServiceRequestTillNextReviewDate({
            serviceRequestId: serviceRequest.id,
            nextReviewDate,
            isDischarged,
            reviewOutcome: mdReview.reviewOutcome as AuthStatus,
            lastStayDateRange: lastPatientStayDateRange,
            triggerStatuses: ["APPROVED"],
          });
        }
        if (caseId) {
          await completeServiceCase({
            reviewOutcome: mdReview.reviewOutcome,
            dateCompleted: new Date().toISOString(),
            caseNotes: mdReview.authorizationNote,
            reviewId: mdReview.id,
            ...(postedOutreachAttempt ? { outreachCaseOutcome: { outreachAttemptId: postedOutreachAttempt.id } } : {}),
          }).catch(() => {
            window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
          });
          setIsSubmissionModalOpen(false);
        } else {
          try {
            // If review was completed outside QM (no caseId in URL), notify QM to
            // cancel existing open MD cases (if any) which are no longer needed.
            await updateCasesAfterReview({
              serviceRequestId: serviceRequest.id,
              reviewId: mdReview.id,
              reviewType: mdReview.reviewType,
              reviewOutcome: mdReview.reviewOutcome,
            });
          } catch {
            logError("Error notifying QM of review completion");
          }
          setIsSubmissionModalOpen(false);
          redirectToPatientSummaryFromReview({
            serviceRequest,
            reviewId: mdReview.id,
            navigate,
          });
        }
        setReviewSubmissionLoading(false);
      } else {
        setReviewSubmissionLoading(false);
        throw new Error("Review outcome must not be empty to submit");
      }
    },
    [
      mdReview.reviewOutcome,
      mdReview.guidelines,
      mdReview.nudgeAttempted,
      mdReview.authorizationNote,
      mdReview.id,
      mdReview.reviewType,
      submitHighlights,
      formatMdReview,
      isReadmissionFeatureEnabled,
      facilityBasedFeatureEnabled,
      submit,
      userClickInfoTracking,
      postBatchAcivities,
      searchInfos,
      pastedAttachmentTexts,
      userId,
      serviceRequest,
      setUserClickInfoTracking,
      setSearchInfos,
      onFinishEditingInternal,
      saveOutreachAttempt,
      trackUserActivityInteraction,
      attachmentGuidelineTextHighlightsSnapshot,
      getHighlightPayload,
      patientStayDateRanges,
      continuationConfiguration?.autoCreateFinalDecisionContinuations,
      caseId,
      isReadmission,
      anchorAuthNumber,
      buildAuthorizationDischargeDetails,
      nextReviewDate,
      patientStayManager,
      isUnplannedInpatient,
      isPatientNotYetAdmittedForInpatient,
      isDischarged,
      continueServiceRequestTillNextReviewDate,
      completeServiceCase,
      navigate,
      updateCasesAfterReview,
    ]
  );

  // Hooks & callbacks to discard a review
  const {
    mutate: discard,
    error: discardError,
    loading: discarding,
  } = useDiscardMdReview({
    reviewId: mdReview.id,
  });
  useEffect(() => {
    if (discardError) {
      const errorAny: any = discardError;
      const message = errorAny?.data?.message;
      const embeddedError = errorAny?.data?._embedded?.errors?.map((error: any) => error?.message).join("\n");
      const errorMessage = (
        <Box>
          <Box>Error discarding MD review</Box>
          {embeddedError ? <Box>{embeddedError}</Box> : message && <Box>{message}</Box>}
        </Box>
      );
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
      logError(`Error discarding md review ${stringifyError(discardError.data)}`);
    }
  }, [discardError, enqueueSnackbar]);

  const discardMdReview = useCallback(async () => {
    const res = await discard({
      ...mdReview,
    });
    userClickInfoTracking &&
      (await postBatchAcivities(
        createBatchActivities(
          "MD_REVIEW",
          "REVIEW_SUBMISSION",
          "INTERACTION",
          userClickInfoTracking,
          searchInfos,
          pastedAttachmentTexts,
          userId,
          res.id,
          serviceRequest.cohereId,
          serviceRequest.id
        )
      ));
    setUserClickInfoTracking?.(getEmptyUserClickTracking());
    setSearchInfos?.([]);
    onFinishEditingInternal();
    return res;
  }, [
    discard,
    mdReview,
    userClickInfoTracking,
    postBatchAcivities,
    searchInfos,
    pastedAttachmentTexts,
    userId,
    serviceRequest.cohereId,
    serviceRequest.id,
    setUserClickInfoTracking,
    setSearchInfos,
    onFinishEditingInternal,
  ]);

  const discardMdReviewWithRedirectsOnCRView = useCallback(async () => {
    const res = await discardMdReview();
    setMdReview(res);
    redirectToPatientSummaryFromReview({
      serviceRequest,
      caseId,
      reviewId: mdReview.id,
      navigate,
    });
  }, [discardMdReview, setMdReview, serviceRequest, mdReview.id, caseId, navigate]);

  const finishReviewLoading =
    reviewSubmissionLoading ||
    submitLoading ||
    !!submittingHighlights ||
    !!submittingDraftHighlights ||
    completingServiceCase ||
    loadingContinuation ||
    !!outreachAttemptLoading;

  return (
    <div
      style={{
        backgroundColor: theme.palette.background.paper,
        paddingBottom: 72,
        margin: 0,
      }}
      data-testid="md-review-container"
      ref={reviewRef}
    >
      <ReviewHeader
        toggleExpanded={toggleExpanded}
        expanded={expanded}
        reviewDateCreated={mdReview.dateCreated}
        reviewLastUpdated={mdReview.lastUpdated}
        reviewStatus={mdReview.reviewStatus}
        reviewOutcome={mdReview.reviewOutcome}
        reviewType={mdReview.reviewType}
        reviewCreatedByName={mdReview.createdByName}
        reviewCompletedByName={mdReview.completedByName}
        reviewDateCompleted={mdReview.dateCompleted}
        reviewText={mdReview.determinationNote}
        saveDraft={saveMdReviewDraft}
        clinicalReviewView={clinicalReviewView}
        coverageLevelDetails={mdReview.coverageLevelDetails}
      />
      <ReviewDetailsCollapse
        in={expanded}
        timeout={100}
        unmountOnExit
        style={reviewDetailsCollapseStyle ? reviewDetailsCollapseStyle : { marginLeft: 16 }}
      >
        {willAutoApprove && (
          <Alert style={{ backgroundColor: "#F5F5F5", paddingTop: 28 }} severity="success">
            <div style={{ color: colorsLight.success.main }}>This request met auto-approval criteria</div>
          </Alert>
        )}

        <MDReviewEdit
          serviceRequest={serviceRequest}
          outreachAttempt={outreachAttempt}
          setOutreachAttempt={setOutreachAttempt}
          review={mdReview}
          setReview={setMdReview}
          openSubmissionModal={() => {
            //preservice doens't have patient stay dates on sr
            if (facilityBasedFeatureEnabled && isUnplannedInpatient) {
              const isDatesValid = !!isStayDatesValid?.();
              setIsSubmissionModalOpen(isDatesValid);
            } else {
              setIsSubmissionModalOpen(true);
            }
          }}
          onDiscard={() => {
            discardMdReviewWithRedirectsOnCRView();
          }}
          discarding={discarding}
          toggleExpanded={toggleExpanded}
          saveDraft={saveMdReviewDraft}
          currentlySavingDraft={updateLoading}
          setSameGuidelines={setSameGuidelines}
          newDenialsWorkflow={newDenialsWorkflow}
          prePopulateDenialsTemplate={prePopulateDenialsTemplate}
          copiedAttachmentText={copiedAttachmentText}
          setPastedAttachmentTexts={setPastedAttachmentTexts}
          hideGuidelinesUsed={hideGuidelinesUsed}
          patientStayDateRanges={patientStayDateRanges}
          setPatientStayDateRanges={setPatientStayDateRanges}
          patientStaysErrorStates={patientStaysErrorStates}
          isDischarged={isDischarged}
          setIsDischarged={setIsDischarged}
          dischargeDetails={dischargeDetails}
          setDischargeDetails={setDischargeDetails}
          facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
          outreachFormConfig={outreachFormConfig}
          outreachFormErrors={outreachFormErrors}
          setOutreachFormErrors={setOutreachFormErrors}
          validateOutreachForm={validateOutreachForm}
          hasOutreachAttempt={hasOutreachAttempt}
          setHasOutreachAttempt={setHasOutreachAttempt}
          saveDraftWithRedirect={saveMdReviewDraftWithRedirect}
          isReadmission={isReadmission}
          setIsReadmission={setIsReadmission}
          anchorAuthNumber={anchorAuthNumber}
          setAnchorAuthNumber={setAnchorAuthNumber}
          hasErrorWithAnchorAuth={hasErrorWithAnchorAuth}
          setHasErrorWithAnchorAuth={setHasErrorWithAnchorAuth}
          isReadmissionFeatureEnabled={isReadmissionFeatureEnabled}
          authorization={authorization}
          isStayDatesValid={isStayDatesValid}
          isDischargeDetailsValid={isDischargeDetailsValid || true}
          patientStayManager={patientStayManager}
          approvedUnitsByClinicalService={approvedUnitsByClinicalService}
          updateApprovedUnitsByCS={updateApprovedUnitsByCS}
          approvedUnits={approvedUnits}
          updateSinglePxCodeUnit={updateSinglePxCodeUnit}
          pxCodesByClinicalService={pxCodesByClinicalService}
          existingReviews={existingReviews}
          discardAndExitDisabled={finishReviewLoading || isSubmissionModalOpen}
        />
        <MdModal
          isReadmission={isReadmission}
          serviceRequest={serviceRequest}
          review={mdReview}
          newDenialsWorkflow={newDenialsWorkflow}
          prePopulateDenialsTemplate={prePopulateDenialsTemplate}
          sameGuidelines={sameGuidelines}
          allReviews={allReviews}
          isInheritingBlobReview={isInheritingBlobReview}
          setIsInheritingBlobReview={setIsInheritingBlobReview}
          setReview={(reviewUpdate) => setMdReview(reviewUpdate as Partial<MdReview>)}
          pxCodesByClinicalService={pxCodesByClinicalService}
          approvedUnitsByClinicalService={approvedUnitsByClinicalService}
          updateApprovedUnitsByCS={updateApprovedUnitsByCS}
          approvedUnits={approvedUnits}
          updateSinglePxCodeUnit={updateSinglePxCodeUnit}
          loading={finishReviewLoading}
          approvedPxCodesAreInvalid={approvedPxCodesAreInvalid}
          facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
          currentlySavingDraft={updateLoading}
          isDischarged={isDischarged}
          initialPatientStayDateRanges={patientStayDateRanges}
          setIsOpen={setIsSubmissionModalOpen}
          isOpen={isSubmissionModalOpen}
          onDiscard={() => {
            discardMdReview();
          }}
          onSubmit={submitMdReview}
          discarding={discarding}
          saveReviewDraft={saveMdReviewDraft}
          checkEmptyRequestedLoc={checkEmptyRequestedLoc}
        />
        <DetailedHardRedirectClinicalReviewModal
          isOpen={openHardRedirectOnSubmitModal}
          setIsOpen={setOpenHardRedirectOnSubmitModal}
          errorDetails={errorDetails}
        />
        <InformativeModal
          open={duplicateReviewModalOpen}
          onClose={() => {
            setDuplicateReviewModalOpen(false);
          }}
          headerText={"Review completed by another user"}
          additionalInfoText={"This review was completed by another user. Your review and notes will not be saved."}
          primaryButtonText={"Go to patient summary"}
          primaryButtonRoute={routeToPatientSummaryFromReview({
            serviceRequestId: serviceRequest.id,
            patientId: serviceRequest?.patient?.id,
          })}
        />
      </ReviewDetailsCollapse>
    </div>
  );
}

const MdReviewReadonly = React.memo(
  ({
    mdReview,
    serviceRequest,
    clinicalReviewView,
    reviewRef,
    expanded,
    expandableGuidelines,
    reviewDetailsCollapseStyle,
    ...otherProps
  }: MdReviewReadonlyProps) => {
    const prePopulateDenialsTemplate = useFeature("prePopulateDenialsTemplate");
    const indicationSectionNewDesign = useFeature("showNewDesignIndicationSection");
    return (
      <div ref={reviewRef}>
        <ReviewHeader
          expanded={expanded}
          reviewDateCreated={mdReview.dateCreated}
          reviewLastUpdated={mdReview.lastUpdated}
          reviewStatus={mdReview.reviewStatus}
          reviewOutcome={mdReview.reviewOutcome}
          reviewType={mdReview.reviewType}
          reviewCreatedByName={mdReview.createdByName}
          reviewCompletedByName={mdReview.completedByName}
          reviewDateCompleted={mdReview.dateCompleted}
          clinicalReviewView={clinicalReviewView}
          reviewText={mdReview.determinationNote}
          coverageLevelDetails={mdReview.coverageLevelDetails}
          {...otherProps}
        />
        <ReviewDetailsCollapse
          in={expanded}
          timeout={100}
          unmountOnExit
          style={reviewDetailsCollapseStyle ? reviewDetailsCollapseStyle : clinicalReviewView ? { marginLeft: 16 } : {}}
        >
          <MDReviewReadonly
            mdReview={mdReview}
            serviceRequest={serviceRequest}
            prePopulateDenialsTemplate={prePopulateDenialsTemplate}
            hideReviewNotes={otherProps.authorizationReviewView}
          />
          <GuidelineReview
            id="tabpanel-0"
            review={mdReview}
            indicationSectionNewDesign={indicationSectionNewDesign}
            serviceRequest={serviceRequest}
            expandable={expandableGuidelines}
            authorizationReviewView={otherProps.authorizationReviewView}
          />
        </ReviewDetailsCollapse>
      </div>
    );
  }
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const ReviewDetailsCollapse = styled(Collapse)(({ theme }) => ({
  margin: theme.spacing(0, 3, 3),
}));

export default MDReviewComponent;
