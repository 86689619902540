import React from "react";
import { Alert, Card } from "@coherehealth/common";
import { Patient } from "@coherehealth/core-platform-api";
import { CardProps as MuiCardProps } from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { checkMemberCoverageInfo, getCombinedCoverageRanges, getPatientHealthPlanName } from "util/patientUtils";
import { isRecommendChangeAction, isRedirectAction } from "util/rule";
import ImpressionTracking, { ImpressionTrackingProps } from "../ImpressionTracking";
import { useTrackUserInteraction } from "util/userActivityTracker";
import PatientHealthPlanMessaging, { usePatientHealthPlanMessageConfig } from "../PatientHealthPlanMessaging";
import { compareDesc } from "date-fns";
import { AuthorizationTransferProps } from "components/DashboardPage/PatientSelectionModal/PatientSelectionModal";
import { PatientSearchResultInteraction } from "./PatientSearchResultInteraction";
import { PatientSearchHeadline } from "./PatientSearchHeadline";
import { PatientSearchRedirectMessage } from "./PatientSearchRedirectMessage";
import { usePatientSearchResultCardStyles } from "./PatientSearchResultCardStyles";

interface IProps {
  /**
   * If we should display the "Transfer" button that serves the purpose of transfering an authorization
   * to another patient and the recipient patient ID.
   */
  authorizationTransfer?: AuthorizationTransferProps;
  patient: Patient;
  cardProps?: MuiCardProps;
  /**
   * Authorization transfer handler that will be executed when the "Transfer" button from the modal
   * gets clicked.
   * @param event either "escapeKeyDown" or "backdropClick"
   * @param receiverPatientId hash Id from the patient that will receive the authorization
   */
  onTransferAuth?: (event: React.MouseEvent, receiverPatientId: string) => void;
  /**
   * If this is present we will render a NavLink button that goes to the given link
   */
  selectLink?: string;
  /**
   * If this is present we will render a normal "button" with the given onClick action
   */
  selectOnClick?: (arg0: Patient) => void;
  /**
   * If we should render a loading state for the button (only applicable if selectOnClick is used)
   */
  selectActionLoading?: boolean;
  /**
   * If we should replace Gender and DOB information with a link to the Patient Summary Page
   */
  showSummaryLink?: boolean;
  /**
   * If we should show helper text indicating the last date of the combined coverages for this patient.
   */
  showCoverageEndDate?: boolean;
}
/**
 * Must provide a selectLink XOR selectOnClick
 */
function PatientSearchResultCard(props: IProps) {
  const {
    authorizationTransfer,
    patient,
    cardProps = { elevation: 0 },
    onTransferAuth,
    selectLink,
    selectOnClick,
    selectActionLoading,
    showSummaryLink,
    showCoverageEndDate,
  } = props;

  const ruleActions = patient.ruleActions || [];

  const redirect = ruleActions.find(isRedirectAction);
  const recommendChange = ruleActions.find(isRecommendChangeAction);
  const actionToShow = redirect || recommendChange;
  const allowSelect = !redirect || redirect.isOptional;

  const trackInteraction = useTrackUserInteraction();
  const userActivityParams: ImpressionTrackingProps = {
    event: redirect ? "PATIENT_REDIRECT" : "PATIENT_RECOMMEND_CHANGE",
    stage: "PATIENT_SEARCH",
    activityContext: {
      ruleId: actionToShow?.ruleId || "",
      actionId: actionToShow?.actionId || "",
      patientId: patient.id,
    },
  };

  const coverageMessageToDisplay: string = checkMemberCoverageInfo(patient);

  const combinedCoverageRanges = getCombinedCoverageRanges(patient?.coverages || []);
  const sortedCombinedCoverages =
    combinedCoverageRanges && combinedCoverageRanges.length > 0
      ? combinedCoverageRanges?.sort((a, b) => {
          return compareDesc(a?.end, b?.end);
        })
      : [];
  const latestCoverageEndDate =
    sortedCombinedCoverages && sortedCombinedCoverages?.length > 0 ? sortedCombinedCoverages[0]?.end : undefined;

  const patientHealthPlanMessageConfig = usePatientHealthPlanMessageConfig(patient);
  const today = new Date();
  const healthPlanName = patient ? getPatientHealthPlanName(patient, today) : undefined;
  const classes = usePatientSearchResultCardStyles();
  return (
    <Card className={classes.cardContainer} {...cardProps}>
      {patientHealthPlanMessageConfig && <div className={classes.infoBar} />}
      <Grid container spacing={2}>
        {patientHealthPlanMessageConfig && (
          <>
            <Grid item xs={12}>
              <PatientHealthPlanMessaging {...patientHealthPlanMessageConfig} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}

        <Grid container item spacing={2}>
          <Grid item xs={allowSelect ? 6 : 12}>
            <PatientSearchHeadline
              allowSelect={allowSelect}
              latestCoverageEndDate={latestCoverageEndDate}
              patient={patient}
              showCoverageEndDate={showCoverageEndDate}
              showSummaryLink={showSummaryLink}
            />
          </Grid>

          {allowSelect && (
            <Grid item xs={6}>
              <PatientSearchResultInteraction
                authorizationTransfer={authorizationTransfer}
                healthPlanName={healthPlanName}
                onTransferAuth={onTransferAuth}
                patient={patient}
                redirect={redirect}
                selectActionLoading={selectActionLoading}
                selectLink={selectLink}
                selectOnClick={selectOnClick}
                showSummaryLink={showSummaryLink}
                trackInteraction={trackInteraction}
                userActivityParams={userActivityParams}
              />
            </Grid>
          )}
        </Grid>
        {actionToShow && (
          <ImpressionTracking {...userActivityParams}>
            <PatientSearchRedirectMessage action={actionToShow} />
          </ImpressionTracking>
        )}
        {Boolean(coverageMessageToDisplay) && (
          <Grid item xs={12} style={{ paddingRight: 20, paddingLeft: 0 }}>
            <Alert className={classes.paddedAlert} severity="warning" message={coverageMessageToDisplay} />
          </Grid>
        )}
      </Grid>
    </Card>
  );
}

export default PatientSearchResultCard;
