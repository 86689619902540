import { InformativeModal, useFeature, useGetAuthorizationByIdWithFallback } from "@coherehealth/common";
import MuiErrorIcon from "@material-ui/icons/Error";
import { Permissions, useAuthorized } from "authorization";
import { useAuthorizationTransfer, useGetPatient } from "@coherehealth/core-platform-api";
import { useContext, useEffect, useState } from "react";
import { TrackUserActivityProps, useTrackUserInteraction } from "util/userActivityTracker";
import { UserContext } from "UserContext";
import { useSnackbar } from "notistack";
import { SuccessfulAuthorizationTransferModal } from "./SuccessfulAuthorizationTransferModal";
import { generatePath } from "react-router";
import routes from "routes";
import { useAuthorizationTransferModalStyles } from "./AuthorizationTransferModalStyles";

interface AuthorizationTransferModalProps {
  authorizationId?: string;
  receiverPatientId?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function AuthorizationTransferModal(props: AuthorizationTransferModalProps): JSX.Element {
  const { authorizationId, receiverPatientId, open, setOpen } = props;
  const classes = useAuthorizationTransferModalStyles();
  const onCancel = (event?: MouseEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    setOpen(false);
  };
  const [transferAuthorizationTriggered, setTransferAuthorizationTriggered] = useState<boolean>(false);
  const [currentUserDataLoading, setCurrentUserDataLoading] = useState<boolean>(false);
  const [authorizationTransferInProgress, setAuthorizationTransferInProgress] = useState<boolean>(false);
  const { getUser } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [userId, setUserId] = useState<string>();
  const onClose = () => {
    window.location.assign(
      `${generatePath(routes.PATIENT_SUMMARY, {
        patientId: receiverPatientId,
      })}`
    );
  };

  // Get current logged in user information for auditing purposes.
  useEffect((): void => {
    setCurrentUserDataLoading(true);
    getUser()
      ?.then((currentUser) => {
        setUserId(currentUser?.sub || "");
      })
      ?.finally(() => {
        setCurrentUserDataLoading(false);
      });
  }, [getUser]);

  // Fetch the latest state of the Authorization to use it afterwards for validations
  // and audit data.
  const {
    data: authorizationData,
    loading: authorizationDataLoading,
    error: authorizationDataError,
  } = useGetAuthorizationByIdWithFallback({
    id: authorizationId ?? "",
  });

  // Fetch the patient receiver data to display it in the modal's GUI.
  const {
    data: patientReceiverData,
    loading: patientReceiverDataLoading,
    error: patientReceiverDataError,
  } = useGetPatient({
    id: receiverPatientId ?? "",
  });

  // UserActivityTracker implementation to save a record of which users are transfering
  // authorizations from one patient to another.
  const trackUserActivityInteraction = useTrackUserInteraction();
  const trackAuditTransferUserActivity = async (): Promise<void> => {
    const userActivityInteractionPayload: TrackUserActivityProps = {
      event: "AUTHORIZATION_TRANSFER",
      stage: "PATIENT_SUMMARY",
      interactionAccept: true,
      activityContext: {
        cohereAuthId: authorizationId,
        userId: userId,
      },
      beforeSnapshot: {
        mongoPatientId: authorizationData?.patient?.id,
      },
      afterSnapshot: {
        mongoPatientId: receiverPatientId,
      },
    };
    await trackUserActivityInteraction(userActivityInteractionPayload);
  };

  // Define the validations required to enable or block users from submitting the
  // authorization transfer to the server. Note: The same set of validations are
  // being performed in the server side.
  const authorizationTransferEnabled = useFeature("authorizationTransfer");
  const hasTransferAuthPerms = useAuthorized(Permissions.TRANSFER_AUTHORIZATION);
  const isAuthorizationTransferEnabledForUser = authorizationTransferEnabled && hasTransferAuthPerms;

  // Define the wrapper function of the actions that are involved in the authorization
  // transfer process.
  const {
    mutate: transferAuthorization,
    loading: transferAuthorizationLoading,
    error: authorizationTransferError,
  } = useAuthorizationTransfer({ authorizationId: authorizationId ?? "", receiverPatientId: receiverPatientId ?? "" });

  const onTransferAuthorization = async (
    event?: MouseEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    event?.stopPropagation();
    if (authorizationId && receiverPatientId) {
      setAuthorizationTransferInProgress(true);
      setTransferAuthorizationTriggered(true);

      await transferAuthorization();
      await trackAuditTransferUserActivity();
    }
    setAuthorizationTransferInProgress(false);
  };

  // Handle errors and notify the user about those.
  useEffect(() => {
    const errors: { status?: number; message?: string; caption: string }[] = [];
    if (authorizationTransferError) {
      const { status, message } = authorizationTransferError;
      errors.push({ status, message, caption: "The authorization transfer was not completed" });
    }

    if (authorizationDataError) {
      const { status, message } = authorizationDataError;
      errors.push({ status, message, caption: "The authorization data couldn't be loaded" });
    }

    if (patientReceiverDataError) {
      const { status, message } = patientReceiverDataError;
      errors.push({ status, message, caption: "The patient receiver data couldn't be loaded" });
    }

    errors.forEach(({ caption, status, message }) => {
      enqueueSnackbar(`${caption}: ${status} - ${message}`, {
        variant: "error",
      });
    });
  }, [authorizationDataError, authorizationTransferError, enqueueSnackbar, patientReceiverDataError]);

  // Control loading and successful transaction statuses.
  const loading =
    transferAuthorizationLoading ||
    authorizationDataLoading ||
    currentUserDataLoading ||
    authorizationTransferInProgress ||
    patientReceiverDataLoading;

  const isAuthorizationTransferSuccessful = transferAuthorizationTriggered && !loading;

  const WarningIcon = <MuiErrorIcon className={classes.warningIcon} />;

  // If the user is not authorized to see this feature, then render a blank component.
  if (!isAuthorizationTransferEnabledForUser) {
    return <></>;
  }

  const patientHolderName = authorizationData?.serviceRequestsOnAuth?.[0]?.patient?.name;

  return isAuthorizationTransferSuccessful ? (
    <SuccessfulAuthorizationTransferModal open={open} onClose={onClose} />
  ) : (
    <InformativeModal
      className={classes.modal}
      open={open}
      onClose={() => onCancel()}
      headerText={`Are you sure you want to transfer this authorization from “${patientHolderName}” to “${patientReceiverData?.name}”?`}
      subHeaderText={"Once this authorization is decisioned, it cannot be transferred."}
      icon={WarningIcon}
      primaryButtonText={"Complete transfer"}
      primaryButtonLoading={authorizationTransferInProgress || loading}
      primaryButtonAction={onTransferAuthorization}
      primaryButtonDisabled={loading}
      tertiaryButtonText={"Cancel"}
      tertiaryButtonAction={onCancel}
      tertiaryButtonDisabled={authorizationTransferInProgress}
      data-testid="confirm-authorization-transfer-modal"
    />
  );
}
