import { CSSProperties, useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  FilterValues,
  LOOKUP_SEARCH,
  DATE_PICKER,
  MULTI_SELECT,
  SINGLE_SELECT,
  TEXT_FIELD,
  DATE_RANGE,
} from "./shared";
import {
  initialFilterValue,
  initialFilterValues,
  isFilterEmpty,
  areFiltersEmpty,
  optionToLabelFn,
  countNumActive,
} from "./util";

import { LookupSearchFilterConfig, LookupSearchFilterProps, LookupSearchFilter } from "./LookupSearchFilter";
import { DatePickerFilterConfig, DatePickerFilterProps, DatePickerFilter } from "./DatePickerFilter";
import { MultiSelectFilterConfig, MultiSelectFilterProps, MultiSelectFilter } from "./MultiSelectFilter";
import { SingleSelectFilterConfig, SingleSelectFilterProps, SingleSelectFilter } from "./SingleSelectFilter";
import { TextFieldFilterConfig, TextFieldFilterProps, TextFieldFilter } from "./TextFieldFilter";
import {
  DateRangeFilterConfig,
  DateRangeFilterProps,
  DateRangeFilter,
  DateRange,
  formatDateRange,
} from "./DateRangeFilter";
import { ClickAwayListener } from "@mui/material";
import { Chip, DropdownOption, InlineButton, PrimaryButton, SecondaryButton } from "../";
import { formatDateStr } from "../../util/DateUtils";
import { Grid } from "@mui/material";
import { isEqual } from "lodash";

export type FilterConfig =
  | LookupSearchFilterConfig<unknown>
  | DatePickerFilterConfig
  | MultiSelectFilterConfig
  | SingleSelectFilterConfig
  | TextFieldFilterConfig
  | DateRangeFilterConfig;

export interface ViewProps {
  isOpen: boolean;
  isClearDisabled?: boolean;
  isApplyDisabled?: boolean;
  filterConfigs: FilterConfig[];
  filterValues: FilterValues;
  onFilterChange?: (key: string, newState: unknown) => void;
  onApply?: () => void;
  onClear?: () => void;
  setPreventClose?: (val: boolean) => void;
}

export interface ControllerProps {
  filterConfigs: FilterConfig[];
  initialValues: FilterValues;
  onFiltersChange?: (filterValues: FilterValues) => void;
  id?: string;
  onApply?: () => void;
  onClear?: () => void;
  style?: CSSProperties;
}

export interface FilterProps {
  filterConfig: FilterConfig;
  value: unknown;
  onChange?: (key: string, newValue: unknown) => void;
  setPreventClose?: (val: boolean) => void;
}

export function Filter(filterProps: FilterProps) {
  const { filterConfig } = filterProps;
  switch (filterConfig.filterType) {
    case LOOKUP_SEARCH: {
      return <LookupSearchFilter {...(filterProps as LookupSearchFilterProps)} />;
    }
    case DATE_PICKER: {
      return <DatePickerFilter {...(filterProps as DatePickerFilterProps)} />;
    }
    case MULTI_SELECT: {
      return <MultiSelectFilter {...(filterProps as MultiSelectFilterProps)} />;
    }
    case SINGLE_SELECT: {
      return <SingleSelectFilter {...(filterProps as SingleSelectFilterProps)} />;
    }
    case TEXT_FIELD: {
      return <TextFieldFilter {...(filterProps as TextFieldFilterProps)} />;
    }
    case DATE_RANGE: {
      return <DateRangeFilter {...(filterProps as DateRangeFilterProps)} />;
    }
    default: {
      return null;
    }
  }
}

export type FilterButtonProps = {
  isOpen: boolean;
  numFiltersApplied: number;
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
};

export function FilterButton(props: FilterButtonProps) {
  const { numFiltersApplied, onClick, isOpen } = props;
  const buttonBackgroundStyle = {
    backgroundColor: "rgba(3, 158, 195, 0.15)", // text primary
    borderRadius: "5px",
    paddingLeft: "4px",
    paddingRight: "4px",
  };
  const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(evt);
    }
  };
  const labelText = numFiltersApplied === 0 ? "Filter" : `Filter (${numFiltersApplied} applied)`;
  return (
    <InlineButton
      style={isOpen ? buttonBackgroundStyle : undefined}
      startIcon={<FilterAltIcon />}
      onClick={handleClick}
    >
      {labelText}
    </InlineButton>
  );
}

const useStyles = makeStyles((_theme) => ({
  button: {
    flex: 1,
    width: "100%",
  },
}));

interface ActiveFilterChipsProps {
  filterConfigs: FilterConfig[];
  filterValues: FilterValues;
  onClearAll: () => void;
  onClearFilter: (key: string) => void;
}

interface ActiveFilterChipProps {
  filterConfig: FilterConfig;
  filterValues: FilterValues;
  onClear: () => void;
}

function formatStrListForChip(values: string[]) {
  if (values.length === 0) {
    return "";
  } else if (values.length === 1) {
    return values[0];
  } else {
    return `${values[0]}, + ${values.length - 1} more`;
  }
}

const defaultDropdownLabelFn = (val: DropdownOption | null) => (val ? val.label || val.id : "");

export function formatValueForChip(filterConfig: FilterConfig, value: unknown): string | null {
  switch (filterConfig.filterType) {
    case LOOKUP_SEARCH: {
      const labelFn = optionToLabelFn(filterConfig?.optionToLabel);
      const valueStrs: string[] = ((value || []) as unknown[]).map((option) => labelFn(option));
      return formatStrListForChip(valueStrs);
    }
    case DATE_PICKER: {
      return value ? formatDateStr(value as Date) : null;
    }
    case MULTI_SELECT: {
      const optionToLabel = filterConfig?.optionToLabel || defaultDropdownLabelFn;
      const labelFn = optionToLabelFn(optionToLabel as (val: unknown) => string);
      const valueStrs: string[] = ((value || []) as unknown[]).map((option) => labelFn(option));
      return formatStrListForChip(valueStrs);
    }
    case SINGLE_SELECT: {
      const optionToLabel = filterConfig?.optionToLabel || defaultDropdownLabelFn;
      const labelFn = optionToLabelFn(optionToLabel as (val: unknown) => string);
      return labelFn(value);
    }
    case TEXT_FIELD: {
      return formatStrListForChip((value || []) as string[]);
    }
    case DATE_RANGE: {
      return formatDateRange(value as DateRange | null | undefined);
    }
    default:
      return null;
  }
}

export function ActiveFilterChip({ filterConfig, filterValues, onClear }: ActiveFilterChipProps) {
  const { label, key } = filterConfig;
  const filterValue = filterValues[key];
  const labelStr = `${label}: `;
  const valueLabelStr = formatValueForChip(filterConfig, filterValue);
  const filterLabel = (
    <div style={{ display: "inline" }}>
      {labelStr}
      <span style={{ fontWeight: "600" }}>{valueLabelStr}</span>
    </div>
  );
  return (
    <Chip
      label={filterLabel}
      size="small"
      type="disabled"
      onDelete={onClear}
      style={{ marginRight: "16px", backgroundColor: "#F5F5F5" }}
    />
  );
}

export function ActiveFilterChips({ filterConfigs, filterValues, onClearAll, onClearFilter }: ActiveFilterChipsProps) {
  const nonEmptyFilters = filterConfigs.filter((config) => {
    const filterValue = filterValues[config.key];
    return !isFilterEmpty(config, filterValue);
  });
  const hasFilters = nonEmptyFilters.length !== 0;
  return (
    <div style={{ display: "inline", marginLeft: "8px" }}>
      {nonEmptyFilters.map((config) => (
        <ActiveFilterChip
          key={config.key}
          filterConfig={config}
          filterValues={filterValues}
          onClear={() => onClearFilter(config.key)}
        />
      ))}
      {hasFilters && (
        <span style={{ marginLeft: "8px" }}>
          <InlineButton onClick={onClearAll}>Clear all</InlineButton>
        </span>
      )}
    </div>
  );
}

export function FiltersPopoverView({
  isClearDisabled,
  isApplyDisabled,
  filterConfigs,
  filterValues,
  onFilterChange,
  onApply,
  onClear,
  setPreventClose,
}: ViewProps) {
  const values = filterValues || {};
  const classes = useStyles();
  return (
    <Paper elevation={8}>
      <Box sx={{ ml: 4, mr: 4, minWidth: "400px", paddingTop: 4, paddingBottom: 4 }}>
        {filterConfigs.map((config: FilterConfig) => {
          const filterValue = values[config.key];
          return (
            <Box key={config.key} sx={{ mb: 2 }}>
              <Filter
                key={config.key}
                filterConfig={config}
                value={filterValue}
                onChange={onFilterChange}
                setPreventClose={setPreventClose}
              />
            </Box>
          );
        })}
        <Grid container item xs={12} direction="row" justifyContent="space-between">
          <Grid item xs={6} sx={{ pr: 1 }}>
            <SecondaryButton warning disabled={isClearDisabled} className={classes.button} onClick={onClear}>
              Clear filters
            </SecondaryButton>
          </Grid>
          <Grid item xs={6} sx={{ pl: 1 }}>
            <PrimaryButton disabled={isApplyDisabled} className={classes.button} onClick={onApply}>
              Apply
            </PrimaryButton>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export function FiltersPopover({ filterConfigs, initialValues, onFiltersChange, id, style }: ControllerProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<FilterValues>(initialValues);
  const [editingFilterValues, setEditingFilterValues] = useState<FilterValues>(initialValues);
  const [numActive, setNumActive] = useState<number>(countNumActive(filterConfigs, initialValues));
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isApplyDisabled = areFiltersEmpty(filterConfigs, editingFilterValues) && numActive === 0;
  const isClearDisabled = areFiltersEmpty(filterConfigs, editingFilterValues) && numActive === 0;

  const [preventClose, setPreventClose] = useState<boolean>(false);

  const handleFilterChange = (key: string, value: unknown) => {
    setEditingFilterValues({
      ...editingFilterValues,
      [key]: value,
    } as FilterValues);
  };
  const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(evt.currentTarget);
    setIsOpen(!isOpen);
  };
  const handleApply = () => {
    setIsOpen(false);
    setFilterValues(editingFilterValues);
    setNumActive(countNumActive(filterConfigs, editingFilterValues));
    if (onFiltersChange) {
      onFiltersChange(editingFilterValues);
    }
  };

  const handleClear = useCallback(() => {
    const filterValues = initialFilterValues(filterConfigs, true);
    setIsOpen(false);
    setNumActive(0);
    setFilterValues(filterValues);
    setEditingFilterValues(filterValues);
    if (onFiltersChange) {
      onFiltersChange(filterValues);
    }
  }, [filterConfigs, onFiltersChange]);

  // Conditional to ensure we are assigning initialValues to filterValues and editingFilterValues
  if (!isEqual(initialValues, filterValues)) {
    setFilterValues(initialValues);
    setEditingFilterValues(initialValues);
    setNumActive(countNumActive(filterConfigs, initialValues)); // this represents number.
  }

  // Clear filters when id changes
  useEffect(() => {
    if (id) {
      handleClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleClearFilter = (key: string) => {
    const config = filterConfigs.filter((config) => config.key === key)[0];
    if (config) {
      const newFilterValues = {
        ...filterValues,
        [key]: initialFilterValue(config, true),
      } as FilterValues;
      setNumActive(countNumActive(filterConfigs, newFilterValues));
      setFilterValues(newFilterValues);
      setEditingFilterValues(newFilterValues);
      if (onFiltersChange) {
        onFiltersChange(newFilterValues);
      }
    }
  };

  return (
    // <ClickAwayListener onClickAway={() => setIsOpen(false)}>
    <ClickAwayListener
      onClickAway={() => {
        if (!preventClose) {
          setIsOpen(false);
        }
      }}
    >
      <Box sx={{ width: "100%", ...style }}>
        <div style={isOpen ? { display: "inline-block" } : undefined}>
          <FilterButton isOpen={isOpen} numFiltersApplied={numActive} onClick={handleClick} />
          <ActiveFilterChips
            filterConfigs={filterConfigs as FilterConfig[]}
            filterValues={filterValues}
            onClearAll={handleClear}
            onClearFilter={handleClearFilter}
          />
        </div>
        <Popper open={isOpen} placement="bottom-start" anchorEl={anchorEl}>
          <FiltersPopoverView
            setPreventClose={setPreventClose}
            isOpen={isOpen}
            isClearDisabled={isClearDisabled}
            isApplyDisabled={isApplyDisabled}
            filterConfigs={filterConfigs}
            filterValues={editingFilterValues}
            onFilterChange={handleFilterChange}
            onApply={handleApply}
            onClear={handleClear}
          />
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}
