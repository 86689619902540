import { Pill } from "@coherehealth/common";
import { Location } from "@coherehealth/core-platform-api";

interface NetworkStatusPillConfig {
  outOfNetworkStatusDisplayOff?: boolean;
  locationIsOutOfNetwork?: boolean;
  outOfNetworkStatusOverride?: boolean;
  outOfNetworkStatusOverrideEnabled?: boolean;
}

interface FacilityNetworkStatusPillProps {
  location: Location | undefined;
  config: NetworkStatusPillConfig;
}

export const LocationNetworkStatusPill = (props: FacilityNetworkStatusPillProps): JSX.Element => {
  const { location } = props;
  const {
    outOfNetworkStatusDisplayOff,
    locationIsOutOfNetwork,
    outOfNetworkStatusOverride,
    outOfNetworkStatusOverrideEnabled,
  } = props.config;
  if (
    (!outOfNetworkStatusDisplayOff && (locationIsOutOfNetwork || location?.networkType === "OON")) ||
    (!outOfNetworkStatusDisplayOff && outOfNetworkStatusOverride && outOfNetworkStatusOverrideEnabled)
  ) {
    return <Pill label="Out-of-network" variant="warning" />;
  }
  if (!outOfNetworkStatusDisplayOff && location?.networkType === "OOA") {
    return <Pill label="Out-of-area" variant="warning" />;
  }
  if (
    !outOfNetworkStatusDisplayOff &&
    location?.networkType === "Unknown" &&
    (outOfNetworkStatusOverride === undefined || !outOfNetworkStatusOverrideEnabled)
  ) {
    return <Pill label="Network unknown" variant="info" />;
  }
  return <></>;
};
