import { containerSmallBreakpoint } from "@coherehealth/common";
import { Drawer } from "@material-ui/core";
import ServiceRequestPage from "components/ServiceRequest/ServiceRequestPage";
import { warn } from "logger";
import { PathMatch, Location } from "react-router";

interface Props {
  faxId: string;
  queueId: string | undefined;
  caseId: string | undefined;
  match?: PathMatch<string> | null;
  location: Location;
  search: URLSearchParams;
}

export default function FaxEditSRSidebar({ caseId, queueId, faxId, match, location, search }: Props) {
  if (!caseId) {
    warn(`Missing case id corresponding to faxId: ${faxId}`);
  }

  return (
    <Drawer variant="permanent" anchor="right" disableEnforceFocus>
      <div style={{ width: containerSmallBreakpoint }}>
        <ServiceRequestPage />
      </div>
    </Drawer>
  );
}
