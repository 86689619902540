import {
  AuthorizationResponse,
  AuthStatus,
  ClinicalService,
  Patient,
  ProcedureCode,
} from "@coherehealth/core-platform-api";
import { Grid, makeStyles } from "@material-ui/core";
import { H6 } from "@coherehealth/design-system";
import UnitTextField from "../../ServiceRequest/ServiceRequestForm/components/UnitTextField";
import ProcedureCodeTable from "../../ServiceRequest/ServiceRequestForm/components/ProcedureCodeTable";
import ProcedureCodeSelector from "../../ServiceRequest/ServiceRequestForm/components/ProcedureCodeSelector";
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { constructProcedureCodes } from "util/clinicalAssessment";
import { ProcedureCodeWithId, withId } from "@coherehealth/common";
import { ServiceRequestFormContent } from "components/ServiceRequest";
import { NonCohereCodes } from "../common";
import usePrevious from "react-use/lib/usePrevious";
import useDebounce from "react-use/lib/useDebounce";
import { useSnackbar } from "notistack";
import listReplace from "util/listReplace";
import { useTrackUserImpression } from "util/userActivityTracker";
import { getPatientHealthPlanName } from "util/patientUtils";
import { getPendingAuthStatuses, useCanEditUnits } from "util/serviceRequest";
import { useTheme } from "@mui/material/styles";
import { useAuthorized } from "authorization";
import { UnitsContinuationWidth, UnitsInitialWidth } from "common/SharedServiceRequestFormComponents";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";

export interface Props {
  formContent: ServiceRequestFormContent;
  clinicalService?: ClinicalService;
  procedureCodes: ProcedureCodeWithId[];
  setProcedureCodes: React.Dispatch<React.SetStateAction<ProcedureCodeWithId[]>>;
  setUnits: Dispatch<SetStateAction<string>>;
  setNonPalPDFOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setNonPalProcedureCodes: Dispatch<SetStateAction<ProcedureCode[]>>;
  nonPalProcedureCodes: ProcedureCode[];
  nonCohereProcedureCodes: NonCohereCodes[];
  setNonCohereProcedureCodes: Dispatch<SetStateAction<NonCohereCodes[]>>;
  attemptedSubmit: boolean;
  palProcedureCodes: ProcedureCode[];
  setPalProcedureCodes: (pxs: ProcedureCode[]) => void;
  patient: Patient | null;
  authorization?: AuthorizationResponse;
  requestType?: string;
  authStatus: AuthStatus;
  isPxCodesValid?: boolean;
  onPatientSummary?: boolean;
  isUsedInPACheckRedesign?: boolean;
  lastElement: boolean;
  showExpedited: boolean;
  servicesFormContents?: ServiceRequestFormContent[];
  isFormContentMatchingServiceRequestPxCode?: boolean;
  setIsAdditionalUnitsRequested?: Dispatch<SetStateAction<boolean>>;
  allowedPxCodePerPayer?: number;
}

const useStyles = makeStyles((theme) => ({
  initialUnits: {
    flex: `0 0 ${UnitsInitialWidth}px`,
    width: UnitsInitialWidth,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  continuationUnits: {
    flex: `0 1 ${UnitsContinuationWidth}px`,
    minWidth: "100px",
    marginBottom: theme.spacing(1),
  },
  procedureCodeErrorText: {
    color: theme.palette.error.dark,
    fontFamily: "Gilroy-Regular",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "15px",
    letterSpacing: "0.15px",
    marginBottom: "16px",
  },
}));

const AUTOFILL_TIMEOUT = 1200;
export default function ProceduresBucket({
  formContent,
  clinicalService,
  procedureCodes,
  setProcedureCodes,
  setUnits,
  setNonPalPDFOpen,
  setNonPalProcedureCodes,
  nonPalProcedureCodes,
  nonCohereProcedureCodes,
  attemptedSubmit,
  palProcedureCodes,
  setPalProcedureCodes,
  setNonCohereProcedureCodes,
  patient,
  authorization,
  requestType,
  authStatus,
  isPxCodesValid,
  onPatientSummary,
  isUsedInPACheckRedesign,
  isFormContentMatchingServiceRequestPxCode,
  setIsAdditionalUnitsRequested,
  servicesFormContents,
  allowedPxCodePerPayer,
}: Props) {
  const trackUserActivityImpression = useTrackUserImpression();
  const [procedureCodeSelectionActive, setProcedureCodeSelectionActive] = useState(false);
  const [onBlurServiceLevelUnits, setOnBlurServiceLevelUnits] = useState(0);
  const prevOnBlurServiceLevelUnits = usePrevious(onBlurServiceLevelUnits);
  const isUnitsOnPx = clinicalService ? !!clinicalService?.isUnitsOnPx : true;
  const { enqueueSnackbar } = useSnackbar();
  const initialRender = useRef(true);
  const [autoFilled, setAutoFilled] = useState(false);
  const [serviceLevelUnits, setServiceLevelUnits] = useState(formContent?.units);
  const prevServiceLevelUnits = usePrevious(serviceLevelUnits);
  const previousCareType = usePrevious(formContent?.isInpatient);
  const prevProcedureCodes = usePrevious(procedureCodes);
  const [isSinglePxUpdate, setIsSinglePxUpdate] = useState(false);
  const cssClasses = useStyles();
  const { spacing } = useTheme();
  //temporary
  const minNumUnits = 1;
  const isContinuation = requestType === "CONTINUATION";
  const validUnits = isContinuation
    ? Number.isInteger(Number(formContent?.units)) && !!isPxCodesValid
    : parseInt(formContent?.units) > Math.max(0, minNumUnits - 1) && Number.isInteger(Number(formContent?.units));

  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);

  const updateProcedureCodes = useCallback(() => {
    const newProcedureCodes = constructProcedureCodes({
      procedureCodes: procedureCodes,
      prevIsInpatient: previousCareType,
      isInpatient: formContent.isInpatient,
      prevServiceLevelUnits: prevServiceLevelUnits,
      serviceLevelUnits: formContent.units,
      clinicalServiceId: clinicalService?.id || "",
      isUnitsOnPx: isUnitsOnPx || false,
      isContinuation: isContinuation || false,
    }).map(withId);
    // this will need to be updated to only set procedure code data on current set of procedure codes
    setProcedureCodes(newProcedureCodes);
  }, [
    procedureCodes,
    previousCareType,
    formContent.isInpatient,
    formContent.units,
    prevServiceLevelUnits,
    clinicalService?.id,
    isUnitsOnPx,
    isContinuation,
    setProcedureCodes,
  ]);
  useEffect(() => {
    /** Check if serviceLevelUnits has not been set yet and serviceLevelUnits has lost focus, then set serviceLevelUnits **/
    if (serviceLevelUnits !== formContent?.units && prevOnBlurServiceLevelUnits !== onBlurServiceLevelUnits) {
      setServiceLevelUnits(formContent?.units);
      updateProcedureCodes();
    }
  }, [
    formContent.units,
    onBlurServiceLevelUnits,
    prevOnBlurServiceLevelUnits,
    serviceLevelUnits,
    updateProcedureCodes,
    setServiceLevelUnits,
  ]);

  // TODO this will need to be refactored since the move into this component
  //this is probably the cause of erasing old px codes when adding new
  const addPxCodeToFormContent = (codes: ProcedureCode[]) => {
    //close the selection dropdown after the user has made a selection
    setProcedureCodeSelectionActive(false);
    /**
     * if new codes are added then update construct and update state,
     * this is to make sure if the palCheck doesn't add the code we don't add the last code again
     */
    if (codes.length > procedureCodes.length) {
      const newProcedureCodes = constructProcedureCodes({
        procedureCodes: [codes[codes.length - 1]],
        prevIsInpatient: false,
        isInpatient: formContent.isInpatient,
        prevServiceLevelUnits: prevServiceLevelUnits,
        serviceLevelUnits: formContent.units,
        clinicalServiceId: clinicalService?.id || "",
        isUnitsOnPx: isUnitsOnPx || false,
        isContinuation,
      }).map(withId);

      setProcedureCodes((prev) => {
        return [...prev, ...newProcedureCodes];
      });
    }
  };

  useDebounce(
    () => {
      /** After debouncing for 1000, check if serviceLevelUnits has been set yet, if not set then set serviceLevelUnits **/
      if (serviceLevelUnits !== formContent?.units && !isUnitsOnPx) {
        setServiceLevelUnits(formContent?.units);
        updateProcedureCodes();
      }
    },
    1000,
    [formContent?.units]
  );

  useEffect(() => {
    /** Check if care type was switched **/
    if (formContent?.isInpatient !== previousCareType) {
      setServiceLevelUnits(formContent?.units);
      updateProcedureCodes();
    }
  }, [formContent?.isInpatient, formContent?.units, previousCareType, serviceLevelUnits, updateProcedureCodes]);

  /* Checks if all procedureCodeUnits have changed */
  const hasProcedureCodeChanged = useCallback((): boolean => {
    /* If no new pxCodes have been added to the list and no singlePxField update has happened, then check all the curr pxCodes units !==  prev pxCode units */
    if (prevProcedureCodes && prevProcedureCodes.length === procedureCodes.length && !isSinglePxUpdate && isUnitsOnPx) {
      return prevProcedureCodes.every((prevCode) => {
        return procedureCodes.find((currCode) => prevCode.code === currCode.code && prevCode.units !== currCode.units);
      });
    }
    return false;
  }, [isSinglePxUpdate, isUnitsOnPx, prevProcedureCodes, procedureCodes]);

  useDebounce(
    () => {
      const exceedsMax = procedureCodes.filter(
        (pxCode) => pxCode.units && pxCode.maxUnits && pxCode.units > (pxCode.maxUnits ?? 1) * Number(serviceLevelUnits)
      );
      if (isSinglePxUpdate && exceedsMax && exceedsMax.length > 0) {
        trackUserActivityImpression({
          event: "EXCEEDED_MAX_UNITS_FOR_PXCODES",
          stage: "AUTH_CREATION",
          activityContext: {
            procedureCodes: exceedsMax,
            serviceRequestId: formContent?.id,
            cohereAuthId: formContent?.cohereId,
          },
        });
      }
      setIsSinglePxUpdate(false);
    },
    1000,
    [procedureCodes, isSinglePxUpdate]
  );

  useEffect(() => {
    if (!initialRender.current && serviceLevelUnits && hasProcedureCodeChanged() && !isUnitsOnPx) {
      /** Apply autofill animation for 1200ms then revert back to original state **/
      setAutoFilled(true);
      setTimeout(() => {
        setAutoFilled(false);
      }, AUTOFILL_TIMEOUT);
      enqueueSnackbar(
        `Default units updated based on what most people request for ${serviceLevelUnits} ${
          !formContent.isInpatient ? "outpatient" : "inpatient"
        } service ${Number(serviceLevelUnits) > 1 ? "dates" : "date"}`,
        {
          variant: "info",
        }
      );
    }
    if (initialRender.current) {
      initialRender.current = false;
    }
  }, [
    enqueueSnackbar,
    formContent.isInpatient,
    prevProcedureCodes,
    procedureCodes,
    serviceLevelUnits,
    isSinglePxUpdate,
    hasProcedureCodeChanged,
    isUnitsOnPx,
  ]);

  useEffect(() => {
    if (!isFormContentMatchingServiceRequestPxCode && procedureCodes) {
      let calculatedUnits = formContent?.units;
      if (isUnitsOnPx) {
        calculatedUnits = procedureCodes.reduce((a, b) => a + (b.units ? b.units : 0), 0).toString();
      }
      if (calculatedUnits !== formContent?.units) {
        setUnits(calculatedUnits);
      }
    }
  }, [procedureCodes, setUnits, isUnitsOnPx, formContent?.units, isFormContentMatchingServiceRequestPxCode]);

  const updatePxCodeUnits = (rowIndex: number, units: string) => {
    const updatedProcedureCodes = [...procedureCodes];
    setIsSinglePxUpdate(true);
    setProcedureCodes(
      listReplace(updatedProcedureCodes, rowIndex, {
        ...updatedProcedureCodes[rowIndex],
        units: /^\d*$/.test(units)
          ? units !== ""
            ? Number(units)
            : undefined
          : updatedProcedureCodes[rowIndex]?.units,
      })
    );
  };

  const healthPlanName = getPatientHealthPlanName(patient || undefined) || "";
  const canEditUnits = useCanEditUnits({ authStatus });
  const canAddPxPendingSR = useAuthorized("ADD_PX_PENDING_SERV_REQUEST");
  // Iterates over each clinical service, sums up the length of procedure codes arrays
  const totalProcedureCount = servicesFormContents?.reduce((total, value) => total + value.procedureCodes.length, 0);
  // Checks if the total count is less than or equal to maxAllowedPxCodePerPayer
  const canAddMoreProcedureCodes = Boolean(
    totalProcedureCount && allowedPxCodePerPayer && totalProcedureCount < allowedPxCodePerPayer
  );
  const isPxCodeLimitExceeded = Boolean(
    allowedPxCodePerPayer &&
      allowedPxCodePerPayer > 0 &&
      totalProcedureCount &&
      totalProcedureCount > allowedPxCodePerPayer
  );

  return (
    <>
      <Grid item xs={12} style={{ marginTop: spacing(4) }}>
        <H6
          style={{ marginBottom: isFaxAuthBuilderFlow ? (isUnitsOnPx ? spacing(0.5) : spacing(2)) : spacing(1) }}
          data-public
        >
          {clinicalService?.name || "Uncategorized service"}
        </H6>
        {isPxCodeLimitExceeded && (
          <div className={cssClasses.procedureCodeErrorText}>
            {`${healthPlanName} allows up to ${allowedPxCodePerPayer} codes. Please remove codes to continue.`}
          </div>
        )}
      </Grid>
      {!isUnitsOnPx && !isContinuation && procedureCodes.length > 0 && (
        <Grid item className={cssClasses.initialUnits}>
          <UnitTextField
            initialUnits={formContent?.units?.toString() || clinicalService?.defaultUnits?.toString() || "1"}
            units={formContent.units}
            setUnits={setUnits}
            unitLabel={"Number of visits"}
            onBlurServiceLevelUnits={onBlurServiceLevelUnits}
            setOnBlurServiceLevelUnits={setOnBlurServiceLevelUnits}
            hasValidUnits={validUnits}
            disabled={!canEditUnits}
            defaultUnits={clinicalService?.defaultUnits}
            attemptedSubmit={attemptedSubmit}
            minNumUnits={1}
          />
        </Grid>
      )}
      {!isUnitsOnPx && isContinuation && procedureCodes.length > 0 && (
        <Grid
          item
          xs
          style={{
            display: "flex",
          }}
        >
          <Grid
            className={cssClasses.continuationUnits}
            style={{
              marginRight: spacing(2),
            }}
          >
            <UnitTextField
              initialUnits={
                authorization?.semanticProcedureCodes
                  ?.find((px) => procedureCodes.find((code) => code.code === px.code && code.groupId === px.groupId))
                  ?.approvedUnits?.toString() || "-"
              }
              units={
                authorization?.semanticProcedureCodes
                  ?.filter((px) => procedureCodes.find((code) => code.code === px.code && code.groupId === px.groupId))
                  ?.reduce((a, b) => (a > (b.approvedUnits ? b.approvedUnits : 0) ? a : b?.approvedUnits || 0), 0)
                  .toString() || "-"
              }
              setUnits={setUnits}
              unitLabel={"Previously approved visits"}
              onBlurServiceLevelUnits={onBlurServiceLevelUnits}
              setOnBlurServiceLevelUnits={setOnBlurServiceLevelUnits}
              // Don't check valid units when this is in the continuations workflow, since this will be a read-only field
              hasValidUnits={true}
              disabled={true}
              defaultUnits={clinicalService?.defaultUnits}
              attemptedSubmit={attemptedSubmit}
              minNumUnits={1}
            />
          </Grid>
          <Grid className={cssClasses.continuationUnits}>
            <UnitTextField
              units={formContent.units}
              initialUnits=""
              setUnits={setUnits}
              unitLabel={"Additional visits"}
              onBlurServiceLevelUnits={onBlurServiceLevelUnits}
              setOnBlurServiceLevelUnits={setOnBlurServiceLevelUnits}
              hasValidUnits={validUnits}
              defaultUnits={clinicalService?.defaultUnits}
              attemptedSubmit={attemptedSubmit}
              minNumUnits={minNumUnits}
              disabled={!canEditUnits}
              requestType={requestType}
              setIsAdditionalUnitsRequested={setIsAdditionalUnitsRequested}
            />
          </Grid>
        </Grid>
      )}
      <Grid xs={12} style={{ marginBottom: 14 }}>
        <ProcedureCodeTable
          hideHeaders={true}
          formContent={formContent}
          setNonPalPDFOpen={setNonPalPDFOpen}
          nonPalProcedureCodes={nonPalProcedureCodes}
          nonCohereProcedureCodes={nonCohereProcedureCodes}
          procedureCodes={procedureCodes}
          setProcedureCodes={setProcedureCodes}
          setProcedureCodeSelectionActive={setProcedureCodeSelectionActive}
          serviceLevelUnits={serviceLevelUnits}
          isUnitsOnPx={isUnitsOnPx}
          autoFilled={autoFilled}
          updatePxCodeUnits={updatePxCodeUnits}
          attemptedSubmit={attemptedSubmit}
          noPadding={true}
          withRowDivider={false}
          isContinuation={isContinuation}
          authorization={authorization}
          healthPlanName={healthPlanName}
          authStatus={authStatus}
          isPxCodesValid={isPxCodesValid}
          requestType={requestType}
          onPatientSummary={onPatientSummary}
          isUsedInPACheckRedesign={isUsedInPACheckRedesign}
          setIsAdditionalUnitsRequested={setIsAdditionalUnitsRequested}
        />
        {(!onPatientSummary || (getPendingAuthStatuses().includes(authStatus || "") && canAddPxPendingSR)) && (
          <ProcedureCodeSelector
            formContent={formContent}
            canAddMoreProcedureCodes={canAddMoreProcedureCodes && !isPxCodeLimitExceeded}
            allowedPxCodePerPayer={allowedPxCodePerPayer}
            palProcedureCodes={palProcedureCodes}
            setPalProcedureCodes={setPalProcedureCodes}
            setNonPalPDFOpen={setNonPalPDFOpen}
            nonPalProcedureCodes={nonPalProcedureCodes}
            setNonPalProcedureCodes={setNonPalProcedureCodes}
            nonCohereProcedureCodes={nonCohereProcedureCodes}
            setNonCohereProcedureCodes={setNonCohereProcedureCodes}
            procedureCodeSelectionActive={procedureCodeSelectionActive}
            setProcedureCodeSelectionActive={setProcedureCodeSelectionActive}
            procedureCodes={procedureCodes}
            setProcedureCodes={(codes) => addPxCodeToFormContent(codes)}
            patientId={patient?.id}
            healthPlanName={healthPlanName}
            attemptedSubmit={attemptedSubmit}
            error={formContent.isInpatient ? procedureCodes.length < 0 : !isPxCodesValid}
            isPxCodesValid={isPxCodesValid}
          />
        )}
      </Grid>
    </>
  );
}
