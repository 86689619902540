import {
  ReviewType,
  MdReview,
  PeerToPeerReview,
  NurseReview,
  OutOfNetworkReview,
  AuditReview,
  HmoExceptionReview,
  Indication,
  GuidelineType,
  NewHireAuditReview,
  OutOfScopeAuditReview,
  NudgeInfo,
  FaxAuditReview,
  ProcedureCode,
} from "@coherehealth/core-platform-api";
import { UNLISTED_GUIDELINE_ID } from "../../ServiceRequest/ReviewSection/MDReview/GuidelinesUsedSection";
import { cloneDeep } from "lodash";
import listInsert from "util/listInsert";
import listRemove from "util/listRemove";
import listReplace from "util/listReplace";
import React, { useCallback, useEffect, useReducer, useContext, useMemo } from "react";
import { useSnackbar } from "notistack";
import { ToggleResults, canHaveCoverageLevelDetails } from "@coherehealth/common";
import { error as logError } from "logger";
import { isSamePxCode } from "components/ServiceRequest/ReviewSection/util/useProcedureCodesState";

interface ReviewsProviderProps {
  initialReviews: ReviewType[] | null;
  children: React.ReactNode;
}

type ReviewsState = ReviewType[] | null;

type ReviewStateContextProps = ReviewType[] | null;
type ReviewDispatchContextProps = React.Dispatch<ReviewsAction> | null;

type InitReviewsAction = { type: "INIT_REVIEWS"; reviews: ReviewType[] };
type ReviewActionBase = {
  type: "SET_REVIEW";
  reviewId: string;
  reviewType: ReviewType["reviewType"];
};
interface SetMdReviewAction extends ReviewActionBase {
  reviewType: "MdReview";
  payload: Partial<MdReview>;
}

interface SetNurseReviewAction extends ReviewActionBase {
  reviewType: "NurseReview";
  payload: Partial<NurseReview>;
}

interface SetOutOfNetworkReviewAction extends ReviewActionBase {
  reviewType: "OutOfNetworkReview";
  payload: Partial<OutOfNetworkReview>;
}

interface SetAuditReviewAction extends ReviewActionBase {
  reviewType: "AuditReview";
  payload: Partial<AuditReview>;
}

interface SetNewHireAuditReviewAction extends ReviewActionBase {
  reviewType: "NewHireAuditReview";
  payload: Partial<NewHireAuditReview>;
}

interface SetP2PReviewAction extends ReviewActionBase {
  reviewType: "PeerToPeerReview";
  payload: Partial<PeerToPeerReview>;
}

interface SetHmoReviewAction extends ReviewActionBase {
  reviewType: "HmoExceptionReview";
  payload: Partial<HmoExceptionReview>;
}

interface SetOOSAuditReviewAction extends ReviewActionBase {
  reviewType: "OutOfScopeAuditReview";
  payload: Partial<OutOfScopeAuditReview>;
}

interface SetFaxAuditReviewAction extends ReviewActionBase {
  reviewType: "FaxAuditReview";
  payload: Partial<FaxAuditReview>;
}

type SetReviewAction =
  | SetMdReviewAction
  | SetNurseReviewAction
  | SetOutOfNetworkReviewAction
  | SetAuditReviewAction
  | SetNewHireAuditReviewAction
  | SetP2PReviewAction
  | SetHmoReviewAction
  | SetOOSAuditReviewAction
  | SetFaxAuditReviewAction;

type CheckIndicationsAction = {
  type: "CHECK_INDICATION";
  reviewId: string;
  payload: {
    checkedIndication: Indication;
    toggleFunc: (metIndications: Indication[], unmetIndications: Indication[], met: boolean) => ToggleResults;
    indicationPolicyType?: GuidelineType;
    met: boolean;
    guidelineId: string;
  };
};
type NurseNudgeAction = {
  type: "SET_NURSE_NUDGE";
  reviewId: string;
  payload: {
    fieldName: keyof NudgeInfo;
    idx: number;
    opt: string | boolean;
  };
};
type setUsedGuidelineIdsAction = {
  type: "SET_USED_GUIDELINES";
  reviewId: string;
  payload: {
    updatedGuidelineId: string;
    updatedIndex: number;
  };
};
type setMultiCoverageUsedGuidelineIdsAction = {
  type: "SET_MULTI_COVERAGE_USED_GUIDELINES";
  reviewId: string;
  payload: {
    updatedGuidelineId: string;
    updatedIndex: number;
    coverageIndex: number;
  };
};
type addReviewAction = {
  type: "ADD_REVIEW";
  reviewId: string;
  payload: {
    review: ReviewType;
  };
};
type toggleGuidelineForCoverageAction = {
  type: "TOGGLE_GUIDELINE_FOR_COVERAGE";
  reviewId: string;
  payload: {
    coverageIndex: number;
    guidelineIds: string[];
  };
};
type addUsedGuidelineIdsAction = {
  type: "ADD_USED_GUIDELINES";
  reviewId: string;
};
type addMultiCoverageGuidelineIdsAction = {
  type: "ADD_MULTI_COVERAGE_USED_GUIDELINES";
  reviewId: string;
  payload: {
    coverageIndex: number;
  };
};
type deleteUsedGuidelineIdsAction = {
  type: "DELETE_USED_GUIDELINES";
  reviewId: string;
  payload: {
    deletedIndex: number;
  };
};
type deleteMultiCoverageUsedGuidelineIdsAction = {
  type: "DELETE_MULTI_COVERAGE_USED_GUIDELINES";
  reviewId: string;
  payload: {
    deletedIndex: number;
    coverageIndex: number;
  };
};
type setMultiCoverageApprovedSemanticProcedureCodesAction = {
  type: "SET_MULTI_COVERAGE_APPROVED_SEMANTIC_PROCEDURE_CODES";
  reviewId: string;
  payload: {
    coverageIndex: number;
    coverageSemanticProcedureCodes: ProcedureCode[];
  };
};

export type ReviewsAction =
  | InitReviewsAction
  | SetReviewAction
  | CheckIndicationsAction
  | NurseNudgeAction
  | setUsedGuidelineIdsAction
  | setMultiCoverageUsedGuidelineIdsAction
  | addReviewAction
  | toggleGuidelineForCoverageAction
  | addUsedGuidelineIdsAction
  | addMultiCoverageGuidelineIdsAction
  | deleteUsedGuidelineIdsAction
  | deleteMultiCoverageUsedGuidelineIdsAction
  | setMultiCoverageApprovedSemanticProcedureCodesAction;

const ReviewStateContext = React.createContext<ReviewStateContextProps>(null);
const ReviewDispatchContext = React.createContext<ReviewDispatchContextProps>(null);

const ReviewsProvider = ({ initialReviews, children }: ReviewsProviderProps) => {
  const [reviewsProviderState, reviewsDispatch] = useReducer(reviewsReducer, initialReviews);

  useEffect(() => {
    initialReviews && reviewsDispatch({ type: "INIT_REVIEWS", reviews: initialReviews });
  }, [initialReviews]);

  const memoReviewsState = useMemo(() => reviewsProviderState, [reviewsProviderState]);
  return (
    <ReviewStateContext.Provider value={memoReviewsState}>
      <ReviewDispatchContext.Provider value={reviewsDispatch}>{children}</ReviewDispatchContext.Provider>
    </ReviewStateContext.Provider>
  );
};

const reviewsReducer = (reviews: ReviewsState, action: ReviewsAction): ReviewsState => {
  switch (action.type) {
    case "ADD_REVIEW":
      return addReview(reviews, action);
    case "INIT_REVIEWS":
      const allReviews = [...(reviews || []), ...action.reviews];
      return initializeReviews(allReviews);
    case "SET_REVIEW":
      return setReview(reviews, action);
    case "CHECK_INDICATION":
      return checkIndication(reviews, action);
    case "SET_NURSE_NUDGE":
      return setNurseNudge(reviews, action);
    case "SET_USED_GUIDELINES":
      return setUsedGuidelineIds(reviews, action);
    case "SET_MULTI_COVERAGE_USED_GUIDELINES":
      return setMultiCoverageUsedGuidelineIds(reviews, action);
    case "ADD_USED_GUIDELINES":
      return addUsedGuidelineIds(reviews, action);
    case "ADD_MULTI_COVERAGE_USED_GUIDELINES":
      return addMultiCoverageUsedGuidelineIds(reviews, action);
    case "TOGGLE_GUIDELINE_FOR_COVERAGE":
      return toggleGuidelineForCoverage(reviews, action);
    case "DELETE_USED_GUIDELINES":
      return deleteUsedGuidelineId(reviews, action);
    case "DELETE_MULTI_COVERAGE_USED_GUIDELINES":
      return deleteMultiCoverageUsedGuidelineId(reviews, action);
    case "SET_MULTI_COVERAGE_APPROVED_SEMANTIC_PROCEDURE_CODES":
      return setMultiCoverageApprovedSemanticProcedureCodes(reviews, action);
    default:
      throw new Error(`Unknown action type`);
  }
};

function updateObject<T>(prevObject: T, updatedFields: Partial<T>): T {
  return {
    ...prevObject,
    ...updatedFields,
  };
}

const addReview = (reviews: ReviewsState, action: addReviewAction): ReviewsState => {
  const reviewId = action.reviewId;
  const review = action.payload.review;
  if (reviews?.find((review) => review.id === reviewId)) {
    return reviews;
  }
  return [...(reviews || []), review];
};

const initializeReviews = (reviews: ReviewsState): ReviewsState => {
  const initializedReviews =
    reviews &&
    reviews.map((review) => {
      if (review.reviewType === "MdReview") {
        return initializeMdReview(review);
      } else if (review.reviewType === "NurseReview") {
        return initializeNurseReview(review);
      } else if (review.reviewType === "OutOfNetworkReview") {
        return initializeOutOfNetworkReview(review);
      } else if (review.reviewType === "AuditReview") {
        return initializeAuditReview(review);
      } else if (review.reviewType === "PeerToPeerReview") {
        return initializeP2PReview(review);
      } else if (review.reviewType === "HmoExceptionReview") {
        return initializeHmoReview(review);
      } else {
        return review;
      }
    });
  return initializedReviews;
};

const initializeMdReview = (mdReview: MdReview): MdReview => {
  const initUnusedGuidelineId = maybeInjectUnlistedGuidelineId(mdReview);
  return initUnusedGuidelineId;
};

const initializeNurseReview = (nurseReview: NurseReview): NurseReview => {
  return nurseReview;
};

const initializeOutOfNetworkReview = (outOfNetworkReview: OutOfNetworkReview): OutOfNetworkReview => {
  return outOfNetworkReview;
};

const initializeAuditReview = (auditReview: AuditReview): AuditReview => {
  return auditReview;
};

const initializeP2PReview = (p2pReview: PeerToPeerReview): PeerToPeerReview => {
  const initUnusedGuidelineId = maybeInjectUnlistedGuidelineId(p2pReview);
  return initUnusedGuidelineId;
};

const initializeHmoReview = (hmoReview: HmoExceptionReview): HmoExceptionReview => {
  return hmoReview;
};

const maybeInjectUnlistedGuidelineId = <T extends MdReview | PeerToPeerReview>(mdOrP2p: T): T => {
  const reviewCopy = { ...mdOrP2p };
  if (reviewCopy.wasUnlistedGuidelineUsed) {
    reviewCopy.usedGuidelineIds = [...(reviewCopy.usedGuidelineIds || []), UNLISTED_GUIDELINE_ID];
  }
  return reviewCopy;
};

const setReview = (reviews: ReviewsState, action: SetReviewAction): ReviewsState => {
  const { reviewId, payload, reviewType } = action;
  const updatedReviews =
    reviews &&
    reviews.map((review) => {
      if (review.id !== reviewId || review.reviewType !== reviewType) {
        return review;
      } else {
        return updateObject(review, payload);
      }
    });
  return updatedReviews;
};

const checkIndication = (reviews: ReviewsState, action: CheckIndicationsAction): ReviewsState => {
  const { reviewId, payload } = action;
  const updatedReviews =
    reviews &&
    reviews.map((review) => {
      if (review.id !== reviewId) {
        return review;
      } else {
        const { toggleFunc, indicationPolicyType, met, guidelineId, checkedIndication } = payload;
        const clonedGuidelinePolicyMaps = cloneDeep(review.guidelines) || [];
        clonedGuidelinePolicyMaps.forEach((gpm) => {
          if (gpm.policyType === indicationPolicyType) {
            ({ checkedIndications: gpm.checkedIndications, unmetIndications: gpm.unmetIndications } = toggleFunc(
              gpm.checkedIndications || [],
              gpm.unmetIndications || [],
              met
            ));
            const indicationState = gpm.guidelineStates
              ?.find((guidelineState) => guidelineState.guidelineId === guidelineId)
              ?.indicationStates?.find((indicationState) => indicationState.indicationUid === checkedIndication.uid);
            if (indicationState) {
              indicationState.indicationState = met
                ? indicationState.indicationState === "MET"
                  ? "UNDECIDED"
                  : "MET"
                : indicationState.indicationState === "UNMET"
                ? "UNDECIDED"
                : "UNMET";
            }
          }
        });
        return updateObject(review, { guidelines: clonedGuidelinePolicyMaps });
      }
    });
  return updatedReviews;
};
const setNurseNudge = (reviews: ReviewsState, action: NurseNudgeAction): ReviewsState => {
  const { reviewId, payload } = action;
  const updatedReviews =
    reviews &&
    reviews.map((review) => {
      if (review.id !== reviewId || review.reviewType !== "NurseReview") {
        return review;
      } else {
        const { fieldName, idx, opt } = payload;
        const nurseReview = review;
        if (nurseReview.nurseReviewNudges) {
          const newNudges = listInsert(listRemove(nurseReview.nurseReviewNudges, idx), idx, {
            ...nurseReview.nurseReviewNudges[idx],
            [fieldName]: opt,
          });
          return updateObject(review, { nurseReviewNudges: newNudges });
        }
        return review;
      }
    });
  return updatedReviews;
};
const setUsedGuidelineIds = (reviews: ReviewsState, action: setUsedGuidelineIdsAction): ReviewsState => {
  const { reviewId, payload } = action;
  const updatedReviews =
    reviews &&
    reviews.map((review) => {
      if (review.id !== reviewId) {
        return review;
      } else {
        const { updatedGuidelineId, updatedIndex } = payload;
        const updatedUsedGuidelineIds = setUsedGuidelineIdsHelper(
          updatedIndex,
          updatedGuidelineId,
          review.usedGuidelineIds
        );

        return updateObject(review, {
          usedGuidelineIds: updatedUsedGuidelineIds,
          wasUnlistedGuidelineUsed: updatedUsedGuidelineIds.includes(UNLISTED_GUIDELINE_ID),
        });
      }
    });
  return updatedReviews;
};

const setMultiCoverageUsedGuidelineIds = (
  reviews: ReviewsState,
  action: setMultiCoverageUsedGuidelineIdsAction
): ReviewsState => {
  const { reviewId, payload } = action;
  const updatedReviews =
    reviews &&
    reviews.map((review) => {
      if (review.id !== reviewId) {
        return review;
      } else {
        if (!canHaveCoverageLevelDetails(review)) {
          logError(`Unexpected review type ${review.reviewType} in setMultiCoverageUsedGuidelineIds`);
          return review;
        }

        if (!review.coverageLevelDetails) {
          logError(`Unexpected unfilled coverageLevelDetails in setMultiCoverageUsedGuidelineIds`);
          return review;
        }
        const { updatedGuidelineId, updatedIndex, coverageIndex } = payload;

        const coverageLevelDetail = review.coverageLevelDetails[coverageIndex];

        const updatedUsedGuidelineIds = setUsedGuidelineIdsHelper(
          updatedIndex,
          updatedGuidelineId,
          coverageLevelDetail.usedGuidelineIds
        );

        const updatedCoverageLevelDetails = listReplace(review.coverageLevelDetails, coverageIndex, {
          ...coverageLevelDetail,
          usedGuidelineIds: updatedUsedGuidelineIds,
        });
        return updateObject(review, {
          coverageLevelDetails: updatedCoverageLevelDetails,
        });
      }
    });
  return updatedReviews;
};

const setUsedGuidelineIdsHelper = (
  updatedIndex: number,
  updatedGuidelineId: string,
  usedGuidelineIds?: string[] | undefined
) => {
  const prevUsedGuidelineIds = usedGuidelineIds && usedGuidelineIds.length > 0 ? usedGuidelineIds : [""];
  let updatedUsedGuidelineIds = prevUsedGuidelineIds.map((guidelineId, index) =>
    index === updatedIndex ? updatedGuidelineId : guidelineId
  );
  // ensure that only defined guideline cannot be deleted by placing it in front of the array
  const definedGuidelineIds = updatedUsedGuidelineIds.filter((guidelineId) => !!guidelineId);
  if (definedGuidelineIds.length === 1 && updatedUsedGuidelineIds[0] === "") {
    updatedUsedGuidelineIds = [definedGuidelineIds[0], ...Array(updatedUsedGuidelineIds.length - 1).fill("")];
  }
  return updatedUsedGuidelineIds;
};

const toggleGuidelineForCoverage = (reviews: ReviewsState, action: toggleGuidelineForCoverageAction): ReviewsState => {
  const {
    reviewId,
    payload: { coverageIndex, guidelineIds },
  } = action;

  const updatedReviews =
    reviews &&
    reviews.map((review) => {
      if (review.id !== reviewId) {
        return review;
      }

      if (!canHaveCoverageLevelDetails(review)) {
        logError(`Unexpected review type ${review.reviewType} in toggleGuidelineForCoverage`);
        return review;
      }

      if (!review.coverageLevelDetails) {
        logError(`Unexpected unfilled coverageLevelDetails in toggleGuidelineForCoverage`);
        return review;
      }
      const coverageLevelDetail = review.coverageLevelDetails[coverageIndex];

      const updatedCoverageLevelDetails = listReplace(review.coverageLevelDetails, coverageIndex, {
        ...coverageLevelDetail,
        selectedGuidelineIds: guidelineIds,
      });

      const unchangedCoverageLevelDetails = [...review.coverageLevelDetails];
      unchangedCoverageLevelDetails.splice(coverageIndex, 1);
      const unchangedCoverageLevelDetailsSelectedGuidelines = unchangedCoverageLevelDetails
        ?.map((item) => item.selectedGuidelineIds || [])
        .flat();
      const uniqueList = new Set([...guidelineIds, ...(unchangedCoverageLevelDetailsSelectedGuidelines || [])]);

      const dedupedSelectedGuidelineIds = Array.from(uniqueList);

      return {
        ...review,
        coverageLevelDetails: updatedCoverageLevelDetails,
        selectedGuidelineIds: dedupedSelectedGuidelineIds,
      };
    });

  return updatedReviews;
};
const addUsedGuidelineIds = (reviews: ReviewsState, action: addUsedGuidelineIdsAction): ReviewsState => {
  const { reviewId } = action;
  const updatedReviews =
    reviews &&
    reviews.map((review) => {
      if (review.id !== reviewId) {
        return review;
      } else {
        const prevUsedGuidelineIds =
          review.usedGuidelineIds && review.usedGuidelineIds.length > 0 ? review.usedGuidelineIds : [""];
        return updateObject(review, {
          usedGuidelineIds: [...prevUsedGuidelineIds, ""],
        });
      }
    });
  return updatedReviews;
};

const addMultiCoverageUsedGuidelineIds = (
  reviews: ReviewsState,
  action: addMultiCoverageGuidelineIdsAction
): ReviewsState => {
  const { reviewId, payload } = action;
  const updatedReviews =
    reviews &&
    reviews.map((review) => {
      if (review.id !== reviewId) {
        return review;
      } else {
        if (!canHaveCoverageLevelDetails(review)) {
          logError(`Unexpected review type ${review.reviewType} in addMultiCoverageUsedGuidelineIds`);
          return review;
        }

        if (!review.coverageLevelDetails) {
          logError(`Unexpected unfilled coverageLevelDetails in addMultiCoverageUsedGuidelineIds`);
          return review;
        }

        const { coverageIndex } = payload;
        const coverageLevelDetail = review.coverageLevelDetails[coverageIndex];
        const prevUsedGuidelineIds =
          coverageLevelDetail.usedGuidelineIds && coverageLevelDetail.usedGuidelineIds.length > 0
            ? coverageLevelDetail.usedGuidelineIds
            : [];

        const updatedCoverageLevelDetails = listReplace(review.coverageLevelDetails, coverageIndex, {
          ...coverageLevelDetail,
          usedGuidelineIds: [...prevUsedGuidelineIds],
        });

        return updateObject(review, {
          coverageLevelDetails: updatedCoverageLevelDetails,
        });
      }
    });
  return updatedReviews;
};

const deleteUsedGuidelineIdsHelper = (deletedIndex: number, previousUsedGuidelineIds?: string[] | undefined) => {
  const prevUsedGuidelineIds =
    previousUsedGuidelineIds && previousUsedGuidelineIds.length > 0 ? previousUsedGuidelineIds : [""];
  let updatedUsedGuidelineIds = [
    ...prevUsedGuidelineIds.slice(0, deletedIndex),
    ...prevUsedGuidelineIds.slice(deletedIndex + 1),
  ];
  // ensure that only defined guideline cannot be deleted by placing it in front of the array
  const definedGuidelineIds = updatedUsedGuidelineIds.filter((guidelineId) => !!guidelineId);
  if (definedGuidelineIds.length === 1 && updatedUsedGuidelineIds[0] === "") {
    updatedUsedGuidelineIds = [definedGuidelineIds[0], ...Array(updatedUsedGuidelineIds.length - 1).fill("")];
  }

  return updatedUsedGuidelineIds;
};

const deleteUsedGuidelineId = (reviews: ReviewsState, action: deleteUsedGuidelineIdsAction): ReviewsState => {
  const { reviewId, payload } = action;
  const updatedReviews =
    reviews &&
    reviews.map((review) => {
      if (review.id !== reviewId) {
        return review;
      } else {
        const { deletedIndex } = payload;
        const updatedUsedGuidelineIds = deleteUsedGuidelineIdsHelper(deletedIndex, review.usedGuidelineIds);

        return updateObject(review, {
          usedGuidelineIds: updatedUsedGuidelineIds,
          wasUnlistedGuidelineUsed: updatedUsedGuidelineIds.includes(UNLISTED_GUIDELINE_ID),
        });
      }
    });
  return updatedReviews;
};

const deleteMultiCoverageUsedGuidelineId = (
  reviews: ReviewsState,
  action: deleteMultiCoverageUsedGuidelineIdsAction
): ReviewsState => {
  const { reviewId, payload } = action;
  const updatedReviews =
    reviews &&
    reviews.map((review) => {
      if (review.id !== reviewId) {
        return review;
      } else {
        if (!canHaveCoverageLevelDetails(review)) {
          logError(`Unexpected review type ${review.reviewType} in deleteMultiCoverageUsedGuidelineId`);
          return review;
        }

        if (!review.coverageLevelDetails) {
          logError(`Unexpected unfilled coverageLevelDetails in deleteMultiCoverageUsedGuidelineId`);
          return review;
        }

        const { deletedIndex, coverageIndex } = payload;
        const coverageLevelDetail = review.coverageLevelDetails[coverageIndex];
        const updatedUsedGuidelineIds = deleteUsedGuidelineIdsHelper(
          deletedIndex,
          coverageLevelDetail.usedGuidelineIds
        );

        const updatedCoverageLevelDetails = listReplace(review.coverageLevelDetails, coverageIndex, {
          ...coverageLevelDetail,
          usedGuidelineIds: updatedUsedGuidelineIds,
        });

        return updateObject(review, {
          coverageLevelDetails: updatedCoverageLevelDetails,
        });
      }
    });
  return updatedReviews;
};

const setMultiCoverageApprovedSemanticProcedureCodes = (
  reviews: ReviewsState,
  action: setMultiCoverageApprovedSemanticProcedureCodesAction
): ReviewsState => {
  const { reviewId, payload } = action;
  const updatedReviews =
    reviews &&
    reviews.map((review) => {
      if (review.id !== reviewId) {
        return review;
      } else {
        if (!canHaveCoverageLevelDetails(review)) {
          logError(`Unexpected review type ${review.reviewType} in setMultiCoverageUsedGuidelineIds`);
          return review;
        }

        if (!review.coverageLevelDetails) {
          logError(`Unexpected unfilled coverageLevelDetails in setMultiCoverageUsedGuidelineIds`);
          return review;
        }
        const { coverageIndex, coverageSemanticProcedureCodes } = payload;

        const coverageLevelDetail = review.coverageLevelDetails[coverageIndex];

        const updatedCoverageLevelDetails = listReplace(review.coverageLevelDetails, coverageIndex, {
          ...coverageLevelDetail,
          approvedSemanticProcedureCodes: coverageSemanticProcedureCodes,
        });

        const allApprovedCodes: ProcedureCode[] = updatedCoverageLevelDetails
          .map((detail) => detail.approvedSemanticProcedureCodes || [])
          .flat();
        const updatedReviewLevelCodes: ProcedureCode[] = condenseApprovedSemanticProcedureCodesHelper(allApprovedCodes);

        const approvedUnits = updatedReviewLevelCodes.reduce((total, code) => total + (code.approvedUnits ?? 0), 0);

        return updateObject(review, {
          coverageLevelDetails: updatedCoverageLevelDetails,
          approvedSemanticProcedureCodes: updatedReviewLevelCodes,
          approvedUnits,
        });
      }
    });
  return updatedReviews;
};

const condenseApprovedSemanticProcedureCodesHelper = (codes: ProcedureCode[]): ProcedureCode[] => {
  return codes.reduce<ProcedureCode[]>((acc, current) => {
    const existingIndex = acc.findIndex((item) => isSamePxCode(item, current));
    if (existingIndex === -1) {
      //if code doesn't exist in list, add ut
      acc.push(current);
    } else {
      //if it does exist, we need to use the code with more units
      if ((current.approvedUnits ?? 0) > (acc[existingIndex].approvedUnits ?? 0)) {
        acc[existingIndex] = current;
      }
    }
    return acc;
  }, []);
};

const useReviewsState = () => {
  const reviewsState = useContext(ReviewStateContext);
  const { enqueueSnackbar } = useSnackbar();
  if (!reviewsState) {
    enqueueSnackbar(`useReviewState must be used within a ReviewsState Provider`, { variant: "error" });
    return null;
  }
  return reviewsState;
};

const useReviewsDispatch = (prop?: { suppressErrorSnackbar?: boolean }) => {
  const { suppressErrorSnackbar } = prop || {};
  const reviewsDispatch = useContext(ReviewDispatchContext);
  const { enqueueSnackbar } = useSnackbar();
  if (!reviewsDispatch) {
    if (!suppressErrorSnackbar) {
      enqueueSnackbar(`useReviewsDispatch must be used within a ReviewsDispatch Provider`, { variant: "error" });
    }
    return null;
  }
  return reviewsDispatch;
};

interface UseMdReviewProps {
  mdReviewId: string;
}

interface UseNurseReviewProps {
  nurseReviewId: string;
}

interface UseOutOfNetworkReviewProps {
  outOfNetworkReviewId: string;
}

interface UseAuditReviewProps {
  auditReviewId: string;
}

interface UseP2PReviewProps {
  p2pReviewId: string;
}

interface UseHmoReviewProps {
  hmoReviewId: string;
}

interface ReviewInfo {
  reviewId: string;
  reviewType: ReviewType["reviewType"];
}

interface MdReviewInfo extends ReviewInfo {
  reviewType: "MdReview";
}
export type MdReviewUpdate = (mdReviewUpdate: Partial<MdReview>) => void;
type MdReviewStateProps =
  | [mdReview: MdReview, setMdReview: MdReviewUpdate, reviewsDispatch: React.Dispatch<ReviewsAction>]
  | [];

interface P2PReviewInfo extends ReviewInfo {
  reviewType: "PeerToPeerReview";
}
export type P2PReviewUpdate = (p2pReviewUpdate: Partial<PeerToPeerReview>) => void;
type P2PReviewStateProps =
  | [p2pReview: PeerToPeerReview, setP2PReview: P2PReviewUpdate, reviewsDispatch: React.Dispatch<ReviewsAction>]
  | [];

type GenericDoctorReviewInfo = MdReviewInfo | P2PReviewInfo;
export type GenericDoctorReview = MdReview | PeerToPeerReview;
export type GenericDoctorUpdate<T extends GenericDoctorReview> = T extends MdReview ? MdReviewUpdate : P2PReviewUpdate;
type GenericDoctorReturnProps<T extends GenericDoctorReviewInfo> = T extends MdReviewInfo
  ? MdReviewStateProps
  : P2PReviewStateProps;

interface NurseReviewInfo extends ReviewInfo {
  reviewType: "NurseReview";
}

interface OutOfNetworkReviewInfo extends ReviewInfo {
  reviewType: "OutOfNetworkReview";
}

export type NurseReviewUpdate = (nurseReviewUpdate: Partial<NurseReview>) => void;
type NurseReviewStateProps =
  | [nurseReview: NurseReview, setNurseReview: NurseReviewUpdate, reviewsDispatch: React.Dispatch<ReviewsAction>]
  | [];

export type OutOfNetworkReviewUpdate = (outOfNetworkReviewUpdate: Partial<OutOfNetworkReview>) => void;
type OutOfNetworkReviewStateProps =
  | [
      outOfNetworkReview: OutOfNetworkReview,
      setOutOfNetworkReview: OutOfNetworkReviewUpdate,
      reviewsDispatch: React.Dispatch<ReviewsAction>
    ]
  | [];

interface AuditReviewInfo extends ReviewInfo {
  reviewType: "AuditReview";
}
type AuditReviewUpdate = (auditReviewUpdate: Partial<AuditReview>) => void;
type AuditReviewStateProps =
  | [auditReview: AuditReview, setAuditReview: AuditReviewUpdate, reviewsDispatch: React.Dispatch<ReviewsAction>]
  | [];

interface NewHireAuditReviewInfo extends ReviewInfo {
  reviewType: "NewHireAuditReview";
}
type NewHireAuditReviewUpdate = (newHireAuditReviewUpdate: Partial<NewHireAuditReview>) => void;
type NewHireAuditReviewStateProps =
  | [
      newHireAuditReview: NewHireAuditReview,
      setAuditReview: NewHireAuditReviewUpdate,
      reviewsDispatch: React.Dispatch<ReviewsAction>
    ]
  | [];

interface OOSAuditReviewInfo extends ReviewInfo {
  reviewType: "OutOfScopeAuditReview";
}
type OOSAuditReviewUpdate = (OOSAuditReviewUpdate: Partial<OutOfScopeAuditReview>) => void;
type OOSAuditReviewStateProps =
  | [
      OOSAuditReview: OutOfScopeAuditReview,
      setAuditReview: OOSAuditReviewUpdate,
      reviewsDispatch: React.Dispatch<ReviewsAction>
    ]
  | [];

interface FaxAuditReviewInfo extends ReviewInfo {
  reviewType: "FaxAuditReview";
}
type FaxAuditReviewUpdate = (FaxAuditReviewUpdate: Partial<FaxAuditReview>) => void;
type FaxAuditReviewStateProps =
  | [
      FaxAuditReview: FaxAuditReview,
      setAuditReview: FaxAuditReviewUpdate,
      reviewsDispatch: React.Dispatch<ReviewsAction>
    ]
  | [];

export type GenericAuditReview = AuditReview | NewHireAuditReview | OutOfScopeAuditReview | FaxAuditReview;
export type GenericAuditReviewInfo = AuditReviewInfo | NewHireAuditReviewInfo | OOSAuditReviewInfo | FaxAuditReviewInfo;
type GenericAuditReviewReturnProps<T extends GenericAuditReviewInfo> = T extends NewHireAuditReviewInfo
  ? NewHireAuditReviewStateProps
  : T extends OOSAuditReviewInfo
  ? OOSAuditReviewStateProps
  : T extends FaxAuditReviewInfo
  ? FaxAuditReviewStateProps
  : AuditReviewStateProps;
export type GenericAuditReviewUpdate<T extends GenericAuditReview> = T extends NewHireAuditReview
  ? NewHireAuditReviewUpdate
  : T extends OutOfScopeAuditReview
  ? OOSAuditReviewUpdate
  : T extends FaxAuditReview
  ? FaxAuditReviewUpdate
  : AuditReviewUpdate;

interface HmoReviewInfo extends ReviewInfo {
  reviewType: "HmoExceptionReview";
}
export type HmoReviewUpdate = (hmoReviewUpdate: Partial<HmoExceptionReview>) => void;
type HmoReviewStateProps =
  | [hmoReview: HmoExceptionReview, setHmoReview: HmoReviewUpdate, reviewsDispatch: React.Dispatch<ReviewsAction>]
  | [];

type UseReviewReturnProps =
  | MdReviewStateProps
  | NurseReviewStateProps
  | OutOfNetworkReviewStateProps
  | AuditReviewStateProps
  | P2PReviewStateProps
  | HmoReviewStateProps
  | NewHireAuditReviewStateProps
  | OOSAuditReviewStateProps
  | FaxAuditReviewStateProps;
export type ReviewUpdate =
  | MdReviewUpdate
  | NurseReviewUpdate
  | OutOfNetworkReviewUpdate
  | AuditReviewUpdate
  | HmoReviewUpdate
  | P2PReviewUpdate
  | NewHireAuditReviewUpdate
  | OOSAuditReviewUpdate
  | FaxAuditReviewUpdate;
function useReview(mdReviewInfo: MdReviewInfo): MdReviewStateProps;
function useReview(nurseReviewInfo: NurseReviewInfo): NurseReviewStateProps;
function useReview(outOfNetworkReviewInfo: OutOfNetworkReviewInfo): OutOfNetworkReviewStateProps;
function useReview(auditReviewInfo: AuditReviewInfo): AuditReviewStateProps;
function useReview(p2pReviewInfo: P2PReviewInfo): P2PReviewStateProps;
function useReview(hmoReviewInfo: HmoReviewInfo): HmoReviewStateProps;
function useReview(newHireAuditReviewInfo: NewHireAuditReviewInfo): NewHireAuditReviewStateProps;
function useReview<T extends GenericDoctorReviewInfo>(genericDoctorReview: T): GenericDoctorReturnProps<T>;
function useReview<T extends GenericAuditReviewInfo>(genericAuditReview: T): GenericAuditReviewReturnProps<T>;
function useReview({ reviewId, reviewType }: ReviewInfo): UseReviewReturnProps;
function useReview({ reviewId, reviewType }: ReviewInfo): UseReviewReturnProps | [] {
  const allReviewsState = useReviewsState();
  const reviewsDispatch = useReviewsDispatch();

  const requestedReview = useMemo(
    () => allReviewsState?.find((review) => review.id === reviewId && review.reviewType === reviewType),
    [reviewId, reviewType, allReviewsState]
  );

  const setMdReview = useCallback(
    (mdReviewUpdate: Partial<MdReview>) => {
      reviewsDispatch?.({ type: "SET_REVIEW", reviewType: "MdReview", reviewId, payload: mdReviewUpdate });
    },
    [reviewId, reviewsDispatch]
  );

  const setNurseReview = useCallback(
    (nurseReviewUpdate: Partial<NurseReview>) => {
      reviewsDispatch?.({ type: "SET_REVIEW", reviewType: "NurseReview", reviewId, payload: nurseReviewUpdate });
    },
    [reviewId, reviewsDispatch]
  );

  const setOutOfNetworkReview = useCallback(
    (outOfNetworkReviewUpdate: Partial<OutOfNetworkReview>) => {
      reviewsDispatch?.({
        type: "SET_REVIEW",
        reviewType: "OutOfNetworkReview",
        reviewId,
        payload: outOfNetworkReviewUpdate,
      });
    },
    [reviewId, reviewsDispatch]
  );

  const setAuditReview = useCallback(
    (auditReviewUpdate: Partial<AuditReview>) => {
      reviewsDispatch?.({ type: "SET_REVIEW", reviewType: "AuditReview", reviewId, payload: auditReviewUpdate });
    },
    [reviewId, reviewsDispatch]
  );

  const setNewHireAuditReview = useCallback(
    (auditReviewUpdate: Partial<NewHireAuditReview>) => {
      reviewsDispatch?.({ type: "SET_REVIEW", reviewType: "NewHireAuditReview", reviewId, payload: auditReviewUpdate });
    },
    [reviewId, reviewsDispatch]
  );

  const setP2PReview = useCallback(
    (p2pReviewUpdate: Partial<PeerToPeerReview>) => {
      reviewsDispatch?.({ type: "SET_REVIEW", reviewType: "PeerToPeerReview", reviewId, payload: p2pReviewUpdate });
    },
    [reviewId, reviewsDispatch]
  );

  const setOOSAuditReview = useCallback(
    (auditReviewUpdate: Partial<OutOfScopeAuditReview>) => {
      reviewsDispatch?.({
        type: "SET_REVIEW",
        reviewType: "OutOfScopeAuditReview",
        reviewId,
        payload: auditReviewUpdate,
      });
    },
    [reviewId, reviewsDispatch]
  );

  const setFaxAuditReview = useCallback(
    (auditReviewUpdate: Partial<FaxAuditReview>) => {
      reviewsDispatch?.({
        type: "SET_REVIEW",
        reviewType: "FaxAuditReview",
        reviewId,
        payload: auditReviewUpdate,
      });
    },
    [reviewId, reviewsDispatch]
  );

  const setHmoReview = useCallback(
    (hmoReviewUpdate: Partial<HmoExceptionReview>) => {
      reviewsDispatch?.({
        type: "SET_REVIEW",
        reviewType: "HmoExceptionReview",
        reviewId: reviewId,
        payload: hmoReviewUpdate,
      });
    },
    [reviewId, reviewsDispatch]
  );

  if (!allReviewsState || !reviewsDispatch) {
    return [];
  }
  if (!requestedReview) {
    return [];
  }

  switch (requestedReview.reviewType) {
    case "MdReview":
      return [requestedReview, setMdReview, reviewsDispatch];
    case "NurseReview":
      return [requestedReview, setNurseReview, reviewsDispatch];
    case "OutOfNetworkReview":
      return [requestedReview, setOutOfNetworkReview, reviewsDispatch];
    case "AuditReview":
      return [requestedReview, setAuditReview, reviewsDispatch];
    case "PeerToPeerReview":
      return [requestedReview, setP2PReview, reviewsDispatch];
    case "HmoExceptionReview":
      return [requestedReview, setHmoReview, reviewsDispatch];
    case "NewHireAuditReview":
      return [requestedReview, setNewHireAuditReview, reviewsDispatch];
    case "OutOfScopeAuditReview":
      return [requestedReview, setOOSAuditReview, reviewsDispatch];
    case "FaxAuditReview":
      return [requestedReview, setFaxAuditReview, reviewsDispatch];
  }
}

const useMdReview = ({ mdReviewId }: UseMdReviewProps): MdReviewStateProps => {
  return useReview({ reviewId: mdReviewId, reviewType: "MdReview" });
};

const useNurseReview = ({ nurseReviewId }: UseNurseReviewProps): NurseReviewStateProps => {
  return useReview({ reviewId: nurseReviewId, reviewType: "NurseReview" });
};

const useOutOfNetworkReview = ({ outOfNetworkReviewId }: UseOutOfNetworkReviewProps): OutOfNetworkReviewStateProps => {
  return useReview({ reviewId: outOfNetworkReviewId, reviewType: "OutOfNetworkReview" });
};

const useAuditReview = ({ auditReviewId }: UseAuditReviewProps): AuditReviewStateProps => {
  return useReview({ reviewId: auditReviewId, reviewType: "AuditReview" });
};

const useP2PReview = ({ p2pReviewId }: UseP2PReviewProps): P2PReviewStateProps => {
  return useReview({ reviewId: p2pReviewId, reviewType: "PeerToPeerReview" });
};

const useHmoReview = ({ hmoReviewId }: UseHmoReviewProps): HmoReviewStateProps => {
  return useReview({ reviewId: hmoReviewId, reviewType: "HmoExceptionReview" });
};

export {
  ReviewsProvider,
  useMdReview,
  useReview,
  useReviewsDispatch,
  useNurseReview,
  useOutOfNetworkReview,
  useAuditReview,
  useHmoReview,
  useP2PReview,
};
