import { H2, H5 } from "@coherehealth/common";
import {
  KafkaMessageDocumentResponse,
  ServiceRequestResponse,
  useSearchKafkaIntegration,
  useGetTatNotificationByServiceRequest,
  TATNotification,
} from "@coherehealth/core-platform-api";
import { CircularProgress, Grid } from "@material-ui/core";
import { useAuthorized } from "authorization";
import { useEffect, useState } from "react";
import AuthDecisionGroupSummary from "./AuthDecisionGroupSummary";
import AuthNumber from "./AuthNumber";
import EditIntegrationStatus from "./EditIntegrationStatus";
import IntegrationStatusSummary from "./IntegrationStatusSummary";

interface Props {
  serviceRequest: ServiceRequestResponse;
  onEdit: () => void;
}

export default function ServiceRequestIntegrationSummary({ serviceRequest, onEdit }: Props) {
  /*
    TODO: once we implement more Kafka integrations with retries, we'll want to think of a better pattern.
    Probably want an API that fetches all Kafka message statuses for one SR. For now to support the mail
    vendor integration we will just explicitly query for this integration status with the existing message
    query API.
  */
  const [mailVendorKafkaLogs, setMailVendorKafkaLogs] = useState<KafkaMessageDocumentResponse[]>();
  const [tatNotification, setTatNotification] = useState<TATNotification>();
  const { mutate: fetchKafkaIntegrations, loading: fetchKafkaIntegrationsLoading } = useSearchKafkaIntegration({});
  const { refetch: fetchTatNotification } = useGetTatNotificationByServiceRequest({
    serviceRequestId: serviceRequest.id,
    lazy: true,
  });

  const canViewIntegrationDetails = useAuthorized("VIEW_SERVICE_REQUEST_INTEGRATION");

  useEffect(() => {
    if (!mailVendorKafkaLogs && serviceRequest?.id && canViewIntegrationDetails) {
      fetchKafkaIntegrations({
        // is there a better way to query for logs that relate to this SR? Might need some investigation
        query: serviceRequest.id,
        integrationType: "mail-vendor-consumer",
        max: 10,
      }).then((response) => setMailVendorKafkaLogs(response));
    }
  }, [fetchKafkaIntegrations, mailVendorKafkaLogs, serviceRequest, canViewIntegrationDetails]);

  useEffect(() => {
    const fetchTatIntegration = async () => {
      if (canViewIntegrationDetails) {
        const tatData = await fetchTatNotification();
        tatData && setTatNotification(tatData);
      }
    };
    fetchTatIntegration();
  }, [canViewIntegrationDetails, fetchTatNotification]);

  if (fetchKafkaIntegrationsLoading && !mailVendorKafkaLogs) {
    return (
      <Grid container spacing={3} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <H2 data-public>Integration data loading...</H2>
        </Grid>
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <H5 data-public>Integrations</H5>
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <AuthNumber serviceRequest={serviceRequest} onEdit={onEdit} />
      </Grid>
      {canViewIntegrationDetails && (
        <>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <IntegrationStatusSummary
              serviceRequest={serviceRequest}
              mailVendorKafkaLogs={mailVendorKafkaLogs}
              tatNotification={tatNotification}
            />
          </Grid>
          <Grid item xs={12}>
            <EditIntegrationStatus
              serviceRequest={serviceRequest}
              onEdit={onEdit}
              mailVendorKafkaLogs={mailVendorKafkaLogs}
              tatNotification={tatNotification}
              setTatNotification={setTatNotification}
            />
          </Grid>
          {serviceRequest?.authDecisionGroup && (
            <Grid item xs={12}>
              <AuthDecisionGroupSummary serviceRequest={serviceRequest} />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
