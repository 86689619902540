import { useState, useEffect } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import PatientInformation from "./PatientInformation";
import ServiceRequestInformation from "./ServiceRequestInformation";
import ClinicalAssessmentInformation from "./ClinicalAssessmentInformation";
import ReviewInformation from "./ReviewInformation";
import NudgeInformation from "./NudgeInformation";
import OtherServiceRequests from "./OtherServiceRequests/OtherServiceRequests";
import CWQIEpisode from "./CWQIEpisode";
import {
  MdReview,
  OutreachOpportunity,
  Patient,
  PeerToPeerReview,
  ServiceRequestResponse,
  ReviewType,
  AuthorizationResponse,
  NurseReview,
  Note,
} from "@coherehealth/core-platform-api";
import CaseOverview from "./CaseOverview";
import { Dispatch, SetStateAction } from "react";
import { UserClickInfoClinicalReviewPage, useConfiguration, useFeature } from "@coherehealth/common";
import { useAuthorized } from "authorization";
import AuthorizationReviewInformation from "./AuthorizationReviewInformation";
import NotesCard from "./NotesCard/NotesCard";
import { UseOtherAuthorizationProps } from "./OtherServiceRequests/useOtherAuthorization";
import ReviewHistoryInformation from "./ReviewHistoryCard/ReviewHistoryInformation";
import { HistoryAppeal, HistoryData, HistoryNote, HistoryOutreach, HistoryReview } from "./NotesCard/notesCardTypes";
import { LeftPanelTabs } from "../reviewUtils/utils";

interface Props extends UseOtherAuthorizationProps {
  authorization?: AuthorizationResponse;
  refreshAuthorization?: () => Promise<void>;
  authorizationFetchLoading?: boolean;
  patient: Patient | null;
  patientLoading: boolean;
  serviceRequest: ServiceRequestResponse | null;
  serviceRequestLoading: boolean;
  existingReviews: ReviewType[] | null;
  outreachOpportunities: OutreachOpportunity[] | null;
  otherServiceRequests: ServiceRequestResponse[] | null;
  otherServiceRequestsLoading: boolean;
  crrDenials?: boolean;
  review?: MdReview | PeerToPeerReview | NurseReview | null;
  serviceRequestId: string;
  newDenialsWorkflow?: boolean;
  userClickInfoTracking?: UserClickInfoClinicalReviewPage;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  serviceRequestRefetch: () => Promise<ServiceRequestResponse | null>;
  viewOnly?: boolean;
  notes?: Note[];
  notesLoading?: boolean;
  canViewGenericNotes?: boolean;
  id: string;
  isDenialReviewPage?: boolean;
  currServiceRequestID?: string;
  faxAudit?: boolean;
  setTabOpen?: React.Dispatch<React.SetStateAction<LeftPanelTabs>>;
}

const ClinicalReviewInfoPanel = (props: Props) => {
  const {
    authorization,
    refreshAuthorization,
    authorizationFetchLoading,
    review,
    patient,
    patientLoading,
    serviceRequest,
    serviceRequestLoading,
    existingReviews,
    outreachOpportunities,
    otherServiceRequests,
    otherServiceRequestsLoading,
    otherAuthsProps,
    crrDenials,
    serviceRequestId,
    newDenialsWorkflow,
    userClickInfoTracking,
    setUserClickInfoTracking,
    serviceRequestRefetch,
    viewOnly,
    notes,
    notesLoading,
    canViewGenericNotes,
    id,
    isDenialReviewPage = false,
    currServiceRequestID,
    faxAudit,
    setTabOpen,
  } = props;

  const [allHistoryData, setAllHistoryData] = useState<(HistoryData | undefined)[]>([]);

  const reviewTimelineCardFeature = useFeature("reviewTimelineCard");

  const authorizationId = serviceRequest?.authorization?.id;

  const facilityBasedRequestConfiguration = useConfiguration(
    "facilityBasedRequestConfiguration",
    serviceRequest?.healthPlanName
  );

  const noteConfig = useConfiguration("noteConfiguration", serviceRequest?.healthPlanName);

  const canEditOutOfNetworkReview = useAuthorized("EDIT_OUT_OF_NETWORK_REVIEW");

  useEffect(() => {
    if (reviewTimelineCardFeature === false) {
      return;
    }
    let allServiceRequestsOnAuth = authorization?.serviceRequestsOnAuth;
    let serviceRequestMap: Map<string, ServiceRequestResponse> = new Map<string, ServiceRequestResponse>();
    allServiceRequestsOnAuth?.forEach((sr) => {
      serviceRequestMap.set(sr.id, sr);
    });

    let allReviewsOnAuth = authorization?.reviews;
    let nonDraftReviewsOnAuth = allReviewsOnAuth?.filter((review) => {
      return review.reviewStatus !== "DRAFT";
    });
    const reviewsOnAuthFiltered = nonDraftReviewsOnAuth?.filter(
      (review) => canEditOutOfNetworkReview || review.reviewType !== "OutOfNetworkReview"
    );
    let existingClaimsPaymentNotes = reviewsOnAuthFiltered?.filter(
      (r) => r.claimsPaymentNote && r.reviewStatus === "COMPLETE"
    );
    const claimsPaymentNoteData: HistoryNote[] | undefined = existingClaimsPaymentNotes?.map((e) => {
      const srId = e?.serviceRequest?.id;
      return {
        id: `${e.id}_claims`,
        dateCreated: e.dateCompleted,
        healthPlanName: e.healthPlanName,
        lastUpdated: e.lastUpdated,
        createdByName: e.completedByName,
        claimsPaymentNote: e.claimsPaymentNote,
        serviceRequest: srId ? serviceRequestMap.get(srId) || { id: "null" } : { id: "null" },
        noteType: "CLAIMS_PAYMENT",
        objType: "NOTES",
        srDateCreated: srId ? serviceRequestMap.get(srId)?.dateCreated || new Date() : new Date(),
      };
    });

    let allOutreachAttemptsOnAuth = authorization?.outreachAttempts;
    let allNotesOnAuth = authorization?.notes;
    const newReviews: HistoryReview[] =
      reviewsOnAuthFiltered?.map((review) => {
        const srId = review?.serviceRequest?.id;
        return {
          ...review,
          objType: "REVIEW",
          serviceRequestId: review?.serviceRequest?.id,
          srDateCreated: srId ? serviceRequestMap.get(srId)?.dateCreated || new Date() : new Date(),
        };
      }) || [];
    const newOutreachAttempts: HistoryOutreach[] =
      allOutreachAttemptsOnAuth?.map((outReachAttempt) => {
        const srId = outReachAttempt?.serviceRequestId;
        return {
          ...outReachAttempt,
          objType: "CONTACT",
          serviceRequestId: outReachAttempt.serviceRequestId,
          srDateCreated: srId ? serviceRequestMap.get(srId)?.dateCreated || new Date() : new Date(),
        };
      }) || [];
    const postAppealNotes: HistoryAppeal[] | undefined = authorization?.appeals?.map((appeal) => ({
      ...appeal,
      objType: "APPEAL",
      serviceRequestId: appeal.serviceRequest.id,
    }));

    let newNotes: Partial<HistoryNote[] | undefined> = allNotesOnAuth?.map((note) => ({
      ...note,
      objType: "NOTES",
      serviceRequestId: note.serviceRequest.id,
      srDateCreated: note.serviceRequest.id
        ? serviceRequestMap.get(note.serviceRequest.id)?.dateCreated || new Date()
        : new Date(),
    }));
    if (claimsPaymentNoteData && claimsPaymentNoteData.length > 0) {
      newNotes?.push(...claimsPaymentNoteData);
    }
    let allHistoryDataArr: Array<HistoryData | undefined> = [];

    if (newReviews) {
      newReviews.forEach((review) => allHistoryDataArr.push(review));
    }
    if (postAppealNotes) {
      postAppealNotes?.forEach((appeal) => allHistoryDataArr.push(appeal));
    }
    if (newNotes) {
      newNotes.forEach((note) => allHistoryDataArr.push(note));
    }
    if (newOutreachAttempts) {
      newOutreachAttempts.forEach((outReachAttempt) => allHistoryDataArr.push(outReachAttempt));
    }
    setAllHistoryData(allHistoryDataArr);
  }, [authorization, canEditOutOfNetworkReview, reviewTimelineCardFeature]);

  const isInpatient = serviceRequest?.encounterType === "INPATIENT";

  return (
    <StyledDiv id={id} data-testid={id}>
      {crrDenials && review && !faxAudit && (
        <CardContainer data-testid="card-case-overview">
          <CaseOverview review={review} serviceRequest={serviceRequest} loadingSRData={serviceRequestLoading} />
        </CardContainer>
      )}

      {isDenialReviewPage &&
        !faxAudit &&
        (facilityBasedRequestConfiguration?.enabled && isInpatient ? (
          <CardContainer data-testid="card-authorization-review-information">
            <AuthorizationReviewInformation
              currServiceRequestID={currServiceRequestID}
              authorization={authorization}
              existingReviews={existingReviews}
              setUserClickInfoTracking={setUserClickInfoTracking}
              isDenialReviewPage={isDenialReviewPage}
            />
          </CardContainer>
        ) : (
          <CardContainer data-testid="card-review-information">
            <ReviewInformation
              serviceRequest={serviceRequest}
              currServiceRequestID={currServiceRequestID}
              existingReviews={existingReviews}
              setUserClickInfoTracking={setUserClickInfoTracking}
              newDenialsWorkflow={newDenialsWorkflow}
              isDenialReviewPage={isDenialReviewPage}
            />
          </CardContainer>
        ))}

      {isDenialReviewPage && !faxAudit && (
        <CardContainer data-testid="card-cwqi-episode">
          <CWQIEpisode
            episodeSummaryId={authorizationId || serviceRequest?.id}
            objectType={authorizationId ? "Authorization" : "ServiceRequest"}
            setUserClickInfoTracking={setUserClickInfoTracking}
          />
        </CardContainer>
      )}
      {(!viewOnly || faxAudit) && (
        <CardContainer data-testid="card-patient-information">
          <PatientInformation
            patient={patient}
            loadingPatientData={patientLoading}
            userClickInfoTracking={userClickInfoTracking}
            setUserClickInfoTracking={setUserClickInfoTracking}
            crrDenials={crrDenials}
            startDate={
              serviceRequest?.encounterType === "INPATIENT" ? authorization?.startDate : serviceRequest?.startDate
            }
            serviceRequestId={serviceRequestId}
          />
        </CardContainer>
      )}
      <CardContainer data-testid="card-service-request-information">
        {serviceRequest && (
          <ServiceRequestInformation
            authorization={authorization}
            refreshAuthorization={refreshAuthorization}
            serviceRequest={serviceRequest}
            loadingSRData={serviceRequestLoading}
            userClickInfoTracking={userClickInfoTracking}
            setUserClickInfoTracking={setUserClickInfoTracking}
            serviceRequestRefetch={serviceRequestRefetch}
            isDenialReviewPage={isDenialReviewPage}
          />
        )}
      </CardContainer>
      {!faxAudit && (
        <CardContainer data-testid="card-other-service-requests">
          <OtherServiceRequests
            otherServiceRequests={otherServiceRequests}
            otherServiceRequestsLoading={otherServiceRequestsLoading}
            setUserClickInfoTracking={setUserClickInfoTracking}
            viewOnly={viewOnly}
            crrDenials={crrDenials}
            otherAuthsProps={otherAuthsProps}
          />
        </CardContainer>
      )}
      {viewOnly && !faxAudit && (
        <CardContainer data-testid="card-patient-information">
          <PatientInformation
            patient={patient}
            loadingPatientData={patientLoading}
            userClickInfoTracking={userClickInfoTracking}
            setUserClickInfoTracking={setUserClickInfoTracking}
            crrDenials={crrDenials}
            startDate={serviceRequest?.startDate}
            serviceRequestId={serviceRequestId}
          />
        </CardContainer>
      )}
      {!faxAudit && (
        <CardContainer data-testid="card-clinical-assessment-information">
          <ClinicalAssessmentInformation
            serviceRequest={serviceRequest}
            serviceRequestId={serviceRequestId}
            userClickInfoTracking={userClickInfoTracking}
            setUserClickInfoTracking={setUserClickInfoTracking}
          />
        </CardContainer>
      )}
      {reviewTimelineCardFeature && !faxAudit && (
        <CardContainer data-testid="card-review-history-information">
          <ReviewHistoryInformation
            allHistoryData={allHistoryData}
            loading={authorizationFetchLoading || false}
            noteConfig={noteConfig}
            hasContinuations={
              authorization?.serviceRequestsOnAuth ? authorization?.serviceRequestsOnAuth?.length > 1 : false
            }
            authorization={authorization}
            setTabOpen={setTabOpen}
          />
        </CardContainer>
      )}
      {!faxAudit &&
        !isDenialReviewPage &&
        (facilityBasedRequestConfiguration?.enabled && isInpatient ? (
          <CardContainer data-testid="card-authorization-review-information">
            <AuthorizationReviewInformation
              authorization={authorization}
              existingReviews={existingReviews}
              setUserClickInfoTracking={setUserClickInfoTracking}
            />
          </CardContainer>
        ) : (
          <CardContainer data-testid="card-review-information">
            <ReviewInformation
              serviceRequest={serviceRequest}
              existingReviews={existingReviews}
              setUserClickInfoTracking={setUserClickInfoTracking}
              newDenialsWorkflow={newDenialsWorkflow}
            />
          </CardContainer>
        ))}
      {!isDenialReviewPage && !faxAudit && (
        <CardContainer data-testid="card-cwqi-episode">
          <CWQIEpisode
            episodeSummaryId={authorizationId || serviceRequest?.id}
            objectType={authorizationId ? "Authorization" : "ServiceRequest"}
            setUserClickInfoTracking={setUserClickInfoTracking}
          />
        </CardContainer>
      )}
      {!faxAudit && (
        <CardContainer data-testid="card-nudge-information">
          <NudgeInformation
            serviceRequest={serviceRequest}
            outreachOpportunities={outreachOpportunities}
            setUserClickInfoTracking={setUserClickInfoTracking}
          />
        </CardContainer>
      )}
      {canViewGenericNotes && !faxAudit && (
        <CardContainer data-testid="card-notes">
          <NotesCard
            notes={notes || []}
            notesLoading={notesLoading || false}
            setUserClickInfoTracking={setUserClickInfoTracking}
            serviceRequest={serviceRequest}
            view="NOTES"
            existingReviews={existingReviews}
            noteConfig={noteConfig}
          />
        </CardContainer>
      )}
    </StyledDiv>
  );
};

export default ClinicalReviewInfoPanel;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledDiv = styled("div")({
  width: "100%",
  overflowY: "auto",
  position: "relative",
  overflowX: "hidden",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const CardContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(1.5, 0, 1.5),
}));
