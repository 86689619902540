import { DependencyList, useState } from "react";
import useDebounce from "react-use/lib/useDebounce";

interface Props {
  validation: () => boolean;
  delay?: number;
  deps: DependencyList;
}

/**
 * Sometimes we want to show a validation error, but we should at least let the user finish typing before
 * yelling at them. However, once they fix the validation error we should say it's fixed right away.
 *
 * This does that.
 */
export function useDebouncedValidation({ validation, delay = 1000, deps }: Props): boolean {
  const [isValidDebounced, setIsValidDebounced] = useState(false);
  useDebounce(() => setIsValidDebounced(validation()), delay, deps);
  return validation() || isValidDebounced;
}
