import React, { useMemo } from "react";
import {
  Card,
  Body1,
  TextField,
  DenialLetterHeader,
  DenialLetterBody,
  DenialLetterProcedureCodePopulate,
  useFeature,
  parseMarkup,
  ParsedBlock,
  H5,
  SingleSelectDropdown,
  InlineButton,
  stripHTMl,
  parseDateFromISOString,
  reduceToSimplePreferredLabelUpdate,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
import {
  MdReview,
  PeerToPeerReview,
  ServiceRequestResponse,
  DenialsInfo,
  useGetDenialsTemplatesForReview,
  useGetFinalDeterminationLetter,
  Guideline,
  NurseReview,
  AuthorizationResponse,
  usePostMdNoteFeedback,
  PreferredLabelFeedback,
  usePatchReviewDeterminationUpdates,
  PatchReviewDeterminationUpdatesRequestBody,
  EditedPreferredLabel,
  ContinuationConfigurationResponse,
} from "@coherehealth/core-platform-api";
import {
  ReviewControlBoxInnerContainer,
  ReviewControlBoxOuterContainer,
} from "components/ServiceRequest/ReviewSection/MDReview/MDReviewEdit";
import { useSearchServiceCase, useCompleteServiceCase } from "@coherehealth/qm-api";
import config from "api/config";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { useTheme, useMediaQuery, Grid, styled, CircularProgress } from "@material-ui/core";
import { useAuthorized } from "authorization";
import DenialPreviewModal from "common/DenialPreviewModal";
import DenialSubmissionModal from "common/DenialSubmissionModal";
import ReviewErrorModal from "common/ReviewErrorModal";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState, useEffect, useCallback } from "react";
import { isTerminalStatus } from "util/serviceRequest";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import DenialLetterControlPanel from "./DenialLetterControlPanel";
import PencilIcon from "@material-ui/icons/Create";
import { RIGHT_HAND_PANEL_SIZE } from "components/ClinicalReview/reviewUtils/utils";
import DetailedHardRedirectClinicalReviewModal, {
  ClinicalReviewErrorDetails,
} from "../Modals/DetailedHardRedirectClinicalReviewModal";
import { defaultClinicalReviewErrorState, extractErrorDetails } from "../util/ReviewSectionUtil";
import { useGetAutomatedDenialLetterConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { redirectToPatientSummaryFromReview } from "util/routeUtils/routeUtils";

interface DenialLetterContentProps {
  serviceRequest: ServiceRequestResponse | null;
  review: MdReview | PeerToPeerReview | NurseReview | null;
  prePopulateDenialsTemplate: boolean;
  setSubmissionError: Dispatch<SetStateAction<string | undefined>>;
  setDenialSentModalOpen: Dispatch<SetStateAction<boolean>>;
  setFinalizeDenial: Dispatch<SetStateAction<boolean>>;
  setDualReviewErrorModalOpen: Dispatch<SetStateAction<boolean>>;
  crrView?: boolean;
  incorrectReviewForDenialLetter?: boolean;
  setIncorrectReviewForDenialLetterModal?: Dispatch<SetStateAction<boolean>>;
  disableDueToWrongReview?: boolean;
  setDisableDueToWrongReview?: Dispatch<SetStateAction<boolean>>;
  continuationConfiguration?: ContinuationConfigurationResponse | null;
}

export type DenialLetterView = "LETTER_WRITE" | "LETTER_EDIT" | "READ_ONLY";

export default function DenialLetterContent({
  serviceRequest,
  review,
  prePopulateDenialsTemplate,
  setSubmissionError,
  setDenialSentModalOpen,
  setFinalizeDenial,
  setDualReviewErrorModalOpen,
  crrView,
  incorrectReviewForDenialLetter,
  setIncorrectReviewForDenialLetterModal,
  disableDueToWrongReview,
  setDisableDueToWrongReview,
  continuationConfiguration,
}: DenialLetterContentProps) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const matchesRight = useMediaQuery(theme.breakpoints.up(RIGHT_HAND_PANEL_SIZE));
  const translatedMdNoteEnabled = useFeature("translatedMdNote");
  const navigate = useNavigate();
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const urlCaseId = search.get("caseId");

  const [caseId, setCaseId] = useState(urlCaseId);

  const [denialLetterView, setDenialLetterView] = useState<DenialLetterView>("READ_ONLY");
  const [parsedMarkupText, setParsedMarkupText] = useState<ParsedBlock[]>();
  const [editedMarkupText, setEditedMarkupText] = useState<string>("");
  const [denialLanguage, setDenialLanguage] = useState<string>("");
  const [isBlob, setIsBlob] = useState<boolean>(false);
  const [canSubmitForReview, setCanSubmitForReview] = useState<boolean>(false);
  const [hasMadeChanges, setHasMadeChanges] = useState<boolean>(false);
  const [finalizeDenialError, setFinalizeDenialError] = useState<boolean>(false);
  const [denialSubmissionModalOpen, setDenialSubmissionModalOpen] = useState<boolean>(false);
  const [errorDetails, setErrorDetails] = useState<ClinicalReviewErrorDetails>(
    defaultClinicalReviewErrorState(serviceRequest?.id || "", review?.id || "")
  );
  const [openHardRedirectOnSubmitModal, setOpenHardRedirectOnSubmitModal] = useState(false);
  const [editsMadeModalOpen, setEditsMadeModalOpen] = useState<boolean>(false);
  const [restoreTemplateOpen, setRestoreTemplateOpen] = useState<boolean>(false);
  const [denialPreviewModalOpen, setDenialPreviewModalOpen] = useState<boolean>(false);
  const [newDenialsTemplate, setNewDenialsTemplate] = useState<DenialsInfo | null | undefined>();
  const [started, setStarted] = useState<boolean>(false);
  const [selectTemplate, setSelectTemplate] = useState<boolean>(true);
  const [doctorOrNurseReview, setDoctorOrNurseReview] = useState<MdReview | PeerToPeerReview | NurseReview | null>(
    review
  );
  const [mdNote, setMdNote] = useState<string>("");

  const {
    data: serviceRequestOnClick,
    loading: serviceRequestLoadingOnClick,
    refetch: getServiceRequestOnClick,
    error: getServiceRequestError,
  } = useGetServiceRequestByIdWithFallback({
    id: serviceRequest?.id || "",
    lazy: true,
  });

  const {
    loading: patchReviewDeterminationUpdatesLoading,
    error: patchReviewDeterminationUpdatesError,
    mutate: patchReviewDeterminationUpdates,
  } = usePatchReviewDeterminationUpdates({
    reviewId: doctorOrNurseReview?.id ?? "",
  });

  const { loading: postMdNoteFeedbackLoading, mutate: postMdNoteFeedback } = usePostMdNoteFeedback({});

  const {
    data: denialsTemplates,
    loading: denialsLoading,
    refetch: denialsRefetch,
    error: getDenialsError,
  } = useGetDenialsTemplatesForReview({
    lazy: true,
  });

  const { mutate: updateServiceCase } = useCompleteServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
  });

  const { mutate: SearchServiceCase } = useSearchServiceCase({
    base: `${config.QM_SERVICE_API_URL}`,
  });

  const { refetch: refetchDeterminationLetter, error: determinationLetterError } = useGetFinalDeterminationLetter({
    lazy: true,
    queryParams: {
      serviceRequestId: serviceRequest?.id || "",
      reviewId: review?.id || "",
      reviewOutcome: review?.reviewOutcome || "",
    },
  });

  const [denialLetterPreviewResponse, setDenialLetterPreviewResponse] = useState<Response>();

  const canWriteReview = useAuthorized("EDIT_MD_REVIEW");
  const canEdit = useAuthorized("EDIT_FINALIZED_MD_REVIEW") && doctorOrNurseReview?.reviewStatus !== "DISCARDED";
  const hasDenialBeenStarted = doctorOrNurseReview?.reviewStatus === "COMPLETE";
  const hasDenialBeenSent = serviceRequest && isTerminalStatus(serviceRequest);
  const renderAsBlob = isBlob;
  const reviewSubmitLoading = postMdNoteFeedbackLoading || patchReviewDeterminationUpdatesLoading;
  const showDenialTemplatesRelatedToHealthPlanFeatureFlag = useFeature("showDenialTemplatesRelatedToHealthPlan");
  const hasMultipleTemplates = (denialsTemplates?.length || 0) > 1;

  const handleSelectTemplate = useCallback(async () => {
    if (newDenialsTemplate && selectTemplate) {
      const res = (await patchReviewDeterminationUpdates({
        denialsTemplate: newDenialsTemplate,
      })) as MdReview | PeerToPeerReview | NurseReview | null;
      setDoctorOrNurseReview(res);
      setStarted(true);
      setSelectTemplate(false);
      setNewDenialsTemplate(undefined);
    }
  }, [newDenialsTemplate, selectTemplate, patchReviewDeterminationUpdates]);

  const handleCancel = useCallback(async () => {
    if (doctorOrNurseReview?.denialsTemplate) {
      setSelectTemplate(false);
      setNewDenialsTemplate(undefined);
    } else {
      redirectToPatientSummaryFromReview({ serviceRequest, reviewId: doctorOrNurseReview?.id, navigate });
    }
  }, [doctorOrNurseReview?.denialsTemplate, doctorOrNurseReview?.id, navigate, serviceRequest]);

  const getAuthStatusFromReviewOutcome = (reviewOutcome: string) => {
    if (reviewOutcome === "RECOMMENDED_DENIAL" || reviewOutcome === "ADMINISTRATIVE_DENIAL") {
      return "RECOMMENDED_DENIAL";
    } else if (
      reviewOutcome === "RECOMMENDED_PARTIAL_APPROVAL" ||
      reviewOutcome === "ADMINISTRATIVE_PARTIAL_APPROVAL"
    ) {
      return "RECOMMENDED_PARTIAL_APPROVAL";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (
      !started &&
      (review?.reviewOutcome === "RECOMMENDED_DENIAL" ||
        review?.reviewOutcome === "RECOMMENDED_PARTIAL_APPROVAL" ||
        review?.reviewOutcome === "ADMINISTRATIVE_DENIAL" ||
        review?.reviewOutcome === "ADMINISTRATIVE_PARTIAL_APPROVAL")
    ) {
      const filteredGuidelines = review?.usedGuidelines?.map(
        (guideline: Guideline) => guideline.originalVersionId || ""
      );
      const filteredGuidelinesPresent = filteredGuidelines && filteredGuidelines?.length > 0;
      denialsRefetch({
        queryParams: {
          authStatus: getAuthStatusFromReviewOutcome(review?.reviewOutcome),
          usedGuidelineIds: filteredGuidelines || [],
          healthPlan: showDenialTemplatesRelatedToHealthPlanFeatureFlag
            ? serviceRequest?.healthPlanName?.toUpperCase()
            : "",
          lineOfBusiness:
            showDenialTemplatesRelatedToHealthPlanFeatureFlag && !filteredGuidelinesPresent
              ? serviceRequest?.lobType
              : "",
          state:
            showDenialTemplatesRelatedToHealthPlanFeatureFlag && !filteredGuidelinesPresent
              ? serviceRequest?.patient?.address?.state
              : "",
        },
      }).then(async (templates) => {
        if (!doctorOrNurseReview?.denialsTemplate) {
          if (templates?.length === 1) {
            setNewDenialsTemplate({
              denialsTemplateId: templates[0]?.id || "",
              formattedDenialsLanguage: templates[0]?.denialLanguage || "",
              templateTitle: templates[0]?.templateTitle || "",
            });
          } else if (!templates || templates.length === 0) {
            setStarted(true);
            setSelectTemplate(false);
          }
        }
      });
    }
  }, [
    denialsRefetch,
    doctorOrNurseReview?.denialsTemplate,
    review?.reviewOutcome,
    review?.usedGuidelines,
    serviceRequest?.healthPlanName,
    serviceRequest?.lobType,
    serviceRequest?.patient?.address?.state,
    showDenialTemplatesRelatedToHealthPlanFeatureFlag,
    started,
  ]);

  /**
   * If current review is in DRAFT denialStatus should already have denialsTemplate.
   * This useEffect make sure's that we prefilling the already selected denialsTemplate on the denial review screen.
   */
  useEffect(() => {
    if (!doctorOrNurseReview?.denialsTemplate) {
      if (denialsTemplates?.length === 1 && newDenialsTemplate) {
        handleSelectTemplate();
      }
    }
  }, [doctorOrNurseReview?.denialsTemplate, denialsTemplates, handleSelectTemplate, newDenialsTemplate]);

  useEffect(() => {
    if (getDenialsError) {
      enqueueSnackbar(`Error Fetching Denial Templates: ${getDenialsError.message}`, {
        variant: "error",
        preventDuplicate: true,
      });
    }
    if (getServiceRequestError) {
      enqueueSnackbar("Error fetching latest Service Request", { variant: "error", preventDuplicate: true });
    }
  }, [getDenialsError, getServiceRequestError, enqueueSnackbar]);

  useEffect(() => {
    if (!denialPreviewModalOpen) {
      if (
        patchReviewDeterminationUpdatesError &&
        (!(patchReviewDeterminationUpdatesError.data as any)?.error?.message ||
          (patchReviewDeterminationUpdatesError.data as any).error.message !==
            "The instance was updated by another user while you were editing")
      ) {
        enqueueSnackbar(`Error updating review: ${patchReviewDeterminationUpdatesError.message}`, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    }
  }, [denialPreviewModalOpen, enqueueSnackbar, patchReviewDeterminationUpdatesError]);

  useEffect(() => {
    const initialDenialLanguage = review?.denialLanguage || "";
    setDenialLanguage(initialDenialLanguage);
    if (initialDenialLanguage) {
      setCanSubmitForReview(true);
    }
  }, [review?.denialLanguage, setDenialLanguage]);

  useEffect(() => {
    if (review?.denialsTemplate) {
      setStarted(true);
      setSelectTemplate(false);
    }
  }, [review?.denialsTemplate]);

  useEffect(() => {
    if (doctorOrNurseReview?.denialsTemplate) {
      setParsedMarkupText(
        parseMarkup(
          doctorOrNurseReview.denialsTemplate.formattedDenialsLanguage || "",
          (doctorOrNurseReview.reviewType === "MdReview" &&
            (doctorOrNurseReview.overwrittenTranslatedMdNote || doctorOrNurseReview.translatedMdNote)) ||
            "",
          setMdNote
        )
      );
    }
    if (
      (doctorOrNurseReview?.denialsTemplate?.convertedToBlob ||
        !doctorOrNurseReview?.denialsTemplate ||
        !prePopulateDenialsTemplate) &&
      doctorOrNurseReview
    ) {
      setIsBlob(true);
    } else {
      setIsBlob(false);
    }
  }, [doctorOrNurseReview, prePopulateDenialsTemplate]);

  useEffect(() => {
    if (serviceRequest && doctorOrNurseReview) {
      hasDenialBeenStarted && canEdit && !hasDenialBeenSent
        ? setDenialLetterView("LETTER_EDIT")
        : !hasDenialBeenStarted && canWriteReview
        ? setDenialLetterView("LETTER_WRITE")
        : setDenialLetterView("READ_ONLY");
    }
  }, [serviceRequest, hasDenialBeenSent, canEdit, hasDenialBeenStarted, canWriteReview, doctorOrNurseReview]);

  useEffect(() => {
    if (serviceRequestOnClick && !serviceRequestLoadingOnClick) {
      const serviceRequestDenied = isTerminalStatus(serviceRequestOnClick);
      if (serviceRequestDenied) {
        setDenialSentModalOpen(true);
      } else {
        setDenialPreviewModalOpen(true);
      }
    }
  }, [serviceRequestOnClick, serviceRequestLoadingOnClick, setDenialSentModalOpen]);

  useEffect(() => {
    if (patchReviewDeterminationUpdatesError) {
      if (patchReviewDeterminationUpdatesError.status === 409) {
        setDualReviewErrorModalOpen(true);
      } else {
        setErrorDetails(
          extractErrorDetails(patchReviewDeterminationUpdatesError, serviceRequest?.id || "", review?.id || "")
        );
        setOpenHardRedirectOnSubmitModal(true);
      }
    }
  }, [
    enqueueSnackbar,
    patchReviewDeterminationUpdatesError,
    setDualReviewErrorModalOpen,
    serviceRequest?.id,
    review?.id,
    setOpenHardRedirectOnSubmitModal,
  ]);

  const saveReviewDeterminationUpdates = useCallback(
    async ({
      stayOnPage = false,
      denialStatus,
      nextReviewDate,
      editedPreferredLabels,
      claimsPaymentNote,
      serviceStatusCode,
      reviewerCreateContinuationAcknowledgement,
      additionalRecipients,
    }: SaveReviewDeterminationUpdatesProps) => {
      const payload: PatchReviewDeterminationUpdatesRequestBody = {
        denialLanguage,
        denialStatus: denialStatus,
        denialsTemplate: {
          ...doctorOrNurseReview?.denialsTemplate,
          formattedDenialsLanguage: editedMarkupText,
          convertedToBlob: isBlob,
        },
        overwrittenTranslatedMdNote: mdNote,
        nextReviewDate,
        editedPreferredLabels,
        claimsPaymentNote,
        serviceStatusCode,
        reviewerCreateContinuationAcknowledgement,
        additionalRecipients,
      };
      await patchReviewDeterminationUpdates(payload);
      if (!patchReviewDeterminationUpdatesError && !stayOnPage) {
        redirectToPatientSummaryFromReview({ serviceRequest, caseId: urlCaseId, navigate });
      }
    },
    [
      denialLanguage,
      doctorOrNurseReview?.denialsTemplate,
      editedMarkupText,
      isBlob,
      mdNote,
      navigate,
      patchReviewDeterminationUpdates,
      patchReviewDeterminationUpdatesError,
      serviceRequest,
      urlCaseId,
    ]
  );

  /**
   * While discarding a denial review we will remove denialLaunguage on the be stored till now
   * and move denial status back to NOT_STARTED.
   */
  const discardReviewDraft = useCallback(
    async (stayOnPage = false) => {
      await patchReviewDeterminationUpdates({
        denialStatus: "NOT_STARTED",
      });
      if (!patchReviewDeterminationUpdatesError && !stayOnPage) {
        redirectToPatientSummaryFromReview({ serviceRequest, caseId: urlCaseId, navigate });
      }
    },
    [navigate, patchReviewDeterminationUpdates, patchReviewDeterminationUpdatesError, serviceRequest, urlCaseId]
  );

  const onFinalizeDenial = async (
    event: any,
    authorization?: Partial<AuthorizationResponse>,
    reviewerCreateContinuationAcknowledgement?: boolean
  ) => {
    event.preventDefault();
    try {
      if (doctorOrNurseReview) {
        await saveReviewDeterminationUpdates({
          stayOnPage: true,
          denialStatus: "COMPLETE",
          nextReviewDate: authorization?.nextReviewDate,
          reviewerCreateContinuationAcknowledgement,
        });
        if (doctorOrNurseReview.reviewType === "MdReview" && mdNote && translatedMdNoteEnabled) {
          await postMdNoteFeedback({
            reviewId: doctorOrNurseReview.id,
            serviceRequestID: serviceRequest?.id,
            mdNote: doctorOrNurseReview.determinationNote,
            translatedMdNote: doctorOrNurseReview.translatedMdNote,
            submittedMdNote: mdNote,
            wasEdited: doctorOrNurseReview.translatedMdNote !== mdNote,
            procedureCodes: serviceRequest?.semanticProcedureCodes?.map((procedureCode) => procedureCode.code),
            healthPlanName: doctorOrNurseReview.healthPlanName,
            modelVersion: doctorOrNurseReview.mdModel?.modelVersion,
            modelTrainedDate: doctorOrNurseReview.mdModel?.dateTrained,
            reviewCompleteDate: new Date().toISOString(),
            wasCustomLetterUsed: isBlob,
            wasMdNoteEditedInCustomLetter: isBlob && !denialLanguage.includes(mdNote),
            sanitizedMdNote: stripHTMl(doctorOrNurseReview.determinationNote || ""),
          });
        }
        setDenialSubmissionModalOpen(false);
        setFinalizeDenial(true);
      } else {
        setSubmissionError("Review must not be empty to submit");
      }
    } catch (error) {
      setFinalizeDenialError(true);
    }
  };

  const automatedDenialLetterConfigEnabled = useFeature("useAutomatedDenialLetterConfiguration");
  const { data: automatedDenialLetterConfig } = useGetAutomatedDenialLetterConfigurationByPayer(
    serviceRequest?.healthPlanName || ""
  );
  const populateProcedureCodeDescription = automatedDenialLetterConfigEnabled
    ? automatedDenialLetterConfig?.usePreferredLabels
    : false;

  const [isPreviewLetterButtonClicked, setIsPreviewLetterButtonClicked] = useState<boolean>(false);

  const [preferredLabelFeedback, setPreferredLabelFeedback] = useState<PreferredLabelFeedback[]>([]);

  const atLeastOnePreferredLabelWasEdited = preferredLabelFeedback.some(
    (preferredLabel) => preferredLabel.wasEdited === true
  );

  const simplePreferredLabelEdit = useMemo(
    () => reduceToSimplePreferredLabelUpdate(preferredLabelFeedback.filter((feedback) => feedback.wasEdited)),
    [preferredLabelFeedback]
  );

  useEffect(() => {
    if (denialPreviewModalOpen && !patchReviewDeterminationUpdatesError) {
      const updateReviewAndFetchDeterminationLetter = async () => {
        let editedPreferredLabels;
        if ((atLeastOnePreferredLabelWasEdited || isPreviewLetterButtonClicked) && populateProcedureCodeDescription) {
          editedPreferredLabels = simplePreferredLabelEdit;
        }
        await saveReviewDeterminationUpdates({
          stayOnPage: true,
          denialStatus: "DRAFT",
          nextReviewDate: undefined,
          editedPreferredLabels,
        });

        const result = await refetchDeterminationLetter();
        if (result) {
          const resultStream = result as Response;
          setDenialLetterPreviewResponse(resultStream);
        }
      };
      updateReviewAndFetchDeterminationLetter();
    } else {
      setDenialLetterPreviewResponse(undefined);
    }
  }, [
    atLeastOnePreferredLabelWasEdited,
    denialPreviewModalOpen,
    isPreviewLetterButtonClicked,
    patchReviewDeterminationUpdatesError,
    populateProcedureCodeDescription,
    refetchDeterminationLetter,
    saveReviewDeterminationUpdates,
    simplePreferredLabelEdit,
  ]);

  useEffect(() => {
    if (denialPreviewModalOpen && patchReviewDeterminationUpdatesError) {
      enqueueSnackbar(`Error creating Final Determination Letter - ${patchReviewDeterminationUpdatesError.message}`, {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [denialPreviewModalOpen, enqueueSnackbar, patchReviewDeterminationUpdatesError]);

  useEffect(() => {
    const getAssociatedServiceCase = async () => {
      const retrievedCases = await SearchServiceCase({
        serviceRequestId: serviceRequest?.id,
        caseStatusList: ["OPEN", "IN_PROGRESS"],
        caseType: "LETTER_WRITING",
      });
      if (retrievedCases?.length > 0) {
        setCaseId(
          retrievedCases.sort(
            (a, b) => parseDateFromISOString(b.dateCreated).getTime() - parseDateFromISOString(a.dateCreated).getTime()
          )[0].id || null
        );
      }
    };
    if (serviceRequest?.id && config.HAS_QM_ENVIRONMENT && !urlCaseId) {
      getAssociatedServiceCase();
    }
  }, [SearchServiceCase, serviceRequest?.id, urlCaseId]);

  const [isValidDenialLetterProcedureCodePopulate, setIsValidDenialLetterProcedureCodePopulate] =
    useState<boolean>(true);

  return (
    <>
      <ServiceRequestInfoCard style={{ padding: theme.spacing(4, 5, 4, 5), ...(crrView ? { boxShadow: "none" } : {}) }}>
        <Grid container spacing={3}>
          {selectTemplate && !hasMultipleTemplates && (
            <CircularProgressContainer>
              <CircularProgress size={100} />
            </CircularProgressContainer>
          )}
          {selectTemplate && hasMultipleTemplates && (
            <>
              <DenialRow>
                <H5 style={{ paddingTop: theme.spacing(1) }}>
                  Select{doctorOrNurseReview?.denialsTemplate && " a new"} final determination template
                </H5>
                <InlineButton
                  onClick={() => {
                    setIsBlob(true);
                    setSelectTemplate(false);
                    setStarted(true);
                  }}
                  startIcon={<PencilIcon />}
                >
                  Custom letter
                </InlineButton>
              </DenialRow>
              <DenialRow>
                <SingleSelectDropdown
                  label="Final determination template"
                  data-testid="Final Determination Dropdown"
                  options={
                    denialsTemplates?.map((i) => ({
                      id: i?.id || "",
                      label: i?.templateTitle || "",
                    })) || []
                  }
                  renderOption={(pos: any) => {
                    return <span style={{ width: matchesRight ? 597 : "calc(100vw - 843px)" }}>{pos.label}</span>;
                  }}
                  showLoadingIcon={denialsLoading}
                  value={newDenialsTemplate?.denialsTemplateId || ""}
                  onChange={(opt) => {
                    const denialsTemplate = denialsTemplates?.find((x) => x.id === opt);
                    setNewDenialsTemplate({
                      denialsTemplateId: denialsTemplate?.id || "",
                      formattedDenialsLanguage: denialsTemplate?.denialLanguage || "",
                      templateTitle: denialsTemplate?.templateTitle || "",
                    });
                  }}
                />
              </DenialRow>
            </>
          )}
          {started &&
            !selectTemplate &&
            (denialLetterView === "READ_ONLY" ? (
              <>
                <DenialLetterHeader
                  isBlob={renderAsBlob}
                  setIsBlob={setIsBlob}
                  review={doctorOrNurseReview}
                  denialsTemplate={doctorOrNurseReview?.denialsTemplate}
                  hideRevertButton={!prePopulateDenialsTemplate}
                  isReadOnly
                  denialLetterView={denialLetterView}
                  canSelectTemplate={hasMultipleTemplates}
                  setSelectTemplate={setSelectTemplate}
                  setRestoreTemplateOpen={setRestoreTemplateOpen}
                />
                {prePopulateDenialsTemplate ? (
                  <DenialLetterBody
                    isReadOnly
                    parsedMarkupText={parsedMarkupText}
                    denialLanguage={denialLanguage}
                    isBlob={renderAsBlob}
                    serviceRequest={serviceRequest}
                    review={doctorOrNurseReview}
                  />
                ) : (
                  <ReadOnlyLetterText>{doctorOrNurseReview?.denialLanguage}</ReadOnlyLetterText>
                )}
              </>
            ) : (
              <>
                <DenialLetterHeader
                  isBlob={renderAsBlob}
                  setIsBlob={setIsBlob}
                  review={doctorOrNurseReview}
                  denialsTemplate={doctorOrNurseReview?.denialsTemplate}
                  hideRevertButton={!prePopulateDenialsTemplate}
                  denialLetterView={denialLetterView}
                  canSelectTemplate={hasMultipleTemplates}
                  setSelectTemplate={setSelectTemplate}
                  setRestoreTemplateOpen={setRestoreTemplateOpen}
                />
                {populateProcedureCodeDescription && (
                  <DenialLetterProcedureCodePopulate
                    serviceRequest={serviceRequest}
                    review={doctorOrNurseReview}
                    setIsValidDenialLetterProcedureCodePopulate={setIsValidDenialLetterProcedureCodePopulate}
                    isPreviewLetterButtonClicked={isPreviewLetterButtonClicked}
                    setIsPreviewLetterButtonClicked={setIsPreviewLetterButtonClicked}
                    setPreferredLabelFeedback={setPreferredLabelFeedback}
                  />
                )}
                {prePopulateDenialsTemplate ? (
                  <DenialLetterBody
                    parsedMarkupText={parsedMarkupText}
                    denialLanguage={denialLanguage}
                    setDenialLanguage={setDenialLanguage}
                    setEditedMarkupText={setEditedMarkupText}
                    isBlob={renderAsBlob}
                    setCanSubmitForReview={setCanSubmitForReview}
                    serviceRequest={serviceRequest}
                    review={doctorOrNurseReview}
                    mdNote={mdNote}
                    setMdNote={setMdNote}
                  />
                ) : (
                  <TemplateField
                    fullWidth
                    multiline
                    rows={35}
                    label="Insert and edit denial letter here"
                    value={denialLanguage}
                    onChangeValue={(value) => {
                      setDenialLanguage(value);
                      if (value === "") {
                        setCanSubmitForReview(false);
                      } else {
                        setCanSubmitForReview(true);
                      }
                      setHasMadeChanges(true);
                    }}
                  />
                )}
              </>
            ))}
          {denialLetterView !== "READ_ONLY" &&
            (crrView ? (
              <ReviewControlBoxOuterContainer matchesRight={matchesRight} denial>
                <ReviewControlBoxInnerContainer>
                  <DenialLetterControlPanel
                    serviceRequest={serviceRequest}
                    reviewUpdateLoading={patchReviewDeterminationUpdatesLoading}
                    reviewSubmitLoading={reviewSubmitLoading}
                    canSubmitForReview={canSubmitForReview}
                    hasMadeChanges={hasMadeChanges}
                    hasDenialBeenSent={hasDenialBeenSent}
                    setDenialSentModalOpen={setDenialSentModalOpen}
                    setEditsMadeModalOpen={setEditsMadeModalOpen}
                    saveReviewDeterminationUpdates={saveReviewDeterminationUpdates}
                    getServiceRequestOnClick={getServiceRequestOnClick}
                    serviceRequestLoadingOnClick={serviceRequestLoadingOnClick}
                    selectTemplate={selectTemplate}
                    handleSelectTemplate={handleSelectTemplate}
                    handleCancel={handleCancel}
                    disableSelection={!newDenialsTemplate}
                    review={review}
                    setIncorrectReviewForDenialLetterModal={setIncorrectReviewForDenialLetterModal}
                    disableDueToWrongReview={disableDueToWrongReview}
                    setDisableDueToWrongReview={setDisableDueToWrongReview}
                    discardReviewDraft={discardReviewDraft}
                    isValidDenialLetterProcedureCodePopulate={isValidDenialLetterProcedureCodePopulate}
                    setIsPreviewLetterButtonClicked={setIsPreviewLetterButtonClicked}
                  />
                </ReviewControlBoxInnerContainer>
              </ReviewControlBoxOuterContainer>
            ) : (
              <DenialLetterControlPanel
                serviceRequest={serviceRequest}
                review={review}
                reviewUpdateLoading={
                  patchReviewDeterminationUpdatesLoading && !denialPreviewModalOpen && !denialSubmissionModalOpen
                }
                reviewSubmitLoading={reviewSubmitLoading}
                canSubmitForReview={canSubmitForReview}
                hasMadeChanges={hasMadeChanges}
                hasDenialBeenSent={hasDenialBeenSent}
                setDenialSentModalOpen={setDenialSentModalOpen}
                setEditsMadeModalOpen={setEditsMadeModalOpen}
                saveReviewDeterminationUpdates={saveReviewDeterminationUpdates}
                getServiceRequestOnClick={getServiceRequestOnClick}
                serviceRequestLoadingOnClick={serviceRequestLoadingOnClick}
                selectTemplate={selectTemplate}
                handleSelectTemplate={handleSelectTemplate}
                handleCancel={handleCancel}
                disableSelection={!newDenialsTemplate}
                setIncorrectReviewForDenialLetterModal={setIncorrectReviewForDenialLetterModal}
                disableDueToWrongReview={disableDueToWrongReview}
                setDisableDueToWrongReview={setDisableDueToWrongReview}
                discardReviewDraft={discardReviewDraft}
                isValidDenialLetterProcedureCodePopulate={isValidDenialLetterProcedureCodePopulate}
                setIsPreviewLetterButtonClicked={setIsPreviewLetterButtonClicked}
              />
            ))}
        </Grid>
      </ServiceRequestInfoCard>
      <DenialSubmissionModal
        denialSubmissionModalOpen={denialSubmissionModalOpen}
        setDenialSubmissionModalOpen={setDenialSubmissionModalOpen}
        onFinalizeDenial={onFinalizeDenial}
        finalizeDenialLoading={patchReviewDeterminationUpdatesLoading}
        finalizeDenialError={finalizeDenialError}
        setFinalizeDenialError={setFinalizeDenialError}
      />
      {serviceRequest && serviceRequest.attachments && (
        <DenialPreviewModal
          denialPreviewModalOpen={denialPreviewModalOpen}
          setDenialPreviewModalOpen={setDenialPreviewModalOpen}
          onFinalizeDenial={onFinalizeDenial}
          finalizingDenial={patchReviewDeterminationUpdatesLoading}
          determinationLetterError={!!determinationLetterError}
          denialLetterPreviewResponse={denialLetterPreviewResponse}
          serviceRequest={serviceRequest}
          reviewOutcome={review?.reviewOutcome ?? ""}
          reviewId={review?.id || ""}
          incorrectReviewForDenialLetter={incorrectReviewForDenialLetter}
          setIncorrectReviewForDenialLetterModal={setIncorrectReviewForDenialLetterModal}
          disableDueToWrongReview={disableDueToWrongReview}
          setDisableDueToWrongReview={setDisableDueToWrongReview}
          updateServiceCase={updateServiceCase}
          caseId={caseId}
          preferredLabelFeedback={preferredLabelFeedback}
          populateProcedureCodeDescription={populateProcedureCodeDescription}
          continuationConfiguration={continuationConfiguration}
        />
      )}
      <DetailedHardRedirectClinicalReviewModal
        isOpen={openHardRedirectOnSubmitModal}
        setIsOpen={setOpenHardRedirectOnSubmitModal}
        errorDetails={errorDetails}
      />
      <ReviewErrorModal
        open={editsMadeModalOpen}
        setReviewErrorModalOpen={setEditsMadeModalOpen}
        headerText={"Edits may not be visible to MD Reviewers in subsequent reviews"}
        primaryButtonAction={() => {
          setEditsMadeModalOpen(false);
          saveReviewDeterminationUpdates({ denialStatus: "DRAFT" });
        }}
        primaryButtonText={"Continue"}
        tertiaryButtonText={"Cancel"}
      />
      <ReviewErrorModal
        open={restoreTemplateOpen}
        setReviewErrorModalOpen={setRestoreTemplateOpen}
        headerText="Any edits made will be lost"
        subHeaderText="This will restore the original template for this review. Any changes to the custom letter will be lost."
        primaryButtonAction={() => {
          setIsBlob(false);
          setRestoreTemplateOpen(false);
        }}
        primaryButtonText="Discard changes and revert"
        tertiaryButtonText="Cancel"
        tertiaryButtonAction={() => {
          setRestoreTemplateOpen(false);
        }}
      />
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ServiceRequestInfoCard = styled(Card)(() => ({
  borderRadius: "10px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TemplateField = styled(TextField)(({ theme }) => ({
  height: 664,
  "& input": {
    paddingBottom: theme.spacing(72),
    "&.Mui-disabled": {
      cursor: "default",
    },
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ReadOnlyLetterText = styled(Body1)(({ theme }) => ({
  whiteSpace: "pre-wrap",
  paddingTop: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DenialRow = styled("div")(({ theme }) => ({
  marginLeft: -theme.spacing(0.5),
  marginRight: -theme.spacing(0.5),
  marginBottom: theme.spacing(1),
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CircularProgressContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: theme.spacing(5),
}));

export interface SaveReviewDeterminationUpdatesProps {
  stayOnPage?: boolean;
  denialStatus?: PatchReviewDeterminationUpdatesRequestBody["denialStatus"];
  nextReviewDate?: string;
  editedPreferredLabels?: EditedPreferredLabel[];
  serviceStatusCode?: string;
  claimsPaymentNote?: string;
  reviewerCreateContinuationAcknowledgement?: boolean;
  additionalRecipients?: string[];
}
