import { RadioGroup } from "@coherehealth/common";
import { ReviewOutcomeProps } from "../../mdReviewModalTypes";
import { useModalStyles } from "../../../mdReviewStyles";
import { Grid } from "@material-ui/core";

export const RadioButtons = ({ review, radioButtonProps, handleChange }: ReviewOutcomeProps) => {
  const { readmissionReason, withdrawRequestor } = review;
  const modalStyles = useModalStyles({});

  const value = radioButtonProps?.value === "readmissionReason" ? readmissionReason : withdrawRequestor;

  return (
    <Grid xs={12} item className={modalStyles.radioBtnRow} data-testid={"md-modal-radio-btns"}>
      <RadioGroup
        label={radioButtonProps?.label}
        options={radioButtonProps?.options}
        value={value}
        onChange={(opt) => handleChange?.(opt)}
      />
    </Grid>
  );
};
