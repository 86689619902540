import { Card, Grid, makeStyles } from "@material-ui/core";
import { ReferralRequestResponse } from "@coherehealth/core-platform-api";
import ReferralPreReviewContent from "./ReferralPreReviewContent";

interface ReferralReviewProps {
  referralRequest: ReferralRequestResponse;
  isAdministrative: boolean;
}

export default function ReviewContainer({ referralRequest, isAdministrative }: ReferralReviewProps) {
  const classes = useStyles();

  return (
    <div className={classes.includeRequestorSpace}>
      <div className={classes.innerContainer}>
        <Grid key={referralRequest.id} item xs>
          <Card className={classes.referralRequestCardContainer}>
            <ReferralPreReviewContent referralRequest={referralRequest} isAdministrative={isAdministrative} />
          </Card>
        </Grid>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "40px",
  },
  referralRequestCardContainer: {
    padding: theme.spacing(3),
  },
  includeRequestorSpace: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(10),
  },
}));
