import { FunctionComponent, useEffect, useState } from "react";
import {
  useGetInternalFinalDeterminationLetterAttachmentDownload,
  useGetFinalDeterminationLetterAttachmentDownload,
  useDownloadTatExtensionLetterAttachment,
  useGetInternalFinalDeterminationFaxAttachmentDownload,
} from "@coherehealth/core-platform-api";
import { blobFromRequest } from "@coherehealth/common";
import print from "util/print";
import MailIcon from "@material-ui/icons/Mail";
import { CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";

interface DProps {
  serviceRequestId: string;
  attachmentId: string;
  showingInternalLetter?: boolean;
  showingTatExtensionLetter?: boolean;
  showingInternalFax?: boolean;
  setFileUrl: (url: string) => void;
}

function DownloadAttachment({
  serviceRequestId,
  attachmentId,
  showingInternalLetter,
  showingTatExtensionLetter,
  showingInternalFax,
  setFileUrl,
}: DProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { error: errorExternal, refetch: refetchExternal } = useGetFinalDeterminationLetterAttachmentDownload({
    serviceRequestId,
    id: attachmentId,
    lazy: true,
  });

  const { error: errorInternal, refetch: refetchInternal } = useGetInternalFinalDeterminationLetterAttachmentDownload({
    serviceRequestId,
    id: attachmentId,
    lazy: true,
  });

  const { error: errorInternalFax, refetch: refetchInternalFax } =
    useGetInternalFinalDeterminationFaxAttachmentDownload({
      serviceRequestId,
      id: attachmentId,
      lazy: true,
    });

  const { error: tatLetterError, refetch: fetchTatLetter } = useDownloadTatExtensionLetterAttachment({
    id: attachmentId,
    lazy: true,
  });

  useEffect(() => {
    const fetchAttachment = async () => {
      const blob = await blobFromRequest(
        showingInternalFax
          ? refetchInternalFax()
          : showingInternalLetter
          ? refetchInternal()
          : showingTatExtensionLetter
          ? fetchTatLetter()
          : refetchExternal()
      );
      if (blob) {
        const url = URL.createObjectURL(blob);
        setFileUrl(url);
        return () => URL.revokeObjectURL(url);
      }
    };

    fetchAttachment();
  }, [
    refetchExternal,
    refetchInternal,
    refetchInternalFax,
    fetchTatLetter,
    showingTatExtensionLetter,
    showingInternalLetter,
    setFileUrl,
    showingInternalFax,
  ]);

  useEffect(() => {
    if (errorExternal) {
      setFileUrl("");
      enqueueSnackbar(`Error downloading final determination letter: ${errorExternal.message}`, { variant: "error" });
    } else if (errorInternal) {
      setFileUrl("");
      enqueueSnackbar(`Error downloading final determination letter: ${errorInternal.message}`, { variant: "error" });
    } else if (errorInternalFax) {
      setFileUrl("");
      enqueueSnackbar(`Error downloading final determination fax: ${errorInternalFax.message}`, { variant: "error" });
    } else if (tatLetterError) {
      setFileUrl("");
      enqueueSnackbar(`Error downloading TAT extension letter: ${tatLetterError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, setFileUrl, errorExternal, errorInternal, errorInternalFax, tatLetterError]);

  return <CircularProgress />;
}

interface IProps {
  serviceRequestId: string;
  attachmentId?: string;
  showingInternalLetter?: boolean;
  showingTatExtensionLetter?: boolean;
  showingInternalFax?: boolean;
  ButtonComponent?: FunctionComponent<{ onClick(): void }>;
}
export default function PrintableStatusAlert({
  serviceRequestId,
  attachmentId,
  showingInternalLetter,
  showingTatExtensionLetter,
  showingInternalFax,
  ButtonComponent = DefaultButtonComponent,
}: IProps) {
  const [fileUrl, setFileUrl] = useState<string>();
  const [printIfReady, setPrintIfReady] = useState(false);

  useEffect(() => {
    if (fileUrl && printIfReady) {
      print(fileUrl, "pdf");
      setPrintIfReady(false);
    }
  }, [fileUrl, printIfReady]);

  if (attachmentId) {
    return !fileUrl && printIfReady ? (
      <DownloadAttachment
        serviceRequestId={serviceRequestId}
        attachmentId={attachmentId}
        showingInternalLetter={showingInternalLetter}
        showingTatExtensionLetter={showingTatExtensionLetter}
        showingInternalFax={showingInternalFax}
        setFileUrl={(url) => {
          setFileUrl(url);
          setPrintIfReady(false);
        }}
      />
    ) : (
      <ButtonComponent
        onClick={() => {
          setPrintIfReady(true);
        }}
      />
    );
  }
  return null;
}

function DefaultButtonComponent({ onClick }: { onClick(): void }) {
  return (
    <IconButton
      onClick={onClick}
      aria-label="View Final Determination Letter"
      title="View Final Determination Letter"
      data-testid="prinatble-status-alert-btn"
    >
      <MailIcon />
    </IconButton>
  );
}
