import { Dispatch, SetStateAction } from "react";
import usePrevious from "react-use/lib/usePrevious";
import { formatDateToISODate, useFeature } from "@coherehealth/common";
import { ProcedureCode, usePalCheck } from "@coherehealth/core-platform-api";
import { NonCohereCodes } from "components/AuthBuilder/common";
import { filterToAuthBucket, filterToCodesAndVendor, filterToMatchingProcedures } from "util/serviceRequest";

interface UseDoPalCheckProps {
  patientId?: string;
  isInpatient: boolean;
  startDate: string | Date;
  palProcedureCodes: ProcedureCode[];
  setProcedureCodes: Dispatch<ProcedureCode[]>;
  setPalProcedureCodes: Dispatch<ProcedureCode[]>;
  setNonPalProcedureCodes: Dispatch<SetStateAction<ProcedureCode[]>>;
  setNonCohereProcedureCodes: Dispatch<SetStateAction<NonCohereCodes[]>>;
}

// Performs a palCheck and updates procedure code buckets
const useDoPalCheckOnServiceRequestForm = ({
  patientId,
  isInpatient,
  startDate,
  palProcedureCodes,
  setProcedureCodes,
  setPalProcedureCodes,
  setNonPalProcedureCodes,
  setNonCohereProcedureCodes,
}: UseDoPalCheckProps) => {
  const { mutate: getPalCheckData } = usePalCheck({});
  const authSubmissionVendorEnabled = useFeature("authSubmissionVendorOnPalCheckEnabled");
  const previousPalProcedureCodeLength = usePrevious(palProcedureCodes?.length);

  const doPalCheck = async (
    procedureCodes: ProcedureCode[],
    isOONExceptionRequest: boolean,
    allowNonPalCodes?: boolean
  ) => {
    const resp = await getPalCheckData({
      patientId: patientId,
      encounterType: isInpatient ? "INPATIENT" : "OUTPATIENT",
      procedureCodes: procedureCodes.map(({ code }) => code),
      startDate: formatDateToISODate(startDate),
      isOONExceptionRequest,
    });
    if (resp) {
      let palCodes: string[] = [];
      let nonPalCodes: string[] = [];
      let nonCohereCodes = new Map<string, string[]>();
      let nonCohereCodesOnly: string[] = [];
      filterToAuthBucket(
        palCodes,
        nonPalCodes,
        nonCohereCodes,
        authSubmissionVendorEnabled,
        resp.pxCodes,
        nonCohereCodesOnly
      );
      let nonPalCodesFromPalCheck: ProcedureCode[] = filterToMatchingProcedures(procedureCodes, nonPalCodes);
      let palProcedureCodesFromPalCheck: ProcedureCode[] = filterToMatchingProcedures(procedureCodes, palCodes);
      let nonCohereProcedureCodesOnlyFromPalCheck: ProcedureCode[] = filterToMatchingProcedures(
        procedureCodes,
        nonCohereCodesOnly
      ); //only the code that have an auth sub vendor that is not Cohere
      let nonCohereCodesFromPalCheck: NonCohereCodes[] = filterToCodesAndVendor(nonCohereCodes, procedureCodes); //auth sub vendor + codes from PalCheck

      setNonPalProcedureCodes((prevNonPalProcedureCodes) =>
        prevNonPalProcedureCodes
          ?.filter((px) => !palProcedureCodesFromPalCheck?.some(({ code }) => code === px.code)) // remove codes from non-pal state that are now on the "PAL"
          .concat(
            // Add new codes that weren't on the non-pal state before
            nonPalCodesFromPalCheck.filter((px) => !prevNonPalProcedureCodes?.some(({ code }) => code === px.code))
          )
      );
      setPalProcedureCodes(palProcedureCodesFromPalCheck);
      //if there were no procedureCodes where Cohere was authSubmissionVendor before but there is now clear banner
      if (previousPalProcedureCodeLength === 0 && palProcedureCodesFromPalCheck.length !== 0) {
        setNonCohereProcedureCodes([]); //removes all codes from the banner
      } else {
        //otherwise display banner
        setNonCohereProcedureCodes((prevNonCohereProcedureCodes) =>
          filterOutDuplicates(
            nonCohereCodesFromPalCheck,
            nonCohereProcedureCodesOnlyFromPalCheck,
            prevNonCohereProcedureCodes
          )
        );
      }
      setProcedureCodes(
        procedureCodes
          .filter((px) => allowNonPalCodes || !nonPalCodesFromPalCheck?.some(({ code }) => code === px.code))
          .filter((px) => !nonCohereProcedureCodesOnlyFromPalCheck?.some(({ code }) => code === px.code))
      );
    }
  };

  return { doPalCheck };
};

function filterOutDuplicates(
  nonCoherePxCodesFromPalCheck: NonCohereCodes[],
  nonCohereProcedureCodesOnly: ProcedureCode[],
  nonCohereProcedureCodes: NonCohereCodes[]
): NonCohereCodes[] {
  let allNonCoherePxCodesOnly: ProcedureCode[] = []; //have an array of only the procedure code part of the nonCohereCodes
  nonCohereProcedureCodes.forEach((value: NonCohereCodes) => {
    value.procedureCode.forEach((pxCode: ProcedureCode) => {
      allNonCoherePxCodesOnly.push(pxCode);
    });
  });
  if (
    nonCohereProcedureCodesOnly?.filter((px) => !allNonCoherePxCodesOnly?.some(({ code }) => code === px.code))
      .length !== 0
  ) {
    //if there is something from the palCheck that is not already in nonCohereProcedureCodes then we want to add them the nonCohereProcedureCodes
    let pxCodesToAdd = nonCohereProcedureCodesOnly?.filter(
      (px) => !allNonCoherePxCodesOnly?.some(({ code }) => code === px.code)
    );
    pxCodesToAdd.forEach((pxCode) => {
      const vendorWithCodes = nonCoherePxCodesFromPalCheck.find((codeAndVendor) =>
        codeAndVendor.procedureCode.includes(pxCode)
      );
      const vendor = vendorWithCodes?.authSubmissionVendor;
      const codesAndVendor = nonCohereProcedureCodes.find(
        (codeAndVendor) => codeAndVendor.authSubmissionVendor === vendor
      );
      if (codesAndVendor && !codesAndVendor.procedureCode.includes(pxCode)) {
        codesAndVendor.procedureCode.push(pxCode);
      } else if (codesAndVendor === undefined) {
        nonCohereProcedureCodes.push(
          vendorWithCodes || { authSubmissionVendor: "Cannot determine", procedureCode: [pxCode] }
        );
      }
    });
  }
  return nonCohereProcedureCodes;
}

export default useDoPalCheckOnServiceRequestForm;
