import React, { FunctionComponent } from "react";
import { FlexGridItem } from "common/SharedServiceRequestFormComponents";
import OutOfNetworkBanner from "components/ServiceRequest/ServiceRequestForm/components/OutOfNetworkBanner";
import { useTheme } from "@material-ui/core/styles";
import { Body2, useNetworkCheckStatusStyles } from "@coherehealth/common";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import QuestionIcon from "@material-ui/icons/HelpRounded";
import { CircularProgress, Grid } from "@material-ui/core";
import { NetworkType } from "@coherehealth/core-platform-api";

interface Props {
  oonLoading?: boolean;
  healthPlanName: string;
  oonExceptionConfig: "REQUIRED" | "OPTIONAL" | "OMIT";
  networkType?: NetworkType;
  entityType: "performingProvider" | "orderingProvider" | "facility" | "careParticipant";
  careParticipantType?: string;
  isOonExceptionRequired?: boolean;
  isOonExceptionOmit?: boolean;
}

function getEntityTypeText(careParticipantType?: string) {
  return {
    performingProvider: "provider",
    orderingProvider: "provider",
    facility: "facility",
    careParticipant: careParticipantType ? careParticipantType : "",
  };
}

function getUnknownNetworkText(
  entityType: "performingProvider" | "orderingProvider" | "facility" | "careParticipant",
  isOonExceptionRequired?: boolean,
  isOonExceptionOmit?: boolean,
  careParticipantType?: string
) {
  let unknownDisplayText = "Network status unknown, and your request may pend for review.";
  const entityTypeText = getEntityTypeText(careParticipantType)[entityType] || "";

  if (isOonExceptionRequired) {
    unknownDisplayText = unknownDisplayText.concat(
      ` If the ${entityTypeText} is out-of-network, please provide an exception reason below. If the ${entityTypeText} is in-network, select “This ${entityTypeText} should be listed as in-network for this patient“.`
    );
  } else {
    if (!isOonExceptionOmit) {
      unknownDisplayText = unknownDisplayText.concat(
        ` Ask for an exception if you believe this ${entityTypeText} is out-of-network and in-network rates should apply.`
      );
    } else {
      return unknownDisplayText;
    }
  }

  return unknownDisplayText;
}
const NetworkCheckStatusDisplay: FunctionComponent<Props> = ({
  oonLoading,
  healthPlanName,
  oonExceptionConfig,
  networkType,
  entityType,
  careParticipantType,
  isOonExceptionRequired,
  isOonExceptionOmit,
}) => {
  if (!oonLoading) {
    if (networkType === "OON" || networkType === "OOA") {
      return (
        <FlexGridItem container>
          <OutOfNetworkBanner
            performingProviderOON={entityType === "performingProvider" ? true : false}
            facilityOON={entityType === "facility" ? true : false}
            orderingProviderOON={entityType === "orderingProvider" ? true : false}
            healthPlanName={healthPlanName}
            oonExceptionConfig={oonExceptionConfig}
            networkType={networkType || undefined}
            careParticipantOON={entityType === "careParticipant" ? true : false}
            careParticipantType={careParticipantType}
          ></OutOfNetworkBanner>
        </FlexGridItem>
      );
    } else if (networkType === "INN") {
      return <InNetworkDisplay />;
    } else if (networkType === "Unknown") {
      const unknownDisplayText = `${getUnknownNetworkText(
        entityType,
        isOonExceptionRequired,
        isOonExceptionOmit,
        careParticipantType
      )}`;
      return <UnknownNetworkDisplay unknownDisplayText={unknownDisplayText} />;
    } else {
      return <></>;
    }
  } else {
    return <OonCheckLoadingDisplay />;
  }
};

export const OonCheckLoadingDisplay = () => {
  const classes = useNetworkCheckStatusStyles();
  return (
    <FlexGridItem>
      <div className={classes.oonStatusContainer}>
        <div className={classes.runningIconContainer}>
          <CircularProgress size={24} />
        </div>
        <div className={classes.verticallyCenteredContainer}>
          <div className={classes.verticallyCenteredItem}>
            <Body2 className={classes.networkCheckRunningText}>Running network check</Body2>
          </div>
        </div>
      </div>
    </FlexGridItem>
  );
};

export const InNetworkDisplay = () => {
  const classes = useNetworkCheckStatusStyles();
  return (
    <FlexGridItem>
      <div className={classes.oonStatusContainer}>
        <div className={classes.iconContainer}>
          <CheckCircleIcon className={classes.successIcon} />
        </div>
        <div className={classes.verticallyCenteredContainer}>
          <div className={classes.verticallyCenteredItem}>
            <Body2 className={classes.oonStatusText}>Network check complete</Body2>
          </div>
        </div>
      </div>
    </FlexGridItem>
  );
};

interface OONDisplayProps {
  unknownDisplayText: string;
}

export const UnknownNetworkDisplay = ({ unknownDisplayText }: OONDisplayProps) => {
  const { spacing } = useTheme();
  const classes = useNetworkCheckStatusStyles();
  return (
    <FlexGridItem container>
      <Grid item xs={12}>
        <div
          className={classes.unknownStatusContainer}
          style={{
            marginBottom: spacing(-1),
            marginTop: spacing(-0.25),
          }}
        >
          <QuestionIcon className={classes.unknownIcon} />
          <div className={classes.verticallyCenteredContainer}>
            <div className={classes.verticallyCenteredItem}>
              <Body2 className={classes.unknownStatusText}>{unknownDisplayText}</Body2>
            </div>
          </div>
        </div>
      </Grid>
    </FlexGridItem>
  );
};

export default NetworkCheckStatusDisplay;
