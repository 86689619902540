import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import { useSnackbar } from "notistack";

import {
  PrimaryButton,
  FullPageLayout,
  ScrollableMainPanel,
  useFeature,
  useStableUniqueId,
  FullPageBanner,
  H2,
  H6,
  PaginateResults,
  useParsedQueryString,
  useSyncQueryParamsToPath,
  useMuiContainerStyles,
  H1,
  colorsDark,
  ScrollSideRail,
  InlineButton,
  Body1,
} from "@coherehealth/common";
import {
  useServiceRequestSearch,
  useGetServiceRequestCounts,
  useFindNewEmailVerifiedOrgUsers,
  ServiceRequestSearchResponse,
  ServiceRequestSearchRequestBody,
  useGetUserById,
} from "@coherehealth/core-platform-api";

import { isMobileOnly } from "react-device-detect";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
import MuiDivider from "@material-ui/core/Divider";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, useTheme } from "@material-ui/core/styles";

import { appHeaderHeight, headerHeight, leftRailWidth } from "util/StyleConstants";
import HeaderContainer from "../AppHeader/HeaderContainer";
import PatientSelectionModal from "./PatientSelectionModal/PatientSelectionModal";
import ServiceRequestFilter from "./ServiceRequestFilter";
import ServiceRequestSearchBar from "./ServiceRequestSearchBar";
import ServiceRequestSearchByUser from "./ServiceRequestSearchByUser";
import debounce from "lodash/debounce";
import trim from "lodash/trim";

import useShallowCompareEffect from "react-use/lib/useShallowCompareEffect";
import { useAuthorized } from "authorization";
import { isBackOfficeWithoutOrganization } from "util/user";
import MobileDashboard from "./MobileDashboard";

import ServiceRequestCardList from "./ServiceRequestCardList";
import ServiceRequestSort from "./ServiceRequestSort";
import { UserContext } from "UserContext";
import NewMembersModal from "./NewMembersModal";
import BuildingIconInfo from "components/images/BuildingIconInfo";
import { useNavigate } from "react-router-dom";
import routes from "routes";
import { error as logError } from "logger";
import { Box } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import { useHealthPlanDisplayNameContext } from "../../util/context/HealthPlanDisplayNameContext";
import ClipboardIcon from "components/images/ClipboardIcon";
import { usePatientContext } from "../../util/context/PatientContext";

const useStyles = makeStyles((theme) => ({
  workflowButton: {
    marginLeft: theme.spacing(2),
  },
  workflowButtonText: {
    fontSize: theme.spacing(2),
    fontFamily: "Gilroy-SemiBold",
    textDecoration: "none",
    paddingTop: theme.spacing(0.5),
  },
}));

const RESULTS_PER_PAGE = [
  { id: "5", label: "5" },
  { id: "10", label: "10" },
  { id: "20", label: "20" },
  { id: "30", label: "30" },
];

function DashboardPage() {
  const [modalOpen, setModalOpen] = useState(false);
  const canCreateCarePathJourney = useAuthorized("START_AUTH_REQUEST");
  const mobileViewEnabled = useFeature("mobileDashboardViewable");
  const canSeeClaimsSRs = useAuthorized("EDIT_SERVICE_REQUEST_STATUS");
  const canViewNewEmailVerifiedUsers = useAuthorized("VIEW_NEW_EMAIL_VERIFIED_USERS");
  const showAdminAutoVerifiedModal = useFeature("displayBackOfficeAdminEmailAutoVerifiedModal");
  const coBrandingPayerLogosFF = useFeature("cobrandingPayerLogos");
  const navigate = useNavigate();
  const classes = useStyles();
  const returnUrl = document.referrer;
  let pathname = "";
  if (returnUrl !== "") {
    pathname = new URL(returnUrl).pathname;
  }
  const [newlyEmailAutoVerifiedUsersExist, setNewlyEmailAutoVerifiedUsersExist] = useState(false);
  const { getUser } = useContext(UserContext);
  const [userId, setUserId] = useState<string>();
  const [userIsBackofficeWithoutOrg, setUserIsBackofficeWithoutOrg] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const availityOnboardingFeatureOn = useFeature("availityOnboarding");
  const referralManagementFF = useFeature("referralManagement");

  const { data: newEmailVerifiedOrgUsers, refetch: refetchNewEmailVerifiedOrgUsers } = useFindNewEmailVerifiedOrgUsers({
    lazy: true,
    id: userId || "",
  });

  const {
    loading: fetchUserDetailsLoading,
    error: fetchUserDetailsError,
    data: user,
    refetch: fetchUserDetails,
  } = useGetUserById({
    id: "",
    lazy: true,
    queryParams: {
      includeOrganizationName: true,
    },
  });

  if (canViewNewEmailVerifiedUsers && showAdminAutoVerifiedModal && pathname === "/implicit/callback") {
    getUser()?.then((user) => setUserId(user.sub || ""));
  }

  useEffect(() => {
    if (userId) {
      refetchNewEmailVerifiedOrgUsers();
    }
  }, [userId, refetchNewEmailVerifiedOrgUsers]);

  useEffect(() => {
    if (newEmailVerifiedOrgUsers && newEmailVerifiedOrgUsers.userIds) {
      if (newEmailVerifiedOrgUsers.userIds.length > 0) {
        setNewlyEmailAutoVerifiedUsersExist(true);
      }
    }
  }, [newEmailVerifiedOrgUsers]);

  useEffect(() => {
    getUser()?.then((user) => {
      if (user) {
        setUserIsBackofficeWithoutOrg(isBackOfficeWithoutOrganization(user));
        if (user.sub) {
          fetchUserDetails({ pathParams: { id: user?.sub } });
        }
      }
    });
  }, [getUser, fetchUserDetails]);

  const userHasOrganization = !!user?.organization; // Return boolean value if user is not null
  const userHasPendingOrganization = !!user?.pendingOrganizationId; // Return boolean value if user is not null
  // const userPendingOrganizationName = user?.pendingOrganizationName ? user.pendingOrganizationName : ""; // fix once we add the rejected user workflow

  const qs = useParseDashboardQueryString();

  // Initializes default values for the form state.
  // Custom fields found in the URL will overwrite the defaults.
  const initQuery: ServiceRequestSearchRequestBody = {
    offset: 0,
    max: Number(RESULTS_PER_PAGE[2].id) + 1,
    sort: "lastUpdated:desc",
    ...qs,
  };
  const [query, setQuery] = useState<ServiceRequestSearchRequestBody>(initQuery);
  const displayMax = query.max ? query.max - 1 : Number(RESULTS_PER_PAGE[2].id);

  useSyncQueryParamsToPath(query);

  const [serviceRequests, setServiceRequests] = useState<ServiceRequestSearchResponse[]>();
  const [viewableServiceRequests, setViewableServiceRequests] = useState<ServiceRequestSearchResponse[]>();
  const [selectedHealthPlan, setSelectedHealthPlan] = useState("allHealthPlan");

  const {
    loading: serviceRequestSearchLoading,
    error: serviceRequestSearchError,
    mutate: serviceRequestSearch,
  } = useServiceRequestSearch({
    onMutate: (prevQuery, data) => {
      setServiceRequests(data);
      queryStable.current = prevQuery;
    },
  });

  const queryParams = { viewRecordsReview: canSeeClaimsSRs?.toString() } as {
    viewRecordsReview: string;
    healthPlanName?: string;
  };

  if (coBrandingPayerLogosFF && selectedHealthPlan !== "allHealthPlan") {
    queryParams.healthPlanName = selectedHealthPlan;
  }
  const { data: serviceRequestCounts, refetch: refetchServiceRequestCounts } = useGetServiceRequestCounts({
    queryParams: queryParams,
    lazy: true,
  });

  const containerClasses = useMuiContainerStyles();
  const radioId = useStableUniqueId();
  const theme = useTheme();

  useEffect(() => {
    if (serviceRequestSearchError) {
      logError(serviceRequestSearchError);
      enqueueSnackbar("Error loading authorizations. Failed to fetch.", {
        variant: "error",
        preventDuplicate: true,
      });
    }
    if (fetchUserDetailsError) {
      console.warn(
        "User fetch failed: this can happen on initial login and is not necessarily an error",
        fetchUserDetailsError
      );
    }
  }, [serviceRequestSearchError, fetchUserDetailsError, enqueueSnackbar]);

  // A 'snapshot' of query at the time the Service Requests are fetched
  const queryStable = useRef(query);

  const delayedFetchServiceRequests = useRef(
    debounce((q: ServiceRequestSearchRequestBody) => {
      serviceRequestSearch(q).catch((err) => {
        if (err.message !== "Failed to fetch: Aborted") {
          throw err;
        }
      });
    }, 800)
  ).current;

  useEffect(() => {
    refetchServiceRequestCounts();
  }, [canSeeClaimsSRs, refetchServiceRequestCounts]);

  const showPendingVerificationBanner = userIsBackofficeWithoutOrg && Boolean(user) && userHasPendingOrganization;

  const needsOrgBanner =
    availityOnboardingFeatureOn &&
    userIsBackofficeWithoutOrg &&
    Boolean(user) &&
    !userHasOrganization &&
    !showPendingVerificationBanner;

  const redirectionUrl = `${routes.AUTHENTICATED_ORGANIZATION_ONBOARDING}?returnTo=${routes.DASHBOARD}&skipSplash=true`;
  const progressBarLoading = serviceRequestSearchLoading || fetchUserDetailsLoading;
  const isAuthViewOnlyUser = useAuthorized("AUTH_VIEW_ONLY");

  useShallowCompareEffect(() => {
    if (query.max === undefined) {
      return;
    }
    let actualQueryString = trim(query.query).replace(/\s+/g, " ");
    delayedFetchServiceRequests({ ...query, query: actualQueryString });
    if (query.query === "") {
      delayedFetchServiceRequests.flush();
    }
    return () => {
      delayedFetchServiceRequests.cancel();
    };
  }, [delayedFetchServiceRequests, query, canSeeClaimsSRs]);

  // Show service requests based on user roles.
  useEffect(() => {
    isAuthViewOnlyUser
      ? setViewableServiceRequests(
          serviceRequests
            ?.filter((sr) => sr.authStatus !== "DRAFT")
            .map((sr) => ({ ...sr, endDate: !sr.endDate ? sr.authValidityWindow?.validityWindowEndDate : sr.endDate }))
        )
      : setViewableServiceRequests(
          serviceRequests
            ?.filter((sr) => !sr.reconClaim || canSeeClaimsSRs)
            .map((sr) => ({ ...sr, endDate: !sr.endDate ? sr.authValidityWindow?.validityWindowEndDate : sr.endDate }))
        );
  }, [canSeeClaimsSRs, isAuthViewOnlyUser, serviceRequests]);

  const onServiceRequestCardListEdit = useCallback(() => {
    delayedFetchServiceRequests(queryStable.current);
    delayedFetchServiceRequests.flush();
    refetchServiceRequestCounts();
  }, [delayedFetchServiceRequests, refetchServiceRequestCounts]);

  //clear out patient data in context to ensure stale data is not maintained (user navigated to patient summary and then back to dashboard)
  const { setPatientData } = usePatientContext();
  useEffect(() => {
    setPatientData(undefined);
  }, [setPatientData]);

  //This sets the current health plan name, which is used for logo cobranding within the AppHeader
  //Setting to empty string effectively sets the logo within AppHeader to be Cohere's standard logo
  const { setHealthPlanDisplayNameContext } = useHealthPlanDisplayNameContext();
  useEffect(() => {
    setHealthPlanDisplayNameContext(selectedHealthPlan === "allHealthPlan" ? "" : selectedHealthPlan);
  }, [selectedHealthPlan, setHealthPlanDisplayNameContext]);

  if (isMobileOnly && mobileViewEnabled) {
    return <MobileDashboard />;
  }

  return (
    <>
      {!!referralManagementFF && (
        <Helmet>
          <title>Cohere | Authorizations</title>
        </Helmet>
      )}
      <Container classes={containerClasses} maxWidth="lg" data-testid="dashboard-page">
        <PatientSelectionModal open={modalOpen} onClose={() => setModalOpen(false)} />
        {newlyEmailAutoVerifiedUsersExist &&
          canViewNewEmailVerifiedUsers &&
          newEmailVerifiedOrgUsers &&
          newEmailVerifiedOrgUsers.userIds && (
            <NewMembersModal
              newMembers={newEmailVerifiedOrgUsers.userIds.length}
              newMembersModalOpen={newlyEmailAutoVerifiedUsersExist}
              setNewMembersModalOpen={setNewlyEmailAutoVerifiedUsersExist}
            />
          )}
        <HeaderContainer height={headerHeight}>
          {!!referralManagementFF ? (
            <Title data-public>
              <Box style={{ display: "flex", paddingBottom: theme.spacing(0.5) }}>
                <ClipboardIcon />
                <H2 style={{ margin: theme.spacing(0.8125, 0, 0.9375, 1) }}>Authorizations</H2>
                <InlineButton
                  className={classes.workflowButton}
                  onClick={() => (window.location.href = routes.REFERRAL_DASHBOARD)}
                >
                  <Body1 className={classes.workflowButtonText}>View referrals</Body1>
                </InlineButton>
              </Box>
            </Title>
          ) : (
            <Title data-public>
              <H1>Dashboard</H1>
            </Title>
          )}
          {canCreateCarePathJourney && (
            <PrimaryButton
              style={{ height: theme.spacing(6.5), alignSelf: "center" }}
              onClick={() => setModalOpen(true)}
              data-public
            >
              Start auth request
            </PrimaryButton>
          )}
        </HeaderContainer>
        <ProgressContainer>{progressBarLoading && <LinearProgress />}</ProgressContainer>
        {needsOrgBanner && (
          <BannerContainer>
            {!userHasOrganization && !userHasPendingOrganization && (
              <FullPageBanner
                bannerIcon={<BuildingIconInfo />}
                backgroundColor={colorsDark.info.dark}
                padding={theme.spacing(2)}
                buttonLabel="Verify my account"
                buttonProps={{
                  info: true,
                  onClick: () => {
                    navigate(redirectionUrl);
                  },
                  // @ts-ignore
                  "data-tracking-id": "verify-my-account-banner",
                }}
              >
                <H2 style={{ paddingBottom: theme.spacing(1) }}>Verify your account to streamline your auth process</H2>
                <H6>See auths started and submitted by everyone on your team</H6>
              </FullPageBanner>
            )}
          </BannerContainer>
        )}
        {showPendingVerificationBanner && (
          <BannerContainer>
            <FullPageBanner
              bannerIcon={<BuildingIconInfo />}
              backgroundColor={colorsDark.info.dark}
              padding={theme.spacing(2)}
            >
              <H2 style={{ paddingBottom: theme.spacing(1) }}>
                Your account is pending verification by your organization’s administrator
              </H2>
              <H6>
                Once your account is verified, you’ll be able to see authorizations created and submitted by everyone on
                your team
              </H6>
            </FullPageBanner>
          </BannerContainer>
        )}
        <FullPageLayout
          headerHeight={needsOrgBanner || showPendingVerificationBanner ? 0 : headerHeight - theme.spacing(1)}
        >
          <ScrollSideRail
            sideRailWidth={leftRailWidth}
            columnSpacingRight={5}
            verticalHeightAroundSideRail={appHeaderHeight() + headerHeight - theme.spacing(1)}
            hasHeader
          >
            <ServiceRequestSearchByUser queryParams={query} setQueryParams={setQuery} />
            <Divider />
            <ServiceRequestFilter
              radioId={radioId}
              queryParams={query}
              setQueryParams={setQuery}
              serviceRequestCounts={serviceRequestCounts?.summary || {}}
              selectedHealthPlan={selectedHealthPlan}
              setSelectedHealthPlan={setSelectedHealthPlan}
              coBrandingPayerLogosFF={coBrandingPayerLogosFF}
            />
          </ScrollSideRail>
          <ScrollableMainPanel sideRailWidth={leftRailWidth}>
            <ServiceRequestSearchAndResults>
              <ServiceRequestSearchBar queryParams={query} setQueryParams={setQuery} />
              <SortRow data-public>
                <ServiceRequestSort queryParams={query} setQueryParams={setQuery} />
              </SortRow>
              <ServiceRequestCardList
                displayMax={displayMax}
                loading={serviceRequestSearchLoading}
                error={!!serviceRequestSearchError}
                viewableServiceRequests={viewableServiceRequests}
                onEdit={onServiceRequestCardListEdit}
                onDeleteDraft={onServiceRequestCardListEdit}
                user={user}
              />
              <PaginateResults
                paginateParams={query}
                count={serviceRequests?.length || 0}
                setPaginateParams={setQuery}
                displayMax={displayMax}
                RESULTS_PER_PAGE={RESULTS_PER_PAGE}
              />
            </ServiceRequestSearchAndResults>
          </ScrollableMainPanel>
        </FullPageLayout>
      </Container>
    </>
  );
}

export default DashboardPage;

function useParseDashboardQueryString(): Partial<ServiceRequestSearchRequestBody> {
  const qs = useParsedQueryString();
  return {
    ...qs,
    // For anything whose type is numerical, try to parse it out as a number
    ...(isntBlank(qs.offset) ? { offset: Number(qs.offset) } : {}),
    ...(isntBlank(qs.max) ? { max: Number(qs.max) } : {}),
  };
}

type ParsedQueryValue = ReturnType<typeof useParsedQueryString> extends Record<any, infer T> ? T : never;
function isntBlank(ipt: ParsedQueryValue): boolean {
  if (ipt === undefined) {
    return false;
  }
  if (ipt === "") {
    return false;
  }

  return true;
}

// Header components
// eslint-disable-next-line cohere-react/no-mui-styled-import
const Title = styled("div")({
  display: "flex",
  alignItems: "center",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ProgressContainer = styled("div")(() => ({
  position: "fixed",
  top: headerHeight + appHeaderHeight(),
  left: 0,
  right: 0,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const BannerContainer = styled("div")(({ theme }) => ({
  paddingTop: headerHeight + theme.spacing(4),
  marginBottom: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ServiceRequestSearchAndResults = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  marginTop: theme.spacing(4),
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const SortRow = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Divider = styled(MuiDivider)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));
