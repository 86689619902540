import {
  useFeature,
  incrementClickCount,
  H5,
  UserClickInfoClinicalReviewPage,
  Subtitle2,
  parseDateFromISOString,
} from "@coherehealth/common";
import { CircularProgress, Divider, Grid, useTheme } from "@material-ui/core";
import { useAuthorized } from "authorization";
import ClinicalReviewCard from "common/ClinicalReviewCard";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { StyledBody1 } from "./ServiceRequestInformation";
import {
  AuthorizationResponse,
  ReviewType,
  ServiceRequestResponse,
  useGetAuthorizationReviews,
} from "@coherehealth/core-platform-api";
import { sum } from "lodash";
import { useSnackbar } from "notistack";
import ListOfReviews from "./ListOfReviews";
import { buildSrGroupingTitle } from "./infoPanelUtils";

interface Props {
  authorization?: AuthorizationResponse;
  existingReviews: ReviewType[] | null;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  isDenialReviewPage?: boolean;
  currServiceRequestID?: string;
}

const AuthorizationReviewInformation = ({
  authorization,
  existingReviews,
  setUserClickInfoTracking,
  isDenialReviewPage = false,
  currServiceRequestID,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(isDenialReviewPage);
  const [srReviewsRecord, setSrReviewsRecord] = useState<Record<string, ReviewType[]>>({});
  const [reviewQuantity, setReviewQuantity] = useState<number>(0);
  const sortedSrs = authorization?.serviceRequestsOnAuth?.sort(
    (a: ServiceRequestResponse, b: ServiceRequestResponse) => {
      return parseDateFromISOString(b.dateCreated).getTime() - parseDateFromISOString(a.dateCreated).getTime();
    }
  );
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const canEditOutOfNetworkReview = useAuthorized("EDIT_OUT_OF_NETWORK_REVIEW");
  const auditReviewsAllowed = useFeature("auditReviews");
  const canAuditReview = useAuthorized("AUDIT_REVIEW") && auditReviewsAllowed;

  const {
    data: reviews,
    loading: reviewsLoading,
    error: authReviewsFetchError,
    refetch: fetchAuthReviews,
  } = useGetAuthorizationReviews({
    authorizationId: authorization?.id || "",
    lazy: true,
  });

  const generateSRReviews = useCallback(
    (reviews: ReviewType[]): Record<string, ReviewType[]> => {
      const record: Record<string, ReviewType[]> = {};
      reviews?.forEach((review: ReviewType) => {
        if (
          review?.serviceRequest?.id &&
          review?.reviewStatus !== "DRAFT" &&
          (canAuditReview || review.reviewType !== "AuditReview") &&
          (canEditOutOfNetworkReview || review.reviewType !== "OutOfNetworkReview")
        ) {
          const srReviews = record[review.serviceRequest.id];
          if (srReviews) {
            record[review.serviceRequest.id] = [...srReviews, review];
          } else {
            record[review.serviceRequest.id] = [review];
          }
        }
      });
      return record;
    },
    [canAuditReview, canEditOutOfNetworkReview]
  );

  useEffect(() => {
    if (authorization?.id) {
      fetchAuthReviews();
    }
  }, [authorization?.id, fetchAuthReviews]);

  useEffect(() => {
    const reviewsWithFallback = reviews?.length ? reviews : existingReviews;
    if (reviewsWithFallback) {
      const srReviewsRecord: Record<string, ReviewType[]> = generateSRReviews(reviewsWithFallback);
      setSrReviewsRecord(srReviewsRecord);
      const reviewQuantity = sum(Object.entries(srReviewsRecord).map(([srId, srReviews]) => srReviews.length));
      setReviewQuantity(reviewQuantity);
    }
  }, [existingReviews, generateSRReviews, reviews, reviews?.length]);

  useEffect(() => {
    if (authReviewsFetchError) {
      enqueueSnackbar(`Failed to fetch Authorization reviews: ${authReviewsFetchError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, authReviewsFetchError]);

  const updateUserClickInfo = () => {
    setUserClickInfoTracking && incrementClickCount("ReviewsCardToggle", "", -1, setUserClickInfoTracking);
  };

  const loading = !authorization?.id || reviewsLoading;

  return (
    <ClinicalReviewCard
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      updateUserClickInfo={updateUserClickInfo}
      header={
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: 6 }}>
            <H5>Reviews</H5>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <CircularProgress size={16} />
            ) : (
              <StyledBody1>
                {reviewQuantity} previous review{reviewQuantity !== 1 && "s"}
              </StyledBody1>
            )}
          </Grid>
        </Grid>
      }
    >
      {isExpanded && (
        <Grid container direction="column" spacing={2}>
          {!!reviewQuantity && sortedSrs ? (
            sortedSrs.map((sr, idx) => {
              return (
                <React.Fragment key={`${sr.id}_${idx}`}>
                  {srReviewsRecord[sr.id] && (
                    <Grid container direction="column" style={{ padding: "8px" }}>
                      <Grid item>
                        <Subtitle2 style={{ color: theme.palette.info.light }}>{buildSrGroupingTitle(sr)}</Subtitle2>
                      </Grid>
                      <ListOfReviews
                        serviceRequest={sr}
                        currServiceRequestID={currServiceRequestID}
                        reviews={srReviewsRecord[sr.id]}
                        newDenialsWorkflow={true}
                        clinicalReviewView
                        buttonPadding={"8px 0px"}
                        authorizationReviewView={true}
                        reviewDetailsCollapseStyle={{ margin: 0 }}
                        isDenialReviewPage={isDenialReviewPage}
                      />
                      {idx !== sortedSrs.length - 1 && (
                        <Grid item>
                          <Divider />
                        </Grid>
                      )}
                    </Grid>
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2.5),
              }}
            >
              {loading ? <CircularProgress /> : <StyledBody1>No previous reviews for this Service Request</StyledBody1>}
            </Grid>
          )}
        </Grid>
      )}
    </ClinicalReviewCard>
  );
};

export default AuthorizationReviewInformation;
