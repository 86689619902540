import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";

import {
  getAttributeInObjectInLocalStorage,
  getFromLocalStorage,
  overwriteAttributeOnObjectInLocalStorage,
} from "util/localStorage";

import { FacilityOrAgencyOrProviderType } from "./types";
import { useGetProviderOrFacility } from "@coherehealth/core-platform-api";
import { useEffect, useState } from "react";
import { error as logError } from "logger";

import { parseDateFromISOString } from "@coherehealth/common";
import { ReferralRequestFormContent } from "components/ReferralManagement/RequestBuilder/ReferralRequestForm";

// * File tag RECENT_PROVIDERS_8B12CDB1

// Local Storage functionality for the Recents components

function doesBucketExistInRecentsInLocalStorage(
  recentProviderOrFacilityOrAgency: FacilityOrAgencyOrProviderType,
  key: string,
  healthPlanName: string
) {
  const bucket: Array<FacilityOrAgencyOrProviderType | null> | null = getAttributeInObjectInLocalStorage(
    getRecentProvidersKey(healthPlanName),
    key
  );
  return bucket
    ?.filter((recent): recent is FacilityOrAgencyOrProviderType => {
      return recent !== null;
    })
    ?.some((recent) => recent.id === recentProviderOrFacilityOrAgency.id);
}

function removeExpiredProviders(key: string, healthPlanName: string) {
  const bucket: Array<FacilityOrAgencyOrProviderType | null> | null = getAttributeInObjectInLocalStorage(
    getRecentProvidersKey(healthPlanName),
    key
  );
  const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  const idsToRemove: string[] = [];
  bucket?.forEach((recent): recent is FacilityOrAgencyOrProviderType => {
    const currentDate = new Date();
    let objectDate = recent?.thisObjectCreatedAt;
    // Determines if object in recent providers is older than a week.
    // If so, overwrite it.
    if (objectDate) {
      if (typeof objectDate === "string") {
        objectDate = parseDateFromISOString(objectDate);
      }
      const timeDifference = currentDate.getTime() - objectDate.getTime();
      if (timeDifference >= oneWeekInMilliseconds) {
        if (recent && recent.id) {
          idsToRemove.push(recent.id);
        }
      }
    } else {
      if (recent && recent.id) {
        idsToRemove.push(recent.id);
      }
    }
    return true;
  });
  if (idsToRemove.length > 0) {
    idsToRemove.forEach((recentsId: string) => {
      removeProviderFromStorage(recentsId, healthPlanName, key);
    });
  }
}

function makeNewBucket(serviceRequest: ServiceRequestFormContent, bucketName: string, healthPlan: string) {
  if (bucketName === "PerformingFacilityOrAgency") {
    // * Clear any out of network business logic.
    if (serviceRequest.facilitySelectedAddress) {
      serviceRequest.facilitySelectedAddress.isOutOfNetwork = false;
      delete serviceRequest.facilitySelectedAddress.outOfNetworkExceptionComment;
      delete serviceRequest.facilitySelectedAddress.outOfNetworkExceptionReason;
    }
    if (serviceRequest.selectedFacility && serviceRequest.selectedFacility.selectedLocation) {
      serviceRequest.selectedFacility.selectedLocation.isOutOfNetwork = false;
      delete serviceRequest.selectedFacility.selectedLocation.outOfNetworkExceptionComment;
      delete serviceRequest.selectedFacility.selectedLocation.outOfNetworkExceptionReason;
    }
    // * Create the object.
    const result = {
      facility: serviceRequest.facility,
      facilitySelectedTin: serviceRequest.facilitySelectedTin,
      facilitySelectedAddress: serviceRequest.facilitySelectedAddress,
      selectedFacility: serviceRequest.selectedFacility,
      name: serviceRequest.selectedFacility?.name,
      id: serviceRequest.selectedFacility?.id,
      thisObjectCreatedAt: new Date(),
    };
    const alreadyExists = doesBucketExistInRecentsInLocalStorage(result, bucketName, healthPlan);
    return [result, alreadyExists];
  } else if (bucketName === "OrderingProvider") {
    // * Clear any out of network business logic.
    if (serviceRequest.orderingProviderSelectedAddress) {
      serviceRequest.orderingProviderSelectedAddress.isOutOfNetwork = false;
      delete serviceRequest.orderingProviderSelectedAddress.outOfNetworkExceptionComment;
      delete serviceRequest.orderingProviderSelectedAddress.outOfNetworkExceptionReason;
    }
    if (serviceRequest.selectedOrderingProvider && serviceRequest.selectedOrderingProvider.selectedLocation) {
      serviceRequest.selectedOrderingProvider.selectedLocation.isOutOfNetwork = false;
      delete serviceRequest.selectedOrderingProvider.selectedLocation.outOfNetworkExceptionComment;
      delete serviceRequest.selectedOrderingProvider.selectedLocation.outOfNetworkExceptionReason;
    }

    const result = {
      orderingProvider: serviceRequest.selectedOrderingProvider,
      orderingProviderSelectedTin: serviceRequest.orderingProviderSelectedTin,
      orderingProviderSelectedAddress: serviceRequest.orderingProviderSelectedAddress,
      selectedOrderingProvider: serviceRequest.selectedOrderingProvider,
      name: serviceRequest.selectedOrderingProvider?.name,
      id: serviceRequest.selectedOrderingProvider?.id,
      thisObjectCreatedAt: new Date(),
    };
    const alreadyExists = doesBucketExistInRecentsInLocalStorage(result, bucketName, healthPlan);
    return [result, alreadyExists];
  } else if (bucketName === "PerformingProvider") {
    // * Clear any out of network business logic.
    if (serviceRequest.performingProviderSelectedAddress) {
      serviceRequest.performingProviderSelectedAddress.isOutOfNetwork = false;
      delete serviceRequest.performingProviderSelectedAddress.outOfNetworkExceptionComment;
      delete serviceRequest.performingProviderSelectedAddress.outOfNetworkExceptionReason;
    }
    if (serviceRequest.selectedPerformingProvider && serviceRequest.selectedPerformingProvider.selectedLocation) {
      serviceRequest.selectedPerformingProvider.selectedLocation.isOutOfNetwork = false;
      delete serviceRequest.selectedPerformingProvider.selectedLocation.outOfNetworkExceptionComment;
      delete serviceRequest.selectedPerformingProvider.selectedLocation.outOfNetworkExceptionReason;
    }
    const result = {
      performingProvider: serviceRequest.selectedPerformingProvider,
      performingProviderSelectedTin: serviceRequest.performingProviderSelectedTin,
      performingProviderSelectedAddress: serviceRequest.performingProviderSelectedAddress,
      selectedPerformingProvider: serviceRequest.selectedPerformingProvider,
      name: serviceRequest.selectedPerformingProvider?.name,
      id: serviceRequest.selectedPerformingProvider?.id,
      thisObjectCreatedAt: new Date(),
    };
    const alreadyExists = doesBucketExistInRecentsInLocalStorage(result, bucketName, healthPlan);
    return [result, alreadyExists];
  }
  return [{}, true];
}

function makeNewReferralBucket(referralRequest: ReferralRequestFormContent, bucketName: string, healthPlan: string) {
  if (bucketName === "ReferringProvider") {
    // * Clear any out of network business logic.
    if (referralRequest.referringProviderSelectedAddress) {
      referralRequest.referringProviderSelectedAddress.isOutOfNetwork = false;
      delete referralRequest.referringProviderSelectedAddress.outOfNetworkExceptionComment;
      delete referralRequest.referringProviderSelectedAddress.outOfNetworkExceptionReason;
    }
    if (referralRequest.selectedReferringProvider && referralRequest.selectedReferringProvider.selectedLocation) {
      referralRequest.selectedReferringProvider.selectedLocation.isOutOfNetwork = false;
      delete referralRequest.selectedReferringProvider.selectedLocation.outOfNetworkExceptionComment;
      delete referralRequest.selectedReferringProvider.selectedLocation.outOfNetworkExceptionReason;
    }
    // * Create the object.
    const result = {
      referringProviderSelectedTin: referralRequest.referringProviderSelectedTin,
      referringProviderSelectedAddress: referralRequest.referringProviderSelectedAddress,
      selectedReferringProvider: referralRequest.selectedReferringProvider,
      name: referralRequest.selectedReferringProvider?.name,
      id: referralRequest.selectedReferringProvider?.id,
      thisObjectCreatedAt: new Date(),
    };
    const alreadyExists = doesBucketExistInRecentsInLocalStorage(result, bucketName, healthPlan);
    return [result, alreadyExists];
  } else if (bucketName === "SpecialistFacility") {
    // * Clear any out of network business logic.
    if (referralRequest.facilitySelectedAddress) {
      referralRequest.facilitySelectedAddress.isOutOfNetwork = false;
      delete referralRequest.facilitySelectedAddress.outOfNetworkExceptionComment;
      delete referralRequest.facilitySelectedAddress.outOfNetworkExceptionReason;
    }
    if (referralRequest.selectedFacility && referralRequest.selectedFacility.selectedLocation) {
      referralRequest.selectedFacility.selectedLocation.isOutOfNetwork = false;
      delete referralRequest.selectedFacility.selectedLocation.outOfNetworkExceptionComment;
      delete referralRequest.selectedFacility.selectedLocation.outOfNetworkExceptionReason;
    }
    // * Create the object.
    const result = {
      facilitySelectedTin: referralRequest.facilitySelectedTin,
      facilitySelectedAddress: referralRequest.facilitySelectedAddress,
      selectedFacility: referralRequest.selectedFacility,
      name: referralRequest.selectedFacility?.name,
      id: referralRequest.selectedPerformingSpecialist?.id,
      thisObjectCreatedAt: new Date(),
    };
    const alreadyExists = doesBucketExistInRecentsInLocalStorage(result, bucketName, healthPlan);
    return [result, alreadyExists];
  } else if (bucketName === "PerformingSpecialist") {
    // * Clear any out of network business logic.
    if (referralRequest.performingSpecialistSelectedAddress) {
      referralRequest.performingSpecialistSelectedAddress.isOutOfNetwork = false;
      delete referralRequest.performingSpecialistSelectedAddress.outOfNetworkExceptionComment;
      delete referralRequest.performingSpecialistSelectedAddress.outOfNetworkExceptionReason;
    }
    if (referralRequest.selectedPerformingSpecialist && referralRequest.selectedPerformingSpecialist.selectedLocation) {
      referralRequest.selectedPerformingSpecialist.selectedLocation.isOutOfNetwork = false;
      delete referralRequest.selectedPerformingSpecialist.selectedLocation.outOfNetworkExceptionComment;
      delete referralRequest.selectedPerformingSpecialist.selectedLocation.outOfNetworkExceptionReason;
    }
    // * Create the object
    const result = {
      performingSpecialistSelectedTin: referralRequest.performingSpecialistSelectedTin,
      performingSpecialistSelectedAddress: referralRequest.performingSpecialistSelectedAddress,
      selectedPerformingSpecialist: referralRequest.selectedPerformingSpecialist,
      name: referralRequest.selectedPerformingSpecialist?.name,
      id: referralRequest.selectedPerformingSpecialist?.id,
      thisObjectCreatedAt: new Date(),
    };
    const alreadyExists = doesBucketExistInRecentsInLocalStorage(result, bucketName, healthPlan);
    return [result, alreadyExists];
  }
  return [{}, true];
}

export function updateLocalStorageRecentsForReferral(
  referralRequestFormContent: ReferralRequestFormContent,
  healthPlanName: string
) {
  const referralRequest = referralRequestFormContent;
  if (!referralRequest) {
    return undefined;
  }

  const bucketNames = ["ReferringProvider", "SpecialistFacility", "PerformingSpecialist"];

  for (let i = 0; i < bucketNames.length; i++) {
    const bucketName = bucketNames[i];
    removeExpiredProviders(bucketName, healthPlanName);
    const [newProvider, newProviderAlreadyExists] = makeNewReferralBucket(referralRequest, bucketName, healthPlanName);
    const currentProvider = getAttributeInObjectInLocalStorage(getRecentProvidersKey(healthPlanName), bucketName);
    if (!newProviderAlreadyExists) {
      overwriteAttributeOnObjectInLocalStorage(getRecentProvidersKey(healthPlanName), bucketName, [
        newProvider,
        currentProvider && currentProvider[0],
        currentProvider && currentProvider[1],
      ]);
    }
  }
}

export function updateLocalStorageRecents(
  serviceRequestFormContents: ServiceRequestFormContent[],
  healthPlanName: string
) {
  const serviceRequest = serviceRequestFormContents[0];
  if (!serviceRequest) {
    return undefined;
  }

  const bucketNames = ["OrderingProvider", "PerformingProvider", "PerformingFacilityOrAgency"];

  for (let i = 0; i < bucketNames.length; i++) {
    const bucketName = bucketNames[i];
    removeExpiredProviders(bucketName, healthPlanName);
    const [newProvider, newProviderAlreadyExists] = makeNewBucket(serviceRequest, bucketName, healthPlanName);
    const currentProvider = getAttributeInObjectInLocalStorage(getRecentProvidersKey(healthPlanName), bucketName);
    if (!newProviderAlreadyExists) {
      overwriteAttributeOnObjectInLocalStorage(getRecentProvidersKey(healthPlanName), bucketName, [
        newProvider,
        currentProvider && currentProvider[0],
        currentProvider && currentProvider[1],
      ]);
    }
  }
}

function removeProviderFromStorage(providerToRemoveId: string, healthPlanName: string, bucketName: string) {
  const currentProviders: Array<{ id?: string } | undefined> | null = getAttributeInObjectInLocalStorage(
    getRecentProvidersKey(healthPlanName),
    bucketName
  );
  const newProviders = currentProviders?.filter((currentProvider) => currentProvider?.id !== providerToRemoveId);

  // ensures that there is a list of size 3 in the recent providers local storage
  overwriteAttributeOnObjectInLocalStorage(getRecentProvidersKey(healthPlanName), bucketName, [
    newProviders && newProviders[0],
    newProviders && newProviders[1],
    newProviders && newProviders[2],
  ]);
}

export async function validateProviders(
  providerIds: string[],
  onValidId: (validId: string) => void,
  onInvalidId: (invalidId: string) => void,
  getProviderById: ReturnType<typeof useGetProviderOrFacility>["refetch"]
): Promise<void> {
  for (const id of providerIds) {
    try {
      const providerResponse = await getProviderById({ pathParams: { id } });
      if (providerResponse?.id === id) {
        onValidId(id);
      } else {
        onInvalidId(id);
      }
    } catch (e) {
      // error fetching this provider, mark as invalid
      onInvalidId(id);
    }
  }
  return Promise.resolve();
}

export function useValidRecentProviders(healthPlanName: string, bucketName: string): FacilityOrAgencyOrProviderType[] {
  const { refetch: getProviderById } = useGetProviderOrFacility({
    id: "",
    lazy: true,
  });

  const [validProviders, setValidProviders] = useState<FacilityOrAgencyOrProviderType[]>([]);

  useEffect(() => {
    getValidProvidersFromLocalStorage(healthPlanName, bucketName, getProviderById).then((validProvidersResult) => {
      setValidProviders(validProvidersResult);
    });
  }, [bucketName, getProviderById, healthPlanName]);

  return validProviders;
}

/**
 * This effect will query each provider id and verify that a valid response comes back.
 * Due to the way restful-react useGet hooks work, we need to call these sequentially.
 * In order to not pause rendering it is important that this logic remains in a useEffect hook.
 */
export async function getValidProvidersFromLocalStorage(
  healthPlanName: string,
  bucketName: string,
  getProviderById: ReturnType<typeof useGetProviderOrFacility>["refetch"]
): Promise<FacilityOrAgencyOrProviderType[]> {
  const recentProviders: Array<FacilityOrAgencyOrProviderType | null> = getFromLocalStorage(
    getRecentProvidersKey(healthPlanName)
  )?.[bucketName];
  const providerIds = recentProviders?.map((elem) => elem?.id)?.filter((id): id is string => !!id) || [];

  const validProviders: FacilityOrAgencyOrProviderType[] = [];
  // This is an async function call, anything in this frame after it will execute immediately while `validateProviders` makes api requests
  try {
    await validateProviders(
      providerIds,
      (validProviderId) => {
        const validRecentProvider = recentProviders.find((recentProvider) => recentProvider?.id === validProviderId);
        if (validRecentProvider) {
          validProviders.push(validRecentProvider);
        }
      },
      (invalidProviderId) => {
        removeProviderFromStorage(invalidProviderId, healthPlanName, bucketName);
      },
      getProviderById
    );
  } catch (e) {
    logError(e);
  }

  return validProviders;
}

export function getRecentProvidersKey(healthPlanName: string): string {
  return `RecentProviderDetails_${healthPlanName}`;
}
