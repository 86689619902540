import { Dispatch, useCallback, useContext, useEffect, useState } from "react";
import {
  useGetAssociateFaxToServiceRequest,
  ServiceRequestResponse,
  useCreateServiceRequestAttachment,
} from "@coherehealth/core-platform-api";

import { SelectedPatientInfo, useFeature, InlineButton } from "@coherehealth/common";
import { Patient } from "@coherehealth/core-platform-api";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { error as logError } from "logger";
import { useSnackbar } from "notistack";
import { generatePath, useNavigate } from "react-router";
import routes from "routes";
import listRemove from "util/listRemove";
import { getPatientHealthPlanName } from "util/patientUtils";
import { WORKFLOW_REFERER_PARAM, useFullLocationPath } from "util/queryParams";

import { FaxSidebarView } from "../common";
import { IsCohereTemplateContext } from "../FaxAttachmentSidebar";
import { FaxAttachmentContext } from "../FaxAttachmentContext";
import ServiceRequestSelectionCard from "./ServiceRequestSelectionCard";
import { useBelongsToOpsGroup } from "authorization";
import { ServiceCase } from "@coherehealth/qm-api";
import CommonFaxFooter from "../CommonFaxFooter";
import { useTrackUserInteraction, activityContextFromServiceRequest } from "util/userActivityTracker";
import { CircularProgress } from "@material-ui/core";

interface Props {
  serviceRequests: Array<ServiceRequestResponse> | null;
  initialAttachedServiceRequestIds: Array<string | undefined>;
  patient?: Patient;
  onCancel: () => void;
  onAllFileAttachmentsComplete: () => void;
  faxCohereId?: string;
  faxId?: string;
  showCreateNewServiceRequest?: boolean;
  setSidebarView: Dispatch<FaxSidebarView>;
  ocrServiceRequest?: ServiceRequestResponse;
  serviceCase?: ServiceCase | undefined | null;
  externalReferenceId?: string | undefined;
  healthPlanName?: string;
  setSelectedServiceId: Dispatch<string>;
  loadingServiceRequests: boolean;
}

const useStyles = makeStyles((theme) => ({
  serviceRequestResultsContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    paddingBottom: 72,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  fullWidthDivider: {
    margin: theme.spacing(4, -3, 3),
  },
  fullWidthBottomDivider: {
    margin: theme.spacing(3, -3, 0),
  },
  createServiceRequestBtn: {
    padding: theme.spacing(1),
  },
  scrollableResultList: {
    overflowY: "auto",
    overflowX: "hidden",
    flex: "1 1 0",
    padding: theme.spacing(2, 3, 3),
    margin: theme.spacing(0, -3),
  },
  resultListHeader: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  leftButton: {
    alignItems: "flex-start",
    margin: "0px 8px 0px 12px",
  },
  rightButton: {
    minWidth: theme.spacing(25),
    margin: "0px 12px 0px 8px",
  },
  loadingSpinnerContainer: {
    display: "block",
    alignSelf: "center",
    margin: "24px auto 8px auto",
  },
}));

export function useGenerateFaxWorkflowRedirectPath(
  patient?: Patient,
  ocrServiceRequestId?: string,
  serviceCaseSpecialty?: string
) {
  const { faxDbId: faxId, caseId } = useContext(FaxAttachmentContext);

  const isGhpUser = useBelongsToOpsGroup("GEISINGER");
  const updatedFaxWorkflowAllUsers = useFeature("updatedFaxWorkflowAllUsers");
  const authBuilderParamNavigationFF = useFeature("authBuilderParamNavigation");
  const sideBySideInitialSrFaxIntakeWorkflowFeature = useFeature("sideBySideInitialSrFaxIntakeWorkflow");

  const fullLocationPath = useFullLocationPath();

  let path;
  const isHighMarkCaseBuild = useFeature("highmarkCaseBuild");
  if (sideBySideInitialSrFaxIntakeWorkflowFeature) {
    path = `${generatePath(routes.FAX_AUTH_BUILDER, { faxId: faxId, patientId: patient?.id || "" })}?`;
  } else if (updatedFaxWorkflowAllUsers || isGhpUser) {
    path = `${generatePath(routes.AUTH_BUILDER, { patientId: patient?.id || "" })}?faxId=${faxId}`;
  } else {
    path = `${generatePath(routes.FAX_SERVICE_REQUEST, { faxId: faxId })}?patientId=${patient?.id || ""}`;
  }

  path += ocrServiceRequestId ? `&serviceRequestId=${ocrServiceRequestId}` : "";
  path += caseId ? `&caseId=${caseId}` : "";

  // (getPatientHealthPlanName(patient) === "Highmark" && !isHighMarkCaseBuild) condition can be removed after casebuild is live
  if ((getPatientHealthPlanName(patient) === "Highmark" && !isHighMarkCaseBuild) || serviceCaseSpecialty === "triage") {
    path += `&workflowLength=${"short"}`;
  }

  path += `&${encodeURIComponent(WORKFLOW_REFERER_PARAM)}=${encodeURIComponent(fullLocationPath)}`;
  if (authBuilderParamNavigationFF) {
    path += `&faxStep=SIDE_BY_SIDE_VIEW`;
  }
  return path;
}

export default function ExistingServiceRequestResults({
  serviceRequests,
  initialAttachedServiceRequestIds,
  patient,
  onCancel,
  onAllFileAttachmentsComplete,
  showCreateNewServiceRequest,
  faxCohereId,
  faxId,
  setSidebarView,
  ocrServiceRequest,
  serviceCase,
  externalReferenceId,
  healthPlanName,
  setSelectedServiceId,
  loadingServiceRequests,
}: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const trackUserActivityInteraction = useTrackUserInteraction();
  const stage = useContext(IsCohereTemplateContext) ? "COHERE_TEMPLATE_FAX_FLOW" : "NON_COHERE_TEMPLATE_FAX_FLOW";

  const sideBySideInitialSrFaxIntakeWorkflowFeature = useFeature("sideBySideInitialSrFaxIntakeWorkflow");
  const addAttachmentToServiceRequestRefactorFF = useFeature("addAttachmentToServiceRequestRefactor");

  const [selectedServiceRequestIds, setSelectedServiceRequestIds] = useState<string[]>([]);
  const [newlyAttachedServiceRequestIds, setNewlyAttachedServiceRequestIds] = useState<string[]>([]);
  const [doFileUpload, setDoFileUpload] = useState(false);
  const {
    mutate: uploadFax,
    loading: uploadingFax,
    error: faxUploadError,
  } = useCreateServiceRequestAttachment({
    id: "",
    requestOptions: { headers: { Accept: "application/json" } },
  });
  const { mutate: getAssociateFaxToServiceRequest, error: associateSubmissionFaxError } =
    useGetAssociateFaxToServiceRequest({
      queryParams: {
        externalReferenceId: serviceCase?.externalSources?.at(0)?.externalReferenceId || externalReferenceId,
        serviceRequestId: "",
      },
    });

  useEffect(() => {
    if (doFileUpload && newlyAttachedServiceRequestIds.length === selectedServiceRequestIds.length) {
      if (!Boolean(addAttachmentToServiceRequestRefactorFF)) {
        enqueueSnackbar("Attachments added", { variant: "success" });
      }

      onAllFileAttachmentsComplete();
    }
  }, [
    newlyAttachedServiceRequestIds.length,
    doFileUpload,
    enqueueSnackbar,
    selectedServiceRequestIds,
    onAllFileAttachmentsComplete,
    addAttachmentToServiceRequestRefactorFF,
  ]);
  useEffect(() => {
    if (
      Boolean(addAttachmentToServiceRequestRefactorFF) &&
      doFileUpload &&
      newlyAttachedServiceRequestIds?.length > 0 &&
      selectedServiceRequestIds?.length > 0
    ) {
      enqueueSnackbar(`Fax attached (${newlyAttachedServiceRequestIds.length}/${selectedServiceRequestIds.length})`, {
        variant: "success",
      });
    }
  }, [
    addAttachmentToServiceRequestRefactorFF,
    doFileUpload,
    enqueueSnackbar,
    newlyAttachedServiceRequestIds,
    selectedServiceRequestIds,
  ]);
  useEffect(() => {
    if (faxUploadError) {
      const errorMessage =
        typeof faxUploadError.data === "object"
          ? faxUploadError.data.message || faxUploadError.message
          : faxUploadError.message;
      enqueueSnackbar(`Failed to attach fax to service request: ${errorMessage}`, {
        variant: "error",
      });
    }
    if (associateSubmissionFaxError) {
      enqueueSnackbar(`Could not associate Highmark fax to service request, please try again`, {
        variant: "error",
      });
    }
  }, [associateSubmissionFaxError, enqueueSnackbar, faxUploadError]);

  const isSelected = useCallback((id: string) => selectedServiceRequestIds.includes(id), [selectedServiceRequestIds]);
  const select = useCallback(
    (id: string) => setSelectedServiceRequestIds((currSelected) => [...currSelected, id]),
    [setSelectedServiceRequestIds]
  );
  const unselect = useCallback(
    (id: string) => {
      const index = selectedServiceRequestIds.indexOf(id);
      if (index > -1) {
        setSelectedServiceRequestIds(listRemove(selectedServiceRequestIds, index));
      }
    },
    [selectedServiceRequestIds]
  );
  const isAttached = useCallback(
    (id: string) => newlyAttachedServiceRequestIds.includes(id) || initialAttachedServiceRequestIds.includes(id),
    [initialAttachedServiceRequestIds, newlyAttachedServiceRequestIds]
  );
  const onAttachmentSuccess = useCallback(
    (id: string) => setNewlyAttachedServiceRequestIds((currAttached) => [...currAttached, id]),
    [setNewlyAttachedServiceRequestIds]
  );
  const onAttachmentFailed = useCallback(
    (id: string) => {
      const errorMessage = `Adding fax attachment to service request failed (srId: ${id}, faxCohereId: ${faxCohereId})`;
      logError(errorMessage);
      enqueueSnackbar(errorMessage, { variant: "error" });
      setDoFileUpload(false);
      unselect(id);
    },
    [enqueueSnackbar, faxCohereId, unselect]
  );
  const faxWorkflowRedirectPath = useGenerateFaxWorkflowRedirectPath(
    patient,
    ocrServiceRequest?.id,
    serviceCase?.specialty
  );

  const handleUploadAttachments = async () => {
    setDoFileUpload(true);
    if (Boolean(addAttachmentToServiceRequestRefactorFF)) {
      if (!!faxCohereId && selectedServiceRequestIds?.length > 0) {
        const handleFaxUpload = async (serviceRequest: ServiceRequestResponse) => {
          const formData = new FormData();
          formData.set("faxId", faxCohereId);

          await uploadFax(formData as unknown as void, { pathParams: { id: serviceRequest.id } }).then(
            async () => {
              //track when a user attaches a fax to a service request
              trackUserActivityInteraction({
                event: "ATTACHED_FAX_TO_AUTH",
                stage: stage,
                activityContext: { ...activityContextFromServiceRequest(serviceRequest), faxId: faxId },
              }).finally(() => onAttachmentSuccess(serviceRequest.id));
            },
            () => {
              onAttachmentFailed(serviceRequest.id);
            }
          );
        };
        for (const selectedServiceId of selectedServiceRequestIds) {
          const selectedServiceRequest = serviceRequests?.find((serviceRequest) => {
            return serviceRequest.id === selectedServiceId;
          });

          if (!!selectedServiceRequest) {
            try {
              //If fax is a hmk fax, associate fax to service request before uploading fax
              if (healthPlanName === "Highmark") {
                await getAssociateFaxToServiceRequest({} as unknown as void, {
                  queryParams: {
                    externalReferenceId:
                      serviceCase?.externalSources?.at(0)?.externalReferenceId || externalReferenceId,
                    serviceRequestId: selectedServiceRequest.id ?? "",
                  },
                });
              }

              await handleFaxUpload(selectedServiceRequest);
            } catch (error) {
              onAttachmentFailed(selectedServiceId);
            }
          }
        }
      }
    }
  };

  return (
    <div className={classes.serviceRequestResultsContainer}>
      {patient && (
        <>
          <SelectedPatientInfo patient={patient} />
          <Divider className={classes.fullWidthBottomDivider} />
        </>
      )}
      <div className={classes.scrollableResultList}>
        <Grid container spacing={2}>
          {loadingServiceRequests ? (
            <div className={classes.loadingSpinnerContainer}>
              <CircularProgress size={26} />
            </div>
          ) : (
            <>
              {serviceRequests?.map((sr) => (
                <Grid item xs={12} key={sr.id}>
                  <ServiceRequestSelectionCard
                    serviceRequest={sr}
                    isSelected={isSelected(sr.id)}
                    isAttached={isAttached(sr.id)}
                    onSelect={select}
                    onUnselect={unselect}
                    onAttachmentSuccess={onAttachmentSuccess}
                    onAttachmentFailed={onAttachmentFailed}
                    // If we have a "Create new authorization" primary action,
                    // then we want the attachment buttons in here to have the secondary style
                    showSecondaryButtonForAttach={showCreateNewServiceRequest}
                    triggerFaxUpload={doFileUpload && !Boolean(addAttachmentToServiceRequestRefactorFF)}
                    faxCohereId={faxCohereId}
                    faxId={faxId}
                    serviceCase={serviceCase}
                    healthPlanName={healthPlanName}
                    externalReferenceId={externalReferenceId}
                    setSidebarView={setSidebarView}
                    setSelectedServiceId={setSelectedServiceId}
                    uploadingAttachment={uploadingFax}
                  />
                </Grid>
              ))}
            </>
          )}
          {showCreateNewServiceRequest && (
            <Grid item xs={12} key="create-new-authorization">
              <InlineButton
                className={classes.createServiceRequestBtn}
                onClick={() => {
                  if (sideBySideInitialSrFaxIntakeWorkflowFeature) {
                    navigate(faxWorkflowRedirectPath);
                  } else {
                    window.open(faxWorkflowRedirectPath);
                    setSidebarView("CREATE_NEW_SR_CONFIRMATION");
                  }
                }}
                data-public
              >
                Create new authorization
              </InlineButton>
            </Grid>
          )}
        </Grid>
      </div>
      <CommonFaxFooter
        onPrimaryButtonClick={() => handleUploadAttachments()}
        disabledFinish={selectedServiceRequestIds.length === 0 || uploadingFax}
        onCancelButtonClick={onCancel}
        setSidebarView={setSidebarView}
      />
    </div>
  );
}
