import { TableWithPanels, TableWithPanelsColumn } from "@coherehealth/common";
import { AuthStatus, ClinicalService, ProcedureCode, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { Grid } from "@material-ui/core";

interface Props {
  serviceRequest: ServiceRequestResponse;
  procedureCodes: ProcedureCode[];
  clinicalService?: ClinicalService;
}

export default function PreApprovedCareProcedureCodeList({ procedureCodes, serviceRequest, clinicalService }: Props) {
  const showUnitsOnPxStatus = clinicalService ? clinicalService?.isUnitsOnPx : true;
  const showUnitsOnServiceStatus = clinicalService ? !clinicalService?.isUnitsOnPx : false;

  const columns = pxTableColumns.filter(
    ({ filterFunction }) =>
      !filterFunction ||
      filterFunction({
        procedureCodes: procedureCodes || [],
        showUnitsOnPxStatus: showUnitsOnPxStatus,
        showUnitsOnServiceStatus: showUnitsOnServiceStatus,
        authStatus: serviceRequest.authStatus,
      })
  );

  const pxCodesWithStatus =
    procedureCodes && serviceRequest.semanticProcedureCodes
      ? procedureCodes.map((code) => {
          const srCodesWithStatus = serviceRequest.semanticProcedureCodes?.find((pxCode) => {
            const clinicalServiceGroupIdFilter =
              pxCode.groupBy === "ClinicalService" && pxCode.groupId === code.groupId;
            const unCategorizedGroupFilter = pxCode.groupBy !== "ClinicalService";
            return pxCode.code === code.code && (unCategorizedGroupFilter || clinicalServiceGroupIdFilter);
          });
          return { ...code, status: srCodesWithStatus?.status };
        })
      : procedureCodes;

  return (
    <>
      {procedureCodes && (
        <>
          <Grid item xs={12}>
            <TableWithPanels columns={columns} panels={[pxCodesWithStatus]} />
          </Grid>
        </>
      )}
    </>
  );
}

interface PxTableColumnFilterParams {
  procedureCodes: ProcedureCode[];
  showRequestedUnitsOnPxTable?: boolean;
  showApprovedUnitsOnPxTable?: boolean;
  showUnitsOnPxStatus?: boolean;
  showUnitsOnServiceStatus?: boolean;
  authStatus?: AuthStatus;
}

interface PxTableColumnConfig extends TableWithPanelsColumn<ProcedureCode> {
  filterFunction?: (args: PxTableColumnFilterParams) => boolean;
}

const pxTableColumns: PxTableColumnConfig[] = [
  {
    columnName: "Code",
    value: (palCatProcCode) => palCatProcCode.code,
    width: "5%",
  },
  {
    /* 
    Units on PX Status column
    e.g. "1 unit approved"
    e.g. "2 units requested, 1 unit approved"
    */
    columnName: "Status",
    value: (palCatProcCode) => procedureCodeStatus(palCatProcCode, true),
    filterFunction: ({ showUnitsOnPxStatus }) => !!showUnitsOnPxStatus,
    width: "10%",
  },
  {
    /* 
    Units on Service Status column
    e.g. "Approved/Denied/Requested"
    * If partially approved, display as if this were units on PX
    */
    columnName: "Status",
    value: (palCatProcCode) => procedureCodeStatus(palCatProcCode, false),
    filterFunction: ({ showUnitsOnServiceStatus }) => !!showUnitsOnServiceStatus,
    width: "10%",
  },
  {
    columnName: "Description",
    value: (palCatProcCode) => palCatProcCode.description,
    width: "85%",
  },
];

/* ProcedureCodeStatus: Display the status for a procedure code */
const procedureCodeStatus = (procedureCode: ProcedureCode, isUnitsOnPx: boolean): string => {
  if (isUnitsOnPx) {
    if (
      (procedureCode.approvedUnits || procedureCode.approvedUnits === 0) &&
      (procedureCode.units || procedureCode.units === 0)
    ) {
      const approvedText = `${procedureCode.approvedUnits} ${
        procedureCode.unitType === "Number of visits"
          ? pluralizedVisits(procedureCode.approvedUnits)
          : pluralizedUnits(procedureCode.approvedUnits)
      } approved`;
      const requestedText = `${procedureCode.units} ${
        procedureCode.unitType === "Number of visits"
          ? pluralizedVisits(procedureCode.units)
          : pluralizedUnits(procedureCode.units)
      } requested`;
      const deniedText = `${procedureCode.units} ${
        procedureCode.unitType === "Number of visits"
          ? pluralizedVisits(procedureCode.units)
          : pluralizedUnits(procedureCode.units)
      } denied`;
      if (procedureCode.approvedUnits === procedureCode.units) {
        // Approval
        return approvedText;
      } else if (procedureCode.approvedUnits !== procedureCode.units && procedureCode.approvedUnits > 0) {
        // Partial Approval
        return `${requestedText}, ${approvedText}`;
      } else if (procedureCode.approvedUnits !== procedureCode.units && procedureCode.approvedUnits === 0) {
        // Denial
        return `${deniedText}`;
      }
    } else if (!procedureCode.approvedUnits && procedureCode.units) {
      // No Final Determination yet
      const requestedText = `${procedureCode.units} ${
        procedureCode.unitType === "Number of visits"
          ? pluralizedVisits(procedureCode.units)
          : pluralizedUnits(procedureCode.units)
      } requested`;
      return requestedText;
    }
    // Should not reach here, but bad data *can* occur
    return "--";
  } else {
    const approvedText = `Approved`;
    const requestedText = `Requested`;
    const deniedText = `Denied`;
    if (
      (procedureCode.approvedUnits || procedureCode.approvedUnits === 0) &&
      (procedureCode.units || procedureCode.units === 0)
    ) {
      if (procedureCode.approvedUnits === procedureCode.units) {
        // Approval
        return approvedText;
      } else if (procedureCode.approvedUnits !== procedureCode.units && procedureCode.approvedUnits > 0) {
        // Partial Approval - Delegate to units on PX result
        return procedureCodeStatus(procedureCode, true);
      } else if (procedureCode.approvedUnits !== procedureCode.units && procedureCode.approvedUnits === 0) {
        // Denial
        return deniedText;
      }
    } else if (!procedureCode.approvedUnits && procedureCode.units) {
      // No Final Determination yet

      return requestedText;
    }
    // Should not reach here, but bad data *can* occur
    return "--";
  }
};

const pluralizedUnits = (units: number) => (units && units === 1 ? `unit` : `units`);
const pluralizedVisits = (visits: number) => (visits && visits === 1 ? `visit` : `visits`);
