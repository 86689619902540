import React, { Dispatch, SetStateAction } from "react";
import { Body1, Caption, Card, H6 } from "@coherehealth/common";
import ServiceRequestForm from "components/AuthBuilder/FillForms/ServiceRequestForm";
import { AuthStatus, Patient } from "@coherehealth/core-platform-api";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Divider, styled } from "@material-ui/core";
import { useServiceRequestConfigSpec } from "../ServiceRequest/ConfigurableServiceRequestForm";
import { getPatientHealthPlanName } from "../../util/patientUtils";

interface Props {
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  attemptedSubmitService: boolean;
  setCanBeSubmitted: (b: boolean) => void;
  patient: Patient;
  authStatus: AuthStatus;
  isCohereFaxForm?: boolean;
  claimId?: string;
  //only show a start date on a new request if available on the claim request otherwise no startDate
  clearDefaultStartDateValue?: boolean;
  allowCareTypeTransition?: boolean;
}
export default function ServiceRequestFormSection({
  formContent,
  setFormContent,
  attemptedSubmitService,
  setCanBeSubmitted,
  patient,
  authStatus,
  isCohereFaxForm,
  claimId,
  clearDefaultStartDateValue,
  allowCareTypeTransition,
}: Props) {
  const healthPlanName = getPatientHealthPlanName(patient, formContent.startDate) || "";
  const { formFieldConfigurations } = useServiceRequestConfigSpec({
    ...formContent,
    patientId: patient?.id || "",
    healthPlanName,
  });

  return (
    <ServiceRequestCard>
      {claimId && (
        <div style={{ paddingTop: 12, paddingBottom: 24 }}>
          <Caption style={{ paddingTop: 12 }}>Claim number</Caption>
          <Body1 style={{ paddingBottom: 24 }}>{claimId}</Body1>
          <Divider />
        </div>
      )}
      <H6 style={{ paddingBottom: 8 }}>Start by adding a service</H6>
      <ServiceRequestForm
        formContent={formContent}
        setFormContent={setFormContent}
        formConfiguration={formFieldConfigurations}
        attemptedSubmit={attemptedSubmitService}
        setCanBeSubmitted={setCanBeSubmitted}
        patient={patient}
        authStatus={authStatus}
        isCohereFaxForm={isCohereFaxForm}
        clearDefaultStartDateValue={clearDefaultStartDateValue}
        allowCareTypeTransitions={allowCareTypeTransition}
      />
    </ServiceRequestCard>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ServiceRequestCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));
