import { H6, SingleSelectDropdown, TextField } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Grid, styled } from "@material-ui/core";
import { OONExceptionReasonOption } from "util/serviceRequest";

interface Props {
  exceptionPracticeCommon:
    | "Performing Provider"
    | "Facility"
    | "Ordering Provider"
    | "Drug supplier"
    | "DME vendor"
    | "Clinic"
    | "Provider group"
    | "";
  exceptionReasons: OONExceptionReasonOption[];
  isExceptionRequired: boolean;
  isCommentRequired?: boolean;
  error?: boolean;
  commentError?: boolean;
  commentValue: string;
  dropDownValue: string;
  dropDownOnChange: (reason: string) => void;
  commentOnChange: (comment: string) => void;
  generalAuthStyling?: boolean;
}

export default function OonExceptionReason({
  exceptionPracticeCommon,
  exceptionReasons,
  isExceptionRequired,
  isCommentRequired,
  error,
  commentError,
  dropDownOnChange,
  commentOnChange,
  commentValue,
  dropDownValue,
  generalAuthStyling,
}: Props) {
  return (
    <>
      {generalAuthStyling && (
        <>
          <Grid item xs={12} md={6} style={{ padding: "12px 8px 0px 8px" }}>
            <SingleSelectDropdown
              value={dropDownValue}
              label={dropDownValue ? "Reason" : "Select reason"}
              options={exceptionReasons}
              data-tracking-id="exception-reason-dropdown"
              error={error}
              helperText={error && "Required"}
              onChange={dropDownOnChange}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "12px 8px 0px 8px" }}>
            <TextField
              label={`Comment ${!isCommentRequired ? " (optional)" : ""}`}
              fullWidth
              data-tracking-id="exception-reason-comment"
              value={commentValue}
              error={commentError}
              helperText={commentError && "Required"}
              onChangeValue={commentOnChange}
            />
          </Grid>
        </>
      )}
      {!generalAuthStyling && (
        <>
          <Heading>{`Reason for out-of-network ${exceptionPracticeCommon.toLowerCase()}`}</Heading>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <SingleSelectDropdown
                value={dropDownValue}
                label={dropDownValue ? "Reason" : `Select reason${!isExceptionRequired ? " (optional)" : ""}`}
                options={exceptionReasons}
                data-tracking-id="exception-reason-dropdown"
                error={error}
                helperText={error && "Required"}
                onChange={dropDownOnChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={`Comment ${!isCommentRequired ? " (optional)" : ""}`}
                fullWidth
                data-tracking-id="exception-reason-comment"
                value={commentValue}
                error={commentError}
                helperText={commentError && "Required"}
                onChangeValue={commentOnChange}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Heading = styled(H6)(({ theme }) => ({
  paddingBottom: theme.spacing(1.5),
}));
