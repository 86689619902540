import { H6, ProcedureCodeWithId, withId, NonPalPxCodesWithGroup } from "@coherehealth/common";
import { ClinicalService, ProcedureCode } from "@coherehealth/core-platform-api";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { Dispatch, SetStateAction } from "react";
import NonPalProcedureCodeTable from "components/ServiceRequest/ServiceRequestForm/components/NonPalProcedureCodeTable";
import { Divider, Grid, useTheme } from "@material-ui/core";
import { PriorAuthRequirements, procedureBucketDataModel } from "../common";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";

interface Props {
  setNonPalProcedureCodes: Dispatch<SetStateAction<ProcedureCode[]>>;
  attemptedSubmit?: boolean;
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  nonPalProcedureCodes: ProcedureCode[];
  priorAuthRequirements: PriorAuthRequirements;
  setPriorAuthRequirements: Dispatch<SetStateAction<PriorAuthRequirements>>;
}

export default function NonPalProcedureCodeTableWrapper({
  priorAuthRequirements,
  setNonPalProcedureCodes,
  attemptedSubmit,
  formContent,
  nonPalProcedureCodes,
  setFormContent,
  setPriorAuthRequirements,
}: Props) {
  const { spacing } = useTheme();
  // filter out nonPalPxCodes from formContent.procedureCode
  const nonPalPxCodesFromFormContent: ProcedureCodeWithId[] = formContent.procedureCodes
    .filter((pxCode) => nonPalProcedureCodes.find((nonPalProcedure) => nonPalProcedure.code === pxCode.code))
    .map(withId);

  const handleRemovePxCode = (code: string, groupId: string | undefined) => {
    const updatedListOfProcedureCodes = formContent.procedureCodes.filter((pxCode) => {
      const value = pxCode.code !== code || pxCode.groupId !== groupId;
      return value;
    });
    setFormContent({ ...formContent, procedureCodes: updatedListOfProcedureCodes });
    const nonPalindex = updatedListOfProcedureCodes.findIndex((pxCode) => pxCode.code === code);
    if (nonPalindex === -1) {
      const updatedNonPalProcedureCode = nonPalProcedureCodes.filter((pxCode) => pxCode.code !== code);
      setNonPalProcedureCodes(updatedNonPalProcedureCode);
    }
    if (priorAuthRequirements?.desiredProcedureCodes && nonPalindex === -1) {
      const updatedPriorAuthProcedureCode = priorAuthRequirements.desiredProcedureCodes.filter(
        (pxCode) => pxCode.code !== code
      );
      setPriorAuthRequirements({
        ...priorAuthRequirements,
        desiredProcedureCodes: updatedPriorAuthProcedureCode,
      });
    }
  };

  const handleUpdatePxCode = (newUnits: string, code: string, groupId: string | undefined) => {
    const index = formContent.procedureCodes.findIndex(
      (originalObj) => originalObj.code === code && originalObj.groupId === groupId
    );

    if (index !== -1) {
      formContent.procedureCodes[index].units = +newUnits;
      setFormContent({ ...formContent });
    }
  };

  const nonPalPxCodesWithEachGroup: NonPalPxCodesWithGroup[] = createNonPalPxCodesForEachNonPalGroup({
    procedureCodesAndGroups: procedureBucketDataModel(nonPalPxCodesFromFormContent, nonPalProcedureCodes),
    clinicalServices: formContent.clinicalServices,
  });

  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);

  return (
    <>
      {nonPalPxCodesWithEachGroup.map((nonPalCodesGrouped, index) => (
        <>
          <Grid item xs={12} style={{ marginTop: spacing(4) }}>
            <H6 style={{ marginBottom: isFaxAuthBuilderFlow ? spacing(1) : spacing(2) }} data-public>
              {nonPalCodesGrouped.group}
            </H6>
          </Grid>
          {nonPalCodesGrouped.nonPalCodes.map((pxCode) => (
            <NonPalProcedureCodeTable
              key={pxCode.groupId + pxCode.code}
              procedureCodes={[pxCode]}
              attemptedSubmit={attemptedSubmit}
              onUpdatePxCode={handleUpdatePxCode}
              onRemovePxCode={handleRemovePxCode}
            />
          ))}
          {index !== nonPalPxCodesWithEachGroup.length - 1 && (
            <Grid item xs={12}>
              <Divider style={{ marginTop: spacing(2) }} />
            </Grid>
          )}
        </>
      ))}
    </>
  );
}

export const createNonPalPxCodesForEachNonPalGroup = ({
  procedureCodesAndGroups,
  clinicalServices,
}: {
  procedureCodesAndGroups: Map<string, ProcedureCodeWithId[]>;
  clinicalServices?: ClinicalService[];
}) => {
  const keys = Array.from(procedureCodesAndGroups.keys());
  let nonPalPxCodesWithEachGroup: NonPalPxCodesWithGroup[] = [];
  keys.forEach((groupId) => {
    const clinicalServiceName = clinicalServices
      ? clinicalServices.find((cs: ClinicalService) => cs.id === groupId)?.name || "Uncategorized Service"
      : "Uncategorized Service";
    nonPalPxCodesWithEachGroup.push({
      group: clinicalServiceName,
      nonPalCodes: procedureCodesAndGroups.get(groupId) || [],
    });
  });

  return nonPalPxCodesWithEachGroup.sort((a, b) => a.group.localeCompare(b.group));
};
