import { PrimaryButton, RichTextEditor, SecondaryButton, stripHTMl } from "@coherehealth/common";
import { Attachment, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { Grid, makeStyles, Tab, useMediaQuery, useTheme } from "@material-ui/core";
import { navigateToPS } from "components/AuthBuilder/common";
import { StyledTabs } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { RIGHT_HAND_PANEL_SIZE } from "components/ClinicalReview/reviewUtils/utils";
import {
  ReviewControlBoxInnerContainer,
  ReviewControlBoxOuterContainer,
} from "components/ServiceRequest/ReviewSection/MDReview/MDReviewEdit";
import { useReducer, useState } from "react";
import { useNavigate } from "react-router";
import { AddAppealAttachment } from "./AddAppealAttachment";
import { AppealNoteSubmissionModal } from "./AppealNoteSubmissionModal";

interface AppealNoteProps {
  serviceRequest: ServiceRequestResponse;
  mockPostAppealNote?: string;
}

const useAppealNoteStyle = makeStyles((theme) => ({
  panelTab: {
    "&:hover span": {
      opacity: 0.7,
    },
  },
  discardButton: {
    width: 258,
    height: 48,
    padding: theme.spacing(0),
    marginRight: theme.spacing(3),
  },
  finishButton: {
    width: 258,
    height: 48,
    marginLeft: theme.spacing(3),
  },
}));

export interface AppealNoteFormState {
  postAppealNote: string;
  postAppealOutcome: string;
  attachments: Attachment[];
  attestationOfRequiredFileUpload: boolean;
}

export const initialAppealNote: AppealNoteFormState = {
  postAppealNote: "",
  postAppealOutcome: "",
  attachments: [],
  attestationOfRequiredFileUpload: false,
};

export type AppealNoteFormAction =
  | { type: "updateAppealNote"; value: AppealNoteFormState["postAppealNote"] }
  | { type: "addFile"; value: Attachment }
  | { type: "removeFile"; attachmentId: string }
  | {
      type: "updatePostAppealOutcome";
      value: string;
    }
  | { type: "updateAttestationOfRequiredFileUpload" };

export const appealNoteReducer = (state: AppealNoteFormState, action: AppealNoteFormAction): AppealNoteFormState => {
  switch (action.type) {
    case "updateAppealNote":
      return { ...state, postAppealNote: action.value };
    case "addFile":
      const files = state.attachments;
      return { ...state, attachments: [...files, action.value] };
    case "removeFile":
      const filesWithoutRemovedFile = state.attachments.filter((attachment) => attachment.id !== action.attachmentId);
      return { ...state, attachments: filesWithoutRemovedFile };
    case "updatePostAppealOutcome":
      return { ...state, postAppealOutcome: action.value };
    case "updateAttestationOfRequiredFileUpload":
      return { ...state, attestationOfRequiredFileUpload: !state.attestationOfRequiredFileUpload };
    default:
      return state;
  }
};

const validateAppealNote = (state: AppealNoteFormState): boolean => {
  return Boolean(stripHTMl(state.postAppealNote)) && state.attachments.length > 0;
};

export const AppealNote = ({ serviceRequest, mockPostAppealNote }: AppealNoteProps) => {
  const classes = useAppealNoteStyle();
  const theme = useTheme();
  const matchesRight = useMediaQuery(theme.breakpoints.up(RIGHT_HAND_PANEL_SIZE));
  const navigate = useNavigate();
  const [openSubmissionModal, setOpenSubmissionModal] = useState<boolean>(false);
  const [appealNoteFormState, updateAppealNoteFormState] = useReducer(appealNoteReducer, {
    ...initialAppealNote,
    postAppealNote: mockPostAppealNote ?? "",
  });
  const [attemptedSubmit, setAttemptedSubmit] = useState<boolean>(false);
  const isValidAppealNote = validateAppealNote(appealNoteFormState);

  const onFinishNote = () => {
    setAttemptedSubmit(true);

    if (!isValidAppealNote) {
      return;
    }
    setOpenSubmissionModal(true);
  };

  return (
    <Grid data-testid="appeal-note-form" container direction="column">
      <StyledTabs
        value={"APPEAL"}
        aria-label="note-tabs"
        style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 2 }}
      >
        <Tab
          key={"post-appeal-note"}
          style={{ minWidth: "180px" }}
          label={"Post-appeal note"}
          value={"APPEAL"}
          className={classes.panelTab}
        />
      </StyledTabs>
      <Grid container direction="column" style={{ padding: theme.spacing(3) }}>
        <RichTextEditor
          readonly={false}
          htmlValue={appealNoteFormState.postAppealNote}
          setHtmlValue={(postAppealNote) =>
            updateAppealNoteFormState({
              type: "updateAppealNote",
              value: postAppealNote,
            })
          }
          label="Post-appeal note"
          testid="post-appeal-note-text-editor"
          error={attemptedSubmit && !Boolean(stripHTMl(appealNoteFormState.postAppealNote))}
          helperText={
            attemptedSubmit && !Boolean(stripHTMl(appealNoteFormState.postAppealNote)) ? "Required" : undefined
          }
        />
        <AddAppealAttachment
          serviceRequestId={serviceRequest.id}
          attachments={appealNoteFormState.attachments}
          updateAppealNoteFormState={updateAppealNoteFormState}
        />
      </Grid>
      <ReviewControlBoxOuterContainer matchesRight={matchesRight} note>
        <ReviewControlBoxInnerContainer center>
          <SecondaryButton
            className={classes.discardButton}
            warning
            disabled={openSubmissionModal}
            onClick={() => {
              navigateToPS(serviceRequest?.patient?.id ?? "", navigate, serviceRequest.id);
            }}
            data-testid="post-appeal-note-discard-changes-button"
          >
            Discard changes
          </SecondaryButton>
          <PrimaryButton
            className={classes.finishButton}
            disabled={!isValidAppealNote || openSubmissionModal}
            data-testid="post-appeal-note-save-button"
            onClick={onFinishNote}
          >
            Finish note
          </PrimaryButton>
        </ReviewControlBoxInnerContainer>
      </ReviewControlBoxOuterContainer>
      {serviceRequest && (
        <AppealNoteSubmissionModal
          openSubmissionModal={openSubmissionModal}
          setOpenSubmissionModal={setOpenSubmissionModal}
          serviceRequest={serviceRequest}
          appealNoteFormState={appealNoteFormState}
          updateAppealNoteFormState={updateAppealNoteFormState}
        />
      )}
    </Grid>
  );
};
