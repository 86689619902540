import React, { FunctionComponent, useEffect, useState } from "react";

import {
  DateSelect,
  MAX_DATE_SELECT_DATE,
  Caption,
  formatDateStr,
  formatDateToISODate,
  parseDateFromISOStringWithoutFallback,
  plusDays,
  removeTimeFromDate,
  today,
} from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { OscarServiceType, Patient } from "@coherehealth/core-platform-api";
import { CoverageCheck, checkOnetimeRequestCoverage, checkRecurringRequestCoverage } from "util/patientUtils";
import { useTrackUserImpression } from "util/userActivityTracker";

import { DateSelectWidth, FlexGridItem } from "common/SharedServiceRequestFormComponents";
import useAuthValidityWindowText from "hooks/useAuthValidityWindowText";

export { MAX_DATE_SELECT_DATE };

/**
 *  THIS IS A DEPRECATED COMPONENT. Remove with the removal of the simplifiedServiceFrequency flag.
 */

const StartAndEndDateSelect: FunctionComponent<{
  hasValidEndDate: boolean;
  minStartDate?: Date;
  startDate: Date;
  setStartDate: (date: Date) => void;
  endDate?: Date;
  setEndDate: (date: Date) => void;
  shouldShowEndDate: boolean;
  isExpedited: boolean;
  isInpatient: boolean;
  clinicalServiceId?: string;
  serviceRequestId?: string;
  cohereAuthId?: string;
  intakeTimestamp?: string;
  patient?: Patient;
  setHasValidCoverageDates: React.Dispatch<React.SetStateAction<boolean>>;
  showApprovedSrEditWarning?: boolean;
  onCancelStartDateChange: () => void;
  attemptedSubmit: boolean;
  healthPlanName: string;
  serviceType?: OscarServiceType;
}> = ({
  hasValidEndDate,
  minStartDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  shouldShowEndDate,
  isExpedited,
  isInpatient,
  clinicalServiceId,
  serviceRequestId,
  cohereAuthId,
  intakeTimestamp,
  patient,
  setHasValidCoverageDates,
  showApprovedSrEditWarning,
  onCancelStartDateChange,
  attemptedSubmit,
  serviceType,
}) => {
  const trackUserActivityImpression = useTrackUserImpression();
  const patientId = patient?.id || "";

  const { message: authWindowText } = useAuthValidityWindowText({ patientId, startDate, isInpatient, serviceType });

  const getIntakeTimestampDate = (intakeTimestamp: string | undefined) => {
    const intakeTimestampDate = parseDateFromISOStringWithoutFallback(intakeTimestamp);
    if (intakeTimestampDate) {
      return removeTimeFromDate(intakeTimestampDate);
    }
  };

  const intakeTimestampDate = getIntakeTimestampDate(intakeTimestamp);
  const displayExpeditedStartDateWarning =
    isExpedited && plusDays(3, intakeTimestampDate ? intakeTimestampDate : today()) <= startDate;

  let helperMessage;

  if (displayExpeditedStartDateWarning) {
    helperMessage = `Requests submitted 3 or more days before the request's start date aren't typically processed as expedited. 
    The request may be flagged for review and downgraded to standard.${
      intakeTimestampDate ? ` This request was originally submitted on ${formatDateStr(intakeTimestampDate)}.` : ""
    }`;
  }

  const [expeditedWarningSeen, setExpeditedWarningSeen] = useState(false);
  useEffect(() => {
    if (displayExpeditedStartDateWarning && !expeditedWarningSeen) {
      setExpeditedWarningSeen(true);
      trackUserActivityImpression({
        event: "FIELD_RECOMMENDED_CHANGE",
        stage: "AUTH_CREATION",
        field: "EXPEDITED",
        activityContext: { patientId, clinicalServiceId, serviceRequestId, cohereAuthId },
        beforeSnapshot: {
          startDate: formatDateToISODate(startDate),
        },
      });
    }
  }, [
    patientId,
    clinicalServiceId,
    startDate,
    displayExpeditedStartDateWarning,
    trackUserActivityImpression,
    expeditedWarningSeen,
    setExpeditedWarningSeen,
    serviceRequestId,
    cohereAuthId,
  ]);

  const memberCoverages = patient?.coverages || (patient?.coverage ? [patient.coverage] : undefined);
  let hasCoverageError = false;
  if (memberCoverages) {
    let coverageCheck: CoverageCheck | undefined = undefined;
    if (shouldShowEndDate && endDate) {
      coverageCheck = Boolean(endDate)
        ? checkRecurringRequestCoverage(memberCoverages, startDate, endDate)
        : { inRange: true };
    } else {
      coverageCheck = checkOnetimeRequestCoverage(memberCoverages, startDate);
    }

    if (!coverageCheck.inRange) {
      helperMessage = (
        <>
          {coverageCheck.messageToDisplay?.map((m, idx) => (
            <HelperText key={idx}>{m}</HelperText>
          ))}
        </>
      );
      hasCoverageError = true;
    }
  }

  useEffect(() => {
    // hoists the coverage error value up to the parent
    // the dates are valid if there is NO coverage error
    setHasValidCoverageDates(!hasCoverageError);
  }, [hasCoverageError, setHasValidCoverageDates]);

  const dateSelectClasses = useDateSelectStyles({});
  const { spacing } = useTheme();

  return (
    <FlexGridItem container wrap="nowrap">
      <DateSelect
        label="Expected start date"
        value={startDate}
        onDateChange={setStartDate}
        minDate={minStartDate}
        error={hasCoverageError}
        warning={hasValidEndDate && displayExpeditedStartDateWarning}
        helperText={hasValidEndDate && helperMessage}
        TextFieldProps={{
          warningHelperText: showApprovedSrEditWarning,
          className: `${dateSelectClasses.textField} ${dateSelectClasses.startDateTextField}`,
        }}
      />
      {!shouldShowEndDate && authWindowText ? (
        <OneTimeDateNote>
          {authWindowText}
          <br /> No action needed if service occurs between these dates
        </OneTimeDateNote>
      ) : (
        <>
          <Box component="span" display="flex" justifyContent="center" m="20px 0 0" width={spacing(2)}>
            -
          </Box>
          <DateSelect
            label="Expected end date"
            value={endDate || null}
            onDateChange={setEndDate}
            minDate={startDate}
            minDateMessage={"End date cannot be before start date"}
            error={(!hasValidEndDate && attemptedSubmit) || hasCoverageError}
            TextFieldProps={{
              className: dateSelectClasses.textField,
            }}
          />
        </>
      )}
    </FlexGridItem>
  );
};

export default StartAndEndDateSelect;

const useDateSelectStyles = makeStyles({
  textField: {
    width: DateSelectWidth,
    height: "fit-content",
  },
  startDateTextField: {
    "& .MuiFormHelperText-root": {
      // width is 275% so that the helper text will extend past the text field container
      width: "275%",
    },
  },
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OneTimeDateNote = styled(Caption)(({ theme }) => ({
  color: theme.palette.text.secondary,
  paddingLeft: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  height: 56,
  maxWidth: 450,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HelperText = styled("span")(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  display: "block",
  width: "110%",
}));
