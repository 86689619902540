import { PriorAuthRequirements, ServiceRequestFormStateSetters } from "../common";
import { ProcedureCodeBucketsState } from "../FillFormsGeneralAuthSubmission/ServicesAndProceduresCard";
import { Coverage, AuthStatus, Patient, CardExtensionProcedureCode } from "@coherehealth/core-platform-api";
import { ServiceRequestFormContent } from "components/ServiceRequest";
import { Props as RequestorProps } from "components/Requestor/RequestorCard";
import { Dispatch, SetStateAction } from "react";
import PatientStayAndServicesCard from "./PatientStayAndServicesCard";

interface Props extends ProcedureCodeBucketsState, RequestorProps, ServiceRequestFormStateSetters {
  setServiceRequestFormContents: Dispatch<SetStateAction<ServiceRequestFormContent[]>>;
  openNonPalConfirmationModal?: boolean;
  setOpenNonPalConfirmationModal?: Dispatch<SetStateAction<boolean>>;
  setUserSelectedNonPalCode?: (value: boolean) => void;
  priorAuthRequirements: PriorAuthRequirements;
  activeCoverage: Coverage | undefined;
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  attemptedSubmit: boolean;
  patientHealthPlanName: string;
  patient: Patient | null;
  workflowId?: string;
  authStatus: AuthStatus;
  requestorProps?: RequestorProps;
  showRequestorCard?: boolean;
  allowMutablePxCodesWithNonPal?: boolean;
  setServiceDetailsFormsCanBeSubmitted: Dispatch<SetStateAction<boolean[]>>;
  setPriorAuthRequirements: Dispatch<SetStateAction<PriorAuthRequirements>>;
  crdProcedureCodeResults: CardExtensionProcedureCode[] | undefined;
  allowedPxCodePerPayer?: number;
}
export default function ServiceDetailsContainer({
  openNonPalConfirmationModal,
  setOpenNonPalConfirmationModal,
  setUserSelectedNonPalCode,
  priorAuthRequirements,
  activeCoverage,
  formContent,
  setFormContent,
  attemptedSubmit,
  patientHealthPlanName,
  patient,
  workflowId,
  authStatus,
  /** Procedure Code Buckets */
  palProcedureCodes,
  nonPalProcedureCodes,
  nonCohereProcedureCodes,
  setPalProcedureCodes,
  setNonCohereProcedureCodes,
  setNonPalProcedureCodes,
  setServiceRequestFormContents,
  setServiceRequestFormsCanBeSubmitted,
  setServiceRequestFormsHaveNewEdits,
  setServiceDetailsFormsCanBeSubmitted,
  setPriorAuthRequirements,
  showRequestorCard,
  allowMutablePxCodesWithNonPal,
  crdProcedureCodeResults,
  allowedPxCodePerPayer,
  ...requestorProps
}: Props) {
  return (
    <PatientStayAndServicesCard
      setPriorAuthRequirements={setPriorAuthRequirements}
      priorAuthRequirements={priorAuthRequirements}
      setOpenNonPalConfirmationModal={setOpenNonPalConfirmationModal}
      openNonPalConfirmationModal={openNonPalConfirmationModal}
      setUserSelectedNonPalCode={setUserSelectedNonPalCode}
      activeCoverage={activeCoverage}
      formContent={formContent}
      setFormContent={setFormContent}
      attemptedSubmit={attemptedSubmit}
      patientHealthPlanName={patientHealthPlanName}
      patient={patient}
      workflowId={workflowId}
      authStatus={authStatus}
      palProcedureCodes={palProcedureCodes}
      setPalProcedureCodes={setPalProcedureCodes}
      nonPalProcedureCodes={nonPalProcedureCodes}
      setNonPalProcedureCodes={setNonPalProcedureCodes}
      nonCohereProcedureCodes={nonCohereProcedureCodes}
      setNonCohereProcedureCodes={setNonCohereProcedureCodes}
      setServiceRequestFormContents={setServiceRequestFormContents}
      setServiceRequestFormsCanBeSubmitted={setServiceRequestFormsCanBeSubmitted}
      setServiceRequestFormsHaveNewEdits={setServiceRequestFormsHaveNewEdits}
      setServiceDetailsFormsCanBeSubmitted={setServiceDetailsFormsCanBeSubmitted}
      showRequestorCard
      allowMutablePxCodesWithNonPal={allowMutablePxCodesWithNonPal}
      crdProcedureCodeResults={crdProcedureCodeResults}
      allowedPxCodePerPayer={allowedPxCodePerPayer}
      {...requestorProps}
    />
  );
}
