import { Dispatch, SetStateAction } from "react";
import { useClinicalReviewNext } from "components/ClinicalReview/next/clinical-review-store";
import { default as ReviewLeftPanelLegacy } from "components/ClinicalReview/reviewUtils/ReviewLeftPanel";
import { AttachmentGuidelineTextHighlightsSnapshot } from "@coherehealth/core-platform-api";
import { useSearchParams } from "react-router-dom";
import { UserClickInfoClinicalReviewPage } from "@coherehealth/common";

export type ClinicalReviewLeftPanelProps = {
  setAttachmentGuidelineTextHighlightsSnapshot?: Dispatch<SetStateAction<AttachmentGuidelineTextHighlightsSnapshot>>;
  userClickInfoTracking?: UserClickInfoClinicalReviewPage;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
};

export const ReviewLeftPanelNext = ({
  setAttachmentGuidelineTextHighlightsSnapshot,
  userClickInfoTracking,
  setUserClickInfoTracking,
}: ClinicalReviewLeftPanelProps) => {
  const { state } = useClinicalReviewNext();
  const {
    claimHistoryProps,
    otherAuthsProps,
    serviceRequestId,
    serviceRequest,
    otherServiceRequests,
    outreachOpportunities,
    authorization,
    patient,
    currentReview,
    existingReviews,
    caseId,
    serviceRequestCases,
    loading: { patientLoading, serviceRequestLoading, otherServiceRequestsLoading, authorizationLoading },
    featureFlagData: { claimHistoryFeature, genericNotes, caseEventHistoryGA },
    clinicalReviewPageUserAuths: {
      canEditMdReview,
      canEditPeerToPeerReview,
      canEditFinalizedMDReview,
      canSubmitDenial,
      canUseNewDenialsWorkflowBeta,
      viewNoteAuthorized,
      isAllowedCaseHistory,
    },
    attachmentsProps: {
      attachments,
      attachmentListFetchLoading,
      onViewAttachment,
      setCopiedAttachmentText,
      setSearchInfos,
      previousAttachmentsExpanded,
      setPreviousAttachmentsExpanded,
      currentPage,
      zoomLevel,
      currentRotate,
      attachmentIndexOpen,
      attachmentsInfo,
      setAttachmentsInfo,
    },
    leftColumnTab,
  } = state;

  const [search] = useSearchParams();

  const newDenialsWorkflow = !!(
    canUseNewDenialsWorkflowBeta ||
    canEditMdReview ||
    canEditPeerToPeerReview ||
    canSubmitDenial ||
    canEditFinalizedMDReview
  );
  const canAccessCaseHistory = !!(isAllowedCaseHistory || caseEventHistoryGA);
  const canViewGenericNotes = !!(genericNotes && viewNoteAuthorized);

  return (
    <ReviewLeftPanelLegacy
      claimHistoryProps={claimHistoryProps}
      otherAuthsProps={{
        otherAuthorizationsResponse: otherAuthsProps?.otherAuthorizationsResponse,
        otherAuthorizationsLoading: otherAuthsProps?.otherAuthorizationsLoading,
        otherAuthorizationTableColumns: otherAuthsProps?.otherAuthorizationTableColumns,
        otherAuthorizationRowChildTableColumns: otherAuthsProps?.otherAuthorizationRowChildTableColumns || [],
        paginateParams: otherAuthsProps?.paginateParams || {},
        setPaginatedParams: otherAuthsProps?.setPaginatedParams || (() => {}),
        otherSRsFilterValues: otherAuthsProps?.otherSRsFilterValues || {},
        setOtherSRsFilterValues: otherAuthsProps?.setOtherSRsFilterValues || (() => {}),
        authFilterConfigs: otherAuthsProps?.authFilterConfigs || [],
      }}
      authorization={authorization}
      refreshAuthorization={async (): Promise<void> => {}} // TODO: Implement fetchAuthorization as async dispatch
      authorizationFetchLoading={authorizationLoading}
      attachments={attachments}
      attachmentsLoading={attachmentListFetchLoading}
      patient={patient}
      patientLoading={patientLoading}
      serviceRequest={serviceRequest}
      serviceRequestLoading={serviceRequestLoading}
      existingReviews={existingReviews}
      outreachOpportunities={outreachOpportunities}
      otherServiceRequests={otherServiceRequests}
      otherServiceRequestsLoading={otherServiceRequestsLoading}
      userClickInfoTracking={userClickInfoTracking}
      setUserClickInfoTracking={setUserClickInfoTracking}
      setSearchInfos={setSearchInfos}
      serviceRequestRefetch={() => Promise.resolve(serviceRequest)} // TODO: Implement fetchServiceRequest as async dispatch
      tabOpen={leftColumnTab}
      setTabOpen={() => {}} // TODO: Change from setState to dispatch
      attachmentIndexOpen={attachmentIndexOpen}
      previousAttachmentsExpanded={previousAttachmentsExpanded}
      setPreviousAttachmentsExpanded={setPreviousAttachmentsExpanded}
      currentPage={currentPage}
      zoomLevel={zoomLevel}
      currentRotate={currentRotate}
      newDenialsWorkflow={newDenialsWorkflow}
      setCopiedAttachmentText={setCopiedAttachmentText}
      serviceCases={serviceRequestCases}
      trackingNumberSearch={!!search.get("trackingNumberSearch")}
      caseId={caseId ?? undefined}
      serviceRequestId={serviceRequestId ?? ""}
      canAccessCaseHistory={canAccessCaseHistory}
      setAttachmentGuidelineTextHighlightsSnapshot={setAttachmentGuidelineTextHighlightsSnapshot}
      canViewGenericNotes={canViewGenericNotes}
      onViewAttachment={onViewAttachment}
      clinicalReview={currentReview}
      attachmentsInfo={attachmentsInfo}
      setAttachmentsInfo={setAttachmentsInfo}
      canUseClaimHistoryFeature={claimHistoryFeature}
    />
  );
};
