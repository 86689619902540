import { FormEvent, useEffect, useState, ComponentProps, useMemo } from "react";
import {
  AuthStatus,
  PendingReason,
  ServiceRequestResponse,
  ServiceRequestSearchResponse,
  useUpdateServiceRequest,
  useCreatePeerToPeerOutreach,
  PeerToPeerOutreachOutcome,
  useGetNextNegationStatus,
  useExtendServiceRequestTurnAroundTime,
  WithdrawRequestor,
  AuthorizationResponse,
  useGetNextAllowedAuthStatuses,
  ProcedureCode,
  OutreachAttempt,
  ServiceRequestUpdatePayload,
} from "@coherehealth/core-platform-api";
import {
  PrimaryButton,
  SingleSelectDropdown,
  TextField,
  RadioGroupOption,
  RadioGroup,
  TertiaryButton,
  DateTextField,
  TimeTextField,
  useFeature,
  formatDateTimeStrToISO,
  colorsLight,
  extractDateTimeMeridianStrings,
  useConfiguration,
  Checkbox,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { error as logError } from "logger";
import { useSnackbar } from "notistack";
import {
  isAuthStatus,
  isNegationAuthStatus,
  withdrawnReasonOptions,
  mapAuthServiceRequestsToDropdownOptions,
  determinePostDenialP2PEligibility,
  sortServiceRequestByDateField,
} from "util/serviceRequest";
import PartialUnitsApproval from "./PartialApproval/PartialUnitsApproval";
import PartialProcedureCodeApproval from "./PartialApproval/PartialProcedureCodeApproval";
import { useAuthorized } from "authorization";
import { helperTextForTATExtension } from "components/ServiceRequest/ReviewSection/util/QueueManagementReviewUtil";
import { ModalMessage } from "components/ClinicalReview/reviewUtils/utils";
import { NextReviewDateSelect } from "components/ServiceRequest/ReviewSection/common/NextReviewDateSelect";
import { Box } from "@material-ui/core";
import { shouldShowNextReviewDate } from "components/ClinicalReview/ClinicalReviewInfoPanel/NotesCard/noteUtils";
import useFetchPendingReason from "components/ServiceRequest/ReviewSection/common/hooks/useFetchPendingReasons";

interface ChangeStatusFormState {
  authStatus: AuthStatus | undefined;
  pendingReason: PendingReason | undefined;
  authorizationNote: string | undefined;
  tatExtension: {
    eligible: boolean | undefined;
    decision: "YES" | "NO";
  };
  partialApproval: {
    approvedUnits: number | undefined;
    approvedProcedureCodes: ProcedureCode[] | undefined;
  };
  peerToPeer: {
    attempted: boolean | undefined;
    lastAttempt?: {
      date?: string;
      time?: string;
      meridian?: string;
      outcome?: PeerToPeerOutreachOutcome | "";
    };
    isPostDenialEligible?: boolean;
  };
  withdrawVoid: {
    reason: string | undefined;
    requester: WithdrawRequestor | undefined;
  };
  claimsPaymentNote: string | undefined;
  nextReviewDate: Date | null;
}

interface ChangeStatusFormProps {
  onEdit: (postedOutreachAttempt?: OutreachAttempt | undefined) => void;
  onCancel: () => void;
  authServiceRequests: (ServiceRequestResponse | ServiceRequestSearchResponse)[];
  onSubmission?: (sr: ServiceRequestResponse) => void;
  authorizationRefetch?: () => Promise<AuthorizationResponse | null>;
  postDenialP2PEligible?: boolean;
  saveOutreachAttempt?: () => Promise<OutreachAttempt | undefined>;
  outreachAttemptLoading?: boolean;
  isLatestServiceRequestMemberRequested?: boolean;
}

export default function ChangeStatusForm({
  onEdit,
  onCancel,
  authServiceRequests,
  onSubmission,
  authorizationRefetch,
  saveOutreachAttempt,
  outreachAttemptLoading,
  isLatestServiceRequestMemberRequested,
}: ChangeStatusFormProps) {
  // Feature flags
  const partialApprovalEnabled = useFeature("partialApproval");
  const pendingAdminVoidEnabled = useFeature("pendingAdminVoidEnabled");
  const diagnosisOutOfScopeEnabled = useFeature("diagnosisOutOfScope");
  const postDenialWorkflowEnabled = useFeature("postDenialWorkflow");
  const enablePendingSchedulingOutreach = useFeature("enablePendingSchedulingOutreach");
  const postDecisionP2PExpanded = useFeature("postDecisionP2PExpanded");
  const oonReviewConfig = useConfiguration("outOfNetworkReviewConfiguration", authServiceRequests[0]?.healthPlanName);
  const ghpMemberRequestedLettersRefactor = useFeature("ghpMemberRequestedLettersRefactor");

  // User authorizations
  const canViewAllWithdrawnReasons = useAuthorized("VIEW_ALL_WITHDRAWAL_REASONS");
  const canEditWithdrawalRequestor = useAuthorized("EDIT_WITHDRAWAL_REQUESTOR");

  // Component states and computed values
  const sortedAuthServiceRequests = sortServiceRequestByDateField("dateCreated", authServiceRequests, true);
  const latestServiceRequest = sortedAuthServiceRequests?.[0] as ServiceRequestResponse;
  const [selectedServiceRequest, setSelectedServiceRequest] = useState(sortedAuthServiceRequests[0]);
  const [sendOptionalDecisionLetter, setSendOptionalDecisionLetters] = useState<boolean>(false);
  const isGHPServiceRequest = selectedServiceRequest.healthPlanName === "Geisinger";
  const isHumanaServiceRequest = selectedServiceRequest.healthPlanName === "Humana";
  const [formState, setFormState] = useState<ChangeStatusFormState>({
    authStatus: selectedServiceRequest.authStatus,
    pendingReason: selectedServiceRequest.pendingReason,
    authorizationNote: selectedServiceRequest.authorizationNote,
    tatExtension: {
      eligible: !!selectedServiceRequest.turnAroundTimeExtensionEligible,
      decision: "NO",
    },
    partialApproval: {
      approvedUnits: selectedServiceRequest.approvedUnits,
      approvedProcedureCodes: Boolean(selectedServiceRequest.approvedProcedureCodes)
        ? selectedServiceRequest.approvedProcedureCodes
        : selectedServiceRequest.procedureCodes,
    },
    peerToPeer: {
      attempted: undefined,
      lastAttempt: {
        ...extractDateTimeMeridianStrings(new Date()),
        outcome: "",
      },
      isPostDenialEligible:
        postDenialWorkflowEnabled &&
        determinePostDenialP2PEligibility({
          currentAuthStatus: selectedServiceRequest.authStatus,
          initialDecisionDisposition: selectedServiceRequest.initialDecisionDisposition,
          revisedDecisionTimestamp: selectedServiceRequest.revisedDecisionTimestamp,
          postDecisionP2PExpanded,
        }),
    },
    withdrawVoid: {
      reason: selectedServiceRequest.withdrawnReason,
      requester: selectedServiceRequest.withdrawRequestor,
    },
    claimsPaymentNote: "",
    nextReviewDate: null,
  });

  const [nextNegationStatus, setNextNegationStatus] = useState<AuthStatus>();

  const requireP2POutreachInfo =
    formState.peerToPeer?.attempted && formState.authStatus === "PENDING" && formState.pendingReason === "MD_REVIEW";

  // RESTful React hooks
  const {
    mutate: updateServiceRequest,
    error: updateServiceRequestError,
    loading: updateServiceRequestLoading,
  } = useUpdateServiceRequest({ id: selectedServiceRequest.id });

  const {
    mutate: createP2POutreach,
    error: createP2POutreachError,
    loading: createP2POutreachLoading,
  } = useCreatePeerToPeerOutreach({
    serviceRequestId: selectedServiceRequest.id,
  });

  const { mutate: getNextNegationStatus } = useGetNextNegationStatus({
    id: selectedServiceRequest.id,
  });

  const { data: allowedAuthStatusesResponse, error: nextAllowedAuthStatusesError } = useGetNextAllowedAuthStatuses({
    id: selectedServiceRequest.id,
  });

  const { mutate: extendServiceRequestTAT } = useExtendServiceRequestTurnAroundTime({
    serviceRequestId: selectedServiceRequest.id,
  });

  const { data: persistedServiceRequest, refetch: refetchPersistedServiceRequest } =
    useGetServiceRequestByIdWithFallback({
      id: selectedServiceRequest.id,
      lazy: true,
    });

  // Utility hooks
  const { enqueueSnackbar } = useSnackbar();
  const { spacing } = useTheme();

  // useEffect declarations
  useEffect(() => {
    // When the user chooses to move an SR to WITHDRAWN, we need to determine what the
    // next auth status will be based on the selected withdrawn reason. This value is
    // displayed to the user and sent in the update payload.
    const getNextState = async () => {
      const nextStatus = await getNextNegationStatus({ withdrawnReason: formState.withdrawVoid?.reason });
      setNextNegationStatus(nextStatus);
    };
    if (isNegationAuthStatus(formState.authStatus)) {
      getNextState();
    } else {
      setNextNegationStatus(formState.authStatus);
    }
  }, [getNextNegationStatus, formState.authStatus, formState.withdrawVoid?.reason]);

  useEffect(() => {
    // When the user chooses to move an SR that has already had a TAT extension to PENDING
    // with the reason being either missing clinical or non-clinical info, the TAT extension
    // decision state is updated to "YES". (behind FF)
    if (
      formState.authStatus === "PENDING" &&
      (formState.pendingReason === "MISSING_CLINICAL_INFO" ||
        formState.pendingReason === "MISSING_NON_CLINICAL_INFO") &&
      selectedServiceRequest.turnAroundTimeExtension &&
      formState.tatExtension.eligible &&
      formState.tatExtension.decision !== "YES"
    ) {
      setFormState((prevState) => ({
        ...prevState,
        tatExtension: {
          ...prevState.tatExtension,
          decision: "YES",
        },
      }));
    }
  }, [
    formState.authStatus,
    formState.pendingReason,
    selectedServiceRequest.turnAroundTimeExtension,
    formState.tatExtension.decision,
    formState.tatExtension.eligible,
  ]);

  useEffect(() => {
    // If the selected SR has no review outcome withdrawn options, is not a reconsideration,
    // and has no withdraw requester option, then we should re-fetch the persisted version of
    // the SR.
    if (
      !selectedServiceRequest.reviewOutcomeWithdrawOptions ||
      !selectedServiceRequest.reconClaim ||
      selectedServiceRequest.withdrawRequestorOption === undefined
    ) {
      refetchPersistedServiceRequest();
    }
  }, [
    refetchPersistedServiceRequest,
    selectedServiceRequest.reconClaim,
    selectedServiceRequest.reviewOutcomeWithdrawOptions,
    selectedServiceRequest.withdrawRequestorOption,
  ]);

  useEffect(() => {
    // When we re-fetch the persisted version of the selected service request and
    // there is no withdrawn reason or requester selected on the form, we should
    // update it to sync with the persisted version of the SR.
    if (persistedServiceRequest?.withdrawnReason && !formState.withdrawVoid?.reason) {
      setFormState((prevState) => {
        return {
          ...prevState,
          withdrawVoid: {
            ...prevState.withdrawVoid,
            reason: persistedServiceRequest.withdrawnReason,
          },
        };
      });
    }
    if (persistedServiceRequest?.withdrawRequestor && !formState.withdrawVoid?.requester) {
      setFormState((prevState) => {
        return {
          ...prevState,
          withdrawVoid: {
            ...prevState.withdrawVoid,
            requester: persistedServiceRequest.withdrawRequestor,
          },
        };
      });
    }
  }, [
    persistedServiceRequest?.withdrawRequestor,
    persistedServiceRequest?.withdrawnReason,
    formState.withdrawVoid?.reason,
    formState.withdrawVoid?.requester,
  ]);

  const nextAllowedAuthStatuses = useMemo(
    // When the allowed auth statuses are updated (this occurs when a different service request
    // is selected), then map the new values to dropdown option objects.
    () => {
      const statuses = allowedAuthStatusesResponse?.nextAllowedAuthStatuses;
      if (isGHPServiceRequest) {
        return statuses?.filter((status) => status.id !== "PENDING_EXTERNAL_DETERMINATION");
      } else {
        return statuses;
      }
    },
    [allowedAuthStatusesResponse?.nextAllowedAuthStatuses, isGHPServiceRequest]
  );

  const { allowedPendingReasons } = useFetchPendingReason(selectedServiceRequest.id);
  useEffect(() => {
    // If there is any error updating the service request, notify the end user with a toast
    if (updateServiceRequestError) {
      const error = updateServiceRequestError;
      const errorMessage = typeof error.data !== "string" && error.data?.message ? error.data.message : error.data;
      enqueueSnackbar(`Error updating service request: ${errorMessage}`, { variant: "error" });
    }
  }, [updateServiceRequestError, enqueueSnackbar]);

  useEffect(() => {
    // If there is any error creating the peer-to-peer outreach, log the error in Sentry
    if (createP2POutreachError) {
      logError("Error creating a P2P Outreach");
    }
  }, [createP2POutreachError]);

  useEffect(() => {
    // If there is any error fetching the next allowed auth statuses, log the error in Sentry
    if (nextAllowedAuthStatusesError) {
      logError(`Error retrieving the next allowed auth statuses for service request: ${selectedServiceRequest.id}`);
    }
  }, [nextAllowedAuthStatusesError, selectedServiceRequest.id]);

  // Component function declarations
  const disableSaveButton = () => {
    const {
      authStatus,
      partialApproval: { approvedUnits = 0 },
      peerToPeer: { lastAttempt: lastPeerToPeerAttempt },
      withdrawVoid: { reason: withdrawnReason, requester: withdrawRequester },
    } = formState;

    // Disable submit if:
    //   - the partial approval FF is enabled
    //   - the chosen auth status on the form is PARTIALLY_APPROVED
    //   - the selected service request has a non-zero number of units
    //   - the user attempts to approve more units than requested or a non-positive number of units
    if (partialApprovalEnabled && authStatus === "PARTIALLY_APPROVED" && Boolean(selectedServiceRequest.units)) {
      return approvedUnits > selectedServiceRequest.units! || approvedUnits <= 0;
    }

    // Disable submit if:
    //   - a negated auth status is chosen
    //   - a truthy withdrawn reason is provided
    //   - the calculated next negation status is WITHDRAWN
    //   - the service request's withdrawRequestorOption field is true
    //   - there is no specified withdraw requester on the form
    //   - the end-user is not a back office user
    if (isNegationAuthStatus(authStatus) && Boolean(withdrawnReason) && nextNegationStatus === "WITHDRAWN") {
      return (
        (selectedServiceRequest.withdrawRequestorOption || persistedServiceRequest?.withdrawRequestorOption) &&
        !withdrawRequester &&
        canEditWithdrawalRequestor
      );
    }

    // Disable submit if:
    //   - a negated auth status is chosen
    //   - there is no withdraw reason provided by the user
    if (isNegationAuthStatus(authStatus)) {
      return !Boolean(withdrawnReason);
    }

    // Disable submit if:
    //   - peer-to-peer outreach information is required
    //   - the user has not provided all the last attempt data (date, time, meridian, and outcome)
    if (requireP2POutreachInfo) {
      return !Boolean(
        lastPeerToPeerAttempt?.meridian &&
          lastPeerToPeerAttempt?.outcome &&
          lastPeerToPeerAttempt?.date?.length === 10 &&
          lastPeerToPeerAttempt?.time?.length === 5 &&
          getISODateStringFromStateFields(
            lastPeerToPeerAttempt?.date,
            lastPeerToPeerAttempt?.time,
            lastPeerToPeerAttempt?.meridian === "AM"
          )
      );
    } else {
      return false;
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const {
      authStatus,
      pendingReason,
      authorizationNote,
      partialApproval: { approvedUnits, approvedProcedureCodes },
      peerToPeer: { lastAttempt: lastPeerToPeerAttempt },
      tatExtension: { decision: tatExtensionDecision },
      withdrawVoid: { reason: withdrawnReason, requester: withdrawRequester },
    } = formState;

    const postedOutreachAttempt = saveOutreachAttempt ? await saveOutreachAttempt() : undefined;

    const getWithdrawRequestorPayload = () => {
      if (
        (selectedServiceRequest.withdrawRequestorOption || persistedServiceRequest?.withdrawRequestorOption) &&
        isNegationAuthStatus(authStatus)
      ) {
        if (!canEditWithdrawalRequestor) {
          return "PROVIDER";
        } else {
          return withdrawRequester;
        }
      }
    };

    let updateServiceRequestPayload: ServiceRequestUpdatePayload = {
      authStatus: nextNegationStatus || authStatus,
      pendingReason: authStatus === "PENDING" ? pendingReason : undefined,
      approvedUnits:
        authStatus === "PARTIALLY_APPROVED" && Boolean(selectedServiceRequest.units) ? approvedUnits : undefined,
      approvedSemanticProcedureCodes: authStatus === "PARTIALLY_APPROVED" ? approvedProcedureCodes : undefined,
      authorizationNote,
      withdrawnReason: isNegationAuthStatus(authStatus) ? withdrawnReason : undefined,
      withdrawRequestor: getWithdrawRequestorPayload(),
      claimsPaymentNote: formState.claimsPaymentNote,
      nextReviewDate: showNextReviewDate ? formState.nextReviewDate?.toISOString() : undefined,
    };

    if (ghpMemberRequestedLettersRefactor && sendOptionalDecisionLetter && isLatestServiceRequestMemberRequested) {
      updateServiceRequestPayload = {
        ...updateServiceRequestPayload,
        additionalRecipients: ["PROVIDER"],
      };
    }

    const retVal = await updateServiceRequest(updateServiceRequestPayload, {
      queryParams: { createClaimsNote: !!formState.claimsPaymentNote },
    });

    if (requireP2POutreachInfo && lastPeerToPeerAttempt?.outcome !== "") {
      const attemptedTimestamp = getISODateStringFromStateFields(
        lastPeerToPeerAttempt?.date || "",
        lastPeerToPeerAttempt?.time || "",
        lastPeerToPeerAttempt?.meridian === "AM"
      );
      await createP2POutreach({
        attemptedTimestamp,
        outcome: lastPeerToPeerAttempt?.outcome,
        healthPlanName: selectedServiceRequest.healthPlanName || "",
      });
    }

    if (tatExtensionDecision === "YES" && !selectedServiceRequest.turnAroundTimeExtension) {
      await extendServiceRequestTAT();
    }

    onEdit(postedOutreachAttempt);
    onSubmission?.(retVal);
    authorizationRefetch?.();
  };

  const withdrawnReasonSelectOptions = () => {
    const withdrawOptions =
      selectedServiceRequest.reviewOutcomeWithdrawOptions ||
      persistedServiceRequest?.reviewOutcomeWithdrawOptions ||
      withdrawnReasonOptions(
        canViewAllWithdrawnReasons,
        selectedServiceRequest.id,
        !!selectedServiceRequest.reconClaim || !!persistedServiceRequest?.reconClaim
      );
    return withdrawOptions;
  };

  const updateFormBasedOnServiceRequestSelection = (serviceRequestId: string) => {
    const selection = sortedAuthServiceRequests.find((sr) => sr.id === serviceRequestId) || selectedServiceRequest;
    setSelectedServiceRequest(selection);
    setNextNegationStatus(undefined);
    setFormState({
      authStatus: selection.authStatus,
      pendingReason: selection.pendingReason,
      authorizationNote: selection.authorizationNote,
      tatExtension: {
        eligible: !!selection.turnAroundTimeExtensionEligible,
        decision: "NO",
      },
      partialApproval: {
        approvedUnits: selection.approvedUnits,
        approvedProcedureCodes: Boolean(selection.approvedProcedureCodes)
          ? selection.approvedProcedureCodes
          : selection.procedureCodes,
      },
      peerToPeer: {
        attempted: undefined,
        lastAttempt: {
          ...extractDateTimeMeridianStrings(new Date()),
          outcome: "",
        },
        isPostDenialEligible:
          postDenialWorkflowEnabled &&
          determinePostDenialP2PEligibility({
            currentAuthStatus: selection.authStatus,
            initialDecisionDisposition: selection.initialDecisionDisposition,
            revisedDecisionTimestamp: selection.revisedDecisionTimestamp,
            postDecisionP2PExpanded,
          }),
      },
      withdrawVoid: {
        reason: selection.withdrawnReason,
        requester: selection.withdrawRequestor,
      },
      claimsPaymentNote: "",
      nextReviewDate: null,
    });
  };

  const ghpPendingReasons = [
    "MISSING_CLINICAL_INFO",
    "RN_REVIEW",
    "MD_REVIEW",
    "PEER_TO_PEER_REVIEW",
    "OUT_OF_NETWORK_REVIEW",
  ];

  // Display SCHEDULING_OUTREACH if FF is enabled or if SR already has it set as the pendingReason
  if (enablePendingSchedulingOutreach || formState.pendingReason === "SCHEDULING_OUTREACH") {
    ghpPendingReasons.push("SCHEDULING_OUTREACH");
  }

  const filteredPendingReasons = Object.entries(pendingReasonOptions)
    // "Other" shouldn't be used anymore, but it may still be present on requests,
    // so show it as an option only if it is the selected value (pendingReason)
    .filter(([id]) => {
      const { pendingReason } = formState;
      if (isGHPServiceRequest && !ghpPendingReasons.includes(id)) {
        return false;
      }
      if (id === "OTHER") {
        return pendingReason === "OTHER";
        // filter out ADMIN_VOID if it's not already selected or FF is disabled
      } else if (id === "ADMIN_VOID") {
        return pendingReason === "ADMIN_VOID" || pendingAdminVoidEnabled;
      } else if (id === "OUT_OF_SCOPE") {
        return isHumanaServiceRequest && diagnosisOutOfScopeEnabled;
      } else if (id === "SCHEDULING_OUTREACH") {
        return isGHPServiceRequest;
      } else if (id === "OUT_OF_NETWORK_REVIEW") {
        return oonReviewConfig?.enabled;
      } else {
        return true;
      }
    })
    .map(([id, option]) => ({ ...option, id }));

  const showNextReviewDate = shouldShowNextReviewDate({
    encounterType: selectedServiceRequest.encounterType,
    authStatus: formState.authStatus,
    patientStatus: srHasPatientStatus(selectedServiceRequest) ? selectedServiceRequest.patientStatus : undefined,
  });

  return (
    <>
      <Grid container component="form" onSubmit={onSubmit} spacing={2}>
        {sortedAuthServiceRequests.length > 1 && (
          <Grid item xs={12}>
            <SingleSelectDropdown
              label={"Select a request to update"}
              value={selectedServiceRequest.id}
              options={mapAuthServiceRequestsToDropdownOptions(sortedAuthServiceRequests)}
              onChange={(value) => {
                updateFormBasedOnServiceRequestSelection(value);
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <SingleSelectDropdown
            label={
              formState.peerToPeer?.isPostDenialEligible
                ? postDecisionP2PExpanded
                  ? "Choose post-decision status"
                  : "Choose post-denial status"
                : "Choose status"
            }
            value={formState.authStatus}
            options={nextAllowedAuthStatuses}
            onChange={(status) => {
              if (isAuthStatus(status)) {
                setFormState({
                  ...formState,
                  authStatus: status,
                });
              }
            }}
          />
        </Grid>
        {formState.authStatus === "PENDING" && (
          <>
            <Grid item xs={12}>
              <SingleSelectDropdown
                label="Choose pending reason"
                value={formState.pendingReason}
                options={allowedPendingReasons.length ? allowedPendingReasons : filteredPendingReasons}
                onChange={(reason) => {
                  if (isPendingReason(reason)) {
                    setFormState({
                      ...formState,
                      pendingReason: reason,
                    });
                  }
                }}
              />
            </Grid>
            {formState.pendingReason === "MD_REVIEW" && (
              <>
                <Grid item xs={12} style={{ paddingTop: spacing(3) }}>
                  <RadioGroup
                    row
                    label="Was a Peer-to-peer offered?"
                    options={[
                      { id: "YES", label: "Yes" },
                      { id: "NO", label: "No" },
                    ]}
                    onChange={(val) => {
                      setFormState({
                        ...formState,
                        peerToPeer: {
                          ...formState.peerToPeer,
                          attempted: val === "YES",
                        },
                      });
                    }}
                    value={formState.peerToPeer?.attempted ? "YES" : "NO"}
                  />
                </Grid>
                {formState.peerToPeer?.attempted && (
                  <>
                    <Grid container item xs={12} spacing={1} style={{ padding: spacing(1, 1, 2) }}>
                      <Grid item xs={3}>
                        <DateTextField
                          label="Last attempt date"
                          value={formState.peerToPeer?.lastAttempt?.date}
                          onChangeValue={(date) => {
                            setFormState({
                              ...formState,
                              peerToPeer: {
                                ...formState.peerToPeer,
                                lastAttempt: {
                                  ...formState.peerToPeer?.lastAttempt,
                                  date,
                                },
                              },
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TimeTextField
                          label="Time (EST)"
                          value={formState.peerToPeer?.lastAttempt?.time}
                          onChangeValue={(time) => {
                            setFormState({
                              ...formState,
                              peerToPeer: {
                                ...formState.peerToPeer,
                                lastAttempt: {
                                  ...formState.peerToPeer?.lastAttempt,
                                  time,
                                },
                              },
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <SingleSelectDropdown
                          options={[
                            { id: "AM", label: "AM" },
                            { id: "PM", label: "PM" },
                          ]}
                          label="AM/PM"
                          value={formState.peerToPeer?.lastAttempt?.meridian}
                          onChange={(meridian) => {
                            setFormState({
                              ...formState,
                              peerToPeer: {
                                ...formState.peerToPeer,
                                lastAttempt: {
                                  ...formState.peerToPeer?.lastAttempt,
                                  meridian,
                                },
                              },
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup<PeerToPeerOutreachOutcome | "">
                        label="What action did the practice take?"
                        options={[
                          { id: "UNRESPONSIVE", label: "Practice was unresponsive" },
                          { id: "DECLINED", label: "Practice declined peer to peer" },
                        ]}
                        value={formState.peerToPeer?.lastAttempt?.outcome}
                        onChange={(outcome) => {
                          setFormState({
                            ...formState,
                            peerToPeer: {
                              ...formState.peerToPeer,
                              lastAttempt: {
                                ...formState.peerToPeer?.lastAttempt,
                                outcome,
                              },
                            },
                          });
                        }}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
            {(formState.pendingReason === "MISSING_CLINICAL_INFO" ||
              formState.pendingReason === "MISSING_NON_CLINICAL_INFO") &&
              isGHPServiceRequest && (
                <>
                  <Grid item xs={12}>
                    <RadioGroup
                      row
                      label="Do you want to extend the due date of this request?"
                      options={[
                        { id: "YES", label: "Yes" },
                        { id: "NO", label: "No" },
                      ]}
                      disabled={!formState.tatExtension?.eligible}
                      helperText={helperTextForTATExtension(
                        formState.tatExtension?.decision || "",
                        !!formState.tatExtension?.eligible,
                        selectedServiceRequest
                      )}
                      value={formState.tatExtension?.decision}
                      onChange={(opt) => {
                        setFormState({
                          ...formState,
                          tatExtension: {
                            ...formState.tatExtension,
                            decision: opt,
                          },
                        });
                      }}
                    />
                  </Grid>
                </>
              )}
          </>
        )}
        {formState.authStatus === "PARTIALLY_APPROVED" && !formState.peerToPeer?.isPostDenialEligible && (
          <>
            <PartialProcedureCodeApproval
              requestedProcedureCodes={selectedServiceRequest.procedureCodes || []}
              approvedProcedureCodes={formState.partialApproval?.approvedProcedureCodes || []}
              setApprovedProcedureCodes={(procedureCodes: ProcedureCode[]) => {
                setFormState({
                  ...formState,
                  partialApproval: {
                    ...formState.partialApproval,
                    approvedProcedureCodes: procedureCodes,
                  },
                });
              }}
            />
            {Boolean(selectedServiceRequest.units) && (
              <PartialUnitsApproval
                requestedUnits={selectedServiceRequest.units || 0}
                approvedUnits={formState.partialApproval?.approvedUnits || 0}
                setApprovedUnits={(units: number) => {
                  setFormState({
                    ...formState,
                    partialApproval: {
                      ...formState.partialApproval,
                      approvedUnits: units,
                    },
                  });
                }}
                healthPlanName={selectedServiceRequest.healthPlanName || ""}
              />
            )}
          </>
        )}
        <Grid item xs={12} style={{ paddingTop: spacing(3) }}>
          {isNegationAuthStatus(formState.authStatus) ? (
            <>
              <div style={{ marginBottom: "8px" }}>
                <SingleSelectDropdown
                  fullWidth
                  label="Select reason for withdrawal"
                  options={withdrawnReasonSelectOptions()}
                  value={formState.withdrawVoid?.reason || ""}
                  onChange={(reason) => {
                    setFormState({
                      ...formState,
                      withdrawVoid: {
                        ...formState.withdrawVoid,
                        reason,
                      },
                    });
                  }}
                  maxMenuHeight={200}
                />
              </div>
              {(selectedServiceRequest.withdrawRequestorOption || persistedServiceRequest?.withdrawRequestorOption) &&
                nextNegationStatus === "WITHDRAWN" && (
                  <Row style={{ textAlign: "start", paddingTop: 0 }}>
                    <RadioGroup
                      label="Select who requested the withdrawal"
                      options={[
                        { id: "PATIENT", label: "Patient" },
                        { id: "PROVIDER", label: "Provider" },
                      ]}
                      value={formState.withdrawVoid?.requester}
                      onChange={(requester) => {
                        setFormState({
                          ...formState,
                          withdrawVoid: {
                            ...formState.withdrawVoid,
                            requester,
                          },
                        });
                      }}
                    />
                  </Row>
                )}
              <Row style={{ textAlign: "start", paddingTop: 0, color: colorsLight.font.main }}>
                <ModalMessage state={nextNegationStatus} />
              </Row>
            </>
          ) : (
            <TextField
              label="Note to provider"
              fullWidth
              multiline
              minRows={4}
              value={formState.authorizationNote}
              onChangeValue={(authNote) => {
                setFormState({
                  ...formState,
                  authorizationNote: authNote,
                });
              }}
            />
          )}
          <Box mt="32px">
            <TextField
              label="Claims payment note"
              fullWidth
              value={formState.claimsPaymentNote}
              onChangeValue={(newClaimsNote) => {
                setFormState((prevFormState) => ({ ...prevFormState, claimsPaymentNote: newClaimsNote }));
              }}
            />
          </Box>
          {showNextReviewDate && (
            <Box mt="32px">
              <NextReviewDateSelect
                nextReviewDate={formState.nextReviewDate}
                setNextReviewDate={(newDate) => {
                  setFormState((prevFormState) => ({ ...prevFormState, nextReviewDate: newDate }));
                }}
              />
            </Box>
          )}
          {ghpMemberRequestedLettersRefactor &&
            latestServiceRequest &&
            latestServiceRequest.isMemberRequestedAuth &&
            formState.authStatus &&
            isLetterDecision(formState.authStatus, formState.pendingReason, formState.tatExtension?.decision) &&
            !formState.peerToPeer?.isPostDenialEligible && (
              <div style={{ paddingTop: "20px" }}>
                <Checkbox
                  onChange={() => setSendOptionalDecisionLetters?.(!sendOptionalDecisionLetter)}
                  label={
                    <span style={{ fontSize: "15px", color: colorsLight.font.secondary, textAlign: "left" }}>
                      Send decision notification to the provider, in addition to the member who submitted the request
                    </span>
                  }
                ></Checkbox>
              </div>
            )}
        </Grid>
        <CenteredRow style={{ paddingTop: spacing(3) }}>
          <PrimaryButton
            disabled={outreachAttemptLoading || updateServiceRequestLoading || disableSaveButton()}
            loading={outreachAttemptLoading || updateServiceRequestLoading || createP2POutreachLoading}
            type="submit"
          >
            Save changes
          </PrimaryButton>
        </CenteredRow>
        <CenteredRow>
          <TertiaryButton onClick={onCancel}>Cancel</TertiaryButton>
        </CenteredRow>
      </Grid>
    </>
  );
}

const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={12} {...props} />;

function getISODateStringFromStateFields(dateStr: string, timeStr: string, mValue: boolean) {
  const [hours, minutes] = timeStr.split(":");
  return formatDateTimeStrToISO(dateStr, Number(hours) || 0, Number(minutes) || 0, mValue);
}

const isLetterDecision = (
  authStatus: AuthStatus,
  pendingReason: PendingReason | undefined,
  TATExtensionDecision: "YES" | "NO"
): boolean => {
  if (TATExtensionDecision === "YES") {
    return true;
  }
  switch (authStatus) {
    case "APPROVED":
    case "WITHDRAWN":
    case "DENIED":
    case "VOIDED":
    case "DISMISSED":
    case "PARTIALLY_APPROVED":
      return true;
    case "PENDING":
      return pendingReason === "MD_REVIEW";
    default:
      return false;
  }
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CenteredRow = styled((props) => <Grid item xs={12} {...props} />)({
  display: "flex",
  justifyContent: "center",
});

const pendingReasonOptions: Record<PendingReason, Omit<RadioGroupOption, "id">> = {
  MISSING_CLINICAL_INFO: { label: "Missing information (clinical)" },
  MISSING_NON_CLINICAL_INFO: { label: "Missing information (non-clinical)" },
  RN_REVIEW: { label: "In clinical review" },
  MD_REVIEW: { label: "In MD review" },
  NEW_HIRE_AUDIT_REVIEW: { label: "In new hire audit review" },
  PEER_TO_PEER_REVIEW: { label: "In peer-to-peer review" },
  OSCAR: { label: "Oscar" },
  PENDING_ASSESSMENT_SUBMISSION: { label: "Pending assessment submission" },
  OTHER: {
    label: "Other",
    disabled: true,
  },
  AUDIT_REVIEW: { label: "Audit Review" },
  NUDGE_OUTREACH: { label: "Nudge outreach" },
  ADMIN_VOID: { label: "Admin void" },
  OUT_OF_SCOPE: { label: "Out of scope - Send to Humana" },
  SCHEDULING_OUTREACH: { label: "Peer to peer scheduling" },
  OUT_OF_NETWORK_REVIEW: { label: "In out of network review" },
  LETTER_WRITING: { label: "Letter writing" },
};
function isPendingReason(ipt: unknown): ipt is PendingReason {
  return typeof ipt === "string" && pendingReasonOptions[ipt as PendingReason] !== undefined;
}

const srHasPatientStatus = (sr: any): sr is ServiceRequestResponse => {
  return !!sr.patientStatus;
};
