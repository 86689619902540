import React, { Dispatch, useEffect, useMemo, useState } from "react";

import Drawer, { DrawerProps } from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useControlled } from "@material-ui/core/utils";

import DoubleDrawerExpansionContext, { DoubleDrawerExpansionState } from "./DoubleDrawerExpansionContext";
import HorizontalCollapse from "./HorizontalCollapse";
import { colorsDark } from "../../themes/colors";

const useStylesDrawer = makeStyles((theme) => ({
  paper: {
    flexDirection: ({ anchor }: DoubleDrawerProps) => (anchor === "right" ? "row-reverse" : "row"),
    top: ({ clipUnderHeight }: DoubleDrawerProps) => clipUnderHeight || 0,
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(2),
    color: colorsDark.gray.light,
  },
  closeIcon: {
    height: 32,
    width: 32,
  },
}));

/** For a controlled expansion state you need both expandedMore and setExpandedMore */
interface ControlledExpansionProps {
  expandedMore: boolean;
  setExpandedMore: Dispatch<boolean>;
}

interface UncontrolledExpansionProps {
  expandedMore?: undefined;
  setExpandedMore?: never;
}

interface DoubleDrawerProps extends DrawerProps {
  anchor?: "left" | "right";
  clipUnderHeight?: number;
}

/**
 * A drawer that also can expand more and show additional content.
 */
export default function DoubleDrawer({
  children: childrenProp,
  expandedMore: expandedMoreProp,
  setExpandedMore: setExpandedMoreProp = () => {},
  onClose,
  clipUnderHeight,
  ...props
}: DoubleDrawerProps & (ControlledExpansionProps | UncontrolledExpansionProps)) {
  const [primaryContent, expandedContent] = React.Children.toArray(childrenProp);

  const { closeButton, closeIcon, ...drawerClasses } = useStylesDrawer({ anchor: props.anchor, clipUnderHeight });

  const [selectedExpandButtonId, setSelectedExpandButtonId] = useState<string | null>(null);
  const [expandedMore, setUncontrolledExpandedMore] = useControlled({
    controlled: expandedMoreProp,
    default: false,
    name: "DoubleDrawer",
    state: "expandedMore",
  });

  // ExpandedMore dispatch function that will handle either controlled or uncontrolled states
  const setExpandedMore = expandedMoreProp !== undefined ? setExpandedMoreProp : setUncontrolledExpandedMore;

  const contextValue = useMemo<DoubleDrawerExpansionState>(
    () => ({
      expandedMore,
      setExpandedMore,
      selectedExpandButtonId,
      setSelectedExpandButtonId,
    }),
    [expandedMore, selectedExpandButtonId, setExpandedMore]
  );

  const internalOnClose: DrawerProps["onClose"] = (event, reason) => {
    setExpandedMore(false);
    onClose?.(event, reason);
  };

  useEffect(() => {
    if (!expandedMore) {
      setSelectedExpandButtonId(null);
    }
  }, [expandedMore]);

  return (
    <Drawer classes={drawerClasses} onClose={internalOnClose} {...props}>
      <DoubleDrawerExpansionContext.Provider value={contextValue}>
        {onClose && (
          <IconButton
            className={closeButton}
            aria-label="close"
            onClick={(event) => internalOnClose(event, "backdropClick")}
          >
            <CloseIcon className={closeIcon} />
          </IconButton>
        )}
        {primaryContent}
        <HorizontalCollapse in={expandedMore}>{expandedContent}</HorizontalCollapse>
      </DoubleDrawerExpansionContext.Provider>
    </Drawer>
  );
}
