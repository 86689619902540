import { useMemo } from "react";

import { Patient } from "@coherehealth/core-platform-api";
import { Body1, Body2 } from "@coherehealth/common";
import Box from "@material-ui/core/Box";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import MuiInfoIcon from "@material-ui/icons/Info";
import { getCurrentPrimaryCoverage } from "util/patientUtils";

interface Props {
  header: string;
  subtext: string;
}

export default function PatientHealthPlanMessaging({ header, subtext }: Props) {
  return (
    <Box display="flex">
      <InfoIcon />
      <div>
        <StyledBody2>{header}</StyledBody2>
        <Body1 color="textSecondary">{subtext}</Body1>
      </div>
    </Box>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const InfoIcon = styled(MuiInfoIcon)(({ theme }) => ({
  color: theme.palette.info.light,
  marginRight: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledBody2 = styled(Body2)(({ theme }) => ({
  color: theme.palette.info.light,
  marginBottom: theme.spacing(0.5),
}));

export function usePatientHealthPlanMessageConfig(patient: Patient) {
  return useMemo(() => {
    const healthPlan = getCurrentPrimaryCoverage(patient)?.healthPlanName;
    if (healthPlan && healthPlan in messageConfigs) {
      return messageConfigs[healthPlan];
    }
    return null;
  }, [patient]);
}

interface MessageConfig {
  display: true;
  header: string;
  subtext: string;
}

const messageConfigs: Record<string, MessageConfig> = {
  Oscar: {
    display: true,
    header: "Cohere only accepts initial requests for Oscar",
    subtext: "Extensions should be submitted to Oscar via phone or fax",
  },
};
