import React, { useCallback, useEffect, useState, ComponentProps } from "react";
import {
  H2,
  InlineButton,
  Modal,
  PrimaryButton,
  SingleSelectDropdown,
  Subtitle2,
  RadioGroup,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
import {
  AuthStatus,
  ServiceRequestResponse,
  useGetNextNegationStatus,
  WithdrawRequestor,
} from "@coherehealth/core-platform-api";
import { DialogContent, DialogProps, Grid } from "@material-ui/core";
import { withdrawnReasonOptions } from "util/serviceRequest";
import { useAuthorized } from "authorization";
import WithdrawAndReplaceServiceRequests from "./WithdrawAndReplaceServiceRequests";
import { ModalMessage, SRNoLongerNeededButtonMessage } from "components/ClinicalReview/reviewUtils/utils";
import { useWithdrawModalStyles } from "../patientSummaryStyles";
import { TrackUserActivityProps, useTrackUserInteraction } from "util/userActivityTracker";

interface Props extends Pick<DialogProps, "open" | "onClose"> {
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onWithdraw?: (authStatus?: AuthStatus) => void;
  withdrawnReason: string | undefined;
  setWithdrawnReason: (reason: string) => void;
  withdrawRequestor: WithdrawRequestor | undefined;
  setWithdrawRequestor: (requestor: WithdrawRequestor) => void;
  submitting?: boolean;
  serviceRequestId: string;
  serviceRequest?: ServiceRequestResponse;
  authDecisionGroupId?: string;
  withdrawnReasonOptionsList: { id: string; label?: string | undefined }[] | undefined;
  srNoLongerNeededButton?: boolean;
}

export default function WithdrawModal({
  onWithdraw,
  withdrawnReason,
  setWithdrawnReason,
  withdrawRequestor,
  setWithdrawRequestor,
  submitting,
  serviceRequestId,
  serviceRequest,
  open,
  onClose = () => {},
  authDecisionGroupId,
  withdrawnReasonOptionsList,
  srNoLongerNeededButton,
}: Props) {
  const withdrawModalStyles = useWithdrawModalStyles();
  const canViewAllWithdrawnReasons = useAuthorized("VIEW_ALL_WITHDRAWAL_REASONS");
  const canEditWithdrawalRequestor = useAuthorized("EDIT_WITHDRAWAL_REQUESTOR");
  const [nextState, setNextState] = useState<AuthStatus>("WITHDRAWN");
  const [selectedServiceRequest, setServiceRequest] = useState(serviceRequest);
  const { refetch: serviceRequestRefetch } = useGetServiceRequestByIdWithFallback({
    id: serviceRequestId,
    lazy: true,
  });

  const refreshServiceRequest = useCallback(
    async (serviceRequestId: string) => {
      const srResponse = await serviceRequestRefetch({
        pathParams: { id: serviceRequestId },
      });
      if (srResponse) {
        setServiceRequest(srResponse);
      }
    },
    [serviceRequestRefetch]
  );

  useEffect(() => {
    if (!selectedServiceRequest && open && serviceRequestId) {
      refreshServiceRequest(serviceRequestId);
    }
  }, [open, refreshServiceRequest, selectedServiceRequest, serviceRequestId, serviceRequestRefetch]);

  const { mutate: nextStatus } = useGetNextNegationStatus({
    id: serviceRequestId,
  });

  useEffect(() => {
    const getNextState = async () => {
      const nextState = await nextStatus({ withdrawnReason });
      setNextState(nextState);
    };
    if (withdrawnReason) {
      getNextState();
    }
  }, [nextStatus, withdrawnReason]);

  const withdrawnReasonSelectOptions = () => {
    const withdrawOptions =
      serviceRequest?.reviewOutcomeWithdrawOptions ||
      withdrawnReasonOptionsList ||
      withdrawnReasonOptions(canViewAllWithdrawnReasons, serviceRequest?.id, !!serviceRequest?.reconClaim);
    return withdrawOptions;
  };

  const trackUserActivityInteraction = useTrackUserInteraction();

  const withdrawWithDuplicate = useCallback(async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    if (urlSearchParams.has("duplicate")) {
      const duplicateWorkflowId = urlSearchParams.get("duplicate");

      const userActivityDuplicateInteraction: TrackUserActivityProps = {
        event: "ORIGINAL_REQUEST_WITHDRAWAL",
        stage: "DUPLICATE_SUBMISSION_REVIEW",
        activityContext: {
          serviceRequestId: serviceRequestId,
          workflowId: duplicateWorkflowId || undefined,
        },
      };

      await trackUserActivityInteraction(userActivityDuplicateInteraction);
    }
  }, [trackUserActivityInteraction, serviceRequestId]);

  const handleWithdrawButtonClick = async (e: React.MouseEvent) => {
    if (onWithdraw) {
      await withdrawWithDuplicate();
      onWithdraw(nextState);
    }
    onClose(e, "backdropClick");
  };

  return !srNoLongerNeededButton ? (
    <Modal
      dialogTitleStyle={withdrawModalStyles.dialogTitleStyle}
      contentClassName={authDecisionGroupId ? withdrawModalStyles.withdrawAuthDecisionGroupContentStyle : ""}
      withdrawAuthDecisionGroup={authDecisionGroupId ? true : false}
      maxWidth={authDecisionGroupId ? "xl" : "sm"}
      open={open}
      onClose={onClose}
    >
      <DialogContent
        className={authDecisionGroupId ? withdrawModalStyles.withdrawAndReplaceContent : withdrawModalStyles.content}
      >
        <Grid container spacing={authDecisionGroupId ? 0 : 5}>
          <Grid item xs={8} className={withdrawModalStyles.center}>
            <H2 style={{ textAlign: "center" }}>Are you sure you want to withdraw this request?</H2>
            {authDecisionGroupId && (
              <Subtitle2 color="textSecondary" style={{ textAlign: "center", paddingTop: "16px" }}>
                Other requests in this auth group will be withdrawn too
              </Subtitle2>
            )}
          </Grid>
          <div>
            {authDecisionGroupId && (
              <Grid item xs={12} className={withdrawModalStyles.withdrawAndReplaceTopSpacing}>
                <WithdrawAndReplaceServiceRequests authDecisionGroupId={authDecisionGroupId} />
              </Grid>
            )}
          </div>
          <Grid item xs={12}>
            <SingleSelectDropdown
              label={!withdrawnReason ? "Select reason" : "Reason"}
              options={withdrawnReasonSelectOptions()}
              value={withdrawnReason || ""}
              onChange={(reason) => {
                setWithdrawnReason(reason);
              }}
              menuWidth={authDecisionGroupId ? 880 : 422}
              maxMenuHeight={200}
              data-testid="withdraw-modal"
            />
          </Grid>
          {withdrawnReason && (
            <>
              {serviceRequest?.withdrawRequestorOption &&
                nextState?.includes("WITHDRAWN") &&
                canEditWithdrawalRequestor && (
                  <Row style={{ textAlign: "start", paddingTop: 0 }}>
                    <RadioGroup
                      label="Select who requested the withdrawal"
                      options={[
                        { id: "PATIENT", label: "Patient" },
                        { id: "PROVIDER", label: "Provider" },
                      ]}
                      value={withdrawRequestor}
                      onChange={setWithdrawRequestor}
                    />
                  </Row>
                )}
              <Row style={{ textAlign: "start", paddingTop: 0 }}>
                <ModalMessage state={nextState} />
              </Row>
            </>
          )}
          {authDecisionGroupId ? (
            <Grid item xs={12} className={withdrawModalStyles.buttonContainer}>
              <PrimaryButton
                className={withdrawModalStyles.primaryButtonStyle}
                fullWidth={false}
                disabled={
                  !withdrawnReason ||
                  (!!withdrawnReason &&
                    canEditWithdrawalRequestor &&
                    serviceRequest?.withdrawRequestorOption &&
                    nextState?.includes("WITHDRAWN") &&
                    !withdrawRequestor)
                }
                loading={submitting}
                onClick={(e) => {
                  if (onWithdraw) {
                    onWithdraw(nextState);
                  }
                  onClose(e, "backdropClick");
                }}
              >
                Withdraw these requests
              </PrimaryButton>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <PrimaryButton
                fullWidth
                disabled={
                  !withdrawnReason ||
                  (!!withdrawnReason &&
                    canEditWithdrawalRequestor &&
                    serviceRequest?.withdrawRequestorOption &&
                    nextState?.includes("WITHDRAWN") &&
                    !withdrawRequestor)
                }
                loading={submitting}
                onClick={(e) => {
                  handleWithdrawButtonClick(e);
                }}
              >
                Withdraw request
              </PrimaryButton>
            </Grid>
          )}
          <Grid item className={withdrawModalStyles.center} style={{ paddingTop: "12px" }}>
            <InlineButton onClick={(e) => onClose(e, "backdropClick")}>Cancel</InlineButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Modal>
  ) : (
    <Modal
      dialogTitleStyle={withdrawModalStyles.dialogTitleStyle}
      contentClassName={authDecisionGroupId ? withdrawModalStyles.withdrawAuthDecisionGroupContentStyle : ""}
      withdrawAuthDecisionGroup={authDecisionGroupId ? true : false}
      maxWidth={authDecisionGroupId ? "xl" : "sm"}
      open={open}
      onClose={onClose}
    >
      <DialogContent
        className={authDecisionGroupId ? withdrawModalStyles.withdrawAndReplaceContent : withdrawModalStyles.content}
      >
        <Grid container spacing={authDecisionGroupId ? 0 : 5}>
          <Grid item className={withdrawModalStyles.center}>
            <H2 style={{ textAlign: "center" }}>Are you sure you want to mark this request as no longer needed?</H2>
          </Grid>
          <Grid item xs={12}>
            <SingleSelectDropdown
              label={!withdrawnReason ? "Select reason" : "Reason"}
              options={withdrawnReasonSelectOptions()}
              value={withdrawnReason || ""}
              onChange={(reason) => {
                setWithdrawnReason(reason);
              }}
              menuWidth={authDecisionGroupId ? 880 : 422}
              maxMenuHeight={200}
              data-testid="withdraw-modal"
            />
          </Grid>
          {withdrawnReason && (
            <>
              <Row style={{ textAlign: "start", paddingTop: 0 }}>
                <SRNoLongerNeededButtonMessage />
              </Row>
            </>
          )}

          <Grid item xs={12}>
            <PrimaryButton
              fullWidth
              disabled={!withdrawnReason}
              loading={submitting}
              onClick={(e) => {
                handleWithdrawButtonClick(e);
              }}
            >
              Mark as no longer needed
            </PrimaryButton>
          </Grid>

          <Grid item className={withdrawModalStyles.center} style={{ paddingTop: "12px" }}>
            <InlineButton onClick={(e) => onClose(e, "backdropClick")}>Cancel</InlineButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Modal>
  );
}
const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={12} {...props} />;
