import { H5, queueMgmtBaseUrl, SingleSelectDropdown } from "@coherehealth/common";
import { CircularProgress, Divider, Fade, Grid, makeStyles } from "@material-ui/core";
import { backToMenuView, CommonFaxSidebarViewProps, INCOMING_FAX_SIDEBAR_WIDTH } from "../common";
import FaxMetaDataDisplay from "../FaxMetaDataDisplay";
import CommonFaxFooter from "../CommonFaxFooter";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CaseOutcome, useCompleteServiceCase } from "@coherehealth/qm-api";
import config from "api/config";
import { FaxAttachmentContext } from "../FaxAttachmentContext";
import { useSnackbar } from "notistack";
import {
  ManualFaxForwardingNumber,
  ManualFaxForwardingCloseOption,
  useGetFaxForwardLinesAndOptions,
  useSendFax,
  SendFaxResponse,
} from "@coherehealth/core-platform-api";
import { warn as logWarning } from "logger";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "column",
  },
  buttonContainer: {
    marginTop: "auto",
    marginLeft: 1,
    marginBottom: theme.spacing(3),
    width: "100%",
  },
  leftButton: {
    alignItems: "flex-start",
    margin: "0px 8px 0px 12px",
  },
  rightButton: {
    minWidth: theme.spacing(25),
    margin: "0px 12px 0px 8px",
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
}));

type FaxOption = { id: string; label: string };

const ADJUSTED_INCOMING_FAX_SIDEBAR_WIDTH = INCOMING_FAX_SIDEBAR_WIDTH - 48;

const getFaxForwardingLinesOptions = (lines: ManualFaxForwardingNumber[]) => {
  return lines.map((line) => {
    return {
      id: line.label,
      label: line.label,
    };
  });
};

const getFaxForwardingCloseOptions = (lines: ManualFaxForwardingCloseOption[]) => {
  return lines.map((line) => {
    return {
      id: line.identifier,
      label: line.label,
    };
  });
};

const mapFaxForwardingLinesToNumbers = (lines: ManualFaxForwardingNumber[]) => {
  return lines.reduce((map, line) => {
    map[line.label] = line.value;
    return map;
  }, {} as { [key: string]: string });
};

const formatFaxNumber = (number: string) => {
  const numberWithoutSpaces = number.replace(/[^0-9]/g, "");
  return `1${numberWithoutSpaces}`;
};

export default function ForwardFax({ setSidebarView, url, blob, fileData, serviceCase }: CommonFaxSidebarViewProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [faxForwardLine, setFaxForwardLine] = useState<string>("");
  const [reasonForFaxForward, setReasonForFaxForward] = useState<string>("");
  const [selectFaxLineDropDownLabel, setSelectFaxLineDropDownLabel] = useState<string>("Select fax line");
  const [selectFaxForwardDropDownLabel, setSelectFaxForwardDropDownLabel] =
    useState<string>("Select reason to forward");

  const [requiresMoreAction, setRequiresMoreAction] = useState(!faxForwardLine || !reasonForFaxForward);
  const { caseId, faxAttachment } = useContext(FaxAttachmentContext);

  const [faxForwardingLinesOptions, setFaxForwardingLinesOptions] = useState<FaxOption[]>([]);
  const [faxForwardingLabelMappedToNumber, setFaxForwardingLabelMappedToNumber] = useState<{
    [key: string]: string;
  }>({});
  const [faxFowardingCloseOptions, setFaxForwardingCloseOptions] = useState<FaxOption[]>([]);

  const classes = useStyles();

  const { mutate: completeServiceCase, loading: serviceCaseLoading } = useCompleteServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: () => {
      window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
    },
  });

  const { mutate: getFaxForwardLinesAndOptions, loading: faxForwardLinesAndOptionsLoading } =
    useGetFaxForwardLinesAndOptions({
      queryParams: {
        featureConfigurationLevel: "HEALTH_PLAN",
        healthPlanName: serviceCase?.healthPlan || "",
      },
    });
  const { mutate: sendFax, loading: sendFaxLoading } = useSendFax({});

  const fetchFaxLinesAndOptions = useCallback(async () => {
    try {
      if (serviceCase?.healthPlan) {
        const faxForwardLinesAndOptions = await getFaxForwardLinesAndOptions();
        const faxForwardLines = faxForwardLinesAndOptions.manualFaxForwardingNumbers;
        const faxForwardCloseOptions = faxForwardLinesAndOptions.manualFaxForwardingCloseOptions;
        setFaxForwardingLinesOptions(getFaxForwardingLinesOptions(faxForwardLines));
        setFaxForwardingLabelMappedToNumber(mapFaxForwardingLinesToNumbers(faxForwardLines));
        setFaxForwardingCloseOptions(getFaxForwardingCloseOptions(faxForwardCloseOptions));
      }
    } catch (error) {
      logWarning(error);
    }
  }, [getFaxForwardLinesAndOptions, serviceCase?.healthPlan]);

  useEffect(() => {
    setRequiresMoreAction(!faxForwardLine || !reasonForFaxForward || faxForwardLinesAndOptionsLoading);
  }, [faxForwardLine, reasonForFaxForward, faxForwardLinesAndOptionsLoading]);

  useEffect(() => {
    fetchFaxLinesAndOptions();
  }, [faxAttachment?.healthPlanName, fetchFaxLinesAndOptions, serviceCase?.healthPlan]);

  const forwardFaxViaWestFax = async () => {
    const formData = new FormData();
    const faxNumber = formatFaxNumber(faxForwardingLabelMappedToNumber[faxForwardLine]);
    if (blob && faxForwardLine && faxNumber) {
      formData.set("file", blob);
      formData.set("recipientName", faxForwardLine);
      formData.set("faxNumber", faxNumber);
      formData.set("serviceCaseId", caseId || "");
    }

    const response = await sendFax(formData as unknown as void);
    const faxResponse = response as SendFaxResponse[];
    if (faxResponse[0]?.isSuccess) {
      return faxResponse[0]?.isSuccess;
    } else {
      enqueueSnackbar("Failed to send fax", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };
  async function forwardFaxAndCompleteServiceCase() {
    if (!requiresMoreAction) {
      try {
        const isFaxSuccessfullyForwarded = await forwardFaxViaWestFax();
        if (isFaxSuccessfullyForwarded) {
          const caseOutcome: CaseOutcome = {
            description: "Fax forwarded",
            outcome: "FORWARDED",
            dateCompleted: new Date().toISOString(),
            caseNotes: "No case notes",
            faxForwardLine: faxForwardLine,
          };
          if (caseId) {
            completeServiceCase(caseOutcome);
          } else {
            enqueueSnackbar("Failed to complete case: Case ID not found", {
              variant: "error",
              preventDuplicate: true,
            });
          }
        }
      } catch {
        enqueueSnackbar("Failed to forward fax", {
          variant: "error",
          preventDuplicate: true,
        });
      }
    } else {
      enqueueSnackbar("Failed to complete case - outcome unknown", {
        variant: "error",
      });
    }
  }

  return (
    <div className={classes.container}>
      <FaxMetaDataDisplay fileData={fileData} url={url} serviceCase={serviceCase} />
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <H5 data-public>Fax forwarding details</H5>
        </Grid>

        <Grid item xs={12}>
          <>
            {faxForwardingLinesOptions ? (
              <SingleSelectDropdown
                label={
                  <Fade in={Boolean(selectFaxLineDropDownLabel)} key={selectFaxLineDropDownLabel} timeout={300}>
                    <span>{selectFaxLineDropDownLabel}</span>
                  </Fade>
                }
                options={faxForwardingLinesOptions || []}
                value={faxForwardLine}
                onChange={(val) => {
                  setFaxForwardLine(val);
                  setSelectFaxLineDropDownLabel("Fax line");
                  setReasonForFaxForward("");
                }}
                menuWidth={ADJUSTED_INCOMING_FAX_SIDEBAR_WIDTH}
                dataPublic
              />
            ) : (
              <CircularProgress />
            )}
          </>
        </Grid>
        {faxForwardLine && (
          <Grid item xs={12}>
            <SingleSelectDropdown
              label={
                <Fade in={Boolean(selectFaxForwardDropDownLabel)} key={selectFaxForwardDropDownLabel} timeout={300}>
                  <span>{selectFaxForwardDropDownLabel}</span>
                </Fade>
              }
              options={faxFowardingCloseOptions || []}
              value={reasonForFaxForward || ""}
              onChange={(val) => {
                setReasonForFaxForward(val);
                setSelectFaxForwardDropDownLabel("Reason to forward");
              }}
              menuWidth={ADJUSTED_INCOMING_FAX_SIDEBAR_WIDTH}
              dataPublic
            />
          </Grid>
        )}
      </Grid>

      <CommonFaxFooter
        onCancelButtonClick={() => {
          setSidebarView(backToMenuView);
        }}
        onPrimaryButtonClick={forwardFaxAndCompleteServiceCase}
        disabledFinish={requiresMoreAction || sendFaxLoading}
        setSidebarView={setSidebarView}
        primaryButtonLoading={serviceCaseLoading || sendFaxLoading}
      />
    </div>
  );
}
