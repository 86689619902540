import { Body1, Body2, Caption, Sanitized, formatDateStrWithTime, parseDateFromISOString } from "@coherehealth/common";
import { getReviewOutcomeTitle, parseMillisecondsIntoReadableTime } from "../../../util/eventHistoryUtil";
import { Box } from "@material-ui/core";
import { formatPhoneNumber, phoneNumberToString } from "../../../util/phoneUtils";
import ClinicalReviewCard from "../../../common/ClinicalReviewCard";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { useState } from "react";
import { CaseType, FaxNoticeInfo, ReviewOutcome } from "@coherehealth/qm-api";
import { AttemptChannel, OutreachOutcome, OutreachType, PhoneNumber, Recipient } from "@coherehealth/core-platform-api";

type EventType = "INBOUND_OUTREACH" | "OUTBOUND_OUTREACH" | "CASE_COMPLETION";

type EventCardData = {
  span: string;
  title: string;
  body: string;
  shouldDisplay: boolean;
};

type CaseCompletionEventCardSections = {
  caseId: EventCardData;
  workRequested: EventCardData;
  workCompleted: EventCardData;
  timeInQueue: EventCardData;
  outcome: EventCardData;
  faxCallerId: EventCardData;
  faxSentTo: EventCardData;
  faxPageCount: EventCardData;
  cohereTemplate: EventCardData;
  faxId: EventCardData;
  reasonForNotice: EventCardData;
  subReasonForNotice: EventCardData;
  otherReasonForNotice: EventCardData;
  caseDocumentedReason: EventCardData;
  workNotes: EventCardData;
};

type InboundOutreachEventCardSections = {
  caseId: EventCardData;
  channel: EventCardData;
  phoneNumber: EventCardData;
  contact: EventCardData;
  outreachNotes: EventCardData;
};

type OutboundOutreachEventCardSections = {
  caseId: EventCardData;
  channel: EventCardData;
  contactMethod: EventCardData;
  workRequested: EventCardData;
  workCompleted: EventCardData;
  timeInQueue: EventCardData;
  contact: EventCardData;
  outcome: EventCardData;
  outreachNotes: EventCardData;
};

type OutreachAttemptDetails = {
  channel: AttemptChannel;
  recipient?: Recipient;
  memberInfo?: {
    memberPhoneNumber?: PhoneNumber;
    memberFaxNumber?: PhoneNumber;
    memberEmail?: string;
  };
  providerInfo?: {
    providerPhoneNumber?: PhoneNumber;
    providerFaxNumber?: PhoneNumber;
    providerEmail?: string;
  };
  workInfo?: {
    workCompleted?: string;
    workStarted?: string;
  };
  outreachType?: OutreachType;
  outreachNotes?: string;
  outreachOutcome?: OutreachOutcome;
};

type ServiceCaseDetails = {
  caseType?: CaseType;
  dateCreated?: string;
  faxDetail?: {
    cohereId?: string;
    faxNumber?: string;
    numberOfPages?: number;
    useCohereTemplate?: boolean;
  };
  outcome?: {
    caseNotes?: string;
    dateCompleted?: string;
    faxbackNumber?: string;
    noticeInfo?: FaxNoticeInfo;
    outcome?: string;
    reviewOutcome?: ReviewOutcome;
  };
};

export type CaseEventHistoryCardProps = {
  caseId: string;
  completedInfo: {
    completedBy: string;
    completedOn: string;
  };
  eventType: EventType;
  serviceCase?: ServiceCaseDetails;
  outreachAttempt?: OutreachAttemptDetails;
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 0,
    marginRight: 1,
    marginLeft: 1,
    display: "flex",
    justifyContent: "space-between",
    textAlign: "start",
    padding: theme.spacing(3, 3, 3, 3),
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "start",
    width: "100%",
  },
  icon: ({ expanded }: { expanded: boolean }) => ({
    transition: theme.transitions.create("transform"),
    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
    height: theme.spacing(4),
    width: theme.spacing(4),
    padding: theme.spacing(0.5),
    marginBottom: "auto",
  }),
  captionContainer: {
    display: "inline-block",
    flexDirection: "row",
    marginTop: theme.spacing(1.25),
  },
  thickFont: {
    fontFamily: "Gilroy-Medium",
  },
}));

const CaseEventHistoryCard = ({
  caseId = "--",
  completedInfo,
  eventType,
  serviceCase,
  outreachAttempt,
}: CaseEventHistoryCardProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const classes = useStyles({ expanded: isExpanded });

  const { formattedDateString, formattedTimeString } = (() => {
    const parsedDate = parseDateFromISOString(completedInfo.completedOn);
    return {
      formattedDateString: parsedDate.toLocaleDateString("en-US"),
      formattedTimeString: parsedDate.toLocaleTimeString("en-US"),
    };
  })();

  const formatDetails = (rawDetails?: string) => {
    switch (rawDetails) {
      case "CREATE_A_NEW_SUBMISSION":
        return "Create a new submission";
      case "EXISTING_AUTH_UPDATE":
        return "Existing auth update";
      case "EXISTING_AUTH_STATUS_INQUIRY":
        return "Existing auth status inquiry";
      case "SCHEDULING_OUTREACH":
        return "Peer to peer scheduling";
      case "MISSING_INFO_CLINICAL":
        return "Missing information (clinical)";
      case "MISSING_INFO_NON_CLINICAL":
        return "Missing information (non-clinical)";
      case "NOTIFICATION":
        return "Notification";
      case "NUDGES":
        return "Nudge";
      default:
        return "Other";
    }
  };

  const generateEventCardTitleLine = () => {
    if (eventType === "CASE_COMPLETION") {
      switch (serviceCase?.caseType) {
        case "FAX_INTAKE":
          return "Fax intake completed";
        case "RN_REVIEW":
          return "Clinical review completed";
        case "MD_REVIEW":
          return "MD review completed";
        case "P2P_REVIEW":
          return "Peer to peer review completed";
        case "LETTER_WRITING":
          return "Letter review completed";
      }
    } else {
      const formattedDetails = formatDetails(outreachAttempt?.outreachType);
      switch (eventType) {
        case "INBOUND_OUTREACH":
          return `Inbound - ${formattedDetails}`;
        case "OUTBOUND_OUTREACH":
          return `Outbound - ${formattedDetails}`;
      }
    }
    return "Failed to generate event card title!";
  };

  const formatRecipientText = (recipient: string = "") => {
    return recipient
      .toLowerCase()
      .split("_")
      .map((word) => word.substring(0, 1).toUpperCase().concat(word.substring(1)))
      .join(" ");
  };

  const formatOutcomeText = (outcome?: OutreachOutcome) => {
    const outcomeLabels: Record<OutreachOutcome, string> = {
      FAILED: "Failed",
      SUCCESS: "Success",
      OFFICE_STAFF_MEMBER_NOTIFIED: "Office staff member notified",
      LEFT_SCRIPTED_MESSAGE: "Left scripted message",
      P2P_RIGHTS_GIVEN: "Peer to peer rights given",
      UNABLE_TO_REACH: "Unable to reach - Office closed",
      MEMBER_NOTIFIED: "Member notified",
      AUTHORIZED_REP_NOTIFIED: "Parent/guardian/authorized representative notified",
      UNABLE_TO_REACH_NO_ANSWER: "Unable to reach - No answer",
    };
    return outcome && outcomeLabels[outcome] ? outcomeLabels[outcome] : "Unknown outcome provided";
  };

  const formatChannelText = (channel: string = "") => {
    return channel.substring(0, 1) + channel.substring(1).toLowerCase();
  };

  const determinePhoneNumberToDisplay = (outreachAttempt: OutreachAttemptDetails) => {
    switch (outreachAttempt.recipient) {
      case "MEMBER":
      case "MEMBER_REPRESENTATIVE":
        return phoneNumberToString(outreachAttempt.memberInfo?.memberPhoneNumber) || "--";
      case "ORDERING_PROVIDER":
      case "PERFORMING_PROVIDER":
        return phoneNumberToString(outreachAttempt.providerInfo?.providerPhoneNumber) || "--";
      default:
        return "--";
    }
  };

  const determineEmailToDisplay = (outreachAttempt: OutreachAttemptDetails) => {
    switch (outreachAttempt.recipient) {
      case "MEMBER":
      case "MEMBER_REPRESENTATIVE":
        return outreachAttempt.memberInfo?.memberEmail || "--";
      case "ORDERING_PROVIDER":
      case "PERFORMING_PROVIDER":
        return outreachAttempt.providerInfo?.providerEmail || "--";
      default:
        return "--";
    }
  };

  const determineFaxNumberToDisplay = (outreachAttempt: OutreachAttemptDetails) => {
    switch (outreachAttempt.recipient) {
      case "MEMBER":
      case "MEMBER_REPRESENTATIVE":
        return phoneNumberToString(outreachAttempt.memberInfo?.memberFaxNumber) || "--";
      case "ORDERING_PROVIDER":
      case "PERFORMING_PROVIDER":
        return phoneNumberToString(outreachAttempt.providerInfo?.providerFaxNumber) || "--";
      default:
        return "--";
    }
  };

  const generateOutreachContactInfo = (outreachAttemptDetails: OutreachAttemptDetails) => {
    const contactInfoConfig: EventCardData = {
      span: "span 5",
      title: "",
      body: "",
      shouldDisplay: true,
    };

    switch (outreachAttemptDetails.channel) {
      case "PHONE":
        contactInfoConfig.title = "Phone number";
        contactInfoConfig.body = determinePhoneNumberToDisplay(outreachAttemptDetails);
        break;
      case "FAX":
        contactInfoConfig.title = "Fax number";
        contactInfoConfig.body = determineFaxNumberToDisplay(outreachAttemptDetails);
        break;
      case "EMAIL":
        contactInfoConfig.title = "Email address";
        contactInfoConfig.body = determineEmailToDisplay(outreachAttemptDetails);
        break;
    }

    contactInfoConfig.span = "span 15";

    return contactInfoConfig;
  };

  const createEventCardDataConfig = (
    span: string,
    title: string,
    body: string = "",
    shouldDisplay: boolean = true
  ) => ({
    span,
    title,
    body,
    shouldDisplay: shouldDisplay,
  });

  const generateCaseCompletionEventCardBody = () => {
    if (!serviceCase) {
      return <></>;
    }

    const { outcome } = serviceCase;

    const elementSections: CaseCompletionEventCardSections = {
      caseId: createEventCardDataConfig("span 4", "Case ID", caseId),
      workRequested: createEventCardDataConfig(
        "span 7",
        "Work requested",
        formatDateStrWithTime(serviceCase.dateCreated)
      ),
      workCompleted: createEventCardDataConfig(
        "span 7",
        "Work completed",
        outcome?.dateCompleted ? formatDateStrWithTime(outcome?.dateCompleted) : "--"
      ),
      timeInQueue: createEventCardDataConfig(
        "span 5",
        "Time in queue",
        outcome?.dateCompleted && serviceCase.dateCreated
          ? parseMillisecondsIntoReadableTime(
              new Date(outcome?.dateCompleted).getTime() - new Date(serviceCase.dateCreated).getTime()
            )
          : "--"
      ),
      outcome: createEventCardDataConfig(
        "span 24",
        "Review outcome",
        serviceCase?.outcome?.reviewOutcome ? getReviewOutcomeTitle(serviceCase?.outcome?.reviewOutcome) : "--",
        serviceCase.caseType === "RN_REVIEW" ||
          serviceCase.caseType === "MD_REVIEW" ||
          serviceCase.caseType === "P2P_REVIEW"
      ),
      faxCallerId: createEventCardDataConfig(
        "span 5",
        "Fax caller ID",
        serviceCase.faxDetail?.faxNumber || "--",
        serviceCase.caseType === "FAX_INTAKE"
      ),
      faxSentTo: createEventCardDataConfig(
        "span 7",
        "Fax sent to",
        outcome?.faxbackNumber ? formatPhoneNumber(outcome.faxbackNumber) : "--",
        outcome?.outcome === "FAXBACK"
      ),
      faxPageCount: createEventCardDataConfig(
        "span 7",
        "Fax page count",
        `${serviceCase.faxDetail?.numberOfPages}` || "--",
        serviceCase.caseType === "FAX_INTAKE"
      ),
      cohereTemplate: createEventCardDataConfig(
        "span 7",
        "Cohere template",
        serviceCase.faxDetail?.useCohereTemplate ? "Yes" : "No",
        serviceCase.caseType === "FAX_INTAKE"
      ),
      faxId: createEventCardDataConfig(
        "span 5",
        "Fax ID",
        serviceCase?.faxDetail?.cohereId,
        !!serviceCase.faxDetail?.cohereId
      ),
      reasonForNotice: createEventCardDataConfig(
        "span 24",
        "Reason for notice",
        outcome?.noticeInfo?.reason === "MISSING_INFO" ? "Missing information" : "Out of Cohere scope",
        outcome?.outcome === "FAXBACK"
      ),
      subReasonForNotice: createEventCardDataConfig(
        "span 24",
        "Sub-reason for notice",
        outcome?.noticeInfo?.subReason?.join(", ") || "--",
        outcome?.outcome === "FAXBACK"
      ),
      otherReasonForNotice: createEventCardDataConfig(
        "span 24",
        "Other reason(s) for notice",
        outcome?.noticeInfo?.additionalNotes || "--",
        outcome?.outcome === "FAXBACK"
      ),
      caseDocumentedReason: createEventCardDataConfig(
        "span 24",
        "Case documented reason",
        serviceCase.outcome?.outcome === "HEALTH_HELP"
          ? "This is a Health Help case and I have resolved it"
          : serviceCase.outcome?.outcome === "ANOTHER_SYSTEM"
          ? "This case was completed in another system"
          : "This is a Claims case and I have resolved it",
        serviceCase.outcome?.outcome === "CLAIM" ||
          serviceCase?.outcome?.outcome === "HEALTH_HELP" ||
          serviceCase.outcome?.outcome === "ANOTHER_SYSTEM"
      ),
      workNotes: createEventCardDataConfig(
        "span 24",
        "Work notes",
        serviceCase.outcome?.caseNotes || "--",
        serviceCase.outcome?.outcome === "CLAIM" || serviceCase?.outcome?.outcome === "HEALTH_HELP"
      ),
    };

    return (
      <Box display="grid" gridTemplateColumns="repeat(24, 1fr)" gridRowGap={20}>
        {Object.keys(elementSections).map((key) => {
          const section = elementSections[key as keyof CaseCompletionEventCardSections];
          return section.shouldDisplay ? (
            <Box gridColumn={section.span} key={`CaseCompletionEventCardSections_${key}`}>
              <ColumnEntry>
                <DetailsCaption>{section.title}</DetailsCaption>
                <Body1>{section.body}</Body1>
              </ColumnEntry>
            </Box>
          ) : (
            <></>
          );
        })}
      </Box>
    );
  };

  const generateInboundOutreachEventCardBody = () => {
    if (!outreachAttempt) {
      return <></>;
    }

    const elementSections: InboundOutreachEventCardSections = {
      caseId: createEventCardDataConfig("span 4", "Case ID", caseId),
      channel: createEventCardDataConfig("span 4", "Outreach channel", formatChannelText(outreachAttempt?.channel)),
      phoneNumber: createEventCardDataConfig("span 7", "Phone number", determinePhoneNumberToDisplay(outreachAttempt)),
      contact: createEventCardDataConfig("span 6", "Contact", formatRecipientText(outreachAttempt.recipient)),
      outreachNotes: createEventCardDataConfig("span 24", "Outreach notes", outreachAttempt?.outreachNotes),
    };

    return (
      <Box display="grid" gridTemplateColumns="repeat(24, 1fr)" gridRowGap={20}>
        {Object.keys(elementSections).map((key) => {
          const section = elementSections[key as keyof InboundOutreachEventCardSections];
          return section.shouldDisplay ? (
            <Box gridColumn={section.span} key={`InboundOutreachEventCardSections_${key}`}>
              <ColumnEntry>
                <DetailsCaption>{section.title}</DetailsCaption>
                <Body1>
                  <Sanitized __html={section.body}></Sanitized>
                </Body1>
              </ColumnEntry>
            </Box>
          ) : (
            <></>
          );
        })}
      </Box>
    );
  };

  const isClinicalOutreachEvent = () => {
    return ["MISSING_INFO_NON_CLINICAL", "NOTIFICATION"].includes(outreachAttempt?.outreachType || "");
  };

  const generateOutboundOutreachEventCardBody = () => {
    if (!outreachAttempt) {
      return <></>;
    }

    const elementSections: OutboundOutreachEventCardSections = {
      caseId: createEventCardDataConfig("span 4", "Case ID", caseId),
      channel: createEventCardDataConfig("span 4", "Outreach channel", formatChannelText(outreachAttempt?.channel)),
      contactMethod: generateOutreachContactInfo(outreachAttempt),
      workRequested: createEventCardDataConfig(
        "span 5",
        "Work requested",
        outreachAttempt?.workInfo?.workStarted ? formatDateStrWithTime(outreachAttempt?.workInfo?.workStarted) : "--"
      ),
      workCompleted: createEventCardDataConfig(
        "span 5",
        "Work completed",
        outreachAttempt?.workInfo?.workCompleted
          ? formatDateStrWithTime(outreachAttempt?.workInfo?.workCompleted)
          : "--"
      ),
      timeInQueue: createEventCardDataConfig(
        "span 4",
        "Time in queue",
        outreachAttempt?.workInfo?.workCompleted && outreachAttempt?.workInfo?.workStarted
          ? parseMillisecondsIntoReadableTime(
              new Date(outreachAttempt?.workInfo?.workCompleted).getTime() -
                new Date(outreachAttempt?.workInfo?.workStarted).getTime()
            )
          : "--"
      ),
      outcome: createEventCardDataConfig(
        "span 10",
        "Outreach outcome",
        formatOutcomeText(outreachAttempt.outreachOutcome)
      ),
      contact: createEventCardDataConfig("span 20", "Contact", formatRecipientText(outreachAttempt.recipient)),
      outreachNotes: createEventCardDataConfig(
        "span 24",
        "Outreach notes",
        outreachAttempt?.outreachNotes,
        isClinicalOutreachEvent()
      ),
    };

    return (
      <Box display="grid" gridTemplateColumns="repeat(24, 1fr)" gridRowGap={20}>
        {Object.keys(elementSections).map((key) => {
          const section = elementSections[key as keyof OutboundOutreachEventCardSections];
          return section.shouldDisplay ? (
            <Box gridColumn={section.span} key={`OutboundOutreachEventCardSections_${key}`}>
              <ColumnEntry>
                <DetailsCaption>{section.title}</DetailsCaption>
                <Body1>
                  <Sanitized __html={section.body}></Sanitized>
                </Body1>
              </ColumnEntry>
            </Box>
          ) : (
            <></>
          );
        })}
      </Box>
    );
  };

  const generateCardBody = () => {
    if (eventType === "CASE_COMPLETION") {
      return generateCaseCompletionEventCardBody();
    } else if (eventType === "INBOUND_OUTREACH") {
      return generateInboundOutreachEventCardBody();
    } else if (eventType === "OUTBOUND_OUTREACH") {
      return generateOutboundOutreachEventCardBody();
    }
  };

  return (
    <CardContainer>
      <ClinicalReviewCard
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        header={
          <div className={classes.textContainer}>
            <Body2>{generateEventCardTitleLine()}</Body2>
            <div className={classes.captionContainer}>
              <div>
                <Caption>
                  <span>
                    Completed on {formattedDateString} at {formattedTimeString} by {completedInfo.completedBy}
                  </span>
                </Caption>
              </div>
            </div>
          </div>
        }
      >
        {isExpanded && generateCardBody()}
      </ClinicalReviewCard>
    </CardContainer>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DetailsCaption = styled(Caption)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ColumnEntry = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CardContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(1.5, 0, 1.5),
}));

export default CaseEventHistoryCard;
