import React from "react";
import { lighten, makeStyles, Theme } from "@material-ui/core/styles";
import { SnackbarProvider as BaseProvider } from "notistack";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import { colorsDark } from "../../themes";

export interface StyleProps {
  style: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  variantSuccess: (props) => ({
    backgroundColor:
      props.style === "light"
        ? notistackImportantHack(theme.palette.success.light)
        : notistackImportantHack(colorsDark.success.light),
    color:
      props.style === "light"
        ? notistackImportantHack(theme.palette.success.main)
        : notistackImportantHack(colorsDark.success.dark),
    iconColor: colorsDark.warning.light,
  }),

  variantError: (props) => ({
    backgroundColor:
      props.style === "light"
        ? notistackImportantHack(theme.palette.error.light)
        : notistackImportantHack(colorsDark.error.light),
    color:
      props.style === "light"
        ? notistackImportantHack(theme.palette.error.main)
        : notistackImportantHack(colorsDark.error.dark),
  }),

  variantWarning: (props) => ({
    backgroundColor:
      props.style === "light"
        ? notistackImportantHack(theme.palette.warning.light)
        : notistackImportantHack(colorsDark.warning.light),
    color:
      props.style === "light"
        ? notistackImportantHack(theme.palette.warning.dark)
        : notistackImportantHack(colorsDark.warning.dark),
  }),

  variantInfo: (props) => ({
    backgroundColor:
      props.style === "light"
        ? notistackImportantHack(lighten(theme.palette.info.light, 0.8))
        : notistackImportantHack(colorsDark.info.faint10),
    color:
      props.style === "light"
        ? notistackImportantHack(theme.palette.info.main)
        : notistackImportantHack(colorsDark.info.dark),
  }),

  message: {
    ...theme.typography.body2,
  },
  lessPadding: {
    paddingLeft: 16,
    borderRadius: 5,
  },
}));

// Hackaround to https://github.com/iamhosseindhv/notistack/issues/305
const notistackImportantHack = (ipt: string): string => `${ipt} !important`;

// compare with https://github.com/iamhosseindhv/notistack/blob/master/src/utils/defaultIconVariants.tsx
const iconStyles = {
  fontSize: 20,
  marginRight: 16,
  width: 24,
  height: 24,
};

const getIconStyle = (color: string) => ({
  ...iconStyles,
  color: notistackImportantHack(color),
});

const SnackbarProvider = ({ children, theme }: { children: React.ReactNode; theme: Theme }) => {
  const styleProps = { style: theme.palette?.type ?? "light" }; // Get the theme type ('light' or 'dark')
  const classes = useStyles(styleProps);

  const getColor = (colorType: "success" | "warning" | "error" | "info") => {
    switch (colorType) {
      case "success":
        return theme.palette.type === "dark" ? colorsDark.success.main : theme.palette.success.main;
      case "warning":
        return theme.palette.type === "dark" ? colorsDark.warning.dark : theme.palette.warning.main;
      case "error":
        return theme.palette.type === "dark" ? colorsDark.error.main : theme.palette.error.main;
      case "info":
        return theme.palette.type === "dark" ? colorsDark.info.dark : theme.palette.info.dark;
      default:
        return theme.palette.text.primary; // Blue default color
    }
  };

  const successStyle = getIconStyle(getColor("success"));
  const warningStyle = getIconStyle(getColor("warning"));
  const errorStyle = getIconStyle(getColor("error"));
  const infoStyle = getIconStyle(getColor("info"));

  const iconVariants = {
    success: <CheckCircleIcon style={successStyle} />,
    warning: <ErrorIcon style={warningStyle} />,
    error: <CancelIcon style={errorStyle} />,
    info: <InfoIcon style={infoStyle} />,
  };

  return (
    <BaseProvider maxSnack={3} classes={classes} iconVariant={iconVariants}>
      {children}
    </BaseProvider>
  );
};

export default SnackbarProvider;
