import { H6, InformativeModal } from "@coherehealth/common";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import EditIcon from "@material-ui/icons/Edit";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Box, IconButton, styled, useTheme } from "@material-ui/core";
import AuthNumberEntryForm from "components/ServiceRequestStatusDisplay/AuthNumberEntryForm";
import { useState } from "react";
import { useAuthorized } from "authorization";

interface Props {
  serviceRequest: ServiceRequestResponse;
  onEdit: () => void;
  disableEdit?: boolean;
}

export default function AuthNumber({ disableEdit = false, serviceRequest, onEdit }: Props) {
  const hasAuthNumber = !!serviceRequest?.authNumber;
  const [editAuthNumberModalOpen, setEditAuthNumberModalOpen] = useState(false);
  const theme = useTheme();
  const { spacing } = theme;
  const canEditAuthNumber = useAuthorized("EDIT_SERVICE_REQUEST_STATUS");

  const onSubmitAuthNumberEdit = () => {
    onEdit();
    setEditAuthNumberModalOpen(false);
  };
  return (
    <AuthNumberContainer>
      <AuthNumberHeader>
        {hasAuthNumber ? `Auth number: #${serviceRequest?.authNumber}` : "No auth number"}
      </AuthNumberHeader>
      {!disableEdit && canEditAuthNumber ? (
        <EditIconButton onClick={() => setEditAuthNumberModalOpen(true)} data-testid="edit-auth-number-button">
          <BlueEditIcon />
        </EditIconButton>
      ) : (
        <Box height={48} /> // to account for the lack of padding of the icon button, keeps page flush
      )}
      <InformativeModal
        open={editAuthNumberModalOpen}
        onClose={() => setEditAuthNumberModalOpen(false)}
        headerText={`${hasAuthNumber ? "Edit" : "Manually add"} auth number`}
        additionalInfoElement={
          <div style={{ paddingTop: spacing(4) }}>
            <AuthNumberEntryForm serviceRequest={serviceRequest} onEdit={onSubmitAuthNumberEdit} />
          </div>
        }
      />
    </AuthNumberContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AuthNumberContainer = styled("div")(({ theme }) => ({
  display: "flex",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AuthNumberHeader = styled(H6)(({ theme }) => ({
  marginRight: theme.spacing(0.25),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const BlueEditIcon = styled(EditIcon)(({ theme }) => ({
  color: theme.palette.primary.dark,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const EditIconButton = styled(IconButton)(({ theme }) => ({
  position: "relative",
  top: -16,
}));
