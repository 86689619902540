import { InitCurrentReview, InitPatientDetails, InitQMCase, InitServiceRequest, SetLeftTab } from "./types";
import { LeftPanelTabs } from "../reviewUtils/utils";

export function initServiceRequest(serviceRequestId: InitServiceRequest["serviceRequestId"]): InitServiceRequest {
  return {
    type: "INIT_SERVICE_REQUEST",
    serviceRequestId,
  };
}
export function initPatientDetails(patientId: InitPatientDetails["patientId"]): InitPatientDetails {
  return {
    type: "INIT_PATIENT_DETAILS",
    patientId,
  };
}

export function initQMCase(caseId: InitQMCase["caseId"], caseType: InitQMCase["caseType"]): InitQMCase {
  return {
    type: "INIT_QM_CASE",
    caseId,
    caseType,
  };
}
export function initCurrentReview(
  currentReview: InitCurrentReview["currentReview"],
  isReviewEditMode: InitCurrentReview["isReviewEditMode"]
): InitCurrentReview {
  return {
    type: "INIT_CURRENT_REVIEW",
    currentReview,
    isReviewEditMode,
  };
}

export function setLeftTabAction(leftColumnTab: LeftPanelTabs): SetLeftTab {
  return {
    type: "SET_LEFT_TAB",
    leftColumnTab,
  };
}
