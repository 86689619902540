import { makeStyles } from "@material-ui/core";

export const usePatientSearchResultInteractionStyles = makeStyles((theme) => ({
  startAuthRequestButtonWrapper: {
    textAlign: "right",
    margin: 0,
    position: "relative",
    top: "50%",
    mstransform: "translateY(-50%)",
    transform: "translateY(-50%)",
  },
  startAuthRequestButton: {
    padding: theme.spacing(2, 2.5),
  },
  authTransferButtonWrapper: {
    height: "100%",
    display: "flex",
  },
  authTransferButton: {
    height: "min-content",
    display: "flex",
    alignSelf: "center",
  },
}));
