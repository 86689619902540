import { useMemo } from "react";
import NoResults from "components/ReferralManagement/Dashboard/NoResults";
import { Helmet } from "react-helmet-async";
import Grid from "@material-ui/core/Grid";
import ReferralRequestSummaryCard from "./ReferralRequestSummaryCard";
import { useLocation } from "react-router-dom";
import useReferralPatientSummary from "./useReferralPatientSummary";

interface Props {
  patientId: string;
}

export default function ReferralRequestPatientSummaryPage({ patientId }: Props) {
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const reviewReferralRequestId = search.get("reviewReferralRequestId") || undefined;
  const { referralRequestsLoading, selectedReferralRequestData, filteredReferralRequests } = useReferralPatientSummary(
    patientId,
    reviewReferralRequestId
  );

  const referralRequests = [selectedReferralRequestData].concat(filteredReferralRequests || []);
  const showEmptyResults = Array.isArray(referralRequests) && referralRequests.length === 0;

  return (
    <>
      <Helmet>
        <title>Cohere | Patient Referrals</title>
      </Helmet>
      {referralRequestsLoading ? (
        <NoResults heading="Loading your referrals" message="This might take a second." />
      ) : showEmptyResults ? (
        <NoResults heading="No referrals yet" message="Get started by adding a referral above." />
      ) : (
        referralRequests.map(
          (referralRequest, idx) =>
            referralRequest && (
              <Grid key={referralRequest.id}>
                {
                  <ReferralRequestSummaryCard
                    referralRequest={referralRequest}
                    expand={referralRequest.id === reviewReferralRequestId}
                  />
                }
              </Grid>
            )
        )
      )}
    </>
  );
}
