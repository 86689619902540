import { Dispatch, SetStateAction, useMemo, useState } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Grid, styled } from "@material-ui/core";
import { H5, Body1, incrementClickCount, UserClickInfoClinicalReviewPage, useFeature } from "@coherehealth/common";
import { OutreachOpportunity as Opportunity, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import NudgeOpportunity from "../../Nudges/NudgeOpportunity";
import ClinicalReviewCard from "common/ClinicalReviewCard";
import { useTheme } from "@material-ui/core/styles";
import { countFailedAttempts, countOutreachTypeAttempts, countSuccessfulAttempts } from "util/NudgeUtils";
import OutreachOpportunity from "components/Nudges/OutreachOpportunity";

interface Props {
  serviceRequest: ServiceRequestResponse | null;
  outreachOpportunities: Opportunity[] | null;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
}

export default function NudgeInformation({ serviceRequest, outreachOpportunities, setUserClickInfoTracking }: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const successMIAttempt = useMemo(() => countSuccessfulAttempts(outreachOpportunities), [outreachOpportunities]);
  const failedMIAttempt = useMemo(() => countFailedAttempts(outreachOpportunities), [outreachOpportunities]);
  const schedulingAttepmt = useMemo(
    () => countOutreachTypeAttempts(outreachOpportunities, "SCHEDULING_OUTREACH"),
    [outreachOpportunities]
  );
  const NudgeAttemptNumber = useMemo(
    () => countOutreachTypeAttempts(outreachOpportunities, "NUDGES"),
    [outreachOpportunities]
  );
  const missingInformationFF = useFeature("missingInformationFF");

  const updateUserClickInfo = () => {
    setUserClickInfoTracking && incrementClickCount("NudgesCardToggle", "", -1, setUserClickInfoTracking);
  };
  const { spacing } = useTheme();
  return (
    <ClinicalReviewCard
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      updateUserClickInfo={updateUserClickInfo}
      regionContainerPadding={"0px"}
      dividerContainerMargin={"0px"}
      autoScroll={true}
      header={
        missingInformationFF ? (
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: 6 }}>
              <H5>Clinical Outreach</H5>
            </Grid>
            <div style={{ marginRight: 24, marginBottom: isExpanded ? "" : 4 }}>
              <StyledBody1>
                {NudgeAttemptNumber} nudge attempt
                {NudgeAttemptNumber !== 1 && "s"}
              </StyledBody1>
            </div>
            <div style={{ marginRight: 24, marginBottom: isExpanded ? 0 : 4 }}>
              <StyledBody1>
                {successMIAttempt} successful missing info attempt
                {successMIAttempt !== 1 && "s"}
              </StyledBody1>
            </div>
            <div style={{ marginRight: 24, marginBottom: isExpanded ? 0 : 4 }}>
              <StyledBody1>
                {failedMIAttempt} failed missing info attempt
                {failedMIAttempt !== 1 && "s"}
              </StyledBody1>
            </div>
            {schedulingAttepmt > 0 && (
              <div>
                <StyledBody1>
                  {schedulingAttepmt} scheduling attempt{schedulingAttepmt !== 1 && "s"}
                </StyledBody1>
              </div>
            )}
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: 6 }}>
              <H5>Nudges</H5>
            </Grid>
            <Grid item xs={12}>
              <StyledBody1>
                {outreachOpportunities?.length} nudge attempt
                {outreachOpportunities && outreachOpportunities?.length !== 1 && "s"}
              </StyledBody1>
            </Grid>
          </Grid>
        )
      }
    >
      {isExpanded && (
        <Grid container>
          {serviceRequest && !!outreachOpportunities?.length ? (
            missingInformationFF ? (
              <OutreachOpportunity
                outreachOpportunities={outreachOpportunities}
                serviceRequestId={serviceRequest.id}
                authStatus={serviceRequest.authStatus}
              />
            ) : (
              outreachOpportunities?.map((OutreachOpportunity) => {
                return (
                  <NudgeOpportunity
                    nudgeOpportunity={OutreachOpportunity}
                    serviceRequest={serviceRequest}
                    key={OutreachOpportunity.id}
                  />
                );
              })
            )
          ) : (
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center", marginTop: spacing(2), marginBottom: spacing(2.5) }}
            >
              <StyledBody1>
                No previous {missingInformationFF ? "outreach" : "nudge"} attempts for this service
              </StyledBody1>
            </Grid>
          )}
        </Grid>
      )}
    </ClinicalReviewCard>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledBody1 = styled(Body1)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
