import { useCallback, useEffect, useRef, useState } from "react";
import { useAuthorized } from "authorization";
import {
  AuthorizationResponse,
  ServiceRequestResponse,
  useGetServiceRequestInternallyGeneratedLetterNotifications,
} from "@coherehealth/core-platform-api";
import {
  NotificationData,
  createNotificationForServiceSummary,
  createNotificationsToShow,
  createDownloadPath,
  createFileName,
} from "../util/notificationUtil";
import { compact, uniqBy } from "lodash";
import { NotificationViewerItem } from "../util/notificationUtil";

interface NotificationProps {
  fetchNotifications: () => Promise<void>;
  notifications: NotificationData[];
  setNotifications: React.Dispatch<React.SetStateAction<NotificationViewerItem[]>>;
  notificationIndexOpen: number;
  notificationsLoading: boolean;
  currentPage: React.MutableRefObject<number>;
  zoomLevel: React.MutableRefObject<number>;
  currentRotate: React.MutableRefObject<number>;
  onViewNotification: (index: number, landingPage?: number) => void;
}

/*
 * This hook maintains the data and PDF display state for all notifications.
 * Typical usage is to pass an Auth and its child SRs, because we want to show
 * all letters for all the SRs. Alternatively you can pass a single SR.
 * If you pass both, the auth will take precedence.
 */
interface UseGetNotificationViewerProps {
  serviceRequest?: ServiceRequestResponse;
  authorization?: AuthorizationResponse;
  serviceRequestsOnAuth?: ServiceRequestResponse[];
}

export const useGetNotifications = ({
  serviceRequest,
  authorization,
  serviceRequestsOnAuth,
}: UseGetNotificationViewerProps): NotificationProps => {
  // Can view any letters at all
  const canViewLetters = useAuthorized("VIEW_LETTERS");

  const [notifications, setNotifications] = useState<NotificationViewerItem[]>([]);
  const [notificationIndexOpen, setNotificationIndexOpen] = useState<number>(0);
  // The page the user is currently viewing on the active notification
  const currentPage = useRef<number>(0);
  // Keeping track of the last-viewed page, for all notifications
  // We don't use a state variable because logic is performed in the state setter.
  const [, setLastViewedPages] = useState<number[]>([]);
  const zoomLevel = useRef<number>(1);
  // The current PDF rotation angle for the active notification
  const currentRotate = useRef<number>(0);
  // Keeping track of the last-used rotation angle, for all notifications
  const [, setLastRotations] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);

  /*
   * When the user clicks on a different notification, we preserve the
   * current notification's rotation angle and page number.
   *
   * For the notification they clicked on, we retrieve the most recent rotation
   * angle and page number, and load it in the PDF viewer, so they don't have
   * to re-rotate and scroll down.
   */
  const onViewNotification = useCallback(
    // index is the notification the user clicked
    (index: number, landingPage?: number): void => {
      let nextPage = 0;
      setLastViewedPages((prevPages) => {
        const newPages = prevPages.map((page, idx) => {
          if (idx === index) {
            nextPage = page;
          }
          return idx === notificationIndexOpen ? currentPage.current : page;
        });
        return newPages;
      });
      currentPage.current = nextPage;

      const openNotificationRotate = currentRotate.current;
      setLastRotations((prevRotations) => {
        const newRotates = prevRotations.map((rotation, idx) => {
          if (idx === index) {
            currentRotate.current = rotation;
          }
          return idx === notificationIndexOpen ? openNotificationRotate : rotation;
        });
        return newRotates;
      });

      setNotificationIndexOpen(index);
    },
    [setLastViewedPages, setLastRotations, setNotificationIndexOpen, notificationIndexOpen]
  );

  // Data fetching hook for ad hoc letters
  const {
    loading: internallyGeneratedLetterNotificationsLoading,
    refetch: getServiceRequestInternallyGeneratedLetterNotifications,
  } = useGetServiceRequestInternallyGeneratedLetterNotifications({
    serviceRequestId: "",
    lazy: true,
  });

  /*
   * This function is called when the Notification Viewer is first opened.
   * It retrieves notifications from the SR(s) and from API calls.
   * It then converts them into NotificationViewerItem objects.
   */
  const fetchNotifications: () => Promise<void> = useCallback(async () => {
    setLoading(true);

    let notifications: NotificationData[] = [];
    let notificationsWithMetadata: NotificationViewerItem[] = [];

    // If the component is passed an Auth, we must fetch data for all SRs on the Auth.
    if (authorization && serviceRequestsOnAuth) {
      // Fetch and convert ad hoc letters
      const adhocLetters = await Promise.all(
        serviceRequestsOnAuth.map((sr) =>
          getServiceRequestInternallyGeneratedLetterNotifications({ pathParams: { serviceRequestId: sr.id } })
        )
      );
      const adhocLetterNotifications: NotificationData[] = compact(adhocLetters.flat()).map((letter) => {
        const serviceRequest = serviceRequestsOnAuth.find((sr) => sr.id === letter.serviceRequestId);
        return {
          variant: "INTERNAL_LETTER",
          data: letter,
          ...(serviceRequest && { serviceRequest: { id: serviceRequest.id, cohereId: serviceRequest.cohereId } }),
        };
      });
      if (canViewLetters && adhocLetterNotifications) {
        notifications.push(...adhocLetterNotifications);
      }

      // Add determination and TAT extension letters - these come directly from the SR(s)
      const letters = serviceRequestsOnAuth.flatMap((serviceRequest) => createNotificationsToShow(serviceRequest));
      if (canViewLetters && letters) {
        notifications.push(...letters);
      }

      // Add Auth Service Summary
      const serviceSummary = createNotificationForServiceSummary(undefined, authorization, serviceRequestsOnAuth);
      if (serviceSummary?.data?.id) {
        notifications.push(serviceSummary);
      }
    } else if (serviceRequest) {
      // Add ad hoc letters
      const adhocLetters = await getServiceRequestInternallyGeneratedLetterNotifications({
        pathParams: { serviceRequestId: serviceRequest.id },
      });
      const adhocLetterNotifications: NotificationData[] = compact(adhocLetters).map((letter) => ({
        variant: "INTERNAL_LETTER",
        data: letter,
        serviceRequest: { id: serviceRequest.id, cohereId: serviceRequest.cohereId },
      }));
      if (canViewLetters && adhocLetterNotifications) {
        notifications.push(...adhocLetterNotifications);
      }

      // Add determination and TAT extension letters
      const letters = createNotificationsToShow(serviceRequest);
      if (canViewLetters && letters) {
        notifications.push(...letters);
      }

      // Add SR Service Summary
      const serviceSummary = createNotificationForServiceSummary(serviceRequest);
      if (serviceSummary?.data?.id) {
        notifications.push(serviceSummary);
      }
    }

    // Populate metadata
    // Currently we only have PDFs for ad hoc letters - no JPGs
    notificationsWithMetadata = notifications.map((notification) => {
      let contentType = "application/pdf";
      if ("contentType" in notification.data && notification.data.contentType) {
        contentType = notification.data.contentType;
      }
      return {
        ...notification,
        fileName: createFileName(notification),
        downloadPath: createDownloadPath(notification),
        contentType,
      };
    });

    // Dedupe in case of bad data
    notificationsWithMetadata = uniqBy(notificationsWithMetadata, (n) => n.data.id);

    // Initialize last-viewed pages and rotation angles
    setLastViewedPages(notifications.map(() => 0));
    setLastRotations(notifications.map(() => 0));

    setNotifications(notificationsWithMetadata);

    setLoading(false);
  }, [
    authorization,
    serviceRequestsOnAuth,
    serviceRequest,
    canViewLetters,
    getServiceRequestInternallyGeneratedLetterNotifications,
  ]);

  // When component unmounts, revoke local file blob URLs to save memory.
  useEffect(() => {
    return () => {
      notifications.forEach((notification) => {
        if (notification.fileUrl) {
          URL.revokeObjectURL(notification.fileUrl);
        }
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    fetchNotifications,
    notifications,
    setNotifications,
    notificationIndexOpen,
    notificationsLoading: internallyGeneratedLetterNotificationsLoading || loading,
    currentPage,
    zoomLevel,
    currentRotate,
    onViewNotification,
  };
};
