import React, { useState } from "react";
import { ServiceRequestResponse, Attachment, AuthorizationResponse } from "@coherehealth/core-platform-api";
import { Grid, useTheme } from "@material-ui/core";
import { PrimaryButton } from "@coherehealth/common";
import { AddServiceRequestAttachments } from "../../AddAttachments";
import { removeEmptyAttachments } from "util/attachmentUtil";
import AttachmentSectionHeader from "./AttachmentSectionHeader";

interface Props {
  authorization?: AuthorizationResponse;
  // If this is an Authorization, this is the latest SR
  serviceRequest: ServiceRequestResponse;
  setViewState: (state: "READ_ONLY" | "EDIT") => void;
  onUpdateAttachments: (attachments: Attachment[]) => void | undefined;
  allowMultipleAttachmentFiles?: boolean;
  fileSizeLimit?: number;
  onSaveAttachments?: () => void;
}

export default function AttachmentSectionEdit({
  authorization,
  serviceRequest,
  setViewState,
  onUpdateAttachments,
  allowMultipleAttachmentFiles,
  fileSizeLimit,
  onSaveAttachments,
}: Props) {
  const [attachmentsCompleted, setAttachmentsCompleted] = useState(true);
  const theme = useTheme();

  // Gather attachments from all ServiceRequests in the Authorization
  const authorizationAttachments: Attachment[] = [];
  if (authorization && authorization.serviceRequestsOnAuth) {
    authorization.serviceRequestsOnAuth.forEach((sr) => {
      sr.attachments?.forEach((attachment) => {
        authorizationAttachments.push(attachment);
      });
    });
  }
  const attachmentList = !!authorization
    ? removeEmptyAttachments(authorizationAttachments)
    : removeEmptyAttachments(serviceRequest.attachments);

  return (
    <Grid container item xs={12} justifyContent="space-between" spacing={3} style={{ margin: 0 }}>
      <Grid item xs={6} style={{ padding: 0 }}>
        <AttachmentSectionHeader attachmentsList={attachmentList} serviceRequest={serviceRequest} />
      </Grid>
      <Grid item style={{ padding: 0 }}>
        <PrimaryButton
          onClick={() => {
            setViewState("READ_ONLY");
            onSaveAttachments?.();
          }}
          disabled={!attachmentsCompleted}
        >
          Save
        </PrimaryButton>
      </Grid>
      <Grid item xs={12} style={{ padding: theme.spacing(1.5, 0) }}>
        <AddServiceRequestAttachments
          serviceRequestIds={[serviceRequest.id]}
          workflowId={serviceRequest?.workflowId}
          initialAttachments={attachmentList}
          onUpdateAttachments={onUpdateAttachments}
          setAttachmentsCompleted={setAttachmentsCompleted}
          stage="PATIENT_SUMMARY"
          allowMultipleAttachmentFiles={allowMultipleAttachmentFiles}
          fileSizeLimit={fileSizeLimit}
        />
      </Grid>
    </Grid>
  );
}
