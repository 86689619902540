import { containerSmallBreakpoint } from "@coherehealth/common";
import { Drawer } from "@material-ui/core";
import { AuthBuilderContentWithContext } from "components/AuthBuilder";
import { warn } from "logger";
import { Location, PathMatch } from "react-router";

interface Props {
  faxId: string;
  queueId: string | undefined;
  caseId: string | undefined;
  match?: PathMatch<string> | null;
  location: Location;
  search: URLSearchParams;
}

export default function FaxAuthBuilderSidebar({ caseId, queueId, faxId, match, location, search }: Props) {
  if (!caseId) {
    warn(`Missing case id corresponding to faxId: ${faxId}`);
  }

  return (
    <Drawer variant="permanent" anchor="right" disableEnforceFocus>
      <div style={{ width: containerSmallBreakpoint }}>
        <AuthBuilderContentWithContext
          match={match ?? null}
          smartOnFhirMatch={null}
          location={location}
          search={search}
          qmCaseId={caseId ?? null}
          queueId={queueId ?? ""}
          faxId={faxId}
        />
      </div>
    </Drawer>
  );
}
