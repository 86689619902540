declare global {
  interface Window {
    zE(...args: any[]): any;
  }
}

interface ChatWidget {
  show(): void;
  hide(): void;
}
export function useChatWidget(): ChatWidget {
  return {
    show() {
      safely(() => window.zE("messenger", "show"));
    },
    hide() {
      safely(() => window.zE("messenger", "hide"));
    },
  };
}

/**
 * Runs the specified code without caring whether it throws an error,
 * because we are supporting two incompatible versions of the zendesk SDK
 * and there's not a good way to tell which is active
 * https://developer.zendesk.com/documentation/classic-web-widget-sdks/web-widget/getting-started/chat_api_migration/#general-apis
 * https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/sdk_api_reference/
 */
function safely(doWhat: () => void): void {
  try {
    doWhat();
  } catch (_) {}
}
