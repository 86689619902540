import { Coverage } from "@coherehealth/core-platform-api";

interface ReferralPayorConfigProps {
  isAdministrative: boolean;
  coverage: Coverage | null;
}

export interface ReferralPayorConfiguration {
  shouldShowAttestation: boolean;
  outOfNewtorkMessage: string;
  unknownMessage: string;
  oonAttestationReasons: Record<string, string> | undefined;
  hideTinField: boolean;
}

const OSH_OON_PERFORMING_PROVIDER_EXCEPTION_REASONS: Record<string, string> = {
  PATIENT_CHANGED_PLAN: "The patient has changed health plans in the last 90 days (continuity of care)",
  PROVIDER_NETWORK_STATUS_CHANGED:
    "This provider's network status has changed in the last 90 days (continuity of care)",
  NO_IN_NETWORK_PROVIDERS: "There are no in-network providers available within 50 miles of the patient",
};

export const getReferralPayorConfiguration = ({
  isAdministrative,
  coverage,
}: ReferralPayorConfigProps): ReferralPayorConfiguration => {
  const shouldShowAttestation =
    !isAdministrative && coverage?.riskBearingEntity === "Oak Street Health" && coverage.coverageProductType === "HMO";

  const hideTinField = coverage?.healthPlanName === "BCBS South Carolina";
  return {
    shouldShowAttestation,
    outOfNewtorkMessage: getReferralOutOfNetworkText(coverage, isAdministrative),
    unknownMessage: getReferralUnknownNetworkText(coverage, isAdministrative),
    oonAttestationReasons: shouldShowAttestation ? OSH_OON_PERFORMING_PROVIDER_EXCEPTION_REASONS : undefined,
    hideTinField: hideTinField,
  };
};

const getReferralUnknownNetworkText = (coverage: Coverage | null, isAdministrative: boolean): string => {
  let unknownDisplayText = "Network status unknown";
  if (coverage?.healthPlanName === "BCBS South Carolina") {
    unknownDisplayText = unknownDisplayText.concat(`, and your request may pend for review.`);
  } else if (
    coverage?.healthPlanName === "Humana" &&
    coverage?.riskBearingEntity === "Oak Street Health" &&
    !isAdministrative
  ) {
    unknownDisplayText = unknownDisplayText.concat(`, and your request may not be covered.`);
  }

  return unknownDisplayText;
};

const getReferralOutOfNetworkText = (coverage: Coverage | null, isAdministrative: boolean): string => {
  //TODO RM: determine if BSBS SC with or without benefits to know which message to show, see designs
  if (
    coverage?.healthPlanName === "BCBS South Carolina" &&
    coverage?.coverageProductType === "HMO" &&
    !isAdministrative
  ) {
    return "Provider is out-of-network and patient does not have out-of-network benefits. Please choose an in-network provider.";
  }

  return "Provider is out-of-network. You can switch to an in-network provider or proceed with your out-of-network request.";
};
