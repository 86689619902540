import React, { forwardRef, useEffect, useState } from "react";
import Cleave from "cleave.js/react";
import { InputBaseComponentProps } from "@material-ui/core/InputBase/InputBase";

import { TextField, TextFieldProps } from "../TextField";

type CleaveDatePattern = Array<"Y" | "y" | "m" | "d">;

export interface InternalProps {
  /**
   * The pattern to match the date against, see https://github.com/nosir/cleave.js/blob/master/doc/options.md#datepattern
   * example: for June 4th, 1996, ['m', 'd', 'Y'] = "06/04/1996", ['y', 'm'] = "96/06", etc.
   * default: ["m", "d", "Y"]
   */
  datePattern?: CleaveDatePattern;
  /**
   * Min date boundary, use ISO 8601 YYYY-MM-DD
   */
  dateMin?: string;
  /**
   * Max date boundry, use ISO 8601 YYYY-MM-DD
   */
  dateMax?: string;
  /**
   * Delimiter character between date fields. Default: "/"
   */
  delimiter?: string;
  /**
   * If a human readable date pattern should be appended to the label
   */
  addDatePatternToLabel?: boolean;
  autoFillIcon?: object;
}

export type Props = InternalProps & TextFieldProps;

const DateTextField = forwardRef(
  (
    {
      datePattern = ["m", "d", "Y"],
      dateMin,
      dateMax,
      delimiter = "/",
      addDatePatternToLabel,
      label,
      value,
      autoFillIcon,
      ...textFieldProps
    }: Props,
    ref
  ) => {
    let labelDisplay = label;
    if (addDatePatternToLabel) {
      labelDisplay = `${label} (${getFormatText(datePattern, delimiter)})`;
    }

    return (
      <TextField
        {...textFieldProps}
        label={labelDisplay}
        ref={ref}
        value={value}
        // Sometimes material doesn't put these classes here w/ a controlled value, which causes the label to overlap
        InputLabelProps={{ className: !!value ? "MuiInputLabel-filled MuiInputLabel-shrink" : "" }}
        InputProps={{
          inputComponent: DateAutoFormatterInput,
          inputProps: {
            datePattern,
            dateMin,
            dateMax,
            delimiter,
          },
          endAdornment: autoFillIcon,
        }}
      />
    );
  }
);

export default DateTextField;

type DateAutoFormatterProps = Omit<InternalProps, "addDatePatternToLabel"> & InputBaseComponentProps;

const DateAutoFormatterInput = ({
  datePattern,
  dateMin,
  dateMax,
  delimiter,
  inputRef,
  value,
  ...inputAttributes
}: DateAutoFormatterProps) => {
  const [cleaveJS, setCleaveJS] = useState<any>(null);

  // A bit of a hack because Cleave doesn't really support controlled components
  // https://github.com/nosir/cleave.js/blob/master/doc/reactjs-component-usage.md#how-to-update-raw-value
  useEffect(() => {
    if (!!value && !!cleaveJS) {
      cleaveJS.setRawValue(value);
    }
  }, [value, cleaveJS]);

  return (
    <Cleave
      htmlRef={(internalRef) => (inputRef = internalRef)}
      options={{
        date: true,
        datePattern,
        dateMin,
        dateMax,
        delimiter,
      }}
      // onChange={internalOnChange}
      onInit={setCleaveJS}
      value={value}
      defaultValue={value}
      {...inputAttributes}
    />
  );
};

const getFormatText = (pattern: CleaveDatePattern, delimiter: string) => {
  const partToPlaceholder: Record<"Y" | "y" | "m" | "d", string> = {
    Y: "YYYY",
    y: "YY",
    m: "MM",
    d: "DD",
  };
  return pattern.map((part) => partToPlaceholder[part]).join(delimiter);
};
