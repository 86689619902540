import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { ReferralRequestFormContent } from "components/ReferralManagement/RequestBuilder/ReferralRequestForm";
import { ReferralFormConfiguration } from "components/ReferralManagement/FormContentSpecification/ReferralRequestFormContentSpecifications";
import {
  FormConfigurationFieldSpec,
  GetOutofNetworkCheckResponse,
  Location,
  OONCheckRequest,
  Patient,
  PracticeCommon,
  useGetOutofNetworkCheck,
} from "@coherehealth/core-platform-api";
import { dateProperlyFormatted } from "util/serviceRequest";
import { formatDateToISODate } from "@coherehealth/common";
import { usePrevious } from "@react-pdf-viewer/core";
import {
  asyncCallWithTimeout,
  networkCheckDependentFields,
  omitNonNetworkCheckFields,
} from "components/AuthBuilder/common";
import { isEqual } from "lodash";

interface UseReferralsOutOfNetworkCheckProps {
  rrFormContent: ReferralRequestFormContent;
  setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>;
  rrFormConfiguration: ReferralFormConfiguration;
  patient?: Patient;
  runOONCheckOnManuallyCreatedProviderFacility: boolean;
  hideTinField: boolean;
}

interface UseOutOfNetworkCheckProps {
  npiFieldSpec: FormConfigurationFieldSpec;
  selectedPractice: PracticeCommon | null | undefined;
  selectedPracticeTin: string | null | undefined;
  practiceAddressFieldSpec: FormConfigurationFieldSpec;
  practiceSelectedAddress: Location | null | undefined;
  startDate: Date | undefined;
  oonFieldSpec: FormConfigurationFieldSpec;
  practiceOONExceptionRequired: boolean | undefined;
  setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>;
  patient: Patient | undefined;
  runOONCheckOnManuallyCreatedPractice: boolean;
  hideTinField: boolean;
  setPracticeOONResponse: (
    oonResponse: GetOutofNetworkCheckResponse | undefined,
    setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>,
    practiceExceptionRequired: boolean,
    formAddressOON: boolean | undefined,
    formOONExceptionRequired: boolean | undefined
  ) => void;
}

const networkCheckTimeout = 10000;

export const useReferralFacilityOutOfNetworkCheck = ({
  rrFormContent,
  setRRFormContent,
  rrFormConfiguration,
  patient,
  runOONCheckOnManuallyCreatedProviderFacility,
  hideTinField,
}: UseReferralsOutOfNetworkCheckProps) => {
  return useGetOutOfNetworkCheck({
    npiFieldSpec: rrFormConfiguration?.facilityNPI?.fieldSpec,
    selectedPractice: rrFormContent?.selectedFacility,
    selectedPracticeTin: rrFormContent?.facilitySelectedTin,
    practiceAddressFieldSpec: rrFormConfiguration?.facilityAddress?.fieldSpec,
    practiceSelectedAddress: rrFormContent?.facilitySelectedAddress,
    startDate: rrFormContent?.startDate,
    oonFieldSpec: rrFormConfiguration?.outOfNetworkCheck?.fieldSpec,
    practiceOONExceptionRequired: rrFormContent?.facilityOONExceptionRequired,
    patient,
    runOONCheckOnManuallyCreatedPractice: runOONCheckOnManuallyCreatedProviderFacility,
    setRRFormContent,
    setPracticeOONResponse: setFacilityOONResponse,
    hideTinField,
  });
};

export const useReferralPerformingSpecialistOutOfNetworkCheck = ({
  rrFormContent,
  setRRFormContent,
  rrFormConfiguration,
  patient,
  runOONCheckOnManuallyCreatedProviderFacility,
  hideTinField,
}: UseReferralsOutOfNetworkCheckProps) => {
  return useGetOutOfNetworkCheck({
    npiFieldSpec: rrFormConfiguration?.performingSpecialistNPI?.fieldSpec,
    selectedPractice: rrFormContent?.selectedPerformingSpecialist,
    selectedPracticeTin: rrFormContent?.performingSpecialistSelectedTin,
    practiceAddressFieldSpec: rrFormConfiguration?.performingSpecialistAddress?.fieldSpec,
    practiceSelectedAddress: rrFormContent?.performingSpecialistSelectedAddress,
    startDate: rrFormContent?.startDate,
    oonFieldSpec: rrFormConfiguration?.outOfNetworkCheck?.fieldSpec,
    practiceOONExceptionRequired: rrFormContent?.performingSpecialistOONExceptionRequired,
    patient,
    runOONCheckOnManuallyCreatedPractice: runOONCheckOnManuallyCreatedProviderFacility,
    setRRFormContent,
    setPracticeOONResponse: setSpecialistOONResponse,
    hideTinField,
  });
};

const useGetOutOfNetworkCheck = ({
  npiFieldSpec,
  selectedPractice,
  selectedPracticeTin,
  practiceAddressFieldSpec,
  practiceSelectedAddress,
  startDate,
  oonFieldSpec,
  practiceOONExceptionRequired,
  patient,
  runOONCheckOnManuallyCreatedPractice,
  setRRFormContent,
  setPracticeOONResponse,
  hideTinField,
}: UseOutOfNetworkCheckProps) => {
  const [oonCheckData, setOonCheckData] = useState<GetOutofNetworkCheckResponse>();
  const [performedOONCheck, setPerformedOONCheck] = useState<boolean>(false);

  const { loading: oonCheckLoading, mutate: doOonCheck, cancel: cancelDoOonCheck } = useGetOutofNetworkCheck({});

  const practiceFieldsFilled = useMemo(() => {
    return getPracticeRequiredFieldsFilled(npiFieldSpec, selectedPractice, selectedPracticeTin, hideTinField);
  }, [npiFieldSpec, selectedPractice, selectedPracticeTin, hideTinField]);

  const practiceAddressFieldsFilled: boolean = useMemo(() => {
    return getPracticeAddressRequiredFieldsFilled(
      practiceAddressFieldSpec,
      selectedPractice,
      selectedPracticeTin,
      practiceSelectedAddress,
      hideTinField
    );
  }, [practiceAddressFieldSpec, selectedPractice, selectedPracticeTin, practiceSelectedAddress, hideTinField]);

  const practiceExceptionRequired = false;
  const requiredFieldsFilled = practiceFieldsFilled && practiceAddressFieldsFilled;

  const oonCheckPayload: OONCheckRequest | null = useMemo(() => {
    return getOONCheckPayload(patient, selectedPractice, practiceSelectedAddress, selectedPracticeTin, startDate);
  }, [patient, selectedPractice, practiceSelectedAddress, selectedPracticeTin, startDate]);

  const previousPayload = usePrevious(oonCheckPayload);

  // checks if the practice fields have been filled completely to start OON check
  useEffect(() => {
    const networkCheckFieldPreviousPayload = omitNonNetworkCheckFields(previousPayload);
    const networkCheckFieldOONCheckPayload = omitNonNetworkCheckFields(oonCheckPayload);
    const previousNetworkCheckDependentFields = networkCheckDependentFields(networkCheckFieldPreviousPayload);
    const currentNetworkCheckDependentFields = networkCheckDependentFields(networkCheckFieldOONCheckPayload);

    if (!isEqual(previousNetworkCheckDependentFields, currentNetworkCheckDependentFields)) {
      //clear out OON check data if payload has changed
      setOonCheckData(undefined);
      setPerformedOONCheck(false);
    }

    if (
      oonFieldSpec !== "OMIT" &&
      requiredFieldsFilled &&
      !!oonCheckPayload &&
      !isEqual(previousNetworkCheckDependentFields, currentNetworkCheckDependentFields) &&
      (runOONCheckOnManuallyCreatedPractice || !selectedPractice?.manuallyCreated) // skip for manually created practice unless configured otherwise
    ) {
      const dataPracticePromise = doOonCheck(oonCheckPayload);

      const checkPracticeOONWithTimeout = checkOONWithTimeout(
        dataPracticePromise,
        setOonCheckData,
        cancelDoOonCheck,
        setRRFormContent,
        practiceExceptionRequired,
        practiceSelectedAddress?.isOutOfNetwork,
        practiceOONExceptionRequired,
        setPracticeOONResponse
      );
      setOonCheckData(undefined);
      checkPracticeOONWithTimeout().then(() => setPerformedOONCheck(true));
    }

    return () => {
      //cleanup in case the check is running and the page is changed
      if (oonCheckLoading) {
        cancelDoOonCheck();
      }
    };
  }, [
    selectedPractice?.manuallyCreated,
    practiceOONExceptionRequired,
    practiceSelectedAddress?.isOutOfNetwork,
    doOonCheck,
    oonFieldSpec,
    practiceExceptionRequired,
    oonCheckPayload,
    previousPayload,
    requiredFieldsFilled,
    setRRFormContent,
    runOONCheckOnManuallyCreatedPractice,
    cancelDoOonCheck,
    setPracticeOONResponse,
    oonCheckLoading,
  ]);

  return { oonCheckData, oonCheckLoading, performedOONCheck };
};

//handle timeout when calling the network check
function checkOONWithTimeout(
  dataPracticePromise: Promise<GetOutofNetworkCheckResponse>,
  setPracticeOonCheckData: Dispatch<SetStateAction<GetOutofNetworkCheckResponse | undefined>>,
  cancelDoPracticeOonCheck: () => void,
  setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>,
  practiceExceptionRequired: boolean,
  formAddressOON: boolean | undefined,
  formOONExceptionRequired: boolean | undefined,
  setPracticeOONResponse: (
    oonResponse: GetOutofNetworkCheckResponse | undefined,
    setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>,
    practiceExceptionRequired: boolean,
    formAddressOON: boolean | undefined,
    formOONExceptionRequired: boolean | undefined
  ) => void
) {
  return async () => {
    let practiceNetworkCheckResponse: GetOutofNetworkCheckResponse | undefined;
    await asyncCallWithTimeout(Promise.all([dataPracticePromise]), networkCheckTimeout)
      .then((result) => {
        if (Object.keys(result[0]).length) {
          setPracticeOonCheckData(result[0]);
          practiceNetworkCheckResponse = result[0];
        } else {
          setPracticeOonCheckData(undefined);
          practiceNetworkCheckResponse = undefined;
        }
      })
      .catch(() => {
        cancelDoPracticeOonCheck();
        // TODO RM : define if we want to block the user or set Unknown type if the check fails/times out
        setPracticeOonCheckData({ isOutOfNetwork: false, networkType: "Unknown" });
        practiceNetworkCheckResponse = { isOutOfNetwork: false, networkType: "Unknown" };
      });
    setPracticeOONResponse(
      practiceNetworkCheckResponse,
      setRRFormContent,
      practiceExceptionRequired,
      formAddressOON,
      formOONExceptionRequired
    );
  };
}

//set oon response for facilities
const setFacilityOONResponse = (
  oonResponse: GetOutofNetworkCheckResponse | undefined,
  setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>,
  practiceExceptionRequired: boolean,
  formAddressOON: boolean | undefined,
  formOONExceptionRequired: boolean | undefined
) => {
  if (oonResponse?.networkType) {
    setRRFormContent((prev) => {
      return {
        ...prev,
        facilitySelectedAddress: {
          ...prev?.facilitySelectedAddress,
          isOutOfNetwork: oonResponse?.networkType === "OON",
          networkType: oonResponse?.networkType,
        },
        selectedFacility: {
          ...prev?.selectedFacility,
          id: prev?.selectedFacility?.id || "",
          dateCreated: prev?.selectedFacility?.dateCreated || "",
          lastUpdated: prev?.selectedFacility?.lastUpdated || "",
          selectedLocation: {
            ...prev?.selectedFacility?.selectedLocation,
            isOutOfNetwork: oonResponse?.networkType === "OON",
            networkType: oonResponse?.networkType,
          },
          type: prev?.selectedFacility?.type || "FACILITY",
        },
        facilityOONExceptionRequired: practiceExceptionRequired,
      };
    });
  } else if (oonResponse?.isOutOfNetwork !== formAddressOON || practiceExceptionRequired !== formOONExceptionRequired) {
    setRRFormContent((prev) => {
      return {
        ...prev,
        facilitySelectedAddress: {
          ...prev?.facilitySelectedAddress,
          isOutOfNetwork: oonResponse?.isOutOfNetwork,
        },
        selectedFacility: {
          ...prev?.selectedFacility,
          id: prev?.selectedFacility?.id || "",
          dateCreated: prev?.selectedFacility?.dateCreated || "",
          lastUpdated: prev?.selectedFacility?.lastUpdated || "",
          selectedLocation: {
            ...prev?.selectedFacility?.selectedLocation,
            isOutOfNetwork: oonResponse?.isOutOfNetwork,
          },
          type: prev?.selectedFacility?.type || "FACILITY",
        },
        facilityOONExceptionRequired: practiceExceptionRequired,
      };
    });
  }
};

//set oon response for specialists
const setSpecialistOONResponse = (
  oonResponse: GetOutofNetworkCheckResponse | undefined,
  setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>,
  practiceExceptionRequired: boolean,
  formAddressOON: boolean | undefined,
  formOONExceptionRequired: boolean | undefined
) => {
  if (oonResponse?.networkType) {
    setRRFormContent((prev) => {
      return {
        ...prev,
        performingSpecialistSelectedAddress: {
          ...prev?.performingSpecialistSelectedAddress,
          isOutOfNetwork: oonResponse?.networkType === "OON",
          networkType: oonResponse?.networkType,
        },
        selectedPerformingSpecialist: {
          ...prev?.selectedPerformingSpecialist,
          id: prev?.selectedPerformingSpecialist?.id || "",
          dateCreated: prev?.selectedPerformingSpecialist?.dateCreated || "",
          lastUpdated: prev?.selectedPerformingSpecialist?.lastUpdated || "",
          selectedLocation: {
            ...prev?.selectedPerformingSpecialist?.selectedLocation,
            isOutOfNetwork: oonResponse?.networkType === "OON",
            networkType: oonResponse?.networkType,
          },
          type: prev?.selectedPerformingSpecialist?.type || "Specialist", //TODO RM: ask what value goes here
        },
        performingSpecialistOONExceptionRequired: practiceExceptionRequired,
      };
    });
  } else if (oonResponse?.isOutOfNetwork !== formAddressOON || practiceExceptionRequired !== formOONExceptionRequired) {
    setRRFormContent((prev) => {
      return {
        ...prev,
        performingSpecialistSelectedAddress: {
          ...prev?.performingSpecialistSelectedAddress,
          isOutOfNetwork: oonResponse?.isOutOfNetwork,
        },
        selectedPerformingSpecialist: {
          ...prev?.selectedPerformingSpecialist,
          id: prev?.selectedPerformingSpecialist?.id || "",
          dateCreated: prev?.selectedPerformingSpecialist?.dateCreated || "",
          lastUpdated: prev?.selectedPerformingSpecialist?.lastUpdated || "",
          selectedLocation: {
            ...prev?.selectedPerformingSpecialist?.selectedLocation,
            isOutOfNetwork: oonResponse?.isOutOfNetwork,
          },
          type: prev?.selectedPerformingSpecialist?.type || "Specialist",
        },
        facilityOONExceptionRequired: practiceExceptionRequired,
      };
    });
  }
};

function getOONCheckPayload(
  patient: Patient | undefined,
  practice: PracticeCommon | null | undefined,
  location: Location | null | undefined,
  selectedTin: string | null | undefined,
  startDate: Date | undefined
): OONCheckRequest | null {
  if (!dateProperlyFormatted(startDate)) {
    return null;
  }
  return {
    patientId: patient?.id,
    memberId: patient?.memberId,
    effectiveDate: formatDateToISODate(startDate),
    npi: practice?.npi || undefined,
    providerType: practice?.providerType,
    location:
      location && location?.address
        ? location
        : selectedTin
        ? getPracticeLocationIfNone(practice, location, selectedTin)
        : {
            tin: selectedTin || undefined,
          },
    recordType: practice?.type,
  };
}

export function getPracticeRequiredFieldsFilled(
  fieldSpec: FormConfigurationFieldSpec,
  practice: PracticeCommon | null | undefined,
  selectedTin: string | null | undefined,
  hideTinField: boolean
): boolean {
  const isTinListEmpty = practice?.tinList?.length === 0;
  return fieldSpec === "OPTIONAL"
    ? !!practice
      ? !!practice && (hideTinField || (isTinListEmpty ? true : !!selectedTin))
      : false
    : fieldSpec === "REQUIRED"
    ? !!practice && (hideTinField || (isTinListEmpty ? true : !!selectedTin))
    : false;
}

export function getPracticeAddressRequiredFieldsFilled(
  fieldSpec: FormConfigurationFieldSpec,
  practice: PracticeCommon | null | undefined,
  selectedTin: string | null | undefined,
  location: Location | null | undefined,
  hideTinField: boolean
): boolean {
  const isTinListEmpty = practice?.tinList?.length === 0;
  return fieldSpec === "OPTIONAL"
    ? !!practice
      ? !!practice && (hideTinField || (isTinListEmpty ? true : !!selectedTin))
      : true
    : fieldSpec === "REQUIRED"
    ? !!practice &&
      (hideTinField || (isTinListEmpty ? true : !!selectedTin)) &&
      (practice?.locations ? !!location?.address : true)
    : false;
}

const getPracticeLocationIfNone = (
  practice: PracticeCommon | null | undefined,
  practiceAddress: Location | null | undefined,
  selectedTin: string | null | undefined
): Location | undefined => {
  let location: Location | undefined;
  if (practice && practice.locations && !practiceAddress) {
    const locationsPractice = practice?.locations.filter((loc) => loc.tin === selectedTin);
    location = locationsPractice[0];
  } else {
    location = {
      tin: selectedTin ? selectedTin : undefined,
    };
  }
  return location;
};
