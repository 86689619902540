import { useState } from "react";
import { AuthorizationResponse, ReviewType, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import NurseReviewComponent from "../../../ServiceRequest/ReviewSection/NurseReview/NurseReview";
import { OutOfNetworkReviewReadonly } from "../../../ServiceRequest/ReviewSection/OutOfNetworkReview/OutOfNetworkReview";
import AuditReviewComponent from "../../../ServiceRequest/ReviewSection/AuditReview/AuditReview";
import MDReviewComponent from "../../../ServiceRequest/ReviewSection/MDReview/MDReview";
import PeerToPeerReviewComponent from "../../../ServiceRequest/ReviewSection/MDReview/PeerToPeerReview";
import { makeStyles } from "@material-ui/core/styles";
import { PeerToPeerOutreachReviewType } from "../../Review/ClinicalReviewPage";
import { Grid, useTheme } from "@material-ui/core";
import { Subtitle2 } from "@coherehealth/common";
import { buildSrGroupingTitle } from "../infoPanelUtils";
import { HistoryReview } from "../NotesCard/notesCardTypes";

interface ReviewViewProps {
  review: HistoryReview;
  index: number;
  hasContinuations?: boolean;
  isNewServiceRequest?: boolean;
  isInFilteredState?: boolean;
  authorization?: AuthorizationResponse;
}

const ReviewComponent = ({
  review,
  index,
  hasContinuations,
  isNewServiceRequest,
  isInFilteredState,
  authorization,
}: ReviewViewProps) => {
  const [expansionByReviewId, setExpansionByReviewId] = useState<Record<string, boolean>>({});

  const toggleExpanded = (review: ReviewType | PeerToPeerOutreachReviewType) => {
    if (!((window.getSelection()?.toString().length || 0) > 0)) {
      setExpansionByReviewId((prev) => ({ ...prev, [review.id]: !prev[review.id] }));
    }
  };

  const theme = useTheme();
  const classes = useStyles();

  return (
    <>
      <Grid container style={{ marginTop: "0px" }}>
        <Grid item key={review.id} className={classes.reviewContainer}>
          {review.serviceRequest && isNewServiceRequest && !isInFilteredState && (
            <Subtitle2
              style={{
                color: theme.palette.info.light,
                marginTop: index === 0 ? "0px" : "16px",
                marginBottom: "-8px",
              }}
            >
              {buildSrGroupingTitle(
                authorization?.serviceRequestsOnAuth?.find((sr) => sr.id === review?.serviceRequest?.id),
                hasContinuations
              )}
            </Subtitle2>
          )}
          {review.reviewType === "NurseReview" && review.serviceRequest && (
            <NurseReviewComponent
              nurseReview={review}
              serviceRequest={
                authorization?.serviceRequestsOnAuth?.find(
                  (sr) => sr.id === review?.serviceRequest?.id
                ) as ServiceRequestResponse
              }
              expanded={expansionByReviewId[review.id]}
              toggleExpanded={() => toggleExpanded(review)}
              buttonPadding={"16px 0px"}
              reviewDetailsCollapseStyle={{ margin: 0 }}
              clinicalReviewView
              expandableGuidelines
              authorizationReviewView={true}
            />
          )}
          {review.reviewType === "OutOfNetworkReview" && review.serviceRequest && (
            <OutOfNetworkReviewReadonly
              outOfNetworkReview={review}
              serviceRequest={
                authorization?.serviceRequestsOnAuth?.find(
                  (sr) => sr.id === review?.serviceRequest?.id
                ) as ServiceRequestResponse
              }
              expanded={expansionByReviewId[review.id]}
              toggleExpanded={() => toggleExpanded(review)}
              buttonPadding={"16px 0px"}
              reviewDetailsCollapseStyle={{ margin: 0 }}
              clinicalReviewView
              authorizationReviewView={true}
            />
          )}
          {review.reviewType === "MdReview" && review.serviceRequest && (
            <MDReviewComponent
              mdReview={review}
              serviceRequest={
                authorization?.serviceRequestsOnAuth?.find(
                  (sr) => sr.id === review?.serviceRequest?.id
                ) as ServiceRequestResponse
              }
              expanded={expansionByReviewId[review.id]}
              toggleExpanded={() => toggleExpanded(review)}
              buttonPadding={"16px 0px"}
              reviewDetailsCollapseStyle={{ margin: 0 }}
              clinicalReviewView
              expandableGuidelines
              authorizationReviewView={true}
            />
          )}
          {review.reviewType === "PeerToPeerReview" && review.serviceRequest && (
            <PeerToPeerReviewComponent
              p2pReview={review}
              serviceRequest={
                authorization?.serviceRequestsOnAuth?.find(
                  (sr) => sr.id === review?.serviceRequest?.id
                ) as ServiceRequestResponse
              }
              expanded={expansionByReviewId[review.id]}
              toggleExpanded={() => toggleExpanded(review)}
              buttonPadding={"16px 0px"}
              reviewDetailsCollapseStyle={{ margin: 0 }}
              clinicalReviewView
              expandableGuidelines
              authorizationReviewView={true}
            />
          )}
          {(review.reviewType === "AuditReview" ||
            review.reviewType === "NewHireAuditReview" ||
            review.reviewType === "OutOfScopeAuditReview") &&
            review.serviceRequest && (
              <AuditReviewComponent
                auditReview={review}
                serviceRequest={
                  authorization?.serviceRequestsOnAuth?.find(
                    (sr) => sr.id === review?.serviceRequest?.id
                  ) as ServiceRequestResponse
                }
                expanded={expansionByReviewId[review.id]}
                toggleExpanded={() => toggleExpanded(review)}
                expandableGuidelines
                clinicalReviewView
                buttonPadding={"16px 0px"}
                reviewDetailsCollapseStyle={{ margin: 0 }}
                authorizationReviewView={true}
              />
            )}
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  reviewContainer: {
    width: "100%",
  },
}));

export default ReviewComponent;
