import {
  ComponentProps,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useState,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { PhoneNumber, Requestor, RequestorType } from "@coherehealth/core-platform-api";
import { Box, Grid, useTheme } from "@material-ui/core";
import { ContactInfoT, FormError } from "../AuthBuilder/FillFormsAddNewPatient/formOptions";
import { FormAction } from "../AuthBuilder/FillFormsAddNewPatient/formReducer";
import { ContactAddressFormSection } from "../AuthBuilder/FillFormsAddNewPatient/FormSections/ContactAddressFormSection";
import {
  TextField,
  RadioGroup,
  PrimaryButton,
  InformativeModal,
  diffDatefromNow,
  Body1,
  Body2,
  SingleSelectDropdown,
  useFeature,
  Body3,
  Checkbox,
  colorsLight,
} from "@coherehealth/common";
import { Address } from "@coherehealth/core-platform-api";
import PhoneInput from "common/PhoneInput";
import UserEmailInput from "./UserEmailInput";
import { makeStyles } from "@material-ui/core/styles";
import { SuggestionContext } from "../AuthBuilder/SuggestionContext";
import AutoFillIcon from "../images/AutoFillIcon";
import { DateTimePicker, removeSecondsFromTimestamp } from "./DateTimePicker";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";
import { useGetTemporaryMemberAuthsConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { useSnackbar } from "notistack";
import { error as logError, stringifyError } from "logger";
import routes from "routes";
import { useMatch } from "react-router-dom";

interface Props {
  requestor: Requestor | undefined;
  setRequestor: Dispatch<SetStateAction<Requestor | undefined>>;
  setUserUpdatedTat?: (wasUpdated: boolean) => void;
  attemptedSave?: boolean;
  setAttemptedSave?: (b: boolean) => void;
  setEditState?: (b: boolean) => void;
  isOnPatientSummary: boolean;
  attemptedSubmitServices?: boolean;
  setCollapseRequestorForm?: (b: boolean) => void;
  responsiveMdWidth?: boolean;
}

function phoneNumberValid(requestor: Requestor | undefined): boolean {
  return Boolean(!requestor?.phoneNumber?.number || requestor.phoneNumber.number.length === 10);
}
function faxNumberValid(requestor: Requestor | undefined): boolean {
  return Boolean(!requestor?.faxNumber?.number || requestor.faxNumber.number.length === 10);
}
function emailChannelValid(requestor: Requestor | undefined): boolean {
  return !Boolean(requestor?.channel === "EMAIL") || Boolean(requestor?.intakeTimestamp);
}
function mailChannelValid(requestor: Requestor | undefined): boolean {
  return !Boolean(requestor?.channel === "MAIL") || Boolean(requestor?.tatStartTimestamp);
}
function requestorTypeValid(
  requestor: Requestor | undefined,
  updateAORDetailsAfterSubmissionEnabled: boolean
): boolean {
  if (!requestor || !requestor.requestorType) {
    return false;
  }
  const isMemberRepAuth = requestor.requestorType === "MEMBER_REPRESENTATIVE";
  if (isMemberRepAuth) {
    //if updateAORDetailsAfterSubmissionEnabled FF is turned on, should always return true.
    return updateAORDetailsAfterSubmissionEnabled || Boolean(requestor.aorReceivedTimestamp);
  }
  return true;
}
export function requestorValid(
  requestor: Requestor | undefined,
  memberRequestedAuthsEnabled?: boolean,
  updateAORDetailsAfterSubmissionEnabled?: boolean | undefined
): boolean {
  let requestorIsValid = Boolean(
    requestor?.channel &&
      phoneNumberValid(requestor) &&
      faxNumberValid(requestor) &&
      emailChannelValid(requestor) &&
      mailChannelValid(requestor)
  );
  if (memberRequestedAuthsEnabled) {
    requestorIsValid =
      requestorIsValid && requestorTypeValid(requestor, updateAORDetailsAfterSubmissionEnabled ?? false);
  }
  return requestorIsValid;
}

const useLabelStyles = makeStyles((theme) => ({
  root: {
    // body2 styles copied from themes/common.ts
    ...theme.typography.body2,
    color: theme.palette.text.primary,

    // styles from RadioGroup.tsx
    "&.MuiFormLabel-root.Mui-focused": {
      color: theme.palette.text.primary,
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(1, 0, 0, 0),
    margin: 0,
    marginLeft: -theme.spacing(1),
    marginRight: -theme.spacing(1),
  },
  mainFlexItem: {
    flex: "1 0 auto",
  },
  secondContainer: {
    padding: 0,
    margin: 0,
    width: "100%",
  },
}));

const initialContactState = {
  ADDRESS_LINE_1: "",
  ADDRESS_LINE_2: "",
  CITY: "",
  STATE: "",
  ZIP_CODE: "",
};

const initialFormError = {
  ADDRESS_LINE_1: true,
  ADDRESS_LINE_2: true,
  CITY: true,
  STATE: true,
  ZIP_CODE: true,
};

const initialFormState: FormState = {
  aorAddress: initialContactState,
  formError: initialFormError,
};

interface FormState {
  aorAddress: ContactInfoT;
  formError: FormError;
}

const formReducer = (state: FormState, action: FormAction): FormState => {
  switch (action.type) {
    case "UPDATE_CONTACT_INFO":
      return {
        ...state,
        aorAddress: { ...state.aorAddress, ...action.payload },
      };
    case "UPDATE_FORM_ERROR":
      return {
        ...state,
        formError: { ...state.formError, ...action.payload },
      };
  }
  return state;
};

const RequestorEdit: FunctionComponent<Props> = ({
  requestor,
  setRequestor,
  attemptedSave,
  setAttemptedSave,
  isOnPatientSummary,
  setEditState,
  attemptedSubmitServices,
  setCollapseRequestorForm,
  setUserUpdatedTat,
  responsiveMdWidth,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const phoneNumberInvalid = Boolean((attemptedSave || attemptedSubmitServices) && !phoneNumberValid(requestor));
  const faxNumberInvalid = Boolean((attemptedSave || attemptedSubmitServices) && !faxNumberValid(requestor));
  const labelClasses = useLabelStyles();
  const classes = useStyles();
  const temporaryMemberAuthsEnabled = useFeature("temporaryMemberAuths");
  const enableMailRequestorChannel = useFeature("enableMailRequestorChannel");
  const enablePhoneRequestorChannelHasTimestamp = useFeature("enablePhoneRequestorChannelHasTimestamp");
  const referralManagementFF = useFeature("referralManagement");
  const matchReferralRoute = useMatch(routes.REFERRAL_BUILDER);

  const { suggestedRequestor } = useContext(SuggestionContext);
  const { faxAttachment: faxData, healthPlanName } = useContext(FaxAttachmentContext);

  const isFaxWorkflow = Boolean(faxData);

  // TODO: COH-5817 replace below with config
  const isHealthPlanGeisinger = healthPlanName === "Geisinger";

  const [tatStartTimestamp, setTatStartTimestamp] = useState<string | undefined>(requestor?.tatStartTimestamp);
  const [faxTatStartTimestamp, setFaxTatStartTimestamp] = useState<string | undefined>(requestor?.tatStartTimestamp);
  const [intakeTimestamp, setIntakeTimestamp] = useState<string | undefined>(requestor?.intakeTimestamp);
  const [aorReceivedTimestamp, setAorReceivedTimestamp] = useState<string | undefined>(requestor?.aorReceivedTimestamp);
  const [aorAddressFormState, aorAddressDispatch] = useReducer(formReducer, initialFormState);
  const [hasValidReceivedDate, setHasValidReceivedDate] = useState<boolean>(true); // Get the valid bool value from DateTimePicker
  const [hasValidAorReceivedDate, setHasValidAorReceivedDate] = useState<boolean>(false); // DateTimePicker will set to true when date is valid
  const [openTATConfirmationModal, setOpenTATConfirmationModal] = useState<boolean>(false);

  const validateForm = (formError: FormError): boolean => {
    const formIsInvalid = Object.entries(formError).some(([fieldName, hasError]) => {
      return hasError;
    });
    return !formIsInvalid;
  };

  const memberRequestedAuthsEnabled = useFeature("memberRequestedAuthFeature");
  const updateAORDetailsAfterSubmissionEnabled = useFeature("updateAORDetailsAfterSubmission");
  const isMemberRep = memberRequestedAuthsEnabled && requestor?.requestorType === "MEMBER_REPRESENTATIVE";

  // Phone channel does not require date so should not block submission
  // TODO: COH-5817 replace isHealthPlanGeisinger below with config
  const phoneIsValid =
    enablePhoneRequestorChannelHasTimestamp && isHealthPlanGeisinger
      ? hasValidReceivedDate
      : requestor?.channel === "PHONE";
  // Can remove line below entirely when ripping out updateAORDetailsAfterSubmissionEnabled FF
  const hasValidAorDate = updateAORDetailsAfterSubmissionEnabled ? true : !isMemberRep || hasValidAorReceivedDate;
  const hasValidDates = phoneIsValid || hasValidReceivedDate;
  const hasValidAddress = updateAORDetailsAfterSubmissionEnabled
    ? true
    : validateForm(aorAddressFormState.formError) || requestor?.requestorType !== "MEMBER_REPRESENTATIVE";

  const {
    showModal: showTatUpdateConfirmationModal,
    headerContent: tatModalHeaderContent,
    subHeaderContent: tatModalSubHeaderContent,
  } = calculateTatConfirmationModalContent(
    tatStartTimestamp,
    faxTatStartTimestamp,
    requestor,
    healthPlanName,
    enablePhoneRequestorChannelHasTimestamp
  );

  const saveButtonDisabled = !hasValidDates || !hasValidAddress || !hasValidAorDate;

  const { data: temporaryMemberAuthsConfig, error: temporaryMemberAuthsConfigError } =
    useGetTemporaryMemberAuthsConfigurationByPayer(healthPlanName ?? "");

  if (temporaryMemberAuthsConfigError) {
    const errorMsg = "Error fetching temporaryMemberAuths configuration";
    enqueueSnackbar(errorMsg, { preventDuplicate: true, variant: "error" });
    logError(`${errorMsg} ${stringifyError(temporaryMemberAuthsConfigError.data)}`);
  }

  useEffect(() => {
    if (requestor?.channel === "FAX" && Boolean(faxData)) {
      //only want to set these states from fax data if they haven't been set already
      if (faxData?.dateCreated) {
        const timestamp = removeSecondsFromTimestamp(faxData.dateCreated);

        setFaxTatStartTimestamp((prev) => (prev !== timestamp && !Boolean(prev) ? timestamp : prev));
        setTatStartTimestamp((prev) => (prev !== timestamp && !Boolean(prev) ? timestamp : prev));
      }
    }
  }, [faxData, requestor?.channel]);

  return (
    <>
      <Grid container spacing={2} className={classes.mainContainer} data-testid="requestor-edit-container">
        <Grid item style={{ flexGrow: 1 }} md={responsiveMdWidth ? 4 : "auto"}>
          <AutoShrinkLabelTextField
            fullWidth
            type="text"
            label="First name"
            value={requestor?.firstName || requestor?.user?.firstName || ""}
            onChangeValue={(firstName) => setRequestor((prev) => ({ ...requestor, firstName: firstName }))}
            InputProps={{
              endAdornment: suggestedRequestor?.firstName?.predictedValue ? (
                suggestedRequestor.firstName.predictedValue === requestor?.firstName ? (
                  <AutoFillIcon />
                ) : undefined
              ) : undefined,
            }}
            data-public
          />
        </Grid>
        <Grid item style={{ flexGrow: 1 }} md={responsiveMdWidth ? 4 : "auto"}>
          <AutoShrinkLabelTextField
            fullWidth
            type="text"
            label="Last name"
            value={requestor?.lastName || requestor?.user?.lastName || ""}
            onChangeValue={(lastName) => setRequestor((prev) => ({ ...requestor, lastName: lastName }))}
            InputProps={{
              endAdornment: suggestedRequestor?.lastName?.predictedValue ? (
                suggestedRequestor.lastName.predictedValue === requestor?.lastName ? (
                  <AutoFillIcon />
                ) : undefined
              ) : undefined,
            }}
            data-public
          />
        </Grid>
        <Grid item xs="auto" md={responsiveMdWidth ? 4 : "auto"}>
          <PhoneInput
            type="text"
            label="Fax number"
            value={requestor?.faxNumber || {}}
            onChange={(faxNumber: PhoneNumber) => setRequestor((prev) => ({ ...prev, faxNumber: faxNumber }))}
            error={faxNumberInvalid}
            helperText={faxNumberInvalid && "Fax number must be 10 digits"}
            InputLabelProps={requestor?.faxNumber?.number || "" ? { shrink: true } : {}}
            InputProps={{
              endAdornment: suggestedRequestor?.faxNumber?.predictedValue ? (
                suggestedRequestor.faxNumber.predictedValue === requestor?.faxNumber?.number ? (
                  <AutoFillIcon />
                ) : undefined
              ) : undefined,
            }}
            dataPublic={true}
          />
        </Grid>
        <Grid item xs="auto" md={responsiveMdWidth ? 4 : "auto"}>
          <PhoneInput
            label="Phone number"
            onChange={(phoneNumber: PhoneNumber) => setRequestor((prev) => ({ ...prev, phoneNumber: phoneNumber }))}
            value={requestor?.phoneNumber || { number: requestor?.user?.phone || "" }}
            error={phoneNumberInvalid}
            helperText={phoneNumberInvalid && "Phone number value must be 10 digits"}
            InputLabelProps={requestor?.phoneNumber?.number || requestor?.user?.phone || "" ? { shrink: true } : {}}
            InputProps={{
              endAdornment: suggestedRequestor?.phoneNumber?.predictedValue ? (
                suggestedRequestor.phoneNumber.predictedValue === requestor?.phoneNumber?.number ? (
                  <AutoFillIcon />
                ) : undefined
              ) : undefined,
            }}
            dataPublic={true}
          />
        </Grid>
        <Grid
          item
          xs={isFaxWorkflow ? 12 : "auto"}
          md={isFaxWorkflow ? false : responsiveMdWidth ? 4 : "auto"}
          style={{ width: "100%", minWidth: "200px" }}
        >
          <UserEmailInput
            email={requestor?.email}
            user={requestor?.user}
            setEmail={(email) => setRequestor((prev) => ({ ...prev, email }))}
            setUser={(user) => setRequestor((prev) => ({ ...prev, user }))}
            autoFilled={
              suggestedRequestor?.email?.predictedValue
                ? suggestedRequestor.email.predictedValue === requestor?.email ||
                  suggestedRequestor.email.predictedValue === requestor?.user?.email
                : false
            }
          />
        </Grid>
        {(memberRequestedAuthsEnabled || (!!referralManagementFF && matchReferralRoute)) && (
          <Grid
            item
            xs={isFaxWorkflow ? 12 : "auto"}
            md={isFaxWorkflow ? false : responsiveMdWidth ? 4 : "auto"}
            style={{ flexGrow: 1 }}
          >
            <SingleSelectDropdown
              fullWidth
              label="Requestor type"
              value={requestor?.requestorType || ""}
              options={RequestorTypeOptions}
              onChange={(requestorType) => setRequestor((prev) => ({ ...prev, requestorType: requestorType }))}
            />
          </Grid>
        )}
        {requestor?.requestorType === "MEMBER_REPRESENTATIVE" && (
          <>
            <div
              style={{
                width: "100%",
                marginTop: theme.spacing(1),
                marginLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
              }}
            >
              <DateTimePicker
                channel={requestor.requestorType}
                timestamp={aorReceivedTimestamp}
                setTimestamp={setAorReceivedTimestamp}
                attemptedSave={attemptedSave}
                setHasValidReceivedDate={setHasValidAorReceivedDate}
                hasValidReceivedDate={hasValidAorReceivedDate}
                responsiveMdWidth={responsiveMdWidth}
              />
            </div>
            <div
              style={{
                marginTop: theme.spacing(2),
                marginLeft: theme.spacing(1),
                width: "100%",
                marginRight: theme.spacing(1),
              }}
            >
              <ContactAddressFormSection dispatch={aorAddressDispatch} aorAddress />
            </div>
          </>
        )}
      </Grid>
      <Grid container spacing={2} className={classes.secondContainer}>
        <Grid item xs={12} style={{ padding: theme.spacing(2, 0) }}>
          <RadioGroup
            error={(attemptedSave || attemptedSubmitServices) && !requestor?.channel}
            helperText={(attemptedSave || attemptedSubmitServices) && !requestor?.channel && "Required"}
            row
            label={"How was this contact received"}
            labelClasses={labelClasses}
            options={[
              { id: "FAX", label: "Fax" },
              { id: "PHONE", label: "Phone" },
              { id: "EMAIL", label: "Email" },
              ...(enableMailRequestorChannel ? [{ id: "MAIL", label: "Mail" }] : []),
            ]}
            value={requestor?.channel || ""}
            onChange={(val) => setRequestor((prev) => ({ ...requestor, channel: getChannelFromId(val) }))}
            data-public
          />
        </Grid>
        {requestor?.channel === "EMAIL" && (
          <DateTimePicker
            channel={requestor.channel}
            timestamp={intakeTimestamp}
            setTimestamp={setIntakeTimestamp}
            attemptedSave={attemptedSave}
            setHasValidReceivedDate={setHasValidReceivedDate}
            data-public
            hasValidReceivedDate={hasValidReceivedDate}
          />
        )}

        {enablePhoneRequestorChannelHasTimestamp && requestor?.channel === "PHONE" && isHealthPlanGeisinger && (
          <DateTimePicker
            channel={requestor.channel}
            timestamp={tatStartTimestamp || new Date().toISOString()}
            setTimestamp={setTatStartTimestamp}
            attemptedSave={attemptedSave}
            setHasValidReceivedDate={setHasValidReceivedDate}
            data-public
            hasValidReceivedDate={hasValidReceivedDate}
            dataTestId="phone-date-time-selector-for-requestor"
          />
        )}

        {(requestor?.channel === "FAX" || requestor?.channel === "MAIL") && (
          <DateTimePicker
            channel={requestor.channel}
            timestamp={tatStartTimestamp}
            setTimestamp={setTatStartTimestamp}
            attemptedSave={attemptedSave}
            setHasValidReceivedDate={setHasValidReceivedDate}
            data-public
            hasValidReceivedDate={hasValidReceivedDate}
          />
        )}

        {showTatUpdateConfirmationModal && (
          <InformativeModal
            open={openTATConfirmationModal}
            onClose={() => setOpenTATConfirmationModal(false)}
            headerText={tatModalHeaderContent}
            subHeaderText={tatModalSubHeaderContent}
            primaryButtonText="Confirm"
            primaryButtonAction={() => {
              const requestorUpdates = {
                intakeTimestamp: intakeTimestamp,
                tatStartTimestamp: tatStartTimestamp,
                aorReceivedTimestamp: aorReceivedTimestamp,
                aorAddress: convertAddress(aorAddressFormState.aorAddress),
              };
              setRequestor((prevRequestor) => ({
                ...prevRequestor,
                ...requestorUpdates,
              }));
              setUserUpdatedTat?.(true);
              setAttemptedSave?.(true);

              // setRequestor's update won't happen until next render, but we need to validate now.
              // Therefore we are constructing and validating the expected future state.
              if (requestorValid({ ...requestor, ...requestorUpdates }) && hasValidDates) {
                setAttemptedSave?.(false);
                setEditState?.(false);
                setCollapseRequestorForm?.(true);
              }
              setOpenTATConfirmationModal(false);
            }}
            tertiaryButtonText="Cancel"
            tertiaryButtonAction={() => setOpenTATConfirmationModal(false)}
            data-testid="modal-tat-update-confirmation"
          />
        )}

        {temporaryMemberAuthsEnabled && temporaryMemberAuthsConfig?.enabled && !matchReferralRoute && (
          <div style={{ width: "100%", marginTop: theme.spacing(3) }}>
            <Body2 style={{ paddingBottom: theme.spacing(1) }}>Care recommendation</Body2>
            <Body3>
              This is for requests that should not receive a final decision. Care recommendations do not receive
              notifications and are not sent to claims.
            </Body3>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                paddingTop: theme.spacing(1),
              }}
            >
              <Checkbox
                data-testid="care-recommendation-checkbox"
                checked={requestor?.isCareRecommendationRequest ?? false}
                style={{ color: colorsLight.font.secondary }}
                label="This authorization is for a care recommendation"
                onChange={(val) => {
                  setRequestor((prev) => ({ ...prev, isCareRecommendationRequest: val }));
                }}
              />
            </Box>
          </div>
        )}

        {!isOnPatientSummary && setAttemptedSave && setEditState && (
          <Grid item xs={12} style={{ padding: theme.spacing(2, 0, 0, 0) }}>
            <PrimaryButton
              onClick={() => {
                if (!showTatUpdateConfirmationModal) {
                  const requestorUpdates = {
                    intakeTimestamp: intakeTimestamp,
                    tatStartTimestamp: tatStartTimestamp,
                    aorReceivedTimestamp: aorReceivedTimestamp,
                    aorAddress: convertAddress(aorAddressFormState.aorAddress),
                  };
                  setRequestor((prevRequestor) => ({
                    ...prevRequestor,
                    ...requestorUpdates,
                  }));
                  setUserUpdatedTat?.(false);
                  setAttemptedSave(true);

                  if (requestorValid({ ...requestor, ...requestorUpdates }) && hasValidDates) {
                    setEditState(false);
                    setAttemptedSave(false);
                    setCollapseRequestorForm?.(true);
                  }
                } else {
                  setOpenTATConfirmationModal(true);
                }
              }}
              data-public
              disabled={saveButtonDisabled}
            >
              Save
            </PrimaryButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default RequestorEdit;

function getChannelFromId(val: string): "FAX" | "PHONE" | "EMAIL" | "MAIL" | undefined {
  switch (val) {
    case "FAX":
      return "FAX";
    case "EMAIL":
      return "EMAIL";
    case "MAIL":
      return "MAIL";
    case "PHONE":
      return "PHONE";
    default:
      return undefined;
  }
}

// MUI fails to detect that the label should shrink on input fields because of the
// non-trivial way in which we're automatically filling name, phone, etc
// from the user object
const AutoShrinkLabelTextField = (props: ComponentProps<typeof TextField>) => (
  <TextField InputLabelProps={props.value ? { shrink: true } : {}} {...props} />
);

// Used to validate the user didn't accidentally select the previous year
function isTimeStampOver14Days(inputDate: string | undefined): boolean {
  if (inputDate === undefined) {
    return false;
  }

  return diffDatefromNow(inputDate).days > 14;
}

// Determine whether to show the modal, and if so, its content
function calculateTatConfirmationModalContent(
  tatStartTimestamp?: string,
  faxTatStartTimestamp?: string,
  requestor?: Requestor,
  healthPlanName?: string,
  enablePhoneRequestorChannelHasTimestamp?: boolean
): {
  showModal: boolean;
  headerContent: string | JSX.Element;
  subHeaderContent?: string | JSX.Element;
} {
  const channel = requestor?.channel;
  // TODO: COH-5817 replace below with config
  const isHealthPlanGeisinger = healthPlanName === "Geisinger";

  // Fax TAT timestamps are auto-populated, so users must explicitly confirm
  // any modifications.
  if (channel === "FAX" && faxTatStartTimestamp !== tatStartTimestamp) {
    const tatUpdateCopy = (
      <div>
        <Body1>
          Updating the receipt date will affect the turnaround time for this request. By updating this date, you are you
          are confirming that the submission date (when the provider sent the request) is different than the portal
          entry date (when you entered the request).
          <br></br>
          <br></br>
          Please note, after you submit this request, you will not be able to edit the receipt date.
        </Body1>
      </div>
    );
    const tatUpdatePast14DaysCopy = (
      <div>
        <Body2>
          The timestamp you have entered is over 14 days in the past
          <br></br>
          <br></br>
        </Body2>
        {tatUpdateCopy}
      </div>
    );

    return {
      showModal: true,
      headerContent: "Confirm that you want to change the receipt date",
      subHeaderContent: isTimeStampOver14Days(tatStartTimestamp) ? tatUpdatePast14DaysCopy : tatUpdateCopy,
    };
  }

  // Mail channel: Unlike Fax, this timestamp is not auto-populated, so users
  // only need to confirm if we think there is an error in the date.
  if (channel === "MAIL" && isTimeStampOver14Days(tatStartTimestamp)) {
    return {
      showModal: true,
      headerContent: "Are you sure this date is correct?",
      subHeaderContent: (
        <div>
          <Body1>
            The timestamp you have entered is over 14 days in the past. Please confirm that it is accurate, or click
            cancel to correct it.
          </Body1>
        </div>
      ),
    };
  }

  // TODO: COH-5817 replace below with config
  if (
    enablePhoneRequestorChannelHasTimestamp &&
    channel === "PHONE" &&
    isHealthPlanGeisinger &&
    isTimeStampOver14Days(tatStartTimestamp)
  ) {
    return {
      showModal: true,
      headerContent: "Are you sure this date is correct?",
      subHeaderContent: (
        <div>
          <Body1>
            The timestamp you have entered is over 14 days in the past. Please confirm that it is accurate, or click
            cancel to correct it.
          </Body1>
        </div>
      ),
    };
  }

  return {
    showModal: false,
    headerContent: "",
    subHeaderContent: undefined,
  };
}

function convertAddress(address: ContactInfoT): Address {
  return {
    line1: address.ADDRESS_LINE_1,
    line2: address.ADDRESS_LINE_2,
    city: address.CITY,
    state: address.STATE,
    zipCode: address.ZIP_CODE,
  };
}

export const RequestorTypeOptions: { id: RequestorType; label: string }[] = [
  { id: "ORDERING_PROVIDER", label: "Ordering provider" },
  { id: "PERFORMING_PROVIDER", label: "Performing provider" },
  { id: "PERFORMING_FACILITY", label: "Performing facility" },
  { id: "MEMBER", label: "Member" },
  { id: "MEMBER_REPRESENTATIVE", label: "Member representative" },
  { id: "THIRD_PARTY", label: "3rd party" },
];
