import { ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useCallback } from "react";
import { Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Caption, colorsLight, Pill } from "@coherehealth/common";
import { Body2, Sanitized, Body1, formatDateStrWithTz, Subtitle2 } from "@coherehealth/common";
import { convertOutcomeLabelText, convertOutreachTypeText } from "../infoPanelUtils";
import { convertNumberToString } from "util/NudgeUtils";
import { buildSrGroupingTitle } from "../infoPanelUtils";
import { useTheme } from "@material-ui/core";
import { AuthorizationResponse } from "@coherehealth/core-platform-api";
import { HistoryOutreach } from "../NotesCard/notesCardTypes";

interface ContactComponentProps {
  outreach: HistoryOutreach;
  unexpandable?: boolean;
  buttonPadding?: string;
  expanded?: boolean;
  toggleExpanded?: () => void;
  index: number;
  hasContinuations?: boolean;
  isNewServiceRequest?: boolean;
  isInFilteredState?: boolean;
  authorization?: AuthorizationResponse;
}

const ContactComponent = ({
  outreach,
  expanded,
  unexpandable,
  buttonPadding,
  toggleExpanded,
  index,
  hasContinuations,
  isNewServiceRequest,
  isInFilteredState,
  authorization,
}: ContactComponentProps) => {
  const theme = useTheme();
  const formattedDateTime = formatDateStrWithTz(outreach.dateCreated);
  const classes = useStyles({ expanded, unexpandable, buttonPadding });

  const onHeadingClick = useCallback(() => {
    toggleExpanded?.();
  }, [toggleExpanded]);

  let outreachTypeLabel = convertOutreachTypeText(outreach.outreachType);

  if (outreach.attemptNumber !== undefined || outreach.integrationRetryCount !== undefined) {
    outreachTypeLabel =
      outreachTypeLabel +
      " - " +
      convertNumberToString(outreach.manual ? outreach.attemptNumber : outreach.integrationRetryCount) +
      " attempt";
  }

  return (
    <>
      {outreach.serviceRequestId !== undefined && isNewServiceRequest && !isInFilteredState && (
        <Subtitle2
          style={{ color: theme.palette.info.light, marginTop: index === 0 ? "0px" : "16px", marginBottom: "-8px" }}
        >
          {buildSrGroupingTitle(
            authorization?.serviceRequestsOnAuth?.find((sr) => sr.id === outreach?.serviceRequestId),
            hasContinuations
          )}
        </Subtitle2>
      )}
      <ButtonBase
        component="div"
        className={classes.button}
        onClick={onHeadingClick}
        disabled={unexpandable}
        // style={{ marginTop: index === 0 ? "-16px" : "0px" }}
      >
        <Grid container direction="column">
          <Grid item container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item container direction="row" alignItems="center" xs={11}>
              <Body2 style={{ paddingRight: 16 }}>Contact</Body2>
              <Caption className={classes.thinFont}>
                {outreach.channel === "FAX"
                  ? "Faxed"
                  : outreach.channel === "EMAIL"
                  ? "Emailed"
                  : outreach.channel === "PHONE"
                  ? "Phoned"
                  : "Set Auth Status"}{" "}
                <span className={classes.thinFont}>
                  {formattedDateTime} {outreach.manual ? "by " + outreach.completedByName : "(Automated)"}
                </span>
              </Caption>
            </Grid>
            {!unexpandable && <ExpandMoreIcon className={classes.newDenialsIcon} />}
          </Grid>
          <Grid>
            {outreach.outreachOutcome !== undefined && (
              <Pill
                label={convertOutcomeLabelText(outreach?.outreachOutcome)}
                variant={
                  outreach.outreachOutcome === "SUCCESS"
                    ? "success"
                    : outreach.outreachOutcome === "FAILED"
                    ? "error"
                    : "info"
                }
                style={{ marginRight: 16 }}
              />
            )}

            {outreach.outreachOutcome !== undefined &&
              (outreach.attemptNumber !== undefined || outreach.integrationRetryCount !== undefined) && (
                <Pill label={outreachTypeLabel} variant={"info"} />
              )}
          </Grid>
        </Grid>
      </ButtonBase>
      <Grid item>
        <Body1 component="div">
          <Sanitized
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "-webkit-inline-box",
              WebkitBoxOrient: "vertical",
              lineClamp: !expanded ? 3 : undefined,
              WebkitLineClamp: !expanded ? 3 : undefined,
            }}
            className={classes.sanitized}
            __html={outreach.rawMessageTemplate ? outreach.rawMessageTemplate : outreach.outreachNotes}
          />
        </Body1>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  button: ({ buttonPadding, unexpandable }: Partial<ContactComponentProps>) => ({
    marginTop: 0,
    marginRight: 1,
    display: "flex",
    justifyContent: "space-between",
    textAlign: "start",
    padding: buttonPadding ? buttonPadding : theme.spacing(3, 3, unexpandable ? 0 : 2, 3),
    userSelect: "text",
  }),
  newDenialsIcon: ({ expanded }: Partial<ContactComponentProps>) => ({
    transition: theme.transitions.create("transform"),
    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginBottom: "auto",
  }),
  thinFont: {
    color: colorsLight.font.secondary,
  },
  sanitized: {
    "& *": {
      margin: "0px",
      marginBottom: "6px",
    },
  },
}));

export default ContactComponent;
