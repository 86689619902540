import React from "react";
import { makeStyles } from "@material-ui/core";
import { Alert, useConfiguration } from "@coherehealth/common";
import { ReferralRequestResponse, ReferralRequestSearchResponse } from "@coherehealth/core-platform-api";

interface Props {
  referralRequest: ReferralRequestSearchResponse | ReferralRequestResponse;
}

const useStyles = makeStyles((theme) => ({
  paddedAlert: {
    userSelect: "text",
    flex: 1,
    textAlign: "left",
    padding: theme.spacing(1, 2),
    borderRadius: `${theme.spacing(0.6)}px !important`,
    whiteSpace: "pre-line",
  },
}));

export default function ReferralRequestStatusDisplay({ referralRequest }: Props) {
  const classes = useStyles();
  const { referralStatus, healthPlanDisplayName, healthPlanName, cohereId, isAdministrative } = referralRequest;

  const requestStatusConfiguration = useConfiguration("requestStatusConfiguration", healthPlanName) ?? {};
  switch (referralStatus) {
    case "APPROVED":
      if (!isAdministrative) {
        return (
          <Alert message={"Covered"} className={classes.paddedAlert}>
            Referral #{cohereId || "UNKNOWN"}
          </Alert>
        );
      } else {
        return (
          <Alert message={"Approved administrative referral"} className={classes.paddedAlert}>
            Referral #{cohereId || "UNKNOWN"}
          </Alert>
        );
      }
    case "DENIED":
      return (
        <Alert message={"Non-covered"} className={classes.paddedAlert} severity={"error"}>
          Referral #{cohereId || "UNKNOWN"}
        </Alert>
      );
    case "PENDING":
      const healthPlan =
        healthPlanDisplayName && requestStatusConfiguration?.pendingWithDisplayHealthPlan
          ? healthPlanDisplayName
          : healthPlanName;
      return (
        <Alert message={`Pending review by ${healthPlan}`} className={classes.paddedAlert} severity={"warning"}>
          Tracking #{cohereId || "UNKNOWN"}
        </Alert>
      );
    default:
      return (
        <Alert message={"Status Unknown"} className={classes.paddedAlert} severity={"warning"}>
          Tracking #{cohereId || "UNKNOWN"}
        </Alert>
      );
  }
}
