import React, { Dispatch, SetStateAction, useEffect, useMemo, useState, useContext, useCallback } from "react";
import { H6, RadioGroup, useFeature } from "@coherehealth/common";
import format from "date-fns/format";
import {
  ServiceRequestSearchRequestBody,
  ServiceRequestCounts,
  useGetHealthPlan,
} from "@coherehealth/core-platform-api";
import { Divider, makeStyles } from "@material-ui/core";
import { RadioOptionsWithExpandedButton } from "components/ServiceRequest/ReviewSection/MDReview/MDReviewModal/ModalFields/components/RadioOptionsWithExpandedButton";
import { UserContext } from "UserContext";

import { useGetUserById, HealthPlan } from "@coherehealth/core-platform-api";

interface Props {
  radioId: string;
  queryParams: ServiceRequestSearchRequestBody;
  setQueryParams: Dispatch<SetStateAction<ServiceRequestSearchRequestBody>>;
  serviceRequestCounts: ServiceRequestCounts;
  selectedHealthPlan?: string;
  setSelectedHealthPlan?: React.Dispatch<React.SetStateAction<string>>;
  coBrandingPayerLogosFF?: boolean;
}

const useStyles = makeStyles((theme) => ({
  radioGroupHealthPlanContainer: {
    marginBottom: theme.spacing(3),
  },
  radioGroupAuthStatusContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const COUNT_MAX = 10000;

const baseStatusOptions: Record<
  string,
  Partial<{
    startDate: string;
    sort: string;
    authStatus: string;
    withdrawnReason: string;
  }>
> = {
  All: {
    sort: "lastUpdated:desc",
  },
  Upcoming: {
    startDate: `ge:${format(new Date(Date.now()), "yyyy-MM-dd")}`,
    sort: "startDate:asc",
  },
  "Pending Review": {
    authStatus: "in:PENDING,INTAKE,RECOMMENDED_DENIAL,PENDING_EXTERNAL_DETERMINATION",
    sort: "startDate:asc",
  },
  Approved: {
    authStatus: "eq:APPROVED",
    sort: "startDate:asc",
  },
  Denied: {
    authStatus: "in:DENIED",
    sort: "startDate:asc",
  },
  "Partially Approved": {
    authStatus: "in:PARTIALLY_APPROVED",
    sort: "startDate:asc",
  },
  Draft: {
    authStatus: "eq:DRAFT",
    sort: "startDate:asc",
  },
  Withdrawn: {
    authStatus: "eq:WITHDRAWN",
    withdrawnReason: "ne:INTEGRATION_FAILURE",
    sort: "startDate:asc",
  },
  Completed: {
    authStatus: "eq:APPROVED",
    startDate: `lt:${format(new Date(Date.now()), "yyyy-MM-dd")}`,
    sort: "startDate:desc",
  },
};

export default function ServiceRequestFilter({
  radioId,
  queryParams,
  setQueryParams,
  serviceRequestCounts,
  selectedHealthPlan,
  setSelectedHealthPlan,
  coBrandingPayerLogosFF,
}: Props) {
  const { data: healthPlans } = useGetHealthPlan({});
  const partialApprovalEnabled = useFeature("partialApproval");
  const [selectedStatus, setSelectedStatus] = useState("All");
  let showAllOptions = false;
  const classes = useStyles();

  const { data: user, refetch: fetchUserDetails } = useGetUserById({
    id: "",
    lazy: true,
  });

  const { getUser } = useContext(UserContext);

  const activeHealthPlans = useMemo(() => {
    let filteredHealthPlans: HealthPlan[] = [];

    if (coBrandingPayerLogosFF) {
      if (!user) {
        getUser()?.then((currentUser) => {
          if (currentUser?.sub && currentUser.groups && currentUser.groups.filter((x) => x !== "Everyone").length > 0) {
            // Only bother fetching user details if the user belongs to groups; otherwise we won't find one anyway
            fetchUserDetails({ pathParams: { id: currentUser.sub } });
          }
        });
      }

      if (user) {
        if (user.organizationHealthPlans) {
          if (healthPlans && healthPlans.length > 0) {
            filteredHealthPlans = healthPlans?.filter(
              (plan) => user?.organizationHealthPlans && user?.organizationHealthPlans.includes(plan?.name || "")
            );
          }

          if (filteredHealthPlans.length > 0) {
            return [
              { id: "allHealthPlan", label: "All" }, // includes all health plans
              ...filteredHealthPlans
                .filter((plan) => plan.status?.toLowerCase() === "active")
                .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
                .map((plan) => ({ id: plan.name || "", label: plan.name || "" })),
            ];
          }
        } else {
          return [];
        }
      }
    }

    return healthPlans
      ? [
          { id: "allHealthPlan", label: "All" }, // includes all health plans
          ...healthPlans
            .filter((plan) => plan.status?.toLowerCase() === "active")
            .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
            .map((plan) => ({ id: plan.name || "", label: plan.name || "" })),
        ]
      : [];
  }, [healthPlans, coBrandingPayerLogosFF, user, fetchUserDetails, getUser]);

  const healthPlanOptions = constructServiceRequestHealthPlanOptions(activeHealthPlans);
  const query = sessionStorage?.getItem("query");

  const updateSessionQueryParams = useCallback(() => {
    if (!query) {
      sessionStorage.setItem("query", JSON.stringify(queryParams));
    } else {
      const parsedQuery = query ? JSON.parse(query) : queryParams;
      setQueryParams({
        ...parsedQuery,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateSessionQueryParams();
  }, [updateSessionQueryParams]);

  useEffect(() => {
    const query = sessionStorage?.getItem("query");
    const parsedQuery = query ? JSON.parse(query) : queryParams;
    const queryParamAuthStatus = parsedQuery.authStatus?.substring(3);
    for (const [key, value] of Object.entries(baseStatusOptions)) {
      if (value.authStatus) {
        const status = value.authStatus.substring(3);
        if (status === queryParamAuthStatus) {
          if (queryParams.startDate) {
            setSelectedStatus("Completed");
          } else {
            setSelectedStatus(key);
          }
          break;
        }
      }
    }
  }, [queryParams, queryParams.startDate]);

  useEffect(() => {
    const query = sessionStorage?.getItem("query");
    const parsedQuery = query ? JSON.parse(query) : queryParams;
    const queryParamHealthPlanName = parsedQuery.healthPlanName?.substring(3);
    const filteredHealthPlan = activeHealthPlans.find((plan) => plan.label === queryParamHealthPlanName);

    if (filteredHealthPlan) {
      setSelectedHealthPlan?.(filteredHealthPlan.id);
    }
  }, [activeHealthPlans, queryParams, setSelectedHealthPlan]);

  const handleHealthPlanChange = (val: string) => {
    let queryParam: ServiceRequestSearchRequestBody = {};
    if (val === "allHealthPlan") {
      setSelectedHealthPlan?.("allHealthPlan");
      queryParam = {
        ...queryParams,
        healthPlanName: undefined,
      };
    } else {
      setSelectedHealthPlan?.(val);
      queryParam = {
        ...queryParams,
        healthPlanName: healthPlanOptions[val].healthPlanName,
      };
    }

    setQueryParams(queryParam);
    sessionStorage.setItem("query", JSON.stringify(queryParam));
  };

  const handleStatusChange = (val: string) => {
    const offsetOrNull = { offset: 0 };
    const { order, sort, authStatus, startDate, withdrawnReason, ...restOfQueryParams } = queryParams;
    setSelectedStatus(val);
    const qps = {
      ...restOfQueryParams,
      ...baseStatusOptions[val],
      ...offsetOrNull,
    };
    setQueryParams(qps);
    sessionStorage.setItem("query", JSON.stringify(qps));
  };

  const renderSRAuthRadioOption = () => {
    const filteredOptions = Object.keys(baseStatusOptions)
      .filter((label) => partialApprovalEnabled || label !== "Partially Approved")
      .map((label) => {
        const count = optionLabelCount(label, serviceRequestCounts) || 0;
        const maxedCount = Math.min(count, COUNT_MAX);
        const countLabel = `${maxedCount}${maxedCount >= COUNT_MAX ? "+" : ""}`;
        return {
          id: label,
          label: `${label} (${countLabel})`,
        };
      });

    return (
      <RadioGroup
        data-public
        id={radioId}
        options={filteredOptions}
        value={selectedStatus}
        onChange={(val) => handleStatusChange(val)}
      />
    );
  };

  const renderSRAuthRadioOptionWithHealthplan = () => {
    return (
      <>
        {activeHealthPlans && activeHealthPlans.length > 2 && (
          <>
            <div className={classes.radioGroupHealthPlanContainer}>
              <RadioOptionsWithExpandedButton
                options={activeHealthPlans}
                selectedOption={selectedHealthPlan}
                onOptionChange={handleHealthPlanChange}
                heading="Health plan"
                showAllInitially={showAllOptions}
                numberOfItemsToShow={4}
              />
            </div>
            <Divider />
          </>
        )}
        <Divider />
        <div className={classes.radioGroupAuthStatusContainer}>
          <H6 component="label" htmlFor={radioId} data-public>
            Status
          </H6>
          {renderSRAuthRadioOption()}
        </div>
      </>
    );
  };

  return <div>{coBrandingPayerLogosFF ? renderSRAuthRadioOptionWithHealthplan() : renderSRAuthRadioOption()}</div>;
}

const constructServiceRequestHealthPlanOptions = (
  activeHealthPlans: {
    id: string;
    label: string;
  }[]
) => {
  const options = activeHealthPlans.reduce((acc, curr) => {
    if (curr.id === "allHealthPlan") {
      acc[curr.label] = { healthPlanName: `in:${activeHealthPlans.map((plan) => plan.label)}` };
    } else {
      acc[curr.label] = { healthPlanName: `eq:${curr.label}` };
    }
    return acc;
  }, {} as Record<string, { healthPlanName: string }>);

  return options;
};

function optionLabelCount(opt: string, serviceRequestCounts: ServiceRequestCounts) {
  switch (opt) {
    case "All":
      return serviceRequestCounts.all;
    case "Upcoming":
      return serviceRequestCounts.upcoming;
    case "Pending Review":
      return serviceRequestCounts.pendingReview;
    case "Approved":
      return serviceRequestCounts.approved;
    case "Denied":
      return serviceRequestCounts.denied;
    case "Partially Approved":
      return serviceRequestCounts.partiallyApproved;
    case "Draft":
      return serviceRequestCounts.draft;
    case "Withdrawn":
      return serviceRequestCounts.withdrawn;
    case "Completed":
      return serviceRequestCounts.completed;
    default:
      return 0;
  }
}
