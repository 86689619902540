import Collapse from "@material-ui/core/Collapse";
import { Box, Divider, makeStyles, useTheme } from "@material-ui/core";
import { Body2, Caption, H4 } from "@coherehealth/design-system";
import { DetailsField } from "../../ClinicalReview/ClinicalReviewInfoPanel/CaseOverview";
import { colorsLight, formatDateStr, formatDateStrAsEastern, InlineButton } from "@coherehealth/common";
import { ReferralRequestResponse } from "@coherehealth/core-platform-api";
import React, { useState } from "react";
import ContactInfoModal, { RequestorInfoModal } from "../../ContactInfoModal";
import ReferralRequestAuditHistory from "./ReferralRequestAuditHistory";
import { useAuthorized } from "../../../authorization";
import Grid from "@material-ui/core/Grid";
import { getDetailFields } from "util/referralRequest";

interface Props {
  expanded: boolean;
  referralRequest: ReferralRequestResponse;
}

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(3, 0),
  },
  cardHeader: {
    paddingBottom: theme.spacing(3),
  },
  viewInfoButton: {
    margin: theme.spacing(0, 1.5),
    marginTop: theme.spacing(0.125),
    height: theme.spacing(2),
  },
  footerText: {
    paddingTop: theme.spacing(0.125),
    color: colorsLight.font.light,
  },
  viewInfoButtonV2: {
    marginTop: theme.spacing(0.125),
    height: theme.spacing(2),
  },
}));

export default function ReferralRequestSummaryDetails({ expanded, referralRequest }: Props) {
  const {
    startDate,
    endDate,
    primarySemanticDiagnosisCode,
    secondarySemanticDiagnosisCodes,
    selectedReferringProvider,
    selectedPerformingSpecialist,
    selectedFacility,
    requestor,
    specialty,
  } = referralRequest;

  const requestorName =
    requestor?.firstName && requestor.lastName ? requestor?.firstName + " " + requestor?.lastName : undefined;
  const detailFields = getDetailFields(referralRequest?.isAdministrative ?? false);
  const canShowHistory = useAuthorized("VIEW_SERVICE_REQUEST_AUDIT_LOG");

  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [showReferringProviderModal, setShowReferringProviderModal] = useState<boolean>(false);
  const [showSpecialistPracticeModal, setShowSpecialistPracticeModal] = useState<boolean>(false);
  const [showSpecialistModal, setShowSpecialistModal] = useState<boolean>(false);
  const [showRequestorModal, setShowRequestorModal] = useState<boolean>(false);

  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Collapse in={expanded} timeout={"auto"} unmountOnExit>
        <Divider className={classes.divider} />
        <H4 className={classes.cardHeader}>Details</H4>
        {detailFields.includes("DATES_VALID") && (
          <DetailsField label={"Dates valid"} value={formatDateStr(startDate) + " - " + formatDateStr(endDate)} />
        )}
        {detailFields.includes("PRIMARY_DIAGNOSIS") && (
          <DetailsField
            label={"Primary diagnosis"}
            value={
              primarySemanticDiagnosisCode
                ? primarySemanticDiagnosisCode.code + " - " + primarySemanticDiagnosisCode?.description
                : "-"
            }
          />
        )}
        {detailFields.includes("SECONDARY_DIAGNOSIS") && (
          <DetailsField
            label={"Secondary diagnosis"}
            value={
              secondarySemanticDiagnosisCodes && secondarySemanticDiagnosisCodes?.length !== 0
                ? secondarySemanticDiagnosisCodes?.map((d) => d.code).join(", ")
                : "-"
            }
          />
        )}
        {detailFields.includes("REFERRING_PROVIDER") && (
          <Box style={{ display: "flex" }}>
            <DetailsField label={"Referring provider"} value={selectedReferringProvider?.name || "-"} />
            <InlineButton
              onClick={() => {
                setShowReferringProviderModal(true);
              }}
              className={classes.viewInfoButton}
              id={"referringProviderViewInfoBtn"}
            >
              <Body2>View info</Body2>
            </InlineButton>
          </Box>
        )}
        {detailFields.includes("SPECIALTY") && <DetailsField label={"Specialty"} value={specialty} />}
        {detailFields.includes("SPECIALIST_PRACTICE") && (
          <Box style={{ display: "flex" }}>
            <DetailsField
              label={"Specialist practice"}
              value={selectedFacility?.name + " / NPI - " + selectedFacility?.npi}
            />
            <InlineButton
              onClick={() => {
                setShowSpecialistPracticeModal(true);
              }}
              className={classes.viewInfoButton}
              id={"specialistPracticeViewInfoBtn"}
            >
              <Body2>View info</Body2>
            </InlineButton>
          </Box>
        )}
        {detailFields.includes("SPECIALIST") && (
          <Box style={{ display: "flex" }}>
            <DetailsField label={"Specialist"} value={selectedPerformingSpecialist?.name || "-"} />
            <InlineButton
              onClick={() => {
                setShowSpecialistModal(true);
              }}
              className={classes.viewInfoButton}
              id={"specialistViewInfoBtn"}
            >
              <Body2>View info</Body2>
            </InlineButton>
          </Box>
        )}
        <Divider className={classes.divider} style={{ marginTop: theme.spacing(2) }} />
        {canShowHistory && (
          <Grid item xs={12}>
            <InlineButton
              title="Toggle audit history"
              onClick={() => {
                if (canShowHistory) {
                  setShowHistory(!showHistory);
                }
              }}
            >
              <Caption color="primary" style={{ fontWeight: 600, fontSize: "13px" }}>
                {
                  "Submitted " +
                    formatDateStrAsEastern(referralRequest.startDate) /*TODO: change to intakeTimestamp once available*/
                }
              </Caption>
            </InlineButton>
          </Grid>
        )}
        {canShowHistory && showHistory && <ReferralRequestAuditHistory referralRequest={referralRequest} />}
        <Divider className={classes.divider} style={{ marginTop: theme.spacing(2) }} />
        <Box style={{ display: "flex" }}>
          <Caption className={classes.footerText}>Requested by {requestorName || "Unkown"}</Caption>
          <InlineButton
            onClick={() => {
              setShowRequestorModal(true);
            }}
            className={classes.viewInfoButtonV2}
            id={"requestorViewInfoBtn"}
          >
            <Body2 style={{ fontSize: theme.spacing(1.625) }}>View info</Body2>
          </InlineButton>
        </Box>
      </Collapse>
      <ContactInfoModal
        open={showReferringProviderModal}
        handleClose={() => {
          setShowReferringProviderModal(false);
        }}
        type={"REFERRING_PROVIDER"}
        name={selectedReferringProvider?.name}
        npiLabel={selectedReferringProvider?.npi}
        tinList={[
          referralRequest.healthPlanName === "BCBS South Carolina"
            ? "--"
            : selectedReferringProvider?.selectedLocation?.tin || "None",
        ]}
        phones={selectedReferringProvider?.selectedLocation?.phoneNumbers}
        addresses={[selectedReferringProvider?.selectedLocation?.address]}
      />
      <ContactInfoModal
        open={showSpecialistPracticeModal}
        handleClose={() => {
          setShowSpecialistPracticeModal(false);
        }}
        type={"SPECIALIST_PRACTICE"}
        name={selectedFacility?.name}
        npiLabel={selectedFacility?.npi}
        tinList={[
          referralRequest.healthPlanName === "BCBS South Carolina"
            ? "--"
            : selectedFacility?.selectedLocation?.tin || "None",
        ]}
        phones={selectedFacility?.selectedLocation?.phoneNumbers}
        addresses={[selectedFacility?.selectedLocation?.address]}
      />
      <ContactInfoModal
        open={showSpecialistModal}
        handleClose={() => {
          setShowSpecialistModal(false);
        }}
        type={"SPECIALIST"}
        name={selectedPerformingSpecialist?.name}
        npiLabel={selectedPerformingSpecialist?.npi}
        tinList={[
          referralRequest.healthPlanName === "BCBS South Carolina"
            ? "--"
            : selectedPerformingSpecialist?.selectedLocation?.tin || "None",
        ]}
        phones={selectedPerformingSpecialist?.selectedLocation?.phoneNumbers}
        addresses={[selectedPerformingSpecialist?.selectedLocation?.address]}
      />
      <RequestorInfoModal
        open={showRequestorModal}
        handleClose={() => {
          setShowRequestorModal(false);
        }}
        type={"REQUESTOR"}
        name={requestorName || "--"}
        phones={[requestor?.phoneNumber]}
        email={requestor?.email}
        fax={[requestor?.faxNumber]}
        addresses={[requestor?.aorAddress]}
      />
    </>
  );
}
