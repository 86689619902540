import { useGetServiceRequestByIdWithFallback } from "@coherehealth/common";
import useClaimHistory from "components/ClaimHistory/useClaimHistory";
import useOtherAuthorizations from "../ClinicalReviewInfoPanel/OtherServiceRequests/useOtherAuthorization";
import { InjectedHooks } from "./types";
import {
  useGetPatient,
  useGetServiceRequestOutreachOpportunities,
  useGetLatestReviewLeadingToDenial,
  useIsCoverageActive,
  useGetAllCoverages,
  useGetAllReviews,
  useGetServiceRequests,
} from "@coherehealth/core-platform-api";
import { useGetServiceCase, useGetServiceRequestCases } from "@coherehealth/qm-api";
import { useAttachments } from "../reviewUtils/useAttachment";
import { useAuthorizationFetch } from "../reviewUtils/utils";
import { useClinicalReviewFeatureFlags } from "./hooks/useClinicalReviewFeatureFlags";
import { useClinicalReviewPageConfigurations } from "./hooks/useClinicalReviewPageConfigurations";
import { useClinicalReviewPageUserAuth } from "./hooks/useClinicalReviewPageUserAuths";
import { useServiceRequestRuleResultsForClinicalReview } from "../reviewUtils/useServiceRequestRuleResultsForClinicalReview";

export const managerProdHooks: InjectedHooks = {
  useGetServiceRequest: useGetServiceRequestByIdWithFallback,
  useGetPatient,
  useGetServiceRequestOutreachOpportunities,
  useGetLatestReviewLeadingToDenial,
  useIsCoverageActive,
  useGetAllCoverages,
  useAuthorizationFetch,
  useGetAllReviews,
  useGetServiceRequests,
  useOtherAuthorizations,
  useClaimHistory,
  useClinicalReviewFeatureFlags,
  useClinicalReviewPageConfigurations,
  useClinicalReviewPageUserAuth,
  useAttachments,
  useServiceRequestRuleResultsForClinicalReview,
  useGetServiceCase,
  useGetServiceRequestCases,
};
