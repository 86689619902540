import React, { Dispatch, SetStateAction, useState } from "react";

import { TextField } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";
import Search from "@material-ui/icons/Search";

import { ServiceRequestSearchRequestBody } from "@coherehealth/core-platform-api";

interface Props {
  queryParams: ServiceRequestSearchRequestBody;
  setQueryParams: Dispatch<SetStateAction<ServiceRequestSearchRequestBody>>;
}

export default function ServiceRequestSearchBar({ queryParams, setQueryParams }: Props) {
  const [searchText, setSearchText] = useState(queryParams.query ? queryParams.query : "");

  const onClearSearch = () => {
    setSearchText("");
    searchServiceRequests("");
  };

  const searchServiceRequests = (searchText: string) => {
    const offsetOrNull = { offset: 0 };
    setQueryParams({ ...queryParams, query: `${searchText}`, ...offsetOrNull });
  };

  return (
    <SearchServiceRequests
      placeholder="Search existing services (Patient name, Member ID, Tracking ID, Auth ID)"
      data-testid="search-bar"
      value={searchText}
      onChange={(e) => {
        if (searchText !== e.target.value) {
          setSearchText(e.target.value);
          searchServiceRequests(e.target.value);
        }
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          searchServiceRequests(searchText);
        }
      }}
      InputProps={{
        startAdornment: (
          <SearchAdornment position="start">
            <IconButton
              aria-label="search"
              onClick={() => {
                searchServiceRequests(searchText);
              }}
            >
              <Search />
            </IconButton>
          </SearchAdornment>
        ),
        endAdornment: (
          <ClearAdornment position="end">
            {searchText && (
              <IconButton aria-label="clear" onClick={onClearSearch}>
                <Clear />
              </IconButton>
            )}
          </ClearAdornment>
        ),
      }}
    />
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SearchServiceRequests = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SearchAdornment = styled(InputAdornment)(({ theme }) => ({
  marginLeft: -9,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ClearAdornment = styled(InputAdornment)(({ theme }) => ({
  marginRight: -9,
}));
