import { ServiceCase } from "@coherehealth/qm-api";
import { DocumentInfo } from "../DocumentViewer";

export type ReasonForNoticeOptions = "MISSING_INFO" | "OUT_OF_SCOPE";

/**
 * Common props that a sidebar view might need
 */
export interface CommonFaxSidebarViewProps {
  /**
   * Meta data about the file attachment to show
   */
  fileData: Partial<DocumentInfo>;
  /**
   * Refetch file data
   */
  refreshFileData?: () => void;
  /**
   * Sets the sidebar to a new view
   */
  setSidebarView: (newView: FaxSidebarView) => void;
  /**
   * Image url
   */
  url?: string;
  /**
   * File data for fax
   */
  blob?: Blob | null;
  /**
   * ServiceCase
   */
  serviceCase?: ServiceCase | null;
}

interface Error {
  name: string;
  message: string;
}

export interface DocumentParams {
  blob: Blob | null;
  error: Error | null;
  url: string | null;
  loading: boolean;
}

export type FaxSidebarView =
  | "CREATE_NEW_SR_CONFIRMATION"
  | "CURRENTLY_ATTACHED_SERVICE_REQUESTS"
  | "CREATE_FAX_NOTICE"
  | "FINISH_OCR_SR"
  | "DOWNLOAD_CONFIRMATION"
  | "UNWORKABLE_FAX"
  | "QUEUE_SUPERVISOR_VIEW"
  | "QUEUE_AGENT_VIEW"
  | "EDIT_SERVICE_VIEW"
  | "CONTINUE_SERVICE_VIEW"
  | "AUTH_SEARCH_VIEW"
  | "FORWARDED_FAX";

export type FaxNoticeMissingInformation = {
  patientInfoMissing?: boolean;
  practiceAuthContactMissing?: boolean;
  diagnosisInfoMissing?: boolean;
  servicesRequestedInfoMissing?: boolean;
  orderingPhysicianInfoMissing?: boolean;
  facilityInfoMissing?: boolean;
  additionalNotes?: string;
};

export type FaxNoticeOutOfScopeInformation = {
  ineligiblePatient?: boolean;
  ineligibleProvider?: boolean;
  mustBePCPSubmitted?: boolean;
  additionalNotes?: string;
};

export const getServiceRequestsFaxAttachedTo = (fileData: Partial<DocumentInfo>) =>
  fileData?.attachedTo?.filter((at) => at.type === "serviceRequest") || [];

export const INCOMING_FAX_SIDEBAR_WIDTH = 608;
export const OUTGOING_FAX_SIDEBAR_WIDTH = 400;
export const INCOMING_FAX_LEFT_SIDEBAR_WIDTH = 624;
export const backToMenuView = "AUTH_SEARCH_VIEW";
