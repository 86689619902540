import React, { useEffect, useContext, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import GetApp from "@material-ui/icons/GetApp";
import { UploadFile } from "./FileUploadItem";
import useOcrEnhancedPath from "./useOcrEnhancedPath";
import { downloadUrl } from "@coherehealth/common";
import { UserContext } from "UserContext";
import { useTrackUserInteraction } from "util/userActivityTracker";
import { useBearerToken } from "hooks/useBearerToken";

interface FileDownloadProps {
  file: UploadFile;
  disabled?: boolean;
  downloadAttachmentPath: string;
  stage?: string;
}

export default function FileDownload({
  file,
  disabled = false,
  downloadAttachmentPath: originalDownloadAttachmentPath,
  stage,
}: FileDownloadProps) {
  const downloadAttachmentPath = useOcrEnhancedPath(originalDownloadAttachmentPath);
  const trackInteraction = useTrackUserInteraction();
  const { getUser } = useContext(UserContext);
  const [userId, setUserId] = useState<string>();

  useEffect(() => {
    getUser()?.then((currentUser) => {
      setUserId(currentUser?.sub || "");
    });
  }, [getUser]);

  const bearerToken = useBearerToken();

  const onDownloadAttachment = async () => {
    if (!!stage && stage === "PATIENT_SUMMARY") {
      trackInteraction({
        event: "DOWNLOAD_ATTACHMENT",
        stage: stage,
        field: "ATTACHMENTS",
        activityContext: {
          serviceRequestId: file.serviceRequest?.id,
          userId: userId,
          attachmentId: file.id,
        },
      });
    }

    const response = await fetch(downloadAttachmentPath, {
      headers: {
        Authorization: bearerToken,
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      if (blob.type === "application/pdf" && file.contentType !== "application/pdf") {
        const splitFileName = file.name?.split(".");
        splitFileName?.splice(splitFileName.length - 1, 1, "pdf");
        const pdfConvertedFileName = splitFileName?.join(".");
        downloadUrl(url, pdfConvertedFileName);
      } else {
        downloadUrl(url, file.name);
      }
    }
  };

  return (
    <IconButton aria-label="Download" onClick={onDownloadAttachment} disabled={disabled}>
      <GetApp />
    </IconButton>
  );
}
