import {
  Caption,
  Body1,
  formatDateStr,
  H5,
  InlineButton,
  patientDisplayName,
  useFeature,
  parseDateFromISOStringWithoutFallback,
} from "@coherehealth/common";
import { Coverage, Patient, usePatientCoverages } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Divider, Grid, styled } from "@material-ui/core";
import { Dispatch, SetStateAction, useMemo, useState, useEffect } from "react";
import {
  coveragePriority,
  getCoverageBasedOnDate,
  getSortedListOfCoverages,
  removeDuplicateCoverages,
} from "util/patientUtils";
import {
  ageStringFromDobString,
  patientGenderDisplay,
  formatAnyPhone,
  singleLineAddress,
  preferredWrittenLanguageDisplay,
} from "util/demographicUtils";
import ClinicalReviewCard from "common/ClinicalReviewCard";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { generatePath } from "react-router";
import routes from "routes";
import { incrementClickCount, UserClickInfoClinicalReviewPage } from "@coherehealth/common";
import CoveragesTable from "./CoveragesTable";

interface Props {
  patient?: Patient | null;
  loadingPatientData?: boolean;
  crrDenials: boolean | undefined;
  userClickInfoTracking?: UserClickInfoClinicalReviewPage;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  startDate?: string;
  serviceRequestId: string;
}
export default function PatientInformation({
  patient,
  crrDenials,
  setUserClickInfoTracking,
  startDate,
  serviceRequestId,
}: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(!crrDenials);
  const [tableCoverages, setTableCoverages] = useState<Coverage[]>();
  const { mutate: patientCoverages } = usePatientCoverages({});

  const showPatientCoverageCoordationOfBenefitsSection = useFeature("showPatientCoverageCoordationOfBenefitsSection");

  useEffect(() => {
    let componentIsMounted = true;
    const getCoverages = async () => {
      if (patient && startDate && patient.coverages?.length) {
        const response = await patientCoverages({
          serviceRequestStartDate: startDate,
          dateOfBirth: patient.dateOfBirth,
          firstName: patient.firstName,
          lastName: patient.lastName,
          fullName: patient.name,
          healthPlanName: getCoverageBasedOnDate(parseDateFromISOStringWithoutFallback(startDate), patient)
            ?.healthPlanName,
        });
        if (componentIsMounted) {
          setTableCoverages(response || []);
        }
      }
    };
    if (patient && startDate && !tableCoverages) {
      getCoverages();
    }
    return () => {
      componentIsMounted = false;
    };
  }, [patient, startDate, tableCoverages, patientCoverages]);

  const priorityCoverages = coveragePriority(tableCoverages ? tableCoverages : []);
  const orderedCoverages = useMemo(
    () =>
      priorityCoverages
        ? priorityCoverages?.map((coverage, index) => {
            const orderedCoverage = coverage as OrderedCoverage;
            if (index === 0) {
              orderedCoverage.first = true;
            }
            return orderedCoverage;
          })
        : [],
    [priorityCoverages]
  );

  const coverages = patient && getSortedListOfCoverages(patient);
  let activePrimaryCoverage: Coverage | undefined;
  if (coverages?.[0]?.planActive) {
    activePrimaryCoverage = coverages.shift();
  }
  const updateUserClickInfo = () => {
    setUserClickInfoTracking && incrementClickCount("PatientInformationCardToggle", "", -1, setUserClickInfoTracking);
  };
  const patientDOB = useMemo(() => formatDateStr(patient?.dateOfBirth), [patient?.dateOfBirth]);
  const patientAge = useMemo(() => ageStringFromDobString(patient?.dateOfBirth || ""), [patient?.dateOfBirth]);
  const patientGender = useMemo(() => patientGenderDisplay(patient?.gender), [patient?.gender]);
  const patientPrefWrittenLang = useMemo(
    () => preferredWrittenLanguageDisplay(patient?.languagePreference?.written),
    [patient?.languagePreference?.written]
  );
  const patientPlanStart = useMemo(
    () => formatDateStr(activePrimaryCoverage?.planStart),
    [activePrimaryCoverage?.planStart]
  );
  const patientPlanEnd = useMemo(() => formatDateStr(activePrimaryCoverage?.planEnd), [activePrimaryCoverage?.planEnd]);
  const patientPhoneNumber = useMemo(() => formatAnyPhone(patient?.phones), [patient?.phones]);
  const patientLineAddress = useMemo(() => singleLineAddress(patient?.address), [patient?.address]);
  return (
    <ClinicalReviewCard
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      updateUserClickInfo={updateUserClickInfo}
      header={
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: 6 }}>
            <H5>Patient Information</H5>
          </Grid>
          <div style={{ marginRight: 24, marginBottom: isExpanded ? "" : 4 }}>
            <StyledBody1>{patient && patientDisplayName(patient)}</StyledBody1>
          </div>
          <div>
            <StyledBody1>Member ID {patient?.memberId}</StyledBody1>
          </div>
        </Grid>
      }
    >
      {isExpanded && (
        <Grid container>
          <Column1Entry>
            <Caption>DOB</Caption>
            <Body1>{patientDOB}</Body1>
          </Column1Entry>
          <ColumnEntry>
            <Caption>Age</Caption>
            <Body1>{patientAge}</Body1>
          </ColumnEntry>
          <ColumnEntry>
            <Caption>Sex</Caption>
            <Body1>{patientGender}</Body1>
          </ColumnEntry>
          <ColumnEntry>
            <Caption>Preferred written language</Caption>
            <Body1>{patientPrefWrittenLang}</Body1>
          </ColumnEntry>
          <ColumnEntry>
            <Caption>PCP grouper ID</Caption>
            <Body1>{activePrimaryCoverage?.grouperId}</Body1>
          </ColumnEntry>
          <Grid item xs={12}>
            <StyledDivider />
          </Grid>
          <Column1Entry>
            <Caption>Phone</Caption>
            <Body1>{patientPhoneNumber}</Body1>
          </Column1Entry>
          <ColumnEntry>
            <Caption>Address</Caption>
            <Body1>{patientLineAddress}</Body1>
          </ColumnEntry>
          <Grid item xs={12}>
            <StyledDivider />
          </Grid>
          {showPatientCoverageCoordationOfBenefitsSection &&
          orderedCoverages &&
          removeDuplicateCoverages(orderedCoverages).length > 1 ? (
            <CoveragesTable coverages={removeDuplicateCoverages(orderedCoverages)} />
          ) : (
            <>
              <Column1Entry>
                <Caption>Plan</Caption>
                <Body1>{activePrimaryCoverage?.healthPlanName}</Body1>
              </Column1Entry>
              <ColumnEntry>
                <Caption>Plan type</Caption>
                <Body1>
                  {activePrimaryCoverage?.coverageProductType
                    ? activePrimaryCoverage?.coverageProductType
                    : activePrimaryCoverage?.productType || " - "}
                </Body1>
              </ColumnEntry>
              <ColumnEntry>
                <Caption>Membership type</Caption>
                <Body1>
                  {activePrimaryCoverage?.coverageLineOfBusinessType
                    ? activePrimaryCoverage?.coverageLineOfBusinessType
                    : activePrimaryCoverage?.lineOfBusinessType}
                </Body1>
              </ColumnEntry>
              <ColumnEntry>
                <Caption>Plan year</Caption>
                <Body1>{patientPlanStart + " - " + patientPlanEnd}</Body1>
              </ColumnEntry>
            </>
          )}
          <Grid item xs={12}>
            <StyledDivider />
          </Grid>
          <InlineButton
            data-testid="patient-summary-redirect-button"
            startIcon={<OpenInNewIcon />}
            onClick={() => {
              setUserClickInfoTracking &&
                incrementClickCount("OpenPatientSummaryHyperLink", "", -1, setUserClickInfoTracking);
              window.open(
                generatePath(`${routes.PATIENT_SUMMARY}?reviewServiceRequestId=${serviceRequestId}`, {
                  patientId: patient?.id,
                })
              );
            }}
          >
            Open patient summary
          </InlineButton>
        </Grid>
      )}
    </ClinicalReviewCard>
  );
}

interface OrderedCoverage extends Coverage {
  first?: boolean;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledDivider = styled(Divider)(({ theme }) => ({
  width: "100%",
  margin: theme.spacing(2, 0, 2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledBody1 = styled(Body1)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Column1Entry = styled("div")(({ theme }) => ({
  width: theme.spacing(14),
  marginRight: theme.spacing(4),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ColumnEntry = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(3),
}));
