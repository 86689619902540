import React, { ComponentProps, HTMLAttributes } from "react";

import { H2, Modal, PrimaryButton, SingleSelect } from "@coherehealth/common";
import { HmoExceptionReview } from "@coherehealth/core-platform-api";
import Grid from "@material-ui/core/Grid";

type ReviewOutcome = NonNullable<HmoExceptionReview>["reviewOutcome"];
type PerformingProviderReviewOutcome = NonNullable<HmoExceptionReview>["performingProviderReviewOutcome"];
type PerformingProviderDecisionCriterias = NonNullable<HmoExceptionReview>["performingProviderDecisionCriterias"];
type FacilityReviewOutcome = NonNullable<HmoExceptionReview>["facilityReviewOutcome"];
type FacilityDecisionCriterias = NonNullable<HmoExceptionReview>["facilityDecisionCriterias"];

interface Props {
  loading: boolean;
  isOpen: boolean;
  setIsOpen(arg0: boolean): void;
  reviewOutcome: ReviewOutcome;
  setReviewOutcome(arg0: ReviewOutcome): void;
  performingProviderReviewOutcome?: PerformingProviderReviewOutcome;
  setPerformingProviderReviewOutcome(arg0?: PerformingProviderReviewOutcome): void;
  facilityReviewOutcome?: PerformingProviderReviewOutcome;
  setFacilityReviewOutcome(arg0?: FacilityReviewOutcome): void;
  performingProviderDecisionCriterias?: PerformingProviderDecisionCriterias;
  setPerformingProviderDecisionCriterias(arg0?: PerformingProviderDecisionCriterias): void;
  facilityDecisionCriterias?: FacilityDecisionCriterias;
  setFacilityDecisionCriterias(arg0?: FacilityDecisionCriterias): void;
  onSubmit(event: React.FormEvent<HTMLFormElement>): void;
}

export default function HmoExceptionReviewSubmissionModal({
  loading,
  isOpen,
  setIsOpen,
  reviewOutcome,
  setReviewOutcome,
  performingProviderReviewOutcome,
  setPerformingProviderReviewOutcome,
  facilityReviewOutcome,
  setFacilityReviewOutcome,
  performingProviderDecisionCriterias,
  setPerformingProviderDecisionCriterias,
  facilityDecisionCriterias,
  setFacilityDecisionCriterias,
  onSubmit,
}: Props) {
  const handleSubmit: HTMLAttributes<HTMLFormElement>["onSubmit"] = (e) => {
    e.preventDefault();
    onSubmit(e);
  };
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <Grid container spacing={4} component="form" onSubmit={handleSubmit}>
        <Row>
          <H2>Select review outcome</H2>
        </Row>
        <Row>
          <SingleSelect<ReviewOutcomeOption>
            label="Outcome"
            helperText={helperText(reviewOutcome)}
            useOptions={() => ({
              optionsLoading: false,
              options: ReviewOutcomeOptions,
            })}
            selectedValue={ReviewOutcomeOptions.find(({ id }) => reviewOutcome === id) || null}
            getOptionLabel={(opt) => opt.label}
            setSelectedValue={(opt) => {
              setReviewOutcome(opt?.id);
            }}
          />
        </Row>
        <Row>
          <PrimaryButton loading={loading} type="submit">
            Finish review
          </PrimaryButton>
        </Row>
      </Grid>
    </Modal>
  );
}

const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={12} {...props} />;

interface ReviewOutcomeOption {
  id: ReviewOutcome;
  label: string;
}
const ReviewOutcomeOptions: ReviewOutcomeOption[] = [
  { id: "APPROVED", label: "Approved" },
  { id: "RECOMMEND_DENIAL", label: "Recommend Denial" },
];

const helperText: (reviewOutcome: ReviewOutcome | undefined) => string | undefined = (reviewOutcome) =>
  reviewOutcome
    ? {
        APPROVED: "This approval will be submitted to the payor.",
        RECOMMEND_DENIAL: "Recommend Denial.",
      }[reviewOutcome]
    : undefined;
