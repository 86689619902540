import React, { useEffect, useMemo, useState } from "react";
import {
  ServiceRequestResponse,
  usePayerProcedureCodesFromCPTCodes,
  PayerProcedureCode,
  RuleActions,
  AuthorizationResponse,
  ClinicalService,
} from "@coherehealth/core-platform-api";
import { Caption, Chip, H6, useGeneralAuthSubmissionWorflowOn, withId } from "@coherehealth/common";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from "notistack";

import ProcedureCodeList from "./ProcedureCodeList";
import RequestDetails from "components/ServiceRequest/ReadonlyDetail/RequestDetails";
import RequestDetailsContinuation from "components/ServiceRequest/ReadonlyDetail/RequestDetailsContinuation";
import ExpandedServiceRequestCard from "components/ServiceRequest/ExpandedServiceRequestCard/ExpandedServiceRequestCard";
import useDraftContinue from "components/ServiceRequest/DraftContinue";
import { getLatestServiceRequest } from "util/authorization";
import { useAuthorized } from "authorization";
import GeneralAuthProcedureCodeList from "./GeneralAuthProcedureCodeList";
import { procedureBucketDataModel } from "components/AuthBuilder/common";
import { DetailsField } from "components/ClinicalReview/ClinicalReviewInfoPanel/CaseOverview";
import { isTerminalStatus } from "util/serviceRequest";

interface Props {
  authorization?: AuthorizationResponse;
  refreshAuthorization?: () => Promise<void>;
  serviceRequest: ServiceRequestResponse;
  serviceRequests?: ServiceRequestResponse[];
  hideFacilityState?: boolean;
  ruleActions?: RuleActions;
  isContinuationSR?: boolean;
  clinicalReviewView?: boolean;
  useNewPxTable?: boolean;
  isFacilityBasedAuth?: boolean;
  onReviewPage?: boolean;
  isFaxEdit?: boolean;
  showServiceChangeBanner?: boolean;
}

const useStyles = makeStyles((theme) => ({
  pxCodeSection: {
    width: "100%",
  },
}));

export default function ServiceRequestFormReadOnly({
  authorization,
  refreshAuthorization,
  serviceRequest,
  serviceRequests,
  hideFacilityState,
  ruleActions,
  isContinuationSR,
  clinicalReviewView,
  useNewPxTable,
  isFacilityBasedAuth,
  onReviewPage,
  isFaxEdit = false,
  showServiceChangeBanner,
}: Props) {
  const generalAuthSubmissionEnabled = useGeneralAuthSubmissionWorflowOn(serviceRequest.healthPlanName || "");
  const {
    mutate: getPayerProcedureCodes,
    loading: procedureCodesLoading,
    error: procedureCodesError,
  } = usePayerProcedureCodesFromCPTCodes({});
  const [payerProcedureCodes, setPayerProcedureCodes] = useState<PayerProcedureCode[]>([]);
  const classes = useStyles();
  // Might be any authStatus
  const latestSr = getLatestServiceRequest(authorization)?.[1];

  // Continue Draft logic
  const draftContinue = useDraftContinue(latestSr);

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (procedureCodesError) {
      enqueueSnackbar(procedureCodesError.message, { variant: "error" });
    }
  }, [procedureCodesError, enqueueSnackbar]);

  useEffect(() => {
    const serviceRequestCardId = `ExpandedServiceRequestCard-` + serviceRequest?.id;
    const element = document.getElementById(serviceRequestCardId);
    element?.scrollIntoView();
  }, [serviceRequest?.id, authorization]);
  const isAuthViewOnlyUser = useAuthorized("AUTH_VIEW_ONLY");

  const procedureCodes =
    !onReviewPage &&
    authorization &&
    authorization.semanticProcedureCodes &&
    authorization.semanticProcedureCodes.length > 0
      ? authorization.semanticProcedureCodes
      : serviceRequest.semanticProcedureCodes;

  const clinicalServices =
    serviceRequest.requestType === "CONTINUATION" && authorization
      ? authorization.clinicalServices
      : serviceRequest.clinicalServices;

  // If all PXs have an originalGroupId, it's a better bet to use the originalGroupIds instead of just id
  // This is because if a clinical service was updated, the PXs and SR may reference different versions of the same clinical service
  const useOriginalVersionIds = procedureCodes?.every((px) => px.groupOriginalVersionId);
  let pxsWithOriginalGroupIdInsteadOfId = useOriginalVersionIds
    ? procedureCodes?.map((px) => {
        let result = { ...px };
        if (result.groupOriginalVersionId) {
          result.groupId = result.groupOriginalVersionId;
        }
        return result;
      })
    : procedureCodes;

  const clinicalServiceWithPxs = useMemo(() => {
    return procedureBucketDataModel(pxsWithOriginalGroupIdInsteadOfId?.map(withId) || []);
  }, [pxsWithOriginalGroupIdInsteadOfId]);
  const clinicalServiceIds = Array.from(clinicalServiceWithPxs.keys());
  return (
    <Grid container item xs={12} spacing={3} style={{ margin: 0 }}>
      {isContinuationSR ? (
        //below is the pre-submission review page for continuations
        <RequestDetailsContinuation
          authorization={authorization}
          serviceRequest={serviceRequest}
          hideFacilityState={hideFacilityState}
          ruleActions={ruleActions || []}
          isFacilityBasedAuth={isFacilityBasedAuth}
        />
      ) : (
        <RequestDetails
          authorization={authorization}
          serviceRequest={serviceRequest}
          hideFacilityState={hideFacilityState}
          ruleActions={ruleActions || []}
          isFacilityBasedAuth={isFacilityBasedAuth}
          clinicalReviewView={clinicalReviewView}
          isFaxEdit={isFaxEdit}
        />
      )}
      {procedureCodesLoading && !payerProcedureCodes && <CircularProgress />}
      <div className={classes.pxCodeSection}>
        {generalAuthSubmissionEnabled ? (
          clinicalServiceIds.sort().map((csId) => {
            const clinicalService = clinicalServices?.find((cs): cs is ClinicalService => {
              return cs?.id === csId || cs?.originalVersionId === csId;
            });
            const units =
              clinicalService && !clinicalService.isUnitsOnPx && clinicalServiceWithPxs
                ? clinicalServiceWithPxs
                    ?.get(csId)
                    ?.reduce((a, b) => (a > (b.units ? b.units : 0) ? a : b?.units || 0), 0)
                    .toString()
                : "";
            const approvedUnits =
              clinicalService && !clinicalService.isUnitsOnPx && clinicalServiceWithPxs
                ? clinicalServiceWithPxs
                    ?.get(csId)
                    ?.reduce((a, b) => (a > (b.approvedUnits ? b.approvedUnits : 0) ? a : b?.approvedUnits || 0), 0)
                    .toString()
                : "";
            const showNumberOfVisits = clinicalService && !clinicalService.isUnitsOnPx;
            return (
              <Grid container spacing={2} key={csId}>
                <Grid item xs={12}>
                  <ClinicalServiceTitle data-public>
                    {clinicalService?.name || "Uncategorized Service"}
                    {showServiceChangeBanner && <UpdatedTitleBadge type="success" label="Updated" />}
                  </ClinicalServiceTitle>
                </Grid>
                {showNumberOfVisits && (
                  <Grid item xs={12} style={{ paddingBottom: 0 }}>
                    <DetailsField
                      label="Number of visits"
                      value={isTerminalStatus(serviceRequest) ? approvedUnits : units}
                    />
                  </Grid>
                )}
                <GeneralAuthProcedureCodeList
                  authorization={authorization}
                  serviceRequest={serviceRequest}
                  procedureCodes={clinicalServiceWithPxs.get(csId) || []}
                  clinicalService={clinicalService}
                />
              </Grid>
            );
          })
        ) : (
          <ProcedureCodeList
            authorization={authorization}
            serviceRequest={serviceRequest}
            getPayerProcedureCodes={getPayerProcedureCodes}
            setPayerProcedureCodes={setPayerProcedureCodes}
            payerProcedureCodes={payerProcedureCodes}
          />
        )}
      </div>
      {clinicalReviewView &&
        serviceRequests &&
        serviceRequests?.length > 1 &&
        serviceRequests
          .reverse()
          .filter((sr) => (isAuthViewOnlyUser ? sr.authStatus !== "DRAFT" : true)) //if authViewOnly then filter Draft AuthStatus SR's
          .map((sr, idx) => {
            return (
              <ExpandedServiceRequestCard
                positionInSrList={idx}
                id={`ExpandedServiceRequestCard-` + sr.id}
                key={sr.id}
                sr={sr}
                priorSr={idx < serviceRequests.length ? serviceRequests[idx + 1] : undefined}
                clinicalReviewView={clinicalReviewView}
                continueDraft={() => {
                  sr.workflowStep && draftContinue(sr.workflowStep);
                }}
                onEdit={() => {
                  refreshAuthorization?.();
                }}
              />
            );
          })}
    </Grid>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const Label = styled(Caption)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "inline-block",
  paddingBottom: theme.spacing(0.5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ClinicalServiceTitle = styled(H6)(({ theme }) => ({
  padding: theme.spacing(2, 0, 0, 0),
  display: "flex",
  alignItems: "center",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const UpdatedTitleBadge = styled(Chip)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  fontSize: "13px",
  padding: theme.spacing(0.5, 1),
  paddingTop: "5px",
  marginLeft: theme.spacing(2),
}));
