import React, { FunctionComponent, useMemo } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { unitTypeLabel, assertIsUnitType } from "util/serviceRequest";
import { UnitType } from "@coherehealth/core-platform-api";
import { colorsLight, SingleSelectDropdown, TextField } from "@coherehealth/common";
import { FlexGridItem } from "common/SharedServiceRequestFormComponents";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-root": {
      color: ({
        recommendedSelected,
        showApprovedSrEditWarning,
        hasValidUnits,
      }: {
        recommendedSelected?: boolean;
        showApprovedSrEditWarning?: boolean;
        hasValidUnits?: boolean;
      }) => {
        // Yes, the error and default colors should be controlled by the error prop/styles in the TextField component
        // But if we set those values as undefined here, JSS doesn't seem to clear the previously applied color :(
        if (!hasValidUnits) {
          return theme.palette.error.dark;
        } else if (showApprovedSrEditWarning) {
          return theme.palette.warning.dark;
        } else if (recommendedSelected) {
          return theme.palette.success.dark;
        } else {
          return colorsLight.black.mediumEmphasis;
        }
      },
      // width is 100% so that the helper text will not extend past the text field container
      width: "100%",
    },
  },
}));

/*
    THIS IS A DEPRECATED COMPONENT. It should be removed with the removal 
    of the simplifiedServiceFrequency flag.
 */

const UnitSelect: FunctionComponent<{
  hasValidUnits: boolean;
  hasValidUnitType: boolean;
  attemptedSubmit: boolean;
  units: string;
  setUnits: (units: string) => void;
  unitType: UnitType | undefined;
  setUnitType: (type: UnitType) => void;
  defaultUnits: number | undefined;
  defaultUnitType: UnitType | undefined;
  showApprovedSrEditWarning?: boolean;
  minNumUnits: number;
  initialUnits: string;
}> = ({
  hasValidUnits,
  hasValidUnitType,
  attemptedSubmit,
  units,
  setUnits,
  unitType,
  setUnitType,
  defaultUnitType,
  defaultUnits,
  showApprovedSrEditWarning,
  minNumUnits,
  initialUnits,
}) => {
  const unitTypeOptions = useMemo(() => currentUnitTypeOptions(unitType, defaultUnitType), [unitType, defaultUnitType]);
  const recommendedSelected =
    units !== "0" && !!unitType && Math.trunc(Number(units)) === defaultUnits && unitType === defaultUnitType;
  const classes = useStyles({ recommendedSelected, showApprovedSrEditWarning, hasValidUnits });
  const noUnitType = attemptedSubmit && !hasValidUnitType;

  const invalidUnitsHelperText = useMemo(() => {
    if (!hasValidUnits) {
      if (units === "") {
        return "Required";
      }
      return `Must be greater than ${minNumUnits}`;
    }
  }, [hasValidUnits, units, minNumUnits]);

  const helperTextUnits = useMemo(() => {
    if (!hasValidUnits && units !== "" && (units !== "0" || minNumUnits !== 0)) {
      return `Must be greater than ${minNumUnits}`;
    } else if (recommendedSelected) {
      return "Recommended for auto approval";
    }
  }, [hasValidUnits, units, minNumUnits, recommendedSelected]);

  return (
    <FlexGridItem container spacing={2}>
      <Grid item>
        <TextField
          style={{ width: 120 }}
          error={!hasValidUnits && attemptedSubmit}
          helperText={attemptedSubmit && !hasValidUnits ? invalidUnitsHelperText : helperTextUnits}
          type="text"
          fullWidth
          label="Quantity"
          value={units === "undefined" ? "" : units}
          onChange={(e) => {
            let strVal = e.currentTarget.value;
            if (!strVal || strVal === "") {
              setUnits("");
            } else {
              const numVal = Math.trunc(Number(strVal));
              if (!isNaN(numVal)) {
                strVal = Math.max(0, numVal).toString();
                setUnits(strVal);
              }
            }
          }}
          warningHelperText={showApprovedSrEditWarning}
          className={classes.root}
        />
      </Grid>
      <Grid item>
        <SingleSelectDropdown
          style={{ width: 441 }}
          error={noUnitType && attemptedSubmit}
          helperText={noUnitType && attemptedSubmit && "Required"}
          label="Unit type"
          value={unitType}
          onChange={(ut) => {
            assertIsUnitType(ut);
            setUnitType(ut);
          }}
          options={unitTypeOptions}
        />
      </Grid>
    </FlexGridItem>
  );
};

export default UnitSelect;

const STANDARD_UNIT_TYPES: Array<UnitType> = ["PROCEDURE", "NUMBER_OF_VISITS", "DAY"];

function currentUnitTypeOptions(currentValue: UnitType | undefined, defaultValue: UnitType | undefined) {
  const allowedValues =
    !currentValue || STANDARD_UNIT_TYPES.includes(currentValue || "PROCEDURE")
      ? STANDARD_UNIT_TYPES
      : [...STANDARD_UNIT_TYPES, currentValue];
  if (defaultValue && !allowedValues.includes(defaultValue)) {
    allowedValues.push(defaultValue);
  }
  return allowedValues.map((val) => ({ id: val, label: unitTypeLabel(val) }));
}
