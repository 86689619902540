// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Card, Container, createStyles, Divider, Grid, makeStyles, styled } from "@material-ui/core";
import RequestorCard from "components/Requestor/RequestorCard";
import { Caption, colorsLight } from "@coherehealth/common";
import { useTheme } from "@material-ui/core";
import { AuthBuilderRequestorProps } from "components/AuthBuilder/index";
import {
  RecommendChangeRuleAction,
  RedirectRuleAction,
  RuleActions,
  ServiceRequestResponse,
  useGetServiceRequestRuleActions,
  useUpdateServiceRequest,
  GetOutofNetworkCheckResponse,
  UserResponse,
} from "@coherehealth/core-platform-api";
import ServiceRequestReview from "./ServiceRequestReview";
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  isPendingAction,
  isRecommendChangeAction,
  isRecommendChangeActionWithAttribute,
  isRecommendChangeActionWithOutAttribute,
  isRedirectAction,
  willApprove,
  removeRulesWhenPxCodesOnDifCarepaths,
  ValidDisplayMessageAction,
} from "util/rule";
import {
  useMuiContainerStyles,
  useGeneralAuthSubmissionWorflowOn,
  HEALTH_HELP_NAME,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
import NudgeContainer from "components/Nudges/NudgeContainer";
import { useIsHealthHelpEnabled } from "util/serviceRequest";
import {
  ServiceRequestReviewFormCenterWidth,
  ServiceRequestReviewFormWidth,
  ServiceRequestFormContent,
} from "common/SharedServiceRequestFormComponents";
import InfoAlert from "../../../common/InfoAlert";
import AuthorizationDetail from "common/AuthorizationDetail";
import { HEALTH_HELP_V2_NAME } from "@coherehealth/common";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import { convertStringToAuthBuilderWorkflowStep, PriorAuthRequirements } from "../common";
import { FiredNudgesContext } from "../FiredNudgesContext";
import isEqual from "lodash/isEqual";
import { deduplicatedRuleActions } from "util/rule";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";

interface Props extends AuthBuilderRequestorProps {
  serviceRequests: ServiceRequestResponse[];
  editServiceRequest: (sr: ServiceRequestResponse) => void;
  deleteServiceRequest: (sr: ServiceRequestResponse) => void;
  setPreSubmissionRedirectActions: (actions: RuleActions | undefined) => void;
  workflowId?: string;
  setReviewEdit: Dispatch<SetStateAction<boolean>>;
  dedicatedNudgeIds: string[];
  hasRuleRuns: boolean;
  ruleActionsData: RuleActions | null;
  loadingAction: boolean;
  getServiceRequestRuleActionsResponse: ReturnType<typeof useGetServiceRequestRuleActions>["refetch"];
  caqSkipped: boolean;
  isContinuationWorkflow?: boolean;
  user?: UserResponse | null;
  serviceRequestSubmitted: boolean;
  setperformingProviderExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  performingProviderExceptionRequest?: boolean;
  setFacilityExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  facilityExceptionRequest?: boolean;
  setOrderingProviderExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  orderingProviderExceptionRequest?: boolean;
  setCareParticipantExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  careParticipantExceptionRequest?: boolean;
  ruleActionError?: GetDataError<unknown> | null;
  formContent: ServiceRequestFormContent[];
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  declinedNudges?: string[] | undefined;
  setDisableContinueButtonOnContinuation?: Dispatch<SetStateAction<boolean>>;
  showServiceChangeBanner?: boolean;
  setShowServiceChangeBanner: Dispatch<SetStateAction<boolean>>;
  visibilityToggleStatus: boolean;
  setVisibilityToggleStatus: Dispatch<SetStateAction<boolean>>;
}

export default function ReviewContainer({
  serviceRequests,
  editServiceRequest,
  deleteServiceRequest,
  requestorFormAuthorized,
  setPreSubmissionRedirectActions,
  workflowId,
  setReviewEdit,
  dedicatedNudgeIds,
  hasRuleRuns,
  ruleActionsData,
  loadingAction,
  getServiceRequestRuleActionsResponse,
  caqSkipped,
  isContinuationWorkflow,
  user,
  serviceRequestSubmitted,
  setperformingProviderExceptionRequest,
  performingProviderExceptionRequest,
  setFacilityExceptionRequest,
  facilityExceptionRequest,
  setOrderingProviderExceptionRequest,
  orderingProviderExceptionRequest,
  setCareParticipantExceptionRequest,
  careParticipantExceptionRequest,
  ruleActionError,
  formContent,
  setFormContent,
  declinedNudges,
  setDisableContinueButtonOnContinuation,
  showServiceChangeBanner,
  setShowServiceChangeBanner,
  visibilityToggleStatus,
  setVisibilityToggleStatus,
  ...requestorProps
}: Props) {
  const containerClasses = useMuiContainerStyles();
  const useHealthHelpIntegration = useIsHealthHelpEnabled();

  return (
    <Container classes={containerClasses} maxWidth="lg">
      <InnerContainer>
        {requestorFormAuthorized && (
          <RequestorCard {...requestorProps} isContinuationWorkflow={isContinuationWorkflow} />
        )}
        <Grid container spacing={4}>
          {serviceRequests.map((sr) => {
            // Disable edit buttons if HealthHelp or HealthHelpV2 delegated vendor
            const disableEdits =
              useHealthHelpIntegration &&
              (sr.delegatedVendor === HEALTH_HELP_NAME || sr.delegatedVendor === HEALTH_HELP_V2_NAME);
            return (
              <Grid key={sr.id} item xs={12}>
                <SummaryWrapper
                  ruleActionError={ruleActionError}
                  serviceRequest={sr}
                  editServiceRequest={editServiceRequest}
                  deleteServiceRequest={deleteServiceRequest}
                  setPreSubmissionRedirectActions={setPreSubmissionRedirectActions}
                  disableEdits={disableEdits}
                  workflowId={workflowId}
                  setReviewEdit={setReviewEdit}
                  dedicatedNudgeIds={dedicatedNudgeIds}
                  hasRuleRuns={hasRuleRuns}
                  ruleActionsData={ruleActionsData}
                  loadingAction={loadingAction}
                  getServiceRequestRuleActionsResponse={getServiceRequestRuleActionsResponse}
                  caqSkipped={caqSkipped}
                  isContinuationWorkflow={isContinuationWorkflow}
                  serviceRequestSubmitted={serviceRequestSubmitted}
                  performingProviderExceptionRequest={performingProviderExceptionRequest}
                  setperformingProviderExceptionRequest={setperformingProviderExceptionRequest}
                  facilityExceptionRequest={facilityExceptionRequest}
                  setFacilityExceptionRequest={setFacilityExceptionRequest}
                  careParticipantExceptionRequest={careParticipantExceptionRequest}
                  setCareParticipantExceptionRequest={setCareParticipantExceptionRequest}
                  formContent={formContent}
                  setFormContent={setFormContent}
                  setDisableContinueButtonOnContinuation={setDisableContinueButtonOnContinuation}
                  orderingProviderExceptionRequest={orderingProviderExceptionRequest}
                  setOrderingProviderExceptionRequest={setOrderingProviderExceptionRequest}
                  declinedNudges={declinedNudges}
                  showServiceChangeBanner={showServiceChangeBanner}
                  setShowServiceChangeBanner={setShowServiceChangeBanner}
                  user={user}
                  visibilityToggleStatus={visibilityToggleStatus}
                  setVisibilityToggleStatus={setVisibilityToggleStatus}
                />
              </Grid>
            );
          })}
        </Grid>
      </InnerContainer>
    </Container>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const InnerContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  paddingTop: "40px",
}));

interface SummaryProps {
  serviceRequest: ServiceRequestResponse;
  editServiceRequest: (sr: ServiceRequestResponse) => void;
  deleteServiceRequest: (sr: ServiceRequestResponse) => void;
  setPreSubmissionRedirectActions: (actions: RuleActions | undefined) => void;
  disableEdits?: boolean;
  workflowId?: string;
  setReviewEdit: Dispatch<SetStateAction<boolean>>;
  dedicatedNudgeIds: string[];
  hasRuleRuns: boolean;
  ruleActionsData: RuleActions | null;
  loadingAction: boolean;
  getServiceRequestRuleActionsResponse: ReturnType<typeof useGetServiceRequestRuleActions>["refetch"];
  caqSkipped: boolean;
  isContinuationWorkflow?: boolean;
  serviceRequestSubmitted: boolean;
  isOonExceptionRequired?: boolean;
  setperformingProviderExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  performingProviderExceptionRequest?: boolean;
  setFacilityExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  facilityExceptionRequest?: boolean;
  setOrderingProviderExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  orderingProviderExceptionRequest?: boolean;
  setCareParticipantExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  careParticipantExceptionRequest?: boolean;
  providerOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  providerOonCheckLoading?: boolean;
  performedProviderOONCheck?: boolean;
  facilityOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  facilityOonCheckLoading?: boolean;
  performedFacilityOONCheck?: boolean;
  orderingProviderOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  orderingProviderOonCheckLoading?: boolean;
  orderingProviderOONCheck?: boolean;
  ruleActionError?: GetDataError<unknown> | null;
  formContent: ServiceRequestFormContent[];
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  setDisableContinueButtonOnContinuation?: Dispatch<SetStateAction<boolean>>;
  declinedNudges?: string[] | undefined;
  showServiceChangeBanner?: boolean;
  setShowServiceChangeBanner: Dispatch<SetStateAction<boolean>>;
  user?: UserResponse | null;
  visibilityToggleStatus: boolean;
  setVisibilityToggleStatus: Dispatch<SetStateAction<boolean>>;
}

const SHOW_ONCE_NUDGE_TYPES = ["units"];
function SummaryWrapper({
  serviceRequest,
  editServiceRequest,
  deleteServiceRequest,
  setPreSubmissionRedirectActions,
  disableEdits,
  workflowId,
  setReviewEdit,
  dedicatedNudgeIds,
  hasRuleRuns,
  ruleActionsData,
  loadingAction,
  getServiceRequestRuleActionsResponse,
  caqSkipped,
  isContinuationWorkflow,
  serviceRequestSubmitted,
  setperformingProviderExceptionRequest,
  performingProviderExceptionRequest,
  setFacilityExceptionRequest,
  facilityExceptionRequest,
  setCareParticipantExceptionRequest,
  careParticipantExceptionRequest,
  formContent,
  setFormContent,
  setOrderingProviderExceptionRequest,
  orderingProviderExceptionRequest,
  providerOonCheckData,
  providerOonCheckLoading,
  performedProviderOONCheck,
  facilityOonCheckData,
  facilityOonCheckLoading,
  performedFacilityOONCheck,
  ruleActionError,
  setDisableContinueButtonOnContinuation,
  declinedNudges,
  showServiceChangeBanner,
  setShowServiceChangeBanner,
  user,
  visibilityToggleStatus,
  setVisibilityToggleStatus,
}: SummaryProps) {
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  const { firedRecommendedNudges } = useContext(FiredNudgesContext);
  const [editableServiceRequest, setEditableServiceRequest] = useState<ServiceRequestResponse>(serviceRequest);
  //^this name is confusing
  const [acceptedNudge, setAcceptedNudge] = useState<ValidDisplayMessageAction[]>([]);
  let newRuleActions: RuleActions = [];
  const [priorAuthRequirements, setPriorAuthRequirements] = useState<PriorAuthRequirements>({
    primaryDiagnosis: serviceRequest?.primarySemanticDiagnosisCode,
    secondaryDiagnoses: serviceRequest?.secondarySemanticDiagnosisCodes,
    showPxCheckbox: false,
    noPxServiceRequired: false,
  });

  const generalAuthSubmissionWorkflowEnabled = useGeneralAuthSubmissionWorflowOn(serviceRequest.healthPlanName || "");
  const classes = useStyles({ isFaxAuthBuilderFlow });
  const { healthPlanName, encounterType } = serviceRequest || {};
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName,
    encounterType,
    skipRequestTimingCheck: true,
  });

  const {
    mutate: updateServiceRequest,
    loading: updateServiceRequestLoading,
    error: updateServiceRequestError,
  } = useUpdateServiceRequest({ id: serviceRequest.id });

  useEffect(() => {
    if (!hasRuleRuns) {
      getServiceRequestRuleActionsResponse({
        pathParams: {
          id: serviceRequest.id,
        },
        queryParams: {
          displayTarget: "PRE_SUBMISSION_REVIEW",
          authStage: convertStringToAuthBuilderWorkflowStep("REVIEW"),
        },
      });
    }
  }, [hasRuleRuns, getServiceRequestRuleActionsResponse, serviceRequest.id]);

  // priorAuthRequirements gets initialized with the current values of the service request
  // Set the primary & secondary DXs so that the DX Select has values populated at init.
  useEffect(() => {
    if (!priorAuthRequirements.primaryDiagnosis && serviceRequest) {
      setPriorAuthRequirements({
        primaryDiagnosis: serviceRequest.primarySemanticDiagnosisCode,
        secondaryDiagnoses: serviceRequest.secondarySemanticDiagnosisCodes,
        showPxCheckbox: false,
        noPxServiceRequired: false,
      });
    }
  }, [
    priorAuthRequirements,
    serviceRequest?.primarySemanticDiagnosisCode,
    serviceRequest?.secondarySemanticDiagnosisCodes,
    setPriorAuthRequirements,
    serviceRequest,
  ]);

  const { refetch: refetchServiceRequest } = useGetServiceRequestByIdWithFallback({
    id: serviceRequest.id,
  });

  const refetchEditableServiceRequest = useCallback(async () => {
    const updatedServiceRequest = await refetchServiceRequest();
    if (updatedServiceRequest) {
      setEditableServiceRequest(updatedServiceRequest);
    }
  }, [refetchServiceRequest]);

  const onUpdateDiagnosisCodes = useCallback(async () => {
    const updatedServiceRequest = await refetchServiceRequest();
    if (updatedServiceRequest) {
      editServiceRequest(updatedServiceRequest);
    }
  }, [refetchServiceRequest, editServiceRequest]);

  /**
   * mapRecommendActionGroups: groups recommendedActions based on groupId identifier to be used later in the flow to identify unique nudges
   *
   * @params actions: List of RecommendChangeRuleAction to be applied
   *
   * @returns Map<string, RecommendChangeRuleAction[]>: key is group identifier, values are grouped nudges
   **/
  const mapRecommendActionGroups = useCallback(
    (actions: RecommendChangeRuleAction[]): Map<string, RecommendChangeRuleAction[]> => {
      const servicesWithAssociatedActions = new Map<string, RecommendChangeRuleAction[]>();

      actions.forEach((action) => {
        const groupId = action?.groupId;

        if (groupId && serviceRequest.clinicalServices?.find((cs) => cs?.id === groupId)) {
          if (servicesWithAssociatedActions.get(groupId)) {
            servicesWithAssociatedActions.get(groupId)?.push(action);
          } else {
            servicesWithAssociatedActions.set(groupId, [action]);
          }
        } else {
          const groupName = groupId && groupId === "parent" ? groupId : "Uncategorized Service";
          if (servicesWithAssociatedActions.get(groupName)) {
            servicesWithAssociatedActions.get(groupName)?.push(action);
          } else {
            servicesWithAssociatedActions.set(groupName, [action]);
          }
        }
      });

      return servicesWithAssociatedActions;
    },
    [serviceRequest.clinicalServices]
  );

  /**
   * getUnits: fetch units based on type of value and parses accordingly to return a appropriately number
   *
   * @params action: RecommendChangeRuleAction with onAcceptValue
   *
   * @returns number: value of units
   **/
  const getUnits = (action: RecommendChangeRuleAction | RedirectRuleAction): number => {
    if (action?.onAcceptValue?.value) {
      return typeof action.onAcceptValue.value === "string"
        ? parseInt(action.onAcceptValue.value)
        : typeof action.onAcceptValue.value === "number"
        ? action?.onAcceptValue?.value
        : 0;
    }
    return 0; //there should not be any onAcceptValues that are not strings or numbers in the case of units nudges
  };

  /**
   * getLargestUnitNudge: returns the largest unit action in a given action list
   *
   * @params actions: List of RecommendChangeRuleAction with onAcceptValue
   *
   * @returns RecommendChangeRuleAction: action with the largest unit nudge
   **/
  const getLargestUnitAction = useCallback((actions: (RecommendChangeRuleAction | RedirectRuleAction)[]) => {
    let largestUnitsAction = actions?.[0];
    actions.forEach((action) => {
      if (action?.onAcceptValue?.value && largestUnitsAction?.onAcceptValue?.value) {
        const largestUnitValue: number = getUnits(largestUnitsAction);

        const curActionUnitValue: number = getUnits(action);

        largestUnitsAction = curActionUnitValue > largestUnitValue ? action : largestUnitsAction;
      }
    });
    return largestUnitsAction;
  }, []);

  const ruleActions: RuleActions = useMemo(() => {
    if (!ruleActionsData) {
      return [];
    }
    //take the ruleaction with the highest number of service dates if it exists and remove the rest of the service dates nudges

    //search for all of the ruleactions with units - service dates
    //take the one with the highest number of service dates
    //disregard the others
    let unitsNudges: (RecommendChangeRuleAction | RedirectRuleAction)[] = [];
    const ruleActionsWithoutUnitsNudges = ruleActionsData.filter((action) => {
      if ("onAcceptAction" in action) {
        //check that this is a RecommendChangeRuleAction
        const foundAcceptedAction = acceptedNudge.find(
          (acceptedNudge) =>
            acceptedNudge.onAcceptAttribute === action.onAcceptAttribute &&
            (!generalAuthSubmissionWorkflowEnabled || acceptedNudge?.groupId === action?.groupId)
        );
        if (
          action?.onAcceptAttribute &&
          SHOW_ONCE_NUDGE_TYPES.includes(action.onAcceptAttribute) &&
          foundAcceptedAction
        ) {
          // filters out nudges of the same type of nudges that were already accepted
          // See ticket: https://coherehealth.atlassian.net/browse/COH-2336 for more details
          //     example: user accepts a nudge for 15 less units so we filter out all units nudges so
          //       that the user is not nudged again
          return false;
        }
        if (action.onAcceptAttribute === "units") {
          //Selects the largest units nudge and suppressed the remaining units nudges.
          // See ticket: https://coherehealth.atlassian.net/browse/COH-2322 for more details
          unitsNudges.push(action);
          return false;
        }
      }
      return true;
    });

    if (unitsNudges.length > 0) {
      if (generalAuthSubmissionWorkflowEnabled) {
        let groupedUnitNudges: (RecommendChangeRuleAction | RedirectRuleAction)[] = [];

        const recommendActionsNudges = unitsNudges.filter(isRecommendChangeActionWithAttribute);
        const mappedGroups = mapRecommendActionGroups(recommendActionsNudges);
        const groupedActionsFromMap = Array.from(mappedGroups.values());

        groupedActionsFromMap.forEach((actionGroup) => {
          const largestUnitsNudge = getLargestUnitAction(actionGroup);
          groupedUnitNudges.push(largestUnitsNudge);
        });

        return [...ruleActionsWithoutUnitsNudges, ...groupedUnitNudges];
      } else {
        const largestUnitsNudge = getLargestUnitAction(unitsNudges);
        return [...ruleActionsWithoutUnitsNudges, largestUnitsNudge];
      }
    } else {
      return ruleActionsWithoutUnitsNudges;
    }
  }, [
    ruleActionsData,
    acceptedNudge,
    generalAuthSubmissionWorkflowEnabled,
    mapRecommendActionGroups,
    getLargestUnitAction,
  ]);

  useEffect(() => {
    setPreSubmissionRedirectActions(
      loadingAction || Boolean(ruleActionError)
        ? undefined
        : generalAuthSubmissionWorkflowEnabled
        ? removeRulesWhenPxCodesOnDifCarepaths(ruleActions, true)
        : ruleActions?.filter(isRedirectAction) || []
    );

    /* When fetching the rules, scroll to top after 1 second to see the loading indicator and let the user acknowledge any new potential banners */
    if (loadingAction) {
      const timeout = setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [
    generalAuthSubmissionWorkflowEnabled,
    loadingAction,
    ruleActions,
    setPreSubmissionRedirectActions,
    ruleActionError,
  ]);

  let hasMessageBanners = false;
  let hasPendingAction = false;
  let hasRecommendedAction = false;
  if (ruleActions) {
    /* Separate between recommended change actionable and non-actionable actions */
    /* Todo - align usage so we can replace all of these dns state usages w/ fired nudge context */
    newRuleActions = deduplicatedRuleActions(ruleActions, dedicatedNudgeIds);

    if (generalAuthSubmissionWorkflowEnabled) {
      newRuleActions = removeRulesWhenPxCodesOnDifCarepaths(ruleActions, false) || [];
    }

    const hasRecommendChangeActionWithAttribute =
      newRuleActions.filter(isRecommendChangeActionWithAttribute).length > 0;

    const hasRecommendChangeActionWithOutAttribute =
      newRuleActions?.filter(isRecommendChangeActionWithOutAttribute).length > 0;

    /* Check if there are any redirect actions */
    const hasRedirectAction = newRuleActions?.filter(isRedirectAction).length > 0;

    /* If there are any bannerMessages, and if there aren't any actionable rules */
    hasMessageBanners =
      (hasRecommendChangeActionWithOutAttribute || hasRedirectAction) && !hasRecommendChangeActionWithAttribute;

    hasPendingAction = newRuleActions?.filter(isPendingAction).length > 0;

    hasRecommendedAction =
      newRuleActions?.filter(
        (rule) =>
          isRecommendChangeAction(rule) &&
          !firedRecommendedNudges?.find((firedRecommendedNudge) => firedRecommendedNudge.ruleId === rule.ruleId)
      ).length > 0;
  }

  const hasNoSuggestions =
    (newRuleActions?.filter(isRecommendChangeActionWithAttribute) || []).length === 0 &&
    hasMessageBanners &&
    isEqual(serviceRequest, editableServiceRequest);

  const { faxDbId: faxId } = useContext(FaxAttachmentContext);
  const isFaxIntakeFlow = Boolean(faxId);

  const showRightRail =
    serviceRequest.type === "Pharmacy" || isFaxIntakeFlow
      ? false
      : (declinedNudges?.length === 0 && !hasMessageBanners) || hasRecommendedAction
      ? (loadingAction || !hasMessageBanners) && (hasPendingAction && !hasRecommendedAction ? false : true)
      : false;

  const noAnimationCenter = useStylesNoAnimationCenter();
  const animationSide = useStylesAnimationSide();

  const animationIn = useStylesAnimationIn();
  const animationOut = useStylesAnimationOut();
  const noAnimationOut = useStylesNoAnimationOut();
  const { spacing } = useTheme();

  return (
    <div className={classes.overallContainer}>
      <div
        className={[
          classes.serviceRequestDivContainer,
          showRightRail ? animationSide.root : noAnimationCenter.root,
          isFaxAuthBuilderFlow
            ? `${noAnimationCenter.root} ${classes.serviceRequestDivNoMargin}`
            : showRightRail
            ? classes.serviceRequestDivMargin
            : classes.serviceRequestDivNoMargin,
        ].join(" ")}
      >
        {serviceRequest.type === "Pharmacy" && (
          <InfoAlert
            style={{ marginBottom: spacing(2) }}
            message="To edit this request after it has been submitted, you will need to contact Humana."
          />
        )}
        {isContinuationWorkflow && (
          <div className={classes.authorizationDetail}>
            <AuthorizationDetail
              facilityBasedWorkflow={facilityBasedFeatureEnabled}
              serviceRequest={serviceRequest}
              priorAuthRequirements={priorAuthRequirements}
              setPriorAuthRequirements={setPriorAuthRequirements}
              onUpdateDiagnosisCodes={onUpdateDiagnosisCodes}
              formContent={formContent[0]}
              setFormContent={setFormContent}
              performingProviderExceptionRequest={performingProviderExceptionRequest}
              facilityExceptionRequest={facilityExceptionRequest}
              setFacilityExceptionRequest={setFacilityExceptionRequest}
              careParticipantExceptionRequest={careParticipantExceptionRequest}
              setCareParticipantExceptionRequest={setCareParticipantExceptionRequest}
              setPerformingProviderExceptionRequest={setperformingProviderExceptionRequest}
              setDisableContinueButtonOnContinuation={setDisableContinueButtonOnContinuation}
            />
          </div>
        )}
        {isFaxAuthBuilderFlow && (
          <Grid item xs={12}>
            <Divider className={classes.dividerStyle} />
          </Grid>
        )}
        <Card
          className={
            isFaxAuthBuilderFlow ? classes.serviceRequestCardContainerNoBorder : classes.serviceRequestCardContainer
          }
        >
          <ServiceRequestReview
            serviceRequest={editableServiceRequest}
            isInAuthBuilder
            setServiceRequest={setEditableServiceRequest}
            updateServiceRequest={updateServiceRequest}
            submitting={updateServiceRequestLoading}
            ruleActions={newRuleActions || []}
            refetchActions={getServiceRequestRuleActionsResponse}
            submitError={updateServiceRequestError}
            onEdit={editServiceRequest}
            onDelete={deleteServiceRequest}
            disableEdits={disableEdits}
            workflowId={workflowId}
            setReviewEdit={setReviewEdit}
            refetchServiceRequest={refetchEditableServiceRequest}
            willAutoApprove={willApprove(ruleActions)}
            caqSkipped={caqSkipped}
            isContinuationSR={isContinuationWorkflow}
            setAcceptedNudge={setAcceptedNudge}
            performingProviderExceptionRequest={performingProviderExceptionRequest}
            setPerformingProviderExceptionRequest={setperformingProviderExceptionRequest}
            facilityExceptionRequest={facilityExceptionRequest}
            setFacilityExceptionRequest={setFacilityExceptionRequest}
            orderingProviderExceptionRequest={orderingProviderExceptionRequest}
            setOrderingProviderExceptionRequest={setOrderingProviderExceptionRequest}
            careParticipantExceptionRequest={careParticipantExceptionRequest}
            setCareParticipantExceptionRequest={setCareParticipantExceptionRequest}
            showServiceChangeBanner={showServiceChangeBanner}
            setShowServiceChangeBanner={setShowServiceChangeBanner}
            user={user}
            visibilityToggleStatus={visibilityToggleStatus}
            setVisibilityToggleStatus={setVisibilityToggleStatus}
          />
        </Card>
        {!isFaxAuthBuilderFlow && (
          <div className={classes.submissionAttestation}>
            <Caption style={{ color: colorsLight.font.secondary }} data-public>
              By submitting this request, the provider is attesting that this request is medically reasonable and is
              acknowledging that all submissions are eligible for audit after approval.
            </Caption>
          </div>
        )}
      </div>
      {showRightRail && !isFaxAuthBuilderFlow && (
        <div className={classes.rightRailDivContainer}>
          <RightRailContainer
            className={showRightRail ? animationIn.root : hasNoSuggestions ? noAnimationOut.root : animationOut.root}
          >
            <NudgeContainer
              originalRequest={serviceRequest}
              updatedRequest={editableServiceRequest}
              setServiceRequest={setEditableServiceRequest}
              onEdit={editServiceRequest}
              updateServiceRequest={updateServiceRequest}
              updateRequestError={updateServiceRequestError}
              loadingActions={loadingAction}
              ruleActions={newRuleActions?.filter(isRecommendChangeActionWithAttribute) || []}
              refetchActions={getServiceRequestRuleActionsResponse}
              workflowId={workflowId}
              hasMessageBanners={hasMessageBanners}
              addAcceptedNudge={(newAcceptedNudge) => {
                setAcceptedNudge((prev) => [...prev, newAcceptedNudge]);
              }}
              refetchServiceRequest={refetchEditableServiceRequest}
              serviceRequestSubmitted={serviceRequestSubmitted}
            />
          </RightRailContainer>
        </div>
      )}
    </div>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RightRailContainer = styled(Card)(() => ({
  backgroundColor: "transparent",
  boxShadow: "none",
}));

/* resize and move review screen to center of the screen without Animation*/
const useStylesNoAnimationCenter = makeStyles(() => {
  return createStyles({
    root: {
      width: ServiceRequestReviewFormCenterWidth,
    },
  });
});

/* Animation to resize and move review screen to side of the screen */
const useStylesAnimationSide = makeStyles(() => {
  return createStyles({
    root: {
      width: ServiceRequestReviewFormWidth,
      transform: "translate(0,0)",
      transition: "width 1000ms, transform 1000ms",
    },
  });
});

/* Animation to bring in the right rail */
const useStylesAnimationIn = makeStyles(() => {
  return createStyles({
    root: {
      visibility: "visible",
      opacity: "1",
      transform: "translate(0,0)",
      transition: "visibility 500ms, opacity 500ms, transform 1000ms",
    },
  });
});

/* Animation to push out the right rail */
const useStylesAnimationOut = makeStyles(() => {
  return createStyles({
    root: {
      visibility: "hidden",
      opacity: "0",
      transform: "translate(20%,0)",
      transition: "visibility 500ms, opacity 500ms, transform 1000ms",
      transitionDelay: "3s",
    },
  });
});

/* push out the right rail without Animation*/
const useStylesNoAnimationOut = makeStyles(() => {
  return createStyles({
    root: {
      visibility: "hidden",
      opacity: "0",
    },
  });
});

interface CssProps {
  isFaxAuthBuilderFlow?: boolean;
}
const useStyles = makeStyles((theme) => ({
  authorizationDetail: {
    marginBottom: ({ isFaxAuthBuilderFlow }: CssProps) => (isFaxAuthBuilderFlow ? theme.spacing(0) : theme.spacing(3)),
  },
  overallContainer: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 1023px)": {
      maxWidth: "918px",
      overflowX: "auto",
    },
    justifyContent: "center",
  },
  serviceRequestDivContainer: {
    "@media (max-width: 1023px)": {
      minWidth: "200px",
    },
    "@media (min-width: 1440px)": {
      maxWidth: "846px",
    },
  },
  serviceRequestDivMargin: {
    marginRight: theme.spacing(3),
  },
  serviceRequestDivNoMargin: {
    marginRight: 0,
  },
  serviceRequestCardContainerNoBorder: {
    padding: 0,
    border: "none",
    boxShadow: "none",
  },
  serviceRequestCardContainer: {
    "@media (max-width: 1023px)": {
      padding: theme.spacing(2),
    },
    "@media (min-width: 1024px) and (max-width: 1439px)": {
      padding: `calc(24px + (100vw - 1024px) * (24 - 16) / (1439 - 1024))`,
    },
    "@media (min-width: 1440px)": {
      padding: theme.spacing(3),
    },
  },
  rightRailDivContainer: {
    "@media (max-width: 1023px)": {
      maxWidth: "276px",
    },
    "@media (min-width: 1024px) and (max-width: 1439px)": {
      maxWidth: `calc(362px + (100vw - 1024px) * (362 - 276) / (1439 - 1024))`,
    },
    "@media (min-width: 1440px)": {
      maxWidth: "362px",
    },
  },
  rightRailFaxContainer: {
    maxWidth: 0,
  },
  dividerStyle: {
    marginBottom: theme.spacing(3),
  },
  submissionAttestation: {
    marginTop: theme.spacing(4),
  },
}));

interface GetDataError<TError> {
  message: string;
  data: TError | string;
  status?: number;
}
