import React, { Dispatch } from "react";

import { H4, RadioGroup, colorsLight } from "@coherehealth/common";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { makeStyles } from "@material-ui/core/styles";
import { BehavioralHealthAdmissionType } from "@coherehealth/core-platform-api";

interface Props {
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<ServiceRequestFormContent>;
  error: boolean;
}

export default function BehavioralHealthAdmissionTypeSelect({ formContent, setFormContent, error }: Props) {
  const classes = useStyles();
  return (
    <RadioGroup<BehavioralHealthAdmissionType>
      className={classes.secondaryText}
      row
      error={error}
      helperText={error && "Required"}
      label={<H4 className={classes.titleText}>Select behavioral health admission type</H4>}
      options={[
        { id: "VOLUNTARY", label: "Voluntary" },
        { id: "INVOLUNTARY", label: "Involuntary" },
      ]}
      value={formContent?.bHAdmissionType}
      onChange={(val) => {
        setFormContent({ ...formContent, bHAdmissionType: val });
      }}
    />
  );
}

const useStyles = makeStyles({
  titleText: {
    color: colorsLight.font.main,
  },
  secondaryText: {
    color: colorsLight.font.secondary,
  },
});
