import { stringify as stringifyQueryString, parse as parseQueryString } from "qs";
import { useFeature } from "@coherehealth/common";

export default function useOcrEnhancedPath(originalPath: string, orderBySegmentation?: boolean) {
  if (useFeature("preferOcrEnhanced")) {
    try {
      const url = new URL(originalPath);
      const query = parseQueryString(url.search.replace(/^[?]/, ""));
      query.preferOcrEnhanced = "true";
      if (orderBySegmentation) {
        query.orderBySegmentation = "true";
      }
      url.search = stringifyQueryString(query);
      return url.toString();
    } catch (e) {
      // This probably just indicates an invalid URL: should just return the original
    }
  }

  return originalPath;
}
