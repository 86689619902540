export const convertToTitleCase = (inputString: string): string => {
  return inputString.toLowerCase().replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
};

export const replaceUnderscoresWithSpace = (inputString: string): string => {
  return inputString.replace(/_/g, " ");
};

// Specific to Auth Category i.e LONG_TERM_CARE -> Long Term Care
export const convertToProperCaseAndFormatAuthCategory = (inputString: string): string => {
  return convertToTitleCase(replaceUnderscoresWithSpace(inputString));
};
