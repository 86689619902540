import { CurrentReviewTabTypes, useFeature, Body1, Body3 } from "@coherehealth/common";
import { ClinicalReviewContext } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { RecommendChangeRuleAction, Coverage, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { useContext, useCallback, useEffect, useRef, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

interface useInsightsPanelProps {
  reviewNudges?: RecommendChangeRuleAction[];
  aModalIsOpen?: boolean;
  showInsightsButton?: boolean;
  rightColumnTab?: CurrentReviewTabTypes;
  serviceRequest?: ServiceRequestResponse | null;
}

interface useInsightsPanelReturnProps {
  openInsightsPanel: boolean;
  handleToggleInsightsPanel: (event: React.MouseEvent<HTMLElement>) => void;
  popperId: string | undefined;
  panelButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
  numOfInsights: number;
  hideBadge: boolean;
  insightsAnchor: HTMLElement | null;
  handleCloseInsightsPanel: (fromClickAway?: boolean) => void;
  hasInsights: boolean;
  insights: Insight[];
}

const useInsightsPanel = ({
  reviewNudges,
  aModalIsOpen,
  showInsightsButton,
  rightColumnTab,
  serviceRequest,
}: useInsightsPanelProps): useInsightsPanelReturnProps => {
  const [openInsightsPanel, setOpenInsightsPanel] = useState(false);
  const [insightsAnchor, setInsightsAnchor] = useState<HTMLElement | null>(null);
  const [hasSeenInsightsOnce, setHasSeenInsightsOnce] = useState(false);
  const panelButtonRef = useRef(null);
  const useUpdatedClinicalReviewNudges = useFeature("updatedClinicalReviewNudges");
  const multiCoverageReviewsEnabled = useFeature("multiCoverageReviews");
  const { isMultiCoverageReviewRequired, patientPrimaryCoverage, additionalActiveCoverages } =
    useContext(ClinicalReviewContext);

  const editableRequestorInfoModalEnabled = useFeature("editableRequestorInfoModal");
  const requiredAORDetailsMissingFields = serviceRequest ? requiredAORDetailsMissing(serviceRequest) : [];

  const missingAORDetails =
    editableRequestorInfoModalEnabled && requiredAORDetailsMissingFields.length > 0
      ? [
          {
            title: "Missing Member Information - Unable to Complete Decisioning",
            messageElement: (
              <Body1>
                Because required member information fields ({requiredAORDetailsMissingFields.join(", ")}) are missing,
                this request cannot be decisioned. Please add AOR (Appointment of Representative) details.
              </Body1>
            ),
          },
        ]
      : [];

  const multiCoverageReviewInsights =
    multiCoverageReviewsEnabled && isMultiCoverageReviewRequired
      ? [
          {
            title: "Member has multiple coverages",
            messageElement: (
              <MultiCoverageReviewRequiredInsightComponent
                patientPrimaryCoverage={patientPrimaryCoverage}
                additionalActiveCoverages={additionalActiveCoverages}
              />
            ),
          },
        ]
      : [];
  const insights = [...multiCoverageReviewInsights, ...missingAORDetails, ...constructInsights(reviewNudges)];
  const numOfInsights = insights.length;
  const hasInsights = numOfInsights > 0;

  const handleOpenInsightsPanel = useCallback(
    (event?: React.MouseEvent<HTMLElement>) => {
      if (useUpdatedClinicalReviewNudges) {
        setInsightsAnchor(event?.currentTarget || panelButtonRef.current);
        setOpenInsightsPanel(true);
      }
    },
    [useUpdatedClinicalReviewNudges]
  );

  const handleToggleInsightsPanel = (event: React.MouseEvent<HTMLElement>) => {
    if (openInsightsPanel) {
      handleCloseInsightsPanel();
    } else {
      handleOpenInsightsPanel(event);
    }
  };

  const handleCloseInsightsPanel = (fromClickAway?: boolean) => {
    if (fromClickAway && rightColumnTab === "REVIEW") {
      return;
    }
    setOpenInsightsPanel(false);
    if (hasInsights) {
      setHasSeenInsightsOnce(true);
    }
  };

  const panelCanOpen = openInsightsPanel && Boolean(insightsAnchor);
  const popperId = panelCanOpen ? "insights-panel" : undefined;

  useEffect(() => {
    if (showInsightsButton && hasInsights && !aModalIsOpen && !hasSeenInsightsOnce) {
      handleOpenInsightsPanel();
    }
  }, [hasInsights, aModalIsOpen, handleOpenInsightsPanel, showInsightsButton, hasSeenInsightsOnce]);

  return {
    openInsightsPanel,
    handleToggleInsightsPanel,
    popperId,
    panelButtonRef,
    numOfInsights,
    hideBadge: hasSeenInsightsOnce,
    insightsAnchor,
    handleCloseInsightsPanel,
    hasInsights,
    insights,
  };
};

const useMultiCoverageInsightComponentStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1.5),
  },
  coverageLabel: {
    color: theme.palette.info.dark,
  },
  titleContainer: { display: "flex", alignItems: "center" },
  infoIcon: {
    color: theme.palette.info.main,
    marginRight: 4,
  },
}));
function MultiCoverageReviewRequiredInsightComponent({
  patientPrimaryCoverage,
  additionalActiveCoverages,
}: {
  patientPrimaryCoverage: Coverage | null | undefined;
  additionalActiveCoverages: Coverage[] | null | undefined;
}) {
  const classes = useMultiCoverageInsightComponentStyles();
  return (
    <Box className={classes.container}>
      <div className={classes.titleContainer}>
        <InfoIcon className={classes.infoIcon} />
        <Body1>This member has multiple coverages, please review for each coverage</Body1>
      </div>
      {patientPrimaryCoverage && (
        <div>
          <Body3 className={classes.coverageLabel}>{coverageLabel(patientPrimaryCoverage)}</Body3>
        </div>
      )}
      {additionalActiveCoverages?.map((coverage, idx) => (
        <div key={coverageLabel(coverage) || `fallback-${idx}`}>
          <Body3 className={classes.coverageLabel}>{coverageLabel(coverage)}</Body3>
        </div>
      ))}
    </Box>
  );
}

function coverageLabel(coverage: Coverage): string {
  const parts = [];
  // NOTE: this is ripped largely from CoveragesTable logic
  parts.push(coverage.healthPlanName);
  parts.push(coverage.coverageLineOfBusinessType ? coverage.coverageLineOfBusinessType : coverage.lineOfBusinessType);
  parts.push(coverage.coverageProductType ? coverage.coverageProductType : coverage.productType);
  return parts.filter(Boolean).join(" ");
}

const constructInsights = (reviewNudges?: RecommendChangeRuleAction[]): Insight[] => {
  const insights: Insight[] = [];
  reviewNudges?.forEach((nudge) => {
    const insight: InsightFromBackend = {
      title: nudge.title || "",
      message: nudge.message || "",
    };
    insights.push(insight);
  });
  return insights;
};

const requiredAORDetailsMissing = (serviceRequest?: ServiceRequestResponse): string[] => {
  const missingFields: string[] = [];
  if (
    serviceRequest &&
    serviceRequest.requestor &&
    serviceRequest.requestor.requestorType === "MEMBER_REPRESENTATIVE"
  ) {
    const { aorReceivedTimestamp, aorAddress } = serviceRequest.requestor;
    if (!aorReceivedTimestamp) {
      missingFields.push("aorReceivedTimestamp");
    }

    if (!aorAddress) {
      missingFields.push("aorAddress");
    } else {
      if (!aorAddress.line1) {
        missingFields.push("line1");
      }
      if (!aorAddress.city) {
        missingFields.push("city");
      }
      if (!aorAddress.state) {
        missingFields.push("state");
      }
      if (!aorAddress.zipCode) {
        missingFields.push("zipCode");
      }
    }
  }
  return missingFields;
};

interface InsightFromBackend {
  title: string;
  message: string;
  messageElement?: never;
}
interface InsightWithComponent {
  title: string;
  message?: never;
  messageElement: React.ReactElement;
}
export type Insight = InsightFromBackend | InsightWithComponent;

export { useInsightsPanel };
