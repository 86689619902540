import React, { useCallback, useEffect, useState } from "react";

import {
  HmoExceptionReview,
  ServiceRequestResponse,
  useDiscardHmoExceptionReview,
  useSubmitHmoExceptionReview,
  useUpdateHmoExceptionReview,
} from "@coherehealth/core-platform-api";
import Collapse from "@material-ui/core/Collapse";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import ReviewHeader from "../ReviewHeader/ReviewHeader";
import { Alert, colorsLight } from "@coherehealth/common";
import HmoExceptionReviewSubmissionModal from "./HmoExceptionSubmissionModal";
import HmoExceptionReviewEdit from "./HmoExceptionReviewEdit";
import HmoExceptionReviewReadOnly from "./HmoExceptionReadonly";
import { useHmoReview, HmoReviewUpdate } from "components/ClinicalReview/reviewUtils/useReviews";
import { ReviewReadOnlyCommonProps } from "components/ClinicalReview/reviewUtils/utils";
import DetailedHardRedirectClinicalReviewModal, {
  ClinicalReviewErrorDetails,
} from "../Modals/DetailedHardRedirectClinicalReviewModal";
import { defaultClinicalReviewErrorState, extractErrorDetails } from "../util/ReviewSectionUtil";

interface HmoExceptionReviewComponentProps extends HmoExceptionReviewReadonlyProps {
  serviceRequest: ServiceRequestResponse;
  hmoExceptionReview: HmoExceptionReview;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onFinishEditing?: Function;
}
interface HmoExceptionReviewEditableProps extends Omit<HmoExceptionReviewComponentProps, "hmoExceptionReview"> {
  hmoExceptionReviewId: string;
}
interface HmoExceptionReviewEditorProps extends HmoExceptionReviewComponentProps {
  setHmoExceptionReview: HmoReviewUpdate;
}

interface HmoExceptionReviewReadonlyProps extends ReviewReadOnlyCommonProps {
  hmoExceptionReview: HmoExceptionReview;
}
const HmoExceptionReviewComponent = ({ hmoExceptionReview, ...otherProps }: HmoExceptionReviewComponentProps) => {
  if (hmoExceptionReview?.reviewStatus === "DRAFT") {
    return <HmoExceptionReviewEditable {...otherProps} hmoExceptionReviewId={hmoExceptionReview.id} />;
  } else if (hmoExceptionReview?.reviewStatus === "COMPLETE" || hmoExceptionReview?.reviewStatus === "DISCARDED") {
    return <HmoExceptionReviewReadonly hmoExceptionReview={hmoExceptionReview} {...otherProps} />;
  } else {
    return null;
  }
};

const HmoExceptionReviewEditable = ({ hmoExceptionReviewId, ...otherProps }: HmoExceptionReviewEditableProps) => {
  const [HmoExceptionReview, setHmoExceptionReview] = useHmoReview({ hmoReviewId: hmoExceptionReviewId });
  if (!HmoExceptionReview || !setHmoExceptionReview) {
    return null;
  }
  return (
    <HmoExceptionReviewEditor
      {...otherProps}
      hmoExceptionReview={HmoExceptionReview}
      setHmoExceptionReview={setHmoExceptionReview}
    />
  );
};

function HmoExceptionReviewEditor({
  serviceRequest,
  hmoExceptionReview,
  setHmoExceptionReview,
  expanded,
  toggleExpanded,
  onFinishEditing,
}: HmoExceptionReviewEditorProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [errorDetails, setErrorDetails] = useState<ClinicalReviewErrorDetails>(
    defaultClinicalReviewErrorState(serviceRequest.id, hmoExceptionReview.id)
  );
  const [openHardRedirectOnSubmitModal, setOpenHardRedirectOnSubmitModal] = useState<boolean>(false);
  const [reviewSubmissionLoading, setReviewSubmissionLoading] = useState<boolean>(false);

  const onFinishEditingInternal = useCallback(() => {
    toggleExpanded?.();
    onFinishEditing?.();
  }, [toggleExpanded, onFinishEditing]);

  // Hooks & callbacks to save a draft
  const { mutate: update, error: updateError } = useUpdateHmoExceptionReview({
    reviewId: hmoExceptionReview.id,
    serviceRequestId: serviceRequest.id,
  });
  useEffect(() => {
    if (updateError) {
      enqueueSnackbar(`Error updating Hmo Exception review: ${updateError.message}`, { variant: "error" });
    }
  }, [updateError, enqueueSnackbar]);
  const saveHmoExceptionReviewDraft = useCallback(async () => {
    const res = await update(hmoExceptionReview);
    setHmoExceptionReview(res);
    onFinishEditingInternal();
  }, [update, hmoExceptionReview, setHmoExceptionReview, onFinishEditingInternal]);

  // Hooks & callbacks to submit a review
  const [isSubmissionModalOpen, setIsSubmissionModalOpen] = useState(false);
  const {
    mutate: submit,
    error: submitError,
    loading: submitLoading,
  } = useSubmitHmoExceptionReview({
    reviewId: hmoExceptionReview.id,
  });
  useEffect(() => {
    if (submitError) {
      setErrorDetails(extractErrorDetails(submitError, serviceRequest.id, hmoExceptionReview.id));
      setOpenHardRedirectOnSubmitModal(true);
      setIsSubmissionModalOpen(false);
    }
  }, [
    submitError,
    serviceRequest.id,
    hmoExceptionReview.id,
    setOpenHardRedirectOnSubmitModal,
    setIsSubmissionModalOpen,
  ]);

  const submitHmoExceptionReview = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setReviewSubmissionLoading(true);
      if (hmoExceptionReview.reviewOutcome) {
        const res = await submit({
          ...hmoExceptionReview,
          reviewOutcome: hmoExceptionReview.reviewOutcome,
        });
        setIsSubmissionModalOpen(false);
        onFinishEditingInternal();
        setHmoExceptionReview(res);
        setReviewSubmissionLoading(false);
      } else {
        setReviewSubmissionLoading(false);
        throw new Error("Review outcome must not be empty to submit");
      }
    },
    [hmoExceptionReview, setHmoExceptionReview, submit, setIsSubmissionModalOpen, onFinishEditingInternal]
  );

  // Hooks & callbacks to discard a review
  const {
    mutate: discard,
    error: discardError,
    loading: discarding,
  } = useDiscardHmoExceptionReview({
    reviewId: hmoExceptionReview.id,
  });
  useEffect(() => {
    if (discardError) {
      enqueueSnackbar(`Error discarding Hmo Exception review: ${discardError.message}`, { variant: "error" });
    }
  }, [discardError, enqueueSnackbar]);

  const discardHmoExceptionReview = useCallback(async () => {
    const res = await discard({
      ...hmoExceptionReview,
    });
    onFinishEditingInternal();
    setHmoExceptionReview(res);
  }, [discard, hmoExceptionReview, setHmoExceptionReview, onFinishEditingInternal]);

  const finishReviewLoading = reviewSubmissionLoading || submitLoading;

  return (
    <div style={{ backgroundColor: "#F5F5F5", paddingBottom: 8 }}>
      <Alert style={{ backgroundColor: "#F5F5F5", paddingTop: 28 }} severity="success">
        <div style={{ color: colorsLight.success.main }}>This request met auto-approval criteria</div>
      </Alert>
      <ReviewHeader
        toggleExpanded={toggleExpanded}
        expanded={expanded}
        reviewDateCreated={hmoExceptionReview.dateCreated}
        reviewLastUpdated={hmoExceptionReview.lastUpdated}
        reviewStatus={hmoExceptionReview.reviewStatus}
        reviewOutcome={hmoExceptionReview.reviewOutcome}
        reviewType={hmoExceptionReview.reviewType}
        reviewCreatedByName={hmoExceptionReview.createdByName}
        reviewCompletedByName={hmoExceptionReview.completedByName}
        reviewDateCompleted={hmoExceptionReview.dateCompleted}
        saveDraft={saveHmoExceptionReviewDraft}
      />
      <ReviewDetailsCollapse in={expanded} timeout={100} unmountOnExit>
        <HmoExceptionReviewEdit
          HmoExceptionReview={hmoExceptionReview}
          setHmoExceptionReview={setHmoExceptionReview}
          openSubmissionModal={() => setIsSubmissionModalOpen(true)}
          onDiscard={discardHmoExceptionReview}
          discarding={discarding}
        />
        <HmoExceptionReviewSubmissionModal
          loading={finishReviewLoading}
          isOpen={isSubmissionModalOpen}
          setIsOpen={setIsSubmissionModalOpen}
          reviewOutcome={hmoExceptionReview.reviewOutcome}
          setReviewOutcome={(reviewOutcome) => setHmoExceptionReview({ reviewOutcome })}
          performingProviderReviewOutcome={hmoExceptionReview.performingProviderReviewOutcome}
          setPerformingProviderReviewOutcome={(performingProviderReviewOutcome) =>
            setHmoExceptionReview({ performingProviderReviewOutcome })
          }
          facilityReviewOutcome={hmoExceptionReview.facilityReviewOutcome}
          setFacilityReviewOutcome={(facilityReviewOutcome) => setHmoExceptionReview({ facilityReviewOutcome })}
          performingProviderDecisionCriterias={hmoExceptionReview.performingProviderDecisionCriterias}
          setPerformingProviderDecisionCriterias={(performingProviderDecisionCriterias) =>
            setHmoExceptionReview({ performingProviderDecisionCriterias })
          }
          facilityDecisionCriterias={hmoExceptionReview.facilityDecisionCriterias}
          setFacilityDecisionCriterias={(facilityDecisionCriterias) =>
            setHmoExceptionReview({ facilityDecisionCriterias })
          }
          onSubmit={submitHmoExceptionReview}
        />
        <DetailedHardRedirectClinicalReviewModal
          isOpen={openHardRedirectOnSubmitModal}
          setIsOpen={setOpenHardRedirectOnSubmitModal}
          errorDetails={errorDetails}
        />
      </ReviewDetailsCollapse>
    </div>
  );
}

const HmoExceptionReviewReadonly = React.memo(
  ({
    hmoExceptionReview,
    expanded,
    toggleExpanded,
    unexpandable,
    reviewDetailsCollapseStyle,
    ...otherProps
  }: HmoExceptionReviewReadonlyProps) => {
    return (
      <div>
        <ReviewHeader
          toggleExpanded={toggleExpanded}
          expanded={expanded}
          reviewDateCreated={hmoExceptionReview.dateCreated}
          reviewLastUpdated={hmoExceptionReview.lastUpdated}
          reviewStatus={hmoExceptionReview.reviewStatus}
          reviewOutcome={hmoExceptionReview.reviewOutcome}
          reviewType={hmoExceptionReview.reviewType}
          reviewCreatedByName={hmoExceptionReview.createdByName}
          reviewCompletedByName={hmoExceptionReview.completedByName}
          reviewDateCompleted={hmoExceptionReview.dateCompleted}
          reviewText={hmoExceptionReview.decisionReasoning}
          unexpandable={unexpandable}
          {...otherProps}
        />
        <ReviewDetailsCollapse in={expanded} timeout={100} style={reviewDetailsCollapseStyle} unmountOnExit>
          <HmoExceptionReviewReadOnly
            hmoExceptionReview={hmoExceptionReview}
            hideDecisionReasoning={otherProps.authorizationReviewView}
          />
          ;
        </ReviewDetailsCollapse>
      </div>
    );
  }
);
// eslint-disable-next-line cohere-react/no-mui-styled-import
const ReviewDetailsCollapse = styled(Collapse)(({ theme }) => ({
  margin: theme.spacing(0, 3, 3),
}));

export default HmoExceptionReviewComponent;
