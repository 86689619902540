import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { useServiceRequestConfigSpec } from "components/ServiceRequest/ConfigurableServiceRequestForm";
import { useServiceRequestFormRedirectDispatch } from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import {
  ContinuationFormContent,
  PatientStayDateRange,
  ServiceRequestFormContent,
} from "common/SharedServiceRequestFormComponents";
import { ClinicalService, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import PatientStayCard, {
  createNewPatientStayDateRange,
} from "components/AuthBuilder/FillFormsGeneralAuthSubmission/PatientStayCard";
import { timePattern } from "util/dateUtils";
import { checkOnetimeRequestCoverage } from "util/patientUtils";
import {
  formatDateToISODate,
  getNoTimezoneDateTimeString,
  parseDateFromISOString,
  plusDays,
  useConfiguration,
  useEffectDeepEquality,
  useFeature,
} from "@coherehealth/common";
import { DischargeDetailsType } from "components/ServiceRequest/ReviewSection/DischargedToDetails";
import last from "lodash/last";

type usePatientStayDatesControlProps = {
  serviceRequest: ServiceRequestResponse | null;
  dischargeDetails?: DischargeDetailsType;
  patientStayDateRanges?: PatientStayDateRange[];
  setIsDischarged?: Dispatch<SetStateAction<boolean | undefined>>;
  setDischargeDetails?: Dispatch<SetStateAction<DischargeDetailsType>>;
  setPatientStayDateRanges?: Dispatch<SetStateAction<PatientStayDateRange[]>>;
  previouslyDecisionedDates?: Date[] | undefined;
  setAdmissionDate: Dispatch<SetStateAction<string | undefined>>;
};

export type PatientStayManger = {
  formContent: Partial<ContinuationFormContent & ServiceRequestFormContent>;
  reviewStayDateEditsFlag: boolean;
  getCombinedDischargedDateTime: () => string;
  renderPatientStayCard: JSX.Element;
  validatePatientStayCard: () => boolean;
};

export default function usePatientStayDatesControl({
  serviceRequest,
  dischargeDetails,
  patientStayDateRanges,
  setIsDischarged,
  setDischargeDetails,
  setPatientStayDateRanges,
  previouslyDecisionedDates,
  setAdmissionDate,
}: usePatientStayDatesControlProps) {
  const reviewStayDateEditsFlag = useFeature("reviewStayDatesEdits");
  const { patient, healthPlanName, authStatus, workflowId } = serviceRequest || {};
  const [attemptedSubmit, setAttemptedSubmit] = useState<boolean>(false);

  const [formContent, setFormContent] = useState({
    patientStatus: serviceRequest?.patientStatus,
    expectedAdmissionDate: serviceRequest?.expectedAdmissionDate
      ? parseDateFromISOString(serviceRequest?.expectedAdmissionDate)
      : undefined,
    admissionDate: parseDateFromISOString(serviceRequest?.admissionDate),
    admissionTime: serviceRequest?.admissionTime || "",
    admissionSource: serviceRequest?.admissionSource,
    patientStayDateRanges: patientStayDateRanges,
    dischargeDate:
      dischargeDetails?.actualDischargeDate || parseDateFromISOString(serviceRequest?.dischargeDate) || undefined,
    dischargeTime: dischargeDetails?.actualDischargeTime || serviceRequest?.dischargeTime || "",
    dischargedTo: dischargeDetails?.dischargedTo || serviceRequest?.dischargedTo,
    isExpedited: serviceRequest?.urgency?.isExpedited || false,
    id: patient?.id || "",
    startDate: (parseDateFromISOString(serviceRequest?.startDate) as Date) || null,
    clinicalServices: (serviceRequest?.clinicalServices as ClinicalService[]) || [],
    procedureCodes: serviceRequest?.procedureCodes || [],
    authCategory: serviceRequest?.authCategory,
  } as ContinuationFormContent & ServiceRequestFormContent);

  const validatePatientStayCard = (): boolean => {
    if (serviceRequest?.encounterType === "INPATIENT" && facilityBasedConfig?.enabled) {
      setAttemptedSubmit(true);
      const { admissionDate, admissionTime, dischargeDate, dischargeTime, dischargedTo, patientStatus } = formContent;
      if (patientStatus === "CURRENTLY_ADMITTED") {
        return Boolean(admissionDate && admissionTime && timePattern.test(admissionTime));
      }
      if (patientStatus === "DISCHARGED") {
        return Boolean(
          admissionDate &&
            admissionTime &&
            timePattern.test(admissionTime) &&
            dischargeDate &&
            dischargeTime &&
            timePattern.test(dischargeTime) &&
            dischargedTo
        );
      }
    }
    return true;
  };

  const startDateCoverage = useMemo(
    () =>
      patient?.coverages && formContent?.admissionDate
        ? checkOnetimeRequestCoverage(patient.coverages, formContent?.admissionDate || "")
        : undefined,
    [patient?.coverages, formContent?.admissionDate]
  );

  const { formFieldConfigurations } = useServiceRequestConfigSpec({
    ...formContent,
    patientId: patient?.id || "",
    healthPlanName: healthPlanName || "",
  });

  const { dispatchUrgencyRuleCheck } = useServiceRequestFormRedirectDispatch({
    workflowId,
    formContent,
    clinicalService: formContent.clinicalServices?.[0],
    healthPlanName: healthPlanName || "",
    patient,
  });

  const getCombinedDischargedDateTime = useCallback(() => {
    return getNoTimezoneDateTimeString(formContent.dischargeDate, formContent.dischargeTime);
  }, [formContent.dischargeDate, formContent.dischargeTime]);

  const addDaysToLastDecisionedDate = previouslyDecisionedDates?.length
    ? plusDays(1, last(previouslyDecisionedDates))
    : null;

  const facilityBasedConfig = useConfiguration("facilityBasedRequestConfiguration", serviceRequest?.healthPlanName);

  useEffectDeepEquality(() => {
    if (serviceRequest?.encounterType === "INPATIENT" && facilityBasedConfig?.enabled) {
      setDischargeDetails?.((prev = {}) => ({
        ...prev,
        dischargeDate: formContent.dischargeDate,
        dischargeTime: formContent.dischargeTime,
        dischargedTo: formContent.dischargedTo,
      }));
      setPatientStayDateRanges?.((prev) =>
        ["NOT_YET_ADMITTED"].includes(formContent.patientStatus as string) &&
        !facilityBasedConfig.includePatientStayDatesOnPlannedAdmission
          ? []
          : prev.length
          ? prev
          : [createNewPatientStayDateRange(addDaysToLastDecisionedDate as Date, null)]
      );
      setIsDischarged?.(formContent.patientStatus === "DISCHARGED");
      setAdmissionDate(formatDateToISODate(formContent.admissionDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formContent.admissionDate,
    formContent.dischargeDate,
    formContent.dischargeTime,
    formContent.dischargedTo,
    formContent.patientStayDateRanges,
    formContent.patientStatus,
    serviceRequest?.encounterType,
    setIsDischarged,
    setDischargeDetails,
    setPatientStayDateRanges,
    setAdmissionDate,
  ]);

  const renderPatientStayCard = useMemo(
    () => (
      <PatientStayCard
        formContent={formContent}
        setFormContent={setFormContent}
        healthPlanName={healthPlanName || ""}
        attemptedSubmit={attemptedSubmit}
        timePattern={timePattern}
        startDateCoverage={startDateCoverage}
        formConfiguration={formFieldConfigurations}
        authStatus={authStatus || "PENDING"}
        patient={patient || null}
        dispatchUrgencyRuleCheck={dispatchUrgencyRuleCheck}
        hideExpeditedRequestCheckbox={true}
        disablePatientStatusChange={false}
        isContinuation={true}
        isReview={true}
      />
    ),
    [
      formContent,
      healthPlanName,
      attemptedSubmit,
      startDateCoverage,
      formFieldConfigurations,
      authStatus,
      patient,
      dispatchUrgencyRuleCheck,
    ]
  );

  return {
    formContent,
    renderPatientStayCard,
    reviewStayDateEditsFlag,
    getCombinedDischargedDateTime,
    validatePatientStayCard,
  } as PatientStayManger;
}
