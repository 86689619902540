import { Dispatch, SetStateAction, useState, useRef } from "react";
import { Body1, H2, PrimaryButton, SecondaryButton, PdfViewer, colorsLight, H4, Checkbox } from "@coherehealth/common";
import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import {
  Box,
  CircularProgress,
  createStyles,
  Dialog,
  Divider,
  IconButton,
  makeStyles,
  Slide,
  styled,
  Theme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@mui/icons-material/Send";

import { TransitionProps } from "@material-ui/core/transitions";

export type CheckBoxValueAndLabel = {
  value: string;
  label: string;
  checked?: boolean;
  disabled?: boolean;
};

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  hasError?: boolean;
  setSubmittedAdhocLetter: Dispatch<SetStateAction<boolean>>;
  fileUrl?: string;
  onSendAdhocLetter: (
    sendLetterButtonDisabled: boolean,
    setHasClickedSend: Dispatch<SetStateAction<boolean>>,
    recipientsChecked: { [key: string]: boolean }
  ) => Promise<void>;
  letterTemplateTitle?: string;
  enabledRecipients?: CheckBoxValueAndLabel[];
  isMemberRequestedAuth?: boolean;
}

const AdHocLetterRecipientModal = ({
  showModal,
  setShowModal,
  hasError,
  fileUrl,
  onSendAdhocLetter,
  letterTemplateTitle,
  enabledRecipients,
  isMemberRequestedAuth,
}: Props) => {
  const [hasClickedSend, setHasClickedSend] = useState<boolean>(false);
  const initialCheckboxState: { [key: string]: boolean } = {};
  enabledRecipients?.forEach((option) => {
    initialCheckboxState[option.value] = option.checked || false;
  });

  const [currentRecipientsChecked, setCurrentRecipientsChecked] = useState<{ [key: string]: boolean }>(
    initialCheckboxState
  );

  const classes = useStyles();
  const currentPage = useRef<number>(0);

  const handleRecipientCheck = (checked: boolean, recipient: CheckBoxValueAndLabel) => {
    const prevState = { ...currentRecipientsChecked };
    prevState[recipient.value] = checked;
    setCurrentRecipientsChecked(prevState);
  };

  const isButtonDisabled = Object.values(currentRecipientsChecked).every((value) => !value);

  return (
    <>
      <Dialog
        open={showModal}
        disableEscapeKeyDown
        TransitionComponent={Transition}
        PaperProps={{
          className: classes.modalWrapper,
        }}
        onClose={() => setShowModal(false)}
      >
        <Box className={classes.innerModalWrapper}>
          <Box className={classes.determinationLetterHeader} component="div">
            {!!fileUrl && !hasError ? (
              <PdfViewer
                fileName={"Adhoc Letter"}
                pdfUrl={fileUrl}
                currentPage={currentPage}
                openSidePanel={false}
                hideSearchInAttachments
                hideBorders
              />
            ) : hasError ? (
              <Box className={classes.errorWrapper}>
                <H4 style={{ color: colorsLight.font.light }}>{"Unexpected server response. Please try again"}</H4>
              </Box>
            ) : (
              <CircularProgressContainer>
                <CircularProgress size={60} />
              </CircularProgressContainer>
            )}
          </Box>
          <Divider orientation="vertical" />
          <Box component="div" className={classes.determinationLetterBody}>
            <Box>
              <IconButton aria-label="close" className={classes.closeButton} onClick={() => setShowModal(false)}>
                {<CloseIcon />}
              </IconButton>
            </Box>
            <H2 className={classes.message}>{letterTemplateTitle}</H2>
            <Body1 className={classes.details} id="sr-detail-message" component="div">
              <Box component="div">
                Review letter to ensure that there are no errors with the language or service request details.
              </Box>
              {enabledRecipients && enabledRecipients?.length > 0 && (
                <Box component="div" className={classes.selectRecipientsHeader}>
                  Select Recipients
                </Box>
              )}
            </Body1>
            {enabledRecipients &&
              enabledRecipients.map((recipient) => (
                <Checkbox
                  key={recipient.value}
                  label={recipient.label}
                  onChange={(e) => handleRecipientCheck(e, recipient)}
                />
              ))}
            {hasClickedSend && (
              <Box className={classes.errorWrapper}>
                <CircularProgress size={60} />
              </Box>
            )}
            <PrimaryButton
              className={classes.primaryButton}
              onClick={() => onSendAdhocLetter(isButtonDisabled, setHasClickedSend, currentRecipientsChecked)}
              fullWidth
              startIcon={<SendIcon />}
              disabled={isButtonDisabled}
            >
              Send letter
            </PrimaryButton>
            <SecondaryButton onClick={() => setShowModal(false)} fullWidth>
              Cancel
            </SecondaryButton>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CircularProgressContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(50),
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      float: "right",
      color: theme.palette.text.primary,
      padding: 0,
      width: "24px",
      marginRight: "6px",
    },
    message: {
      paddingTop: "46px",
    },
    details: {
      paddingTop: theme.spacing(1),
      color: theme.palette.text.secondary,
      textAlign: "left",
      width: "100%",
    },
    primaryButton: {
      marginTop: "auto",
      marginBottom: theme.spacing(2),
    },
    primaryButtonforQM: {
      marginBottom: theme.spacing(2),
    },
    checkBox: {
      marginTop: "201px",
      marginLeft: theme.spacing(12),
      marginBottom: theme.spacing(2),
    },
    selectRecipientsHeader: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
    sendDenialLetterIcon: {
      margin: "auto",
      height: "184px",
    },
    innerModalWrapper: {
      display: "flex",
      justifyContent: "center",
      height: "100%",
      overflow: "hidden",
    },
    determinationLetterHeader: {
      display: "flex",
      flexDirection: "column",
      width: "648px",
    },
    errorWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    determinationLetterBody: {
      display: "flex",
      flexDirection: "column",
      width: "440px",
      padding: "34px 24px",
    },
    modalWrapper: {
      maxWidth: "none",
      maxHeight: "none",
      height: "100vh",
      width: "1088px",
      margin: "0",
      borderRadius: "0 0 4px 4px",
      position: "absolute",
      top: "0",
      background: theme.palette.background.default,
    },
    boxWrapper: {
      padding: "16px 0 0 0",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  const { timeout, ...otherProps } = { ...props };
  return <Slide direction="down" ref={ref} timeout={500} {...otherProps} unmountOnExit />;
});

export default AdHocLetterRecipientModal;
