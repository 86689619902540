import React, { useState } from "react";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { PrimaryButton, SecondaryButton, SingleSelectDropdown, TextField, RichTextEditor } from "@coherehealth/common";
import { HmoExceptionReview } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { HmoReviewUpdate } from "components/ClinicalReview/reviewUtils/useReviews";

interface Props {
  HmoExceptionReview: HmoExceptionReview;
  setHmoExceptionReview: HmoReviewUpdate;
  openSubmissionModal: () => void;
  onDiscard: () => void;
  discarding: boolean;
}

export default function HmoExceptionReviewEdit({
  HmoExceptionReview,
  setHmoExceptionReview,
  openSubmissionModal,
  onDiscard,
  discarding,
}: Props) {
  const setValue = (fieldName: keyof HmoExceptionReview) => (val: string) => {
    setHmoExceptionReview({ [fieldName]: val });
  };

  const [attemptedSubmitted, setAttemptedSubmitted] = useState(false);
  type PerformingProviderDecisionCriterias = NonNullable<
    NonNullable<HmoExceptionReview>["performingProviderDecisionCriterias"]
  >;
  type FacilityDecisionCriterias = NonNullable<NonNullable<HmoExceptionReview>["facilityDecisionCriterias"]>;

  return (
    <Grid container spacing={2} component="form">
      {
        <>
          {
            <Grid item xs={12}>
              <TextField
                label="Performing Provider Review Outcome"
                error={attemptedSubmitted}
                // helperText={attemptedSubmitted && "Required"}
                value={HmoExceptionReview.performingProviderReviewOutcome || ""}
                onChangeValue={setValue("performingProviderReviewOutcome")}
              />
            </Grid>
          }

          {
            <Grid item xs={12}>
              <TextField
                label="Facility Review Outcome"
                error={attemptedSubmitted}
                // helperText={attemptedSubmitted && "Required"}
                value={HmoExceptionReview.facilityReviewOutcome || ""}
                onChangeValue={setValue("facilityReviewOutcome")}
              />
            </Grid>
          }
          {
            <Grid item xs={12}>
              <SingleSelectDropdown<PerformingProviderDecisionCriteriaOption>
                label="Performing Provider Decision Criteria"
                fullWidth
                error={attemptedSubmitted}
                helperText={attemptedSubmitted && "Required"}
                options={PerformingProviderDecisionCriteriaOptions}
                value={
                  PerformingProviderDecisionCriteriaOptions.find(
                    ({ id }) => HmoExceptionReview.performingProviderDecisionCriterias === id
                  )?.id
                }
                onChange={(opt) => {
                  setHmoExceptionReview({
                    performingProviderDecisionCriterias: opt as PerformingProviderDecisionCriterias,
                  });
                }}
              />
            </Grid>
          }
          {
            <Grid item xs={12}>
              <SingleSelectDropdown<FacilityDecisionCriteriaOption>
                label="Facility Decision Criteria"
                fullWidth
                error={attemptedSubmitted}
                helperText={attemptedSubmitted && "Required"}
                options={FacilityDecisionCriteriaOptions}
                value={
                  FacilityDecisionCriteriaOptions.find(({ id }) => HmoExceptionReview.facilityDecisionCriterias === id)
                    ?.id
                }
                onChange={(opt) => {
                  setHmoExceptionReview({
                    facilityDecisionCriterias: opt as FacilityDecisionCriterias,
                  });
                }}
              />
            </Grid>
          }
          {
            <Grid item xs={12}>
              <RichTextEditor
                readonly={false}
                label="Decision reasoning (required)"
                error={attemptedSubmitted}
                // helperText={attemptedSubmitted && "Required"}
                htmlValue={HmoExceptionReview.decisionReasoning || ""}
                setHtmlValue={setValue("decisionReasoning")}
              />
            </Grid>
          }
        </>
      }

      <Grid item xs={12} style={{ paddingTop: "inherit", paddingBottom: 24 }}>
        <Divider />
      </Grid>
      <Grid item container xs={12} alignItems="baseline" justify="flex-end">
        <DiscardButton warning onClick={onDiscard} loading={discarding}>
          Discard
        </DiscardButton>
        <FinishButton
          onClick={() => {
            setAttemptedSubmitted(true);
            openSubmissionModal();
          }}
        >
          Finish review
        </FinishButton>
      </Grid>
    </Grid>
  );
}

interface PerformingProviderDecisionCriteriaOption {
  id: string;
  label: string;
}

const PerformingProviderDecisionCriteriaOptions: PerformingProviderDecisionCriteriaOption[] = [
  { id: "LIMITED_SPECIALIST_IN_FIELD", label: "The provider is a limited specialist in the field" },
  {
    id: "MEMBER_NO_ACCESS_TRANSPORTATION",
    label: "Member does not have access to transportation to get to a network provider",
  },
  {
    id: "NETWORK_PROVIDER_NOT_IN_DEFINED_DISTANCE",
    label:
      "A network provider does not exist within the defined time or distance - as required by state law or contract - from a member’s official place of residence",
  },
  { id: "PARTICULAR_PROVIDER_FOR_THE_LOCATION", label: "Praticular provider for that specific location" },
  { id: "OTHER_REASON", label: "Other Reasons" },
];

interface FacilityDecisionCriteriaOption {
  id: string;
  label: string;
}

const FacilityDecisionCriteriaOptions: FacilityDecisionCriteriaOption[] = [
  {
    id: "MEMBER_NO_ACCESS_TRANSPORTATION",
    label: "Member does not have access to transportation to get to a network facility",
  },
  {
    id: "NETWORK_PROVIDER_NOT_IN_DEFINED_DISTANCE",
    label:
      "A network facility does not exist within the defined time or distance - as required by state law or contract - from a member’s official place of residence",
  },
  {
    id: "PROVIDER_PRACTICES_OON_FACULTY_ONLY",
    label: "The performing provider only practices at this OON facility",
  },
  {
    id: "SPECIALIZED_EQUIPMENT",
    label:
      "The services require specialized equipment that is only available at this out of network facility. (If yes then specific documentation is needed to support reason)",
  },
];

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DiscardButton = styled(SecondaryButton)(({ theme }) => ({
  width: 138,
  height: 48,
  padding: theme.spacing(0),
  marginRight: theme.spacing(3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const FinishButton = styled(PrimaryButton)(({ theme }) => ({
  width: 209,
  height: 48,
  padding: theme.spacing(0),
}));
