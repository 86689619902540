import {
  AutoFillIcon,
  Body1,
  Body2,
  H6,
  InlineButton,
  SingleSelectDropdown,
  TextField,
  Tooltip,
} from "@coherehealth/common";
import { Address } from "@coherehealth/core-platform-api";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import PhoneInput from "common/PhoneInput";
import listRemove from "util/listRemove";
import listReplace from "util/listReplace";
import { isPhoneNumberValid } from "util/phoneUtils";
import {
  getTINDisplayValue,
  isNpiValid,
  isTinValid,
  isZipcodeValid,
  updateTinFromDisplayValue,
} from "util/providerUtils";
import { Dispatch, Fragment, useState } from "react";
import {
  AvailityInfo,
  FormSection,
  FormTitleDivider,
  ProviderOrgInfo,
  AVAILITY_AUTO_FILLED_TOOLTIP,
  StyledEndAdornmentContainer,
  useStyles,
} from "./shared";
import OnboardingQuestionCard from "./OnboardingQuestionCard";
import { InputAdornment } from "@material-ui/core";
import UnitedStatesStateSelection from "../ProviderOrganization/UnitedStatesStateSelection";

export interface Props {
  organizationInfo: ProviderOrgInfo;
  availityInfo: AvailityInfo;
  setOrganizationInfo: Dispatch<ProviderOrgInfo>;
  orgNameIsDuplicate: boolean;
  highlightInfoFormErrors: boolean;
}

export default function OrganizationInfoForm({
  organizationInfo,
  availityInfo,
  setOrganizationInfo,
  orgNameIsDuplicate,
  highlightInfoFormErrors,
}: Props) {
  const setOrgInfoField =
    (field: keyof ProviderOrgInfo, addressField?: keyof Address) => (newVal: ProviderOrgInfo[typeof field]) => {
      if (field === "primaryAddress" && !!addressField) {
        setOrganizationInfo({
          ...organizationInfo,
          primaryAddress: { ...organizationInfo.primaryAddress, [addressField]: newVal },
        });
      } else {
        setOrganizationInfo({ ...organizationInfo, [field]: newVal });
      }
    };

  const { toolTip, icon, formGroupDivider } = useStyles();

  /*
   * Checks if a field is auto filled from Availity
   */
  const isAutoFilled = (availityField: keyof AvailityInfo, organizationField: keyof ProviderOrgInfo) => {
    return !!availityInfo[availityField] && availityInfo[availityField] === organizationInfo[organizationField];
  };

  /*
   * If auto filled from Availity return autoFilled endAdornment
   */
  const autoFilledEndAdornment = (availityField: keyof AvailityInfo, organizationField: keyof ProviderOrgInfo) => {
    if (isAutoFilled(availityField, organizationField)) {
      return {
        endAdornment: (
          <StyledEndAdornmentContainer>
            <Tooltip className={toolTip} title={AVAILITY_AUTO_FILLED_TOOLTIP} placement="top-end">
              <InputAdornment className={icon} position="end">
                <AutoFillIcon />
              </InputAdornment>
            </Tooltip>
          </StyledEndAdornmentContainer>
        ),
      };
    }
  };

  const [orgTinDisplay, setOrgTinDisplay] = useState<string>(getTINDisplayValue(organizationInfo.tin));

  return (
    <OnboardingQuestionCard titleText="About your organization">
      <FormTitleDivider className={formGroupDivider} />
      <FormSection>
        <Grid item xs={12}>
          <H6>Organization contact information</H6>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Organization Name"
            value={organizationInfo.name}
            onChangeValue={setOrgInfoField("name")}
            fullWidth
            error={highlightInfoFormErrors && (orgNameIsDuplicate || !organizationInfo.name)}
            helperText={
              highlightInfoFormErrors &&
              (orgNameIsDuplicate
                ? "This organization name has already been used."
                : !organizationInfo.name && "Required")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Organization TIN"
            value={orgTinDisplay}
            onChangeValue={(newValue) => {
              setOrgInfoField("tin")(newValue.replaceAll("-", ""));
              setOrgTinDisplay(getTINDisplayValue(newValue));
            }}
            disabled={isAutoFilled("AvailityCustomerTINs", "tin")}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneInput
            label="Phone number"
            value={organizationInfo.phoneNumber}
            onChange={setOrgInfoField("phoneNumber")}
            error={highlightInfoFormErrors && !isPhoneNumberValid(organizationInfo.phoneNumber.number)}
            helperText={
              highlightInfoFormErrors &&
              !isPhoneNumberValid(organizationInfo.phoneNumber.number) &&
              "You must provide a valid 10-digit phone number (Ex. (123) 456-7890)"
            }
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneInput
            label="Fax number"
            value={organizationInfo.faxNumber}
            onChange={setOrgInfoField("faxNumber")}
            hideExtensionField
            error={highlightInfoFormErrors && !organizationInfo.faxNumber.number}
            helperText={highlightInfoFormErrors && !organizationInfo.faxNumber.number && "Required"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Website (optional)"
            value={organizationInfo.website}
            onChangeValue={setOrgInfoField("website")}
            fullWidth
          />
        </Grid>
      </FormSection>
      <FormSection>
        <Grid item xs={12}>
          <H6>Organization NPI</H6>
        </Grid>
        <Grid item xs={12}>
          <Body1 color="textSecondary" style={{ width: "50%" }}>
            List one NPI associated with your organization. If you do not have an organization NPI, please enter the NPI
            of one of your providers.
          </Body1>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Organization NPI"
            value={organizationInfo.npi}
            onChangeValue={setOrgInfoField("npi")}
            InputProps={autoFilledEndAdornment("AvailityCustomerNPIs", "npi")}
            error={highlightInfoFormErrors && !isNpiValid(organizationInfo.npi)}
            helperText={
              highlightInfoFormErrors &&
              !isNpiValid(organizationInfo.npi) &&
              "You must provide a valid 10-digit NPI number (Ex: 1234567890)"
            }
            fullWidth
          />
        </Grid>
      </FormSection>
      <FormSection>
        <Grid item xs={12}>
          <H6>Organization address</H6>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Street address 1"
            value={organizationInfo.primaryAddress.line1}
            onChangeValue={setOrgInfoField("primaryAddress", "line1")}
            fullWidth
            error={highlightInfoFormErrors && !organizationInfo.primaryAddress.line1}
            helperText={highlightInfoFormErrors && !organizationInfo.primaryAddress.line1 && "Required"}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Street address 2 (optional)"
            value={organizationInfo.primaryAddress.line2}
            onChangeValue={setOrgInfoField("primaryAddress", "line2")}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="City"
            value={organizationInfo.primaryAddress.city}
            onChangeValue={setOrgInfoField("primaryAddress", "city")}
            fullWidth
            error={highlightInfoFormErrors && !organizationInfo.primaryAddress.city}
            helperText={highlightInfoFormErrors && !organizationInfo.primaryAddress.city && "Required"}
          />
        </Grid>
        <Grid item xs={3}>
          <UnitedStatesStateSelection
            state={organizationInfo.primaryAddress.state || ""}
            setState={setOrgInfoField("primaryAddress", "state")}
            error={highlightInfoFormErrors && !organizationInfo.primaryAddress.state}
            helperText={(highlightInfoFormErrors && !organizationInfo.primaryAddress.state && "Required") || ""}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Zip code"
            value={organizationInfo.primaryAddress.zipCode}
            onChangeValue={setOrgInfoField("primaryAddress", "zipCode")}
            fullWidth
            error={highlightInfoFormErrors && !isZipcodeValid(organizationInfo.primaryAddress.zipCode)}
            helperText={
              highlightInfoFormErrors &&
              !isZipcodeValid(organizationInfo.primaryAddress.zipCode) &&
              "You must provide a 5 digit zip code"
            }
          />
        </Grid>
      </FormSection>
      <FormSection>
        <Grid item xs={12}>
          <H6>Which of the following best describes your organizational structure</H6>
        </Grid>
        <Grid item xs={9}>
          <SingleSelectDropdown
            label="Organization type"
            value={organizationInfo.organizationStructure}
            onChange={setOrgInfoField("organizationStructure")}
            options={orgStructureOptions}
            maxMenuHeight={200}
            fullWidth
            error={highlightInfoFormErrors && !organizationInfo.organizationStructure}
            helperText={highlightInfoFormErrors && !organizationInfo.organizationStructure && "Required"}
          />
        </Grid>
      </FormSection>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <H6>Do you plan to submit authorizations for any other TINs? If so, please list them below.</H6>
        </Grid>
        {organizationInfo.otherTins.map(({ name, tin }, idx) => {
          const setName = (newName: string) => {
            setOrganizationInfo({
              ...organizationInfo,
              otherTins: listReplace(organizationInfo.otherTins, idx, { name: newName, tin }),
            });
          };

          const setTin = (newTin: string) => {
            setOrganizationInfo({
              ...organizationInfo,
              otherTins: listReplace(organizationInfo.otherTins, idx, { name, tin: newTin }),
            });
          };

          return (
            <Fragment key={`extra-organization-tin-${idx}`}>
              <Grid item xs={6}>
                <TextField
                  label="Organization Name"
                  value={name}
                  onChangeValue={setName}
                  fullWidth
                  error={highlightInfoFormErrors && !name}
                  helperText={highlightInfoFormErrors && !name && "Required"}
                />
              </Grid>
              <Grid container item xs={6}>
                <Grid item xs>
                  <TextField
                    label={"Practice TIN"}
                    value={getTINDisplayValue(tin)}
                    onChangeValue={updateTinFromDisplayValue(setTin)}
                    style={{ flexGrow: 1, paddingRight: 16 }}
                    fullWidth
                    error={highlightInfoFormErrors && !isTinValid(tin)}
                    helperText={
                      highlightInfoFormErrors &&
                      !isTinValid(tin) &&
                      "You must provide a 9 digit TIN number (Ex. 12-3456789)"
                    }
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={() => {
                      setOrganizationInfo({
                        ...organizationInfo,
                        otherTins: listRemove(organizationInfo.otherTins, idx),
                      });
                    }}
                    style={{
                      marginTop: 3,
                    }} /* centers the icon button vertically, using align-items: flex center doesn't work well if there is helper text on adjacent text fields */
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Fragment>
          );
        })}
        <Grid item xs={12}>
          <InlineButton
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setOrganizationInfo({
                ...organizationInfo,
                otherTins: [...organizationInfo.otherTins, { name: "", tin: "" }],
              });
            }}
          >
            <Body2>Add another TIN</Body2>
          </InlineButton>
        </Grid>
      </Grid>
    </OnboardingQuestionCard>
  );
}

const orgStructureOptions = [
  { id: "Individual provider", label: "Individual provider" },
  {
    id: "Single specialty group",
    label: "Single specialty group (such as a group focused on orthopedics only or physical therapy only)",
  },
  {
    id: "Multi-specialty group",
    label: "Multi-specialty group (such as a group that includes primary care, gynecology, and orthopedics)",
  },
  { id: "Hospital", label: "Hospital" },
  { id: "Inpatient rehabilitation", label: "Inpatient rehabilitation" },
  { id: "Outpatient rehabilitation", label: "Outpatient rehabilitation" },
  { id: "None", label: "None of these apply" },
];
