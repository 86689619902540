import React, { FunctionComponent, useRef, useContext } from "react";
import { Body1, Caption, Tooltip, SingleSelectDropdown } from "@coherehealth/common";
import {
  AuthBuilderWorkflowStep,
  AuthStatus,
  ClinicalService,
  Patient,
  PlaceOfService,
  ProcedureCode,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core";
import { useAuthorized } from "authorization";
import { SuggestionContext } from "../../../AuthBuilder/SuggestionContext";

import { NON_COHERE_USER_EDIT_DISABLED_TOOLTIP, RedirectRuleCheckFn } from "common/SharedServiceRequestFormComponents";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-root": {
      color: theme.palette.error.dark,
    },
  },
}));

const PlaceOfServiceSelect: FunctionComponent<{
  error: boolean;
  isInpatient: boolean;
  placeOfService: PlaceOfService | null;
  startDate?: string;
  setPlaceOfService: (pos: PlaceOfService | null) => void;
  authStatus: AuthStatus;
  clinicalService?: ClinicalService;
  patient?: Patient;
  dispatchRedirectRuleCheck?: RedirectRuleCheckFn;
  isCohereFaxForm?: boolean;
  procedureCodes?: ProcedureCode[];
  placeOfServiceLoading: boolean;
  availablePlacesOfService: PlaceOfService[] | undefined;
  menuWidth?: number;
  onContinuationPatientSummary?: boolean;
  editableFromPatientSummary?: boolean;
  authStage?: AuthBuilderWorkflowStep;
  isDiagnosisAndProviderCardOnContinuation?: boolean;
}> = ({
  error,
  isInpatient,
  placeOfService,
  startDate,
  setPlaceOfService,
  authStatus,
  clinicalService,
  patient,
  dispatchRedirectRuleCheck,
  isCohereFaxForm,
  procedureCodes,
  placeOfServiceLoading,
  availablePlacesOfService,
  menuWidth,
  onContinuationPatientSummary = false,
  editableFromPatientSummary,
  authStage,
  isDiagnosisAndProviderCardOnContinuation,
}) => {
  const defaultPlaceOfService = clinicalService?.defaultPlaceOfService;
  const getHelperText = () => {
    if (error) {
      return "Required";
    }
  };

  const canEditWhenApproved = useAuthorized("EDIT_APPROVED_SR_PLACE_OF_SERVICE");
  const disableEdit =
    (authStatus === "APPROVED" && !canEditWhenApproved) ||
    (onContinuationPatientSummary && !editableFromPatientSummary);

  const classes = useStyles();
  const endAdornmentRef = useRef<HTMLDivElement>(null);
  const { suggestedFormContent } = useContext(SuggestionContext);

  return (
    <Tooltip
      interactive
      title={disableEdit && !onContinuationPatientSummary ? NON_COHERE_USER_EDIT_DISABLED_TOOLTIP : ""}
      PopperProps={{ anchorEl: endAdornmentRef.current }}
    >
      {/* key is necessary because without it, the single select does not always rerender when placeOfService.id changes */}
      <div key={placeOfService?.id}>
        <SingleSelectDropdown<PlaceOfService>
          data-testid="place-of-service-dropdown"
          showAutoFillIcon={
            isCohereFaxForm ||
            (suggestedFormContent?.placeOfService?.placeOfServiceEntity
              ? suggestedFormContent.placeOfService.placeOfServiceEntity.code === placeOfService?.code
              : false)
          }
          disabled={disableEdit || isDiagnosisAndProviderCardOnContinuation}
          showLoadingIcon={placeOfServiceLoading}
          error={error}
          helperText={getHelperText()}
          label="Place of service"
          options={placeOfServiceLoading ? undefined : availablePlacesOfService}
          value={placeOfService?.id}
          renderOption={({ id, name }) => {
            if (!disableEdit && defaultPlaceOfService && id === defaultPlaceOfService.id) {
              return (
                <>
                  <span>
                    <Body1 style={{ display: "inline" }} data-public>
                      {name}
                    </Body1>
                    <CaptionGreen data-public> Recommended for auto approval</CaptionGreen>
                  </span>
                </>
              );
            }
            return <Body1>{name}</Body1>;
          }}
          onChange={(selectedOption) => {
            if (patient && selectedOption && dispatchRedirectRuleCheck) {
              dispatchRedirectRuleCheck({
                displayTarget: "FACILITY_CATEGORY",
                authStage,
                serviceRequest: {
                  patient: patient,
                  ...(!!startDate ? { startDate } : {}),
                  clinicalService: clinicalService,
                  encounterType: isInpatient ? "INPATIENT" : "OUTPATIENT",
                  ...(procedureCodes && procedureCodes.length > 0 ? { semanticProcedureCodes: procedureCodes } : {}),
                },
              });
            }
            setPlaceOfService(availablePlacesOfService?.find((pos) => pos.id === selectedOption) || null);
          }}
          className={classes.root}
          selectRef={endAdornmentRef}
          menuWidth={menuWidth}
          dataPublic={true}
        />
      </div>
    </Tooltip>
  );
};

export default PlaceOfServiceSelect;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CaptionGreen = styled(Caption)(({ theme }) => ({
  fontWeight: "normal",
  color: theme.palette.success.dark,
  paddingLeft: theme.spacing(1),
}));
