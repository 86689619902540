import {
  ClinicalService,
  MdReview,
  NurseReview,
  PeerToPeerReview,
  ProcedureCode,
  ReviewType,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import { useMemo, useState } from "react";
import elementIsNonNull from "util/elementIsNonNull";

interface ProcedureCodeStateProps {
  serviceRequest: ServiceRequestResponse;
  existingReviews?: ReviewType[] | null;
  currentReviewId: string;
  groupedByClinicalServices?: boolean;
}
interface ProcedureCodeStateReturn {
  approvedProcedureCodes: ProcedureCode[];
  requestedProcedureCodes: ProcedureCode[];
  pxCodesByClinicalService: Map<string, ProcedureCode[]>;
  approvedUnitsByClinicalService: Map<string, number>;
  updateApprovedUnitsByCS: (clinicalServiceId: string, approvedUnits: number) => void;
  updateSinglePxCodeUnit: (unitsUpdate: string, updatedPxCode: ProcedureCode) => void;
  approvedUnits: number;
  approvedPxCodesAreInvalid: () => boolean;
}

export function useProcedureCodesState({
  serviceRequest,
  existingReviews = [],
  currentReviewId,
  groupedByClinicalServices = true,
}: ProcedureCodeStateProps): ProcedureCodeStateReturn {
  const [pxCodesByClinicalService, setPxCodesByClinicalService] = useState<Map<string, ProcedureCode[]>>(
    grouppxCodesByClinicalService(serviceRequest, existingReviews, currentReviewId)
  );
  const clinicalServices = serviceRequest.clinicalServices?.filter(elementIsNonNull);
  const [approvedUnitsByClinicalService, setapprovedUnitsByClinicalService] = useState<Map<string, number>>(
    getapprovedUnitsByClinicalService(pxCodesByClinicalService, clinicalServices)
  );

  /**
   * finds the given updatedPxCode in the pxCodesByClinicalService map
   * and updates its approvedUnits to unitsUpdate
   */
  const updateSinglePxCodeUnit = (unitsUpdate: string, updatedPxCode: ProcedureCode) => {
    const pxCodesByServiceUpdate = new Map(pxCodesByClinicalService);
    const csIdOrUncategorized = updatedPxCode.groupId || UNCATEGORIZED_SERVICE;
    const pxCodesForGroup = pxCodesByServiceUpdate.get(csIdOrUncategorized) || [];
    const procedureCodesUpdate = pxCodesForGroup.map((pxCode) => {
      if (isSamePxCode(pxCode, updatedPxCode)) {
        const numVal = Math.trunc(Number(unitsUpdate || 0));
        const newPx = { ...pxCode, approvedUnits: numVal };
        return newPx;
      } else {
        return pxCode;
      }
    });
    pxCodesByServiceUpdate.set(csIdOrUncategorized, procedureCodesUpdate);
    setPxCodesByClinicalService(pxCodesByServiceUpdate);
  };

  /**
   * updates the approvedUnits for the given clinicalServiceId in the
   * approvedUnitsByClinicalService map to approvedUnits. It also updates each
   * px code under clinicalServiceId in the pxCodesByClinicalService map
   * to have the same approvedUnits
   */
  const updateApprovedUnitsByCS = (clinicalServiceId: string, approvedUnits: number) => {
    const updatedApprovedUnits = new Map(approvedUnitsByClinicalService);
    updatedApprovedUnits.set(clinicalServiceId, approvedUnits);
    setapprovedUnitsByClinicalService(updatedApprovedUnits);

    const pxCodesByServiceUpdate = new Map(pxCodesByClinicalService);
    const pxCodesForGroup = pxCodesByServiceUpdate.get(clinicalServiceId) || [];
    const procedureCodesUpdate = pxCodesForGroup.map((pxCode) => {
      const newPx = { ...pxCode, approvedUnits: approvedUnits };
      return newPx;
    });
    pxCodesByServiceUpdate.set(clinicalServiceId, procedureCodesUpdate);
    setPxCodesByClinicalService(pxCodesByServiceUpdate);
  };

  /**
   * @returns whether the current approvedProcedureCodes is invalid. A px code
   * is invalid if it has more approvedUnits than requested units. If it's not a
   * unitsOnPx clinicalService and we are grouping by services on the UI (groupedByClinicalServies = true),
   * then approved px units should also not be greater than approved service level units
   */
  const approvedPxCodesAreInvalid = (): boolean => {
    const hasMoreThanRequested = approvedProcedureCodes.some(
      (px) => px && px.approvedUnits && px.units && px.approvedUnits > px.units
    );
    if (hasMoreThanRequested) {
      return true;
    }

    const hasMoreThanServiceLevelApproved = approvedProcedureCodes.some((px) => {
      const clinicalService = clinicalServices?.find((cs) => cs.id === px.groupId);
      if (clinicalService && !clinicalService.isUnitsOnPx && groupedByClinicalServices) {
        const approvedUnitsForService = approvedUnitsByClinicalService.get(px.groupId || UNCATEGORIZED_SERVICE) || 0;
        return (px.approvedUnits || 0) > approvedUnitsForService;
      } else {
        return false;
      }
    });
    if (hasMoreThanServiceLevelApproved) {
      return true;
    }
    return false;
  };

  const requestedProcedureCodes = Array.from(pxCodesByClinicalService.values()).flat();

  const approvedProcedureCodes = requestedProcedureCodes.filter(
    (pxCode) => pxCode.approvedUnits && pxCode.approvedUnits > 0
  );

  const approvedUnits = useMemo(
    () => sumAllApprovedUnits(pxCodesByClinicalService, clinicalServices),
    [pxCodesByClinicalService, clinicalServices]
  );

  return {
    approvedProcedureCodes,
    requestedProcedureCodes,
    pxCodesByClinicalService,
    approvedUnitsByClinicalService,
    updateApprovedUnitsByCS,
    updateSinglePxCodeUnit,
    approvedUnits,
    approvedPxCodesAreInvalid,
  };
}

export const UNCATEGORIZED_SERVICE = "Uncategorized Service";

function grouppxCodesByClinicalService(
  serviceRequest: ServiceRequestResponse,
  existingReviews: ReviewType[] | null,
  currentReviewId: string
): Map<string, ProcedureCode[]> {
  const previousOrExistingPxCodes = getPreviousOrExistingPxCodes(serviceRequest, existingReviews, currentReviewId);
  const groups = new Map<string, ProcedureCode[]>();
  previousOrExistingPxCodes.forEach((px) => {
    const pxClinicalServiceId = px.groupId || UNCATEGORIZED_SERVICE;

    if (!groups.has(pxClinicalServiceId)) {
      groups.set(pxClinicalServiceId, [px]);
    } else {
      groups.get(pxClinicalServiceId)?.push(px);
    }
  });

  return groups;
}

type ReviewsWithApprovedCodes = MdReview | NurseReview | PeerToPeerReview;
type ValidReviewType = ReviewsWithApprovedCodes["reviewType"];
const isReviewWithApprovedCode = (review: ReviewType): review is ReviewsWithApprovedCodes => {
  const validReviewTypes: ValidReviewType[] = ["MdReview", "NurseReview", "PeerToPeerReview"];
  return validReviewTypes.includes(review.reviewType as ValidReviewType);
};

const isValidDate = (dateString: string) => {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
};

/**
 * initializes all px codes array. It uses serviceRequest.semanticProcedureCodes as base. Then it
 * checks for approvedSemanticProcedureCodes in the order:
 * 1. review with same currentReviewId in existingReviews
 * 2. latest completed MD, RN, or P2P review in existingReviews
 * 3. serviceRequest.approvedSemanticProcedureCodes
 * @returns all requested px codes with latest version of already approved codes according to order above
 */
const getPreviousOrExistingPxCodes = (
  serviceRequest: ServiceRequestResponse,
  existingReviews: ReviewType[] | null,
  currentReviewId: string
): ProcedureCode[] => {
  let procedureCodesApproved: ProcedureCode[] = [];
  let hasApprovedCodesFromReview = false;
  if (existingReviews) {
    const validReviewTypes: ReviewsWithApprovedCodes[] = existingReviews.filter(isReviewWithApprovedCode);
    const currentReview = validReviewTypes.find((review) => review.id === currentReviewId);
    if (currentReview && currentReview.approvedSemanticProcedureCodes?.length) {
      procedureCodesApproved = [...currentReview.approvedSemanticProcedureCodes];
      hasApprovedCodesFromReview = true;
    } else {
      const completedReviews = validReviewTypes.filter(
        (review) => review.reviewStatus === "COMPLETE" && isValidDate(review.dateCompleted || "")
      );
      const sortedReviews = completedReviews.sort((reviewA, reviewB) => {
        const dateCompletedA = new Date(reviewA.dateCompleted || "");
        const dateCompletedB = new Date(reviewB.dateCompleted || "");
        return dateCompletedB.getTime() - dateCompletedA.getTime();
      });
      const latestValidReview = sortedReviews[0];
      if (latestValidReview) {
        procedureCodesApproved = [...(latestValidReview.approvedSemanticProcedureCodes || [])];
        hasApprovedCodesFromReview = true;
      }
    }
  }
  if (serviceRequest.approvedSemanticProcedureCodes?.length && !hasApprovedCodesFromReview) {
    procedureCodesApproved = serviceRequest.approvedSemanticProcedureCodes;
  }
  const allInitPxCodes = (serviceRequest.semanticProcedureCodes || []).map((pxCode) => {
    const previouslyApprovedCode = procedureCodesApproved.find((approvedCode) => isSamePxCode(approvedCode, pxCode));
    if (previouslyApprovedCode) {
      return previouslyApprovedCode;
    } else {
      const initPx = {
        ...pxCode,
        approvedUnits: pxCode.approvedUnits || 0,
      };
      return initPx;
    }
  });
  return allInitPxCodes;
};

export const isSamePxCode = (pxCode1: ProcedureCode, pxCode2: ProcedureCode): boolean => {
  if (
    (pxCode1.groupBy === "Unclassified" && pxCode2.groupBy === "Unclassified") ||
    (!pxCode1.groupBy && !pxCode2.groupBy)
  ) {
    return pxCode1.code === pxCode2.code;
  }
  return pxCode1.code === pxCode2.code && pxCode1.groupId === pxCode2.groupId;
};

const getapprovedUnitsByClinicalService = (
  pxCodesByService: Map<string, ProcedureCode[]>,
  clinicalServices?: ClinicalService[]
): Map<string, number> => {
  const approvedUnitsByClinicalService = new Map<string, number>();
  pxCodesByService.forEach((pxCodes, serviceId) => {
    const pxClinicalService = clinicalServices?.find((clinicalService) => clinicalService.id === serviceId);
    const sumOfApprovedUnitsForService = pxCodes.reduce(
      (accumulator: number, pxCode: ProcedureCode) =>
        sumApprovedUnitsForService(accumulator, pxCode, pxClinicalService),
      0
    );
    approvedUnitsByClinicalService.set(serviceId, sumOfApprovedUnitsForService);
  });
  return approvedUnitsByClinicalService;
};

const sumAllApprovedUnits = (
  pxCodesByClinicalService: Map<string, ProcedureCode[]>,
  clinicalServices?: ClinicalService[]
): number => {
  const approvedUnitsByClinicalService = getapprovedUnitsByClinicalService(pxCodesByClinicalService, clinicalServices);
  const allApprovedUnits = Array.from(approvedUnitsByClinicalService.values()).reduce(
    (accumulator, approvedUnitForService) => accumulator + approvedUnitForService,
    0
  );
  return allApprovedUnits;
};

/**
 * This function is intended to be passed to a px code array reducer function.
 * It is expected that all pxCodes in the array belong to the same clinicalService.
 * It updates the accumulator according to pxCode.approvedUnits
 * and whether clinicalService.isUnitsOnPx is true or false. If there is no
 * clinicalService for the pxCode or if clinicalService.isUnitsOnPx is true, add
 * pxCode.approvedUnits to the accumulator. If clinicalService.isUnitsOnPx is false,
 * return the greater of pxCode.approvedUnits and accumulator.
 *
 * At the end of array reduction, @returns the max of all approved units for px codes under non unitsOnPx clinical service OR
 * the sum total of approved units for px codes that are uncategorized or under a unitsOnPx clinical service.
 */
const sumApprovedUnitsForService = (
  accumulator: number,
  pxCode: ProcedureCode,
  clinicalService?: ClinicalService
): number => {
  const pxApprovedUnits = pxCode.approvedUnits || 0;
  if (clinicalService) {
    if (clinicalService.isUnitsOnPx) {
      return accumulator + pxApprovedUnits;
    } else {
      if (pxApprovedUnits > accumulator) {
        return pxApprovedUnits;
      } else {
        return accumulator;
      }
    }
  } else {
    // UNCATEGORIZED_SERVICE
    return accumulator + pxApprovedUnits;
  }
};
