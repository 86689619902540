import React, { useCallback, useEffect, useState, Dispatch, SetStateAction, MutableRefObject, useContext } from "react";
import {
  SearchInfo,
  CopyPasteInfo,
  Attachment,
  AttachmentGuidelineTextHighlightsSnapshot,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import {
  colorsLight,
  H4,
  UserClickInfoClinicalReviewPage,
  useFeature,
  AttachmentInfo,
  HighlightStateContext,
  extractUploadId,
} from "@coherehealth/common";
import { useAuthorized } from "authorization";
import AttachmentViewer from "./AttachmentViewer";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { CircularProgress, makeStyles, styled } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import useOcrEnhancedPath from "components/AddAttachments/useOcrEnhancedPath";
import useDocSegmentation from "./useDocSegmentation";
import { createAttachmentOpenPath, parseServiceSummaryUrl } from "./util";
import { AttachmentViewerConfig, ExpandableAlert } from "@coherehealth/common";
import { useBearerToken } from "hooks/useBearerToken";

interface Props {
  serviceRequestId: string;
  attachments?: Attachment[];
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  setSearchInfos?: Dispatch<SetStateAction<SearchInfo[]>>;
  attachmentIndexOpen: number;
  previousAttachmentsExpanded?: boolean;
  setPreviousAttachmentsExpanded?: Dispatch<React.SetStateAction<boolean>>;
  currentPage: MutableRefObject<number>;
  zoomLevel: MutableRefObject<number>;
  currentRotate: MutableRefObject<number>;
  onViewAttachment: (index: number, landingPage?: number) => void;
  orderBySegmentation?: boolean;
  setCopiedAttachmentText?: Dispatch<SetStateAction<CopyPasteInfo | undefined>>;
  setAttachmentGuidelineTextHighlightsSnapshot?: Dispatch<SetStateAction<AttachmentGuidelineTextHighlightsSnapshot>>;
  isContinuationRequest?: boolean;
  attachmentsLoading?: boolean;
  attachmentsInfo: AttachmentInfo[];
  setAttachmentsInfo: React.Dispatch<React.SetStateAction<AttachmentInfo[]>>;
  id: string;
  viewerConfig?: AttachmentViewerConfig;
  serviceRequest?: ServiceRequestResponse | null;
}

const useStyles = makeStyles((theme) => {
  return {
    expandableAlertStyle: {
      padding: 12,
    },
  };
});

export default function AttachmentViewerShell({
  serviceRequestId,
  attachments,
  setUserClickInfoTracking,
  setSearchInfos,
  attachmentIndexOpen,
  previousAttachmentsExpanded,
  setPreviousAttachmentsExpanded,
  currentPage,
  zoomLevel,
  currentRotate,
  onViewAttachment,
  orderBySegmentation,
  setCopiedAttachmentText,
  setAttachmentGuidelineTextHighlightsSnapshot,
  isContinuationRequest,
  attachmentsLoading,
  attachmentsInfo,
  setAttachmentsInfo,
  id,
  viewerConfig,
  serviceRequest,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const currentAttachment = attachments?.[attachmentIndexOpen];
  let currentAttachmentOpenPath = useOcrEnhancedPath(createAttachmentOpenPath(currentAttachment), orderBySegmentation);

  // Use the /serviceSummary endpoint instead of /attachment
  if (currentAttachment && !currentAttachment?.url) {
    currentAttachmentOpenPath = parseServiceSummaryUrl(currentAttachment);
  }

  const classes = useStyles();
  const missingAttachmentInfoReasons = useFeature("missingAttachmentInfoReasons");
  const docSegmentationBetaFF = useFeature("docTypeandOrderingBeta");
  const docSegmentationBetaAuth = useAuthorized("DOC_SEGMENTATION_BETA");
  const docSegmentationGAFF = useFeature("docTypeandOrderingGeneralAvailabilty");
  const withDocSegmentedSidePanel = (docSegmentationBetaFF && docSegmentationBetaAuth) || docSegmentationGAFF;
  const [downloadError, setDownloadError] = useState<Error>();
  const [openSidePanel, setOpenSidePanel] = useState<boolean>(!!attachments);
  const { docSegmentationInfo, handleSegmentChange, savingFeedbackUpdates, segmentsUpdated } = useDocSegmentation(
    withDocSegmentedSidePanel,
    attachmentIndexOpen,
    attachments
  );

  useEffect(() => {
    setOpenSidePanel(!!attachments);
  }, [attachments]);

  const handleError = useCallback(
    (error: Error) => {
      setDownloadError(error);
      enqueueSnackbar("Error downloading attachment", {
        variant: "error",
        preventDuplicate: true,
      });
    },
    [enqueueSnackbar]
  );

  const bearerToken = useBearerToken();

  const fetchAttachment = useCallback(
    async (index: number) => {
      setDownloadError(undefined);
      if (attachments) {
        try {
          const response = await fetch(currentAttachmentOpenPath, {
            headers: {
              Authorization: bearerToken,
            },
          });

          if (response.ok) {
            const nonOcr = response.headers.get("content-disposition")?.endsWith('ml-ocrenhance.pdf"') ? false : true;
            const blob = await response.blob();
            const file = attachments[index];
            const convertedToPDF = blob.type === "application/pdf" && file.contentType !== "application/pdf";
            const fileUrl = window.URL.createObjectURL(blob);
            setAttachmentsInfo((prevAttachmentsInfo) =>
              prevAttachmentsInfo.map((attachmentInfo, index) => {
                if (index === attachmentIndexOpen) {
                  return { ...attachmentInfo, fileUrl, convertedToPDF, nonOcr };
                } else {
                  return attachmentInfo;
                }
              })
            );
          } else {
            handleError({ name: "UnexpectedResponseException", message: "Error downloading attachment" });
          }
        } catch (error) {
          if (error instanceof Error) {
            handleError(error);
          }
        }
      }
    },
    [attachmentIndexOpen, attachments, currentAttachmentOpenPath, handleError, setAttachmentsInfo, bearerToken]
  );

  useEffect(() => {
    if (attachmentsInfo?.[attachmentIndexOpen] && !attachmentsInfo?.[attachmentIndexOpen]?.fileUrl) {
      fetchAttachment(attachmentIndexOpen);
    }
  }, [attachmentIndexOpen, attachmentsInfo, fetchAttachment]);

  useEffect(() => {
    if (attachments) {
      setAttachmentGuidelineTextHighlightsSnapshot?.((prevSnapshots) => {
        return {
          ...prevSnapshots,
          numAttachments: attachments?.length,
        };
      });
    }
  }, [attachments, attachments?.length, setAttachmentGuidelineTextHighlightsSnapshot]);

  const { setAttachmentId } = useContext(HighlightStateContext);

  useEffect(() => {
    if (setAttachmentId && attachments?.length) {
      if (attachmentIndexOpen > -1) {
        setAttachmentId(extractUploadId(attachments[attachmentIndexOpen].url));
      } else {
        setAttachmentId(undefined);
      }
    }
  }, [attachmentIndexOpen, attachments, setAttachmentId]);

  const showExpandableAlertBanner = serviceRequest?.possibleAttachmentNudgeReasons?.length;
  let text = "";

  if (!!showExpandableAlertBanner) {
    const reasonText: string[] = [];
    serviceRequest.possibleAttachmentNudgeReasons?.forEach((srReason) => {
      if (srReason.reasonText) {
        reasonText.push(`"${srReason.reasonText}"`);
      }
    });

    if (reasonText.length > 1) {
      text = reasonText.join("\n\n");
    } else if (reasonText.length === 1) {
      text = reasonText[0];
    }
  }

  return (
    <div
      id="tabpanel-ATTACHMENTS"
      data-testid="tabpanel-ATTACHMENTS"
      style={{ width: "100%", height: "100%", margin: "-1px" }}
    >
      {missingAttachmentInfoReasons && !!showExpandableAlertBanner && (
        <div className={classes.expandableAlertStyle}>
          <ExpandableAlert
            severity={"info"}
            message={"Auth submitter indicated that they may be missing some info"}
            text={text}
          />
        </div>
      )}
      {attachments?.length && attachmentsInfo.length > 0 ? (
        attachmentIndexOpen !== -1 ? (
          <>
            <AttachmentViewer
              fileUrl={attachmentsInfo[attachmentIndexOpen]?.fileUrl}
              file={attachments[attachmentIndexOpen]}
              attachments={attachments}
              handleAttachmentClick={onViewAttachment}
              attachmentIndexOpen={attachmentIndexOpen}
              previousAttachmentsExpanded={previousAttachmentsExpanded}
              setPreviousAttachmentsExpanded={setPreviousAttachmentsExpanded}
              convertedToPDF={attachmentsInfo[attachmentIndexOpen].convertedToPDF}
              currentPage={currentPage}
              zoomLevel={zoomLevel}
              currentRotate={currentRotate}
              openSidePanel={openSidePanel}
              setOpenSidePanel={setOpenSidePanel}
              setUserClickInfoTracking={setUserClickInfoTracking}
              setSearchInfos={setSearchInfos}
              withDocSegmentedSidePanel={withDocSegmentedSidePanel}
              attachmentsInfo={attachmentsInfo}
              setAttachmentsInfo={setAttachmentsInfo}
              downloadError={downloadError}
              handleSegmentChange={handleSegmentChange}
              docSegmentationInfo={docSegmentationInfo}
              savingFeedbackUpdates={savingFeedbackUpdates}
              segmentsUpdated={segmentsUpdated}
              setCopiedAttachmentText={setCopiedAttachmentText}
              setAttachmentGuidelineTextHighlightsSnapshot={setAttachmentGuidelineTextHighlightsSnapshot}
              isContinuationRequest={isContinuationRequest}
              viewerConfig={viewerConfig}
              serviceRequest={serviceRequest}
            />
          </>
        ) : null
      ) : (
        <CenteredTextContainer>
          {attachmentsLoading ? (
            <CircularProgress size={144} />
          ) : (
            <H4 style={{ color: colorsLight.font.light }}>{`There are no ${
              viewerConfig?.entityLabel || "attachments"
            } for this service request.`}</H4>
          )}
          {viewerConfig?.closeDialogFunction && (
            <div style={{ position: "absolute", top: "8px", right: "8px" }}>
              <IconButton onClick={viewerConfig.closeDialogFunction} aria-label="close">
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </CenteredTextContainer>
      )}
    </div>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const CenteredTextContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100vh - 200px)",
});
