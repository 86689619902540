import { useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";
import { stringify as stringifyQueryString, parse as parseQueryString } from "qs";
import { debounce } from "@material-ui/core";

/*
When queryParams are changed, update the query parameters in the url
 */
export function useSyncQueryParamsToPath<T>(queryParams: T) {
  const location = useLocation();
  const navigate = useNavigate();
  const debouncedReplace = useMemo(() => debounce(navigate, 300), [navigate]);
  useDeepCompareEffect(() => {
    debouncedReplace(`${location.pathname}?${stringifyQueryString(queryParams)}`, { replace: true });

    return () => {
      // This ensures that if we navigate away, there is no re-attempt to navigate
      debouncedReplace.clear();
    };
  }, [location.pathname, queryParams, debouncedReplace]);
}

/*
Parse query parameters from the url
 */
export function useParsedQueryString() {
  const location = useLocation();
  const qs = useMemo(
    () => (location.search && parseQueryString(location.search.replace(/^[?]/, ""))) || {},
    [location.search]
  );
  return qs;
}

export const initialQueryStringFromPath = () =>
  (window.location.search && parseQueryString(window.location.search.replace(/^[?]/, ""))) || {};
