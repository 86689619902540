import { InformativeModal, TertiaryButton, useFeature } from "@coherehealth/common";
import { Divider } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useAuthorized, Permissions } from "authorization";
import { useSuccesfulAuthorizationTransferModalStyles } from "./SuccessfulAuthorizationTransferModalStyles";

interface SuccessfulAuthorizationTransferModalProps {
  onClose: () => void;
  open: boolean;
}

export function SuccessfulAuthorizationTransferModal(props: SuccessfulAuthorizationTransferModalProps): JSX.Element {
  const { open, onClose } = props;
  const classes = useSuccesfulAuthorizationTransferModalStyles();

  // Define the validations required to enable or block users from submitting the
  // authorization transfer to the server. Note: The same set of validations are
  // being performed in the server side.
  const authorizationTransferEnabled = useFeature("authorizationTransfer");
  const hasTransferAuthPerms = useAuthorized(Permissions.TRANSFER_AUTHORIZATION);
  const isAuthorizationTransferEnabledForUser = authorizationTransferEnabled && hasTransferAuthPerms;

  const GreenCheckmarkIcon = <CheckCircleIcon className={classes.sucessIcon} />;

  // If the user is not authorized to see this feature, then render a blank component.
  if (!isAuthorizationTransferEnabledForUser) {
    return <></>;
  }

  return (
    <InformativeModal
      className={classes.modal}
      open={open}
      onClose={onClose}
      headerText={"Authorization successfully transferred"}
      icon={GreenCheckmarkIcon}
      data-testid="successful-authorization-transfer-modal"
    >
      <Divider className={classes.divider} />
      <TertiaryButton onClick={onClose}>Close</TertiaryButton>
    </InformativeModal>
  );
}
