import React, { useState, useEffect, useCallback, useMemo } from "react";

// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {
  Body1,
  Alert,
  useFeature,
  OrderedPolicyTypes,
  GuidelineIndicationsReview,
  ToggleResults,
} from "@coherehealth/common";
import {
  ReviewType,
  FilterMatchMap,
  Guideline,
  GuidelineType,
  Indication,
  ServiceRequestResponse,
  useGetAttachmentHighlights,
  NurseReview,
} from "@coherehealth/core-platform-api";
import IndicationsSection from "./IndicationsSection";
import CareWebQILink from "./CareWebQILink";
import SelectableGuidelines from "./SelectableGuidelines";
import { useReviewsDispatch } from "components/ClinicalReview/reviewUtils/useReviews";
import { useAuthorized } from "authorization";
import { tabProps } from "components/ClinicalReview/reviewUtils/utils";

const StyledTabs = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: "100%",
    overflow: "visible",
  },
  indicator: {
    backgroundColor: theme.palette.text.primary,
  },
}))(Tabs);

interface GuidelineProps {
  review: ReviewType;
  indicationSectionNewDesign?: boolean;
  newCrrDisplayPage?: boolean;
  serviceRequest?: ServiceRequestResponse | null;
  setSelectedGuidelines?: (guidelineIds: string[]) => void;
  expandable?: boolean;
  openAttachment?: (attachmentId: string) => void;
  authorizationReviewView?: boolean;
  id: string;
}

export default function GuidelineReview({
  review,
  indicationSectionNewDesign,
  newCrrDisplayPage = false,
  serviceRequest,
  setSelectedGuidelines,
  expandable,
  openAttachment,
  authorizationReviewView,
  id,
}: GuidelineProps) {
  return (
    <GuidelineReviewSection
      id={id}
      review={review}
      indicationSectionNewDesign={indicationSectionNewDesign}
      newCrrDisplayPage={newCrrDisplayPage}
      serviceRequest={serviceRequest}
      setSelectedGuidelines={setSelectedGuidelines}
      expandable={expandable}
      openAttachment={openAttachment}
      authorizationReviewView={authorizationReviewView}
    />
  );
}

interface DisplayProps {
  review?: ReviewType;
  indicationSectionNewDesign?: boolean;
  newCrrDisplayPage?: boolean;
  serviceRequest?: ServiceRequestResponse | null;
  setSelectedGuidelines?: (guidelineIds: string[]) => void;
  expandable?: boolean;
  openAttachment?: (attachmentId: string) => void;
  authorizationReviewView?: boolean;
  id: string;
}

export function GuidelineReviewSection({
  review,
  indicationSectionNewDesign,
  newCrrDisplayPage,
  serviceRequest,
  setSelectedGuidelines,
  expandable,
  openAttachment,
  authorizationReviewView,
  id,
}: DisplayProps) {
  const [tabIndex, setTabIndex] = useState<string>("0");
  const classes = useStyles();
  const cwqiReviewGuidelinesDemoView = useFeature("cwqiReviewGuidelinesDemoView");
  const showSelectableGuidelines = useFeature("showSelectableGuidelines");
  if (!review) {
    return null;
  }
  const guidelinePolicyTypes: GuidelineType[] =
    review.guidelines?.map((guidelinePolicyMap) => guidelinePolicyMap.policyType) || [];

  // NOTE: this enforces the order of keys in OrderedPolicyTypes
  const orderedPolicyTypes: GuidelineType[] = (Object.keys(OrderedPolicyTypes) as GuidelineType[]).filter(
    (policyType) => guidelinePolicyTypes.includes(policyType)
  );

  const haveCriteria = orderedPolicyTypes.length !== 0;
  const isReadOnly = review.reviewStatus === "COMPLETE" || review.reviewStatus === "DISCARDED";

  return (
    <div id={id}>
      {!haveCriteria && newCrrDisplayPage && serviceRequest && review && (
        <FullWidthDiv style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <FullWidthDiv>
            <CenterStyleDiv>
              <Body1>There are no in-app guidelines for this Service Request.</Body1>
            </CenterStyleDiv>
            {!cwqiReviewGuidelinesDemoView && (
              <CenterStyleDiv>
                <CareWebQILink hasInAppGuidelines={false} serviceRequestId={serviceRequest.id} review={review} />
              </CenterStyleDiv>
            )}
          </FullWidthDiv>
        </FullWidthDiv>
      )}
      {haveCriteria &&
        (showSelectableGuidelines && setSelectedGuidelines ? (
          <SelectableGuidelines
            orderedPolicyTypes={orderedPolicyTypes}
            newCrrDisplayPage={newCrrDisplayPage}
            serviceRequestId={serviceRequest?.id}
            healthPlanName={serviceRequest?.healthPlanName}
            review={review}
            selectedGuidelines={review.selectedGuidelineIds || []}
            setSelectedGuidelines={setSelectedGuidelines}
            openAttachment={openAttachment}
            serviceRequest={serviceRequest}
          />
        ) : (
          <>
            <Box display="flex">
              <StyledTabs
                value={tabIndex}
                onChange={(_, index) => {
                  setTabIndex(index);
                }}
                aria-label="guideline tabs"
                style={
                  isReadOnly
                    ? { paddingTop: 24, margin: authorizationReviewView ? "0px" : "0px -24px" }
                    : { margin: "0px -24px" }
                }
              >
                {orderedPolicyTypes.map((policyType, index) => (
                  <Tab
                    label={
                      policyType === "PAYOR"
                        ? serviceRequest
                          ? `${serviceRequest?.healthPlanName} Guidelines`
                          : "Humana Guidelines"
                        : OrderedPolicyTypes[policyType]
                    }
                    {...tabProps(`${index}`)}
                    key={`GuideLineTabKey${index}`}
                    id="payor-guideline-tab"
                    className={classes.panelTab}
                  />
                ))}
              </StyledTabs>
              {newCrrDisplayPage && serviceRequest && !cwqiReviewGuidelinesDemoView && (
                <FullWidthDiv>
                  <CareWebQITabDiv>
                    <CareWebQILink hasInAppGuidelines={true} serviceRequestId={serviceRequest.id} review={review} />
                  </CareWebQITabDiv>
                </FullWidthDiv>
              )}
            </Box>
            <div>
              {orderedPolicyTypes.map((policyType, index) => {
                const guidelinePolicyMap = review.guidelines?.find(
                  (guidelinePolicyMap) => guidelinePolicyMap.policyType === policyType
                );
                return (
                  <PolicyGuidelinesDisplay
                    key={`GuideLineKey${index}`}
                    review={review}
                    guidelineList={guidelinePolicyMap?.guidelines ?? []}
                    matchList={guidelinePolicyMap?.guidelineMatchMap ?? []}
                    checkedIndications={guidelinePolicyMap?.checkedIndications || []}
                    unmetIndications={guidelinePolicyMap?.unmetIndications || []}
                    value={tabIndex}
                    index={index}
                    indicationSectionNewDesign={indicationSectionNewDesign}
                    expandable={expandable}
                    serviceRequest={serviceRequest}
                  />
                );
              })}
            </div>
          </>
        ))}
    </div>
  );
}

interface GuidelinesDisplayProps {
  review: ReviewType;
  guidelineList: Guideline[];
  matchList: FilterMatchMap[];
  value: string;
  index: number;
  indicationSectionNewDesign?: boolean;
  checkedIndications: Indication[];
  unmetIndications: Indication[];
  expandable?: boolean;
  serviceRequest?: ServiceRequestResponse | null;
}

const PolicyGuidelinesDisplay = ({
  guidelineList,
  review,
  matchList,
  value,
  index,
  indicationSectionNewDesign,
  checkedIndications,
  unmetIndications,
  expandable,
  serviceRequest,
}: GuidelinesDisplayProps) => {
  return (
    <CriteriaBody
      role="tabpanel"
      hidden={value !== index.toString()}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index.toString() &&
        guidelineList.map((guideline: Guideline, _index) => {
          return (
            <React.Fragment key={guideline.id}>
              {_index !== 0 && expandable && <GuidelineDivider />}
              {review.reviewStatus === "DRAFT" ? (
                <div key={`${guideline.id}${_index}`}>
                  <GuidelineMatchDisplay
                    guideline={guideline}
                    matchList={matchList}
                    review={review}
                    indicationSectionNewDesign={indicationSectionNewDesign}
                    checkedIndications={checkedIndications}
                    unmetIndications={unmetIndications}
                    expandable={expandable}
                    serviceRequest={serviceRequest}
                  />
                </div>
              ) : (
                <div key={`${guideline.id}${_index}`}>
                  <GuidelineMatchDisplayReadonly
                    guideline={guideline}
                    matchList={matchList}
                    review={review}
                    indicationSectionNewDesign={indicationSectionNewDesign}
                    checkedIndications={checkedIndications}
                    unmetIndications={unmetIndications}
                    expandable={expandable}
                    serviceRequest={serviceRequest}
                  />
                </div>
              )}
            </React.Fragment>
          );
        })}
    </CriteriaBody>
  );
};

interface MatchDisplayProps {
  guideline: Guideline;
  review: ReviewType;
  matchList: FilterMatchMap[];
  indicationSectionNewDesign?: boolean;
  checkedIndications: Indication[];
  unmetIndications: Indication[];
  expandable?: boolean;
  policyTitle?: string;
  openAttachment?: (attachmentId: string) => void;
  selectableGuidelines?: boolean;
  serviceRequest?: ServiceRequestResponse | null;
  multiCoverageReviewsEnabled?: boolean;
}

export const GuidelineMatchDisplay = ({
  guideline,
  matchList,
  review,
  indicationSectionNewDesign,
  checkedIndications,
  unmetIndications,
  expandable,
  policyTitle,
  openAttachment,
  serviceRequest,
  multiCoverageReviewsEnabled,
}: MatchDisplayProps) => {
  const filterMatchmap: FilterMatchMap | undefined = matchList.find(
    (map: FilterMatchMap) => map.guidelineId === guideline.id
  );
  const [criteriaMet, setCriteriaMet] = useState<boolean>(false);
  const newDesignIndicationSectionFeatureFlag = useFeature("showNewDesignIndicationSection");
  const reviewsDispatch = useReviewsDispatch();
  const checkCriteriaMet = useMemo(
    () => guideline.indications && review.reviewStatus === "DRAFT",
    [guideline.indications, review.reviewStatus]
  );

  const onCheckIndication = useCallback(
    (
      checkedIndication: Indication,
      toggleFunc: (metIndications: Indication[], unmetIndications: Indication[], met: boolean) => ToggleResults,
      met: boolean
    ) => {
      reviewsDispatch?.({
        type: "CHECK_INDICATION",
        reviewId: review.id,
        payload: {
          checkedIndication,
          toggleFunc,
          indicationPolicyType: filterMatchmap?.policyType,
          met,
          guidelineId: guideline.id || "",
        },
      });
    },
    [reviewsDispatch, review.id, filterMatchmap?.policyType, guideline.id]
  );
  const isCPP = review.reviewType === "NurseReview" && (review as NurseReview).isClinicalPreprocessingReview;
  return (
    <GuidelineIndicationsReview
      guideline={guideline}
      filterMatchMap={filterMatchmap}
      checkedIndications={checkedIndications}
      unmetIndications={unmetIndications}
      readonly={false}
      onCheckIndication={onCheckIndication}
      useOldIndicationSectionDesign={!indicationSectionNewDesign && !newDesignIndicationSectionFeatureFlag}
      hideHeadlessIndications
      expandable={expandable}
      policyTitle={policyTitle}
      openAttachment={openAttachment}
      isCPP={isCPP}
      review={review}
      serviceRequest={serviceRequest}
      multiCoverageReviewsEnabled={multiCoverageReviewsEnabled}
    >
      {!indicationSectionNewDesign && !newDesignIndicationSectionFeatureFlag && (
        <>
          {checkCriteriaMet ? showBanner(criteriaMet) : ""}
          <IndicationsSection guideline={guideline} review={review} setCriteriaMet={setCriteriaMet} />
        </>
      )}
    </GuidelineIndicationsReview>
  );
};

const GuidelineMatchDisplayReadonly = ({
  guideline,
  matchList,
  review,
  indicationSectionNewDesign,
  checkedIndications,
  unmetIndications,
  expandable,
  policyTitle,
  serviceRequest,
}: MatchDisplayProps) => {
  const filterMatchmap: FilterMatchMap | undefined = matchList.find(
    (map: FilterMatchMap) => map.guidelineId === guideline.id
  );
  const [criteriaMet, setCriteriaMet] = useState<boolean>(false);
  const newDesignIndicationSectionFeatureFlag = useFeature("showNewDesignIndicationSection");
  const canUseCrrGeneral = useAuthorized("USE_CLINICAL_REVIEW_REDESIGN_GENERAL");
  const checkCriteriaMet = guideline.indications && review.reviewStatus === "DRAFT";

  const { data: attachmentHighlightsForReview, refetch: getAttachmentHighlightsForReview } = useGetAttachmentHighlights(
    {
      queryParams: {
        reviewId: review?.id,
        serviceRequestId: review?.serviceRequest?.id,
        guidelineId: guideline?.id,
      },
      lazy: true,
    }
  );

  useEffect(() => {
    if (canUseCrrGeneral) {
      getAttachmentHighlightsForReview();
    }
  }, [canUseCrrGeneral, getAttachmentHighlightsForReview]);

  return (
    <GuidelineIndicationsReview
      guideline={guideline}
      filterMatchMap={filterMatchmap}
      checkedIndications={checkedIndications}
      unmetIndications={unmetIndications}
      readonly={true}
      useOldIndicationSectionDesign={!indicationSectionNewDesign && !newDesignIndicationSectionFeatureFlag}
      hideHeadlessIndications
      expandable={expandable}
      policyTitle={filterMatchmap?.policyTitle}
      selected={review.selectedGuidelineIds?.includes(guideline.id || "")}
      viewOnlyHighlights={attachmentHighlightsForReview}
      review={review}
      serviceRequest={serviceRequest}
    >
      {!indicationSectionNewDesign && !newDesignIndicationSectionFeatureFlag && (
        <>
          {checkCriteriaMet ? showBanner(criteriaMet) : ""}
          <IndicationsSection guideline={guideline} review={review} setCriteriaMet={setCriteriaMet} />
        </>
      )}
    </GuidelineIndicationsReview>
  );
};

const useStyles = makeStyles(() => ({
  panelTab: {
    "&:hover span": {
      opacity: 0.7,
    },
  },
}));

const showBanner = (criteriaMet: boolean) => {
  return criteriaMet ? (
    <Alert message="Guideline criteria met" />
  ) : (
    <Alert severity="error" message="Guideline criteria not met" />
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CriteriaBody = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 0),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CenterStyleDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const FullWidthDiv = styled("div")({
  width: "100%",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CareWebQITabDiv = styled("div")(({ theme }) => ({
  justifyContent: "flex-end",
  height: "100%",
  display: "flex",
  alignItems: "center",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const GuidelineDivider = styled(Divider)(({ theme }) => ({
  marginLeft: -theme.spacing(1),
}));
