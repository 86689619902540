import { useFeature } from "@coherehealth/common";

export const useClinicalReviewFeatureFlags = () => {
  const updatedClinicalReviewNudges = useFeature("updatedClinicalReviewNudges");
  const caseEventHistoryGA = useFeature("caseEventHistoryGA");
  const genericNotes = useFeature("genericNotes");
  const claimHistoryFeature = useFeature("claimHistoryFeature");

  return {
    updatedClinicalReviewNudges,
    caseEventHistoryGA,
    genericNotes,
    claimHistoryFeature,
  };
};
