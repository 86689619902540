import { useFeature, NotFoundPage } from "@coherehealth/common";
import { useAuthorized } from "authorization";
import { AddNewPatientForm } from "./AddPatientForm";

export default function AddNewPatientFormPage() {
  const temporaryMemberAuthsEnabled = useFeature("temporaryMemberAuths");
  const canAddPlaceholderMember = useAuthorized("CREATE_PLACEHOLDER_PATIENT");

  if (canAddPlaceholderMember && temporaryMemberAuthsEnabled) {
    return <AddNewPatientForm />;
  } else {
    return <NotFoundPage />;
  }
}
