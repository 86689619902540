import { Dispatch, SetStateAction, useState } from "react";
import { Patient, Coverage, ProcedureCode, ClinicalService } from "@coherehealth/core-platform-api";
import { Card, H6, Chip, Caption, colorsLight, InlineButton, Body3, useFeature, Checkbox } from "@coherehealth/common";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ApprovedServiceRequestImage from "components/images/ApprovedServiceRequestImage";
import GetAppIcon from "@material-ui/icons/GetApp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ServiceRequestNotRequired from "components/images/ServiceRequestNotRequired";
import ClipboardMissingInfo from "components/images/ClipboardMissingInfo";
import NonPalPDFProvider from "components/ServiceRequest/ServiceRequestForm/NonPalDocument/NonPalPDFProvider";
import DocumentViewer from "components/DocumentViewer";
import { Box, Button, Dialog } from "@material-ui/core";
import { NonCohereCodes } from "../common";
import useSearchAndLocation from "hooks/useSearchAndLocation";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";

type PriorAuthResultCardType = "AUTH_REQUIRED_BY_COHERE" | "AUTH_REQUIRED_BY_VENDOR" | "AUTH_NOT_REQUIRED";

interface Props {
  procedureCodes: ProcedureCode[];
  serviceType?: ClinicalService;
  nonCohereCodes?: NonCohereCodes[];
  type: PriorAuthResultCardType;
  patient: Patient | undefined;
  primaryActiveCoverage?: Coverage | undefined;
  userSelectedNonPalCode?: boolean;
  setUserSelectedNonPalCode?: (value: boolean) => void;
  setOpenNonPalConfirmationModal?: Dispatch<SetStateAction<boolean>>;
  isUsedInPACheckRedesign?: boolean;
  nonPalSubmissionIsEnabled?: boolean;
  children?: JSX.Element;
}

export default function PriorAuthResultCard({
  procedureCodes,
  serviceType,
  nonCohereCodes,
  type,
  patient,
  primaryActiveCoverage,
  userSelectedNonPalCode,
  setUserSelectedNonPalCode,
  setOpenNonPalConfirmationModal,
  isUsedInPACheckRedesign,
  nonPalSubmissionIsEnabled,
  children,
}: Props) {
  const { location, search } = useSearchAndLocation();
  const [nonPalPDFOpen, setNonPalPDFOpen] = useState(false);
  const [nonPalExpandCarrotOpen, setNonPalExpandCarrotOpen] = useState(userSelectedNonPalCode || false);
  const { spacing } = useTheme();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  const classes = useStyles({ isRotated: nonPalExpandCarrotOpen });
  const authSubmissionVendorOnPalCheckEnabled = useFeature("authSubmissionVendorOnPalCheckEnabled");

  type ChipType = "default" | "success" | "warning" | "error" | "disabled";

  type PriorAuthResultCardProps = {
    chipType: ChipType;
    icon: JSX.Element;
    title: string;
  };

  const PriorAuthResultCardConfig: Record<PriorAuthResultCardType, PriorAuthResultCardProps> = {
    AUTH_REQUIRED_BY_COHERE: {
      chipType: "success",
      icon: <ApprovedServiceRequestImage />,
      title: authSubmissionVendorOnPalCheckEnabled ? "Requires submission through Cohere" : "Requires submission",
    },
    AUTH_REQUIRED_BY_VENDOR: {
      chipType: "warning",
      icon: <ClipboardMissingInfo />,
      title: "Requires submission through another organization",
    },
    AUTH_NOT_REQUIRED: {
      chipType: "disabled",
      icon: <ServiceRequestNotRequired />,
      title: "Doesn't require submission in most cases",
    },
  };
  const priorAuthResultCardConfig = PriorAuthResultCardConfig[type];

  const checkNonPalReason = () => {
    if (userSelectedNonPalCode) {
      setUserSelectedNonPalCode?.(false);
    } else {
      setOpenNonPalConfirmationModal?.(true);
    }
  };

  return (
    <>
      {type === "AUTH_NOT_REQUIRED" && isUsedInPACheckRedesign && (
        <div className={classes.alertContainer}>
          {children && <>{children}</>}
          <div className={classes.paCheckRedesignButton}>
            <InlineButton
              style={{ minWidth: "25px" }}
              className={classes.inlineButton}
              startIcon={<GetAppIcon />}
              onClick={() => setNonPalPDFOpen(true)}
            >
              <Box component={"span"} display={{ xs: "none", sm: "none", md: "block" }} style={{ paddingLeft: "8px" }}>
                {isFaxAuthBuilderFlow ? "PDF" : "Download PDF"}
              </Box>
              <Box component={"span"} display={{ xs: "none", sm: "block", md: "none" }} style={{ paddingLeft: "8px" }}>
                {"PDF"}
              </Box>
            </InlineButton>
            <Button
              aria-controls=""
              aria-haspopup="true"
              className={classes.carrotIcon}
              onClick={() => {
                setNonPalExpandCarrotOpen(!nonPalExpandCarrotOpen);
              }}
            >
              <ExpandMoreIcon className={classes.expandIcon} />
            </Button>
          </div>
        </div>
      )}
      <Card
        className={isUsedInPACheckRedesign ? classes.noneBoxShadow : ""}
        style={{
          backgroundColor:
            type !== "AUTH_NOT_REQUIRED" || isUsedInPACheckRedesign
              ? colorsLight.background.card
              : colorsLight.action.selected,
          marginBottom: isUsedInPACheckRedesign ? 0 : 32,
          paddingTop: isUsedInPACheckRedesign ? 0 : 32,
          paddingBottom: type === "AUTH_NOT_REQUIRED" && nonPalSubmissionIsEnabled ? 0 : undefined,
          marginLeft: type === "AUTH_NOT_REQUIRED" && isUsedInPACheckRedesign ? -16 : 0,
        }}
      >
        <div className={classes.card}>
          <div>{isUsedInPACheckRedesign ?? priorAuthResultCardConfig.icon}</div>
          <div className={isUsedInPACheckRedesign ? "" : classes.textContainer}>
            {isUsedInPACheckRedesign ?? <H6 className={classes.secondaryH6}>{priorAuthResultCardConfig.title}</H6>}
            <div className={classes.chipContainer}>
              {!isUsedInPACheckRedesign &&
                procedureCodes.length > 0 &&
                procedureCodes.map((code) => (
                  <div className={classes.chipAndLabel} key={code.code}>
                    <Chip label={code?.code} type={priorAuthResultCardConfig.chipType} size="small" dataPublic={true} />
                    <div className={classes.pxCodeDescription}>
                      <Caption className={classes.caption}>{code?.description}</Caption>
                    </div>
                  </div>
                ))}
              {nonCohereCodes &&
                nonCohereCodes.length > 0 &&
                type === "AUTH_REQUIRED_BY_VENDOR" &&
                nonCohereCodes.map((codesAndVendor, index) => (
                  <div className={classes.vendorChipAndLabel}>
                    <div
                      className={index === 0 ? classes.vendorLabel : classes.chipAndVendor}
                      key={codesAndVendor.authSubmissionVendor}
                    >
                      <Body3>
                        {codesAndVendor?.authSubmissionVendor === "Unknown Auth Submission Vendor"
                          ? "Organization Unknown"
                          : codesAndVendor?.authSubmissionVendor}
                      </Body3>
                    </div>
                    <div>
                      {codesAndVendor?.procedureCode.map((code) => (
                        <div className={classes.chipAndLabel} key={code.code}>
                          <Chip
                            label={code?.code}
                            type={priorAuthResultCardConfig.chipType}
                            size="small"
                            dataPublic={true}
                          />
                          <div className={classes.pxCodeDescription}>
                            <Caption className={classes.caption}>{code?.description}</Caption>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              {serviceType && (
                <div className={classes.chipAndLabel}>
                  <Chip
                    label={serviceType?.name}
                    type={priorAuthResultCardConfig.chipType}
                    size="small"
                    dataPublic={true}
                  />
                  <Caption className={classes.caption}>{serviceType?.description}</Caption>
                </div>
              )}
            </div>
          </div>
          {type === "AUTH_NOT_REQUIRED" && !isUsedInPACheckRedesign && (
            <div className={classes.button}>
              <InlineButton startIcon={<GetAppIcon />} onClick={() => setNonPalPDFOpen(true)}>
                <Box component={"span"} display={{ xs: "none", sm: "none", md: "block" }}>
                  {"Download PDF"}
                </Box>
                <Box component={"span"} display={{ xs: "none", sm: "block", md: "none" }}>
                  {"PDF"}
                </Box>
              </InlineButton>
            </div>
          )}
        </div>
        {type === "AUTH_NOT_REQUIRED" && nonPalSubmissionIsEnabled && (
          <div className={classes.nonPalCheckbox}>
            <Checkbox
              onChange={checkNonPalReason}
              checked={userSelectedNonPalCode}
              label={`I have a qualifying reason to include ${procedureCodes.length > 1 ? "these codes" : "this code"}`}
            />
          </div>
        )}
        <div className={classes.chipContainer}>
          {isUsedInPACheckRedesign &&
            nonPalExpandCarrotOpen &&
            !userSelectedNonPalCode &&
            procedureCodes.length > 0 &&
            procedureCodes.map((code) => (
              <div className={classes.chipAndLabel} key={code.code}>
                <Chip label={code?.code} type={priorAuthResultCardConfig.chipType} size="small" dataPublic={true} />
                <div className={classes.pxCodeDescription}>
                  <Caption className={classes.caption}>{code?.description}</Caption>
                </div>
              </div>
            ))}
        </div>
        {!userSelectedNonPalCode &&
          isUsedInPACheckRedesign &&
          !nonPalExpandCarrotOpen &&
          procedureCodes.map((cpt) => (
            <Chip label={cpt.code} type="disabled" style={{ marginLeft: spacing(2) }} size="small" dataPublic={true} />
          ))}
      </Card>
      <Dialog fullScreen open={nonPalPDFOpen} onClose={() => setNonPalPDFOpen(false)}>
        <NonPalPDFProvider
          patient={patient}
          nonPalProcedureCodes={procedureCodes}
          coverage={primaryActiveCoverage}
          nonPalSubmissionEnabled={nonPalSubmissionIsEnabled}
        >
          {({ url, loading }) => (
            <DocumentViewer
              documentInfo={{
                name: `NonPalCodes_${patient?.memberId}.pdf`,
                contentType: "application/pdf",
              }}
              loading={loading}
              url={url || undefined}
              handleClose={() => setNonPalPDFOpen(false)}
              canDelete={false}
              location={location}
              search={search}
            />
          )}
        </NonPalPDFProvider>
      </Dialog>
    </>
  );
}

type StyleProps = {
  isRotated: boolean;
};

const useStyles = makeStyles((theme) => ({
  noneBoxShadow: {
    boxShadow: "none",
  },
  secondaryH6: {
    color: colorsLight.font.secondary,
    marginTop: "8px",
    marginBottom: "12px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "16px",
    width: "100%",
  },
  chipContainer: {
    display: "flex",
    flexDirection: "column",
  },
  chipAndLabel: {
    display: "flex",
    paddingTop: "8px",
  },
  card: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  caption: {
    marginLeft: "8px",
  },
  button: {
    display: "flex",
    minInlineSize: "fit-content",
    flexDirection: "row",
    alignItems: "center",
  },
  vendorLabel: {
    alignItems: "baseline",
    color: colorsLight.font.secondary,
  },
  chipAndVendor: {
    paddingTop: "12px",
    alignItems: "baseline",
    color: colorsLight.font.secondary,
  },
  vendorChipAndLabel: {
    alignItems: "baseline",
    display: "flex",
    flexDirection: "column",
  },
  pxCodeDescription: {
    minHeight: "24px",
    alignItems: "center",
    display: "flex",
  },
  nonPalCheckbox: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  alertContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  paCheckRedesignButton: {
    display: "flex",
    justifyContent: "center",
  },
  expandIcon: {
    color: theme.palette.primary.main,
    transition: theme.transitions.create("transform"),
    transform: (props: StyleProps) => (props.isRotated ? "rotate(180deg)" : "rotate(0deg)"),
    "&:hover, &:focus": {
      boxShadow: "none",
      backgroundColor: colorsLight.action.hoverBackground,
    },
  },
  carrotIcon: {
    minWidth: "40px",
    paddingLeft: theme.spacing(2),
    "&:hover, &:focus": {
      boxShadow: "none",
      backgroundColor: "transparent",
    },
  },
  inlineButton: {
    "& .MuiButton-startIcon": {
      marginRight: 0,
    },
  },
}));
