import React from "react";

import { Patient } from "@coherehealth/core-platform-api";
import { makeStyles } from "@material-ui/core/styles";
import { H4, SecondaryButton, Subtitle2 } from "@coherehealth/common";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import PatientSearchResultCard from "common/PatientSearchCard/PatientSearchResultCard";

interface Props {
  createFaxNotice: () => void;
  hideResults: boolean;
  onPatientSelect: (patient: Patient) => void;
  onTransferAuth?: (event: React.MouseEvent, receiverPatientId: string) => void;
  patients?: Patient[];
  patientSelectLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  searchResultsContainer: {
    marginBottom: theme.spacing(2),
    opacity: 1,
    transition: theme.transitions.create("opacity"),
    "&.hideResults": {
      opacity: 0,
    },
    flex: 1,
  },
  patientSearchResultCard: {
    alignItems: "center",
    border: `1px solid ${theme.palette.divider}`,
    minHeight: 100,
  },
  patientResultsDivider: {
    margin: theme.spacing(4, -3),
  },
}));

export default function PatientResultsList({
  createFaxNotice,
  hideResults,
  onPatientSelect,
  onTransferAuth,
  patients,
  patientSelectLoading,
}: Props) {
  const classes = useStyles();

  return (
    <div className={`${classes.searchResultsContainer}${hideResults ? " hideResults" : ""}`}>
      <Divider className={classes.patientResultsDivider} />
      {!patients || patients.length === 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <H4>We were not able to find any patients based on this information</H4>
          </Grid>
          <Grid item xs={12}>
            <Subtitle2>Check your information and search again, or send a fax notice back to the provider</Subtitle2>
          </Grid>
          <Grid item xs={12}>
            <SecondaryButton fullWidth onClick={createFaxNotice} disabled={hideResults}>
              Create fax notice
            </SecondaryButton>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} className={classes.searchResultsContainer}>
          {patients.map((patient) => (
            <Grid item xs={12} key={patient.id}>
              <PatientSearchResultCard
                key={patient.id}
                cardProps={{ className: classes.patientSearchResultCard }}
                onTransferAuth={onTransferAuth}
                patient={patient}
                selectOnClick={onPatientSelect}
                selectActionLoading={patientSelectLoading}
                showCoverageEndDate
              />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}
