import { useEffect, useContext } from "react";
import { Attachment, ReviewType, useGetLatestAttachmentHighlightsForSR } from "@coherehealth/core-platform-api";
import { useFeature, HighlightStateContext } from "@coherehealth/common";
import { useAuthorized } from "authorization";

export default function useFetchAttachmentHighlights(
  currentReview?: ReviewType,
  attachments?: Attachment[],
  hideUserFeedbackHighlights?: boolean
) {
  const isAuthorized = useAuthorized("EDIT_TEXT_HIGHLIGHTS");
  const canUseClinicalReviewRedesign = useAuthorized("USE_CLINICAL_REVIEW_REDESIGN_GENERAL");
  const mlHighlights = useFeature("MLPredictionHighlights");
  const { initializeHighlights, attachmentId, selectedGuideline, readOnlySelectedGuideline, isInitialized } =
    useContext(HighlightStateContext);
  const { refetch: getAttachmentHighlightsForReview } = useGetLatestAttachmentHighlightsForSR({
    reviewId: currentReview?.id || "",
    lazy: true,
  });

  useEffect(() => {
    const fetchHighlights = async () => {
      if (
        isAuthorized &&
        canUseClinicalReviewRedesign &&
        currentReview?.guidelines &&
        attachments &&
        isInitialized &&
        !isInitialized() &&
        (attachmentId || selectedGuideline || readOnlySelectedGuideline)
      ) {
        getAttachmentHighlightsForReview().then((latestReviewHighlight) =>
          initializeHighlights?.(
            latestReviewHighlight,
            currentReview?.guidelines!,
            attachments || [],
            currentReview?.serviceRequest?.id || "",
            currentReview?.id,
            mlHighlights,
            hideUserFeedbackHighlights ?? false
          )
        );
      }
    };
    fetchHighlights();
  }, [
    currentReview?.guidelines,
    initializeHighlights,
    attachments,
    currentReview?.id,
    mlHighlights,
    canUseClinicalReviewRedesign,
    attachmentId,
    selectedGuideline,
    readOnlySelectedGuideline,
    isInitialized,
    getAttachmentHighlightsForReview,
    isAuthorized,
    currentReview?.serviceRequest?.id,
    hideUserFeedbackHighlights,
  ]);
}
