import React from "react";
import { GetDataError } from "restful-react";

import { Subtitle1 } from "@coherehealth/common";
import { Patient } from "@coherehealth/core-platform-api";
import Grid from "@material-ui/core/Grid";
import PatientSearchResultCard from "common/PatientSearchCard/PatientSearchResultCard";

import EmptyResultsPatientLookup from "../EmptyResultsPatientLookup";
import { generatePath } from "react-router-dom";
import routes from "routes";
import { AuthorizationTransferProps } from "./PatientSelectionModal";

interface IProps {
  authorizationTransfer?: AuthorizationTransferProps;
  error: GetDataError<unknown> | null;
  onTransferAuth?: (event: React.MouseEvent, receiverPatientId: string) => void;
  patients: Patient[] | null;
  showSummaryLink?: boolean;
  showCoverageEndDate?: boolean;
}

function PatientSearchResult(props: IProps) {
  const { authorizationTransfer, error, onTransferAuth, patients, showSummaryLink, showCoverageEndDate } = props;
  return (
    <>
      {error ? (
        <p>{error.message}</p>
      ) : !patients || patients.length === 0 ? (
        <EmptyResultsPatientLookup />
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Subtitle1 color="textSecondary">
              {patients.length > 1 ? `${patients.length} results` : "1 result"}
            </Subtitle1>
          </Grid>
          {patients.map((patient) => (
            <Grid item xs={12} key={patient.id}>
              <PatientSearchResultCard
                authorizationTransfer={authorizationTransfer}
                onTransferAuth={onTransferAuth}
                patient={patient}
                selectLink={generatePath(routes.PATIENT_SUMMARY, { patientId: patient.id })}
                showSummaryLink={showSummaryLink}
                showCoverageEndDate={showCoverageEndDate}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}

export default PatientSearchResult;
