import React from "react";
import { OSCAR_HEALTH_PLAN_NAME, Body2 } from "@coherehealth/common";
import { Grid } from "@material-ui/core";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import { useTheme } from "@mui/material/styles";
import { NetworkType } from "@coherehealth/core-platform-api";

interface Props {
  performingProviderOON: boolean;
  facilityOON: boolean;
  orderingProviderOON: boolean;
  careParticipantOON: boolean;
  careParticipantType?: string;
  healthPlanName: string;
  oonExceptionConfig: "REQUIRED" | "OPTIONAL" | "OMIT";
  networkType?: NetworkType;
}

function getOONBannerText(
  orderingProviderOON: boolean,
  performingProviderOON: boolean,
  facilityOON: boolean,
  careParticipantOON: boolean,
  healthPlanName: string,
  oonExceptionConfig: "REQUIRED" | "OPTIONAL" | "OMIT",
  careParticipantType?: string,
  networkType?: NetworkType
) {
  let oonPracticeCommon = "";

  oonPracticeCommon =
    performingProviderOON || orderingProviderOON
      ? "provider"
      : facilityOON
      ? "facility"
      : careParticipantOON
      ? careParticipantType
        ? careParticipantType
        : ""
      : "";

  let displayText = "";

  if (healthPlanName === OSCAR_HEALTH_PLAN_NAME) {
    displayText = `${oonPracticeCommon} is not in the patient's health plan network. Oscar currently does not accept out-of-network requests through Cohere. Please submit your auth request directly with Oscar.`;
  } else {
    if (networkType === undefined || networkType === "OON") {
      if (oonExceptionConfig === "REQUIRED") {
        displayText = `${
          oonPracticeCommon.charAt(0).toUpperCase() + oonPracticeCommon.slice(1)
        }  is out-of-network. Please choose an in-network ${
          careParticipantOON ? oonPracticeCommon.toLowerCase() : oonPracticeCommon
        }  or provide reasoning for your out-of-network request below.`;
      } else if (oonExceptionConfig === "OPTIONAL") {
        displayText = `${
          oonPracticeCommon.charAt(0).toUpperCase() + oonPracticeCommon.slice(1)
        } is out-of-network. You can switch to an in-network ${
          careParticipantOON ? oonPracticeCommon.toLowerCase() : oonPracticeCommon
        }, proceed with your out-of-network request, or ask for an exception below if you believe in-network rates should apply.`;
      } else if (oonExceptionConfig === "OMIT") {
        displayText = `${
          oonPracticeCommon.charAt(0).toUpperCase() + oonPracticeCommon.slice(1)
        } is out-of-network. You can switch to an in-network ${
          careParticipantOON ? oonPracticeCommon.toLowerCase() : oonPracticeCommon
        } or proceed with your out-of-network request.`;
      }
    } else if (networkType === "OOA") {
      displayText = `${oonPracticeCommon.charAt(0).toUpperCase() + oonPracticeCommon.slice(1)} is out-of-area`;
    }
  }
  return displayText;
}

export default function OutOfNetworkBanner({
  performingProviderOON,
  facilityOON,
  orderingProviderOON,
  careParticipantOON,
  careParticipantType,
  healthPlanName,
  oonExceptionConfig,
  networkType,
}: Props) {
  const { spacing } = useTheme();
  return (
    <Grid item xs={12}>
      <OONStatusContainer
        style={{
          marginBottom: facilityOON || careParticipantOON ? spacing(-0.625) : spacing(-1),
          marginTop: spacing(-0.25),
        }}
      >
        <WarningIcon />
        <VerticallyCenteredContainer>
          <VerticallyCenteredItem>
            <OONStatusText>
              {`${getOONBannerText(
                orderingProviderOON,
                performingProviderOON,
                facilityOON,
                careParticipantOON,
                healthPlanName,
                oonExceptionConfig,
                careParticipantType,
                networkType
              )}`}
            </OONStatusText>
          </VerticallyCenteredItem>
        </VerticallyCenteredContainer>
      </OONStatusContainer>
    </Grid>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WarningIcon = styled(ErrorIcon)(({ theme }) => ({
  color: theme.palette.warning.dark,
  fontSize: "20px",
  marginRight: "8px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OONStatusText = styled(Body2)(({ theme }) => ({
  fontFamily: "Gilroy-SemiBold",
  color: theme.palette.warning.dark,
  fontSize: "15px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OONStatusContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const VerticallyCenteredContainer = styled("div")(({ theme }) => ({
  display: "table",
  height: "18px",
  overflow: "hidden",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const VerticallyCenteredItem = styled("div")(({ theme }) => ({
  display: "table-cell",
}));
