import {
  ProcedureCodeWithId,
  FormTable,
  Body3,
  TextField,
  Tooltip,
  Chip,
  ProcedureCodeWithAggregate,
  withAggregate,
  InlineButton,
  colorsLight,
} from "@coherehealth/common";
import { ProcedureCode } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Box, Grid, makeStyles, styled, useTheme } from "@material-ui/core";
import { ComponentProps } from "react";
import { PROCEDURE_CODE_UNIT_TYPE_LABEL_MAP } from "util/serviceRequest";
import MuiErrorIcon from "@material-ui/icons/Error";
import { UnitsInitialWidth } from "common/SharedServiceRequestFormComponents";
import DeleteIcon from "@material-ui/icons/Delete";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";

interface Props {
  procedureCodes: ProcedureCodeWithId[];
  attemptedSubmit?: boolean;
  onUpdatePxCode: (newUnits: string, code: string, groupId: string | undefined) => void;
  onRemovePxCode: (code: string, groupId: string | undefined) => void;
}

export interface CssProps {
  isFaxAuthBuilderFlow?: boolean;
}

const ADD_MORE_UNITS = "Add more units";
export default function ProcedureCodeTable({ procedureCodes, attemptedSubmit, onUpdatePxCode, onRemovePxCode }: Props) {
  const theme = useTheme();
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  const classes = useStyles({ isFaxAuthBuilderFlow });

  const invalidUnitsMessage = (units: number | undefined): string => {
    if ((Number.isNaN(Number(units)) || units === null || units === 0) && attemptedSubmit) {
      return ADD_MORE_UNITS;
    }
    return "";
  };

  function RemoveButton({ rowIndex }: { rowIndex: number }) {
    return (
      <Box component="span" className={classes.pxRemoveBtnSpan}>
        <InlineButton
          className="removeButton"
          onClick={() => {
            const { code, groupId } = procedureCodes[rowIndex];
            onRemovePxCode(code, groupId);
          }}
          startIcon={<DeleteIcon />}
        >
          <Box component="span" style={{ display: isFaxAuthBuilderFlow ? "none" : "block" }}>
            Remove
          </Box>
        </InlineButton>
      </Box>
    );
  }

  const units_on_px_contents = (
    id: string,
    rowIndex: number,
    code: string,
    units?: number,
    unitType?: string,
    description?: string,
    groupId?: string
  ) => {
    return [
      {
        contents: (
          <>
            <Grid item container xs={12} className={classes.tableContainerRow} spacing={3}>
              <Grid item className={classes.tableFlexAuto}>
                <Chip style={{ height: theme.spacing(3) }} label={code} dataPublic={true} />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.tableFlexAuto}
                  error={Boolean(invalidUnitsMessage(units))}
                  type="text"
                  value={units || ""}
                  data-testid={"unit-field"}
                  inputClasses={{}}
                  style={{ width: UnitsInitialWidth }}
                  onChangeValue={function (newUnits): void {
                    onUpdatePxCode(newUnits, code, groupId);
                  }}
                  label={
                    unitType && PROCEDURE_CODE_UNIT_TYPE_LABEL_MAP[unitType]
                      ? `${PROCEDURE_CODE_UNIT_TYPE_LABEL_MAP[unitType]}s`
                      : "Units"
                  }
                  InputProps={{
                    endAdornment: Boolean(invalidUnitsMessage(units)) ? (
                      <Tooltip
                        classes={{ tooltip: classes.customWidth }}
                        title={invalidUnitsMessage(units)}
                        placement="top"
                      >
                        <ErrorIcon className={classes.customWidth} />
                      </Tooltip>
                    ) : undefined,
                  }}
                  dataPublic={true}
                />
              </Grid>
              <Grid item className={classes.pxDescriptionContainer}>
                <Box component="span" className={classes.pxDescriptionSpan}>
                  <Body3 data-public>{description}</Body3>
                </Box>
                <RemoveButton key={`removeBtn${id}`} rowIndex={rowIndex} />
              </Grid>
            </Grid>
          </>
        ),
        width: "90%",
      },
    ];
  };
  const pxCodesMap: Map<string, ProcedureCode> = procedureCodes.reduce((map, pxCode) => {
    map.set(pxCode.code, pxCode);
    return map;
  }, new Map());
  const allPxCodes = Array.from(pxCodesMap.values());

  return (
    <>
      <Row>
        {procedureCodes && procedureCodes.length > 0 && (
          <FormTable<ProcedureCodeWithAggregate>
            responsive={true}
            noPadding={true}
            withRowDivider={false}
            tableData={allPxCodes.map((px) => withAggregate(px))}
            headers={[]}
            TableRowProps={{ hover: true }}
            onRemoveRow={(rowIndex: number) => {
              const { code, groupId } = procedureCodes[rowIndex];
              onRemovePxCode(code, groupId);
            }}
          >
            {({ rowData: { id, code, units, unitType, description, groupId }, rowIndex }) =>
              units_on_px_contents(id, rowIndex, code, units, unitType, description, groupId)
            }
          </FormTable>
        )}
      </Row>
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ErrorIcon = styled(MuiErrorIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const Row = (props: ComponentProps<typeof Grid>) => <Grid item xs={12} {...props} />;

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 250,
  },
  tableContainerRow: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      "& .removeButton": {
        opacity: 1,
      },
    },
    "& .removeButton": {
      opacity: 0,
      color: colorsLight.font.secondary,
      transition: theme.transitions.create("opacity"),
      minWidth: (props: CssProps) => (props.isFaxAuthBuilderFlow ? "25px" : "80px"),
    },
  },
  tableFlexAuto: {
    flex: `0 0 ${UnitsInitialWidth}px`,
  },
  pxDescriptionContainer: {
    display: "flex",
    flex: "1 1 30%",
    minWidth: "200px",
    alignItems: "center",
  },
  pxDescriptionSpan: {
    flex: `1 1 99%`,
  },
  pxRemoveBtnSpan: {
    flex: "0 1 auto",
    minWidth: (props: CssProps) => (props.isFaxAuthBuilderFlow ? "25px" : "80px"),
    marginLeft: theme.spacing(2),
  },
}));
