import { AuthStatus } from "@coherehealth/core-platform-api";
import { RadioGroup, Tooltip, H6, colorsLight } from "@coherehealth/common";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useAuthorized } from "authorization";
import { useTheme } from "@material-ui/core/styles";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import useSRFormRadioGroupHeaderStyles from "../hooks/useSRFormRadioGroupHeaderStyles";
import {
  ServiceRequestFormContent,
  NON_COHERE_USER_EDIT_DISABLED_TOOLTIP,
} from "common/SharedServiceRequestFormComponents";

interface Props {
  inpatientValue?: boolean;
  authStatus: AuthStatus;
  formContent: ServiceRequestFormContent;
  patientHealthPlanName?: string | null;
  label?: string;
  onContinuationPatientSummary?: boolean;
  onCareTypeSwitch: (arg0: any) => void;
  isDiagnosisAndProviderCardOnContinuation?: boolean;
  onProviderDetailsPage?: boolean;
  editableFromPatientSummary?: boolean;
}

export default function CareTypeSelect({
  inpatientValue,
  authStatus,
  formContent,
  patientHealthPlanName,
  label,
  onContinuationPatientSummary = false,
  onCareTypeSwitch,
  isDiagnosisAndProviderCardOnContinuation,
  editableFromPatientSummary,
  onProviderDetailsPage,
}: Props) {
  const canEditWhenApproved = useAuthorized("EDIT_APPROVED_SR_PLACE_OF_SERVICE");
  const cantEditApprovedRequest = authStatus === "APPROVED" && !canEditWhenApproved;
  const disableEdit =
    onProviderDetailsPage || cantEditApprovedRequest || (onContinuationPatientSummary && !editableFromPatientSummary);
  const disableInpatientNoClinicalService =
    inpatientValue &&
    (!formContent.clinicalServices || formContent.clinicalServices.length === 0) &&
    patientHealthPlanName !== "Highmark";

  const { tooltipIcon: tooltipIconClass, ...labelClasses } = useSRFormRadioGroupHeaderStyles();
  const { spacing } = useTheme();

  const handleChange = (val: any) => {
    onCareTypeSwitch(val);
  };

  return (
    <RadioGroup
      row
      labelClasses={labelClasses}
      label={
        <>
          <SubHeading data-public>{label || `Care setting`}</SubHeading>
          {cantEditApprovedRequest && (
            <Tooltip
              interactive
              title={NON_COHERE_USER_EDIT_DISABLED_TOOLTIP}
              style={{
                marginTop: spacing(-1.5),
                marginBottom: spacing(-2),
                width: spacing(2.5),
                height: spacing(2.5),
                color: colorsLight.font.main,
              }}
            >
              <InfoOutlinedIcon className={tooltipIconClass} />
            </Tooltip>
          )}
          {!cantEditApprovedRequest && disableInpatientNoClinicalService && (
            <Tooltip
              interactive
              title={`Go back and select procedure codes before changing care setting to Outpatient`}
              style={{
                marginTop: spacing(-1.5),
                marginBottom: spacing(-2),
                width: spacing(2.5),
                height: spacing(2.5),
                color: colorsLight.font.main,
              }}
              data-public
            >
              <InfoOutlinedIcon className={tooltipIconClass} />
            </Tooltip>
          )}
        </>
      }
      disabled={disableEdit || disableInpatientNoClinicalService || isDiagnosisAndProviderCardOnContinuation}
      options={[
        { id: "false", label: "Outpatient" },
        { id: "true", label: "Inpatient" },
      ]}
      value={inpatientValue ? "true" : inpatientValue !== undefined ? "false" : ""}
      onChange={handleChange}
    />
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SubHeading = styled(H6)(({ theme }) => ({
  color: colorsLight.font.main,
}));
