import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { Body1 } from "@coherehealth/common";
import NoPatientResults from "../images/NoPatientResults.svg";

export default function EmptyResultsPatientLookup() {
  return (
    <EmptyResultContainer>
      <Row>
        <img alt="" src={NoPatientResults} />
        <NoResultsText>
          No patients match your search criteria. Please check your information and try again.
        </NoResultsText>
      </Row>
    </EmptyResultContainer>
  );
}
// eslint-disable-next-line cohere-react/no-mui-styled-import
const EmptyResultContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& div": {
    margin: theme.spacing(3),
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const NoResultsText = styled(Body1)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "flex",
  marginLeft: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Row = styled("div")({
  display: "flex",
});
