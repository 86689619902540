import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import {
  AuthBuilderWorkflowStep,
  AuthStatus,
  OutOfNetworkCheckConfiguration,
  Patient,
  PlaceOfService,
  useGetAuthCategories,
} from "@coherehealth/core-platform-api";
import { Body1, CohereCard, H4, Caption } from "@coherehealth/design-system";
import { Checkbox, H6, useFeature, useParsedQueryString } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Grid, makeStyles, styled } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import { useAuthorized } from "authorization";
import {
  FacilityBasedGeneralAuthSubmissionForm,
  FormConfiguration,
} from "components/ServiceRequest/ConfigurableServiceRequestForm";
import OonExceptionReason from "components/ServiceRequest/ServiceRequestForm/components/OonExceptionReason";
import OONProviderStatusDisplay from "components/ServiceRequest/ServiceRequestForm/components/OONProviderStatusDisplay";
import OONFacilityStatusDisplay from "components/ServiceRequest/ServiceRequestForm/components/OONFacilityStatusDisplay";
import OONOrderingProviderStatusDisplay from "components/ServiceRequest/ServiceRequestForm/components/OONOrderingProviderStatusDisplay";
import PlaceOfServiceSelect from "components/ServiceRequest/ServiceRequestForm/components/PlaceOfServiceSelect";
import { ServiceRequestFormContent, RedirectRuleCheckFn } from "common/SharedServiceRequestFormComponents";
import {
  HUMANA_OON_FACILITY_EXCEPTION_REASONS,
  HUMANA_OON_PERFORMING_PROVIDER_EXCEPTION_REASONS,
  OonExceptionReasonOptions,
  sameProviderCheckboxClick,
  getAdditionalCareParticipantName,
  getFieldSpecForAdditionalCareParticipant,
  isCareParticipantNameInvalid,
  isCareParticipantNpiInvalid,
  isCareParticipantTinInvalid,
  isCareParticipantAddressInvalid,
  isOonProviderOrFacility,
  isUnknownNetworkType,
} from "util/serviceRequest";
import { getPatientHealthPlanName } from "util/patientUtils";
import {
  PriorAuthRequirements,
  onCareTypeSwitch,
  useFacilityOutOfNetworkCheck,
  usePerformingProviderOutOfNetworkCheck,
  useOrderingProviderOutOfNetworkCheck,
} from "../common";
import { RedirectModalErrorObjectType } from "../../ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import PhoneInput from "common/PhoneInput";
import CareTypeSelect from "components/ServiceRequest/ServiceRequestForm/components/CareTypeSelect";
import { AuthCategorySelect } from "components/ServiceRequest/ServiceRequestForm/components/AuthCategorySelect";
import { AuthSubCategorySelect } from "components/ServiceRequest/ServiceRequestForm/components/AuthSubCategorySelect";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import {
  OrderingProviderSelectManual,
  PerformingProviderSelectManual,
} from "components/ServiceRequest/ServiceRequestForm/components/ProviderSelectManual";
import FacilitySelectManual from "components/ServiceRequest/ServiceRequestForm/components/FacilitySelectManual";
import AdditionalCareParticipantsSelect from "components/ServiceRequest/ServiceRequestForm/components/AdditionalCareParticipantsSelect";
import { useLocation } from "react-router";
import CareParticipantsOonCheck from "components/ServiceRequest/ServiceRequestForm/components/CareParticipantsOonCheck";
import NetworkCheckStatusDisplay from "components/ServiceRequest/ServiceRequestForm/components/NetworkCheckStatusDisplay";
import { shouldUseCohereCard } from "util/workflowUtils";
import {
  handleChangeOnProviderField,
  handleChangeOnExceptionReasonField,
  handleChangeOnExceptionCommentField,
} from "util/providerUtils";
import { ScrubTinsContext } from "../../ScrubTinsContext";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";
import { SRFormConfigFieldWrapper } from "common/FormConfigUtils";

type Props = {
  attemptedSubmit: boolean;
  VALIDATION_MAP: Record<FacilityBasedGeneralAuthSubmissionForm, boolean>;
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  sameProviders: boolean;
  dispatchRedirectRuleCheck: (errorObjectType: RedirectModalErrorObjectType) => RedirectRuleCheckFn;
  setSameProviders: Dispatch<SetStateAction<boolean>>;
  performingIsOptional: boolean;
  authStatus: AuthStatus;
  facilityIsOptional: boolean;
  isInpatient: boolean;
  placeOfService: PlaceOfService | null;
  setPlaceOfService: (pos: PlaceOfService | null) => void;
  placeOfServiceLoading: boolean;
  availablePlacesOfService: PlaceOfService[] | undefined;
  formFieldConfigurations: FormConfiguration;
  outOfNetworkCheckConfiguration: OutOfNetworkCheckConfiguration;
  patient?: Patient;
  workflowId?: string;
  delegatedVendorName?: string;
  faxNumberIsAutofilled?: boolean;
  onPatientSummary?: boolean;
  isContinuation?: boolean;
  priorAuthRequirements?: PriorAuthRequirements;
  setPriorAuthRequirements?: Dispatch<SetStateAction<PriorAuthRequirements>>;
  isFacilityOutOfNetworkCommentRequired?: boolean;
  isPerformingProviderOutOfNetworkCommentRequired?: boolean;
  isOrderingProviderOutOfNetworkCommentRequired?: boolean;
  authStage?: AuthBuilderWorkflowStep;
  setPerformingProviderExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  performingProviderExceptionRequest?: boolean;
  setFacilityExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  facilityExceptionRequest?: boolean;
  setOrderingProviderExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  orderingProviderExceptionRequest?: boolean;
  setCareParticipantExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  careParticipantExceptionRequest?: boolean;
  onProviderDetailsPage?: boolean;
  onFillFormsPage?: boolean;
  isDiagnosisAndProviderCardOnContinuation?: boolean;
  setFacilityOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
  setProviderOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
  setOrderingProviderOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
  setAdditionalCareOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
};

function ProvidersCard({
  attemptedSubmit,
  VALIDATION_MAP,
  formContent,
  setFormContent,
  sameProviders,
  dispatchRedirectRuleCheck,
  setSameProviders,
  performingIsOptional,
  authStatus,
  facilityIsOptional,
  isInpatient,
  placeOfService,
  setPlaceOfService,
  placeOfServiceLoading,
  availablePlacesOfService,
  patient,
  workflowId,
  delegatedVendorName,
  faxNumberIsAutofilled,
  onPatientSummary,
  isContinuation,
  priorAuthRequirements,
  setPriorAuthRequirements,
  isFacilityOutOfNetworkCommentRequired,
  isPerformingProviderOutOfNetworkCommentRequired,
  isOrderingProviderOutOfNetworkCommentRequired,
  authStage,
  setPerformingProviderExceptionRequest,
  performingProviderExceptionRequest,
  setFacilityExceptionRequest,
  facilityExceptionRequest,
  setOrderingProviderExceptionRequest,
  orderingProviderExceptionRequest,
  setCareParticipantExceptionRequest,
  careParticipantExceptionRequest,
  formFieldConfigurations,
  outOfNetworkCheckConfiguration,
  onProviderDetailsPage,
  onFillFormsPage,
  isDiagnosisAndProviderCardOnContinuation,
  setFacilityOonCheckLoading,
  setProviderOonCheckLoading,
  setOrderingProviderOonCheckLoading,
  setAdditionalCareOonCheckLoading,
}: Props) {
  const classes = useStyles();

  const { spacing } = useTheme();
  const canSkipOon = useAuthorized("CAN_SKIP_OON");
  const canEditCareSettings = useAuthorized("CAN_EDIT_CARE_SETTINGS") && authStatus === "PENDING";
  const patientHealthPlanName = getPatientHealthPlanName(patient || undefined, formContent?.startDate) || "";
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName: patientHealthPlanName,
    encounterType: isInpatient ? "INPATIENT" : "OUTPATIENT",
    skipRequestTimingCheck: true,
  });
  const {
    data: authCategoriesData,
    refetch: getAuthCategoriesData,
    loading: isLoadingAuthCategories,
  } = useGetAuthCategories({
    queryParams: { healthPlanName: patientHealthPlanName },
    lazy: true,
  });

  const { scrubTinsEnabled: hideTinField } = useContext(ScrubTinsContext);

  const { providerOonCheckLoading, performedProviderOONCheck } = usePerformingProviderOutOfNetworkCheck({
    formFieldConfigurations,
    outOfNetworkCheckConfiguration,
    content: formContent,
    setFormContent,
    patient: patient || undefined,
    workflowId,
    delegatedVendorName,
    hideTinField,
  });

  const { facilityOonCheckLoading, performedFacilityOONCheck } = useFacilityOutOfNetworkCheck({
    formFieldConfigurations,
    outOfNetworkCheckConfiguration,
    content: formContent,
    setFormContent,
    patient: patient || undefined,
    workflowId,
    delegatedVendorName,
    hideTinField,
  });

  const { orderingProviderOonCheckLoading, performedOrderingProviderOONCheck } = useOrderingProviderOutOfNetworkCheck({
    formFieldConfigurations,
    outOfNetworkCheckConfiguration,
    content: formContent,
    setFormContent,
    patient: patient || undefined,
    workflowId,
    delegatedVendorName,
    hideTinField,
  });

  const isFacilityOonExceptionRequired = formContent.facilityOONExceptionRequired;
  const isPerformingProviderOonExceptionRequired = formContent.performingProviderOONExceptionRequired;
  const isOrderingProviderOonExceptionRequired = formContent.orderingProviderOONExceptionRequired;

  const isFacilityOonExceptionOmit = formFieldConfigurations.facilityOutOfNetworkExceptionReason.fieldSpec === "OMIT";
  const isOrderingProviderOonExceptionOmit =
    formFieldConfigurations.orderingProviderOutOfNetworkExceptionReason.fieldSpec === "OMIT";
  const isPerformingProviderOonExceptionOmit =
    formFieldConfigurations.performingProviderOutOfNetworkExceptionReason.fieldSpec === "OMIT";

  const inPatientWithPrior = isInpatient ?? priorAuthRequirements?.encounterType === "INPATIENT";
  const authBuilderParamNavigationFF = useFeature("authBuilderParamNavigation");

  const location = useLocation();
  const qs = useParsedQueryString();
  const hashStep = location.hash.substring(1);
  const workflowStepStr = !authBuilderParamNavigationFF ? hashStep : qs["authBuilderStep"] || hashStep;

  useEffect(() => {
    if (!authCategoriesData && facilityBasedFeatureEnabled && inPatientWithPrior) {
      getAuthCategoriesData();
    }
  }, [authCategoriesData, facilityBasedFeatureEnabled, getAuthCategoriesData, inPatientWithPrior]);
  useEffect(() => {
    if (!!setFacilityOonCheckLoading) {
      setFacilityOonCheckLoading(facilityOonCheckLoading);
    }
  }, [facilityOonCheckLoading, setFacilityOonCheckLoading]);

  useEffect(() => {
    if (!!setProviderOonCheckLoading) {
      setProviderOonCheckLoading(providerOonCheckLoading);
    }
  }, [providerOonCheckLoading, setProviderOonCheckLoading]);

  useEffect(() => {
    if (!!setOrderingProviderOonCheckLoading) {
      setOrderingProviderOonCheckLoading(orderingProviderOonCheckLoading);
    }
  }, [orderingProviderOonCheckLoading, setOrderingProviderOonCheckLoading]);
  const displayAuthCategory =
    facilityBasedFeatureEnabled && inPatientWithPrior && authCategoriesData ? authCategoriesData.length > 0 : false;

  const subcategoryOptions =
    formContent?.authCategory?.subcategories ||
    authCategoriesData?.find((authCategory) => authCategory.enumName === formContent?.authCategory?.enumName)
      ?.subcategories ||
    [];
  const displaySubcategory = displayAuthCategory && subcategoryOptions.length > 0;
  const authCategoryErrorSpacing =
    attemptedSubmit && !priorAuthRequirements?.authCategory && displayAuthCategory ? 1 : 4;
  const formAuthCategory = formContent?.authCategory?.enumName ? formContent?.authCategory.enumName : null;
  const canShowEditOption =
    formContent?.facility && formContent?.facilitySelectedAddress && formContent?.facilitySelectedTin;
  const showEditOptionForOp =
    formContent?.orderingProvider &&
    formContent?.orderingProviderSelectedAddress &&
    formContent?.orderingProvider?.selectedLocation?.tin;

  const showEditOptionForPp =
    formContent?.performingProvider &&
    formContent.performingProviderSelectedAddress &&
    formContent.performingProvider?.selectedLocation?.tin;

  const shouldShowAdditionalCareParticipants = workflowStepStr === "REVIEW" || authStatus !== "DRAFT";
  const showExceptionReasonBoxForFacility =
    !facilityOonCheckLoading &&
    (isOonProviderOrFacility(formContent.facilitySelectedAddress) ||
      isUnknownNetworkType(formContent.facilitySelectedAddress?.networkType));

  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);

  return (
    <Grid container spacing={2} style={{ paddingBottom: isFaxAuthBuilderFlow ? spacing(1) : spacing(0) }}>
      <Grid item xs={12}>
        <ProviderHeading
          style={{ paddingTop: isFaxAuthBuilderFlow ? spacing(0) : undefined, marginBottom: spacing(0) }}
          data-public
        >
          Providers
        </ProviderHeading>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} container direction="column" justifyContent="center">
          <CareTypeSelect
            onProviderDetailsPage={onProviderDetailsPage}
            inpatientValue={formContent?.isInpatient}
            formContent={formContent}
            authStatus={authStatus}
            patientHealthPlanName={patientHealthPlanName || ""}
            editableFromPatientSummary={canEditCareSettings}
            onContinuationPatientSummary={onPatientSummary}
            isDiagnosisAndProviderCardOnContinuation={isDiagnosisAndProviderCardOnContinuation}
            onCareTypeSwitch={(isInpatient: string) => {
              onCareTypeSwitch(isInpatient, setFormContent);
              if (!!setPriorAuthRequirements) {
                setPriorAuthRequirements((prev) => ({
                  ...prev,
                  encounterType: isInpatient === "true" ? "INPATIENT" : "OUTPATIENT",
                }));
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
        style={{ marginTop: spacing(-2.375), paddingBottom: isInpatient ? spacing(authCategoryErrorSpacing) : "" }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <PlaceOfServiceSelect
            error={attemptedSubmit && !VALIDATION_MAP["placeOfService"]}
            authStatus={authStatus}
            isInpatient={isInpatient}
            placeOfService={placeOfService}
            setPlaceOfService={setPlaceOfService}
            placeOfServiceLoading={placeOfServiceLoading}
            availablePlacesOfService={availablePlacesOfService}
            clinicalService={formContent?.clinicalService || formContent?.clinicalServices?.[0]}
            dispatchRedirectRuleCheck={dispatchRedirectRuleCheck("encounter type")}
            patient={patient || undefined}
            editableFromPatientSummary={!!!isContinuation || canEditCareSettings}
            onContinuationPatientSummary={onPatientSummary}
            authStage={authStage}
            isDiagnosisAndProviderCardOnContinuation={isDiagnosisAndProviderCardOnContinuation}
          />
        </Grid>
        {displayAuthCategory && (
          <Grid item xs={12} sm={6} md={4} className={classes.authCategoryWrapper}>
            <AuthCategorySelect
              selectedAuthCategory={
                priorAuthRequirements?.authCategory?.enumName
                  ? priorAuthRequirements.authCategory.enumName
                  : formAuthCategory
              }
              onSelectAuthCategory={(selectedAuthCategoryName) => {
                const authCategory = authCategoriesData?.find(({ enumName }) => selectedAuthCategoryName === enumName);
                if (!!setPriorAuthRequirements) {
                  setPriorAuthRequirements((prev) => ({
                    ...prev,
                    authCategory,
                    authSubcategory: undefined,
                  }));
                }
                setFormContent({
                  ...formContent,
                  authCategory,
                  authSubcategory: undefined,
                });
              }}
              authCategoryOptions={authCategoriesData ?? []}
              attemptedSubmit={attemptedSubmit}
              isLoading={isLoadingAuthCategories}
              onPatientSummary={onPatientSummary}
              onProviderDetailsPage={onProviderDetailsPage}
              onFillFormsPage={onFillFormsPage}
              isInpatient={isInpatient}
            />
          </Grid>
        )}
        {displaySubcategory && (
          <Grid item xs={12} sm={12} md={4} className={classes.authCategoryWrapper}>
            <AuthSubCategorySelect
              selectedAuthSubCategory={priorAuthRequirements?.authSubcategory ?? formContent?.authSubcategory ?? ""}
              onSelectAuthSubCategory={(selectedSubcategory) => {
                if (!!setPriorAuthRequirements) {
                  setPriorAuthRequirements((prev) => ({
                    ...prev,
                    authSubcategory: selectedSubcategory,
                  }));
                }
                setFormContent({
                  ...formContent,
                  authSubcategory: selectedSubcategory,
                });
              }}
              authSubCategoryOptions={subcategoryOptions}
              attemptedSubmit={attemptedSubmit}
              isLoading={isLoadingAuthCategories}
              onPatientSummary={onPatientSummary}
            />
          </Grid>
        )}
      </Grid>
      <SRFormConfigFieldWrapper {...formFieldConfigurations.orderingProvider}>
        <Grid item xs={12}>
          <SubHeading
            style={{
              marginTop: isInpatient && formContent?.procedureCodes.length >= 0 ? spacing(-2.375) : spacing(1),
              marginBottom: spacing(-0.5),
            }}
            data-public
          >
            {"Ordering provider" +
              (formFieldConfigurations.orderingProvider.fieldSpec === "OPTIONAL" ? " (optional)" : "")}
          </SubHeading>
        </Grid>
        <Grid item container spacing={2}>
          <OrderingProviderSelectManual
            error={attemptedSubmit && !VALIDATION_MAP["orderingProvider"]}
            npiError={attemptedSubmit && VALIDATION_MAP["orderingProvider"] && !VALIDATION_MAP["orderingProviderNPI"]}
            tinError={attemptedSubmit && VALIDATION_MAP["orderingProvider"] && !VALIDATION_MAP["orderingProviderTIN"]}
            addressError={
              attemptedSubmit &&
              VALIDATION_MAP["orderingProvider"] &&
              VALIDATION_MAP["orderingProviderTIN"] &&
              !VALIDATION_MAP["orderingProviderAddress"]
            }
            showTinAndAddressField={
              patientHealthPlanName === "Geisinger"
                ? formFieldConfigurations.orderingProviderAddress.fieldSpec !== "OMIT"
                : formFieldConfigurations.orderingProviderAddress.fieldSpec === "REQUIRED"
            }
            formContent={formContent}
            setFormContent={setFormContent}
            sameProviders={sameProviders}
            dispatchRedirectRuleCheck={dispatchRedirectRuleCheck("ordering provider")}
            setSameProviders={setSameProviders}
            patient={patient}
            showEditOption={!!showEditOptionForOp}
            setOrderingProviderExceptionRequest={setOrderingProviderExceptionRequest}
            hideTinField={hideTinField || formFieldConfigurations?.orderingProviderTIN?.fieldSpec === "OMIT"}
            formConfiguration={formFieldConfigurations}
          />
        </Grid>
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formFieldConfigurations.outOfNetworkCheck}>
        <SRFormConfigFieldWrapper {...formFieldConfigurations.orderingProviderOutOfNetworkStatusDisplay}>
          <SRFormConfigFieldWrapper {...formFieldConfigurations.outOfNetworkOrderingProvider}>
            {!formContent?.orderingProviderSelectedAddress?.networkType ? (
              <Grid item container xs={12} spacing={2}>
                <OONOrderingProviderStatusDisplay
                  isOrderingProviderOON={formContent?.orderingProviderSelectedAddress?.isOutOfNetwork || false}
                  oonLoading={orderingProviderOonCheckLoading}
                  inNetwork={
                    performedOrderingProviderOONCheck && !formContent?.orderingProviderSelectedAddress?.isOutOfNetwork
                  }
                  healthPlanName={patientHealthPlanName}
                  oonExceptionConfig={formFieldConfigurations.orderingProviderOutOfNetworkExceptionReason.fieldSpec}
                />
              </Grid>
            ) : (
              <Grid item container xs={12} spacing={2}>
                <NetworkCheckStatusDisplay
                  networkType={formContent?.orderingProviderSelectedAddress?.networkType || undefined}
                  oonLoading={orderingProviderOonCheckLoading}
                  healthPlanName={patientHealthPlanName}
                  entityType={"orderingProvider"}
                  oonExceptionConfig={formFieldConfigurations.orderingProviderOutOfNetworkExceptionReason.fieldSpec}
                  isOonExceptionRequired={isOrderingProviderOonExceptionRequired}
                  isOonExceptionOmit={isOrderingProviderOonExceptionOmit}
                />
              </Grid>
            )}
          </SRFormConfigFieldWrapper>
          {!isOrderingProviderOonExceptionRequired &&
            (isOonProviderOrFacility(formContent.orderingProviderSelectedAddress) ||
              isUnknownNetworkType(formContent.orderingProviderSelectedAddress?.networkType)) && (
              <SRFormConfigFieldWrapper {...formFieldConfigurations.orderingProviderOutOfNetworkExceptionReason}>
                <Grid item xs={12}>
                  <Checkbox
                    label={
                      <Body1 color={"text.secondary"}>Ask for a network exception (request will pend for review)</Body1>
                    }
                    checked={orderingProviderExceptionRequest}
                    onChange={async (userSelectedOONExceptionValue) => {
                      setPriorAuthRequirements?.((prev) => {
                        return { ...prev, userSelectedOutOfNetworkException: userSelectedOONExceptionValue };
                      });
                      if (!userSelectedOONExceptionValue) {
                        setFormContent(handleChangeOnProviderField(formContent));
                      }
                      setOrderingProviderExceptionRequest?.(userSelectedOONExceptionValue);
                    }}
                    style={{ marginTop: spacing(-0.5), marginBottom: spacing(-2) }}
                  />
                </Grid>
              </SRFormConfigFieldWrapper>
            )}
        </SRFormConfigFieldWrapper>
      </SRFormConfigFieldWrapper>
      {(!isOrderingProviderOonExceptionRequired && !orderingProviderExceptionRequest) ||
        ((isOonProviderOrFacility(formContent.orderingProviderSelectedAddress) ||
          isUnknownNetworkType(formContent.orderingProviderSelectedAddress?.networkType)) && (
          <SRFormConfigFieldWrapper {...formFieldConfigurations.orderingProviderOutOfNetworkExceptionReason}>
            <Grid item container spacing={2} style={{ marginBottom: spacing(0) }}>
              <OonExceptionReason
                exceptionPracticeCommon="Ordering Provider"
                exceptionReasons={OonExceptionReasonOptions(HUMANA_OON_PERFORMING_PROVIDER_EXCEPTION_REASONS)}
                isExceptionRequired={(isOrderingProviderOonExceptionRequired && !canSkipOon) || false}
                isCommentRequired={isOrderingProviderOutOfNetworkCommentRequired}
                data-tracking-id="ordering-provider-exception-reason"
                error={attemptedSubmit && !VALIDATION_MAP["orderingProviderOutOfNetworkExceptionReason"]}
                commentError={attemptedSubmit && !VALIDATION_MAP["orderingProviderOutOfNetworkExceptionComment"]}
                dropDownValue={formContent?.orderingProviderSelectedAddress?.outOfNetworkExceptionReason || ""}
                commentValue={formContent?.orderingProviderSelectedAddress?.outOfNetworkExceptionComment || ""}
                dropDownOnChange={(reason) => {
                  return setFormContent(handleChangeOnExceptionReasonField(formContent, reason));
                }}
                commentOnChange={(comment) => {
                  return setFormContent(handleChangeOnExceptionCommentField(formContent, comment));
                }}
                generalAuthStyling={true}
              />
            </Grid>
          </SRFormConfigFieldWrapper>
        ))}
      <SRFormConfigFieldWrapper {...formFieldConfigurations.performingProvider}>
        <Grid item xs={12} style={{ marginTop: spacing(1) }}>
          <SubHeading data-public>
            {"Performing or attending provider" + (performingIsOptional ? " (optional)" : "")}
          </SubHeading>
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            muiCheckboxProps={{ className: classes.root }}
            checked={sameProviders}
            onChange={(checked) => {
              sameProviderCheckboxClick(setFormContent, setSameProviders, checked);
            }}
            label={
              <Body1 color={"font.light"} fontFamily="Gilroy-medium">
                Performing is the same as the ordering
              </Body1>
            }
          />
        </Grid>
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formFieldConfigurations.performingProvider}>
        <Grid item container spacing={2}>
          <PerformingProviderSelectManual
            formContent={formContent}
            setFormContent={setFormContent}
            npiError={
              attemptedSubmit && VALIDATION_MAP["performingProvider"] && !VALIDATION_MAP["performingProviderNPI"]
            }
            tinError={
              attemptedSubmit && VALIDATION_MAP["performingProvider"] && !VALIDATION_MAP["performingProviderTIN"]
            }
            addressError={
              attemptedSubmit &&
              VALIDATION_MAP["performingProvider"] &&
              VALIDATION_MAP["performingProviderTIN"] &&
              !VALIDATION_MAP["performingProviderAddress"]
            }
            showAddressField={formFieldConfigurations.performingProviderAddress.fieldSpec !== "OMIT"}
            sameProviders={sameProviders}
            setSameProviders={setSameProviders}
            isOptional={performingIsOptional}
            providerError={attemptedSubmit && !VALIDATION_MAP["performingProvider"]}
            authStatus={authStatus}
            formConfiguration={formFieldConfigurations}
            patient={patient || undefined}
            showEditOption={!!showEditOptionForPp}
            setPerformingProviderExceptionRequest={setPerformingProviderExceptionRequest}
            hideTinField={hideTinField || formFieldConfigurations?.performingProviderTIN?.fieldSpec === "OMIT"}
          />
        </Grid>
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formFieldConfigurations.outOfNetworkCheck}>
        <SRFormConfigFieldWrapper {...formFieldConfigurations.performingProviderOutOfNetworkStatusDisplay}>
          {!formContent?.performingProviderSelectedAddress?.networkType ? (
            <Grid item container xs={12} spacing={2}>
              <OONProviderStatusDisplay
                isPerformingProviderOON={formContent?.performingProviderSelectedAddress?.isOutOfNetwork || false}
                oonLoading={providerOonCheckLoading}
                inNetwork={performedProviderOONCheck && !formContent?.performingProviderSelectedAddress?.isOutOfNetwork}
                healthPlanName={patientHealthPlanName}
                oonExceptionConfig={formFieldConfigurations.performingProviderOutOfNetworkExceptionReason.fieldSpec}
              />
            </Grid>
          ) : (
            <Grid item container xs={12} spacing={2}>
              <NetworkCheckStatusDisplay
                networkType={formContent?.performingProviderSelectedAddress?.networkType || undefined}
                oonLoading={providerOonCheckLoading}
                healthPlanName={patientHealthPlanName}
                oonExceptionConfig={formFieldConfigurations.performingProviderOutOfNetworkExceptionReason.fieldSpec}
                entityType={"performingProvider"}
                isOonExceptionRequired={isPerformingProviderOonExceptionRequired}
                isOonExceptionOmit={isPerformingProviderOonExceptionOmit}
              />
            </Grid>
          )}
          {!isPerformingProviderOonExceptionRequired &&
            (isOonProviderOrFacility(formContent.performingProviderSelectedAddress) ||
              isUnknownNetworkType(formContent.performingProviderSelectedAddress?.networkType)) && (
              <SRFormConfigFieldWrapper {...formFieldConfigurations.performingProviderOutOfNetworkExceptionReason}>
                <Grid item xs={12}>
                  <Checkbox
                    label={
                      <Body1 color={"text.secondary"}>Ask for a network exception (request will pend for review)</Body1>
                    }
                    checked={performingProviderExceptionRequest}
                    onChange={async (userSelectedOONExceptionValue) => {
                      setPriorAuthRequirements?.((prev) => {
                        return { ...prev, userSelectedOutOfNetworkException: userSelectedOONExceptionValue };
                      });
                      if (!userSelectedOONExceptionValue) {
                        setFormContent({
                          ...formContent,
                          performingProviderSelectedAddress: {
                            ...formContent?.performingProviderSelectedAddress,
                            outOfNetworkExceptionReason: "",
                          },
                          selectedPerformingProvider: {
                            ...formContent?.selectedPerformingProvider,
                            id: formContent?.selectedPerformingProvider?.id || "",
                            dateCreated: formContent?.selectedPerformingProvider?.dateCreated || "",
                            lastUpdated: formContent?.selectedPerformingProvider?.lastUpdated || "",
                            selectedLocation: {
                              ...formContent?.selectedPerformingProvider?.selectedLocation,
                              outOfNetworkExceptionReason: "",
                            },
                            type: formContent?.selectedPerformingProvider?.type || "PROVIDER",
                          },
                        });
                      }
                      setPerformingProviderExceptionRequest?.(userSelectedOONExceptionValue);
                    }}
                    style={{ marginTop: spacing(-0.5), marginBottom: spacing(-2) }}
                  />
                </Grid>
              </SRFormConfigFieldWrapper>
            )}
        </SRFormConfigFieldWrapper>
      </SRFormConfigFieldWrapper>
      {(!isPerformingProviderOonExceptionRequired && !performingProviderExceptionRequest) ||
        ((isOonProviderOrFacility(formContent.performingProviderSelectedAddress) ||
          isUnknownNetworkType(formContent.performingProviderSelectedAddress?.networkType)) && (
          <SRFormConfigFieldWrapper {...formFieldConfigurations.performingProviderOutOfNetworkExceptionReason}>
            <Grid item container spacing={2} style={{ marginBottom: spacing(0) }}>
              <OonExceptionReason
                exceptionPracticeCommon="Performing Provider"
                exceptionReasons={OonExceptionReasonOptions(HUMANA_OON_PERFORMING_PROVIDER_EXCEPTION_REASONS)}
                isExceptionRequired={(isPerformingProviderOonExceptionRequired && !canSkipOon) || false}
                isCommentRequired={isPerformingProviderOutOfNetworkCommentRequired}
                data-tracking-id="performing-provider-exception-reason"
                error={attemptedSubmit && !VALIDATION_MAP["performingProviderOutOfNetworkExceptionReason"]}
                commentError={attemptedSubmit && !VALIDATION_MAP["performingProviderOutOfNetworkExceptionComment"]}
                dropDownValue={formContent?.performingProviderSelectedAddress?.outOfNetworkExceptionReason || ""}
                commentValue={formContent?.performingProviderSelectedAddress?.outOfNetworkExceptionComment || ""}
                dropDownOnChange={(reason) => {
                  return setFormContent({
                    ...formContent,
                    performingProviderSelectedAddress: {
                      ...formContent?.performingProviderSelectedAddress,
                      outOfNetworkExceptionReason: reason,
                    },
                    selectedPerformingProvider: {
                      ...formContent?.selectedPerformingProvider,
                      id: formContent?.selectedPerformingProvider?.id || "",
                      dateCreated: formContent?.selectedPerformingProvider?.dateCreated || "",
                      lastUpdated: formContent?.selectedPerformingProvider?.lastUpdated || "",
                      selectedLocation: {
                        ...formContent?.selectedPerformingProvider?.selectedLocation,
                        outOfNetworkExceptionReason: reason,
                      },
                      type: formContent?.selectedPerformingProvider?.type || "PROVIDER",
                    },
                  });
                }}
                commentOnChange={(comment) => {
                  return setFormContent({
                    ...formContent,
                    performingProviderSelectedAddress: {
                      ...formContent?.performingProviderSelectedAddress,
                      outOfNetworkExceptionComment: comment,
                    },
                    selectedPerformingProvider: {
                      ...formContent?.selectedPerformingProvider,
                      id: formContent?.selectedPerformingProvider?.id || "",
                      dateCreated: formContent?.selectedPerformingProvider?.dateCreated || "",
                      lastUpdated: formContent?.selectedPerformingProvider?.lastUpdated || "",
                      selectedLocation: {
                        ...formContent?.selectedPerformingProvider?.selectedLocation,
                        outOfNetworkExceptionComment: comment,
                      },
                      type: formContent?.selectedPerformingProvider?.type || "PROVIDER",
                    },
                  });
                }}
                generalAuthStyling={true}
              />
            </Grid>
          </SRFormConfigFieldWrapper>
        ))}
      <SRFormConfigFieldWrapper {...formFieldConfigurations.facility}>
        <Grid item xs={12}>
          <SubHeading
            style={{
              marginTop: spacing(1),
              marginBottom: spacing(-0.5),
            }}
            data-public
          >
            {"Performing facility or agency" + (facilityIsOptional ? " (optional)" : "")}
          </SubHeading>
        </Grid>
        <Grid item container spacing={2}>
          <FacilitySelectManual
            facilityError={attemptedSubmit && !VALIDATION_MAP["facility"]}
            npiError={attemptedSubmit && VALIDATION_MAP["facility"] && !VALIDATION_MAP["facilityNPI"]}
            tinError={attemptedSubmit && VALIDATION_MAP["facility"] && !VALIDATION_MAP["facilityTIN"]}
            addressError={
              attemptedSubmit &&
              VALIDATION_MAP["facility"] &&
              VALIDATION_MAP["facilityTIN"] &&
              !VALIDATION_MAP["facilityAddress"]
            }
            formContent={formContent}
            showAddressField={formFieldConfigurations.facilityAddress.fieldSpec !== "OMIT"}
            setFormContent={setFormContent}
            isOptional={facilityIsOptional}
            formConfiguration={formFieldConfigurations}
            patient={patient || undefined}
            showEditOption={!!canShowEditOption}
            setFacilityExceptionRequest={setFacilityExceptionRequest}
            hideTinField={hideTinField || formFieldConfigurations?.facilityTIN?.fieldSpec === "OMIT"}
          />
        </Grid>
      </SRFormConfigFieldWrapper>

      <SRFormConfigFieldWrapper {...formFieldConfigurations.outOfNetworkCheck}>
        <SRFormConfigFieldWrapper {...formFieldConfigurations.facilityOutOfNetworkStatusDisplay}>
          {!formContent?.facilitySelectedAddress?.networkType ? (
            <Grid item container xs={12} spacing={2}>
              <OONFacilityStatusDisplay
                isFacilityOON={formContent?.facilitySelectedAddress?.isOutOfNetwork || false}
                oonLoading={facilityOonCheckLoading}
                inNetwork={performedFacilityOONCheck && !formContent?.facilitySelectedAddress?.isOutOfNetwork}
                healthPlanName={patientHealthPlanName}
                oonExceptionConfig={formFieldConfigurations.facilityOutOfNetworkExceptionReason.fieldSpec}
              />
            </Grid>
          ) : (
            <Grid item container xs={12} spacing={2}>
              <NetworkCheckStatusDisplay
                networkType={formContent?.facilitySelectedAddress?.networkType || undefined}
                oonLoading={facilityOonCheckLoading}
                healthPlanName={patientHealthPlanName}
                oonExceptionConfig={formFieldConfigurations.facilityOutOfNetworkExceptionReason.fieldSpec}
                entityType={"facility"}
                isOonExceptionRequired={isFacilityOonExceptionRequired}
                isOonExceptionOmit={isFacilityOonExceptionOmit}
              />
            </Grid>
          )}
          {!isFacilityOonExceptionRequired &&
            (isOonProviderOrFacility(formContent.facilitySelectedAddress) ||
              isUnknownNetworkType(formContent.facilitySelectedAddress?.networkType)) && (
              <SRFormConfigFieldWrapper {...formFieldConfigurations.facilityOutOfNetworkExceptionReason}>
                <Grid item xs={12}>
                  <Checkbox
                    label={
                      <Body1 color={"text.secondary"}>Ask for a network exception (request will pend for review)</Body1>
                    }
                    checked={facilityExceptionRequest}
                    onChange={async (userSelectedOONExceptionValue) => {
                      setPriorAuthRequirements?.((prev) => {
                        return { ...prev, userSelectedOutOfNetworkException: userSelectedOONExceptionValue };
                      });
                      if (!userSelectedOONExceptionValue) {
                        setFormContent({
                          ...formContent,
                          facilitySelectedAddress: {
                            ...formContent?.facilitySelectedAddress,
                            outOfNetworkExceptionReason: "",
                          },
                          selectedFacility: {
                            ...formContent?.selectedFacility,
                            id: formContent?.selectedFacility?.id || "",
                            dateCreated: formContent?.selectedFacility?.dateCreated || "",
                            lastUpdated: formContent?.selectedFacility?.lastUpdated || "",
                            selectedLocation: {
                              ...formContent?.selectedFacility?.selectedLocation,
                              outOfNetworkExceptionReason: "",
                            },
                            type: formContent?.selectedFacility?.type || "FACILITY",
                          },
                        });
                      }
                      setFacilityExceptionRequest?.(userSelectedOONExceptionValue);
                    }}
                    style={{ marginTop: spacing(-0.5), marginBottom: spacing(-2) }}
                  />
                </Grid>
              </SRFormConfigFieldWrapper>
            )}
        </SRFormConfigFieldWrapper>
      </SRFormConfigFieldWrapper>

      {(!isFacilityOonExceptionRequired && !facilityExceptionRequest) ||
        (showExceptionReasonBoxForFacility && (
          <SRFormConfigFieldWrapper {...formFieldConfigurations.facilityOutOfNetworkExceptionReason}>
            <Grid item container spacing={2}>
              <OonExceptionReason
                exceptionPracticeCommon="Facility"
                exceptionReasons={OonExceptionReasonOptions(HUMANA_OON_FACILITY_EXCEPTION_REASONS)}
                isExceptionRequired={(isFacilityOonExceptionRequired && !canSkipOon) || false}
                isCommentRequired={isFacilityOutOfNetworkCommentRequired}
                data-tracking-id="facility-exception-reason"
                error={attemptedSubmit && !VALIDATION_MAP["facilityOutOfNetworkExceptionReason"]}
                commentError={attemptedSubmit && !VALIDATION_MAP["facilityOutOfNetworkExceptionComment"]}
                dropDownValue={formContent?.facilitySelectedAddress?.outOfNetworkExceptionReason || ""}
                commentValue={formContent?.facilitySelectedAddress?.outOfNetworkExceptionComment || ""}
                dropDownOnChange={(reason) => {
                  return setFormContent({
                    ...formContent,
                    facilitySelectedAddress: {
                      ...formContent?.facilitySelectedAddress,
                      outOfNetworkExceptionReason: reason,
                    },
                    selectedFacility: {
                      ...formContent?.selectedFacility,
                      id: formContent?.selectedFacility?.id || "",
                      dateCreated: formContent?.selectedFacility?.dateCreated || "",
                      lastUpdated: formContent?.selectedFacility?.lastUpdated || "",
                      selectedLocation: {
                        ...formContent?.selectedFacility?.selectedLocation,
                        outOfNetworkExceptionReason: reason,
                      },
                      type: formContent?.selectedFacility?.type || "FACILITY",
                    },
                  });
                }}
                commentOnChange={(comment) => {
                  return setFormContent({
                    ...formContent,
                    facilitySelectedAddress: {
                      ...formContent?.facilitySelectedAddress,
                      outOfNetworkExceptionComment: comment,
                    },
                    selectedFacility: {
                      ...formContent?.selectedFacility,
                      id: formContent?.selectedFacility?.id || "",
                      dateCreated: formContent?.selectedFacility?.dateCreated || "",
                      lastUpdated: formContent?.selectedFacility?.lastUpdated || "",
                      selectedLocation: {
                        ...formContent?.selectedFacility?.selectedLocation,
                        outOfNetworkExceptionComment: comment,
                      },
                      type: formContent?.selectedFacility?.type || "FACILITY",
                    },
                  });
                }}
                generalAuthStyling={true}
              />
            </Grid>
          </SRFormConfigFieldWrapper>
        ))}
      <SRFormConfigFieldWrapper {...formFieldConfigurations?.userSelectedOONException}>
        <Grid item xs={12}>
          <Checkbox
            style={{ marginTop: 12 }}
            label={
              <Body1 color={"text.secondary"}>Ask for an out-of-network waiver (request will pend for review)</Body1>
            }
            checked={formContent.userSelectedOONException}
            onChange={(checked) =>
              setFormContent({
                ...formContent,
                userSelectedOONException: checked,
              })
            }
          />
        </Grid>
      </SRFormConfigFieldWrapper>
      {formContent?.additionalCareParticipants?.map((careParticipant, i) => {
        return (
          <SRFormConfigFieldWrapper
            {...formFieldConfigurations.additionalCareParticipants}
            forceShow={
              shouldShowAdditionalCareParticipants &&
              formFieldConfigurations.additionalCareParticipants.fieldSpec === "OMIT" &&
              formContent!.additionalCareParticipants?.length !== 0
            }
          >
            <Grid item xs={12}>
              <SubHeading
                style={{
                  marginTop: spacing(0.5),
                  marginBottom: spacing(-0.5),
                }}
              >
                {getAdditionalCareParticipantName(careParticipant.careParticipantType) +
                  (getFieldSpecForAdditionalCareParticipant(careParticipant, "name") === "OPTIONAL" &&
                  getFieldSpecForAdditionalCareParticipant(careParticipant, "npi") === "OPTIONAL" &&
                  getFieldSpecForAdditionalCareParticipant(careParticipant, "tin") === "OPTIONAL" &&
                  getFieldSpecForAdditionalCareParticipant(careParticipant, "address") === "OPTIONAL"
                    ? " (optional)"
                    : "")}
              </SubHeading>
            </Grid>
            <Grid item container spacing={2}>
              <AdditionalCareParticipantsSelect
                additionalCareParticipantError={
                  attemptedSubmit && isCareParticipantNameInvalid(careParticipant) && !careParticipant.isDisabled
                }
                npiError={
                  attemptedSubmit && isCareParticipantNpiInvalid(careParticipant) && !careParticipant.isDisabled
                }
                tinError={
                  attemptedSubmit && isCareParticipantTinInvalid(careParticipant) && !careParticipant.isDisabled
                }
                addressError={
                  attemptedSubmit && isCareParticipantAddressInvalid(careParticipant) && !careParticipant.isDisabled
                }
                formContent={formContent}
                showAddressField={
                  careParticipant.fieldConfigurations?.find(
                    (fieldConfiguration) => fieldConfiguration?.fieldName === "address"
                  )?.fieldSpecification !== "OMIT"
                }
                setFormContent={setFormContent}
                formConfiguration={formFieldConfigurations}
                patient={patient || undefined}
                additionalCareParticipant={careParticipant}
                rowIndex={i}
                hideTinField={
                  hideTinField ||
                  careParticipant?.fieldConfigurations?.find((fieldConfig) => fieldConfig?.fieldName === "tin")
                    ?.fieldSpecification === "OMIT"
                }
              />
            </Grid>
            <SRFormConfigFieldWrapper {...formFieldConfigurations.careParticipantOutOfNetworkStatusDisplay}>
              <CareParticipantsOonCheck
                formFieldConfigurations={formFieldConfigurations}
                outOfNetworkCheckConfiguration={outOfNetworkCheckConfiguration}
                formContent={formContent}
                careParticipant={careParticipant}
                setFormContent={setFormContent}
                patient={patient || undefined}
                workflowId={workflowId}
                delegatedVendorName={delegatedVendorName}
                rowIndex={i}
                canSkipOon={canSkipOon}
                healthPlanName={patientHealthPlanName}
                careParticipantExceptionRequest={careParticipantExceptionRequest}
                setCareParticipantExceptionRequest={setCareParticipantExceptionRequest}
                attemptedSubmit={attemptedSubmit}
                VALIDATION_MAP={VALIDATION_MAP}
                hideTinField={hideTinField}
                setAdditionalCareOonCheckLoading={setAdditionalCareOonCheckLoading}
              />
            </SRFormConfigFieldWrapper>
          </SRFormConfigFieldWrapper>
        );
      })}
      <SRFormConfigFieldWrapper {...formFieldConfigurations.faxInputField}>
        <Grid item xs={12}>
          <SubHeading
            style={{
              marginBottom: spacing(-0.5),
              marginTop:
                performedFacilityOONCheck && !formContent?.facilitySelectedAddress?.isOutOfNetwork
                  ? spacing(0.75)
                  : spacing(0.375),
            }}
          >
            Follow-up fax number
          </SubHeading>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={3}>
            <PhoneInput
              label="Follow-up fax number (10 digits)"
              value={formContent?.followUpFaxNumber || {}}
              onChange={(faxNumber) => {
                setFormContent({
                  ...formContent,
                  followUpFaxNumber: faxNumber,
                });
              }}
              error={attemptedSubmit && !VALIDATION_MAP["faxInputField"]}
              isAutofilled={faxNumberIsAutofilled}
              helperText={attemptedSubmit && !VALIDATION_MAP["faxInputField"] && "Fax number must be 10 digits"}
              hideExtensionField
              width={274}
            />
          </Grid>
          <StyledOscarFaxInputCaption>
            Enter the fax number intended to receive follow up questions and determination letters (typically the
            requesting provider’s fax number)
          </StyledOscarFaxInputCaption>
        </Grid>
      </SRFormConfigFieldWrapper>
    </Grid>
  );
}

export default function ProvidersCardWrapper({ isDiagnosisAndProviderCardOnContinuation, ...props }: Props) {
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  return !isDiagnosisAndProviderCardOnContinuation && shouldUseCohereCard() && !isFaxAuthBuilderFlow ? (
    <CohereCard>
      <ProvidersCard isDiagnosisAndProviderCardOnContinuation={isDiagnosisAndProviderCardOnContinuation} {...props} />
    </CohereCard>
  ) : (
    <ProvidersCard isDiagnosisAndProviderCardOnContinuation={isDiagnosisAndProviderCardOnContinuation} {...props} />
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SubHeading = styled(H6)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ProviderHeading = styled(H4)(({ theme }) => ({
  paddingTop: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledOscarFaxInputCaption = styled(Caption)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "flex",
  alignItems: "center",
  maxWidth: 450,
}));

const useStyles = makeStyles((theme) => ({
  authCategoryWrapper: {
    marginTop: "4px",
  },
  root: {
    padding: "0 8px",
  },
}));
