import { H3, InlineButton } from "@coherehealth/common";
import { ReferralRequestWorflowStep } from "@coherehealth/core-platform-api";
import { Box, makeStyles } from "@material-ui/core";
import HeaderContainer from "components/AppHeader/HeaderContainer";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import ReferralHeaderIcon from "components/images/ReferralHeaderIcon";

interface HeaderProps {
  headerHeightWithNavButtons: number;
  workflowStep: ReferralRequestWorflowStep;
  navigateBack: () => void;
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1),
    width: "100%",
  },
  headerWithBackBtn: { display: "inline-flex", alignItems: "center", width: "68%", gap: theme.spacing(1) },
  backSection: {
    width: "32%",
    alignSelf: "center",
  },
}));
export default function ReferralHeader({ headerHeightWithNavButtons, workflowStep, navigateBack }: HeaderProps) {
  const classes = useStyles();
  const HEADER_MAP: Record<ReferralRequestWorflowStep, string> = {
    REFERRAL_REQUEST_DETAILS: "Tell us about your referral",
    REFERRAL_REQUEST_REVIEW: "Review referral before submitting",
  };
  const headerTitle = HEADER_MAP[workflowStep];
  return (
    <>
      <HeaderContainer height={headerHeightWithNavButtons}>
        {workflowStep === "REFERRAL_REQUEST_REVIEW" && (
          <Box className={classes.backSection}>
            <InlineButton onClick={navigateBack} startIcon={<NavigateBeforeIcon fontSize="large" />} data-public>
              Back
            </InlineButton>
          </Box>
        )}
        <Box className={workflowStep === "REFERRAL_REQUEST_REVIEW" ? classes.headerWithBackBtn : classes.header}>
          <ReferralHeaderIcon
            style={{
              width: 32,
              height: 32,
            }}
          />
          <H3>{headerTitle}</H3>
        </Box>
      </HeaderContainer>
    </>
  );
}
