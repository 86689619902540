import { Card, DATE_FORMAT, isDateValidWithFormat, useFeature } from "@coherehealth/common";
import {
  Address,
  NameTinPair,
  NewUserJoinExistingOrganizationResponse,
  OnboardingPatientInfo,
  PhoneNumber,
  useCreateOnboardingUserActivity,
  useNewUserJoinExistingOrganization,
  useSsoUserJoinExistingOrganization,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import Grid, { GridProps } from "@material-ui/core/Grid";
import MuiDivider from "@material-ui/core/Divider";
import { AvailityUserInfo } from "UserContext";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { GetDataError } from "restful-react";
import { error } from "../../logger";
import { OktaAuth } from "@okta/okta-auth-js";
import config from "../../api/config";
import { useSnackbar } from "notistack";
import usePostOnboardingRedirectUrl from "./usePostOnboardingUrl";
import { useNavigate } from "react-router-dom";

export interface UserInfoState {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  phoneNumber: PhoneNumber;
  faxNumber: PhoneNumber;
  userSpecialties: string[];
  isAdmin?: boolean;
}

export const INITIAL_USER_INFO = {
  firstName: "",
  lastName: "",
  title: "",
  email: "",
  phoneNumber: {
    number: "",
    extension: "",
  },
  faxNumber: {
    number: "",
  },
  isAdmin: undefined,
  userSpecialties: [],
};

export interface ProviderOrgInfo {
  name: string;
  tin: string;
  npi: string;
  otherTins: Array<NameTinPair>;
  phoneNumber: PhoneNumber;
  faxNumber: PhoneNumber;
  website: string;
  primaryAddress: Address;
  organizationSpecialties: string[];
  organizationSpecialty: string;
  organizationStructure: string;
  healthPlans: Array<string>;
}

export const INITIAL_PROVIDER_ORG_INFO = {
  name: "",
  tin: "",
  npi: "",
  otherTins: [],
  phoneNumber: {
    number: "",
    extension: "",
  },
  faxNumber: {
    number: "",
  },
  website: "",
  primaryAddress: {
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
  },
  organizationSpecialties: [],
  organizationSpecialty: "",
  organizationStructure: "",
  healthPlans: [],
};

export type OnboardingSteps =
  | "NOT_NEEDED"
  | "START"
  | "SEARCH"
  | "SELECTION"
  | "SCOPE_QUESTIONS"
  | "USER_ORG_INFO"
  | "VERIFICATION_START"
  | "VERIFICATION_BY_PATIENT_INFO_CREATE_ORG"
  | "VERIFICATION_BY_PATIENT_INFO_JOIN_ORG"
  | "VERIFICATION_LOADING"
  | "VERIFICATION_SUCCESS"
  | "OUT_OF_SCOPE_WARNING"
  | "NON_ADMIN_WARNING"
  | "EXISTING_ORG_USER_INFO"
  | "VERIFICATION_UNDER_REVIEW"
  | "ADMIN_ASSISTANCE"
  | "AUTO_VERIFICATION_SUCCESS";

// todo this seems a bit flaky, see if we can get a better solution
export function emailToDomain(email: string): string {
  return email.includes("@") ? email.split("@")[1] : "";
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const FormCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
}));

// todo it would be nice to get a common component for these form sections which have extra vertical padding
// eslint-disable-next-line cohere-react/no-mui-styled-import
export const FormSection = styled((props: GridProps) => <Grid container spacing={2} {...props} />)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const FormTitleDivider = styled(MuiDivider)(({ theme }) => ({ margin: theme.spacing(3, 0) }));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const ContentsContainer = styled("div")({
  maxWidth: "1280px",
  alignSelf: "center",
  padding: "0px 100px",
  margin: "0 auto",
  width: "100%",
});

export interface AvailityInfo extends AvailityUserInfo {
  isAvailityUser: boolean;
}

export const INITIAL_AVAILITY_INFO = {
  AvailityCustomerPhone: "",
  AvailityCustomerId: "",
  AvailityCustomerAddress: "",
  AvailityCustomerNPIs: "",
  AvailityCustomerTINs: "",
  AvailityCustomerName: "",
  AvailityCustomerFax: "",
  AvailityUserPhone: "",
  AvailityUserName: "",
  AvailityUserLastName: "",
  AvailityUserFirstName: "",
  AvailityCohereRoles: "",
  isAvailityUser: false,
};

export function isMemberIdLengthValid(memberId?: string): boolean {
  if (memberId) {
    return memberId.length < 25;
  }
  return false;
}

export function isMemberDobValid(memberDob?: string): boolean {
  return memberDob?.length === 10 && isDateValidWithFormat(memberDob, DATE_FORMAT);
}

export const AVAILITY_AUTO_FILLED_TOOLTIP = "Auto-filled based on your Availity account information";

export const useStyles = makeStyles((theme) => ({
  icon: {
    paddingTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  toolTip: {
    marginTop: theme.spacing(1),
  },
  formGroupDivider: {
    marginTop: theme.spacing(1),
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const StyledEndAdornmentContainer = styled("div")(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.grey[100],
    borderRadius: "50%",
  },
}));
/**
 * Simple check that email address matches a user@domain.domain format
 * This regex is not comprehensive
 * @param email
 */
export function isEmailValid(email: string): boolean {
  return email.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z0-9]{2,}$/g) !== null;
}

export function useRenewAccessToken(): { renewAccessToken: () => void; oktaAuthClientLoading: boolean } {
  const [oktaAuthClientLoading, setOktaAuthClientLoading] = useState<boolean>(false);

  const PKCE_AVAILABLE = Boolean(window.TextEncoder); // IE11 does not have any web security and is a horrible browser
  const oktaAuthClient: OktaAuth = new OktaAuth({
    issuer: `${config.AUTH_API_URL}oauth2/default`,
    clientId: config.AUTH_CLIENT_ID,
    redirectUri: window.location.origin + "/implicit/callback",
    pkce: PKCE_AVAILABLE,
  });

  const renewAccessToken = async () => {
    try {
      setOktaAuthClientLoading(true);
      await oktaAuthClient.tokenManager.renew("accessToken");
      setOktaAuthClientLoading(false);
    } catch (e) {
      error(e);
    }
  };

  return {
    renewAccessToken,
    oktaAuthClientLoading,
  };
}

interface UseJoinExistingOrganizationProps {
  userInfo: UserInfoState;
  isAvailityUser: boolean;
  autoVerificationEnabled?: boolean;
  organizationId: string;
  organizationTin: string;
  organizationName: string;
  renewAccessToken: () => void;
  setCurrentStep: Dispatch<SetStateAction<OnboardingSteps>>;
}

interface UseJoinExistingOrganizationReturn {
  joinExistingOrganization: (
    memberIdValidationInfoList?: OnboardingPatientInfo[]
  ) => Promise<NewUserJoinExistingOrganizationResponse>;
  userJoinExistingOrganizationLoading: boolean;
  userJoinExistingOrganizationError: GetDataError<unknown> | null;
}

/**
 * Convenience hook that returns a callback which does everything needed for a user to join an organization.
 * This API works for both manual verification and member ID verification (by providing the member ID information)
 *
 * @param userInfo The user info entered in the ExistingOrgUserInfoPage
 * @param isAvailityUser True if the user is from Availity SSO
 * @param autoVerificationEnabled
 * @param organizationId The database ID of the organization trying to join
 * @param organizationTin The TIN of the organization trying to join
 * @param organizationName The name of the organization trying to join
 * @param renewAccessToken A callback which refreshes the Okta access token (see useRenewAccessToken)
 * @param setCurrentStep A set state action for the onboarding current step
 */
export function useJoinExistingOrganizationApi({
  userInfo,
  isAvailityUser,
  autoVerificationEnabled,
  organizationId,
  organizationTin,
  organizationName,
  renewAccessToken,
  setCurrentStep,
}: UseJoinExistingOrganizationProps): UseJoinExistingOrganizationReturn {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { returnTo } = usePostOnboardingRedirectUrl();
  const onboardingRedesignEnabled = useFeature("onboardingRedesign");

  const { mutate: trackUserInteraction } = useCreateOnboardingUserActivity({});
  const {
    mutate: newUserJoinExistingOrganization,
    loading: newUserJoinExistingOrganizationLoading,
    error: newUserJoinExistingOrgError,
  } = useNewUserJoinExistingOrganization({});
  const {
    mutate: ssoUserJoinExistingOrganization,
    loading: ssoUserJoinExistingOrganizationLoading,
    error: ssoUserJoinExistingOrgError,
  } = useSsoUserJoinExistingOrganization({});

  const userJoinExistingOrganization = isAvailityUser
    ? ssoUserJoinExistingOrganization
    : newUserJoinExistingOrganization;

  const userJoinExistingOrganizationLoading = isAvailityUser
    ? ssoUserJoinExistingOrganizationLoading
    : newUserJoinExistingOrganizationLoading;

  const userJoinExistingOrganizationError = useMemo(
    () => (isAvailityUser ? ssoUserJoinExistingOrgError : newUserJoinExistingOrgError),
    [isAvailityUser, ssoUserJoinExistingOrgError, newUserJoinExistingOrgError]
  );

  const joinExistingOrganization = async (memberIdValidationInfoList?: OnboardingPatientInfo[]) => {
    const response = await userJoinExistingOrganization({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      title: userInfo.title,
      email: userInfo.email,
      phoneNumber: userInfo.phoneNumber.number,
      phoneExtension: userInfo.phoneNumber.extension,
      faxNumber: userInfo.faxNumber.number,
      userSpecialties: userInfo.userSpecialties,
      organizationId,
      memberIdValidationInfoList,
    });
    if (response.autoVerified) {
      await trackUserInteraction({
        event: "EMAIL_VERIFICATION",
        stage: "ORGANIZATION_ONBOARDING_FLOW",
        type: "INTERACTION",
        activityContext: {
          onboardingAutoVerificationStatus: {
            enabled: autoVerificationEnabled,
            userEmail: userInfo.email,
            organizationTin: organizationTin,
            organizationName: organizationName,
            successful: true,
          },
        },
      });
      if (isAvailityUser) {
        await renewAccessToken();
        navigate(returnTo);
        enqueueSnackbar("Your account has been verified", { variant: "success" });
      } else {
        setCurrentStep("AUTO_VERIFICATION_SUCCESS");
      }
    } else if (response.newUserCreated) {
      // todo needs to go to onboarding success page instead
      setCurrentStep("AUTO_VERIFICATION_SUCCESS");
    } else {
      await trackUserInteraction({
        event: "EMAIL_VERIFICATION",
        stage: "ORGANIZATION_ONBOARDING_FLOW",
        type: "INTERACTION",
        activityContext: {
          onboardingAutoVerificationStatus: {
            enabled: autoVerificationEnabled,
            userEmail: userInfo.email,
            organizationTin: organizationTin,
            organizationName: organizationName,
            successful: false,
          },
        },
      });
      if (isAvailityUser) {
        await renewAccessToken();
        navigate(returnTo);
      } else {
        if (response.manualReviewCaseCreated) {
          if (onboardingRedesignEnabled) {
            setCurrentStep("VERIFICATION_BY_PATIENT_INFO_JOIN_ORG");
          } else {
            setCurrentStep("ADMIN_ASSISTANCE");
          }
        }
      }
    }
    return response;
  };

  return {
    joinExistingOrganization,
    userJoinExistingOrganizationLoading,
    userJoinExistingOrganizationError,
  };
}
