import React, { Dispatch, PropsWithChildren, useState } from "react";
import {
  ServiceRequestResponse,
  PhoneNumber,
  RuleActions,
  RecommendChangeRuleAction,
  AuthorizationResponse,
  ServiceRequestSearchResponse,
} from "@coherehealth/core-platform-api";
import {
  Body1,
  Caption,
  formatDateStr,
  parseDateFromISOString,
  useFeature,
  POLICY_UNITED_STATES_OPTIONS,
  OSCAR_HEALTH_PLAN_NAME,
  formatDateToISODate,
  plusDays,
  useGeneralAuthSubmissionWorflowOn,
  H6,
  TertiaryButton,
  colorsLight,
  useConfiguration,
} from "@coherehealth/common";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, makeStyles } from "@material-ui/core/styles";
import { GridSize } from "@material-ui/core";
import { formatPhone } from "util/demographicUtils";
import {
  requestTimingToString,
  calculateDecisionedLengthOfStay,
  calculateRequestedLengthOfStay,
} from "util/authorization";
import ContactInfoModal, { ContactInfoModalProps } from "components/ContactInfoModal";
import {
  getAdditionalCareParticipantName,
  getDatesOfServiceString,
  getNumberOfServiceDatesString,
  getPendingAuthStatuses,
  oscarBHAdmissionTypeLabel,
  oscarBHReviewTypeLabel,
  oscarCaseTypeLabel,
  oscarServiceTypeLabel,
  safelyGetFormConfig,
  unitTypeLabel,
} from "util/serviceRequest";
import { differenceInDays, format } from "date-fns";
import {
  DEFAULT_FORM_CONFIG,
  FormConfiguration,
} from "../ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import { isRecommendChangeActionWithAttribute } from "util/rule";
import ContentNudge from "./ContentNudge";
import { strToPhoneNumber } from "util/phoneUtils";
import { getLatestServiceRequest } from "util/authorization";
import { getPatientHealthPlanName } from "util/patientUtils";
import PatientStayModal from "components/PatientStayModal";
import { useGetOtherInsuranceConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { SRFormConfigFieldWrapper } from "common/FormConfigUtils";
import { LocationNetworkStatusPill } from "../utils/serviceRequestUtils";

interface Props {
  authorization?: AuthorizationResponse;
  serviceRequest: ServiceRequestResponse;
  hideFacilityState?: boolean;
  ruleActions?: RuleActions;
  isFacilityBasedAuth?: boolean;
  clinicalReviewView?: boolean;
  isFaxEdit?: boolean;
}
const useStyle = makeStyles((theme) => ({
  OutOfNetworkExceptionReasonStyle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.75),
    color: theme.palette.text.secondary,
    display: "inline-block",
  },
  facilityBasedHeader: {
    padding: "19px 0px 5.5px",
  },
  bodyChildrenContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  labelContainer: {
    color: theme.palette.text.secondary,
    display: "inline-block",
  },
}));

export default function RequestDetails({
  serviceRequest,
  hideFacilityState,
  ruleActions,
  authorization,
  isFacilityBasedAuth,
  clinicalReviewView,
  isFaxEdit,
}: Props) {
  const simplifiedServiceFrequency = useFeature("simplifiedServiceFrequency");
  const oonReviewConfig = useConfiguration("outOfNetworkReviewConfiguration", serviceRequest.healthPlanName);
  const simplifiedServiceFrequencyOFF = !simplifiedServiceFrequency;

  const closeModal = () => setModalState((prev) => ({ ...prev, open: false }));
  const [modalState, setModalState] = useState<ContactInfoModalProps>({
    open: false,
    handleClose: () => {
      setModalState((prev) => ({ ...prev, open: false }));
    },
    type: "FACILITY",
  });

  const startDate = serviceRequest?.startDate ? new Date(serviceRequest?.startDate) : new Date();
  const healthPlanName = getPatientHealthPlanName(serviceRequest?.patient, startDate) || "";
  const generalAuthSubmissionWorkflowEnabled = useGeneralAuthSubmissionWorflowOn(healthPlanName);

  const facilityOutOfNetworkStatusDisplayOff =
    serviceRequest.formConfiguration?.facilityOutOfNetworkStatusDisplay?.fieldSpec === "OMIT";
  const performingProviderOutOfNetworkStatusDisplayOff =
    serviceRequest.formConfiguration?.performingProviderOutOfNetworkStatusDisplay?.fieldSpec === "OMIT";
  const orderingProviderOutOfNetworkStatusDisplayOff =
    serviceRequest.formConfiguration?.orderingProviderOutOfNetworkStatusDisplay?.fieldSpec === "OMIT";

  const providerIsOutOfNetwork = serviceRequest.performingProviderLocation?.isOutOfNetwork;
  const oonExceptionPerformingProviderReason = serviceRequest.performingProviderLocation?.outOfNetworkExceptionReason;
  const oonExceptionPerformingProviderComment = serviceRequest.performingProviderLocation?.outOfNetworkExceptionComment;

  const facilityIsOutOfNetwork = serviceRequest.facilityLocation?.isOutOfNetwork;
  const oonExceptionFacilityReason = serviceRequest.facilityLocation?.outOfNetworkExceptionReason;
  const oonExceptionFacilityComment = serviceRequest.facilityLocation?.outOfNetworkExceptionComment;

  const orderingProviderIsOutOfNetwork = serviceRequest.orderingProviderLocation?.isOutOfNetwork;
  const oonExceptionOrderingProviderReason = serviceRequest.orderingProviderLocation?.outOfNetworkExceptionReason;
  const oonExceptionOrderingProviderComment = serviceRequest.orderingProviderLocation?.outOfNetworkExceptionComment;

  const formConfiguration = safelyGetFormConfig(serviceRequest?.formConfiguration || DEFAULT_FORM_CONFIG);
  const numberOfServiceDates = getNumberOfServiceDatesString(serviceRequest);
  let numberOfServiceDatesContinuations: string = numberOfServiceDates;
  const latestSr = authorization ? getLatestServiceRequest(authorization)?.[1] : undefined;
  const outOfNetworkStatusOverrideEnabled = oonReviewConfig?.enabled && oonReviewConfig?.networkStatusOverride;

  if (authorization) {
    let requestedUnits = authorization.units || 0;
    let approvedUnits = authorization.approvedUnits || 0;
    if (approvedUnits === requestedUnits) {
      numberOfServiceDatesContinuations = `${approvedUnits} approved`;
    } else if (approvedUnits === 0 && requestedUnits > 0) {
      numberOfServiceDatesContinuations = `${requestedUnits} requested`;
    } else if (approvedUnits > 0 && requestedUnits > 0 && approvedUnits !== requestedUnits) {
      numberOfServiceDatesContinuations = `${requestedUnits} requested, ${approvedUnits} approved`;
    }
    if (latestSr?.authStatus === "DRAFT" && authorization.serviceRequestsOnAuth?.length === 1) {
      numberOfServiceDatesContinuations = `${latestSr.units}`;
    }
  }
  const estimatedEndDate = serviceRequest.endDate
    ? formatDateStr(serviceRequest.endDate)
    : formatDateToISODate(plusDays(90, serviceRequest.startDate));

  const { otherInsuranceEnabled } = useGetOtherInsuranceConfigurationByPayer({
    healthPlanName: healthPlanName ?? "",
  });
  const classes = useStyle();

  return isFacilityBasedAuth ? (
    <>
      <FacilityBasedRequestDetails
        serviceRequest={serviceRequest}
        authorization={authorization}
        formConfiguration={formConfiguration}
        ruleActions={ruleActions}
        isFacilityBasedAuth={isFacilityBasedAuth}
        setModalState={setModalState}
        closeModal={closeModal}
        oonExceptionPerformingProviderReason={oonExceptionPerformingProviderReason}
        oonExceptionPerformingProviderComment={oonExceptionPerformingProviderComment}
        oonExceptionFacilityReason={oonExceptionFacilityReason}
        oonExceptionFacilityComment={oonExceptionFacilityComment}
        oonExceptionOrderingProviderReason={oonExceptionOrderingProviderReason}
        oonExceptionOrderingProviderComment={oonExceptionOrderingProviderComment}
        providerIsOutOfNetwork={providerIsOutOfNetwork}
        facilityIsOutOfNetwork={facilityIsOutOfNetwork}
        orderingProviderIsOutOfNetwork={orderingProviderIsOutOfNetwork}
        latestSr={latestSr}
        isFaxEdit={isFaxEdit}
        outOfNetworkStatusDisplayOff={facilityOutOfNetworkStatusDisplayOff}
      />
      <ContactInfoModal
        open={modalState.open}
        handleClose={modalState.handleClose}
        type={modalState.type}
        name={modalState.name}
        phones={modalState.phones}
        fax={modalState.fax}
        email={modalState.email}
        npi={modalState.npi}
        tinList={modalState.tinList}
        addresses={modalState.addresses}
        npiLabel={modalState.npiLabel}
      />
    </>
  ) : (
    <>
      <SRFormConfigFieldWrapper {...formConfiguration?.prescribedDrug}>
        <DetailsField label={"Quantity"} value={serviceRequest.prescribedDrug?.quantity || "-"} />
        <DetailsField label={"Days supply"} value={serviceRequest.prescribedDrug?.daysSupply + " days" || "-"} />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formConfiguration?.primaryDiagnosis}>
        <DetailsField
          label={"Primary diagnosis"}
          value={`${serviceRequest.primaryDiagnosis?.code}
            ${serviceRequest.primaryDiagnosis?.description ? " - " + serviceRequest.primaryDiagnosis?.description : ""}
          `}
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            .find((action) => action.onAcceptAttribute === "primaryDiagnosis")}
        />
      </SRFormConfigFieldWrapper>

      <SRFormConfigFieldWrapper {...formConfiguration?.secondaryDiagnoses}>
        <DetailsField
          label={"Secondary diagnosis"}
          value={
            serviceRequest.secondaryDiagnoses ? serviceRequest.secondaryDiagnoses.map((d) => d.code).join(", ") : "--"
          }
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            .find((action) => action.onAcceptAttribute === "secondaryDiagnosis")}
        />
      </SRFormConfigFieldWrapper>

      {/* Only show this field if it _is_ an oon exception request, otherwise hide the whole field */}
      {serviceRequest?.userSelectedOONException && (
        <>
          <SRFormConfigFieldWrapper {...formConfiguration?.userDeclaredOONException}>
            <DetailsField label={"Out-of-network exception"} value="Yes" />
          </SRFormConfigFieldWrapper>
        </>
      )}

      {simplifiedServiceFrequencyOFF && (
        <SRFormConfigFieldWrapper {...formConfiguration?.startEndDate}>
          <DetailsField
            label={"Expected service start date"}
            value={serviceRequest.startDate && formatDateStr(serviceRequest.startDate)}
          />

          {serviceRequest.recurrenceType === "RECURRING" && (
            <>
              <DetailsField label={"Expected service end date"} value={estimatedEndDate} />
            </>
          )}
        </SRFormConfigFieldWrapper>
      )}

      <SRFormConfigFieldWrapper {...formConfiguration?.encounterType}>
        <DetailsField
          label={"Care setting"}
          value={serviceRequest.encounterType === "INPATIENT" ? "Inpatient" : "Outpatient"}
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            .find(
              (action) =>
                action.onAcceptAttribute === "encounterType" &&
                action.onAcceptValue?.value !== serviceRequest.encounterType
            )}
        />
      </SRFormConfigFieldWrapper>

      {simplifiedServiceFrequency && (
        <>
          <SRFormConfigFieldWrapper
            forceShow={serviceRequest.healthPlanName === OSCAR_HEALTH_PLAN_NAME}
            {...formConfiguration?.placeOfService}
          >
            <DetailsField
              label={"Place of service"}
              value={serviceRequest.placeOfService?.name}
              ruleNudge={ruleActions
                ?.filter(isRecommendChangeActionWithAttribute)
                .find((action) => action.onAcceptAttribute === "placeOfService")}
            />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper {...formConfiguration?.units}>
            {!generalAuthSubmissionWorkflowEnabled && (
              <DetailsField
                label={"Number of visits"}
                value={numberOfServiceDatesContinuations}
                ruleNudge={ruleActions
                  ?.filter(isRecommendChangeActionWithAttribute)
                  .find((action) => action.onAcceptAttribute === "units")}
              />
            )}
          </SRFormConfigFieldWrapper>
        </>
      )}

      {simplifiedServiceFrequencyOFF && (
        <>
          <SRFormConfigFieldWrapper forceShow={serviceRequest.healthPlanName === OSCAR_HEALTH_PLAN_NAME}>
            <DetailsField
              label={"Procedure codes included"}
              value={serviceRequest.procedureCodes ? "Yes" : "No"}
              // From the check box on the first page of the SR creation that says "No procedure codes"
            />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper forceShow={serviceRequest.healthPlanName === OSCAR_HEALTH_PLAN_NAME}>
            <DetailsField label={"Procedure"} value={serviceRequest.clinicalService?.name} />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper forceShow={serviceRequest.healthPlanName === OSCAR_HEALTH_PLAN_NAME}>
            <DetailsField
              label={"Service type"}
              value={serviceRequest.serviceType && oscarServiceTypeLabel(serviceRequest.serviceType)}
            />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper
            forceShow={serviceRequest.healthPlanName === OSCAR_HEALTH_PLAN_NAME}
            {...formConfiguration?.placeOfService}
          >
            <DetailsField
              label={"Place of service"}
              value={serviceRequest.placeOfService?.name}
              ruleNudge={ruleActions
                ?.filter(isRecommendChangeActionWithAttribute)
                .find((action) => action.onAcceptAttribute === "placeOfService")}
            />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper forceShow={serviceRequest.healthPlanName === OSCAR_HEALTH_PLAN_NAME}>
            <DetailsField
              label={"Case type"}
              value={
                serviceRequest.clinicalService?.caseType && oscarCaseTypeLabel(serviceRequest.clinicalService?.caseType)
              }
            />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper {...formConfiguration?.admissionDischargeDate}>
            <DetailsField
              label={"Admission date"}
              value={serviceRequest.admissionDate && formatDateStr(serviceRequest.admissionDate)}
            />

            <DetailsField
              label={"Expected discharge date"}
              value={serviceRequest.expectedDischargeDate && formatDateStr(serviceRequest.expectedDischargeDate)}
            />

            <DetailsField
              label={"Inpatient days"}
              value={
                //subtracts the end date from the start date
                serviceRequest.expectedDischargeDate &&
                serviceRequest.admissionDate &&
                differenceInDays(
                  parseDateFromISOString(serviceRequest.expectedDischargeDate),
                  parseDateFromISOString(serviceRequest.admissionDate)
                ).toString()
              }
            />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper {...formConfiguration?.behavioralHealthAdmissionType}>
            <DetailsField
              label={"BH admission type"}
              value={
                serviceRequest.behavioralHealthAdmissionType &&
                oscarBHAdmissionTypeLabel(serviceRequest.behavioralHealthAdmissionType)
              }
            />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper {...formConfiguration?.behavioralHealthReviewType}>
            <DetailsField
              label={"BH review type"}
              value={
                serviceRequest.behavioralHealthReviewType &&
                oscarBHReviewTypeLabel(serviceRequest.behavioralHealthReviewType)
              }
            />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper {...formConfiguration?.recurring}>
            <DetailsField
              label={"Service frequency"}
              value={
                serviceRequest.recurrenceType && serviceRequest.recurrenceType === "RECURRING"
                  ? "Recurring"
                  : "One-time"
              }
            />
          </SRFormConfigFieldWrapper>

          <SRFormConfigFieldWrapper {...formConfiguration?.units}>
            <DetailsField
              label={"Unit quantity"}
              value={serviceRequest.units ? serviceRequest.units.toString() : "1"}
            />

            {serviceRequest.recurrenceType === "RECURRING" && (
              <>
                <DetailsField
                  label={"Unit type"}
                  value={serviceRequest.unitType ? unitTypeLabel(serviceRequest.unitType) : undefined}
                />
              </>
            )}
          </SRFormConfigFieldWrapper>
        </>
      )}
      <SRFormConfigFieldWrapper {...formConfiguration?.orderingProvider}>
        <DetailsField
          label={"Ordering provider"}
          subTextChildren={
            <>
              {oonExceptionOrderingProviderReason && (
                <Caption className={classes.OutOfNetworkExceptionReasonStyle} data-public>
                  Exception reason: {oonExceptionOrderingProviderReason}
                </Caption>
              )}
              {oonExceptionOrderingProviderComment && (
                <Caption className={classes.labelContainer} data-public>
                  Comment: {oonExceptionOrderingProviderComment}
                </Caption>
              )}
            </>
          }
          bodyChildren={
            <>
              {serviceRequest?.orderingProvider ? (
                <>
                  <Body1 className={classes.bodyChildrenContainer} data-public>
                    <span>{serviceRequest.orderingProvider?.name?.concat(" / ")}</span>
                    <span>{`NPI - ${serviceRequest.orderingProvider?.npi || "N/A"}`}</span>
                  </Body1>
                </>
              ) : undefined}
            </>
          }
        >
          {serviceRequest.orderingProvider && (
            <>
              <ContactButton
                onClick={() => {
                  setModalState({
                    open: true,
                    name: serviceRequest.orderingProvider?.name || "None",
                    phones: serviceRequest.orderingProvider?.phoneNumbers,
                    fax: serviceRequest.orderingProviderLocation?.faxNumbers?.map(strToPhoneNumber),
                    tinList: serviceRequest.orderingProvider?.tinList,
                    npi: serviceRequest.orderingProvider?.npi,
                    addresses: [serviceRequest.orderingProviderLocation?.address],
                    type: "ORDERING_PROVIDER",
                    npiLabel: serviceRequest.orderingProvider?.npi || "N/A",
                    handleClose: closeModal,
                  });
                }}
              >
                {contactInfoButtonText(serviceRequest.orderingProvider?.phoneNumbers, undefined)}
              </ContactButton>
              <LocationNetworkStatusPill
                location={serviceRequest.orderingProviderLocation ?? undefined}
                config={{
                  outOfNetworkStatusDisplayOff: orderingProviderOutOfNetworkStatusDisplayOff,
                  locationIsOutOfNetwork: orderingProviderIsOutOfNetwork,
                  outOfNetworkStatusOverrideEnabled,
                }}
              />
            </>
          )}
        </DetailsField>
      </SRFormConfigFieldWrapper>

      <SRFormConfigFieldWrapper {...formConfiguration?.performingProvider}>
        <DetailsField
          label={"Performing or attending provider"}
          subTextChildren={
            <>
              {oonExceptionPerformingProviderReason && (
                <Caption className={classes.OutOfNetworkExceptionReasonStyle} data-public>
                  Exception reason: {oonExceptionPerformingProviderReason}
                </Caption>
              )}
              {oonExceptionPerformingProviderComment && (
                <Caption className={classes.labelContainer} data-public>
                  Comment: {oonExceptionPerformingProviderComment}
                </Caption>
              )}
            </>
          }
          bodyChildren={
            <>
              {serviceRequest.performingProvider ? (
                <>
                  <Body1 className={classes.bodyChildrenContainer} data-public>
                    <span>{serviceRequest.performingProvider?.name?.concat(" / ")}</span>
                    <span>{`NPI - ${serviceRequest.performingProvider?.npi || "N/A"}`}</span>
                  </Body1>
                </>
              ) : (
                <Body1>--</Body1>
              )}
            </>
          }
        >
          {serviceRequest.performingProvider && (
            <>
              <ContactButton
                onClick={() => {
                  setModalState({
                    open: true,
                    name: serviceRequest.performingProvider?.name || "None",
                    phones: serviceRequest.performingProvider?.phoneNumbers,
                    fax: serviceRequest.performingProviderLocation?.faxNumbers?.map(strToPhoneNumber),
                    type: "PERFORMING_PROVIDER",
                    tinList: serviceRequest.performingProvider?.tinList,
                    npi: serviceRequest.performingProvider?.npi,
                    addresses: [serviceRequest.performingProviderLocation?.address],
                    npiLabel: serviceRequest.performingProvider?.npi || "N/A",
                    handleClose: closeModal,
                  });
                }}
              >
                {contactInfoButtonText(serviceRequest.performingProvider?.phoneNumbers, undefined)}
              </ContactButton>
              <LocationNetworkStatusPill
                location={serviceRequest?.performingProviderLocation ?? undefined}
                config={{
                  outOfNetworkStatusDisplayOff: performingProviderOutOfNetworkStatusDisplayOff,
                  locationIsOutOfNetwork: providerIsOutOfNetwork,
                  outOfNetworkStatusOverride: serviceRequest?.networkStatusOverride?.isOutOfNetworkPerformingProvider,
                  outOfNetworkStatusOverrideEnabled,
                }}
              />
            </>
          )}
        </DetailsField>
      </SRFormConfigFieldWrapper>

      <SRFormConfigFieldWrapper {...formConfiguration?.facility}>
        <DetailsField
          label={"Performing facility or agency"}
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            .find((action) => action.onAcceptAttribute === "facility")}
          subTextChildren={
            <>
              {oonExceptionFacilityReason && (
                <Caption className={classes.OutOfNetworkExceptionReasonStyle} data-public>
                  Exception reason: {oonExceptionFacilityReason}
                </Caption>
              )}
              {oonExceptionFacilityComment && (
                <Caption className={classes.labelContainer} data-public>
                  Comment: {oonExceptionFacilityComment}
                </Caption>
              )}
            </>
          }
          bodyChildren={
            <>
              {serviceRequest.facility ? (
                <>
                  <Body1 className={classes.bodyChildrenContainer} data-public>
                    <span>{serviceRequest.facility?.name?.concat(" / ")}</span>
                    <span>{`NPI - ${serviceRequest.facility?.npi || "N/A"}`}</span>
                  </Body1>
                </>
              ) : undefined}
            </>
          }
        >
          {serviceRequest.facility && (
            <>
              <ContactButton
                onClick={() => {
                  setModalState({
                    open: true,
                    name: serviceRequest.facility?.name || "None",
                    phones: serviceRequest.facility?.phoneNumbers,
                    fax: serviceRequest.facilityLocation?.faxNumbers?.map(strToPhoneNumber),
                    type: "FACILITY",
                    tinList: serviceRequest.facility?.tinList,
                    npi: serviceRequest.facility?.npi,
                    addresses: [serviceRequest.facilityLocation?.address],
                    npiLabel: serviceRequest.facility?.npi || "N/A",
                    handleClose: closeModal,
                  });
                }}
              >
                {contactInfoButtonText(serviceRequest.facility?.phoneNumbers, undefined)}
              </ContactButton>
              <LocationNetworkStatusPill
                location={serviceRequest.facilityLocation ?? undefined}
                config={{
                  outOfNetworkStatusDisplayOff: facilityOutOfNetworkStatusDisplayOff,
                  locationIsOutOfNetwork: facilityIsOutOfNetwork,
                  outOfNetworkStatusOverride: serviceRequest?.networkStatusOverride?.isOutOfNetworkFacility,
                  outOfNetworkStatusOverrideEnabled,
                }}
              />
            </>
          )}
        </DetailsField>
      </SRFormConfigFieldWrapper>

      {!hideFacilityState && (
        <SRFormConfigFieldWrapper {...formConfiguration?.startEndDate}>
          <DetailsField
            label={"Facility state"}
            value={
              POLICY_UNITED_STATES_OPTIONS?.find(
                (opt) => opt.id === serviceRequest?.selectedFacility?.selectedLocation?.address?.state
              )?.label
            }
          />
        </SRFormConfigFieldWrapper>
      )}

      <SRFormConfigFieldWrapper {...formConfiguration?.userSelectedOONException}>
        <DetailsField
          label="Out-of-network waiver requested"
          value={Boolean(serviceRequest.userSelectedOONException) ? "Yes" : "No"}
        />
      </SRFormConfigFieldWrapper>

      {serviceRequest.encounterType === "OUTPATIENT" && (
        <DetailsField
          label={"Dates of service"}
          value={getDatesOfServiceString(serviceRequest, authorization, isFaxEdit)}
        />
      )}
      {serviceRequest.encounterType === "INPATIENT" && (
        <DetailsField
          label={serviceRequest?.expectedDischargeDate ? "Dates of service" : "Expected admission date"}
          value={
            serviceRequest?.expectedDischargeDate
              ? formatDateStr(serviceRequest.admissionDate ?? serviceRequest.startDate).concat(
                  serviceRequest.expectedDischargeDate
                    ? ` - ${formatDateStr(serviceRequest.expectedDischargeDate)}`
                    : ` - ${estimatedEndDate}`
                )
              : formatDateStr(serviceRequest?.expectedAdmissionDate ?? serviceRequest.startDate)
          }
        />
      )}

      {serviceRequest?.additionalCareParticipants?.map((careParticipant) => {
        return (
          <SRFormConfigFieldWrapper {...formConfiguration.additionalCareParticipants} key={careParticipant.id}>
            <DetailsField
              label={getAdditionalCareParticipantName(careParticipant.careParticipantType)}
              value={
                careParticipant
                  ? `${careParticipant?.name ? careParticipant.name : `None`} / ${
                      `NPI - ` + (careParticipant?.npi || "N/A")
                    }`
                  : "--"
              }
              subTextChildren={
                <>
                  {careParticipant.selectedLocation?.outOfNetworkExceptionReason && (
                    <Caption className={classes.OutOfNetworkExceptionReasonStyle} data-public>
                      Exception reason: {careParticipant.selectedLocation?.outOfNetworkExceptionReason}
                    </Caption>
                  )}
                  {careParticipant.selectedLocation?.outOfNetworkExceptionComment && (
                    <Caption className={classes.labelContainer} data-public>
                      Comment: {careParticipant.selectedLocation?.outOfNetworkExceptionComment}
                    </Caption>
                  )}
                </>
              }
            >
              {careParticipant && (
                <>
                  <ContactButton
                    onClick={() => {
                      setModalState({
                        open: true,
                        name: careParticipant?.name || "None",
                        phones: careParticipant?.phoneNumbers || careParticipant?.selectedLocation?.phoneNumbers,
                        fax: careParticipant.selectedLocation?.faxNumbers?.map(strToPhoneNumber),
                        tinList:
                          careParticipant?.tinList ||
                          (careParticipant?.selectedLocation?.tin ? [careParticipant.selectedLocation.tin] : []),
                        npi: careParticipant?.npi,
                        addresses:
                          careParticipant?.addresses ||
                          (careParticipant?.selectedLocation?.address
                            ? [careParticipant.selectedLocation.address]
                            : []),
                        type: careParticipant.careParticipantType || "ORDERING_PROVIDER",
                        npiLabel: careParticipant?.npi || "N/A",
                        handleClose: closeModal,
                      });
                    }}
                  >
                    {contactInfoButtonText(careParticipant?.phoneNumbers, undefined)}
                  </ContactButton>
                  <LocationNetworkStatusPill
                    location={careParticipant?.selectedLocation ?? undefined}
                    config={{
                      locationIsOutOfNetwork: careParticipant?.selectedLocation?.isOutOfNetwork,
                      outOfNetworkStatusOverrideEnabled,
                    }}
                  />
                </>
              )}
            </DetailsField>
          </SRFormConfigFieldWrapper>
        );
      })}

      <SRFormConfigFieldWrapper {...formConfiguration?.faxInputField}>
        <DetailsField label={"Follow-up fax number"} value={formatPhone(serviceRequest.followUpFaxNumber)} />
      </SRFormConfigFieldWrapper>

      {serviceRequest.requestType === "INITIAL" && (
        <SRFormConfigFieldWrapper {...formConfiguration?.urgency}>
          <DetailsField label={"Expedited"} value={serviceRequest.urgency?.isExpedited ? "Yes" : "No"} />
        </SRFormConfigFieldWrapper>
      )}

      {otherInsuranceEnabled && (
        <DetailsField
          label={"Additional insurance"}
          value={serviceRequest.additionalInsurance?.additionalInsuranceText || "--"}
        />
      )}
      <ContactInfoModal
        open={modalState.open}
        handleClose={modalState.handleClose}
        type={modalState.type}
        name={modalState.name}
        phones={modalState.phones}
        fax={modalState.fax}
        email={modalState.email}
        npi={modalState.npi}
        tinList={modalState.tinList}
        addresses={modalState.addresses}
        npiLabel={modalState.npiLabel}
      />
    </>
  );
}

interface FacilityBasedRequestDetailsProps {
  serviceRequest: ServiceRequestResponse;
  authorization: AuthorizationResponse | undefined;
  formConfiguration: FormConfiguration;
  ruleActions: RuleActions | undefined;
  setModalState: Dispatch<React.SetStateAction<ContactInfoModalProps>>;
  isFacilityBasedAuth: boolean;
  oonExceptionPerformingProviderReason: string | undefined;
  oonExceptionPerformingProviderComment: string | undefined;
  oonExceptionFacilityReason: string | undefined;
  oonExceptionFacilityComment: string | undefined;
  oonExceptionOrderingProviderReason: string | undefined;
  oonExceptionOrderingProviderComment: string | undefined;
  providerIsOutOfNetwork: boolean | undefined;
  facilityIsOutOfNetwork: boolean | undefined;
  orderingProviderIsOutOfNetwork: boolean | undefined;
  closeModal: () => void;
  latestSr?: ServiceRequestResponse;
  isFaxEdit: boolean | undefined;
  outOfNetworkStatusDisplayOff: boolean | undefined;
}

const FacilityBasedRequestDetails = ({
  serviceRequest,
  authorization,
  formConfiguration,
  ruleActions,
  setModalState,
  isFacilityBasedAuth,
  oonExceptionPerformingProviderReason,
  oonExceptionPerformingProviderComment,
  oonExceptionFacilityReason,
  oonExceptionFacilityComment,
  oonExceptionOrderingProviderReason,
  oonExceptionOrderingProviderComment,
  providerIsOutOfNetwork,
  facilityIsOutOfNetwork,
  orderingProviderIsOutOfNetwork,
  closeModal,
  latestSr,
  isFaxEdit,
  outOfNetworkStatusDisplayOff,
}: FacilityBasedRequestDetailsProps) => {
  const classes = useStyle();
  const oonReviewConfig = useConfiguration("outOfNetworkReviewConfiguration", serviceRequest.healthPlanName);
  const showDischargeDateAndTime = latestSr?.dischargeDate
    ? isFacilityBasedAuth && Boolean(latestSr.dischargeDate)
    : isFacilityBasedAuth && serviceRequest.patientStatus === "DISCHARGED";
  const outOfNetworkStatusOverrideEnabled = oonReviewConfig?.enabled && oonReviewConfig?.networkStatusOverride;

  const [patientStayModalOpen, setPatientStayModalOpen] = useState<boolean>(false);

  const latestPatientStatus = requestTimingToString(
    serviceRequest?.authStatus === "DRAFT" ? latestSr?.patientStatus : authorization?.patientStatus
  );

  const lengthOfDecisionedStays = calculateDecisionedLengthOfStay(authorization?.serviceRequestsOnAuth, serviceRequest);
  const lengthOfRequestedStays = calculateRequestedLengthOfStay(authorization?.serviceRequestsOnAuth, serviceRequest);

  const decisionedStaysText =
    lengthOfDecisionedStays > 0
      ? `${lengthOfRequestedStays > 0 ? "," : ""} ${lengthOfDecisionedStays} day${
          lengthOfDecisionedStays > 1 ? "s" : ""
        } previously decisioned`
      : "";
  const requestedStaysText = lengthOfRequestedStays
    ? `${lengthOfRequestedStays} day${lengthOfRequestedStays > 1 ? "s" : ""} requested`
    : ``;

  let showNextReviewDateOnPatientSummary = false;
  if (
    (serviceRequest?.patientStatus === "DISCHARGED" &&
      getPendingAuthStatuses().includes(serviceRequest?.authStatus || "")) ||
    (serviceRequest?.patientStatus === "CURRENTLY_ADMITTED" && serviceRequest?.authStatus !== "DRAFT")
  ) {
    showNextReviewDateOnPatientSummary = true;
  }

  const startDate = serviceRequest?.startDate ? new Date(serviceRequest?.startDate) : new Date();
  const healthPlanName = getPatientHealthPlanName(serviceRequest?.patient, startDate) || "";
  const { otherInsuranceEnabled } = useGetOtherInsuranceConfigurationByPayer({
    healthPlanName: healthPlanName ?? "",
  });

  const dischargedDateTime = latestSr?.dischargeDate
    ? getDischargedDateTime(latestSr, authorization, isFaxEdit)
    : getDischargedDateTime(serviceRequest, authorization, isFaxEdit);
  const dischargedTo = isFaxEdit
    ? serviceRequest.dischargedTo?.dischargedTo ?? ""
    : authorization?.dischargedTo?.dischargedTo ?? "";
  const showDischargeInfo = serviceRequest.patientStatus === "DISCHARGED" || latestSr?.dischargeDate;

  const facilityBasedConfig = useConfiguration("facilityBasedRequestConfiguration", healthPlanName);

  return (
    <>
      <SRFormConfigFieldWrapper {...formConfiguration?.primaryDiagnosis}>
        <DetailsField
          label="Primary diagnosis"
          value={`${serviceRequest.primaryDiagnosis?.code}
            ${serviceRequest.primaryDiagnosis?.description ? " - " + serviceRequest.primaryDiagnosis?.description : ""}
          `}
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            ?.find((action) => action.onAcceptAttribute === "primaryDiagnosis")}
        />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formConfiguration?.secondaryDiagnoses}>
        <DetailsField
          label="Secondary diagnosis"
          value={
            serviceRequest.secondaryDiagnoses ? serviceRequest.secondaryDiagnoses.map((d) => d.code).join(", ") : "--"
          }
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            ?.find((action) => action.onAcceptAttribute === "secondaryDiagnosis")}
        />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formConfiguration?.encounterType}>
        <DetailsField
          label="Care setting"
          value={serviceRequest.encounterType === "INPATIENT" ? "Inpatient" : "Outpatient"}
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            ?.find((action) => action.onAcceptAttribute === "encounterType")}
        />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper forceShow={isFacilityBasedAuth}>
        <DetailsField
          label="Auth category"
          value={serviceRequest.authSubcategory || serviceRequest.authCategory?.name || ""}
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            ?.find((action) => action.onAcceptAttribute === "authCategory")}
        />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper
        forceShow={serviceRequest.healthPlanName === OSCAR_HEALTH_PLAN_NAME}
        {...formConfiguration?.placeOfService}
      >
        <DetailsField
          label="Place of service"
          value={serviceRequest.placeOfService?.name}
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            ?.find((action) => action.onAcceptAttribute === "placeOfService")}
        />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formConfiguration?.orderingProvider}>
        <DetailsField
          label="Ordering provider"
          subTextChildren={
            <>
              {oonExceptionOrderingProviderReason && (
                <Caption className={classes.OutOfNetworkExceptionReasonStyle} data-public>
                  Exception reason: {oonExceptionOrderingProviderReason}
                </Caption>
              )}
              {oonExceptionOrderingProviderComment && (
                <Caption className={classes.labelContainer} data-public>
                  Comment: {oonExceptionOrderingProviderComment}
                </Caption>
              )}
            </>
          }
          bodyChildren={
            <>
              {serviceRequest?.orderingProvider ? (
                <>
                  <div className={classes.bodyChildrenContainer}>
                    <Body1 data-public>{serviceRequest.orderingProvider?.name?.concat(" / ")}</Body1>
                  </div>
                  <div>
                    <Body1 data-public>{`NPI - ${serviceRequest.orderingProvider?.npi || "N/A"}`}</Body1>
                  </div>
                </>
              ) : undefined}
            </>
          }
        >
          {serviceRequest.orderingProvider && (
            <>
              <ContactButton
                onClick={() => {
                  setModalState({
                    open: true,
                    name: serviceRequest.orderingProvider?.name || "None",
                    phones: serviceRequest.orderingProvider?.phoneNumbers,
                    fax: serviceRequest.orderingProviderLocation?.faxNumbers?.map(strToPhoneNumber),
                    tinList: serviceRequest.orderingProvider?.tinList,
                    npi: serviceRequest.orderingProvider?.npi,
                    addresses: [serviceRequest.orderingProviderLocation?.address],
                    type: "ORDERING_PROVIDER",
                    npiLabel: serviceRequest.orderingProvider?.npi || "N/A",
                    handleClose: closeModal,
                  });
                }}
              >
                {contactInfoButtonText(serviceRequest.orderingProvider?.phoneNumbers, undefined)}
              </ContactButton>
              <LocationNetworkStatusPill
                location={serviceRequest?.orderingProviderLocation ?? undefined}
                config={{
                  outOfNetworkStatusDisplayOff,
                  locationIsOutOfNetwork: orderingProviderIsOutOfNetwork,
                  outOfNetworkStatusOverrideEnabled,
                }}
              />
            </>
          )}
        </DetailsField>
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formConfiguration?.performingProvider} forceShow={isFacilityBasedAuth}>
        <DetailsField
          label="Performing or attending provider"
          subTextChildren={
            <>
              {oonExceptionPerformingProviderReason && (
                <Caption className={classes.OutOfNetworkExceptionReasonStyle} data-public>
                  Exception reason: {oonExceptionPerformingProviderReason}
                </Caption>
              )}
              {oonExceptionPerformingProviderComment && (
                <Caption className={classes.labelContainer} data-public>
                  Comment: {oonExceptionPerformingProviderComment}
                </Caption>
              )}
            </>
          }
          bodyChildren={
            <>
              {serviceRequest.performingProvider ? (
                <>
                  <Body1 className={classes.bodyChildrenContainer} data-public>
                    <span>{serviceRequest.performingProvider?.name?.concat(" / ")}</span>
                    <span>{`NPI - ${serviceRequest.performingProvider?.npi || "N/A"}`}</span>
                  </Body1>
                </>
              ) : (
                <Body1>--</Body1>
              )}
            </>
          }
        >
          {serviceRequest.performingProvider && (
            <>
              <ContactButton
                onClick={() => {
                  setModalState({
                    open: true,
                    name: serviceRequest.performingProvider?.name || "None",
                    phones: serviceRequest.performingProvider?.phoneNumbers,
                    fax: serviceRequest.performingProviderLocation?.faxNumbers?.map(strToPhoneNumber),
                    type: "PERFORMING_PROVIDER",
                    tinList: serviceRequest.performingProvider?.tinList,
                    npi: serviceRequest.performingProvider?.npi,
                    addresses: [serviceRequest.performingProviderLocation?.address],
                    npiLabel: serviceRequest.performingProvider?.npi || "N/A",
                    handleClose: closeModal,
                  });
                }}
              >
                {contactInfoButtonText(serviceRequest.performingProvider?.phoneNumbers, undefined)}
              </ContactButton>
              <LocationNetworkStatusPill
                location={serviceRequest?.performingProvider}
                config={{
                  outOfNetworkStatusDisplayOff,
                  locationIsOutOfNetwork: providerIsOutOfNetwork,
                  outOfNetworkStatusOverride: serviceRequest?.networkStatusOverride?.isOutOfNetworkPerformingProvider,
                  outOfNetworkStatusOverrideEnabled,
                }}
              />
            </>
          )}
        </DetailsField>
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formConfiguration?.facility}>
        <DetailsField
          label="Performing facility or agency"
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            ?.find((action) => action.onAcceptAttribute === "facility")}
          subTextChildren={
            <>
              {oonExceptionFacilityReason && (
                <Caption className={classes.OutOfNetworkExceptionReasonStyle} data-public>
                  Exception reason: {oonExceptionFacilityReason}
                </Caption>
              )}
              {oonExceptionFacilityComment && (
                <Caption className={classes.labelContainer} data-public>
                  Comment: {oonExceptionFacilityComment}
                </Caption>
              )}
            </>
          }
          bodyChildren={
            <>
              {serviceRequest.facility ? (
                <>
                  <Body1 className={classes.bodyChildrenContainer} data-public>
                    <span>{serviceRequest.facility?.name?.concat(" / ")}</span>
                    <span>{`NPI - ${serviceRequest.facility?.npi || "N/A"}`}</span>
                  </Body1>
                </>
              ) : undefined}
            </>
          }
        >
          {serviceRequest.facility && (
            <>
              <ContactButton
                onClick={() => {
                  setModalState({
                    open: true,
                    name: serviceRequest.facility?.name || "None",
                    phones: serviceRequest.facility?.phoneNumbers,
                    fax: serviceRequest.facilityLocation?.faxNumbers?.map(strToPhoneNumber),
                    type: "FACILITY",
                    tinList: serviceRequest.facility?.tinList,
                    npi: serviceRequest.facility?.npi,
                    addresses: [serviceRequest.facilityLocation?.address],
                    npiLabel: serviceRequest.facility?.npi || "N/A",
                    handleClose: closeModal,
                  });
                }}
              >
                {contactInfoButtonText(serviceRequest.facility?.phoneNumbers, undefined)}
              </ContactButton>
              <LocationNetworkStatusPill
                location={serviceRequest?.facilityLocation ?? undefined}
                config={{
                  outOfNetworkStatusDisplayOff,
                  locationIsOutOfNetwork: facilityIsOutOfNetwork,
                  outOfNetworkStatusOverride: serviceRequest?.networkStatusOverride?.isOutOfNetworkFacility,
                  outOfNetworkStatusOverrideEnabled,
                }}
              />
            </>
          )}
        </DetailsField>
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper {...formConfiguration?.startEndDate}>
        <DetailsField
          label={"Facility state"}
          value={POLICY_UNITED_STATES_OPTIONS?.find((opt) => opt.id === serviceRequest?.associatedStateOfUnion)?.label}
        />
      </SRFormConfigFieldWrapper>
      {serviceRequest.requestType === "INITIAL" && (
        <SRFormConfigFieldWrapper {...formConfiguration?.urgency}>
          <DetailsField label={"Expedited"} value={serviceRequest.urgency?.isExpedited ? "Yes" : "No"} />
        </SRFormConfigFieldWrapper>
      )}
      <H6 className={classes.facilityBasedHeader} data-public>
        Patient stay
      </H6>
      <SRFormConfigFieldWrapper forceShow={isFacilityBasedAuth}>
        <DetailsField label="Patient status" value={latestPatientStatus} />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper forceShow={isFacilityBasedAuth && serviceRequest.patientStatus === "NOT_YET_ADMITTED"}>
        <DetailsField
          label="Expected admission date"
          value={serviceRequest?.expectedAdmissionDate ? formatDateStr(serviceRequest?.expectedAdmissionDate) : ""}
        />
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper forceShow={isFacilityBasedAuth && serviceRequest.patientStatus !== "NOT_YET_ADMITTED"}>
        <DetailsField
          label="Admission source (optional)"
          value={serviceRequest.admissionSource?.name || "--"}
          ruleNudge={ruleActions
            ?.filter(isRecommendChangeActionWithAttribute)
            ?.find((action) => action.onAcceptAttribute === "admissionSource")}
        />
        <DetailsField
          label="Admission date and time"
          value={
            serviceRequest.admissionDate && serviceRequest.admissionTime
              ? format(new Date(`${serviceRequest.admissionDate} ${serviceRequest.admissionTime}`), "MM/dd/yyyy HH:mm")
              : ""
          }
          ruleNudge={ruleActions?.filter(isRecommendChangeActionWithAttribute)?.find((action) => {
            return action.onAcceptAttribute === "admissionDate" || action.onAcceptAttribute === "admissionTime";
          })}
        />
      </SRFormConfigFieldWrapper>{" "}
      <SRFormConfigFieldWrapper
        forceShow={
          isFacilityBasedAuth &&
          (serviceRequest.patientStatus !== "NOT_YET_ADMITTED" ||
            facilityBasedConfig?.includePatientStayDatesOnPlannedAdmission)
        }
      >
        <DetailsField label="Length of stay" value={`${requestedStaysText}${decisionedStaysText}`}>
          {lengthOfDecisionedStays + lengthOfRequestedStays > 0 && (
            <ContactButton onClick={() => setPatientStayModalOpen(true)}>View details</ContactButton>
          )}
        </DetailsField>
      </SRFormConfigFieldWrapper>
      <SRFormConfigFieldWrapper
        forceShow={isFacilityBasedAuth && !!authorization?.nextReviewDate && showNextReviewDateOnPatientSummary}
      >
        <DetailsField label="Next review date" value={formatDateStr(authorization?.nextReviewDate)} />
      </SRFormConfigFieldWrapper>
      {showDischargeInfo && <H6 className={classes.facilityBasedHeader}>Discharge Information</H6>}
      <SRFormConfigFieldWrapper forceShow={showDischargeDateAndTime}>
        <DetailsField label="Discharge date and time" value={dischargedDateTime} />
        <DetailsField label="Discharged to" value={dischargedTo} />
      </SRFormConfigFieldWrapper>
      {otherInsuranceEnabled && (
        <DetailsField
          label={"Additional insurance"}
          value={serviceRequest.additionalInsurance?.additionalInsuranceText || "--"}
        />
      )}
      <PatientStayModal
        authorization={authorization}
        currentServiceRequest={serviceRequest}
        open={patientStayModalOpen}
        setOpen={setPatientStayModalOpen}
      />
    </>
  );
};

const getDischargedDateTime = (
  serviceRequest: ServiceRequestResponse,
  authorization?: AuthorizationResponse,
  isFaxEdit?: boolean
) => {
  if (isFaxEdit && serviceRequest.dischargeDate && serviceRequest.dischargeTime) {
    return format(new Date(`${serviceRequest.dischargeDate} ${serviceRequest.dischargeTime}`), "MM/dd/yyyy HH:mm");
  } else if (authorization?.actualDischargeDateTime) {
    return format(new Date(authorization.actualDischargeDateTime), "MM/dd/yyyy HH:mm");
  }
  return "";
};

const DetailsField = ({
  label,
  value,
  xs = 12,
  ruleNudge,
  children,
  subTextChildren,
  bodyChildren,
}: PropsWithChildren<{
  label: string;
  value?: string;
  ruleNudge?: RecommendChangeRuleAction;
  xs?: GridSize;
  subTextChildren?: JSX.Element;
  bodyChildren?: JSX.Element;
}>) => {
  const showContentBeingNudged = useFeature("showContentBeingNudged");
  const isNudgePresent = ruleNudge !== undefined;
  const classes = useStyles({ isNudgePresent, showContentBeingNudged });

  return (
    /*will change state to be based on if nudge has been accepted or not part of COH-2027*/
    <Grid
      item
      xs={xs}
      className={`${classes.grid}${isNudgePresent && showContentBeingNudged ? " nudgeLayout" : " nonNudgeLayout"}`}
      style={{ paddingLeft: 0 }}
    >
      <Grid item xs={12} className={classes.initialContainer}>
        <Grid item xs={4} className={classes.captionContainer}>
          <Caption className={classes.labelContainer} data-public>
            {label}
          </Caption>
        </Grid>
        <Grid item xs={8} className={classes.container}>
          <div className={classes.subContainer}>
            {value && <Body1 data-public>{value || "--"}</Body1>}
            {bodyChildren}
            {showContentBeingNudged && ruleNudge && (
              <ContentNudge ruleNudge={ruleNudge} state={"pending"} type={"main"} />
            )}
            {children}
          </div>
          <div className={classes.exceptionContainer}>{subTextChildren}</div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export function getRequestorPhone(serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse) {
  let requestorPhone: PhoneNumber;
  if (serviceRequest.requestor?.phoneNumber) {
    requestorPhone = serviceRequest.requestor?.phoneNumber;
  } else if (serviceRequest.requestor?.user?.phone) {
    requestorPhone = strToPhoneNumber(serviceRequest.requestor?.user?.phone);
  } else {
    requestorPhone = strToPhoneNumber(serviceRequest.createdByPhoneNumber);
  }
  return requestorPhone;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ContactButton = styled(TertiaryButton)(({ theme }) => ({
  padding: "0",
  borderRadius: 4,
  "& :hover": {
    backgroundColor: colorsLight.action.hoverBackground,
  },
  "& :focus": {
    backgroundColor: colorsLight.action.hoverBackground,
  },
  justifyContent: "center",
  alignItems: "flex-start",
}));

const contactInfoButtonText = (phones?: PhoneNumber[], faxes?: PhoneNumber[]) => "View info";

const useStyles = makeStyles((theme) => ({
  grid: {
    wordWrap: "break-word",
    "&.nonNudgeLayout": {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
    "&.nudgeLayout": {
      paddingBottom: theme.spacing(2),
    },
    display: "flex",
  },
  valueText: {
    display: "flex",
    flexDirection: "row",
  },
  component: {
    "&.nudgeLayout": {
      minHeight: "32px",
      alignItems: "center",
      display: "flex",
    },
  },
  initialContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: theme.spacing(3),
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    flex: "1 0 230px",
    flexWrap: "wrap",
  },
  subContainer: {
    display: "flex",
    alignItems: "flex-start",
    maxWidth: theme.spacing(93),
    gap: theme.spacing(1.5),
    flexWrap: "wrap",
  },
  exceptionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.75),
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  labelContainer: {
    color: theme.palette.text.secondary,
    display: "inline-block",
  },
  captionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    maxWidth: "200px",
    minWidth: "65px",
  },
}));
