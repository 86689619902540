import {
  ReferralRequestCreatePayload,
  ReferralRequestResponse,
  ReferralRequestWorflowStep,
  Requestor,
  useCreateReferralRequest,
  useUpdateReferralRequest,
} from "@coherehealth/core-platform-api";
import { ReferralRequestFormContent } from "../ReferralRequestForm";
import { formatDateToISODate, useFeature } from "@coherehealth/common";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import Footer from "components/AuthBuilder/Footer";
import { rrFormContentFromResponse } from "util/referralRequest";
import { useSnackbar } from "notistack";
import { updateLocalStorageRecentsForReferral } from "common/RecentProviders/utility";
import { RecentProvidersContext } from "common/RecentProviders/context";
import { useTrackUserInteraction } from "util/userActivityTracker";

interface IProps {
  setCancelModalOpen: (open: boolean) => void;
  patientId: string;
  healthPlanName: string;
  isAdministrative: boolean;
  rrFormContent: ReferralRequestFormContent;
  setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>;
  workflowStep: ReferralRequestWorflowStep;
  setWorkflowStep: (step: ReferralRequestWorflowStep) => void;
  referralRequestFormCanBeSubmitted: boolean;
  addReferralRequest: (referralRequest: ReferralRequestResponse, newDraftRRId?: string) => void; // Referral persists if user clicks on continue
  editReferralRequest: Dispatch<ReferralRequestResponse>; // If users plans to edit any field by clicking back on pre-review page
  setFooterHeight: Dispatch<SetStateAction<number>>;
  setAttemptedReferralRequestFormsSubmit: (b: boolean) => void;
  userUpdatedTat?: boolean;
  requestor?: Requestor;
}

export default function ReferralRequestDetailsFooter({
  setCancelModalOpen,
  patientId,
  healthPlanName,
  isAdministrative,
  rrFormContent,
  setRRFormContent,
  workflowStep,
  setWorkflowStep,
  referralRequestFormCanBeSubmitted,
  addReferralRequest,
  editReferralRequest,
  setFooterHeight,
  setAttemptedReferralRequestFormsSubmit,
  userUpdatedTat,
  requestor,
}: IProps) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: createReferralRequest,
    loading: createReferralRequestLoading,
    error: createReferralRequestError,
  } = useCreateReferralRequest({});

  const {
    mutate: updateReferralRequest,
    loading: updateReferralRequestLoading,
    error: updateReferralRequestError,
  } = useUpdateReferralRequest({ id: "" });

  useEffect(() => {
    if (createReferralRequestError) {
      enqueueSnackbar("Failed to create referral request, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
    if (updateReferralRequestError) {
      enqueueSnackbar("Failed to update referral request, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [createReferralRequestError, enqueueSnackbar, updateReferralRequestError]);

  // * Code tag RECENT_PROVIDERS_8B12CDB1
  const useRecentProviderDetails = useFeature("recentProviderDetails");
  const { referringProviderTrackingState, setReferringProviderTrackingState } = useContext(RecentProvidersContext);
  const { performingSpecialistTrackingState, setPerformingSpecialistTrackingState } =
    useContext(RecentProvidersContext);
  const { specialistFacilityTrackingState, setSpecialistFacilityTrackingState } = useContext(RecentProvidersContext);

  const trackUserActivityInteraction = useTrackUserInteraction();

  // Sets the request payload based on formContent state
  const getPayload = (rrFormContent: ReferralRequestFormContent): ReferralRequestCreatePayload => {
    return {
      patient: patientId,
      startDate: formatDateToISODate(rrFormContent.startDate),
      cohereId: rrFormContent?.cohereId,
      primarySemanticDiagnosisCode: rrFormContent.primarySemanticDiagnosisCode || undefined,
      secondarySemanticDiagnosisCodes: rrFormContent.secondarySemanticDiagnosisCodes,
      selectedReferringProvider: rrFormContent.selectedReferringProvider,
      selectedFacility: rrFormContent.selectedFacility,
      specialty: rrFormContent.specialty,
      selectedPerformingSpecialist: rrFormContent.selectedPerformingSpecialist,
      referralStatus: rrFormContent.referralStatus || "DRAFT",
      healthPlanName: healthPlanName,
      isAdministrative: isAdministrative,
      userUpdatedTat: userUpdatedTat,
      requestor: requestor,
    };
  };

  // Functions handles create/ update functionality for a referral
  const submitReferralRequestForm = async ({ rr }: { rr: ReferralRequestFormContent }): Promise<void> => {
    const payload = getPayload(rr);
    let newDraftRrId = "";
    if (rrFormContent.id) {
      let response: ReferralRequestResponse;
      response = await updateReferralRequest({ ...payload }, { pathParams: { id: rrFormContent?.id } });
      if (response) {
        editReferralRequest(response);
      }
    } else {
      const referralResp: ReferralRequestResponse = await createReferralRequest(payload);
      if (referralResp) {
        newDraftRrId = referralResp.id;
        const updatedReferralRequestForm = rrFormContentFromResponse(referralResp);
        addReferralRequest(referralResp, newDraftRrId);
        setRRFormContent(updatedReferralRequestForm);
      }
    }
    if (workflowStep === "REFERRAL_REQUEST_DETAILS") {
      setWorkflowStep("REFERRAL_REQUEST_REVIEW");
    }
  };

  const onSubmitReferralRequestForms = async () => {
    setAttemptedReferralRequestFormsSubmit(true);
    submitReferralRequestForm({ rr: rrFormContent });
    // * Code tag RECENT_PROVIDERS_8B12CDB1
    if (useRecentProviderDetails && healthPlanName) {
      updateLocalStorageRecentsForReferral(rrFormContent, healthPlanName);
      // * Submit tracking events for Recent Providers
      if (referringProviderTrackingState) {
        trackUserActivityInteraction({
          stage: "SELECT_RECENT",
          event: referringProviderTrackingState,
        });
      }
      if (performingSpecialistTrackingState) {
        trackUserActivityInteraction({
          stage: "SELECT_RECENT",
          event: performingSpecialistTrackingState,
        });
      }
      if (specialistFacilityTrackingState) {
        trackUserActivityInteraction({
          stage: "SELECT_RECENT",
          event: specialistFacilityTrackingState,
        });
      }
      // * Reset Recent Providers tracking state
      if (setReferringProviderTrackingState) {
        setReferringProviderTrackingState("RECENT_RP_NOT_CLICKED");
      }
      if (setPerformingSpecialistTrackingState) {
        setPerformingSpecialistTrackingState("RECENT_PS_NOT_CLICKED");
      }
      if (setSpecialistFacilityTrackingState) {
        setSpecialistFacilityTrackingState("RECENT_SF_NOT_CLICKED");
      }
    }
  };
  return (
    <>
      <Footer
        onPrimaryButtonClick={() => {
          onSubmitReferralRequestForms();
        }}
        onSecondaryButtonClick={() => {
          setCancelModalOpen(true);
        }}
        primaryButtonDisabled={!referralRequestFormCanBeSubmitted}
        primaryButtonLoading={createReferralRequestLoading || updateReferralRequestLoading}
        primaryButtonText={"Continue"}
        secondaryButtonText={"Cancel"}
        secondaryButtonDisabled={false}
        showSaveAndExitButton={false}
        showSecondaryButton={true}
        errorCaptionText={!referralRequestFormCanBeSubmitted ? "There are some errors with the details above." : ""} // This is just a fallback
        setFooterHeight={setFooterHeight}
      />
    </>
  );
}
