import {
  AdditionalInsurance,
  FacilityCategory,
  RequestTiming,
  useGetFacilityBasedRequestConfiguration,
  useGetOtherInsuranceConfiguration,
  useGetPostDecisionConfiguration,
  PostDecisionConfigurationResponse,
  useGetAutomatedDenialLetterConfiguration,
  AutomatedDenialLetterConfigurationResponse,
  FeatureConfigurationLevel,
  AdditionalCareParticipantConfig,
  useGetAdditionalCareParticipantsFeatureConfiguration,
  useGetAuthStatusAttachmentsTransitionConfiguration,
  AuthStatusAttachmentsTransitionConfigurationResponse,
  useGetLettersExpansionConfiguration,
  LettersExpansionConfigurationResponse,
  useGetScrubFieldsFromResponsesConfiguration,
  ServiceRequestEditConfig,
  AuthStatus,
  useGetServiceRequestEditConfiguration,
  FacilityBasedRequestConfigurationResponse,
  useGetTemporaryMemberAuthsConfiguration,
  TemporaryMemberAuthsConfigurationResponse,
  useGetContinuationConfiguration,
  ContinuationConfigurationResponse,
  TransferAuthorizationConfigurationResponse,
  useGetTransferAuthorizationConfiguration,
  Patient,
} from "@coherehealth/core-platform-api";
import { useContext, useEffect, useState } from "react";
import { UseMutateProps, UseMutateReturn } from "restful-react";
import { IGNORE_ERRORS, error as logError } from "logger";
import { ScrubTinsContext } from "../components/ScrubTinsContext";
import { useFeature } from "@coherehealth/common";
import { usePatientContext } from "../util/context/PatientContext";

interface FacilityBasedRequestConfigurationProps {
  healthPlanName?: string;
  encounterType?: FacilityCategory;
  requestTiming?: RequestTiming;
  patientData?: Patient | null;
  skipRequestTimingCheck?: boolean; //This prop is to make sure isFacilityBasedAuth check is skipped before showing any facility feature changes to UI.
}
interface OtherInsuranceConfigurationProps {
  healthPlanName?: string;
}

interface ScrubFieldsFromResponsesConfigurationProps {
  healthPlanName?: string;
}

interface ServiceRequestEditConfigurationProps {
  authStatus?: AuthStatus;
  healthPlanName?: string;
}

interface CareParticipantConfigurationProps {
  healthPlanName?: string;
}

interface FetchError {
  data?: string | FetchError;
  message?: string;
}

const shouldIgnoreErrorEntry = (error: FetchError, depth = 0): boolean => {
  const ignoreEntry = IGNORE_ERRORS.includes(error.message ?? "");
  if (!ignoreEntry && error.data && typeof error.data !== "string" && depth < 5) {
    return shouldIgnoreErrorEntry(error.data, depth + 1);
  }
  return ignoreEntry;
};

const useGetFacilityBasedRequestConfigurationByPayer = ({
  healthPlanName,
  encounterType,
  requestTiming, //TODO: remove requestTiming check we should not combine multiple condition
  skipRequestTimingCheck,
}: FacilityBasedRequestConfigurationProps) => {
  const [config, setConfig] = useState<FacilityBasedRequestConfigurationResponse>();
  const { mutate: facilityBasedRequestConfigurationByPayer } = useGetFacilityBasedRequestConfiguration({
    queryParams: {
      featureConfigurationLevel: "HEALTH_PLAN",
      healthPlanName: healthPlanName || "",
    },
  });

  useEffect(() => {
    let unmounted = false;
    const getConfig = async () => {
      try {
        if (healthPlanName && !unmounted) {
          const config = await facilityBasedRequestConfigurationByPayer();
          setConfig(config);
        }
      } catch (err) {
        if (!shouldIgnoreErrorEntry(err as FetchError)) {
          logError("Could not fetch facility-Based-Request configurations for payer");
        }
      }
    };

    if (!unmounted) {
      getConfig();
    }

    return () => {
      unmounted = true;
    };
  }, [healthPlanName, facilityBasedRequestConfigurationByPayer]);

  // Extract patient data from the Patient Context
  const { patientData } = usePatientContext();

  // Retrieve the feature flag value for 'facilityBasedFlowForOakStreetHealthPlan'
  const facilityBasedFlowForOakStreetHealthPlanFF = useFeature("facilityBasedFlowForOakStreetHealthPlan");

  // Determine if the current encounter involves a delegated vendor 'Oak Street Health'
  // This is true if:
  // 1. The encounter type is "INPATIENT"
  // 2. The health plan name is "Humana"
  // 3. The patient has coverage under 'Oak Street Health' as a risk-bearing entity
  const IsDelegatedVendorOakStreetHealth =
    encounterType === "INPATIENT" &&
    healthPlanName === "Humana" &&
    patientData?.coverages?.some((coverage) => coverage.riskBearingEntity === "Oak Street Health");

  // Enable the facility-based flow for Oak Street Health Plan if the feature flag is enabled
  // and the patient is under a delegated vendor 'Oak Street Health'
  const enableFacilityBasedFlowForOakStreetHealthPlan =
    facilityBasedFlowForOakStreetHealthPlanFF && IsDelegatedVendorOakStreetHealth ? true : false;

  const facilityBasedFeatureEnabled =
    (config?.enabled &&
      encounterType === "INPATIENT" &&
      (!!skipRequestTimingCheck || ["CONCURRENT", "POST_SERVICE"].includes(requestTiming || ""))) ||
    enableFacilityBasedFlowForOakStreetHealthPlan;

  const checkEmptyRequestedLoc =
    (config?.levelOfCareIsMandatoryOnReviews && encounterType === "INPATIENT" && facilityBasedFeatureEnabled) || false;
  const includePatientStayDatesOnPlannedAdmission = config?.includePatientStayDatesOnPlannedAdmission;

  return { facilityBasedFeatureEnabled, checkEmptyRequestedLoc, includePatientStayDatesOnPlannedAdmission };
};

export const useGetOtherInsuranceConfigurationByPayer = ({ healthPlanName }: OtherInsuranceConfigurationProps) => {
  const [otherInsuranceEnabled, setOtherInsuranceEnabled] = useState<boolean>();
  const [otherInsurance, setOtherInsurance] = useState<AdditionalInsurance[]>();
  const { mutate: getOtherInsuranceConfiguration } = useGetOtherInsuranceConfiguration({
    queryParams: {
      featureConfigurationLevel: "HEALTH_PLAN",
      healthPlanName: healthPlanName || "",
    },
  });

  useEffect(() => {
    let unmounted = false;
    const getConfig = async () => {
      try {
        if (healthPlanName && !unmounted) {
          const config = await getOtherInsuranceConfiguration();
          const enabled = config.enabled;
          const options = config.otherInsuranceOptions;
          setOtherInsuranceEnabled(enabled);
          setOtherInsurance(options);
        }
      } catch (err) {
        if (!shouldIgnoreErrorEntry(err as FetchError)) {
          logError("Could not fetch other-insurance configurations for payer");
        }
      }
    };

    if (!unmounted) {
      getConfig();
    }

    return () => {
      unmounted = true;
    };
  }, [healthPlanName, getOtherInsuranceConfiguration]);

  return { otherInsuranceEnabled, otherInsurance };
};

//set the ScrubTinsContext based off the value returned from the /ScrubFieldsFromResponsesConfiguration/configureByHealthPlan request
export const useSetScrubTinsContextByHealthPlanConfiguration = ({
  healthPlanName,
}: ScrubFieldsFromResponsesConfigurationProps) => {
  const { setScrubTinsContextState } = useContext(ScrubTinsContext);
  const { mutate: getScrubFieldsFromResponsesConfiguration } = useGetScrubFieldsFromResponsesConfiguration({
    queryParams: {
      featureConfigurationLevel: "HEALTH_PLAN",
      healthPlanName: healthPlanName || "",
    },
  });

  useEffect(() => {
    let unmounted = false;
    const getConfig = async () => {
      try {
        if (healthPlanName && !unmounted) {
          const config = await getScrubFieldsFromResponsesConfiguration();
          const enabled = config.scrubTins ?? false;
          setScrubTinsContextState(enabled);
        }
      } catch (err) {
        if (!shouldIgnoreErrorEntry(err as FetchError)) {
          logError("Could not fetch scrubFieldsFromResponseConfiguration for payer");
        }
      }
    };

    if (!unmounted) {
      getConfig();
    }

    return () => {
      unmounted = true;
    };
  }, [healthPlanName, getScrubFieldsFromResponsesConfiguration, setScrubTinsContextState]);
};

export const useGetServiceRequestEditConfigurationByPayerAndAuthStatus = ({
  healthPlanName,
  authStatus,
}: ServiceRequestEditConfigurationProps) => {
  const [srEditConfig, setSrEditConfig] = useState<ServiceRequestEditConfig>();
  const { mutate: getSrEditConfig } = useGetServiceRequestEditConfiguration({
    queryParams: {
      featureConfigurationLevel: "HEALTH_PLAN",
      healthPlanName: healthPlanName || "",
    },
  });

  useEffect(() => {
    let unmounted = false;
    const getConfig = async () => {
      try {
        if (healthPlanName && !unmounted) {
          const config = await getSrEditConfig();
          // Save the config corresponding with the given AuthStatus, otherwise save the default config
          const authStatusMappings = config.authStatusServiceRequestEditMappings;
          if (authStatus && authStatusMappings) {
            const mappingIndex = authStatusMappings?.findIndex((mapping) => mapping.authStatus === authStatus);
            if (mappingIndex !== undefined && mappingIndex > -1) {
              const newSrEditConfig = authStatusMappings[mappingIndex].serviceRequestEditConfig;
              // Disabled button tooltips fall back to the default if undefined (empty string will remain empty)
              if (newSrEditConfig && !newSrEditConfig.disableFormEditsMessage) {
                newSrEditConfig.disableFormEditsMessage = `You can't edit this service request when it has a status of ${authStatus.toLowerCase()}`;
              }
              if (newSrEditConfig && !newSrEditConfig.disableAttachmentEditsMessage) {
                newSrEditConfig.disableAttachmentEditsMessage = `You can’t edit attachments for a request that has been ${authStatus.toLowerCase()}`;
              }
              if (newSrEditConfig && !newSrEditConfig.disableClinicalAssessmentQuestionsMessage) {
                newSrEditConfig.disableClinicalAssessmentQuestionsMessage =
                  "You can't edit the clinical assessment after submission";
              }
              setSrEditConfig(newSrEditConfig);
            } else {
              setSrEditConfig(config.defaultAuthStatusConfig);
            }
          } else {
            setSrEditConfig(config.defaultAuthStatusConfig);
          }
        }
      } catch (err) {
        if (!shouldIgnoreErrorEntry(err as FetchError)) {
          logError("Could not fetch other-insurance configurations for payer");
        }
      }
    };

    if (!unmounted) {
      getConfig();
    }

    return () => {
      unmounted = true;
    };
  }, [authStatus, getSrEditConfig, healthPlanName]);

  return { srEditConfig };
};

export const useGetCareParticipantsConfigByHealthPlan = ({ healthPlanName }: CareParticipantConfigurationProps) => {
  const [careParticipantEnabled, setCareParticipantEnabled] = useState<boolean>();
  const [careParticipantConfig, setCareParticipantConfig] = useState<AdditionalCareParticipantConfig[]>();
  const { mutate: getCareParticipantConfiguration } = useGetAdditionalCareParticipantsFeatureConfiguration({
    queryParams: {
      featureConfigurationLevel: "HEALTH_PLAN",
      healthPlanName: healthPlanName || "",
    },
  });

  useEffect(() => {
    let unmounted = false;
    const getConfig = async () => {
      try {
        if (healthPlanName && !unmounted) {
          const config = await getCareParticipantConfiguration();
          const enabled = config.enabled;
          const options = config.additionalCareParticipantList;
          setCareParticipantEnabled(enabled);
          setCareParticipantConfig(options);
        }
      } catch (err) {
        if (!shouldIgnoreErrorEntry(err as FetchError)) {
          logError("Could not fetch careParticipants Configuration for the health plan");
        }
      }
    };

    if (!unmounted) {
      getConfig();
    }

    return () => {
      unmounted = true;
    };
  }, [healthPlanName, getCareParticipantConfiguration]);

  return { careParticipantEnabled, careParticipantConfig };
};

type GetPayorConfigByHealthPlanQueryParams = {
  featureConfigurationLevel: FeatureConfigurationLevel;
  healthPlanName: string;
};
/**
 * Wrapper around useMutate so it can be used with the useGet API
 * For basic retrieval of payor config objects at the health plan level
 * @param useMutateHook The original hook from restful-react
 * @param healthPlanName The health plan name, from the service request
 * @param description Description of the config; used for logging
 */
function useGetPayorConfigByHealthPlan<TData>(
  useMutateHook: (
    props: Omit<UseMutateProps<TData, unknown, GetPayorConfigByHealthPlanQueryParams, void, void>, "path" | "verb">
  ) => UseMutateReturn<TData, unknown, void, GetPayorConfigByHealthPlanQueryParams, void>,
  healthPlanName: string = "",
  description?: string
) {
  const queryParams: GetPayorConfigByHealthPlanQueryParams = {
    featureConfigurationLevel: "HEALTH_PLAN",
    healthPlanName,
  };

  const { mutate, error, loading } = useMutateHook({ queryParams });
  const [data, setData] = useState<TData | null>(null);

  useEffect(() => {
    let unmounted = false;
    const fetchData = async () => {
      try {
        if (healthPlanName && !unmounted) {
          const config = await mutate();
          setData(config);
        }
      } catch (error) {
        if (!shouldIgnoreErrorEntry(error as FetchError)) {
          let errorMessage = `Could not fetch ${description || "payor config"}`;
          errorMessage += healthPlanName ? ` for ${healthPlanName}` : "";
          logError(errorMessage);
        }
      }
    };

    // Only perform the fetch if the component was not unmounted, cancel it otherwise
    if (!unmounted) {
      fetchData();
    }

    return () => {
      unmounted = false;
    };
  }, [healthPlanName, description, mutate]);

  return {
    data,
    error,
    loading,
  };
}

export function useGetPostDecisionConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<PostDecisionConfigurationResponse>(
    useGetPostDecisionConfiguration,
    healthPlanName,
    "post-decision payor config"
  );
}

export function useGetAutomatedDenialLetterConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<AutomatedDenialLetterConfigurationResponse>(
    useGetAutomatedDenialLetterConfiguration,
    healthPlanName,
    "automated denial letter payor config"
  );
}

export function useGetAuthStatusAttachmentsTransitionConfigurationConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<AuthStatusAttachmentsTransitionConfigurationResponse>(
    useGetAuthStatusAttachmentsTransitionConfiguration,
    healthPlanName,
    "auth status attachments transition added payor config"
  );
}

export function useGetLettersExpansionConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<LettersExpansionConfigurationResponse>(
    useGetLettersExpansionConfiguration,
    healthPlanName,
    "letters expansion payor config"
  );
}

export function useGetTemporaryMemberAuthsConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<TemporaryMemberAuthsConfigurationResponse>(
    useGetTemporaryMemberAuthsConfiguration,
    healthPlanName,
    "temporary patients and members authorizations payor config"
  );
}

export function useGetContinuationConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<ContinuationConfigurationResponse>(
    useGetContinuationConfiguration,
    healthPlanName,
    "continuations payor config"
  );
}

export function useGetTransferAuthorizationConfigurationByPayer(healthPlanName: string) {
  return useGetPayorConfigByHealthPlan<TransferAuthorizationConfigurationResponse>(
    useGetTransferAuthorizationConfiguration,
    healthPlanName,
    "transfer authorization payor config"
  );
}

export default useGetFacilityBasedRequestConfigurationByPayer;
