import { useState, MutableRefObject, useEffect, Dispatch, SetStateAction, useContext } from "react";
import { RenderPageProps } from "@react-pdf-viewer/core";
import { PageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import HeaderComponent from "./HeaderComponent";
import { AugmentedDocSegmentation } from "./AttachmentViewerSidePanel";
import { Segment } from "@coherehealth/core-platform-api";
import { HighlightStateContext } from "../../util";
import { DocTypeItem } from "./PdfViewer";

interface CustomPageProps {
  renderPageProps: RenderPageProps;
  stableCurrentPageLabel: MutableRefObject<PageNavigationPlugin["CurrentPageLabel"]>;
  handleSegmentChange?: (
    updatedSegment: Segment,
    isDeletion: boolean,
    updatedPage: number,
    numberOfPages: number
  ) => void;
  savingFeedbackUpdates?: boolean;
  docSegmentationInfo?: AugmentedDocSegmentation;
  headerPositionRight?: boolean;
  setHeaderPositionRight?: Dispatch<SetStateAction<boolean>>;
  docTypeOptions?: DocTypeItem[];
}
const CustomPage = ({
  renderPageProps,
  stableCurrentPageLabel,
  handleSegmentChange,
  savingFeedbackUpdates,
  docSegmentationInfo,
  headerPositionRight,
  setHeaderPositionRight,
  docTypeOptions,
}: CustomPageProps) => {
  const latestSegments = docSegmentationInfo?.latestSegments;

  const pageSegment = (latestSegments || []).find(
    (segment) =>
      segment.docPages &&
      segment.docPages[0] <= renderPageProps.pageIndex + 1 &&
      renderPageProps.pageIndex + 1 <= segment.docPages[1]
  );

  const [openHeaderComponent, setOpenHeaderComponent] = useState(
    pageSegment?.docPages?.[0] === renderPageProps.pageIndex + 1
  );
  useEffect(() => {
    setOpenHeaderComponent(pageSegment?.docPages?.[0] === renderPageProps.pageIndex + 1);
  }, [pageSegment, renderPageProps.pageIndex]);
  const CurrentPageLabel = stableCurrentPageLabel.current;
  const { linking } = useContext(HighlightStateContext);

  return (
    <>
      {renderPageProps.canvasLayer.children}
      {renderPageProps.annotationLayer.children}
      {renderPageProps.textLayer.children}
      <div
        style={{
          position: "absolute",
          zIndex: 1,
          top: 8,
          right: headerPositionRight ? 8 : "inherit",
          left: headerPositionRight ? "inherit" : 7,
        }}
      >
        {!linking && (
          <CurrentPageLabel>
            {({ currentPage, numberOfPages }) => {
              return (
                <HeaderComponent
                  pageNumber={renderPageProps.pageIndex + 1}
                  isOpenHeaderComponent={openHeaderComponent}
                  isActiveHeaderComponent={openHeaderComponent && currentPage === renderPageProps.pageIndex}
                  setOpenHeaderComponent={setOpenHeaderComponent}
                  segment={pageSegment}
                  onSegmentChange={(latestSegment, isDeletion) => {
                    handleSegmentChange?.(latestSegment, isDeletion, renderPageProps.pageIndex + 1, numberOfPages);
                  }}
                  savingUpdates={savingFeedbackUpdates}
                  headerPositionRight={headerPositionRight}
                  setHeaderPositionRight={setHeaderPositionRight}
                  docTypeOptions={docTypeOptions}
                />
              );
            }}
          </CurrentPageLabel>
        )}
      </div>
    </>
  );
};

export default CustomPage;
