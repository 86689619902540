import { Body1, H5, SingleSelectDropdown, InlineButton, DropdownOption, useFeature } from "@coherehealth/common";
import { Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DeleteOutline, AddCircle } from "@material-ui/icons";
import { Guideline } from "@coherehealth/core-platform-api";
import clsx from "clsx";

interface GuidelinesUsedSectionProps {
  guidelinesFromReview: Guideline[];
  usedGuidelineIds: string[];
  setUsedGuidelineIds: (updatedGuidelineId: string, updatedIndex: number) => void;
  addUsedGuidelineIds: () => void;
  deleteUsedGuidelineId: (deletedIndex: number) => void;
  selectedGuidelines?: string[];
  usedGuidelines: Guideline[];
}

export const UNLISTED_GUIDELINE_ID = "UNLISTED_GUIDELINE_ID";

const GuidelinesUsedSection = ({
  guidelinesFromReview,
  usedGuidelineIds,
  setUsedGuidelineIds,
  addUsedGuidelineIds,
  deleteUsedGuidelineId,
  selectedGuidelines,
  usedGuidelines,
}: GuidelinesUsedSectionProps) => {
  const classes = useStyles();
  const showSelectableGuidelines = useFeature("showSelectableGuidelines");
  let guidelinesForOptions = guidelinesFromReview;
  if (showSelectableGuidelines && selectedGuidelines) {
    guidelinesForOptions = guidelinesForOptions.filter((guideline) => selectedGuidelines.includes(guideline.id || ""));
  }
  const guidelinesFromReviewOptions: DropdownOption[] = guidelinesForOptions.map((guideline) => {
    return {
      id: guideline.id || "",
      label: guideline.guidelineTitle || "",
      subLabel: guideline.policyTitle || "",
    };
  });
  const guidelinesUsedOptions: DropdownOption[] = [
    ...guidelinesFromReviewOptions,
    { id: UNLISTED_GUIDELINE_ID, label: "Unlisted guideline used" },
  ];
  const currentGuidelineIds = usedGuidelineIds
    .map((guidelineId) =>
      !guidelineId ||
      guidelineId === UNLISTED_GUIDELINE_ID ||
      !!guidelinesForOptions.find((guideline) => guideline.id === guidelineId)
        ? guidelineId
        : guidelinesForOptions.find(
            (guideline) =>
              guideline.originalVersionId ===
              usedGuidelines.find((usedGuideline) => usedGuideline.id === guidelineId)?.originalVersionId
          )?.id || "not found"
    )
    .filter((guidelineId) => guidelineId !== "not found");
  const definedGuidelineIds = currentGuidelineIds.filter((guidelineId) => !!guidelineId);
  return (
    <Grid container className={clsx(classes.rootContainer, classes.topAndBottomMargin)}>
      <H5>Guidelines used</H5>
      {currentGuidelineIds.map((guidelineId, index) => {
        return (
          <GuidelineUsedField
            key={`${guidelineId}_${index}`}
            guidelinesUsedOptions={guidelinesUsedOptions}
            setUsedGuidelineIds={setUsedGuidelineIds}
            index={index}
            guidelineId={guidelineId}
            deleteUsedGuidelineId={deleteUsedGuidelineId}
            hideDeleteButton={definedGuidelineIds.length < 2 && index === 0}
          />
        );
      })}
      <Grid item container xs={4} className={classes.citeAnotherButtonLessMargin}>
        <InlineButton startIcon={<AddCircle />} onClick={addUsedGuidelineIds}>
          Cite another guideline
        </InlineButton>
      </Grid>
    </Grid>
  );
};

interface GuidelineUsedFieldProps {
  guidelinesUsedOptions: DropdownOption[];
  setUsedGuidelineIds: (updatedGuidelineId: string, updatedIndex: number) => void;
  index: number;
  guidelineId: string;
  deleteUsedGuidelineId: (deletedIndex: number) => void;
  hideDeleteButton: boolean;
}

const GuidelineUsedField = ({
  guidelinesUsedOptions,
  setUsedGuidelineIds,
  index,
  guidelineId,
  deleteUsedGuidelineId,
  hideDeleteButton,
}: GuidelineUsedFieldProps) => {
  const classes = useStyles();
  return (
    <Grid item className={classes.topAndBottomMargin}>
      <Body1 className={classes.externalLabel}>{`Guideline ${index + 1}`}</Body1>
      <Grid container>
        <Grid item xs={hideDeleteButton ? 12 : 11}>
          <SingleSelectDropdown
            label="Guideline used"
            options={guidelinesUsedOptions}
            onChange={(guidelineId) => setUsedGuidelineIds(guidelineId, index)}
            value={guidelineId}
            data-testid={"guideline-used-dropdown"}
          />
        </Grid>
        {!hideDeleteButton && (
          <Grid item container xs={1} justifyContent="center" alignItems="center">
            <IconButton onClick={() => deleteUsedGuidelineId(index)}>
              <DeleteOutline />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    display: "flex",
    flexDirection: "column",
  },
  topAndBottomMargin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  externalLabel: {
    marginBottom: theme.spacing(1),
  },
  citeAnotherButtonExtraMargin: {
    marginTop: theme.spacing(3),
  },
  citeAnotherButtonLessMargin: {
    marginTop: theme.spacing(1),
  },
}));

export default GuidelinesUsedSection;
