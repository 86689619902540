import React from "react";
import ReactPDF, { StyleSheet, Image, Link, Text, View, Page } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import CohereLogo from "components/images/CohereLogo.jpg";
import CohereLogoGrayscale from "components/images/CohereLogoGrayscale.jpg";
import InfoOutlineIcon from "components/images/InfoOutlineIcon.jpg";
import { colorsLight } from "@coherehealth/common";
import PayerAgnosticFaxBackEmptyFormFirstPage from "components/images/PayerAgnosticFaxBackEmptyFormPart1.jpg";
import PayerAgnosticFaxBackEmptyFormSecondPage from "components/images/PayerAgnosticFaxBackEmptyFormPart2.jpg";
import PayerAgnosticFaxBackEmptyFormThirdPage from "components/images/PayerAgnosticFaxBackEmptyFormPart3.jpg";
import { GilroyBold, GilroyRegular, GilroySemiBold } from "./PDFfonts";

interface TextFieldProps extends ReactPDF.ViewProps {
  label: string;
  value: string;
  styleOverrides?: {
    textField?: Style;
    label?: Style;
    body?: Style;
  };
}

export function TextField({ label, value, styleOverrides }: TextFieldProps) {
  return (
    <View style={styleOverrides?.textField || styles.textField}>
      <Text style={styleOverrides?.label || styles.label}>{label}</Text>
      <Text style={styleOverrides?.body || styles.body}>{value}</Text>
    </View>
  );
}

interface CohereServiceRequestPDFHeaderProps {
  email?: string;
  phone: string;
  contactName: string;
}

export function CohereServiceRequestPDFHeader({ email, phone, contactName }: CohereServiceRequestPDFHeaderProps) {
  return (
    <View style={styles.logoContactRow}>
      <Image src={CohereLogo} style={styles.logo} />
      <View style={styles.contactInfo}>
        <Text style={[styles.label, { paddingBottom: 16 }]}>Questions about this service?</Text>
        <Text style={styles.body}>Contact {contactName}</Text>
        {email ? (
          <Link style={styles.contactLink} src={`mailto:${email}`}>
            {email}
          </Link>
        ) : null}
        <Link style={styles.contactLink} src={`tel:${phone}`}>
          {phone}
        </Link>
      </View>
    </View>
  );
}

export function CohereDisclaimerFooter() {
  return (
    <View style={styles.fullWidthSection}>
      <Text style={styles.disclaimer}>
        This document contains confidential information and is protected by the Health Insurance Portability and
        Accountability Act (HIPAA), the Health Information Technology for Economic and Clinical Health Act (HITECH) and
        a number of other federal and state privacy laws. This document and its contents may only be accessed, used or
        disclosed by duly authorized individuals in the course of the subject’s treatment, claims processing or as
        otherwise required or permitted by law. Any other access, use or disclosure is strictly prohibited and may
        result in civil or criminal penalties.
      </Text>
    </View>
  );
}

type FaxBackType = "MISSING_INFO" | "OUT_OF_SCOPE" | "FINAL_DETERMINATION";

interface FaxProps {
  faxBackType: FaxBackType;
  dateRequested?: string | undefined;
  isGhpUser?: boolean;
}

export function CohereFaxPDFHeader({ faxBackType, dateRequested, isGhpUser }: FaxProps) {
  return (
    <View style={{ position: "relative" }}>
      <View style={faxStyles.logoRow}>
        <View>
          <Image src={CohereLogoGrayscale} style={faxStyles.logo} />
        </View>
        <View>
          <View style={faxStyles.headerSection}>
            <Text style={{ paddingBottom: 6 }}>
              <Text style={faxStyles.regularMediumHeadingText}>From: </Text>
              {!isGhpUser && <Text style={faxStyles.boldMediumHeadingText}>Cohere Health</Text>}
              {isGhpUser && <Text style={faxStyles.boldMediumHeadingText}>Geisinger Health Plan</Text>}
              {dateRequested && (
                <>
                  <Text style={faxStyles.regularMediumHeadingText}>{"     "}Date Requested: </Text>
                  <Text style={faxStyles.boldMediumHeadingText}>{dateRequested}</Text>
                </>
              )}
            </Text>
            {faxBackType === "FINAL_DETERMINATION" ? (
              <Text style={faxStyles.subjectInfoText}>We have finished processing your service request.</Text>
            ) : (
              <Text style={faxStyles.subjectInfoText}>Unable to process service request.</Text>
            )}
            <Text>
              <Text style={faxStyles.smallText}>To review the status of your request please go online to</Text>
              <Text style={faxStyles.smallBoldText}>{" coherehealth.com/check_status"}</Text>
            </Text>
          </View>
        </View>
        <Text style={faxStyles.responseText}>Response</Text>
      </View>
      <View style={[styles.fullWidthSection, { flexDirection: "row" }]}>
        <View style={{ padding: "8 2 2 2" }}>
          <Image src={InfoOutlineIcon} style={faxStyles.infoIcon} />
        </View>
        <Text style={{ margin: "8 20 0 0" }}>
          <Text style={faxStyles.smallBoldText}>Still faxing? </Text>
          <Text style={faxStyles.smallText}>
            If so, you're missing out on timesaving benefits, including immediate auth decisions and transparent in-app
            clinical guidelines only available when using the CohereNext:® web portal to manage preauthorizations.
          </Text>
        </Text>
      </View>
      <View style={[styles.fullWidthSection, { borderBottomWidth: 1 }]}>
        <Text style={{ margin: "0 20 8 20" }}>
          <Text style={faxStyles.smallText}>
            Registration only takes a few minutes, and unlocks access for all users at your practice organization. Visit
          </Text>
          <Text style={faxStyles.smallBoldText}> www.coherehealth.com/register </Text>
          <Text style={faxStyles.smallText}>to begin.</Text>
        </Text>
      </View>
    </View>
  );
}

export function CohereFaxResourcesSection({ faxBackType, isGhpUser }: FaxProps) {
  return (
    <View style={faxStyles.bodySection}>
      {faxBackType === "FINAL_DETERMINATION" && !isGhpUser && (
        <>
          <Text style={{ paddingBottom: 4 }}>
            <Text style={faxStyles.smallBoldText}>Please note: </Text>
            <Text style={faxStyles.smallText}>
              Physical therapy, occupational therapy, and speech therapy are not considered “urgent” services as defined
              in the Medicare Managed
            </Text>
          </Text>
          <Text style={[faxStyles.smallText, { paddingBottom: 24 }]}>
            Care Manual. Therefore, Cohere Health will process all such requests according to standard timeframes.
          </Text>
        </>
      )}
      <Text style={[faxStyles.smallText, { paddingBottom: 4 }]}>
        For answers to questions regarding the Cohere systems and available resources please go online to
      </Text>
      <Text>
        <Text style={faxStyles.smallBoldText}>https://coherehealth.zendesk.com </Text>
        <Text style={faxStyles.smallText}>or </Text>
        <Text style={faxStyles.smallBoldText}>https://coherehealth.com/resources</Text>
      </Text>
    </View>
  );
}

export function CohereFaxFooter() {
  return (
    <View style={faxStyles.footerSection}>
      <Text style={faxStyles.smallText}>
        The information transmitted is intended only for the person or entity to which it is addressed and may contain
        CONFIDENTIAL material. If you receive this
      </Text>
      <Text style={faxStyles.smallText}>
        material/information in error, please contact the sender and delete or destroy the material/information
      </Text>
      <Text style={faxStyles.smallText}>© 2021 Cohere Health, Inc.. All Rights Reserved.</Text>
    </View>
  );
}

interface FaxEmptyFormProps {
  isGhpUser?: boolean;
}

export function CohereFaxEmptyForm({ isGhpUser }: FaxEmptyFormProps) {
  return !isGhpUser ? (
    <>
      <Page size={[650, 841.89]} style={faxStyles.emptyFormPage}>
        <Image src={PayerAgnosticFaxBackEmptyFormFirstPage} style={faxStyles.emptyForm} />
      </Page>
      <Page size={[650, 841.89]} style={faxStyles.emptyFormPage}>
        <Image src={PayerAgnosticFaxBackEmptyFormSecondPage} style={faxStyles.emptyForm} />
      </Page>
      <Page size={[650, 841.89]} style={faxStyles.emptyFormPage}>
        <Image src={PayerAgnosticFaxBackEmptyFormThirdPage} style={faxStyles.emptyForm} />
      </Page>
    </>
  ) : null;
}

const styles = StyleSheet.create({
  logoContactRow: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logo: {
    paddingBottom: 16,
  },
  contactInfo: {
    alignItems: "flex-end",
  },
  label: {
    color: colorsLight.font.secondary,
    fontSize: 10,
    paddingBottom: 4,
  },
  contactLink: {
    color: colorsLight.font.main,
    fontSize: 10,
    paddingTop: 4,
  },
  textField: {
    paddingBottom: 12,
  },
  body: {
    color: colorsLight.font.main,
    fontWeight: 600,
    fontSize: 10,
  },
  disclaimer: {
    color: colorsLight.font.light,
    fontSize: 10,
  },
  fullWidthSection: {
    width: "100%",
  },
});

const faxStyles = StyleSheet.create({
  logoRow: {
    width: "100%",
    flexDirection: "row",
    borderBottomWidth: 1,
  },
  logo: {
    height: 48,
    paddingRight: 12,
    paddingBottom: 8,
    paddingTop: 12,
  },
  headerSection: {
    flexDirection: "column",
    width: "85%",
    paddingLeft: 12,
    paddingBottom: 8,
    borderLeftWidth: 1,
  },
  subjectInfoText: {
    fontSize: 11,
    paddingBottom: 6,
    fontFamily: GilroyBold,
  },
  regularMediumHeadingText: {
    fontSize: 8,
    fontFamily: GilroyRegular,
  },
  boldMediumHeadingText: {
    fontSize: 8,
    fontFamily: GilroyBold,
  },
  smallText: {
    fontSize: 7,
    fontFamily: GilroyRegular,
  },
  smallBoldText: {
    fontSize: 7,
    fontFamily: GilroyBold,
  },
  responseText: {
    fontSize: 8,
    fontFamily: GilroySemiBold,
    color: "#FFFFFF",
    padding: 4,
    paddingBottom: 1,
    backgroundColor: "#000000",
    height: 15,
    marginLeft: 55,
  },
  infoIcon: {
    width: 16,
    height: 16,
    color: "#9A9A9A",
  },
  footerSection: {
    alignItems: "center",
    flexDirection: "column",
    position: "absolute",
    bottom: 56,
    left: 56,
    paddingTop: 4,
  },
  bodySection: {
    width: "100%",
    borderBottom: 1,
    paddingTop: 24,
    paddingBottom: 24,
    position: "relative",
  },
  emptyFormPage: {
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
    padding: 32,
  },
  emptyForm: {
    width: "100%",
    height: "100%",
  },
});

export const BULLET = "\u2022";
