import { ClinicalReviewStoreData, ClinicalReviewSyncAction } from "./types";

// FIXME: add tests
export function stateReducer(
  state: ClinicalReviewStoreData,
  action: ClinicalReviewSyncAction
): ClinicalReviewStoreData {
  switch (action.type) {
    case "INIT_SERVICE_REQUEST": {
      return {
        ...state,
        serviceRequestId: action.serviceRequestId,
      };
    }
    case "INIT_PATIENT_DETAILS": {
      return {
        ...state,
        patientId: action.patientId,
      };
    }
    case "INIT_QM_CASE": {
      return {
        ...state,
        caseId: action.caseId,
        caseType: action.caseType,
      };
    }
    case "INIT_CURRENT_REVIEW": {
      return {
        ...state,
        currentReview: action.currentReview,
        isReviewEditMode: action.isReviewEditMode,
      };
    }
    case "SET_LEFT_TAB": {
      return {
        ...state,
        leftColumnTab: action.leftColumnTab,
      };
    }
    default:
      return state;
  }
}

/* Testing TypeScript type inference */
