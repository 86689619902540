import React from "react";
import { Card, Grid } from "@material-ui/core";
import { CardTextField } from "../CardTextField";
import { getSelectedRequestActionButtons } from "../ServiceRequestSelectionCard";
import { ClassNameMap } from "@material-ui/styles";
import { AuthCategoryResponse, BaseReview, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { ErrorSelectionCard } from "./ErrorSelectionCard";
import { SelectionCardHeader } from "./SelectionCardHeader";

export interface CommonSelectionCardProps {
  authorizationLoading?: boolean;
  cardButtonComponent: JSX.Element;
  extractClinicalServiceText: (
    clinicalServices?: ServiceRequestResponse["clinicalServices"],
    authCategory?: AuthCategoryResponse
  ) => string;
  generalAuthSubmissionWorkflowEnabled: boolean;
  loadingReview?: boolean;
  loadingServiceRequest?: boolean;
  loadingContinuation?: boolean;
  mostRecentReview?: BaseReview;
  onContinuationClick?: () => Promise<void>;
  onEditClick?: () => void;
  reviewNotesComponent: JSX.Element;
  serviceRequest: ServiceRequestResponse | null;
  serviceCaseId?: string;
  showCreateContinuation?: boolean;
  showPatientAlert: boolean;
  showEditRequest?: boolean;
  serviceRequestSelectionCardStyles: (props?: any) => ClassNameMap;
  continueDraftComponent?: JSX.Element;
  draftWarningComponent?: JSX.Element;
}

export function CommonSelectionCard(props: CommonSelectionCardProps): JSX.Element {
  const {
    authorizationLoading,
    cardButtonComponent,
    extractClinicalServiceText,
    generalAuthSubmissionWorkflowEnabled,
    loadingContinuation,
    loadingServiceRequest,
    onContinuationClick,
    onEditClick,
    serviceCaseId,
    serviceRequest,
    showCreateContinuation,
    showPatientAlert,
    showEditRequest,
    serviceRequestSelectionCardStyles,
    continueDraftComponent,
    draftWarningComponent,
  } = props;

  const { authNumber, cohereId, primaryDiagnosis, procedureCodes, secondaryDiagnoses } = serviceRequest ?? {};

  const classes = serviceRequestSelectionCardStyles();

  if (!serviceRequest) {
    return (
      <ErrorSelectionCard
        serviceRequestSelectionCardStyles={serviceRequestSelectionCardStyles}
        loadingServiceRequest={!!loadingServiceRequest}
      />
    );
  }

  return (
    <Card className={classes.serviceRequestSelectionCard}>
      <Grid container spacing={2}>
        <SelectionCardHeader
          classes={classes}
          extractClinicalServiceText={extractClinicalServiceText}
          generalAuthSubmissionWorkflowEnabled={generalAuthSubmissionWorkflowEnabled}
          serviceCaseId={serviceCaseId}
          serviceRequest={serviceRequest}
          showPatientAlert={showPatientAlert}
        />

        {authNumber && (
          <Grid item xs={3}>
            <CardTextField label="Auth number" content={authNumber} />
          </Grid>
        )}
        <Grid item xs={3}>
          <CardTextField label="Tracking number" content={cohereId} />
        </Grid>
        <Grid item xs={3} data-public>
          <CardTextField label="Primary diagnosis" content={`${primaryDiagnosis?.code}`} data-public />
        </Grid>
        <Grid item xs={6} data-public>
          <CardTextField
            label="Procedure codes"
            content={procedureCodes?.map((px) => px.code)?.join(", ")}
            data-public
          />
        </Grid>
        {secondaryDiagnoses && (
          <Grid item xs={4} data-public>
            <CardTextField
              label="Secondary diagnosis"
              content={`${secondaryDiagnoses?.map((diagnosis) => diagnosis.code).join(",")}`}
              data-public
            />
          </Grid>
        )}
        <Grid item xs={12} className={classes.cardButtons}>
          {cardButtonComponent}
          {getSelectedRequestActionButtons(
            serviceRequest.authStatus,
            authorizationLoading,
            continueDraftComponent,
            showCreateContinuation,
            onContinuationClick,
            loadingContinuation,
            showEditRequest,
            onEditClick
          )}
        </Grid>
        {serviceRequest.authStatus === "DRAFT" && draftWarningComponent && (
          <Grid item xs={12}>
            {draftWarningComponent}
          </Grid>
        )}
      </Grid>
    </Card>
  );
}
