import React, {
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
  MutableRefObject,
  useMemo,
  useRef,
} from "react";
import { colorsLight } from "@coherehealth/common";
import {
  Viewer,
  Worker,
  PageChangeEvent,
  SpecialZoomLevel,
  ZoomEvent,
  RotateEvent,
  LoadError,
  Plugin,
} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin, SidebarTab } from "@react-pdf-viewer/default-layout";
import { useFeature } from "@coherehealth/common";

import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";

// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, Theme } from "@material-ui/core";
import DehazeIcon from "@material-ui/icons/Dehaze";
import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";
import PdfViewerRenderError from "./PdfViewerRenderError";
import ToolbarControl from "./ToolbarControl";
import { NotificationViewerConfig } from "../util/viewerUtil";
import { NotificationViewerItem } from "../util/notificationUtil";

interface Props {
  fileName: string;
  pdfUrl: string;
  notifications?: NotificationViewerItem[];
  setNotifications?: React.Dispatch<React.SetStateAction<NotificationViewerItem[]>>;
  handleNotificationClick?: (index: number, landingPage?: number) => void;
  notificationIndexOpen?: number;
  openSidePanel?: boolean;
  setOpenSidePanel?: Dispatch<SetStateAction<boolean>>;
  currentPage?: MutableRefObject<number>;
  zoomLevel?: MutableRefObject<number>;
  currentRotate?: MutableRefObject<number>;
  viewerConfig?: NotificationViewerConfig;
}

export default function NotificationPdfViewer({
  fileName,
  pdfUrl,
  notifications,
  setNotifications,
  handleNotificationClick,
  notificationIndexOpen,
  currentPage,
  zoomLevel,
  currentRotate,
  openSidePanel,
  setOpenSidePanel,
  viewerConfig,
}: Props) {
  if (typeof window !== "undefined") {
    window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
  }
  const [slideInSidePanel, setSlideInSidePanel] = useState(openSidePanel);
  const [sidePanelIsMounted, setSidePanelIsMounted] = useState(openSidePanel);
  const textInputRef = useRef<HTMLInputElement>();
  const attachmentPDFViewerDefaultScale = useFeature("attachmentPDFViewerDefaultScale");

  // Enable Ctrl-F (Windows) or Cmd-F (Mac) to focus search box
  const isMacOs = () => navigator.userAgent.indexOf("Mac") !== -1;
  const keyDownListener = useCallback(
    (event: KeyboardEvent) => {
      if (!isMacOs() && event.ctrlKey && event.key === "f") {
        event.preventDefault();
        textInputRef.current?.focus();
      }
      if (isMacOs() && event.metaKey && event.key === "f") {
        event.preventDefault();
        textInputRef.current?.focus();
      }
    },
    [textInputRef]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyDownListener);
    return () => {
      document.removeEventListener("keydown", keyDownListener);
    };
  }, [keyDownListener]);

  // Configuration for react-pdf-viewer plugins
  const renderToolbar = useCallback(ToolbarControl, [openSidePanel, setOpenSidePanel]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      searchPlugin: {
        onHighlightKeyword: (props) => {
          props.highlightEle.style.backgroundColor = colorsLight.warning.main;
        },
      },
    },
    renderToolbar: (Toolbar) =>
      renderToolbar(
        Toolbar,
        openSidePanel,
        setOpenSidePanel,
        false, // hideSearchInAttachments
        true, // crrStyling
        currentPage,
        currentRotate,
        fileName,
        textInputRef,
        viewerConfig
      ),
    sidebarTabs: () => sideBarTabs,
  });

  const { toolbarPluginInstance } = defaultLayoutPluginInstance;
  const { pageNavigationPluginInstance } = toolbarPluginInstance;
  const { jumpToPage, CurrentPageLabel } = pageNavigationPluginInstance;
  /*
   * These need to stay stable throughout the component lifecycle or we get
   * "Maximum update depth exceeded" errors
   */
  const stableJumpToPage = useRef(jumpToPage);
  const stableCurrentPageLabel = useRef(CurrentPageLabel);

  const sidePanelRef = useCallback(
    (node: React.ReactNode) => {
      if (node !== null || openSidePanel) {
        setSidePanelIsMounted(true);
      } else {
        setSidePanelIsMounted(false);
      }
    },
    [openSidePanel]
  );

  const SidePanelComponent = viewerConfig?.sidePanelComponent;

  const sidePanel = useMemo(() => {
    if (handleNotificationClick && notifications) {
      const jumpToPage = stableJumpToPage.current;
      const CurrentPageLabel = stableCurrentPageLabel.current;
      return {
        content: (
          <CurrentPageLabel>
            {() =>
              SidePanelComponent ? (
                <SidePanelComponent
                  notifications={notifications}
                  setNotifications={setNotifications}
                  handleNotificationClick={handleNotificationClick}
                  notificationIndexOpen={notificationIndexOpen}
                  openSidePanel={slideInSidePanel}
                  sidePanelRef={sidePanelRef}
                  jumpToPage={jumpToPage}
                  closeDialogFunction={viewerConfig.closeDialogFunction}
                />
              ) : (
                <></>
              )
            }
          </CurrentPageLabel>
        ),
        icon: <DehazeIcon />,
        title: "Attachment list",
      };
    } else {
      return null;
    }
  }, [
    handleNotificationClick,
    notifications,
    SidePanelComponent,
    setNotifications,
    notificationIndexOpen,
    slideInSidePanel,
    sidePanelRef,
    viewerConfig?.closeDialogFunction,
  ]);

  const [sideBarTabs, setSideBarTabs] = useState<SidebarTab[]>(openSidePanel && sidePanel ? [sidePanel] : []);

  useEffect(() => {
    if (openSidePanel && sidePanel) {
      setSideBarTabs([sidePanel]);
      setSlideInSidePanel(true);
    } else if (!openSidePanel && sidePanel) {
      // trigger slide out animation without removing sidePanel from state array
      setSideBarTabs([sidePanel]);
      setSlideInSidePanel(false);
    }
  }, [openSidePanel, sidePanel]);

  useEffect(() => {
    // after slide out animation completes, remove sidePanel component
    if (!sidePanelIsMounted) {
      setSideBarTabs([]);
    }
  }, [sidePanelIsMounted]);

  const handleDocumentLoad = () => {
    const { activateTab } = defaultLayoutPluginInstance;
    if (sidePanel) {
      activateTab(0);
    }
  };
  const handlePageChange = (e: PageChangeEvent) => {
    currentPage && (currentPage.current = e.currentPage);
  };
  const handleZoomChange = (e: ZoomEvent) => {
    zoomLevel && (zoomLevel.current = e.scale);
  };
  const handlePageRotation = (e: RotateEvent) => {
    currentRotate && (currentRotate.current = e.rotation);
  };

  const renderError = (error: LoadError) => {
    return (
      <PdfViewerRenderError
        error={error}
        notifications={notifications}
        setNotifications={setNotifications}
        handleNotificationClick={handleNotificationClick}
        notificationIndexOpen={notificationIndexOpen}
        openSidePanel={openSidePanel}
        setOpenSidePanel={setOpenSidePanel}
        viewerConfig={viewerConfig}
      />
    );
  };

  let plugins: Plugin[] = [defaultLayoutPluginInstance];

  return (
    <DocumentWrapper hasViewerConfig={!!viewerConfig}>
      <Worker workerUrl="/pdf.worker.min.js">
        <PdfViewerDiv hasViewerConfig={!!viewerConfig}>
          <Viewer
            fileUrl={pdfUrl}
            plugins={plugins}
            onDocumentLoad={handleDocumentLoad}
            defaultScale={attachmentPDFViewerDefaultScale ? SpecialZoomLevel.PageFit : SpecialZoomLevel.PageWidth}
            initialPage={currentPage?.current || 0}
            onPageChange={handlePageChange}
            onZoom={handleZoomChange}
            onRotate={handlePageRotation}
            renderError={renderError}
          />
        </PdfViewerDiv>
      </Worker>
    </DocumentWrapper>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DocumentWrapper = styled(({ hasViewerConfig, ...other }) => <div {...other} />)<
  Theme,
  { hasViewerConfig?: boolean }
>(({ hasViewerConfig }) => ({
  position: "relative",
  width: "100%",
  "& .rpv-default-layout__sidebar-headers": {
    display: "none",
  },
  "& .rpv-default-layout__container": {
    borderStyle: "1px solid rgba(0,0,0,.3)",
  },
  "& .rpv-default-layout__sidebar--opened": {
    width: hasViewerConfig ? "400px" : "266px",
  },
  "& .rpv-core__splitter": {
    display: "none",
  },
  "& .rpv-core__inner-pages": {
    overflow: "auto",
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PdfViewerDiv = styled(({ hasViewerConfig, ...other }) => <div {...other} />)<
  Theme,
  { hasViewerConfig?: boolean }
>(({ hasViewerConfig }) => ({
  width: "100%",
  height: hasViewerConfig ? "100vh" : "calc(100vh - 148px)",
  "& .rpv-search__highlights": {
    "& .rpv-search__highlight": {
      opacity: "0.2 !important",
    },
  },
}));
