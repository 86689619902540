import { Box, Container, makeStyles, useTheme } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import routes from "routes";

import {
  Body1,
  FullPageLayout,
  H2,
  InlineButton,
  NotFoundPage,
  PaginateResults,
  PrimaryButton,
  ScrollableMainPanel,
  useFeature,
  useMuiContainerStyles,
} from "@coherehealth/common";
import { appHeaderHeight, headerHeight } from "../../../util/StyleConstants";
import HeaderContainer from "../../AppHeader/HeaderContainer";
import { useAuthorized } from "../../../authorization";
import LinearProgress from "@material-ui/core/LinearProgress";
import ReferralRequestSearchBar from "./ReferralRequestSearchBar";
import ReferralRequestSort from "./ReferralRequestSort";
import ReferralRequestCardList from "./ReferralRequestCardList";
import ReferralHeaderIcon from "../../images/ReferralHeaderIcon";
import { Helmet } from "react-helmet-async";
import { PatientSearchModal } from "./PatientSelectionModal/PatientSearchModal";
import {
  ReferralRequestSearchRequestBody,
  ReferralRequestSearchResponse,
  useReferralRequestSearch,
} from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import debounce from "lodash/debounce";
import useShallowCompareEffect from "react-use/lib/useShallowCompareEffect";
import trim from "lodash/trim";

const RESULTS_PER_PAGE = [
  { id: "5", label: "5" },
  { id: "10", label: "10" },
  { id: "20", label: "20" },
  { id: "30", label: "30" },
];

const initQuery = {
  offset: 0,
  max: Number(RESULTS_PER_PAGE[2].id) + 1,
  sort: "lastUpdated:desc",
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3, 0),
  },
  progressBar: {
    position: "fixed",
    top: headerHeight + appHeaderHeight(),
    left: 0,
    right: 0,
  },
  sortRow: {
    display: "flex",
  },
  referralRequestSearchAndResults: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  workflowButton: {
    marginLeft: theme.spacing(2),
  },
  workflowButtonText: {
    fontSize: theme.spacing(2),
    fontFamily: "Gilroy-SemiBold",
    textDecoration: "none",
    paddingTop: theme.spacing(1),
  },
  spacing: {
    paddingBottom: theme.spacing(6),
  },
}));

const ReferralsDashboard = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [query, setQuery] = useState<ReferralRequestSearchRequestBody>(initQuery);
  const [referralRequests, setReferralRequests] = useState<ReferralRequestSearchResponse[]>();

  const queryStable = useRef(query);
  const canCreateReferral = useAuthorized("START_AUTH_REQUEST");
  const displayMax = Number(RESULTS_PER_PAGE[2].id); // TODO: implement

  const {
    loading: referralRequestSearchLoading,
    error: referralRequestSearchError,
    mutate: referralRequestSearch,
  } = useReferralRequestSearch({
    onMutate: (prevQuery, data) => {
      setReferralRequests(data);
      queryStable.current = prevQuery;
    },
  });

  const progressBarLoading = referralRequestSearchLoading;
  const { enqueueSnackbar } = useSnackbar();

  const containerMUIStyles = useMuiContainerStyles();
  const classes = useStyles();
  const theme = useTheme();

  const referralManagementFF = useFeature("referralManagement");

  useEffect(() => {
    if (referralRequestSearchError) {
      enqueueSnackbar("Failed to get list of referral requests, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [referralRequestSearchError, enqueueSnackbar]);

  const delayedFetchReferralRequests = useRef(
    debounce((req: ReferralRequestSearchRequestBody) => {
      referralRequestSearch(req).catch((err) => {
        if (err.message !== "Failed to fetch: Aborted") {
          throw err;
        }
      });
    }, 800)
  ).current;

  useShallowCompareEffect(() => {
    if (query.max === undefined) {
      return;
    }
    let actualQueryString = trim(query.query).replace(/\s+/g, " ");
    delayedFetchReferralRequests({ ...query, query: actualQueryString });
    if (query.query === "") {
      delayedFetchReferralRequests.flush();
    }
    return () => {
      delayedFetchReferralRequests.cancel();
    };
  }, [delayedFetchReferralRequests, query]);

  if (!!referralManagementFF) {
    return (
      <>
        <Helmet>
          <title>Cohere | Referrals</title>
        </Helmet>
        <Container classes={containerMUIStyles} maxWidth="lg">
          <PatientSearchModal
            open={modalOpen}
            onClose={() => {
              setModalOpen(false);
            }}
          />
          <HeaderContainer height={headerHeight}>
            <div className={classes.header}>
              <div style={{ marginRight: theme.spacing(2) }}>
                <Box style={{ display: "flex" }}>
                  <ReferralHeaderIcon />
                  <H2 style={{ marginLeft: theme.spacing(1), paddingTop: theme.spacing(1) }}>Referrals</H2>
                  <InlineButton
                    className={classes.workflowButton}
                    onClick={() => (window.location.href = routes.DASHBOARD)}
                  >
                    <Body1 className={classes.workflowButtonText}>View authorizations</Body1>
                  </InlineButton>
                </Box>
              </div>
            </div>
            {canCreateReferral && (
              <PrimaryButton
                style={{ height: theme.spacing(6.5), alignSelf: "center" }}
                onClick={() => setModalOpen(true)}
                data-public
              >
                Start referral
              </PrimaryButton>
            )}
          </HeaderContainer>
          <div className={classes.progressBar}>{progressBarLoading && <LinearProgress />}</div>

          <FullPageLayout headerHeight={headerHeight + theme.spacing(3)}>
            <ScrollableMainPanel sideRailWidth={0}>
              <div className={classes.referralRequestSearchAndResults}>
                <ReferralRequestSearchBar queryParams={query} setQueryParams={setQuery} />
              </div>
              <div className={classes.sortRow}>
                <ReferralRequestSort queryParams={query} setQueryParams={setQuery} />
              </div>
              <div
                style={
                  referralRequests && referralRequests.length > 0
                    ? { paddingBottom: theme.spacing(3) }
                    : { paddingBottom: theme.spacing(5) }
                }
              ></div>
              <ReferralRequestCardList
                displayMax={displayMax}
                viewableReferralRequests={referralRequests}
                loading={referralRequestSearchLoading}
                error={!!referralRequestSearchError}
              />
              {referralRequests && referralRequests.length > 0 && (
                <PaginateResults
                  paginateParams={query}
                  count={referralRequests?.length || 0}
                  setPaginateParams={setQuery}
                  displayMax={displayMax}
                  RESULTS_PER_PAGE={RESULTS_PER_PAGE}
                />
              )}
            </ScrollableMainPanel>
          </FullPageLayout>
          <div className={classes.spacing}></div>
        </Container>
      </>
    );
  } else {
    return <NotFoundPage />;
  }
};

export default ReferralsDashboard;
