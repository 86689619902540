import { makeStyles } from "@material-ui/core/styles";
import { colorsLight } from "../themes/colors";
/**
 * Style overrides for Network check status displays
 */
export const useNetworkCheckStatusStyles = makeStyles((theme) => ({
  oonStatusContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(-1),
    marginTop: theme.spacing(-0.25),
  },
  unknownStatusContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(-0.25),
  },
  iconContainer: {
    justifyContent: "center",
    width: "24px",
    height: "24px",
    marginRight: "2px",
  },

  verticallyCenteredContainer: {
    display: "table",
    height: "18px",
    overflow: "hidden",
  },

  verticallyCenteredItem: {
    display: "table-cell",
  },

  oonStatusText: {
    fontFamily: "Gilroy-SemiBold",
    color: theme.palette.success.dark,
  },
  networkCheckRunningText: {
    fontFamily: "Gilroy-SemiBold",
    color: colorsLight.font.secondary,
  },
  unknownStatusText: {
    fontFamily: "Gilroy-SemiBold",
    color: colorsLight.font.light,
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: "20px",
    marginRight: "8px",
  },
  runningIconContainer: {
    marginRight: "8px",
  },
  unknownIcon: {
    color: colorsLight.font.light,
    fontSize: "20px",
    marginRight: "8px",
  },
}));
