import { Body1, Caption, InformativeModal, formatDateStrWithTz } from "@coherehealth/common";
import { Address, CareParticipantType, PhoneNumber, RequestorType } from "@coherehealth/core-platform-api";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { formatPhone, singleLineAddress } from "util/demographicUtils";

export const useRequestorInforStyle = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {
      flex: "50%",
      marginRight: theme.spacing(2),
    },
    title: {
      alignSelf: "center",
      textAlign: "center",
      marginBottom: theme.spacing(4),
    },
    infoFieldMargin: {
      flex: "50%",
    },
    infoLabel: {
      display: "inline-block",
      marginBottom: theme.spacing(0.5),
      color: theme.palette.text.secondary,
    },
    modalContainer: {
      padding: theme.spacing(3, 7),
    },
    requestorModalContainer: {
      padding: theme.spacing(3),
      maxWidth: "600px",
    },
    editRequestorModalContainer: {
      padding: theme.spacing(0),
      width: "608px",
    },
    requestorField: { overflowWrap: "break-word", padding: theme.spacing(0, 3, 3, 0) },
    contentStyle: {
      marginBottom: theme.spacing(2),
      height: "56px",
    },
    wideContentStyle: {
      marginBottom: theme.spacing(2),
      height: "56px",
      flex: 1,
    },
    containerStyle: {
      display: "flex",
      marginTop: theme.spacing(4),
    },
    clinicalContainerStyle: {
      display: "flex",
      marginTop: theme.spacing(4),
      width: "496px",
    },
    flexContainer: { display: "flex", gap: "64px" },
    infoModalGridContainer: { marginTop: theme.spacing(4), padding: theme.spacing(0, 1, 0, 4) },
    disabledSaveButtonStyle: {
      width: "40%",
      margin: theme.spacing(2, 0, 4, 0),
      whiteSpace: "nowrap",
    },
  })
);

export type ContactType =
  | "FACILITY"
  | "ORDERING_PROVIDER"
  | "PERFORMING_PROVIDER"
  | "REFERRING_PROVIDER"
  | "SPECIALIST_PRACTICE"
  | "SPECIALIST"
  | "REQUESTOR"
  | CareParticipantType;

const entityTypeDisplayName: Record<ContactType, string> = {
  FACILITY: "Performing facility or agency",
  SPECIALIST_PRACTICE: "Specialist practice",
  SPECIALIST: "Specialist",
  ORDERING_PROVIDER: "Ordering provider",
  PERFORMING_PROVIDER: "Performing provider",
  REFERRING_PROVIDER: "Referring provider",
  REQUESTOR: "Requestor",
  DRUG_SUPPLIER: "Drug supplier",
  DME_VENDOR: "DME vendor",
  CLINIC: "Clinic",
  PROVIDER_GROUP: "Provider group",
};

export interface ContactInfoModalProps {
  open: boolean;
  handleClose: () => void;
  type: ContactType;
  name?: string;
  phones?: Array<PhoneNumber | undefined>;
  fax?: Array<PhoneNumber | undefined>;
  email?: string;
  npi?: string;
  tinList?: Array<string>;
  addresses?: Array<Address | undefined>;
  npiLabel?: string;
  requestorType?: RequestorType;
  aorReceivedTimestamp?: string;
  setRequestorInfo?: () => void;
}

export default function ContactInfoModal({
  open,
  handleClose,
  type,
  name,
  phones,
  fax,
  email,
  npi,
  tinList,
  addresses,
  npiLabel,
}: ContactInfoModalProps) {
  const classes = useRequestorInforStyle();
  return (
    <InformativeModal
      open={open}
      onClose={handleClose}
      headerText={entityTypeDisplayName[type]}
      customContentStyle={classes.modalContainer}
    >
      <div className={classes.containerStyle}>
        <div className={classes.modalContent}>
          <div className={classes.contentStyle}>
            <ContactInfoField name="Name" value={valueWithFallback(name)} dataPubic={true} />
          </div>
          <div className={classes.contentStyle}>
            <ContactInfoField name="NPI" value={valueWithFallback(npiLabel)} dataPubic={true} />
          </div>
          <div className={classes.contentStyle}>
            <ContactInfoField
              name="Phone"
              value={valueWithFallback(
                phones?.map((p) => formatPhone(p)).filter((phoneString) => !!phoneString) as string[]
              )}
              dataPubic={true}
            />
          </div>
          <div className={classes.contentStyle}>
            <ContactInfoField name="Email" value={valueWithFallback(email)} dataPubic={true} />
          </div>
        </div>
        <div className={classes.infoFieldMargin}>
          <div className={classes.contentStyle}>
            <ContactInfoField name="TIN" value={valueWithFallback(tinList)} />
          </div>
          <div className={classes.contentStyle}>
            <ContactInfoField
              name="Address"
              value={valueWithFallback(
                addresses?.map((a) => singleLineAddress(a)).filter((addressesString) => !!addressesString) as string[]
              )}
              dataPubic={true}
            />
          </div>
          <div className={classes.contentStyle}>
            <ContactInfoField
              name="Fax"
              value={valueWithFallback(
                fax?.map((p) => formatPhone(p)).filter((phoneString) => !!phoneString) as string[]
              )}
              dataPubic={true}
            />
          </div>
        </div>
      </div>
    </InformativeModal>
  );
}
export function RequestorInfoModal({
  open,
  handleClose,
  type,
  name,
  phones,
  fax,
  email,
  requestorType,
  aorReceivedTimestamp,
}: ContactInfoModalProps) {
  const classes = useRequestorInforStyle();
  const formatedPhones = phones?.map((p) => formatPhone(p)).filter(elementIsNotUndefined);
  const formattedFax = fax?.map((p) => formatPhone(p)).filter(elementIsNotUndefined);
  const requestorTypeDisplayName: Record<RequestorType, string> = {
    ORDERING_PROVIDER: "Ordering provider",
    PERFORMING_PROVIDER: "Peforming provider",
    PERFORMING_FACILITY: "Performing facility",
    MEMBER_REPRESENTATIVE: "Member representative",
    MEMBER: "Member",
    THIRD_PARTY: "3rd party",
  };

  return (
    <InformativeModal
      open={open}
      onClose={handleClose}
      headerText={entityTypeDisplayName[type]}
      customContentStyle={classes.requestorModalContainer}
    >
      <Grid container spacing={0} direction={"row"} className={classes.infoModalGridContainer}>
        <Grid item xs={6} className={classes.requestorField}>
          <ContactInfoField name="Name" value={name} dataPubic={true} />
        </Grid>
        <Grid item xs={6} className={classes.requestorField}>
          <ContactInfoField
            name="Phone"
            value={formatedPhones && formatedPhones.length > 0 ? formatedPhones : "--"}
            dataPubic={true}
          />
        </Grid>
        <Grid item xs={6} className={classes.requestorField}>
          <ContactInfoField name="Email" value={email ?? "--"} dataPubic={true} />
        </Grid>
        {!!requestorType && (
          <>
            <Grid item xs={6} className={classes.requestorField}>
              <ContactInfoField
                name="Requestor type"
                value={requestorType ? requestorTypeDisplayName[requestorType] : "--"}
                dataPubic={true}
              />
            </Grid>
            <Grid item xs={6} className={classes.requestorField}>
              <ContactInfoField
                name="Fax"
                value={formattedFax && formattedFax.length > 0 ? formattedFax : "--"}
                dataPubic={true}
              />
            </Grid>
            <Grid item xs={6} className={classes.requestorField}>
              {requestorType === "MEMBER_REPRESENTATIVE" && (
                <ContactInfoField
                  name="AOR received"
                  value={aorReceivedTimestamp ? formatDateStrWithTz(aorReceivedTimestamp) : "--"}
                  dataPubic={true}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </InformativeModal>
  );
}

interface ContactInfoFieldProps {
  name: string;
  value?: string | Array<string>;
  dataPubic?: boolean;
}

export const ContactInfoField = ({ name, value, dataPubic }: ContactInfoFieldProps) => {
  const classes = useRequestorInforStyle();
  if (!value) {
    return null;
  }
  if ("string" == typeof value && !value.trim()) {
    return null;
  }

  if (Array.isArray(value) && (value.length === 0 || value.every((val) => !val.trim()))) {
    return null;
  }

  const dataPublicProps = dataPubic ? { "data-public": true } : {};
  return (
    <div>
      <Caption className={classes.infoLabel} {...dataPublicProps}>
        {name}
      </Caption>
      <Body1 {...dataPublicProps}>{Array.isArray(value) ? value.join(", ") : value}</Body1>
    </div>
  );
};

const valueWithFallback = (value: any) =>
  !Boolean(value) || (Array.isArray(value) && value.length === 0) ? "--" : value;

export function elementIsNotUndefined<T>(x: T | undefined): x is T {
  return !!x;
}
