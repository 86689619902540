import { Body1, useFeature } from "@coherehealth/common";
import { ServiceRequestResponse, ServiceRequestSearchResponse, UserResponse } from "@coherehealth/core-platform-api";
import { Grid, makeStyles } from "@material-ui/core";
import { useTheme } from "@mui/material";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { getCreatedByName, isSrCreatedByUser } from "util/serviceRequest";
import { ViewInfoButton } from "components/PatientSummary/AuthorizationPatientSummary/RequestedByInfo";
import { isBackOfficeUser } from "util/user";
import { Dispatch, SetStateAction } from "react";
interface IProps {
  serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse;
  user?: UserResponse | null;
  isRestricted?: boolean;
  isViewable?: boolean;
  visibilityToggleStatus?: boolean;
  relaxAuthVisibilityFF?: boolean;
  setIsContactInfoModalOpen?: Dispatch<SetStateAction<boolean>>;
  renderedFromServiceRequestSummary?: boolean;
}

const useStyles = makeStyles((theme) => ({
  viewDetailsRow: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 2),
  },
  lockIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    fill: theme.palette.text.secondary,
  },
}));

export default function AuthVisibilityMessage({
  serviceRequest,
  user,
  isRestricted,
  isViewable,
  visibilityToggleStatus,
  setIsContactInfoModalOpen,
  renderedFromServiceRequestSummary,
}: IProps) {
  const relaxAuthVisibilityFF = useFeature("relaxAuthVisibility");

  if (isViewable) {
    return (
      <CanViewDetailsMessage
        serviceRequest={serviceRequest}
        user={user}
        visibilityToggleStatus={visibilityToggleStatus}
        relaxAuthVisibilityFF={relaxAuthVisibilityFF}
        renderedFromServiceRequestSummary={renderedFromServiceRequestSummary}
      />
    );
  } else if (isRestricted) {
    return (
      <CannotViewDetailsMessage
        serviceRequest={serviceRequest}
        user={user}
        visibilityToggleStatus={visibilityToggleStatus}
        relaxAuthVisibilityFF={relaxAuthVisibilityFF}
        setIsContactInfoModalOpen={setIsContactInfoModalOpen}
      />
    );
  } else {
    return <></>;
  }
}

export const checkIsColleague = (
  serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse,
  user: UserResponse
) => {
  if (
    serviceRequest.requestor?.user?.organization === user.organization ||
    serviceRequest.createdByGroups?.some((group) => group === user.organizationName)
  ) {
    return true;
  } else {
    return false;
  }
};

const CanViewDetailsMessage = ({
  serviceRequest,
  user,
  visibilityToggleStatus,
  relaxAuthVisibilityFF,
  renderedFromServiceRequestSummary,
}: IProps) => {
  const classes = useStyles();
  const theme = useTheme();

  let createdByUser;
  if (user?.id && typeof user?.id === "string") {
    createdByUser = isSrCreatedByUser(user?.id, serviceRequest);
  }
  if (!isBackOfficeUser(user) && !createdByUser) {
    return <></>;
  }
  return createdByUser && (!visibilityToggleStatus || !relaxAuthVisibilityFF) ? (
    <Grid item xs={12} className={classes.viewDetailsRow}>
      <LockOutlinedIcon fontSize="small" className={classes.lockIcon} />
      <Body1>
        {renderedFromServiceRequestSummary ? (
          <span style={{ color: theme.palette.font.secondary }}>
            <span style={{ color: theme.palette.font.main, fontWeight: 600 }}>Only you</span> can view the details of
            this request.
          </span>
        ) : (
          <span style={{ color: theme.palette.font.secondary }}>
            <span style={{ color: theme.palette.font.main, fontWeight: 600 }}>Only you</span> can view the details of
            this request.&nbsp;Others involved in this patient's care will only be able to check the status.
          </span>
        )}
      </Body1>
    </Grid>
  ) : relaxAuthVisibilityFF ? (
    <Grid item xs={12} className={classes.viewDetailsRow}>
      <LockOpenIcon fontSize="small" className={classes.lockIcon} />
      <Body1>
        <span style={{ color: theme.palette.font.secondary }}>
          <span style={{ color: theme.palette.font.main, fontWeight: 600 }}>
            Individuals associated with the providers and facility
          </span>
          &nbsp;can view the details of this request.
        </span>
      </Body1>
    </Grid>
  ) : null;
};

const CannotViewDetailsMessage = ({
  serviceRequest,
  user,
  visibilityToggleStatus,
  relaxAuthVisibilityFF,
  setIsContactInfoModalOpen,
}: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const requestorName = getCreatedByName(serviceRequest);
  const isColleague = user && checkIsColleague(serviceRequest, user);
  return (
    <Grid item xs={12} className={classes.viewDetailsRow}>
      {!visibilityToggleStatus || !relaxAuthVisibilityFF ? (
        <>
          <LockOutlinedIcon fontSize="small" className={classes.lockIcon} />
          <Body1 style={{ color: theme.palette.font.secondary }}>
            Only {requestorName} {isColleague ? "(colleague)" : "(external)"} can view all details
          </Body1>
          {serviceRequest && (
            <ViewInfoButton
              serviceRequest={serviceRequest}
              setIsContactInfoModalOpen={setIsContactInfoModalOpen}
              fontSize="16px"
            />
          )}
        </>
      ) : (
        <>
          <LockOutlinedIcon fontSize="small" className={classes.lockIcon} />
          <Body1 style={{ color: theme.palette.font.secondary }}>
            Only the care team can view all details • Requested by {requestorName}{" "}
            {isColleague ? "(colleague)" : "(external)"}
          </Body1>
          {serviceRequest && (
            <ViewInfoButton
              serviceRequest={serviceRequest}
              setIsContactInfoModalOpen={setIsContactInfoModalOpen}
              fontSize="16px"
            />
          )}
        </>
      )}
    </Grid>
  );
};
