import { colorsLight, isIE } from "@coherehealth/common";
import { Grid, makeStyles } from "@material-ui/core";
import { ComponentProps } from "react";

interface StyleProps {
  partialApprovalSelected?: boolean;
}
export const useModalStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: (props: StyleProps) => (props.partialApprovalSelected ? 750 : 600),
      minWidth: (props: StyleProps) => (isIE ? (props.partialApprovalSelected ? 750 : 600) : ""),
    },
  },
  loadingSpinner: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  cbRow: {
    textAlign: "center",
  },
  cbLabel: {
    fontSize: "15px",
    color: colorsLight.font.secondary,
  },
  radioBtnRow: {
    textAlign: "start",
  },
  outcomeRow: {
    paddingLeft: (props: StyleProps) => (props.partialApprovalSelected ? 0 : ""),
    paddingRight: (props: StyleProps) => (props.partialApprovalSelected ? 0 : ""),
    marginBottom: (props: StyleProps) => (props.partialApprovalSelected ? 8 : ""),
  },
  readmissionRow: {
    marginBottom: 8,
  },
  submitButton: {
    marginBottom: "32px",
    marginTop: "12px",
  },

  textInputRow: {
    paddingLeft: (props: StyleProps) => (props.partialApprovalSelected ? 0 : undefined),
    paddingRight: (props: StyleProps) => (props.partialApprovalSelected ? 0 : undefined),
    paddingTop: (props: StyleProps) => (!props.partialApprovalSelected ? 0 : undefined),
  },

  clinicalConvoRow: {
    padding: "4px 20px",
  },
  modalContent: {
    maxWidth: 1088,
  },

  infoRow: {
    color: colorsLight.warning.dark,
  },
  errorIconContainer: {
    display: "flex",
    alignItems: "center",
  },
  errorIcon: {
    marginBottom: 5,
  },
  errorText: {
    marginLeft: 5,
  },
  errorBody: {
    color: colorsLight.font.secondary,
    marginLeft: 30,
  },
  infoAlert: {
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(3),
  },
}));

export const useMDReviewStyles = makeStyles((theme) => ({
  approveRequestWithoutP2PButton: {
    width: 333,
    height: 48,
    whiteSpace: "nowrap",
  },
  customH5: {
    margin: theme.spacing(2, 0, 3, 0),
  },
}));

export const Row = (props: ComponentProps<typeof Grid>) => (
  <Grid style={{ textAlign: "center" }} item xs={12} {...props} />
);
