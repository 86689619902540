import NoResults from "./NoResults";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import InfoAlert from "../../common/InfoAlert";
import React, { memo } from "react";
import {
  ServiceRequestCreatePayload,
  ServiceRequestSearchResponse,
  UserResponse,
} from "@coherehealth/core-platform-api";
import ServiceRequestCard from "./ServiceRequestCard";
import { ServiceRequestFormContent } from "../../common/SharedServiceRequestFormComponents";

interface Props {
  error: boolean;
  viewableServiceRequests?: ServiceRequestSearchResponse[];
  loading: boolean;
  displayMax: number;
  gatherRequirements?: boolean;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEdit?: Function;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onDeleteDraft?: Function;
  isExistingAuthorization?: boolean;
  user?: UserResponse | null;
  cardStyles?: object;
  searchForPotentialDuplicates?: (srPayload: ServiceRequestCreatePayload) => Promise<boolean>;
  workflowId?: string;
  serviceRequestFormContents?: ServiceRequestFormContent[];
  getPotentialDuplicatesLoading?: boolean;
  onPotentialDuplicatesClose?: () => void;
}

const ServiceRequestCardList = memo(
  ({
    error,
    viewableServiceRequests,
    loading,
    displayMax,
    gatherRequirements,
    onEdit,
    onDeleteDraft,
    isExistingAuthorization,
    user,
    cardStyles,
    searchForPotentialDuplicates,
    workflowId,
    serviceRequestFormContents,
    getPotentialDuplicatesLoading,
    onPotentialDuplicatesClose,
  }: Props) => {
    return (
      <CardList>
        {!error && !viewableServiceRequests && loading && (
          <NoResults heading="Loading your services" message="This might take a few seconds..." />
        )}
        {viewableServiceRequests &&
          (viewableServiceRequests.length === 0 ? (
            <NoResults />
          ) : (
            viewableServiceRequests.slice(0, displayMax).map((s, idx) => (
              <div key={s.id || `service-request-fallback-${idx}`}>
                {s.reconClaim && <InfoAlert message="Claim">Claim number: {s.reconClaim.claimId}</InfoAlert>}
                <ServiceRequestCard
                  gatherRequirements={gatherRequirements}
                  serviceRequest={s}
                  onEdit={onEdit}
                  onDeleteDraft={onDeleteDraft}
                  isExistingAuthorization={isExistingAuthorization}
                  cardStyles={cardStyles}
                  user={user}
                  searchForPotentialDuplicates={searchForPotentialDuplicates}
                  workflowId={workflowId}
                  serviceRequestFormContents={serviceRequestFormContents}
                  getPotentialDuplicatesLoading={getPotentialDuplicatesLoading}
                  onPotentialDuplicatesClose={onPotentialDuplicatesClose}
                ></ServiceRequestCard>
              </div>
            ))
          ))}
      </CardList>
    );
  }
);

export default ServiceRequestCardList;

// Service request cards
// eslint-disable-next-line cohere-react/no-mui-styled-import
const CardList = styled("div")({
  // These styles mostly help with centering the loading spinner
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
});
